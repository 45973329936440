import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { editRatesMutation } from 'api/actions/editRates/requestNeedActions';
import { projectsKeys, dictionariesKeys } from 'utils/queryKeys';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';

export const useEditRates = (projectId: string) => {
  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutationDomainError(['editRatesMutation'], editRatesMutation(projectId), {
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.editRates.success'] }));
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(dictionariesKeys.specializationsList);
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.editRates.error'] }),
  });
};
