import { Table } from 'lux/components';

import { EmptyState } from 'ui/emptyState/EmptyState';
import { Translation } from 'ui/translation/Translation';
import { sortArr } from 'utils/sortArr';
import { ProjectInvoicesSkeleton } from 'app/project/projectInvoices/ProjectInvoices.skeleton';
import { InvoiceRow } from 'app/project/projectInvoices/invoiceRow/InvoiceRow';
import { useClientInvoices } from 'hooks/useClientInvoices/useClientInvoices';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useClient } from 'hooks/useClient/useClient';
import { ProjectDetailsContextController } from 'context/projectDetails/projectDetailsContextController/ProjectDetailsContextController';
import { CardWrapper } from 'ui/cardWrapper/CardWrapper';

import * as styles from './ClientInvoices.styles';

export const ClientInvoices = () => {
  const { t } = useLocale();
  const { clientDetails, isLoading: isClientLoading } = useClient();
  const { data: clientInvoices, isLoading: isClientInvoicesLoading } = useClientInvoices(clientDetails?.client?.id);

  if (isClientLoading || isClientInvoicesLoading) {
    return (
      <CardWrapper>
        <ProjectInvoicesSkeleton />;
      </CardWrapper>
    );
  }

  const sortedInvoices = clientInvoices?.invoices
    ? sortArr(
        clientInvoices.invoices,
        (invoiceOne, invoiceTwo) =>
          new Date(invoiceOne.billingCycle.start).getTime() - new Date(invoiceTwo.billingCycle.start).getTime(),
      )
    : [];

  if (!sortedInvoices.length) {
    return (
      <CardWrapper>
        <EmptyState
          illustration="relaxPeople"
          title={t('client.invoices.emptyInvoices.title')}
          message={t('client.invoices.emptyInvoices.message')}
        />
      </CardWrapper>
    );
  }

  return (
    <CardWrapper>
      <Table.Table sx={styles.table}>
        <Table.Head>
          <Table.Row>
            <Table.Cell size="small">
              <Translation id="projectDetails.invoices.invoiceNumber" />
            </Table.Cell>
            <Table.Cell size="small">
              <Translation id="projectDetails.invoices.status" />
            </Table.Cell>
            <Table.Cell size="small">
              <Translation id="projectDetails.invoices.workStatement" />
            </Table.Cell>
            <Table.Cell size="small">
              <Translation id="projectDetails.invoices.billingCycle" />
            </Table.Cell>
            <Table.Cell size="small">
              <Translation id="projectDetails.invoices.invoiceAmount" />
            </Table.Cell>
            <Table.Cell size="small">
              <Translation id="projectDetails.invoices.project" />
            </Table.Cell>
            <Table.Cell size="small" />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {sortedInvoices.map((invoice) => (
            <ProjectDetailsContextController key={invoice.id} projectId={invoice.projectId}>
              <InvoiceRow invoice={invoice} isClientView />
            </ProjectDetailsContextController>
          ))}
        </Table.Body>
      </Table.Table>
    </CardWrapper>
  );
};
