import { Styles } from 'styles/theme';

export const projectCardWrapper: Styles = {
  display: 'flex',
  minHeight: '144px',
  paddingY: 2,
  paddingLeft: 2,
  paddingRight: 4,
  backgroundColor: 'surface.paper.default',
  borderRadius: 2,
  cursor: 'pointer',
  textDecoration: 'none',
  color: 'text.primary',

  '.hoverVisible': {
    visibility: 'hidden',
  },

  '&:hover': {
    backgroundColor: 'primary.light',

    '.hoverVisible': {
      visibility: 'visible',
    },

    '.hoverInvisible': {
      visibility: 'hidden',
    },
  },
};

export const topBar: Styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const dateWrapper: Styles = {
  display: 'flex',
  gap: 1,
  alignItems: 'center',
};

export const descriptionTitle: Styles = {
  fontWeight: 600,
  marginBottom: 0.5,
};

export const description: Styles = {
  display: '-webkit-box',
  marginBottom: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
};

export const wrapper: Styles = {
  position: 'relative',
  width: '100%',
  justifyContent: 'space-between',

  a: {
    textDecorationLine: 'none',
    color: 'text.secondary',
    width: 'fit-content',
  },

  'a:hover': {
    color: 'text.active',
  },
};
