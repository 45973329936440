import { SxProps } from '@mui/material';

export const container: SxProps = {
  ['@media print']: {
    display: 'none',
    width: '300px',
    border: '0.5px solid',
    borderColor: 'border.light',
    borderRadius: 5,
    padding: '1rem',
  },
};

export const depositItem: SxProps = {
  ['@media print']: {
    '& .MuiButtonBase-root': {
      ['@media print']: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      },
    },
    '& .MuiBox-root': {
      justifyContent: 'center',
    },
  },
};

export const upperDepositItem: SxProps = {
  ['@media print']: {
    '&.MuiGrid-item': {
      paddingTop: 3,
      paddingBottom: 0,
    },
    ...depositItem,
  },
};

export const bottomDepositItem: SxProps = {
  ['@media print']: {
    '&.MuiGrid-item': {
      paddingTop: 0,
      paddingBottom: 2,
    },
    ...depositItem,
  },
};
