import { ProjectNeedsFiltersContext } from 'context/projectNeedsFilters/projectNeedsFiltersContext/ProjectNeedsFiltersContext';
import {
  ProjectNeedsFilters,
  ProjectNeedsSortingField,
} from 'context/projectNeedsFilters/projectNeedsFiltersContext/ProjectNeedsFiltersContext.types';
import { useFilters } from 'hooks/useFilters/useFilters';
import { generateSortFilter } from 'utils/filters/filters';

import { ProjectNeedsFiltersContextControllerProps } from './ProjectNeedsFiltersContextController.types';

export const projectNeedsSortingOptions = generateSortFilter(ProjectNeedsSortingField);

const defaultFilters = {
  sort: projectNeedsSortingOptions.startDateASC,
};

export const ProjectNeedsFiltersContextController = ({
  initialFilters,
  children,
  withParams,
  storageKey,
}: ProjectNeedsFiltersContextControllerProps) => {
  const contextValues = useFilters<ProjectNeedsFilters>(
    {
      ...defaultFilters,
      ...initialFilters,
    },
    { withParams, storageKey },
  );

  return <ProjectNeedsFiltersContext.Provider value={contextValues}>{children}</ProjectNeedsFiltersContext.Provider>;
};
