import { useMemo, useState } from 'react';

import { RawIntlProvider } from 'react-intl';

import { AppLocale } from '../AppLocale.enum';
import { defaultLocale } from '../defaultLocale';
import { LocaleContext } from '../localeContext/LocaleContext';
import { intl } from '../intl/Intl';
import { LocaleContextValueType } from '../localeContext/LocaleContext.types';

import { LocaleContextControllerProps } from './LocaleContextController.types';

export const LocaleContextController = ({ children }: LocaleContextControllerProps) => {
  const [locale, setLocale] = useState<AppLocale>(defaultLocale);

  const handleLocaleChange = (locale: AppLocale) => {
    setLocale(locale);
    intl.currentLocale = locale;
  };

  const value: LocaleContextValueType = useMemo(
    () => ({ defaultLocale, locale, setLocale: handleLocaleChange }),
    [defaultLocale, locale, handleLocaleChange],
  );

  return (
    <RawIntlProvider value={intl.currentIntl}>
      <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
    </RawIntlProvider>
  );
};
