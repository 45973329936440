import { NeedDto } from 'api/types/Need.types';

export type PeopleProposeModalProps = {
  projectId: string;
  needData: NeedDto;
  isOpen: boolean;
  onClose: VoidFunction;
};

export enum PeopleProposeModalStep {
  comparisonTable = 'comparisonTable',
}
