import { FormProvider } from 'react-hook-form';
import { Box } from '@mui/material';
import { joiResolver } from '@hookform/resolvers/joi';
import { Link, useLocation } from 'react-router-dom';
import pickBy from 'lodash.pickby';
import negate from 'lodash.negate';
import isEmpty from 'lodash.isempty';
import { useSnackbar } from 'notistack';
import { Button, Typography, Grid } from 'lux/components';
import { ChevronLeftIcon } from 'lux/icons';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { useCreateClient } from 'hooks/useCreateClient/useCreateClient';
import { useCreateProject } from 'hooks/useCreateProject/useCreateProject';
import { useTitle } from 'hooks/useTitle/useTitle';
import { AppRoute } from 'routing/AppRoute.enum';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Currency } from 'api/types/Currency.enum';
import { CreateClientRequest } from 'api/actions/createClient/createClientActions.types';
import { CreateProjectRequest } from 'api/actions/createProject/createProjectActions.types';
import { convertRateToInteger } from 'utils/convertRate';
import { formatDate } from 'utils/dateUtils';

import * as styles from './CreateProject.styles';
import { CreateProjectForm } from './CreateProject.types';
import { formSchema } from './CreateProject.schema';
import { defaultValues } from './defaultValues';
import { mapRateCard } from './rateCard/RateCard.mappers';
import { FormStep } from './formStep/FormStep';
import { FormNavigationContextController } from './formNavigationContext/FormNavigationContext';
import { Stepper } from './stepper/Stepper';
import { BottomBar } from './bottomBar/BottomBar';

export const CreateProject = () => {
  const form = useIntlForm<CreateProjectForm>({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(formSchema()),
  });

  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const { formatMessage } = useLocale();

  useTitle(formatMessage({ id: AppMessages['title.createProject'] }));

  const { mutateAsync: mutateClientAsync } = useCreateClient();
  const { mutateAsync: mutateProjectAsync } = useCreateProject();

  const from = location.state?.from || AppRoute.dashboard;

  const onSubmit = async (createProjectForm: CreateProjectForm) => {
    const payloadClient: CreateClientRequest = {
      name: createProjectForm.client.name,
      industries: createProjectForm.client.industries,
      country: createProjectForm.client.country,
      accountNumber: createProjectForm.client.accountNumber,
    };

    const payloadProject: CreateProjectRequest = {
      name: createProjectForm.project.name,
      description: createProjectForm.project.description,
      attributes: createProjectForm.project.attributes,
      startDate: createProjectForm.project.startDate ? formatDate(createProjectForm.project.startDate) : '',
      endDate: createProjectForm.project.endDate && formatDate(createProjectForm.project.endDate),
      currency: createProjectForm.project.currency as Currency,
      tags: createProjectForm.project.tags,
      rateCard: {
        items: mapRateCard(createProjectForm.rateCards),
        currency: createProjectForm.project.currency as Currency,
        appliesFrom: formatDate(new Date()),
      },
      clientId: createProjectForm.clientId || '',
      timeDifference: createProjectForm.project.timeDiff,
      budget:
        createProjectForm.project.budget?.toString() && String(convertRateToInteger(createProjectForm.project.budget)),
      contactPerson: createProjectForm.project.contactPerson,
      codename: createProjectForm.project.codename,
      slackChannelId: createProjectForm.project.slackChannelId,
      category: '',
    };

    if (createProjectForm.clientId) {
      payloadProject.clientId = createProjectForm.clientId;
    } else {
      const { clientId } = await mutateClientAsync(payloadClient);

      payloadProject.clientId = clientId;
      form.setValue('clientId', clientId);
    }

    await mutateProjectAsync(pickBy(payloadProject, negate(isEmpty)) as CreateProjectRequest, {
      onSuccess: () => {
        enqueueSnackbar(
          formatMessage({ id: AppMessages['snackbar.createProject.success'] }, { name: payloadProject.name }),
        );
        form.reset();
      },
    });
  };

  return (
    <Box>
      <FormProvider {...form}>
        <FormNavigationContextController onSubmit={form.handleSubmit(onSubmit)}>
          <form>
            <Grid container sx={styles.gridContainer} gutter={0} spacing={0}>
              <Grid item xs={12}>
                <Button
                  size="large"
                  variant="text"
                  component={Link}
                  to={from}
                  color="secondary"
                  sx={styles.backBtn}
                  startIcon={<ChevronLeftIcon />}
                  data-cy="create-project_back-button"
                >
                  {from === AppRoute.projects
                    ? formatMessage({ id: AppMessages['createProject.button.backToProjects'] })
                    : formatMessage({ id: AppMessages['createProject.button.backToDashboard'] })}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h4" sx={styles.stepperHeader}>
                  {formatMessage({ id: AppMessages['createProject.title'] })}
                </Typography>
                <Stepper />
              </Grid>
              <Grid item xs={6}>
                <FormStep />
              </Grid>
            </Grid>
            <BottomBar />
          </form>
        </FormNavigationContextController>
      </FormProvider>
    </Box>
  );
};
