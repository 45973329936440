import Joi from 'joi';

import { needSchema } from 'app/need/schema/Need.schema';
import { NeedType } from 'api/types/NeedType.enum';

export const absenceReplacementSchema = (projectStartDate?: string) =>
  needSchema(projectStartDate).keys({
    type: Joi.string().valid(NeedType.replacement).required(),
    replacedAssignmentId: Joi.string().uuid().required(),
  });
