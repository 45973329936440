import { Stack } from '@mui/material';
import { Checkbox, Table, Typography } from 'lux/components';

import { ReactComponent as CheckboxMidIcon } from 'assets/images/icons/checkbox-mid-icon.svg';
import { Translation } from 'ui/translation/Translation';

import { PlanAssignmentExtensionTableHeadProps } from './PlanAssignmentExtensionTableHead.types';

export const PlanAssignmentExtensionTableHead = ({
  onChangeAllAssignments,
  selectedAssignmentIds,
}: PlanAssignmentExtensionTableHeadProps) => {
  const selectedIds = Object.values(selectedAssignmentIds);
  const selectedCount = selectedIds.filter(Boolean).length;
  const isAnyIdSelected = selectedCount > 0;
  const areAllIdsSelected = selectedCount === selectedIds.length;

  return (
    <Table.Head>
      <Table.Row>
        <Table.Cell padding="checkbox" colSpan={isAnyIdSelected ? 5 : 1} width={100}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap={4}>
              <Checkbox
                checked={isAnyIdSelected}
                onChange={(event, checked) => onChangeAllAssignments(checked)}
                checkedIcon={!areAllIdsSelected ? <CheckboxMidIcon /> : undefined}
              />
              {isAnyIdSelected && (
                <Typography variant="body2">
                  <Translation
                    id="projectDetails.planProjectExtensionModal.table.itemsSelected"
                    values={{ count: selectedCount }}
                  />
                </Typography>
              )}
            </Stack>
          </Stack>
        </Table.Cell>
        {!isAnyIdSelected && (
          <>
            <Table.Cell>
              <Translation id="projectDetails.planProjectExtensionModal.table.name" />
            </Table.Cell>
            <Table.Cell>
              <Translation id="projectDetails.planProjectExtensionModal.table.role" />
            </Table.Cell>
            <Table.Cell>
              <Translation id="projectDetails.planProjectExtensionModal.table.assignedTo" />
            </Table.Cell>
            <Table.Cell>
              <Translation id="projectDetails.planProjectExtensionModal.table.extensionTo" />
            </Table.Cell>
          </>
        )}
      </Table.Row>
    </Table.Head>
  );
};
