import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { Grid } from 'lux/components';

import { DatePicker } from 'ui/form/datePicker/DatePicker';
import { WorkStatementForm } from '../WorkStatementModals.types';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Loader } from 'ui/loader/Loader';
import { AppMessages } from 'i18n/messages';
import { MAX_SUPPORTED_DATE, ZERO_UNIX_DATE, addDaysToDate, parseDate, subtractDaysFromDate } from 'utils/dateUtils';
import { Select } from 'ui/form/select/Select';

import { WorkStatementFormContentProps, WorkStatementFormType } from './WorkStatementFormContent.types';

export const WorkStatementFormContent = ({
  loading,
  projectStartDate,
  projectEndDate,
  projectCurrency,
  useProjectStartDate,
  type,
}: WorkStatementFormContentProps) => {
  const { formatMessage } = useLocale();
  const { watch, control, setValue } = useFormContext<WorkStatementForm>();

  const [startDate, endDate] = watch(['startDate', 'endDate']);
  const depositAmountFieldValue = watch('depositAmount');

  useEffect(() => {
    if (!depositAmountFieldValue) {
      setValue('depositPaymentDate', null);
    }
  }, [depositAmountFieldValue]);

  const paymentDueText = depositAmountFieldValue
    ? 'projectDetails.workStatementForm.depositPaymentDueMandatory'
    : 'projectDetails.workStatementForm.depositPaymentDue';

  const getMaxDate = () => {
    if (endDate) {
      return subtractDaysFromDate(endDate, 1);
    }

    if (projectEndDate) {
      return parseDate(projectEndDate);
    }

    return MAX_SUPPORTED_DATE;
  };

  const getMaxDepositPaymentDueDate = () => {
    if (endDate) {
      return endDate;
    }

    if (projectEndDate) {
      return parseDate(projectEndDate);
    }

    return MAX_SUPPORTED_DATE;
  };

  if (loading) {
    return <Loader fullHeight={false} />;
  }

  return (
    <Grid container gutter={0}>
      <Grid item xs={12}>
        <FormTextField
          name="statementNumber"
          control={control}
          label={formatMessage({ id: AppMessages['projectDetails.workStatementForm.statementNumber'] })}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          minDate={parseDate(projectStartDate)}
          maxDate={getMaxDate()}
          name="startDate"
          control={control}
          label={formatMessage({ id: AppMessages['projectDetails.workStatementForm.startDate'] })}
          disableUntilDate={parseDate(projectStartDate)}
          isDisabled={useProjectStartDate}
          fullWidth
        />
      </Grid>
      {process.env.REACT_APP_INVOICING_FEATURE_DISABLED === 'false' && (
        <Grid item xs={12}>
          <DatePicker
            minDate={startDate ? addDaysToDate(startDate, 1) : parseDate(projectStartDate)}
            maxDate={projectEndDate ? parseDate(projectEndDate) : MAX_SUPPORTED_DATE}
            name="endDate"
            control={control}
            label={formatMessage({ id: AppMessages['projectDetails.workStatementForm.endDate'] })}
            disableUntilDate={startDate ? addDaysToDate(startDate, 1) : ZERO_UNIX_DATE}
            isDisabled={!startDate}
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Select
          fullWidth
          name="billingCycle"
          control={control}
          label={formatMessage({ id: AppMessages['projectDetails.workStatementForm.billingPeriod.label'] })}
          options={[
            {
              id: '1',
              children: formatMessage({ id: AppMessages['projectDetails.workStatementForm.billingPeriod.month'] }),
              value: 'month',
            },
          ]}
          isDisabled={true}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          fullWidth
          name="paymentPeriod"
          control={control}
          label={formatMessage({ id: AppMessages['projectDetails.workStatementForm.paymentPeriod'] })}
          endAdornment={formatMessage({
            id: AppMessages['projectDetails.workStatementForm.paymentPeriod.days'],
          })}
          type="number"
        />
      </Grid>
      {type === WorkStatementFormType.add && (
        <>
          <Grid item xs={12}>
            <FormTextField
              fullWidth
              name="depositAmount"
              control={control}
              label={formatMessage({ id: AppMessages['projectDetails.workStatementForm.depositAmount'] })}
              startAdornment={projectCurrency}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              minDate={startDate ? addDaysToDate(startDate, 1) : parseDate(projectStartDate)}
              maxDate={getMaxDepositPaymentDueDate()}
              name="depositPaymentDate"
              control={control}
              label={formatMessage({
                id: AppMessages[paymentDueText],
              })}
              disableUntilDate={startDate ? addDaysToDate(startDate, 1) : parseDate(projectStartDate)}
              isDisabled={!depositAmountFieldValue}
              fullWidth
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
