import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { RemoveFutureRateCardParams } from './removeFutureRateCardActions.types';

export const removeFutureRateCardMutation: (projectId: string) => MutationFn<RemoveFutureRateCardParams> =
  (projectId: string) => () => ({
    endpoint: `/projects/${projectId}/remove-future-rates`,
    method: 'DELETE',
    params: {},
  });
