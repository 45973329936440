import { Box } from '@mui/material';
import { Illustration, Typography } from 'lux/components';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import * as projectStyles from '../CreateProject.styles';

import * as styles from './NoRateCardRequired.styles';

export const NoRateCardRequired = () => {
  const { formatMessage } = useLocale();

  return (
    <Box>
      <Typography variant="h6" sx={projectStyles.formHeader}>
        {formatMessage({ id: AppMessages['createProject.noRateCardRequired.header'] })}
      </Typography>
      <Typography sx={projectStyles.message} variant="body1">
        {formatMessage({ id: AppMessages['createProject.noRateCardRequired.message'] })}
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Illustration sx={styles.illustration} type="goodJob" />
      </Box>
    </Box>
  );
};
