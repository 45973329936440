import { Typography } from 'lux/components';
import { Stack } from '@mui/material';

import type { EditAssignmentSectionProps } from './EditAssignmentSection.types';
import * as styles from './EditAssignmentSection.styles';

export const EditAssignmentSection = ({ title, children }: EditAssignmentSectionProps) => (
  <Stack gap={1}>
    <Typography sx={styles.title}>{title}</Typography>

    <Stack gap={2}>{children}</Stack>
  </Stack>
);
