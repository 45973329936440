import { Button } from 'lux/components';

import { useNeedsFilters } from 'hooks/useNeedsFilters/useNeedsFilters';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const ResetFiltersButton = () => {
  const { formatMessage } = useLocale();
  const { resetAllFiltersToDefault } = useNeedsFilters();

  const handleClick = () => {
    resetAllFiltersToDefault(['groupBy', 'sort']);
  };

  return (
    <Button size="sm" variant="text" onClick={handleClick}>
      {formatMessage({ id: AppMessages['filters.reset'] })}
    </Button>
  );
};
