import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { getProposalsQuery } from 'api/actions/getProposals/getProposals';
import { GetProposalsParams, GetProposalsResponse } from 'api/actions/getProposals/getProposals.types';
import { proposalsKeys } from 'utils/queryKeys';

export const useProposals = (needId: string) => {
  const { isAuthenticated } = useAuth();

  const response = useQuery<GetProposalsParams, GetProposalsResponse>(
    proposalsKeys.proposalsList(needId),
    getProposalsQuery(needId),
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
    },
  );

  return response;
};
