import { Table } from 'lux/components';

import { Skeleton } from 'ui/skeleton/Skeleton';

import * as styles from './AvailabilityTableCell.styles';
import { SplitValueSkeleton } from './splitValue/SplitValue.skeleton';

export const AvailabilityTableCellSkeleton = ({
  filterBySeniority,
  showSeniorityHeaders,
}: {
  filterBySeniority: boolean;
  showSeniorityHeaders?: boolean;
}) => (
  <Table.Cell align="right" sx={styles.cellBase}>
    {filterBySeniority ? (
      <SplitValueSkeleton showSeniorityHeaders={showSeniorityHeaders} />
    ) : (
      <Skeleton width="100%" height={32} />
    )}
  </Table.Cell>
);
