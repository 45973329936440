import { Styles } from 'styles/theme';

export const infoIcon: Styles = {
  backgroundColor: 'transparent',
};

export const datePickersWrapper: Styles = {
  flexDirection: 'row',
  maxWidth: '690px',
  gap: 3,
};

export const divider = {
  borderColor: 'divider',
  marginY: 3,
  width: '100%',
};
