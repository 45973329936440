import { useMemo } from 'react';

import { Table } from 'lux/components';

import { composeStyles } from 'utils/composeStyles';

import * as styles from './AvailabilityTableCell.styles';
import { AvailabilityTableCellProps } from './AvailabilityTableCell.types';
import { SplitValue } from './splitValue/SplitValue';
import { SingleValue } from './singleValue/SingleValue';

export const AvailabilityTableCell = ({
  filterBySeniority,
  includeProposed,
  includeExtension,
  data,
  showSeniorityHeaders,
  period,
  department,
}: AvailabilityTableCellProps) => {
  const availabilityData = useMemo(() => {
    const defaultData = { all: 0, junior: 0, senior: 0, regular: 0 };

    if (includeExtension && includeProposed) {
      return data.availableWithAll || defaultData;
    }

    if (includeProposed) {
      return data.availableWithProposed || defaultData;
    }

    if (includeExtension) {
      return data.availableWithExtensions || defaultData;
    }

    return data.available || defaultData;
  }, [includeProposed, includeExtension]);

  const availablePeople = useMemo(() => {
    if (includeExtension && includeProposed) {
      return data.availableAllNames || [];
    }

    if (includeProposed) {
      return data.availableNamesWithProposed || [];
    }

    if (includeExtension) {
      return data.availableNamesWithExtension || [];
    }

    return data.availableNamesWithoutProposed || [];
  }, [includeProposed, includeExtension]);

  const { all, ...levelsData } = availabilityData;

  return (
    <Table.Cell
      align="right"
      sx={composeStyles([
        styles.cellBase,
        !filterBySeniority && styles.cell(availabilityData.all, data.totalInDepartment),
        filterBySeniority && styles.cellUnstyled,
      ])}
    >
      {filterBySeniority ? (
        <SplitValue
          data={levelsData}
          totalInDepartment={data.totalInDepartment}
          showSeniorityHeaders={showSeniorityHeaders}
          people={availablePeople}
          period={period}
          department={department}
        />
      ) : (
        <SingleValue value={all} totalInDepartment={data.totalInDepartment} people={availablePeople} />
      )}
    </Table.Cell>
  );
};
