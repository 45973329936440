import { useEffect, useState } from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import jwtDecode from 'jwt-decode';

import { Loader } from 'ui/loader/Loader';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useCurrentUser } from '../hooks/useCurrentUser/useCurrentUser';

import { AppRoute } from './AppRoute.enum';

export const AuthorizedRoute = () => {
  const location = useLocation();
  const { inProgress, instance, accounts } = useMsal();
  const { data: user, isInitialLoading } = useCurrentUser();
  const { accessToken, isAuthenticated } = useAuth();
  const isMsalAuthenticated = useIsAuthenticated();
  const [isAuthenticating, setIsAuthenticating] = useState(inProgress !== InteractionStatus.None);

  useEffect(() => {
    if (accessToken) {
      setIsAuthenticating(false);
    } else {
      setIsAuthenticating(!accessToken && inProgress !== InteractionStatus.None);
    }
  }, [instance, inProgress, accounts, accessToken]);

  if (isAuthenticating || isInitialLoading) {
    return <Loader />;
  }

  if (!isMsalAuthenticated || !isAuthenticated || !user) {
    return <Navigate to={AppRoute.logout} state={{ from: location }} replace />;
  }

  if (!user.metadata.hasAccess) {
    return <Navigate to={AppRoute.forbidden} state={{ from: location }} replace />;
  }

  if (accessToken) {
    const decoded = jwtDecode(accessToken as string) as { exp: number };
    const currentTime = Math.floor(Date.now() / 1000);
    if (currentTime >= decoded.exp) {
      window.location.reload();

      return <Loader />;
    }
  }
  return <Outlet />;
};
