import { MenuItemProps } from '@mui/material';

import { addMonthsToDate, formatDate, getMonthsDifference } from 'utils/dateUtils';

import { AddBillingCycleFormData } from './AddBillingCycleForm.types';

export const defaultValues: AddBillingCycleFormData = {
  amount: 1,
};

export const getOptions = (startDate: Date, endDate: Date | null): MenuItemProps[] => {
  let numberOfBillingCycleArr = [1, 2, 3, 4, 5, 6];

  if (endDate) {
    const difference = getMonthsDifference(startDate, endDate);

    numberOfBillingCycleArr = numberOfBillingCycleArr.slice(0, difference + 1);
  }

  return numberOfBillingCycleArr.map((item) => ({
    id: String(item),
    value: item,
    children: formatDate(addMonthsToDate(startDate, item - 1), 'MMMM yyyy'),
  }));
};
