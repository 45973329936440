import { Box } from '@mui/material';
import { Typography } from 'lux/components';

import { Seniority } from 'api/types/Seniority.enum';

import { SeniorityRate } from './seniorityRate/SeniorityRate';
import * as styles from './RateCardItem.styles';
import type { RateCardItemProps } from './RateCardItem.types';
import { getMissingSeniorities } from './RateCardItem.utils';

export const RateCardItem = ({ role, rateCard, neededRoles }: RateCardItemProps) => {
  const withoutLevelRateCard = rateCard.items[role].find((item) => item.seniority === Seniority.withoutLevel);

  if (withoutLevelRateCard) {
    return (
      <Box sx={styles.rateCardItem(false)}>
        <Typography sx={styles.roleName}>{role}</Typography>

        <Box sx={styles.rateCardItemColumns}>
          <SeniorityRate amount={withoutLevelRateCard.amount} />
          <Typography sx={styles.currency}>{rateCard.currency}</Typography>
        </Box>
      </Box>
    );
  }

  const juniorRateCard = rateCard.items[role].find((item) => item.seniority === Seniority.junior);
  const devRateCard = rateCard.items[role].find((item) => item.seniority === Seniority.mid);
  const seniorRateCard = rateCard.items[role].find((item) => item.seniority === Seniority.senior);

  const missingSeniorities = getMissingSeniorities(rateCard, role, neededRoles);
  const isMissingRates = !!missingSeniorities.length;
  const isMissing = (seniority: Seniority) => missingSeniorities.some(({ value }) => value === seniority);

  return (
    <Box sx={styles.rateCardItem(isMissingRates)}>
      <Typography sx={styles.roleName}>{role}</Typography>

      <Box sx={styles.rateCardItemColumns}>
        <SeniorityRate amount={juniorRateCard?.amount} isMissing={isMissing(Seniority.junior)} />
        <span>/</span>
        <SeniorityRate amount={devRateCard?.amount} isMissing={isMissing(Seniority.mid)} />
        <span>/</span>
        <SeniorityRate amount={seniorRateCard?.amount} isMissing={isMissing(Seniority.senior)} />
        <Typography sx={styles.currency}>{rateCard.currency}</Typography>
      </Box>
    </Box>
  );
};
