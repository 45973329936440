import { Box, Stack } from '@mui/material';

import * as styles from '../PersonTooltip.styles';
import { PersonBasicInfoSkeleton } from 'shared/people/personBasicInfo/PersonBasicInfo.skeleton';
import { Skeleton } from 'ui/skeleton/Skeleton';
import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

export const PersonTooltipContentSkeleton = () => (
  <Stack gap={1}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Box sx={styles.employeeLink}>
        <PersonBasicInfoSkeleton />
      </Box>
      <Skeleton width={116} height={24} />
    </Stack>

    <Stack>
      <TextSkeleton width={132} variant="caption" />
    </Stack>
  </Stack>
);
