import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { issueInvoice } from 'api/actions/issueInvoice/issueInvoice';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { extractInvoiceNumber } from 'utils/extractInvoiceNumber';
import { UseConfirmInvoiceConflictError } from 'hooks/useConfirmInvoice/useConfirmInvoice.types';
import { confirmInvoiceErrorDefaultState } from 'context/projectBillingSummary/projectBillingSummaryDataContextController/ProjectBillingSummaryDataContextController';
import { getInvoicesQueryKey } from 'utils/queryKeys';

import { IssueInvoiceErrorEnum } from './useIssueInvoice.types';

export const useIssueInvoice = (invoiceId: string) => {
  const queryClient = useQueryClient();
  const { invalidateBillingSummaryCache, projectId, setConfirmInvoiceError } = useProjectBillingSummaryData();
  const { formatMessage } = useLocale();

  return useMutation(['issueInvoice'], issueInvoice(invoiceId), {
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: AppMessages['projectDetails.invoice.actions.add.success'] }));
      invalidateBillingSummaryCache();
      queryClient.invalidateQueries(getInvoicesQueryKey({ projectId }));
      setConfirmInvoiceError(confirmInvoiceErrorDefaultState);
      sessionStorage.removeItem('confirmInvoiceError');
    },
    onError: (err) => {
      const { stack } = err.response?.data as UseConfirmInvoiceConflictError;

      const setConfirmInvoiceErrorHandler = (errorType: string, message: string) => {
        const invoiceNumber = extractInvoiceNumber(stack, errorType);

        setConfirmInvoiceError({
          message,
          invoiceNumber,
          errorType,
          invoiceId,
        });
      };

      if (stack.includes(IssueInvoiceErrorEnum.invoiceNotFoundError)) {
        setConfirmInvoiceErrorHandler(
          IssueInvoiceErrorEnum.invoiceNotFoundError,
          'projectDetails.invoice.actions.add.error.number.mismatch',
        );
      }

      if (stack.includes(IssueInvoiceErrorEnum.invoiceTotalAmountMismatchError)) {
        setConfirmInvoiceErrorHandler(
          IssueInvoiceErrorEnum.invoiceTotalAmountMismatchError,
          'projectDetails.invoice.actions.add.error.amount.mismatch',
        );
      }

      if (stack.includes(IssueInvoiceErrorEnum.invoiceCurrencyMismatchError)) {
        setConfirmInvoiceErrorHandler(
          IssueInvoiceErrorEnum.invoiceCurrencyMismatchError,
          'projectDetails.invoice.actions.add.error.currency.mismatch',
        );
      }

      if (stack.includes(IssueInvoiceErrorEnum.invoiceExistError)) {
        setConfirmInvoiceErrorHandler(
          IssueInvoiceErrorEnum.invoiceExistError,
          'projectDetails.invoice.actions.add.error.currency.exist',
        );
      }
    },
  });
};
