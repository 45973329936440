import { Styles } from 'styles/theme';

export const tabList: Styles = {
  '&.MuiTabs-root': {
    marginBottom: 3,
  },

  '.MuiTabs-flexContainer': {
    borderBottom: '2px solid',
    borderBottomColor: 'rgba(0, 0, 0, 0.12)',
  },

  '.MuiTab-root': {
    borderBottom: 0,
    minWidth: 200,
  },
};
