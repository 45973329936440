import { Tooltip } from 'lux/components';
import { FieldValues } from 'react-hook-form';
import { Box, Stack } from '@mui/material';
import { InfoIcon } from 'lux/icons';

import { CheckboxProps } from './Checkbox.types';
import { CheckboxController } from './CheckboxController';

export const Checkbox = <T extends FieldValues>(props: CheckboxProps<T>) => {
  const { tooltipTitle } = props;

  return (
    <Stack direction="row" alignItems="center" justifyContent="start">
      <CheckboxController {...props} />
      {tooltipTitle && (
        <Tooltip title={tooltipTitle} placement="top" arrow>
          <Box display="flex" alignItems="center">
            <InfoIcon />
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
};
