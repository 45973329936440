import { editDepositMutation } from 'api/actions/editDeposit/editDepositActions';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { AppMessages } from 'i18n/messages';

export const useEditDeposit = (workStatementId: string, depositId: string) => {
  const { invalidateBillingSummaryCache } = useProjectBillingSummaryData();
  const { formatMessage } = useLocale();

  return useMutationDomainError(['editDeposit'], editDepositMutation(workStatementId, depositId), {
    onSuccess: () => {
      invalidateBillingSummaryCache();
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.editDeposit.error'] }),
  });
};
