import { Stack } from '@mui/material';

import { ProposalStatus } from 'config/data/need/ProposalStatus.enum';

import { NeedCardFooterProps } from './NeedCardFooter.types';
import { ProposalTab } from './proposalTab/ProposalTab';
import * as styles from './NeedCardFooter.styles';

export const NeedCardFooter = ({ proposals }: NeedCardFooterProps) => {
  const filterProposals = (status: ProposalStatus) => proposals.filter((proposal) => proposal.status === status);

  return (
    <Stack sx={styles.proposalTabsWrapper} className="proposalTabs">
      <ProposalTab proposalStatus={ProposalStatus.pending} proposals={filterProposals(ProposalStatus.pending)} />
      <ProposalTab proposalStatus={ProposalStatus.approved} proposals={filterProposals(ProposalStatus.approved)} />
      <ProposalTab proposalStatus={ProposalStatus.rejected} proposals={filterProposals(ProposalStatus.rejected)} />
    </Stack>
  );
};
