import { FormProvider } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useEditWorkStatement } from 'hooks/useEditWorkStatement/useEditWorkStatement';
import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';
import { workStatementFormSchema } from '../../workStatementFormContent/WorkStatementFormContent.schema';
import { WorkStatementForm } from '../../WorkStatementModals.types';
import { WorkStatementFormContent } from '../../workStatementFormContent/WorkStatementFormContent';
import { areDatesEqual, formatDate } from 'utils/dateUtils';
import { WorkStatementFormType } from '../../workStatementFormContent/WorkStatementFormContent.types';

import { EditWorkStatementContentProps, EditWorkStatementPayload } from './EditWorkStatementContent.types';

const defaultValues: WorkStatementForm = {
  statementNumber: '',
  startDate: null,
  endDate: null,
  billingCycle: 'month',
  paymentPeriod: '',
};

export const EditWorkStatementContent = ({
  onClose,
  useProjectStartDate,
  projectStartDate,
  projectEndDate,
  projectCurrency,
  workStatement,
}: EditWorkStatementContentProps) => {
  const { formatMessage } = useLocale();
  const { mutate, isLoading } = useEditWorkStatement(workStatement.id, workStatement.statementNumber);

  const form = useIntlForm<WorkStatementForm>({
    defaultValues: {
      ...defaultValues,
      statementNumber: workStatement.statementNumber,
      ...(workStatement.startDate && { startDate: new Date(workStatement.startDate) }),
      ...(workStatement.endDate && { endDate: new Date(workStatement.endDate) }),
      ...(workStatement.paymentPeriod &&
        typeof workStatement?.paymentPeriod === 'number' && {
          paymentPeriod: workStatement?.paymentPeriod,
        }),
    },
    mode: 'all',
    resolver: joiResolver(workStatementFormSchema(useProjectStartDate, projectStartDate, projectEndDate)),
  });

  const onSubmit = (data: WorkStatementForm) => {
    const payload: EditWorkStatementPayload = {
      ...(workStatement.statementNumber !== data.statementNumber && { statementNumber: data.statementNumber }),
      ...(data.startDate && !areDatesEqual(data.startDate, new Date(workStatement.startDate))
        ? {
            startDate: formatDate(data.startDate),
          }
        : {}),
      ...(data.endDate && { endDate: formatDate(data.endDate) }),
      ...(data.paymentPeriod && { paymentPeriod: data.paymentPeriod }),
    };

    mutate(payload, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <DialogInnerWrapper
      title={formatMessage({ id: AppMessages['projectDetails.workStatementForm.editWorkStatement.title'] })}
      loading={isLoading}
      onClose={onClose}
      submitText={formatMessage({ id: AppMessages['button.save'] })}
      onSubmit={form.handleSubmit(onSubmit)}
      isFormValid={form.formState.isValid}
    >
      <FormProvider {...form}>
        <WorkStatementFormContent
          type={WorkStatementFormType.edit}
          loading={isLoading}
          projectStartDate={projectStartDate}
          projectEndDate={projectEndDate}
          projectCurrency={projectCurrency}
          useProjectStartDate={useProjectStartDate}
        />
      </FormProvider>
    </DialogInnerWrapper>
  );
};
