import { Styles } from 'lux/theme';

export const warningText: Styles = {
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  color: 'text.primary',
  mb: 2,

  '> .MuiAlert-icon': {
    marginRight: '1rem',
    width: '1.25rem',
    aspectRatio: 1,
  },
};

export const actionButton: Styles = {
  color: 'button.primary',
  width: '200px',
  '&:hover': {
    backgroundColor: 'unset',
  },
};
