import { Stack } from '@mui/material';

import * as styles from '../DashboardGrid.styles';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser';
import { PersonAvailabilityStatus } from 'api/types/PersonAvailabilityStatus.enum';
import { PeopleFiltersContextController } from 'context/peopleFilters/peopleFiltersContextController/PeopleFiltersContextController';
import { DefaultPeopleFilters } from 'context/peopleFilters/peopleFiltersContext/PeopleFiltersContext.types';
import { DashboardCard } from 'ui/dashboardCard/DashboardCard';
import { AppMessages } from 'i18n/messages';
import { filtersData } from 'config/data/people/filters';

import { ResetFiltersButton } from './restFiltersButton/ResetFiltersButton';
import { PeopleSorting } from './peopleSorting/PeopleSorting';
import { PeopleFiltersSection as PeopleFilters } from './peopleFilters/PeopleFilters';
import { PeopleList } from './peopleList/PeopleList';
import { PeopleRedirect } from './peopleRedirect/PeopleRedirect';

export const People = () => {
  const { formatMessage } = useLocale();
  const { data: user } = useCurrentUser();

  const defaultPeopleFilters: DefaultPeopleFilters = {
    status: [PersonAvailabilityStatus.available, PersonAvailabilityStatus.availableSoon],
    includeUnemployed: 'false',
    departments:
      user?.person?.managedDepartments?.filter((department) =>
        filtersData.departments.some((departmentFilter) => departmentFilter.value === department),
      ) ?? [],
  };

  return (
    <PeopleFiltersContextController peopleFilters={defaultPeopleFilters} storageKey="dasboardPeople">
      <DashboardCard
        sx={{ wrapper: styles.cardWrapper }}
        title={formatMessage({ id: AppMessages['dashboard.people.title'] })}
        actions={<PeopleRedirect />}
      >
        <Stack overflow="hidden">
          <Stack>
            <PeopleFilters />
            <Stack flexDirection="row" justifyContent="space-between" marginBottom={2}>
              <ResetFiltersButton />
              <PeopleSorting />
            </Stack>
          </Stack>
          <Stack spacing={2} sx={styles.listWrapper} data-cy="available-people_list">
            <PeopleList />
          </Stack>
        </Stack>
      </DashboardCard>
    </PeopleFiltersContextController>
  );
};
