import { Styles } from 'styles/theme';

export const popper: Styles = {
  zIndex: 1,
  '& > div': {
    position: 'relative',
  },
  '&[data-popper-placement*="bottom"]': {
    '& > div': {
      marginTop: 1.5,
    },
    '& .MuiPopper-arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: (theme) => `transparent transparent ${theme.palette.common.white} transparent`,
      },
    },
  },
  '&[data-popper-placement*="top"]': {
    '& > div': {
      marginBottom: 1.5,
    },
    '& .MuiPopper-arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: (theme) => `${theme.palette.common.white} transparent transparent transparent`,
      },
    },
  },
};

export const contentContainer: Styles = {
  maxHeight: 300,
  minWidth: 300,
  overflow: 'auto',
  backgroundColor: 'common.white',
  boxShadow: (theme) => theme.luxShadows[100],
  paddingY: 0.5,
  borderRadius: 1,
  '&:focus': {
    outline: 'none',
  },
};

export const arrow: Styles = {
  position: 'absolute',
  fontSize: 8,
  width: '3em',
  height: '3em',
  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
  },
};
