import { useEffect } from 'react';

export const useDialogBlockScroll = (isOpen: boolean) => {
  useEffect(() => {
    // Currently disableScrollLock property for Dialog is not working and body provide us scroll behind dialog.
    // To prevent it we need to use little workaround to add class to body when modal is open.
    // Style overide for body-overflow-hidden class is in theme.ts file

    const bodyPreventScrollClass = 'body-overflow-hidden';

    if (isOpen) {
      return document.body.classList.add(bodyPreventScrollClass);
    }

    return document.body.classList.remove(bodyPreventScrollClass);
  }, [isOpen]);
};
