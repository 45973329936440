import { useEditDeposit } from 'hooks/useEditDepositAmount/useEditDepositAmount';
import { TextFieldEdit } from 'ui/form/inlineEdit/textFieldEdit/TextFieldEdit';
import { useBillingSummaryPrice } from 'hooks/useBillingSummaryPrice/useBillingSummaryPrice';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { convertRateToDecimal } from 'utils/convertRate';
import { moneyInputNumberParser } from 'utils/createInputNumberChangeParser';
import { isDepositChangeAllowed } from '../Deposits.utils';

import { DepositAmountProps } from './DepositAmount.types';
import { depositAmountSchema } from './DepositAmount.schema';

export const DepositAmount = ({ wordOrderId, deposit }: DepositAmountProps) => {
  const { formatPrice } = useBillingSummaryPrice();
  const { mutateAsync, isLoading } = useEditDeposit(wordOrderId, deposit.id);
  const { isInvoiceEditBlocked } = useProjectBillingSummaryData();
  const { isReport } = useProjectBillingSummaryData();

  return (
    <TextFieldEdit
      name="amount"
      value={deposit.amount ? formatPrice(deposit.amount) : ''}
      label="projectDetails.billingSummary.workStatementDetails.deposits.amount"
      defaultValues={{ amount: convertRateToDecimal(deposit.amount) }}
      onSubmit={(value) => mutateAsync({ amount: value.amount })}
      loading={isLoading}
      sendAsInt
      schema={depositAmountSchema}
      onChangeParser={moneyInputNumberParser}
      disabled={!isDepositChangeAllowed(deposit.status) || isInvoiceEditBlocked || isReport}
    />
  );
};
