import { Box } from '@mui/material';

import { ProjectCardSkeleton } from './projectCard/ProjectCard.skeleton';
import * as styles from './ProjectsList.styles';

export const ProjectsListSkeleton = () => (
  <Box sx={styles.projectsListWrapper}>
    {Array(6)
      .fill(null)
      .map((_, index) => (
        <ProjectCardSkeleton key={index} />
      ))}
  </Box>
);
