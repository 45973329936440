import { Button } from 'lux/components';
import { AddIcon } from 'lux/icons';

import { SquareIconButton } from 'ui/squareIconButton/SquareIconButton';

import { AddLinkButtonProps } from './AddLinkButton.types';

export const AddLinkButton = ({ showExtended, disabled, onClick, message }: AddLinkButtonProps) => (
  <>
    {showExtended ? (
      <Button startIcon={<AddIcon />} size="small" variant="outlinedGray" onClick={onClick} disabled={disabled}>
        {message}
      </Button>
    ) : (
      <SquareIconButton size="small" variant="outlinedGray" onClick={onClick} disabled={disabled}>
        <AddIcon />
      </SquareIconButton>
    )}
  </>
);
