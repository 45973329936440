import type { Styles } from 'styles/theme';

export const customDialog = (isVisible: boolean): Styles => ({
  '.MuiDialog-paper': {
    visibility: isVisible ? 'visible' : 'hidden',
  },

  '.MuiDialogContent-root': {
    paddingBottom: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: 3,

    '&.MuiDialogContent-root.MuiDialogContent-root': {
      paddingTop: 0,
    },

    '> *': {
      flexShrink: 0,
    },
  },
});
