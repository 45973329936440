import { useCallback, useEffect, useState, ChangeEvent } from 'react';

import { debounce } from '@mui/material';

import { TextField } from 'ui/textField/TextField';

import { DebouncedTextFieldProps } from './DebouncedTextField.types';

export const DebouncedTextField = ({
  onChange,
  defaultValue,
  delay = 1000,
  value,
  ...props
}: DebouncedTextFieldProps) => {
  const [internalValue, setInternalValue] = useState(defaultValue);

  const handleValueChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInternalValue(event.target.value);
    handleChangeDebounced(event);
  };

  const handleChangeDebounced = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.target.value === value) {
        return;
      }
      onChange(event);
    }, delay),
    [onChange],
  );

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }
    return () => handleChangeDebounced.clear();
  }, [value]);

  return <TextField value={internalValue} {...props} onChange={handleValueChange} />;
};
