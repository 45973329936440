import {
  add,
  addDays,
  differenceInCalendarDays,
  endOfDay,
  endOfMonth,
  format,
  isFuture,
  startOfMonth,
  sub,
  subDays,
  isBefore,
  startOfToday,
  eachDayOfInterval,
  isAfter,
  isValid,
  isToday,
  isPast,
  areIntervalsOverlapping,
  getDate,
  isWeekend,
  isEqual,
  getTime,
  getDaysInMonth as getDaysInMonthFns,
  isSameDay,
  startOfDay as startOfDayFns,
  parse,
  parseISO,
  isWithinInterval,
  compareDesc,
  isSameMonth,
  addMonths,
  compareAsc,
  differenceInMonths,
  getYear,
  differenceInCalendarMonths,
} from 'date-fns';
import type { Interval } from 'date-fns';

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

export const DATE_WITH_HOURS_FORMAT = 'yyyy-MM-dd HH:mm';

export const DATE_NEED_CARD_FORMAT = 'yyyy/MM/dd';

export const ZERO_UNIX_DATE = new Date(0);

export const MAX_SUPPORTED_DATE = new Date('2099-12-31');

// After below date we start generating billing cycles
export const START_OF_BILLING_CYCLE_FEATURE = new Date('2024-01-01');

/**
 * @description converts YYYY-MM-DD string format to valid date
 */
export const parseDate = (date: string) => parse(date, DEFAULT_DATE_FORMAT, new Date());

export const parseISODate = (date: string) => parseISO(date);

export const parseDateToEndOfDay = (date: string) => endOfDay(parseDate(date));

export const formatDate = (date: Date | string, dateFormat = DEFAULT_DATE_FORMAT) => {
  if (typeof date === 'string') {
    return format(parseDate(date), dateFormat);
  }

  return format(date, dateFormat);
};

export const needFormatDate = (date: Date, dateFormat = DATE_NEED_CARD_FORMAT) => format(date, dateFormat);

export const getDayOfTheMonth = (date: Date): number => getDate(date);

export const addDaysToDate = (initialDate: Date, numberOfDays: number) => addDays(initialDate, numberOfDays);

export const subtractDaysFromDate = (initialDate: Date, numberOfDays: number) => subDays(initialDate, numberOfDays);

export const addMonthToDate = (date: Date): Date => add(date, { months: 1 });

export const addMonthsToDate = (date: Date, numberOfMonths: number) => addMonths(date, numberOfMonths);

export const subtractMonthFromDate = (date: Date): Date => sub(date, { months: 1 });

export const getEndOfDayDate = (date: Date): Date => endOfDay(date);

export const getStartOfMonthDate = (date: Date): Date => startOfMonth(date);

export const getEndOfMonthDate = (date: Date): Date => endOfMonth(date);

export const getNumberOfCalendarDaysBetweenDates = (laterDate: Date, earlierDate: Date): number =>
  differenceInCalendarDays(laterDate, earlierDate);

export const getNumberOfCalendarMonthsBetweenDates = (laterDate: Date, earlierDate: Date): number =>
  differenceInCalendarMonths(laterDate, earlierDate);

export const isFutureDate = (date: Date) => isFuture(date);

export const isPastDate = (date: Date) => isPast(date);

export const isPastOrPresentDate = (dateToCompare: Date, actualDate?: Date) => {
  const parsedActualDate = actualDate ? actualDate : new Date();

  return isBefore(dateToCompare, parsedActualDate) || isSameDay(dateToCompare, parsedActualDate);
};

export const isDateBefore = (date: Date, dateToCompare: Date): boolean => isBefore(date, dateToCompare);

export const isCurrentDate = (date: Date): boolean => isToday(date);

export const isWeekendDate = (date: Date): boolean => isWeekend(date);

export const getDaysInMonth = (date: number | Date): number => getDaysInMonthFns(date);

export const isDateAfter = (date: Date, dateToCompare: Date): boolean => isAfter(date, dateToCompare);

export const isDateBetween = (date: Date, interval: Interval) => isWithinInterval(date, interval);

export const isDateInThePast = (date: Date): boolean => isPast(date);

export const areDatesEqual = (date: Date, dateToCompare: Date): boolean => isEqual(date, dateToCompare);

export const getCurrentDayStartDate = (): Date => startOfToday();

export const isDateInSameMonth = (date: Date, dateToCompare: Date): boolean => isSameMonth(date, dateToCompare);

export const getArrayWithDatesInMonth = (date: Date): Date[] => {
  const startOfMonthDate = getStartOfMonthDate(date);
  const endOfMonthDate = getEndOfMonthDate(date);

  return eachDayOfInterval({
    start: startOfMonthDate,
    end: endOfMonthDate,
  });
};

export const isValidDate = (date: Date): boolean => isValid(date);

export const areTimeIntervalsOverlapping = (firstInterval: Interval, secondInterval: Interval): boolean =>
  areIntervalsOverlapping(firstInterval, secondInterval);

export const getDateTimestamp = (date: Date): number => getTime(date);

export const areDatesInTheSameDay = (firstDate: Date, secondDate: Date): boolean => isSameDay(firstDate, secondDate);

export const startOfDay = (date: number | Date) => startOfDayFns(date);

export const compareDatesDesc = (left?: Date | null, right?: Date | null) =>
  compareDesc(left ?? MAX_SUPPORTED_DATE, right ?? MAX_SUPPORTED_DATE);

export const compareDatesAsc = (left?: Date | null, right?: Date | null) =>
  compareAsc(left ?? MAX_SUPPORTED_DATE, right ?? MAX_SUPPORTED_DATE);

export const getMonthsDifference = (date: Date, dateToCompare: Date): number =>
  Math.abs(differenceInMonths(date, dateToCompare));

export const getMonthName = (date: Date | number) => format(new Date(date), 'LLLL');

export const getDateYear = (date: Date | number) => getYear(date);
