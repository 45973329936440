import { createTheme } from '@mui/material';
import { componentsLibTheme, Styles as LuxStyles } from 'lux/theme';

import { dialog } from './componentOverrides/dialog';

export const theme = createTheme(componentsLibTheme, {
  topBarHeight: 8,
  components: {
    MuiCssBaseline: {
      styleOverrides: () => ({
        body: {
          '&.body-overflow-hidden': {
            overflow: 'hidden',
          },
        },
      }),
    },
    ...dialog,
  },
});

export type Styles = LuxStyles<typeof theme>;
