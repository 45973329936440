import { Table } from 'lux/components';

import * as styles from '../PlanAssignmentExtensionTable.styles';

import { PlanAssignmentExtensionTableCellProps } from './PlanAssignmentExtensionTableCell.types';

export const PlanAssignmentExtensionTableCell = ({
  children,
  isSelected,
  width,
}: PlanAssignmentExtensionTableCellProps) => (
  <Table.Cell sx={styles.tableCell(isSelected)} width={width}>
    {children}
  </Table.Cell>
);
