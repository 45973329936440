import { useState } from 'react';

import { Box } from '@mui/material';
import { Button, Tabs } from 'lux/components';

import { usePersonData } from 'hooks/usePersonData/usePersonData';
import { useRejections } from 'hooks/useRejections/useRejections';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { DashboardCard } from 'ui/dashboardCard/DashboardCard';
import { SortOrder } from 'utils/filters/filters.types';

import * as styles from './PersonHistory.styles';
import { PersonRejection } from './personRejection/PersonRejection';

const PAGE_SIZE = 10;
const INITIAL_PAGE_SIZE = 3;

export const PersonHistory = () => {
  const { formatMessage } = useLocale();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [isFirstPageExpanded, setFirstPageExpanded] = useState(false);
  const { person } = usePersonData();
  const {
    data: rejectionsData,
    isLoading: isLoadingRejections,
    hasNextPage,
    fetchNextPage,
  } = useRejections({
    person: String(person.employeeId),
    size: PAGE_SIZE,
    'sort[rejectionDate]': SortOrder.desc,
  });

  const personRejections = rejectionsData?.pages.flatMap((page) => page.rejections) || [];
  const shouldSliceRejectionList = !isFirstPageExpanded && personRejections.length > INITIAL_PAGE_SIZE;
  const rejections = shouldSliceRejectionList ? personRejections.slice(0, INITIAL_PAGE_SIZE) : personRejections;

  const handleTabIndex = (e: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  // TODO: change this condition when working on "projects" tab. We might want to keep the history section on the screen but manage visibility of the particular tabs.
  if (rejections?.length === 0) {
    return null;
  }

  return (
    <DashboardCard title={formatMessage({ id: AppMessages['personDetails.history.title'] })}>
      <Tabs>
        <Tabs.TabList value={activeTabIndex} onChange={handleTabIndex} variant="fullWidth">
          {rejections.length > 0 && (
            <Tabs.Tab label={formatMessage({ id: AppMessages['personDetails.history.rejections'] })} />
          )}
        </Tabs.TabList>
        <Box sx={styles.tabPanelWrapper}>
          {rejections.length > 0 && (
            <Tabs.TabPanel value={activeTabIndex} index={0}>
              <>
                {rejections.map((rejection) => (
                  <PersonRejection key={rejection.id} rejection={rejection} />
                ))}

                {(shouldSliceRejectionList || hasNextPage) && (
                  <Button
                    onClick={() => {
                      if (!isFirstPageExpanded) {
                        return setFirstPageExpanded(true);
                      }

                      fetchNextPage();
                    }}
                    disabled={isLoadingRejections}
                    size="small"
                    variant="text"
                    color="primary"
                    sx={styles.showMoreButton}
                  >
                    {formatMessage({ id: AppMessages['personDetails.history.showMore'] })}
                  </Button>
                )}
              </>
            </Tabs.TabPanel>
          )}
        </Box>
      </Tabs>
    </DashboardCard>
  );
};
