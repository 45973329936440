import { AssignmentsFiltersContext } from '../assignmentsFiltersContext/AssignmentsFiltersContext';
import {
  AssignmentsFilters,
  AssignmentsSortingField,
} from '../assignmentsFiltersContext/AssignmentsFiltersContext.types';
import { useFilters } from 'hooks/useFilters/useFilters';
import { generateSortFilter } from 'utils/filters/filters';

import { AssignmentsFiltersContextControllerProps } from './AssignmentsFiltersContextController.types';

export const assignmentsSortingOptions = generateSortFilter(AssignmentsSortingField);

const defaultInitialFilters: AssignmentsFilters = {
  owner: '',
  sort: assignmentsSortingOptions.endDateDESC,
  seniorities: [],
  roles: [],
  status: [],
  fte: '',
  startDate: '',
  endDate: '',
};

export const AssignmentsFiltersContextController = ({
  children,
  initialFilters,
  withParams,
}: AssignmentsFiltersContextControllerProps) => {
  const contextValues = useFilters<AssignmentsFilters>(
    {
      ...defaultInitialFilters,
      ...initialFilters,
    },
    { withParams },
  );

  return <AssignmentsFiltersContext.Provider value={contextValues}>{children}</AssignmentsFiltersContext.Provider>;
};
