import { Box, Stack } from '@mui/material';
import { CardHeader } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AssignmentsFiltersContextController } from 'context/assignmentsFilters/assignmentsFiltersContextController/AssignmentsFiltersContextController';
import { AssignmentsActiveFilters } from 'shared/assignments/assignmentsFilers/assignmentsActiveFilters/AssignmentsActiveFilters';
import { CardWrapper } from 'ui/cardWrapper/CardWrapper';

import { AssignmentsFilters } from './assignmentFilters/AssignmentFilters';
import { AssignmentsList } from './assignmentList/AssignmentList';
import * as styles from './AssignmentHistory.styles';

export const AssignmentHistory = () => {
  const { t } = useLocale();

  return (
    <CardWrapper>
      <CardHeader title={t('projectDetails.assignmentHistory.header')} />
      <AssignmentsFiltersContextController>
        <Stack gap={3}>
          <AssignmentsFilters />
          <Box sx={styles.activeFilterWrapper}>
            <AssignmentsActiveFilters />
          </Box>
          <AssignmentsList />
        </Stack>
      </AssignmentsFiltersContextController>
    </CardWrapper>
  );
};
