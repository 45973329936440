import { QueryFn } from 'hooks/useQuery/useQuery.types';

import {
  GetLastGeneratedBillingCycleParams,
  GetLastGeneratedBillingCycleResponse,
} from './getLastGeneratedBillingCycle.types';

export const getLastGeneratedBillingCycle =
  (projectId: string): QueryFn<GetLastGeneratedBillingCycleParams, GetLastGeneratedBillingCycleResponse> =>
  (args) => ({
    endpoint: `/billing-cycles/project/${projectId}/last-generated`,
    args,
  });
