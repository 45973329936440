import { Components, Theme } from '@mui/material';

export const dialog: Components<Theme> = {
  MuiDialog: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          '& .MuiBackdrop-root': {
            backgroundColor: 'surface.backdropOverlay',
          },
        }),
      paper: {
        borderRadius: 8,
        backgroundColor: '#fff',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          paddingTop: 3.5,
          paddingBottom: 0,
          paddingX: 3,
          ...theme.typography.h5,

          '&.MuiDialogTitle-root + .MuiDialogContent-root': {
            paddingTop: 3.5,
          },
        }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          paddingBottom: 0,
        }),
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          padding: 3,
        }),
    },
  },
};
