import { useEffect } from 'react';

import { Box } from '@mui/material';
import { Typography } from 'lux/components';

import { useAssignments } from 'hooks/useAssignments/useAssignments';
import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';
import { mapAssignmentData } from 'shared/assignments/Assignments.functions';

import { getRolesStats } from './getRolesStats';
import { StatsItemsProps } from './StatsItems.types';
import * as styles from './StatsItems.styles';

export const StatsItems = ({ needs, projectId }: StatsItemsProps) => {
  const { data, isFetchingNextPage, fetchNextPage, hasNextPage } = useAssignments({
    project: projectId,
    size: 200,
    status: [
      AssignmentStatus.active,
      AssignmentStatus.leaving,
      AssignmentStatus.temporary,
      AssignmentStatus.preassigned,
    ],
  });

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, data]);

  const assignments = mapAssignmentData(data?.pages) || [];

  const stats = getRolesStats(needs, assignments);

  return (
    <Box sx={styles.statsItems}>
      {stats.map((item, i) => (
        <Typography
          key={`${item.role}-${i}`}
          variant="caption"
        >{`${item.role} ${item.assignedCount}/${item.totalCount}`}</Typography>
      ))}
    </Box>
  );
};
