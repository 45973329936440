import { Styles } from 'styles/theme';

export const avatar: Styles = {
  width: 40,
  height: 40,
  marginRight: 1,
};

export const accordion: Styles = {
  backgroundColor: 'surface.paper.default',
  borderColor: 'surface.paper.default',

  '.MuiAccordionSummary-expandIconWrapper > svg > path': {
    fill: (theme) => `${theme.palette.text.secondary}`,
  },

  '.MuiAccordionDetails-root': {
    marginTop: 0,
    borderTop: 0,
  },

  '&:not(:last-of-type)': {
    marginBottom: 2,
  },
};

export const accordionSummary: Styles = {
  '.MuiAccordionSummary-content': {
    flexDirection: 'column',
    marginRight: 2,
    alignItems: 'flex-start',
  },

  '.MuiAccordionSummary-content.Mui-expanded': {
    marginRight: 2,
  },
};

export const assignmentName: Styles = {
  fontWeight: 500,
  marginBottom: 1,
};

export const accordionDetails: Styles = {
  '>.MuiBox-root': {
    marginBottom: 2,
  },

  backgroundColor: (theme) => theme.palette.common.white,
  padding: 3,
  borderRadius: 1,
  marginTop: 0,
  marginBottom: 2,

  '&:first-of-type': {
    border: 'none',
  },
};

export const accordionHeaderWrapper: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const headerContent: Styles = {
  width: '100%',
};

export const accordionHeaderProfileDetails: Styles = {
  display: 'flex',
  flexDirection: 'column',
};

export const personLink: Styles = {
  textDecoration: 'none',
};

export const expandIcon: Styles = {
  '&.MuiSvgIcon-root path': {
    fill: (theme) => theme.palette.text.primary,
  },
};

export const badgesContainer: Styles = {
  display: 'flex',
  gap: 1,
  marginLeft: 'auto',
};

export const accordionItemHeader: Styles = {
  mr: 1,
};
