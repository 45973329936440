import { ReactNode } from 'react';

import { Table } from 'lux/components';

import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

import * as styles from './ComparisonRow.styles';

export const ComparisonRowSkeleton = ({ needCell, personCell }: { needCell: ReactNode; personCell: ReactNode }) => (
  <Table.Row sx={styles.comparisonRow(false)}>
    <Table.Cell sx={styles.tableRowHeader}>
      <TextSkeleton width={88} variant="caption" />
    </Table.Cell>
    <Table.Cell sx={styles.comparisonTableCell()}>{needCell}</Table.Cell>
    <Table.Cell sx={styles.comparisonTableCell()}>{personCell}</Table.Cell>
    <Table.Cell sx={styles.comparisonTableCell(true)}></Table.Cell>
  </Table.Row>
);
