import { Styles } from 'styles/theme';

export const header: Styles = {
  padding: 1,
  borderRadius: 1,
};

export const personContainer: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  borderRadius: 1,

  '&.MuiBox-root': {
    marginY: 0.5,
    padding: 1,
  },
};
