import { stringify } from 'qs';
import { Link } from 'react-router-dom';

import { DashboardCard } from 'ui/dashboardCard/DashboardCard';
import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { AppRoute } from 'routing/AppRoute.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const PeopleRedirect = () => {
  const { filters } = usePeopleFilters();
  const { formatMessage } = useLocale();

  return (
    <DashboardCard.Button component={Link} to={`${AppRoute.people}?${stringify(filters)}`}>
      {formatMessage({ id: AppMessages['dashboard.seeAll'] })}
    </DashboardCard.Button>
  );
};
