import { Styles, theme } from 'styles/theme';

export const gridContainer: Styles = {
  paddingX: 6,
  paddingTop: 5,
};

export const stepperHeader: Styles = {
  marginTop: 1,
  marginBottom: 5,
};

export const container: Styles = {
  rowGap: 3,
};

export const formHeader: Styles = {
  marginTop: 2.25,
};

export const formElement: Styles = {
  marginTop: 5,
  label: {
    left: -7,
  },
  fieldset: {
    padding: '0px 3px',

    legend: {
      paddingRight: 0.1,

      span: {
        padding: '0 8px',
      },
    },
  },
};

export const addRoleButton: Styles = {
  marginTop: 2,
};

export const card: Styles = {
  '&': {
    border: `1px solid ${theme.palette.border.default}`,
    borderRadius: '12px',
    p: 2,
  },
};

export const cardHeader: Styles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const role: Styles = {
  flex: 1,
};

export const backBtn: Styles = {
  '&.MuiButton-root': {
    paddingX: 1,
  },
};

export const message: Styles = {
  marginTop: 3.5,
  marginBottom: 3.5,
};

export const selectWithChips: Styles = {
  '& .MuiSelect-select': {
    padding: 1,
  },
};
