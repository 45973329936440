import { Box, SxProps } from '@mui/system';
import { Typography } from '@mui/material';

import { DetailsGridItem } from '../detailsGrid/detailsGridItem/DetailsGridItem';
import { DetailsGrid } from '../detailsGrid/DetailsGrid';
import { Translation } from 'ui/translation/Translation';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { hideInPdf } from 'app/project/projectReport/ProjectReport.styles';
import { composeStyles } from 'utils/composeStyles';

import { DepositStatusSelect } from './depositStatusSelect/DepositStatusSelect';
import { DepositsProps } from './Deposits.types';
import { DepositDateEdit } from './depositDateEdit/DepositDateEdit';
import { DepositAmount } from './depositAmount/DepositAmount';
import { AddDeposit } from './addDeposit/AddDeposit';
import * as styles from './Deposits.styles';

export const Deposits = ({ workStatement, deposits }: DepositsProps) => {
  const { isReport } = useProjectBillingSummaryData();

  const containerStyles = composeStyles([styles.container, !deposits.length && hideInPdf]);

  return (
    <Box sx={containerStyles as SxProps}>
      <Typography variant="subtitle2" mb={2}>
        <Translation id="projectDetails.billingSummary.workStatementDetails.deposits" />
      </Typography>
      {deposits.map((deposit) => (
        <DetailsGrid key={deposit.id}>
          <DetailsGridItem sx={styles.upperDepositItem}>
            <DepositAmount wordOrderId={workStatement.id} deposit={deposit} />
          </DetailsGridItem>
          <DetailsGridItem sx={styles.upperDepositItem}>
            <DepositDateEdit workStatement={workStatement} deposit={deposit} />
          </DetailsGridItem>
          <DetailsGridItem span={2} sx={styles.bottomDepositItem}>
            <DepositStatusSelect workStatementId={workStatement.id} deposit={deposit} />
          </DetailsGridItem>
        </DetailsGrid>
      ))}
      {!isReport && <AddDeposit workStatement={workStatement} />}
    </Box>
  );
};
