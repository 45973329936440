import { Grid } from 'lux/components';

import { DashboardCard } from 'ui/dashboardCard/DashboardCard';
import { PeopleList } from '../peopleList/PeopleList';
import { PeopleListTopbar } from '../peopleListTopbar/PeopleListTopbar';
import { PeopleAccordionFilters } from '../peopleAccordionFilters/PeopleAccordionFilters';

import * as styles from './PeopleGrid.styles';

export const PeopleGrid = () => (
  <Grid container sx={styles.peopleGridWrapper} overflow="hidden" gutter={0}>
    <Grid overflow="hidden" display="flex" height="100%" flexDirection="column" item xs={4}>
      <DashboardCard
        sx={{
          wrapper: styles.filtersDashboardCardWrapper,
        }}
      >
        <PeopleAccordionFilters />
      </DashboardCard>
    </Grid>

    <Grid item xs={8} display="flex" flexDirection="column">
      <DashboardCard sx={{ wrapper: styles.listDashboardCardWrapper }}>
        <PeopleListTopbar />
        <PeopleList />
      </DashboardCard>
    </Grid>
  </Grid>
);
