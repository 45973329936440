import { useApiVersion } from 'hooks/useApiVersion/useApiVersion';
import { ApiVersionContext } from '../apiVersionContext/ApiVersionContext';

import { ApiVersionControllerProps } from './ApiVersionContextController.types';

export const ApiVersionContextController = ({ children }: ApiVersionControllerProps) => {
  useApiVersion();

  return <ApiVersionContext.Provider value={{}}>{children}</ApiVersionContext.Provider>;
};
