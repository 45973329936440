import { Stack } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useRejections } from 'hooks/useRejections/useRejections';
import { AppMessages } from 'i18n/messages';
import { EmployeeCard } from 'app/employee/card/EmployeeCard';
import { EmptyState } from 'ui/emptyState/EmptyState';
import { Loader } from 'ui/loader/Loader';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll/useInfiniteScroll';
import { parseDate } from 'utils/dateUtils';
import { RejectionSource } from 'config/data/rejection/RejectionSource.enum';
import { ProposalStatus } from 'config/data/need/ProposalStatus.enum';

import { TabRejectedPanelSkeleton } from './TabRejectedPanel.skeleton';
import { TabRejectedPanelProps } from './TabRejected.types';

export const TabRejectedPanel = ({ projectId }: TabRejectedPanelProps) => {
  const { formatMessage } = useLocale();

  const { ref, queryMethods } = useInfiniteScroll(
    useRejections({
      project: projectId,
      source: [RejectionSource.proposal],
    }),
  );
  const { data, isLoading, isFetchingNextPage } = queryMethods;

  const rejections = data?.pages.flatMap((page) => page.rejections);

  if (isLoading) {
    return <TabRejectedPanelSkeleton />;
  }

  return (
    <>
      {!rejections?.length ? (
        <EmptyState
          illustration="searchPeople"
          title={formatMessage({ id: AppMessages['projectDetails.teamDetails.rejected.empty.title'] })}
          message={formatMessage({ id: AppMessages['projectDetails.teamDetails.rejected.empty.subtitle'] })}
        />
      ) : (
        <Stack spacing={3}>
          {rejections.map((rejection, index) => (
            <EmployeeCard
              key={rejection.id}
              ref={index === rejections.length - 1 ? ref : null}
              employee={rejection.person}
              status={ProposalStatus.rejected}
              reason={rejection.reason}
              rejectionDate={parseDate(rejection.rejectionDate)}
              rejectionSource={rejection.source}
            />
          ))}
          {isFetchingNextPage && <Loader fullHeight={false} />}
        </Stack>
      )}
    </>
  );
};
