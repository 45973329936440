import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { UpdateProjectParams } from './updateProjectActions.types';

export const updateProjectMutation: (projectId: string) => MutationFn<UpdateProjectParams> =
  (projectId: string) => (body) => ({
    endpoint: `/projects/${projectId}/update-project`,
    method: 'PATCH',
    params: body,
  });
