import { joiResolver } from '@hookform/resolvers/joi';
import { Typography, InlineEdit } from 'lux/components';
import { FieldValues } from 'react-hook-form';
import { ErrorFilledIcon } from 'lux/icons';
import { Box } from '@mui/material';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useEditMode } from 'hooks/useEditMode/useEditMode';

import * as styles from './TextFieldEdit.styles';
import { TextFieldEditProps } from './TextFieldEdit.types';
import { defaultTextFieldEditSchema } from './TextFieldEditSchema';

export const TextFieldEdit = <T extends FieldValues>(props: TextFieldEditProps<T>) => {
  const {
    name,
    type,
    label,
    value,
    defaultValues,
    onSubmit,
    loading,
    adornment = '',
    required,
    disabled,
    schema,
    onKeyDown,
    onChangeParser,
    sendAsInt,
    showErrorIcon,
  } = props;

  const { formatMessage } = useLocale();
  const { inputRef, isEditMode, setOpenEditMode, setCloseEditMode, onFormSubmit } = useEditMode(
    onSubmit,
    disabled,
    sendAsInt,
  );
  const { control, handleSubmit } = useIntlForm({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(schema || defaultTextFieldEditSchema(name, required)),
  });

  return (
    <InlineEdit
      header={
        <Box sx={styles.header}>
          {formatMessage({ id: AppMessages[label] })}
          {showErrorIcon && !isEditMode && <ErrorFilledIcon fontSize="small" color="error" />}
        </Box>
      }
      isActive={isEditMode}
      onDataDisplayClick={setOpenEditMode}
      onCancel={setCloseEditMode}
      onSubmit={handleSubmit(onFormSubmit)}
      loading={loading}
      disabled={disabled}
      input={
        <FormTextField
          name={name}
          type={type}
          control={control}
          label={formatMessage({ id: AppMessages[label] })}
          inputRef={inputRef}
          size="small"
          autoFocus
          fullWidth
          onKeyDown={onKeyDown}
          onChangeParser={onChangeParser}
          min={props.min}
          max={props.max}
          maxLength={props.maxLength}
          startAdornment={adornment}
          endAdornment={showErrorIcon && <ErrorFilledIcon fontSize="small" color="error" />}
        />
      }
    >
      <Typography variant="caption" sx={styles.editModeTypography}>
        {value ? `${value} ${adornment}` : '-'}
      </Typography>
    </InlineEdit>
  );
};
