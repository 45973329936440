import { GetIndustriesParams, GetIndustriesResponse } from 'api/actions/getIndustries/getIndustries.types';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { dictionariesKeys } from 'utils/queryKeys';
import { getProjectRolesQuery } from '../../api/actions/getProjectRoles/getProjectRoles';

export const useProjectRoles = () => {
  const { isAuthenticated } = useAuth();

  return useQuery<GetIndustriesParams, GetIndustriesResponse>(dictionariesKeys.projectRolesList, getProjectRolesQuery, {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });
};
