import { IPublicClientApplication } from '@azure/msal-browser';

export const msalAccountKey = `msal.account.keys`;
export const msalActiveAccountKey = `msal.${process.env.REACT_APP_ACTIVE_DIRECTORY_CLIENT_ID}.active-account`;

export const getAccountLocalIdFromStorage = () => {
  const msalActiveAccount = localStorage.getItem(msalActiveAccountKey);

  if (msalActiveAccount) {
    return msalActiveAccount;
  }

  return getAccountLocalIdFromMsalKeys();
};

export const getAccountLocalIdFromMsalKeys = () => {
  const msalKeys = localStorage.getItem(msalAccountKey);

  if (!msalKeys) {
    return null;
  }

  const localAccountId = JSON.parse(msalKeys) as string[];
  if (!localAccountId.length) {
    return null;
  }

  return localAccountId[0].split('.')[0];
};

export const getActiveAccount = (instance: IPublicClientApplication, personEmail?: string) =>
  instance.getActiveAccount() ??
  instance.getAccountByUsername(personEmail ?? '') ??
  instance.getAccountByLocalId(getAccountLocalIdFromStorage() ?? '');
