import { Box } from '@mui/material';
import { Table, Typography } from 'lux/components';

import { createDepartmentLabel, getDepartmentIcon } from 'config/data/departments';
import { composeStyles } from 'utils/composeStyles';

import { AvailabilityTableCell } from './availabilityTableCell/AvailabilityTableCell';
import * as styles from './AvailabilityTableRow.styles';
import { AvailabilityTableRowProps } from './AvailabilityTableRow.types';

export const AvailabilityTableRow = ({
  values,
  department,
  includeProposed,
  includeExtension,
  splitBySeniority,
  showSeniorityHeaders,
  showDepartmentIcon = true,
}: AvailabilityTableRowProps) => {
  const Icon = getDepartmentIcon(department) ?? getDepartmentIcon('DEFAULT');

  return (
    <Table.Row sx={composeStyles([styles.row, splitBySeniority && styles.splitValuesRow])}>
      <Table.Cell sx={styles.departmentColumn} align="left">
        <Box sx={styles.tableCellWrapper}>
          {showDepartmentIcon && <Icon />}
          <Typography variant="body1" sx={styles.typographyHead}>
            {createDepartmentLabel(department)}
          </Typography>
        </Box>
      </Table.Cell>
      {values.map((dataByPeriod) => (
        <AvailabilityTableCell
          key={dataByPeriod.from}
          department={department}
          period={dataByPeriod.from}
          includeProposed={includeProposed}
          includeExtension={includeExtension}
          filterBySeniority={splitBySeniority}
          data={dataByPeriod}
          showSeniorityHeaders={showSeniorityHeaders}
        />
      ))}
    </Table.Row>
  );
};
