import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { AddUncertainPersonRequest, AddUncertainPersonResponse } from './addUncertainPersonActions.types';

export const addUncertainPersonMutation: () => MutationFn<AddUncertainPersonRequest, AddUncertainPersonResponse> =
  () => (body) => ({
    endpoint: `/people/add-uncertain`,
    method: 'POST',
    params: body,
  });
