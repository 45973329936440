import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { createProjectMutation } from 'api/actions/createProject/createProjectActions';
import { projectsKeys, dictionariesKeys } from 'utils/queryKeys';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

export const useCreateProject = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();

  return useMutationDomainError(['createProjectMutation'], createProjectMutation(), {
    onSuccess: (data: { leadId: string }) => {
      navigate(`/projects/${data.leadId}`);
      queryClient.invalidateQueries(projectsKeys.projectsList);
      queryClient.invalidateQueries(dictionariesKeys.tagsList);
      queryClient.invalidateQueries(dictionariesKeys.specializationsList);
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.createProject.error'] }),
  });
};
