import { Styles } from 'styles/theme';

export const button: Styles = {
  minWidth: 0,

  '& path': {
    fill: 'currentcolor',
  },

  '&.MuiButton-sizeSmall': {
    padding: 1,
  },

  '&.MuiButton-sizeMedium': {
    padding: 1.5,
  },

  '&.MuiButton-sizeLarge': {
    padding: 2,
  },
};
