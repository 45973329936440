import { Stack } from '@mui/material';
import { Typography, TextField, DateTimePicker } from 'lux/components';

import { DEFAULT_DATE_FORMAT, MAX_SUPPORTED_DATE, ZERO_UNIX_DATE, parseDate } from 'utils/dateUtils';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { Filter } from 'ui/filter/Filter';
import { useAvailabilityRange } from 'hooks/useAvailabilityRange/useAvailabilityRange';

export const AvailabilityFilter = () => {
  const { formatMessage } = useLocale();

  const { filters, setFilterValue, clearFilter } = usePeopleFilters();
  const { values, onDateChange, errors } = useAvailabilityRange({ filters, setFilter: setFilterValue, clearFilter });

  return (
    <Filter variant="accordion" label={formatMessage({ id: AppMessages['people.filters.availability.label'] })}>
      <Stack direction="row" alignItems="center" spacing={0.5} maxWidth={470} width="100%">
        <DateTimePicker
          label={formatMessage({ id: AppMessages['filters.availability.from'] })}
          value={values.availabilityFrom || null}
          views={['day']}
          onChange={(newValue) => onDateChange(newValue, 'availabilityFrom')}
          minDate={ZERO_UNIX_DATE}
          maxDate={MAX_SUPPORTED_DATE}
          InputProps={{ error: errors.availabilityFrom }}
          renderInput={(params) => (
            <TextField fullWidth={true} {...params} size="small" error={errors.availabilityFrom} />
          )}
          inputFormat={DEFAULT_DATE_FORMAT}
          shouldDisableDate={(date) => {
            if (filters.availabilityTo) {
              return new Date(date).getTime() > parseDate(filters.availabilityTo).getTime();
            }
            return false;
          }}
        />
        <Typography color="text.secondary">-</Typography>
        <DateTimePicker
          label={formatMessage({ id: AppMessages['filters.availability.to'] })}
          value={values.availabilityTo}
          views={['day']}
          minDate={ZERO_UNIX_DATE}
          maxDate={MAX_SUPPORTED_DATE}
          InputProps={{ error: errors.availabilityTo }}
          onChange={(newValue) => onDateChange(newValue, 'availabilityTo')}
          renderInput={(params) => (
            <TextField fullWidth={true} {...params} size="small" error={errors.availabilityTo} />
          )}
          inputFormat={DEFAULT_DATE_FORMAT}
          shouldDisableDate={(date) => {
            if (filters.availabilityFrom) {
              return new Date(date).getTime() < parseDate(filters.availabilityFrom).getTime();
            }
            return false;
          }}
        />
      </Stack>
    </Filter>
  );
};
