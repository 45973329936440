import { useEffect, useRef, useState } from 'react';

import { joiResolver } from '@hookform/resolvers/joi';
import { Box } from '@mui/material';
import { Typography, InlineEdit, InlineEditDataDisplay } from 'lux/components';
import { FieldValues } from 'react-hook-form';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useEditMode } from 'hooks/useEditMode/useEditMode';
import { dashIfEmpty } from 'utils/stringUtils';
import { composeStyles } from 'utils/composeStyles';

import { TextAreaEditProps } from './TextAreaEdit.types';
import { defaultTextAreaEditSchema } from './TextAreaEdit.schema';
import * as styles from './TextAreaEdit.styles';
import { ShowMoreButton } from './showMoreButton/ShowMoreButton';

export const TextAreaEdit = <T extends FieldValues>(props: TextAreaEditProps<T>) => {
  const {
    name,
    label,
    value,
    defaultValues,
    onSubmit,
    loading,
    disabled,
    required,
    enableTruncation,
    maxLines = 2,
    showMoreButton,
  } = props;
  const { formatMessage } = useLocale();
  const { inputRef, isEditMode, setOpenEditMode, setCloseEditMode, onFormSubmit } = useEditMode(onSubmit, disabled);
  const contentRef = useRef<HTMLParagraphElement | null>(null);
  const [isExpanded, setExpanded] = useState(false);
  const [isContentTruncated, setContentTruncated] = useState(false);

  const { control, handleSubmit, setValue } = useIntlForm({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(defaultTextAreaEditSchema(name, required)),
  });

  useEffect(() => {
    setValue(name, value);

    if (!enableTruncation) return;

    if (contentRef.current !== null) {
      setContentTruncated(contentRef.current.scrollHeight > contentRef.current.clientHeight);
    }
  }, [value]);

  const ShowMoreButtonComponent = showMoreButton || ShowMoreButton;

  return (
    <InlineEdit
      header={formatMessage({ id: AppMessages[label] })}
      isActive={isEditMode}
      onDataDisplayClick={() => {
        setExpanded(false);
        setOpenEditMode();
      }}
      onCancel={setCloseEditMode}
      onSubmit={handleSubmit(onFormSubmit)}
      loading={loading}
      disabled={disabled}
      input={
        <FormTextField
          name={name}
          control={control}
          label={formatMessage({ id: AppMessages[label] })}
          inputRef={inputRef}
          size="small"
          multiline
          rows={2}
          autoFocus
          fullWidth
          // we modify an initial position of the cursor when an 'edit mode' is on
          // if multiline prop is on and description's input is editable, the cursor's initial position is at the beginning of the input value, which is wrong, that's why we modify it in onFocus function
          onFocus={(e) => {
            const value = e.target.value;
            e.target.value = '';
            e.target.value = value;
          }}
        />
      }
      renderDataDisplay={(params) => (
        <>
          <InlineEditDataDisplay header={formatMessage({ id: AppMessages[label] })} {...params}>
            <Box sx={composeStyles([enableTruncation && styles.contentContainer])}>
              <Typography
                sx={composeStyles([enableTruncation && !isExpanded && styles.truncatedContent(maxLines)])}
                ref={contentRef}
                variant="caption"
              >
                {dashIfEmpty(value)}
              </Typography>
            </Box>
          </InlineEditDataDisplay>
          {isContentTruncated ? (
            <ShowMoreButtonComponent
              toggleExpanded={() => {
                setExpanded((prev) => !prev);
              }}
              isExpanded={isExpanded}
            />
          ) : null}
        </>
      )}
    />
  );
};
