import { Stack } from '@mui/material';
import { Typography } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';
import { Translation } from 'ui/translation/Translation';

import { AbsencesWarning } from './absencesWarning/AbsencesWarning';
import * as styles from './AssignOrProposeAnywayContent.styles';
import { FteWarning } from './fteWarning/FteWarning';
import { RateWarning } from './rateWarning/RateWarning';
import { RejectionsWarning } from './rejectionsWarning/RejectionsWarning';
import { AssignOrProposeAnywayContentProps } from './AssignOrProposeAnywayContent.types';

export const AssignOrProposeAnywayContent = ({
  loading,
  mode,
  onClose,
  warnings,
  onSubmit,
  projectId,
  employeeId,
}: AssignOrProposeAnywayContentProps) => {
  const { formatMessage } = useLocale();

  return (
    <DialogInnerWrapper
      onClose={onClose}
      title={formatMessage({ id: AppMessages['assignOrProposeAnyway.title'] })}
      submitText={formatMessage({ id: AppMessages[`assignOrProposeAnyway.${mode}.button.submit`] })}
      onSubmit={onSubmit}
      loading={loading}
      isFormValid
    >
      <Typography sx={styles.message}>
        <Translation id={`assignOrProposeAnyway.${mode}.message`} />
      </Typography>
      <Stack spacing={1.5}>
        {warnings.availability?.rate && <RateWarning warning={warnings.availability.rate} employeeId={projectId} />}
        {warnings.availability?.absences && (
          <AbsencesWarning absences={warnings.availability.absences} employeeId={employeeId} />
        )}
        {warnings.availability?.fte && <FteWarning warning={warnings.availability.fte} employeeId={employeeId} />}
        {warnings.rejections && <RejectionsWarning rejections={warnings.rejections} projectId={employeeId} />}
      </Stack>
    </DialogInnerWrapper>
  );
};
