import { Typography } from 'lux/components';
import { round } from 'lodash';

import { EmploymentType } from 'api/types/EmploymentType.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { PersonRateProps } from './PersonRate.types';

export const PersonRate = ({ employmentType, personRates, currency }: PersonRateProps) => {
  const { formatMessage } = useLocale();

  if (employmentType !== EmploymentType.LTSH) {
    return null;
  }

  const rateInProjectCurrency = personRates?.[currency];

  return (
    <>
      {!rateInProjectCurrency ? (
        formatMessage({ id: AppMessages['card.need.comparisonTable.hourlyRate.notDefined'] })
      ) : (
        <>
          {round(rateInProjectCurrency, 2)}{' '}
          <Typography color="text.secondary" variant="caption" fontWeight={600}>
            {currency}
          </Typography>
        </>
      )}
    </>
  );
};
