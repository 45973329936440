import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Button } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';
import { AppMessages } from 'i18n/messages';
import { useDisconnectFromCRMModal } from '../../../hooks/useDisconnectFromCRMModal/useDisconnectFromCRMModal';

export type DisconnectFromCRMModalProps = {
  projectId: string;
  open: boolean;
  onClose: VoidFunction;
};

export const DisconnectFromCRMModal = ({ projectId, open, onClose }: DisconnectFromCRMModalProps) => {
  const { formatMessage } = useLocale();
  const { handleSubmitButtonClick, isLoading } = useDisconnectFromCRMModal({ projectId, onClose });
  useDialogBlockScroll(open);

  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>{formatMessage({ id: AppMessages['projectDetails.disconnectFromCRMModal.title'] })}</DialogTitle>
      <DialogContent>
        {formatMessage({ id: AppMessages['projectDetails.disconnectFromCRMModal.message'] })}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {formatMessage({ id: AppMessages['button.cancel'] })}
        </Button>
        <Button loading={isLoading} variant="contained" onClick={handleSubmitButtonClick}>
          {formatMessage({ id: AppMessages['projectDetails.disconnectFromCRMModal.button.submit'] })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
