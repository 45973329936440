import { Theme } from '@mui/system';
import { SxProps } from '@mui/material';

import { Styles } from 'styles/theme';

export const tooltipsContainer: Styles = {
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  gap: 0.5,
};

export const tooltip: SxProps<Theme> = {
  backgroundColor: 'surface.paper.default',
  borderRadius: 1,
  padding: 0.5,

  '& svg': {
    color: 'text.secondary',
  },
};

export const arrow: SxProps<Theme> = {
  '&&.MuiTooltip-arrow': {
    color: 'surface.paper.default',
  },
};
