import { AppMessages } from 'i18n/messages';
import { intl } from 'context/locale/intl/Intl';

export enum ProjectSetupStep {
  assignPM = 'AssignPM',
  assignTeamMember = 'AssignTeamMember',
}

export const projectSetupSteps = (projectData: { assignedPmCount: number; activeAssignments: number }) => {
  const { formatMessage } = intl.currentIntl;

  const steps = [
    {
      id: ProjectSetupStep.assignPM,
      label: formatMessage({ id: AppMessages['projectDetails.accordions.details.projectSetup.assignProjectManager'] }),
      completed: projectData.assignedPmCount > 0,
    },
    {
      id: ProjectSetupStep.assignTeamMember,
      label: formatMessage({ id: AppMessages['projectDetails.accordions.details.projectSetup.assignTeamMember'] }),
      completed: projectData.activeAssignments - projectData.assignedPmCount > 0,
    },
  ];
  return steps;
};
