import { SxProps } from '@mui/material';

export const totalClientMoneyAmountContainer: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
  textAlign: 'right',
  mb: 2,
};

export const totalClientMoneyAmount: SxProps = {
  color: 'text.secondary',
  fontWeight: 500,
  marginTop: 4,
};

export const amount: SxProps = {
  fontWeight: 500,
  ml: 1,
  marginTop: 4,
};
