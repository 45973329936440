import { Box } from '@mui/material';
import { Button, DataDisplay, Grid, Typography } from 'lux/components';
import { Link } from 'react-router-dom';

import { findRateByRoleAndSeniority, fteToHumanValue } from 'app/need/utils';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { SkillsList } from '../skillsList/SkillsList';
import { formatSeniorityName } from 'utils/stringUtils';
import { DEFAULT_DATE_FORMAT, needFormatDate } from '../../../../../../../utils/dateUtils';

import * as styles from './NeedDetails.styles';
import type { NeedDetailsProps } from './NeedDetails.types';

export const NeedDetails = ({ projectDetails, needData }: NeedDetailsProps) => {
  const { formatMessage } = useLocale();

  const { currentRateCard } = projectDetails;

  return (
    <>
      <Box data-cy="need-modal_details" sx={styles.detailsTab}>
        <Grid container spacing={0} gutter={0}>
          <Grid item xs={6}>
            <Box sx={styles.detailsItem}>
              <DataDisplay header={formatMessage({ id: AppMessages['card.details.seniority'] })}>
                <Typography variant="caption">{formatSeniorityName(needData.seniority) || '-'}</Typography>
              </DataDisplay>
            </Box>
            <Box sx={styles.detailsItem}>
              <DataDisplay header={formatMessage({ id: AppMessages['card.details.availableFrom'] })}>
                <Typography variant="caption">{needData.startDate}</Typography>
              </DataDisplay>
            </Box>
            <Box sx={styles.detailsItem}>
              <DataDisplay header={formatMessage({ id: AppMessages['card.need.details.project'] })}>
                <Button
                  size="small"
                  sx={styles.seeDetailsLink}
                  component={Link}
                  disableRipple
                  variant="text"
                  to={`/projects/${projectDetails.id}`}
                  target="_blank"
                >
                  <Typography variant="caption">{projectDetails.name}</Typography>
                </Button>
              </DataDisplay>
            </Box>
            <Box sx={styles.detailsItem}>
              <DataDisplay header={formatMessage({ id: AppMessages['card.details.fte'] })}>
                <Typography variant="caption">
                  {formatMessage({ id: AppMessages[fteToHumanValue(needData.fte)] }, { fte: needData.fte })}
                </Typography>
              </DataDisplay>
            </Box>
            <Box sx={styles.detailsItem}>
              <DataDisplay header={formatMessage({ id: AppMessages['card.need.details.createdAt'] })}>
                <Typography variant="caption">
                  {needFormatDate(new Date(needData.createdAt), DEFAULT_DATE_FORMAT)}
                </Typography>
              </DataDisplay>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={styles.detailsItem}>
              <DataDisplay header={formatMessage({ id: AppMessages['card.need.details.role'] })}>
                <Typography variant="caption">{needData.role}</Typography>
              </DataDisplay>
            </Box>
            <Box sx={styles.detailsItem}>
              <DataDisplay header={formatMessage({ id: AppMessages['card.need.details.endDate'] })}>
                <Typography variant="caption">{needData.endDate || '-'}</Typography>
              </DataDisplay>
            </Box>
            <Box sx={styles.detailsItem}>
              <DataDisplay header={formatMessage({ id: AppMessages['card.need.details.projectStart'] })}>
                <Typography variant="caption">{projectDetails.startDate}</Typography>
              </DataDisplay>
            </Box>
            <Box sx={styles.detailsItem}>
              <DataDisplay header={formatMessage({ id: AppMessages['card.details.rate'] })}>
                <Box>
                  <Typography variant="caption">
                    {findRateByRoleAndSeniority(currentRateCard.items, needData.role, needData.seniority) ?? '-'}
                  </Typography>
                  <Typography fontWeight={600} color="text.secondary" variant="caption">
                    &nbsp;{currentRateCard.currency}
                  </Typography>
                </Box>
              </DataDisplay>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={styles.detailsItem}>
            <DataDisplay header={formatMessage({ id: AppMessages['card.need.details.additionalInfo'] })}>
              <Typography variant="caption">
                {needData.additionalInformation ? needData.additionalInformation : '-'}
              </Typography>
            </DataDisplay>
          </Box>
        </Grid>
        {needData.skillSet && <SkillsList skillSet={needData.skillSet} />}
      </Box>
    </>
  );
};
