import { useState } from 'react';

import { Accordion, Button } from 'lux/components';
import { Box } from '@mui/material';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

import * as styles from './PeopleInProject.styles';
import { PeopleInProjectProps } from './PeopleInProject.types';
import { ManageRolesAutocomplete } from './manageRolesAutocomplete/ManageRolesAutocomplete';
import { RoleSection } from './roleSection/RoleSection';
import { ProjectOwnerSection } from './projectOwnerSection/ProjectOwnerSection';

export const PeopleInProject = ({ projectData }: PeopleInProjectProps) => {
  const [expanded, setExpanded] = useState(true);
  const [isAddingRoleActive, setAddingRoleActive] = useState(false);
  const { formatMessage } = useLocale();

  const { keyRoles, id } = projectData;

  return (
    <Accordion
      summary={formatMessage({ id: AppMessages['projectDetails.accordions.details.people'] })}
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
    >
      <Accordion.Details>
        <ProjectOwnerSection owner={projectData.owner} />
        {keyRoles.map((keyRole) => (
          <RoleSection key={keyRole.id} role={keyRole} keyRoles={keyRoles} projectId={id} />
        ))}
        <Box sx={styles.addRoleButton}>
          {isAddingRoleActive ? (
            <ManageRolesAutocomplete
              isActive={isAddingRoleActive}
              closeEditMode={() => setAddingRoleActive(false)}
              projectId={id}
              keyRoles={keyRoles}
            />
          ) : (
            <Button variant="outlined" size="small" fullWidth onClick={() => setAddingRoleActive(true)}>
              {formatMessage({
                id:
                  keyRoles.length === 0
                    ? AppMessages['projectDetails.accordions.peopleInProject.button.addRole']
                    : AppMessages['projectDetails.accordions.peopleInProject.button.addAnotherRole'],
              })}
            </Button>
          )}
        </Box>
      </Accordion.Details>
    </Accordion>
  );
};
