import { Fragment } from 'react';

import { Table } from 'lux/components';

import { WorkStatementTableCellProps } from './WorkStatementTableCell.types';

export const WorkStatementTableCell = ({
  children,
  autoWidth = false,
  isLabel = false,
  sx,
}: WorkStatementTableCellProps) => {
  const Wrapper = isLabel ? Table.SortLabel : Fragment;
  const props = isLabel ? { disabled: true } : undefined;

  return (
    <Table.Cell width={autoWidth ? undefined : 150} sx={sx}>
      <Wrapper {...props}>{children}</Wrapper>
    </Table.Cell>
  );
};
