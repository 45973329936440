import { Stack } from '@mui/material';
import { Table } from 'lux/components';

import { Skeleton } from 'ui/skeleton/Skeleton';

export const LoadMoreInvoicesSkeleton = () => (
  <Table.Row>
    <Table.Cell colSpan={6} align="center">
      <Stack alignItems={'center'}>
        <Skeleton width={155} height={48} />
      </Stack>
    </Table.Cell>
  </Table.Row>
);
