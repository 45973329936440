import { addBillingCycle } from 'api/actions/addBillingCycle/addBillingCycle';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';

export const useAddBillingCycle = () => {
  const { invalidateBillingCycleCache } = useProjectTimesheetData();

  return useMutation(['addBillingCycle'], addBillingCycle(), {
    onSuccess: invalidateBillingCycleCache,
  });
};
