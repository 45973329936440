import { Styles } from 'styles/theme';

export const proposalTabsWrapper: Styles = {
  flexDirection: 'row',
  borderTop: 1,
  borderColor: 'transparent',

  '> :first-of-type': {
    borderBottomLeftRadius: 11,
  },
  '> :last-child': {
    borderBottomRightRadius: 11,
  },
};
