import { Avatar, Typography } from 'lux/components';
import { Box } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import * as styles from './ProjectOwnerSection.styles';
import { ProjectOwnerSectionProps } from './ProjectOwnerSection.types';

export const ProjectOwnerSection = ({ owner }: ProjectOwnerSectionProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Typography sx={styles.header} variant="subtitle2">
        {formatMessage({ id: AppMessages['projectDetails.accordions.peopleInProject.owner'] })}
      </Typography>
      <Box sx={styles.personContainer}>
        <Avatar size="sm" alt="project owner" />
        <Typography variant="caption">{owner}</Typography>
      </Box>
    </>
  );
};
