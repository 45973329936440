import { Styles } from 'styles/theme';

export const tableHead: Styles = {
  '.MuiTableCell-root': {
    paddingY: 0.25,
    '&.MuiTableCell-head.MuiTableCell-sizeMedium:not(.MuiTableCell-paddingCheckbox)': {
      paddingX: 0,

      '& .MuiTableSortLabel-root': {
        paddingX: 2,
      },
    },
  },
};

export const labelCell: Styles = {
  whiteSpace: 'nowrap',
};
