import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { NeedCardModal } from 'app/need/card/modal/NeedCardModal';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useNeeds } from 'hooks/useNeeds/useNeeds';
import { EmptyState } from 'ui/emptyState/EmptyState';
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser';
import { useNeedsFilters } from 'hooks/useNeedsFilters/useNeedsFilters';
import { AccessType } from 'context/auth/currentUser/CurrentUser.types';
import { NeedsData } from 'shared/needs/needsContent/needsData/NeedsData';
import { AppRoute } from 'routing/AppRoute.enum';

export const NeedsContent = () => {
  const navigate = useNavigate();
  const { formatMessage } = useLocale();
  const { filters, setFilterValue, clearFilter } = useNeedsFilters();
  const { availabilityFrom, availabilityTo } = filters;

  useEffect(() => {
    if (availabilityFrom && availabilityTo && availabilityTo < availabilityFrom) {
      navigate(AppRoute.needs);
    }
  }, [availabilityFrom, availabilityTo, navigate]);

  const { data: user } = useCurrentUser();
  const { data, isLoading } = useNeeds(filters);

  const { isOpen, setOpen, setClose } = useDialog();
  const [activeNeedId, setActiveNeedId] = useState<string | null>(null);

  const projectId = data?.find((need) => need.id === activeNeedId)?.project.id;

  const handleNeedCardClick = (needId: string) => {
    setActiveNeedId(needId);
    setOpen();
  };

  const handleNeedCardClose = () => {
    setActiveNeedId(null);
    setClose();
  };

  useEffect(() => {
    if (user) {
      setFilterValue('owner', user?.metadata.accessType === AccessType.sales ? user.person.email : '');
    } else {
      clearFilter('owner');
    }
  }, [user]);

  return (
    <>
      {isLoading || (data && data.length > 0) ? (
        <NeedsData needs={data} onNeedClick={handleNeedCardClick} />
      ) : (
        <EmptyState
          illustration="requestMailbox"
          title={formatMessage({ id: AppMessages['needs.empty.title'] })}
          message={formatMessage({ id: AppMessages['needs.empty.message'] })}
        />
      )}
      {activeNeedId && projectId && (
        <NeedCardModal projectId={projectId} needId={activeNeedId} open={isOpen} onClose={handleNeedCardClose} />
      )}
    </>
  );
};
