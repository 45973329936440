import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { CancelProjectExtensionPayload } from './cancelProjectExtensionAction.types';

export const cancelProjectExtensionMutation: (projectId: string) => MutationFn<CancelProjectExtensionPayload> =
  (projectId: string) => (body) => ({
    endpoint: `projects/${projectId}/cancel-extension`,
    method: 'PATCH',
    params: body,
  });
