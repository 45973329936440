import { ReactNode } from 'react';

import { Table } from 'lux/components';

import { WorkStatementTableCell as TableCell } from '../workStatementTableCell/WorkStatementTableCell';
import { HourType } from 'api/types/HourType.enum';
import { useBillingSummaryPrice } from 'hooks/useBillingSummaryPrice/useBillingSummaryPrice';
import { Translation } from 'ui/translation/Translation';
import { roundHours } from 'utils/roundHours';

import * as styles from './HourDetailsRow.styles';
import { HourDetailsRowProps } from './HourDetailsRow.types';

const switchHourTypeToTranslation = (hourType: HourType): ReactNode => {
  if (hourType === HourType.overhour) {
    return <Translation id="projectDetails.timesheet.overtime" />;
  }

  return <Translation id="projectDetails.timesheet.regularWorks" />;
};

export const HourDetailsRow = ({ hour, isLastRow = false, hasPadding = false, role }: HourDetailsRowProps) => {
  const { formatPrice } = useBillingSummaryPrice();

  return (
    <Table.Row sx={styles.hourDetailsRow(isLastRow)}>
      <TableCell autoWidth sx={hasPadding ? {} : styles.firstCell}>
        {switchHourTypeToTranslation(hour.type)} ({hour.ratePercentage}%)
      </TableCell>
      {role && <TableCell autoWidth>{role}</TableCell>}
      <TableCell autoWidth sx={styles.hoursCell}>
        {roundHours(hour.amount)}
      </TableCell>
      <TableCell>{formatPrice(hour.rateValue * (hour.ratePercentage / 100))}</TableCell>
      <TableCell>{formatPrice(hour.total)}</TableCell>
    </Table.Row>
  );
};
