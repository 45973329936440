import { Button } from '@mui/material';

import { EmptyState } from 'ui/emptyState/EmptyState';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useClientsFilters } from '../../../hooks/useClientsFilters/useClientsFilters';
import { useClients } from '../../../hooks/useClients/useClients';

import { ClientsTable } from './clientsTable/ClientsTable';
import { ClientsListProps } from './ClientsList.types';
import { ClientsListSkeleton } from './ClientsList.skeleton';

export const ClientsList = ({}: ClientsListProps) => {
  const { t } = useLocale();
  const { filters, resetAllFiltersToDefault } = useClientsFilters();

  const { data: clientsData, isLoading, isFetching, fetchNextPage, hasNextPage } = useClients(filters);

  if (isLoading) {
    return <ClientsListSkeleton />;
  }

  const handleListEndReached = () => {
    if (!hasNextPage || isFetching) {
      return;
    }
    fetchNextPage();
  };

  const clients = clientsData?.pages.flatMap((page) => page.clients) || [];

  return (
    <>
      {!clients?.length ? (
        <EmptyState
          illustration="searchPeople"
          title={t('clients.clientsList.empty.title')}
          message={t('clients.clientsList.empty.message')}
          actionBtn={<Button onClick={() => resetAllFiltersToDefault()}>{t('filters.reset')}</Button>}
        />
      ) : (
        <ClientsTable onEndReached={handleListEndReached} clientsData={clients} />
      )}
    </>
  );
};
