import { getProjectNeedsQuery } from 'api/actions/getProjectNeeds/getProjectNeeds';
import { GetProjectNeedsParams, GetProjectNeedsResponse } from 'api/actions/getProjectNeeds/getProjectNeeds.types';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { projectsKeys } from 'utils/queryKeys';
import { parseSortFilter } from 'utils/filters/filters';

import { UseProjectNeedsParams } from './useProjectNeeds.types';

export const useProjectNeeds = ({ projectId, sort }: UseProjectNeedsParams) => {
  const { isAuthenticated } = useAuth();
  let args = {};

  if (sort) {
    const { field, order } = parseSortFilter(sort);

    args = {
      ...args,
      [`sort[${field}]`]: order,
    };
  }

  return useQuery<GetProjectNeedsParams, GetProjectNeedsResponse>(
    [...projectsKeys.singleProjectNeeds(projectId), { projectId, sort }],
    getProjectNeedsQuery(projectId),
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
      args,
    },
  );
};
