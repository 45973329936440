import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import * as styles from '../../CreateProject.styles';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { useTypingState } from 'hooks/useTypingState/useTypingState';
import { useFormNavigation } from 'app/createProject/useFormNavigation/useFormNavigation';

import { UniqueValueFieldProps } from './UniqueValueField.types';

export const UniqueValueField = ({
  fieldName,
  label,
  checkUniqueValue,
  isLoading,
  isTypingEnabled,
  ...rest
}: UniqueValueFieldProps) => {
  const form = useFormContext();
  const { setDisabled } = useFormNavigation();

  const { onKeyDownHandler, isTyping } = useTypingState(checkUniqueValue, undefined, { enabled: isTypingEnabled });

  useEffect(() => {
    checkUniqueValue();
  }, []);

  useEffect(() => {
    setDisabled(isTyping || isLoading);
  }, [isTyping, isLoading]);

  return (
    <FormTextField
      name={fieldName}
      control={form.control}
      label={label}
      sx={styles.formElement}
      fullWidth
      onKeyDown={onKeyDownHandler}
      {...rest}
    />
  );
};
