import { Box } from '@mui/material';
import { CardHeader } from 'lux/components';

import { ProjectCard } from 'app/projects/projectsGrid/projectsList/projectCard/ProjectCard';
import { EmptyState } from 'ui/emptyState/EmptyState';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useClient } from 'hooks/useClient/useClient';
import { CardWrapper } from 'ui/cardWrapper/CardWrapper';

import { ClientProjectsSkeleton } from './ClientProjectsSkeleton';
import * as styles from './ClientProjects.styles';

export const ClientProjects = () => {
  const { clientDetails, isLoading: isClientLoading } = useClient();
  const { t } = useLocale();
  const projects = clientDetails?.client.projects;

  if (isClientLoading) {
    return (
      <CardWrapper>
        <CardHeader title={t('client.projects.title')} />
        <ClientProjectsSkeleton />
      </CardWrapper>
    );
  }

  return (
    <CardWrapper>
      <CardHeader title={t('client.projects.title')} />
      <Box sx={projects?.length === 1 ? styles.leftContainer : styles.centeredContainer}>
        {projects?.length ? (
          projects.map((project) => (
            <Box key={project.id} sx={styles.projectCardContainer}>
              <ProjectCard project={project} showClientInfo={false} />
            </Box>
          ))
        ) : (
          <EmptyState illustration="emptyState" title={t('client.projects.not.found.title')} message={''} />
        )}
      </Box>
    </CardWrapper>
  );
};
