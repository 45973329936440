import { UseQueryResult } from '@tanstack/react-query';

import { getClientDetails } from 'api/actions/getClientDetails/getClientDetails';
import { ClientResponse } from 'context/clients/clientContext/ClientContext.types';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { clientsKeys } from 'utils/queryKeys';

export const useClientDetails = (clientId?: string): UseQueryResult<ClientResponse> => {
  const { isAuthenticated } = useAuth();

  return useQuery(clientsKeys.clientDetails(clientId), getClientDetails(clientId), {
    enabled: isAuthenticated && !!clientId,
  });
};
