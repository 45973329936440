import Joi from 'joi';

import { formatDate, MAX_SUPPORTED_DATE, getCurrentDayStartDate } from 'utils/dateUtils';
import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';

export const planProjectExtensionSchema = () => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object().keys({
    extendIndefinitely: Joi.boolean().required(),
    endDate: Joi.date()
      .min(getCurrentDayStartDate())
      .max(MAX_SUPPORTED_DATE)
      .required()
      .label(formatMessage({ id: 'projectDetails.planProjectExtensionModal.endDate.label' }))
      .messages({
        'date.min': formatMessage(
          {
            id: AppMessages['validation.min'],
          },
          {
            label: formatMessage({ id: 'projectDetails.planProjectExtensionModal.endDate.label' }),
            limit: formatDate(getCurrentDayStartDate()),
          },
        ),
        'date.max': formatMessage(
          {
            id: AppMessages['validation.max'],
          },
          {
            label: formatMessage({ id: 'projectDetails.planProjectExtensionModal.endDate.label' }),
            limit: formatDate(MAX_SUPPORTED_DATE),
          },
        ),
      }),
    extensionDate: Joi.when('extendIndefinitely', {
      is: true,
      then: Joi.allow(null),
      otherwise: Joi.date()
        .greater(Joi.ref('endDate'))
        .max(MAX_SUPPORTED_DATE)
        .required()
        .label(formatMessage({ id: 'projectDetails.planProjectExtensionModal.extensionDate.label' }))
        .messages({
          'date.greater': formatMessage(
            {
              id: AppMessages['validation.greater'],
            },
            {
              label: formatMessage({ id: 'projectDetails.planProjectExtensionModal.extensionDate.label' }),
              limit: formatMessage({ id: 'projectDetails.planProjectExtensionModal.endDate.label' }),
            },
          ),
        }),
    }),
  });
};
