import { enqueueSnackbar } from 'notistack';

import { useMutation } from 'hooks/useMutation/useMutation';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { addCharge } from 'api/actions/addCharge/addCharge';

export const useAddCharge = (workStatementId: string) => {
  const { invalidateBillingSummaryCache } = useProjectBillingSummaryData();
  const { formatMessage } = useLocale();

  return useMutation(['addCharge'], addCharge(workStatementId), {
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: AppMessages['projectDetails.charges.actions.add.success'] }));
      invalidateBillingSummaryCache();
    },
  });
};
