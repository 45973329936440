import { DialogTitle, DialogContent, DialogActions, Stack } from '@mui/material';
import { SubmitHandler } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useSnackbar } from 'notistack';
import { Button, Tooltip } from 'lux/components';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { formatDate } from 'utils/dateUtils';
import { useLocale } from 'hooks/useLocale/useLocale';
import { DatePicker } from 'ui/form/datePicker/DatePicker';
import { InfoBox } from 'ui/infoBox/InfoBox';
import { AppMessages } from 'i18n/messages';
import { useEditAssignment } from 'hooks/useEditAssignment/useEditAssignment';
import { getAssignmentRemovalDisabledReason } from 'utils/projectUtils';

import { planAssignmentRemovalSchema } from './PlanRemovalContent.schema';
import * as styles from './PlanRemovalContent.styles';
import { PlanRemovalFormValues, PlanRemovalContentProps } from './PlanRemovalContent.types';

export const PlanRemovalContent = ({ onClose, assignmentData, onRemoveImmediatelyClick }: PlanRemovalContentProps) => {
  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate, isLoading } = useEditAssignment(assignmentData.project.id, assignmentData.id);
  const { control, handleSubmit } = useIntlForm<PlanRemovalFormValues>({
    defaultValues: {
      plannedRemovalDate: null,
    },
    mode: 'onSubmit',
    resolver: joiResolver(planAssignmentRemovalSchema(assignmentData.startDate)),
  });

  const personFullName = `${assignmentData.person.firstName} ${assignmentData.person.lastName}`;

  const onSubmit: SubmitHandler<PlanRemovalFormValues> = ({ plannedRemovalDate }) => {
    if (!plannedRemovalDate) {
      return;
    }

    mutate(
      {
        endDate: formatDate(plannedRemovalDate),
      },
      {
        onSuccess: () => {
          onClose();
          enqueueSnackbar(
            formatMessage(
              { id: AppMessages['assignment.planRemoval.snackbar.success'] },
              {
                personName: personFullName,
                projectName: assignmentData.project.name,
                date: formatDate(plannedRemovalDate),
              },
            ),
          );
        },
      },
    );
  };

  const removalDisabledReason = getAssignmentRemovalDisabledReason(assignmentData.project, assignmentData);
  const disableRemoval = Boolean(removalDisabledReason);
  return (
    <>
      <DialogTitle>
        {formatMessage(
          { id: AppMessages['assignment.planRemoval.title'] },
          { personName: personFullName, projectName: assignmentData.project.name },
        )}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack marginBottom={3} spacing={3} alignItems="flex-start">
            <DatePicker
              disablePast
              fullWidth
              label={formatMessage({ id: AppMessages['assignment.planRemoval.plannedRemovalDate.label'] })}
              name="plannedRemovalDate"
              control={control}
            />
            <InfoBox title={formatMessage({ id: AppMessages['assignment.planRemoval.infobox'] })} />
            <Tooltip arrow title={removalDisabledReason}>
              <div>
                <Button
                  variant="text"
                  type="button"
                  sx={styles.cancelButton}
                  disabled={disableRemoval}
                  onClick={onRemoveImmediatelyClick}
                  color="error"
                >
                  {formatMessage({ id: AppMessages['assignment.planRemoval.button.removeImmediately'] })}
                </Button>
              </div>
            </Tooltip>
          </Stack>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button variant="text" sx={styles.cancelButton} type="button" onClick={onClose}>
            {formatMessage({ id: AppMessages['button.cancel'] })}
          </Button>
          <Button loading={isLoading} type="submit" variant="contained">
            {formatMessage({ id: AppMessages['assignment.planRemoval.button.submit'] })}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};
