import { ReactNode } from 'react';

import { EmojiType } from 'lux/components/atoms/emoji/Emoji.types';

export enum BannerVariant {
  info = 'info',
  warning = 'warning',
  error = 'error',
  success = 'success',
}

export type BannerProps = {
  title: string;
  subtitle?: string;
  emoji: EmojiType;
  actions?: ReactNode;
  variant?: BannerVariant;
};
