import { Styles } from 'styles/theme';

export const customDialog: Styles = {
  '&&& .MuiDialogContent-root': {
    paddingTop: 0,
  },

  '.MuiDialog-paper': {
    height: '100%',
  },
};
