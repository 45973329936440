import type { Styles } from 'styles/theme';

export const stepper: Styles = {
  '&.MuiStepper-root': {
    mt: 1,
  },
  '.MuiStep-root.MuiStep-root': {
    '&:hover': {
      backgroundColor: 'transparent',
    },

    '.MuiStepLabel-root': {
      py: 0.5,
    },

    '.MuiStepConnector-root': {
      marginLeft: 2,
    },

    '.MuiStepLabel-label': {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 400,
    },

    '.MuiStepLabel-iconContainer': {
      '.MuiSvgIcon-root': {
        width: 16,
        height: 16,
        backgroundColor: 'primary.light',
        border: 0,

        path: {
          fill: 'currentColor',
        },
      },

      '&.Mui-completed .MuiSvgIcon-root': {
        backgroundColor: 'success.main',
      },
    },

    '.MuiStepLabel-label.Mui-active': {
      color: 'text.primary',
    },

    '.MuiStepLabel-label.Mui-disabled': {
      color: 'text.disabled',
    },
  },
};
