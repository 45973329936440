import { SxProps } from '@mui/system';

export const projectCardContainer: SxProps = {
  width: 500,
  m: '1rem',
};

export const centeredContainer: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  flexWrap: 'wrap',
};

export const leftContainer: SxProps = {
  ...centeredContainer,
  justifyContent: 'left',
};

export const projectsListWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
  overflow: 'auto',
};
