import decode from 'jwt-decode';

import type { JwtToken } from 'api/types/types';

export const checkToken = (exp: number) => {
  const secondsSinceEpoch = Math.round(new Date().getTime());
  return secondsSinceEpoch >= exp;
};

export const decodeToken = (token: string) => decode<JwtToken>(token);
