import { useContext } from 'react';

import { PersonDataContext } from 'context/personData/personDataContext/PersonDataContext';

export const usePersonData = () => {
  const context = useContext(PersonDataContext);

  if (!context) {
    throw new Error('PersonDataContext must be within PersonDataContextController');
  }

  return context;
};
