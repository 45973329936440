import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { ConvertLeadParams } from './convertLeadActions.types';

export const convertLeadMutation: (projectId: string) => MutationFn<ConvertLeadParams> =
  (projectId: string) => (body) => ({
    endpoint: `projects/${projectId}/start`,
    method: 'PATCH',
    params: body,
  });
