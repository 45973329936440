import { Box } from '@mui/material';

import { TextAreaEdit } from 'ui/form/inlineEdit/textAreaEdit/TextAreaEdit';
import { useUpdateProject } from 'hooks/useUpdateProject/useUpdateProject';

import * as styles from './ProjectDescription.styles';
import { ProjectDescriptionProps } from './ProjectDescription.types';

export const ProjectDescription = ({ description, projectId, disabled }: ProjectDescriptionProps) => {
  const { mutateAsync, isLoading } = useUpdateProject(projectId);

  return (
    <Box sx={styles.container}>
      <TextAreaEdit
        name="description"
        value={description || ''}
        label="projectDetails.accordions.details.description"
        defaultValues={{ description }}
        onSubmit={mutateAsync}
        loading={isLoading}
        required
        enableTruncation
        disabled={disabled}
      />
    </Box>
  );
};
