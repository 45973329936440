import { Styles } from 'styles/theme';
import { isProduction } from 'utils/isProduction';

export const DRAWER_WIDTH = 57;
export const DRAWER_OPENED_WIDTH = 193;

export const drawer: (expanded: boolean) => Styles = (expanded) => ({
  width: expanded ? DRAWER_OPENED_WIDTH : DRAWER_WIDTH,
  whiteSpace: 'nowrap',

  '.MuiPaper-root': {
    width: expanded ? DRAWER_OPENED_WIDTH : DRAWER_WIDTH,
    paddingX: 1,
    paddingTop: (theme) => theme.topBarHeight + (!isProduction ? 9 : 2),
    paddingBottom: 4,
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 2,
    borderRight: '1px solid',
    borderRightColor: 'border.default',
    backgroundColor: 'surface.paper.default',
    transition: (theme) =>
      theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: expanded ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
      }),
  },
});
