import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';
import { AssignmentInfoContextController } from 'context/assignmentInfo/assignmentInfoContextController/AssignmentInfoContextController';
import { AssignmentsFiltersContextController } from 'context/assignmentsFilters/assignmentsFiltersContextController/AssignmentsFiltersContextController';
import { ProjectNeedsFiltersContextController } from 'context/projectNeedsFilters/projectNeedsFiltersContextController/ProjectNeedsFiltersContextController';

import { TeamDetails } from './TeamDetails';

const initialFilters = {
  status: [AssignmentStatus.active, AssignmentStatus.leaving, AssignmentStatus.temporary, AssignmentStatus.preassigned],
};

export const TeamDetailsContainer = () => (
  <AssignmentsFiltersContextController initialFilters={initialFilters}>
    <AssignmentInfoContextController>
      <ProjectNeedsFiltersContextController>
        <TeamDetails />
      </ProjectNeedsFiltersContextController>
    </AssignmentInfoContextController>
  </AssignmentsFiltersContextController>
);
