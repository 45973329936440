import { useSnackbar } from 'notistack';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { deleteDiscountMutation } from 'api/actions/deleteDiscount/deleteDiscountActions';

export const useDeleteDiscount = (workStatementId: string) => {
  const { formatMessage } = useLocale();
  const { invalidateBillingSummaryCache } = useProjectBillingSummaryData();
  const { enqueueSnackbar } = useSnackbar();

  return useMutationDomainError(['deleteDiscountMutation'], deleteDiscountMutation(workStatementId), {
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.deleteDiscount.success'] }));
      invalidateBillingSummaryCache();
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.deleteDiscount.error'] }),
  });
};
