import Joi from 'joi';
import { BadgeProps } from 'lux/components/atoms/badge/Badge.types';

import { AppMessages } from 'i18n/messages';
import { intl } from 'context/locale/intl/Intl';

export const mapBadgeContentToColor = (badgeContent: BadgeProps['badgeContent']): BadgeProps['color'] => {
  if (typeof badgeContent === 'string') {
    if (badgeContent.includes('A') && !badgeContent.includes('B')) return 'green';
    if (badgeContent.includes('B')) return 'yellow';
    if (badgeContent === 'C') return 'orange';
    if (badgeContent === 'D') return 'red';
    if (badgeContent === 'E') return 'purple';
  }

  return 'lightGray';
};

export const codenameSchema = () => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object().keys({
    codename: Joi.string()
      .allow('')
      .trim()
      .max(15)
      .optional()
      .messages({
        'string.max': formatMessage(
          { id: AppMessages['validation.tooLong'] },
          { label: formatMessage({ id: AppMessages['createProject.project.form.codename'] }), limit: 15 },
        ),
      }),
  });
};
