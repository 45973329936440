import { Badge, Tooltip, Typography } from 'lux/components';
import { Box } from '@mui/material';

import { ReactComponent as HubspotFavicon } from 'assets/images/favicons/favicon-hubspot.svg';

import * as styles from './HubspotBadge.styles';
import { HubspotBadgeProps } from './HubspotBadgeProps';

export const HubspotBadge = ({ title, tooltipDescription }: HubspotBadgeProps) => (
  <Tooltip title={tooltipDescription} placement="bottom" arrow>
    <Box sx={styles.tooltipChildElement}>
      <Badge
        isStatic
        color="orange"
        badgeContent={
          <Box sx={styles.boxWrapper}>
            <HubspotFavicon />
            <Typography variant="badgeLabel">{title}</Typography>
          </Box>
        }
      ></Badge>
    </Box>
  </Tooltip>
);
