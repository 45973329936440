import { Styles } from 'styles/theme';

export const rateCardItem = (isMissing: boolean): Styles => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: 2,
  px: 2,
  py: 1,
  backgroundColor: isMissing ? 'warning.light' : 'surface.paper.default',
  color: 'text.secondary',
});

export const roleName: Styles = {
  fontSize: 14,
  lineHeight: '20px',
};

export const currency: Styles = {
  minWidth: 32,
  fontSize: 12,
  lineHeight: '20px',
};

export const rateCardItemColumns: Styles = {
  display: 'flex',
  alignItems: 'center',
  minWidth: 150,
  gap: 1,
  fontSize: 12,
  lineHeight: '20px',
  fontWeight: 500,
};
