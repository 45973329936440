import { Grid } from 'lux/components';

import { DashboardCard } from 'ui/dashboardCard/DashboardCard';
import { PeopleList } from 'app/people/peopleList/PeopleList';
import { PeopleListTopbar } from 'app/people/peopleListTopbar/PeopleListTopbar';
import { PeopleAccordionFilters } from 'app/people/peopleAccordionFilters/PeopleAccordionFilters';

import * as styles from './PeopleListGrid.styles';
import { PeopleListGridProps } from './PeopleListGrid.types';

export const PeopleListGrid = ({ personAction }: PeopleListGridProps) => (
  <Grid container gutter={0} overflow="hidden">
    <Grid xs={3.3} overflow="hidden" display="flex" height="100%" flexDirection="column" item>
      <DashboardCard
        sx={{
          wrapper: styles.filtersDashboardCardWrapper,
        }}
      >
        <PeopleAccordionFilters />
      </DashboardCard>
    </Grid>
    <Grid item xs={8.7} display="flex" flexDirection="column">
      <DashboardCard sx={{ wrapper: styles.listDashboardCardWrapper }}>
        <PeopleListTopbar />
        <PeopleList personAction={personAction} />
      </DashboardCard>
    </Grid>
  </Grid>
);
