import { Box, Stack } from '@mui/material';

import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';
import { Skeleton } from 'ui/skeleton/Skeleton';

import { AssignmentsTableSkeleton } from './assignmentsTable/AssignmentsTable.skeleton';

export const TabAssignedPanelSkeleton = () => (
  <>
    <Stack sx={{ pb: 3 }}>
      <TextSkeleton width={172} variant="caption" />
    </Stack>

    <AssignmentsTableSkeleton />

    <Box marginTop={1}>
      <Skeleton width={104} height={48} />
    </Box>
  </>
);
