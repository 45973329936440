import Joi from 'joi';

import { AppMessages } from 'i18n/messages';
import { intl } from 'context/locale/intl/Intl';
import { KeyRole } from 'api/actions/getProject/getProject.types';

export const manageRolesSchema = (keyRoles: KeyRole[], currentValue?: string) => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object().keys({
    role: Joi.string()
      .allow('')
      .invalid(
        'owner',
        'Owner',
        ...keyRoles.map((keyRole) => keyRole.role).filter((keyRole) => keyRole !== currentValue),
      )
      .messages({
        'any.invalid': formatMessage({
          id: AppMessages['projectDetails.accordions.peopleInProject.validation.roleExistsAlready'],
        }),
      }),
  });
};
