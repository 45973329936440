import { getAvailabilityQuery } from 'api/actions/getAvailability/getAvailability';
import { GetAvailabilityParams, GetAvailabilityResponse } from 'api/actions/getAvailability/getAvailability.types';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { peopleKeys } from 'utils/queryKeys';

export const useAvailability = () => {
  const { isAuthenticated } = useAuth();

  return useQuery<GetAvailabilityParams, GetAvailabilityResponse>(peopleKeys.peopleAvailability, getAvailabilityQuery, {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });
};
