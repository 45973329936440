import { parseQueryString, stringifyQueryParams } from 'api/utils/queryString';

import type { GetNewFiltersArgs, GetNewParamsFiltersArgs, InitialState, StorageType } from './useFilters.types';

export const getStorageData = <T extends InitialState>(storageKey: string, storageType: StorageType) => {
  const storage = storageType === 'local' ? localStorage : sessionStorage;
  const storageData = storage.getItem(storageKey);

  if (!storageData) {
    return null;
  }

  return JSON.parse(storageData) as T;
};

export const saveDataToStorage = <T extends InitialState>(data: T, storageKey: string, storageType: StorageType) => {
  const storage = storageType === 'local' ? localStorage : sessionStorage;
  storage.setItem(storageKey, JSON.stringify(data));
};

export const getNewFilters = <T extends InitialState>(args: GetNewFiltersArgs<T>) => {
  const newFilters = { ...args.prev, ...args.newFiltersState };

  if (args.storageKey) {
    saveDataToStorage(newFilters, args.storageKey, args.storageType);
  }

  return newFilters;
};

export const getNewParamsFilters = <T extends InitialState>(args: GetNewParamsFiltersArgs<T>) => {
  const parsedPrevFilters = parseQueryString<InitialState>(args.prevFilters.toString());

  return stringifyQueryParams(getNewFilters({ prev: parsedPrevFilters, ...args }));
};

export const getClearedFilters = <T extends InitialState>(
  filtersState: T,
  initialState: InitialState,
  exceptions: (keyof T)[],
) =>
  Object.entries(filtersState).reduce((acc, filter) => {
    const [filterKey, filterValue] = filter;
    if (exceptions.includes(filterKey)) {
      return { ...acc, [filterKey]: filterValue };
    }

    return { ...acc, [filterKey]: Array.isArray(initialState[filterKey]) ? [] : '' };
  }, {});
