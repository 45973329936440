import { useEffect, useState } from 'react';

import { Box, Popover } from '@mui/material';
import { Button, Typography } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { HourType } from 'api/types/HourType.enum';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { Translation } from 'ui/translation/Translation';
import { hourInputNumberParser, percentageInputNumberParser } from 'utils/createInputNumberChangeParser';
import { isAbsentHourType } from 'utils/is-absent-hour-type';

import * as styles from './HoursPopover.styles';
import { HoursPopoverFormSubmitHandler, HoursPopoverProps } from './HoursPopover.types';
import { useHoursPopoverForm } from './HoursPopoverForm';

const baseRegularRate = 100;
const baseOverhourRate = 150;

export const HoursPopover = ({ hours, anchorEl, onClose, onSave, hoursTypeToDisplay }: HoursPopoverProps) => {
  const { t } = useLocale();
  const defaultValues = {
    regularWorkAmount: hours.find((billingHours) => billingHours.type === HourType.regular)?.amount ?? 0,
    regularWorkHourRate:
      hours.find((billingHours) => billingHours.type === HourType.regular)?.ratePercentage ?? baseRegularRate,
    overhoursAmount: hours.find((billingHours) => billingHours.type === HourType.overhour)?.amount ?? 0,
    overhourRate:
      hours.find((billingHours) => billingHours.type === HourType.overhour)?.ratePercentage ?? baseOverhourRate,
  };
  const { control, reset, handleSubmit, isValid, watch, setError, clearErrors, trigger } =
    useHoursPopoverForm(defaultValues);

  const regularWorkAmount = Number(watch('regularWorkAmount'));
  const overhoursAmount = Number(watch('overhoursAmount'));

  useEffect(() => {
    const hoursSum = regularWorkAmount + overhoursAmount;

    if (hoursSum > 24) {
      setError('regularWorkAmount', {});
      setError('overhoursAmount', {});
    } else {
      trigger();
      clearErrors(['regularWorkAmount', 'overhoursAmount']);
    }
  }, [overhoursAmount, regularWorkAmount]);

  useEffect(() => {
    reset(defaultValues);
  }, [anchorEl]);

  const handleSave: HoursPopoverFormSubmitHandler = ({
    regularWorkAmount,
    regularWorkHourRate,
    overhoursAmount,
    overhourRate,
  }) => {
    if (!onSave) {
      return;
    }

    if (
      onClose &&
      defaultValues.regularWorkAmount === regularWorkAmount &&
      defaultValues.regularWorkHourRate === regularWorkHourRate &&
      defaultValues.overhoursAmount === overhoursAmount &&
      defaultValues.overhourRate === overhourRate
    ) {
      return onClose();
    }

    onSave([
      {
        type: HourType.regular,
        amount: regularWorkAmount || 0,
        ratePercentage: regularWorkAmount ? regularWorkHourRate : baseRegularRate,
      },
      ...(overhoursAmount > 0
        ? [
            {
              type: HourType.overhour,
              amount: overhoursAmount || 0,
              ratePercentage: overhoursAmount ? overhourRate : baseOverhourRate,
            },
          ]
        : []),
    ]);
  };

  const sumOfHours = hours.reduce((sum, billingHours) => sum + billingHours.amount, 0);
  const isAbsent = !!hours.find((billingHours) => isAbsentHourType(billingHours.type)) && sumOfHours <= 0;
  const [shouldDisplayConfirmation, setShouldDisplayConfirmation] = useState(isAbsent);

  const handleConfirmEditing = () => {
    setShouldDisplayConfirmation(false);
  };

  const showBothInputs = hoursTypeToDisplay === HourType.total;

  return (
    <Popover
      open={!!anchorEl}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      {shouldDisplayConfirmation ? (
        <Box sx={styles.confirmation}>
          <Typography variant="subtitle1" color="text.secondary">
            <Translation id="projectDetails.timesheet.changeHoursConfirmation.title" />
          </Typography>
          <Box sx={styles.actionButtonsWrapper}>
            <Button size="small" variant="outlined" onClick={onClose}>
              <Translation id="button.cancel" />
            </Button>
            <Button size="small" onClick={handleConfirmEditing} sx={styles.confirmationButton}>
              <Translation id="projectDetails.timesheet.changeHoursConfirmation.confirmButton" />
            </Button>
          </Box>
        </Box>
      ) : (
        <form onSubmit={handleSubmit(handleSave)}>
          <Box sx={styles.wrapper}>
            {(showBothInputs || hoursTypeToDisplay === HourType.regular) && (
              <Box sx={styles.inputsWrapper}>
                <FormTextField
                  sx={styles.percentageRateInput}
                  name="regularWorkAmount"
                  size="small"
                  control={control}
                  label={t('projectDetails.timesheet.regularWorks')}
                  fullWidth
                  endAdornment="h"
                  hideHelperText
                  inputLabelShrink
                  autoFocus
                  onChangeParser={hourInputNumberParser}
                />
                <FormTextField
                  sx={styles.percentageRateInput}
                  name="regularWorkHourRate"
                  size="small"
                  control={control}
                  label="%"
                  fullWidth
                  endAdornment="%"
                  hideHelperText
                  inputLabelShrink
                  onChangeParser={percentageInputNumberParser}
                />
              </Box>
            )}
            {(showBothInputs || hoursTypeToDisplay === HourType.overhour) && (
              <Box sx={styles.inputsWrapper}>
                <FormTextField
                  sx={styles.percentageRateInput}
                  name="overhoursAmount"
                  size="small"
                  control={control}
                  label={t('projectDetails.timesheet.overtime')}
                  fullWidth
                  endAdornment="h"
                  hideHelperText
                  inputLabelShrink
                  onChangeParser={hourInputNumberParser}
                />
                <FormTextField
                  sx={styles.percentageRateInput}
                  name="overhourRate"
                  size="small"
                  control={control}
                  label="%"
                  fullWidth
                  endAdornment="%"
                  hideHelperText
                  inputLabelShrink
                  onChangeParser={percentageInputNumberParser}
                />
              </Box>
            )}
            <Box sx={styles.actionButtonsWrapper}>
              <Button size="small" variant="outlined" onClick={onClose}>
                <Translation id="button.cancel" />
              </Button>
              <Button type="submit" size="small" disabled={!isValid}>
                <Translation id="button.save" />
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Popover>
  );
};
