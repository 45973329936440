import { Styles } from 'styles/theme';

export const button: Styles = {
  '&&': {
    display: 'inline-flex',
    marginTop: 1,
    padding: 0,
    '&:hover': { backgroundColor: 'transparent' },
  },
};

export const stack: Styles = {
  '>.MuiButtonBase-root': {
    ml: -1,
  },

  'button > div': {
    flexGrow: 1,
  },

  width: '100%',
};
