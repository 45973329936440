import { Styles } from 'styles/theme';

export const peopleGridWrapper: Styles = {
  paddingX: 6,
};

export const filtersDashboardCardWrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden',
};

export const listDashboardCardWrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden',
};
