import { Box, Divider } from '@mui/material';

import { ProposalStatus } from 'config/data/need/ProposalStatus.enum';
import { useNeedModal } from 'hooks/useNeedModal/useNeedModal';
import type { Proposal } from 'shared/needs/Needs.types';
import type { Rejection } from 'api/actions/getRejections/getRejections.types';
import { Seniority } from 'api/types/Seniority.enum';
import { EmploymentType } from 'api/types/EmploymentType.enum';
import type { NeedDto } from 'api/types/Need.types';
import type { RateCard } from 'api/actions/getProject/getProject.types';
import { isRejection } from '../ProposalCard.utils';

import { ApprovedProposalButtons } from './approvedProposalButtons/ApprovedProposalButtons';
import { PendingProposalButtons } from './pendingProposalButtons/PendingProposalButtons';
import { RejectedProposalButtons } from './rejectedProposalButtons/RejectedProposalButtons';
import * as styles from './ProposalCardButtons.styles';
import type { AssignDisabled, ProposalCardButtonsProps } from './ProposalCardButtons.types';

const getAssignDisabled = (
  rateCard: RateCard | undefined,
  needData: NeedDto | undefined,
  proposal: Proposal | Rejection,
): AssignDisabled => {
  const isRoleOrSeniorityRateMissing =
    !rateCard ||
    !needData ||
    !rateCard.items[needData.role] ||
    !rateCard.items[needData.role].some(
      ({ seniority }) => seniority === Seniority.withoutLevel || seniority === needData.seniority,
    );
  if (isRoleOrSeniorityRateMissing) {
    return {
      value: true,
      reason: 'missingRoleRate',
    };
  }

  const isContractorWithMissingRate = proposal.person.employmentType === EmploymentType.LTSH && !proposal.person.rate;
  if (isContractorWithMissingRate) {
    return { value: true, reason: 'missingPersonRate' };
  }

  return { value: false };
};

export const ProposalCardButtons = (props: ProposalCardButtonsProps) => {
  const { proposal, status } = props;
  const { projectDetails, needData, onAccept, onAssign, onReject, onUndoAcceptation, onUndoRejection, onRemove } =
    useNeedModal();
  const assignDisabled = getAssignDisabled(projectDetails?.currentRateCard, needData, proposal);

  const isRejectionFromThisNeed = isRejection(proposal) && proposal.proposal?.need?.id === needData?.id;

  const handleAssign = () => onAssign(proposal as Proposal);
  const handleAccept = () => onAccept(proposal as Proposal);
  const handleUndoAcceptation = () => onUndoAcceptation(proposal as Proposal);
  const handleReject = () => onReject(proposal as Rejection);
  const handleUndoRejection = () => onUndoRejection(proposal as Rejection);
  const handleRemove = () => onRemove(proposal as Proposal);

  return (
    <>
      {<Divider sx={styles.divider} />}

      <Box
        sx={status === ProposalStatus.pending ? styles.threeButtonContainer : styles.twoButtonContainer}
        onClick={(e) => e.preventDefault()}
      >
        {status === ProposalStatus.approved && (
          <ApprovedProposalButtons
            assignDisabled={assignDisabled}
            onUndo={handleUndoAcceptation}
            onAssign={handleAssign}
            onRemove={handleRemove}
          />
        )}
        {status === ProposalStatus.pending && (
          <PendingProposalButtons onReject={handleReject} onAccept={handleAccept} onRemove={handleRemove} />
        )}
        {status === ProposalStatus.rejected && (
          <RejectedProposalButtons disabled={!isRejectionFromThisNeed} onUndo={handleUndoRejection} />
        )}
      </Box>
    </>
  );
};
