import { useMemo } from 'react';

import { TextField as LuxTextField } from 'lux/components';
import { TextFieldProps } from 'lux/components/atoms/textField/TextField.types';

import { createNumberKeyDownHandler } from 'utils/createNumberKeyDownHandler';

export const TextField = ({ InputProps, ...props }: TextFieldProps) => {
  const handleKeyDown = useMemo(
    () =>
      (InputProps?.onKeyDown || InputProps?.inputProps?.onKeyDown) ??
      (props.type === 'number' ? createNumberKeyDownHandler() : undefined),
    [InputProps?.onKeyDown, InputProps?.inputProps?.onKeyDown, props.type],
  );

  return (
    <LuxTextField
      InputProps={{
        ...InputProps,
        onKeyDown: handleKeyDown,
      }}
      {...props}
    />
  );
};
