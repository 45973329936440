import { Step } from 'lux/components';
import { CheckIcon } from 'lux/icons';

import { StepProgress } from 'ui/StepProgress/StepProgress';
import { Stepper } from 'ui/stepper/Stepper';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import * as styles from './NeedSteps.styles';
import type { NeedStepsProps, NeedStep } from './NeedSteps.types';

const steps: NeedStep[] = ['propose', 'accept', 'assign'];

export const NeedSteps = ({ currentStep }: NeedStepsProps) => {
  const { formatMessage } = useLocale();

  const nextStepKey: `${NeedStep}.next` | undefined = steps[currentStep] ? `${steps[currentStep]}.next` : undefined;
  const nextStepName = formatMessage({ id: AppMessages[`card.need.step.${nextStepKey || 'completed'}`] });

  return (
    <>
      <StepProgress
        totalSteps={steps.length}
        nextStepName={nextStepName}
        completedSteps={currentStep}
        nextStepPlacement="bottom"
      />

      <Stepper
        activeStep={currentStep}
        sx={styles.stepper}
        orientation="vertical"
        size="small"
        data-cy="need-steps_wrapper"
        hideConnectors
      >
        {steps.map((stepName, index) => {
          const label = formatMessage({ id: AppMessages[`card.need.step.${stepName}`] });
          return <Step data-cy={`need-step-${index}`} key={index} label={label} stepIcon={CheckIcon} />;
        })}
      </Stepper>
    </>
  );
};
