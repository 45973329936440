import { useProjectsFilters } from 'hooks/useProjectsFilters/useProjectsFilters';
import { RoleFilter as SharedRoleFilter } from 'shared/filters/roleFilter/RoleFilter';

import { RoleFilterProps } from './RoleFilter.types';

export const RoleFilter = ({ variant }: RoleFilterProps) => {
  const {
    filters: { role },
    setFilterValue,
    clearFilter,
  } = useProjectsFilters();

  const handleFilterChange = (value: string[]) => {
    setFilterValue('role', value);
  };

  const handleClearClick = () => {
    clearFilter('role');
  };

  return (
    <SharedRoleFilter
      roles={role}
      onClearClick={handleClearClick}
      onFilterChange={handleFilterChange}
      variant={variant}
    />
  );
};
