import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { UpdateClientParams } from './updateClientActions.types';

export const updateClientMutation: (clientId: string) => MutationFn<UpdateClientParams> =
  (clientId: string) => (body) => ({
    endpoint: `clients/${clientId}`,
    method: 'PATCH',
    params: body,
  });
