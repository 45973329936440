import { useState } from 'react';

import { Dialog } from '@mui/material';

import { NeedModalContextController } from 'context/needModal/needModalContextController/NeedModalContextController';

import { NeedCardModalContent } from './needCardModalContent/NeedCardModalContent';
import * as styles from './NeedCardModal.styles';
import type { NeedCardModalProps } from './NeedCardModal.types';

export const NeedCardModal = ({ projectId, needId, open, onClose }: NeedCardModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState(true);

  const handleNestedModalToggle = () => {
    setIsModalVisible((prev) => !prev);
  };

  return (
    <Dialog open={open} fullWidth onClose={onClose} sx={styles.customDialog(isModalVisible)}>
      <NeedModalContextController>
        <NeedCardModalContent
          needId={needId}
          projectId={projectId}
          onClose={onClose}
          onNestedModalToggle={handleNestedModalToggle}
        />
      </NeedModalContextController>
    </Dialog>
  );
};
