import { Stack } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

import * as styles from './CostSummary.styles';

export const CostSummarySkeleton = ({ width, variant = 'h6' }: { width: string | number; variant?: Variant }) => (
  <Stack sx={styles.costSummaryWrapper}>
    <TextSkeleton width={width} variant={variant} />
  </Stack>
);
