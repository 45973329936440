import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { archiveLeadMutation } from 'api/actions/archiveLead/archiveLeadActions';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { projectsKeys } from 'utils/queryKeys';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { UseArchiveLeadParams } from './useArchiveLead.types';

export const useArchiveLead = (params: UseArchiveLeadParams) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();

  return useMutationDomainError(['archiveProject'], archiveLeadMutation(params.projectId), {
    onSuccess: () => {
      queryClient.invalidateQueries(projectsKeys.singleProject(params.projectId));
      queryClient.invalidateQueries(projectsKeys.projectsList);
      enqueueSnackbar(
        formatMessage({ id: AppMessages['snackbar.archiveLead.success'] }, { projectName: params.projectName }),
      );
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.archiveLead.error'] }),
  });
};
