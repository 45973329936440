import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { EditUncertainPersonRequest, EditUncertainPersonResponse } from './editUncertainPersonActions.types';

export const editUncertainPersonMutation: (
  id: number,
) => MutationFn<EditUncertainPersonRequest, EditUncertainPersonResponse> = (employeeID: number) => (body) => ({
  endpoint: `/people/${employeeID}/update-uncertain`,
  method: 'PATCH',
  params: body,
});
