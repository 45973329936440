import { Styles } from 'styles/theme';

export const popoverButton = (active?: boolean, count?: number): Styles => ({
  color: 'text.secondary',
  backgroundColor: 'surface.paper.default',
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  border: '1px solid transparent',
  minHeight: 32,

  '&&&': {
    height: 'unset',
    maxHeight: 'unset',
    py: 0.25,
    px: 2,
  },

  '.MuiButton-startIcon > svg > path': {
    fill: (theme) => `${theme.palette.text.secondary}`,
  },

  '&:hover': {
    backgroundColor: 'surface.paper.default',
  },

  ...(active && {
    color: 'primary.main',
    backgroundColor: 'common.white',
    borderColor: (theme) => theme.palette.primary.main,

    '.MuiButton-startIcon > svg > path': {
      fill: (theme) => `${theme.palette.primary.main}`,
    },
  }),

  ...(count && {
    paddingRight: 1,
    '&::after': {
      content: `"${count}"`,
      fontWeight: 500,
      width: '20px',
      height: '20px',
      color: 'text.primary',
      backgroundColor: 'accent.blue.pastel',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      marginLeft: 1,
    },
  }),
});

export const popover = (spacing: number): Styles => ({
  '.MuiPopover-paper': {
    marginTop: spacing,
    backgroundColor: 'common.white',
    boxShadow: 25,
    borderRadius: 2,
    width: '360px',
  },
});

export const popoverContent: Styles = {
  paddingX: 1,
  paddingY: 2,

  '&>fieldset': {
    width: '100%',
  },

  '.MuiFormControlLabel-label': {
    typography: 'caption',
  },
};
