import { Stack } from '@mui/material';
import { Avatar, Badge, Typography, Button, Table } from 'lux/components';
import { Link } from 'react-router-dom';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { PersonStatusBadge } from 'ui/personStatusBadge/PersonStatusBadge';
import { EmploymentType } from 'api/types/EmploymentType.enum';
import { OverflowTooltipTypography } from 'ui/overflowTooltipTypography/OverflowTooltipTypography';

import { HeadRowProps } from './HeadRow.types';
import * as styles from './HeadRow.styles';

export const HeadRow = ({ projectName, person }: HeadRowProps) => {
  const { formatMessage } = useLocale();

  return (
    <Table.Row sx={styles.row}>
      <Table.Cell sx={styles.headRowCell}></Table.Cell>
      <Table.Cell padding="none" sx={styles.headRowCell}>
        <Stack gap={0.5}>
          <Typography color="text.primary" variant="caption" fontWeight={600}>
            {projectName}
          </Typography>
          <Badge isStatic color="blue" badgeContent={formatMessage({ id: AppMessages['card.need.badge.need'] })} />
        </Stack>
      </Table.Cell>
      <Table.Cell padding="none" sx={styles.headRowCell}>
        <Button
          size="small"
          sx={styles.personLink}
          component={Link}
          variant="text"
          to={`/people/${person.employeeId}`}
          target="_blank"
        >
          <Avatar image={person.picture} sx={styles.avatar} alt={`${person.firstName} ${person.lastName}`} />

          <Typography sx={styles.personDetails} color="text.primary" variant="caption" fontWeight={600}>
            <OverflowTooltipTypography
              text={`${person.firstName} ${person.lastName}`}
              color="text.primary"
              variant="caption"
            />
            <Stack direction="row" spacing={1}>
              <PersonStatusBadge status={person.availabilityStatus} availableFte={person.availableFte} />
              {person.employmentType === EmploymentType.LTSH && (
                <Badge
                  isStatic
                  color="lightGray"
                  badgeContent={formatMessage({ id: AppMessages['card.need.comparisonTable.personDetails.outsource'] })}
                />
              )}
            </Stack>
          </Typography>
          {formatMessage({ id: AppMessages['button.seeDetails'] })}
        </Button>
      </Table.Cell>
      <Table.Cell width={48} padding="none" sx={styles.headRowCell}></Table.Cell>
    </Table.Row>
  );
};
