import { Stack } from '@mui/material';

import { GroupByProject } from 'shared/needs/needsFilters/groupByProject/GroupByProject';
import { NeedsSorting } from 'shared/needs/needsFilters/needsSorting/NeedsSorting';

import { ResetFiltersButton } from './resetFiltersButton/ResetFiltersButton';

export const NeedsSortingBar = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="center">
    <ResetFiltersButton />
    <Stack gap={1} direction="row">
      <GroupByProject />
      <NeedsSorting />
    </Stack>
  </Stack>
);
