import { FormControlLabel } from '@mui/material';
import { Radio } from 'lux/components';

import { Filter } from 'ui/filter/Filter';
import { filtersData } from 'config/data/projects/filters';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useProjectsFilters } from 'hooks/useProjectsFilters/useProjectsFilters';

import { TeamFilterProps } from './TeamFilter.types';

export const TeamFilter = ({ variant }: TeamFilterProps) => {
  const { formatMessage } = useLocale();
  const {
    filters: { team },
    toggleFilter,
  } = useProjectsFilters();

  return (
    <Filter variant={variant} label={formatMessage({ id: AppMessages['projects.filters.team.label'] })}>
      {filtersData['team'].map(({ labelTranslation, value }) => (
        <FormControlLabel
          key={value}
          label={formatMessage({ id: AppMessages[labelTranslation] })}
          control={<Radio value={value} checked={team === value} onClick={() => toggleFilter('team', value)} />}
        />
      ))}
    </Filter>
  );
};
