import Joi from 'joi';

import { AppMessages } from 'i18n/messages';
import { MAX_SUPPORTED_DATE, parseDate } from 'utils/dateUtils';
import { intl } from 'context/locale/intl/Intl';

const { formatMessage } = intl.currentIntl;

export const addDepositSchema = (startDate: string, endDate: string) =>
  Joi.object({
    amount: Joi.number()
      .min(0)
      .required()
      .label(
        formatMessage({
          id: AppMessages['projectDetails.billingSummary.workStatementDetails.deposits.amount'],
        }),
      ),
    paymentDue: Joi.date()
      .min(parseDate(startDate))
      .max(endDate ? parseDate(endDate) : MAX_SUPPORTED_DATE)
      .required()
      .label(
        formatMessage({
          id: AppMessages['projectDetails.billingSummary.workStatementDetails.deposits.paymentDue'],
        }),
      ),
  });
