import { Translation } from 'i18n/messages';
import { useNeedsFilters } from 'hooks/useNeedsFilters/useNeedsFilters';
import { ActiveFilters } from 'ui/activeFilters/ActiveFilters';
import { filtersData } from 'config/data/needs/filters';
import { NeedsFilters } from 'context/needsFilters/needsFiltersContext/NeedsFiltersContext.types';
import { ActiveFilter } from 'ui/activeFilters/ActiveFilters.types';
import { createActiveFilters } from 'ui/activeFilters/createActiveFilters';

const translationsBasedOnName: { name: string; labelTranslation: Translation }[] = [
  { name: 'fte', labelTranslation: 'filters.fte.other.value' },
  { name: 'availabilityFrom', labelTranslation: 'filters.availability.from.value' },
  { name: 'availabilityTo', labelTranslation: 'filters.availability.to.value' },
  { name: 'search', labelTranslation: 'filters.search.value' },
];

export const NeedsActiveFilters = () => {
  const { filters, toggleFilter } = useNeedsFilters();

  const handleFilterDelete = ({ name, value }: ActiveFilter) => {
    toggleFilter(name as keyof NeedsFilters, value);
  };

  const activeFiltersData = createActiveFilters(filters, {
    exclude: ['owner', 'sort', 'groupBy'],
    translationsBasedOnValue: filtersData,
    translationsBasedOnName: translationsBasedOnName,
  });

  return <ActiveFilters activeFiltersData={activeFiltersData} onFilterDelete={handleFilterDelete} />;
};
