import { CardHeader, Tabs } from 'lux/components';
import { Box, Stack } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useAssignmentInfo } from 'hooks/useAssignmentInfo/useAssignmentInfo';
import { ProjectBillingSummaryDataContextController } from '../../../context/projectBillingSummary/projectBillingSummaryDataContextController/ProjectBillingSummaryDataContextController';
import { ProjectBillingSummaryContextController } from '../../../context/projectBillingSummary/projectBillingSummaryContextController/ProjectBillingSummaryContextController';
import { CardWrapper } from 'ui/cardWrapper/CardWrapper';

import * as styles from './TeamDetails.styles';
import { AssignmentInfoCard } from './assignmentInfoCard/AssignmentInfoCard';
import { useTeamDetailsTabs } from './useTeamDetailsTabs/useTeamDetailsTabs';

export const TeamDetails = () => {
  const { formatMessage } = useLocale();
  const { projectDetails, allowProjectEdition } = useProjectDetails();
  const { id, stage, activeAssignments } = projectDetails;
  const { isOpen: isAssignmentInfoOpen } = useAssignmentInfo();

  const { activeTabIndex, onTabChange, tabs } = useTeamDetailsTabs({
    hasActiveAssignments: Boolean(activeAssignments),
    projectStage: stage,
  });

  return (
    <Stack direction="row" height="100%" spacing={3}>
      <CardWrapper>
        <Box width="100%">
          <CardHeader title={formatMessage({ id: AppMessages['projectDetails.teamDetails.header'] })} />
          <Tabs>
            <Tabs.TabList value={activeTabIndex} onChange={onTabChange} sx={styles.tabList}>
              {tabs.map((tab) => (
                <tab.tab key={tab.key} projectId={id} />
              ))}
            </Tabs.TabList>
            {tabs.map((tab, index) => (
              <Tabs.TabPanel key={tab.key} value={activeTabIndex} index={index}>
                <tab.panel projectId={id} allowProjectEdition={allowProjectEdition} />
              </Tabs.TabPanel>
            ))}
          </Tabs>
        </Box>
      </CardWrapper>
      <ProjectBillingSummaryContextController>
        <ProjectBillingSummaryDataContextController>
          {isAssignmentInfoOpen && (
            <Box sx={{ width: 500 }}>
              <AssignmentInfoCard />
            </Box>
          )}
        </ProjectBillingSummaryDataContextController>
      </ProjectBillingSummaryContextController>
    </Stack>
  );
};
