import { FormProvider } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useSnackbar } from 'notistack';
import { Button } from 'lux/components';
import { DeleteIcon } from 'lux/icons';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';
import { UncertainEmploymentType } from 'config/data/people/uncertain';
import { UncertainPersonForm } from 'shared/people/UncertainPersonForm/UncertainPersonForm';
import { uncertainPersonSchema } from 'shared/people/UncertainPersonForm/UncertainPerson.schema';
import { useEditUncertainPerson } from 'hooks/useEditUncertain/useEditUncertain';
import { usePersonAssignments } from 'hooks/usePersonAssignments/usePersonAssignments';
import { usePersonData } from 'hooks/usePersonData/usePersonData';
import { EmploymentType } from 'api/types/EmploymentType.enum';
import { convertFteToOptionValue, Fte } from 'config/data/fteList';
import { UncertainPersonFormValues } from 'shared/people/UncertainPersonForm/UncertainPersonForm.types';
import { createUncertainPersonPayload } from 'shared/people/UncertainPersonForm/createUncertainPersonPayload';
import { Seniority } from 'api/types/Seniority.enum';
import { Translation } from 'ui/translation/Translation';
import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';

import { EditUncertainModalProps } from './EditUncertainContent.types';

export const EditUncertainContent = ({ onClose, onRemove }: EditUncertainModalProps) => {
  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const {
    person: {
      employeeId,
      firstName,
      lastName,
      email,
      seniority,
      departmentCode,
      fte,
      employmentType,
      currentPersonRate,
    },
  } = usePersonData();

  const { mutate, isLoading } = useEditUncertainPerson(employeeId);
  const { data: assignmentsData, isLoading: isLoadingAssignments } = usePersonAssignments(String(employeeId));

  const defaultValues: UncertainPersonFormValues = {
    firstName,
    lastName,
    email,
    departmentCode,
    seniorityEnabled: seniority !== Seniority.withoutLevel,
    seniority: seniority ?? '',
    fte: convertFteToOptionValue(fte),
    fteCustom: convertFteToOptionValue(fte) === Fte.other ? String(fte) : '',
    employmentType:
      employmentType === EmploymentType.LTSH ? UncertainEmploymentType.subcontractor : UncertainEmploymentType.inhouse,
    rate: currentPersonRate ? String(currentPersonRate.rate) : '',
    currency: currentPersonRate ? currentPersonRate.currency : '',
  };

  const form = useIntlForm<UncertainPersonFormValues>({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(uncertainPersonSchema()),
  });

  const onSubmit = (data: UncertainPersonFormValues) => {
    const payload = createUncertainPersonPayload(data);

    mutate(payload, {
      onSuccess: () => {
        enqueueSnackbar(
          formatMessage(
            { id: AppMessages['people.editUncertain.snackbar.success'] },
            { fullName: `${payload.firstName} ${payload.lastName}` },
          ),
        );
        onClose();
      },
    });
  };

  const handleRemove = () => {
    if (assignmentsData?.assignments.some((assignment) => assignment.status !== AssignmentStatus.left)) {
      enqueueSnackbar(formatMessage({ id: AppMessages['people.removeUncertain.snackbar.assigned'] }), {
        variant: 'error',
      });

      return;
    }

    onClose();
    onRemove();
  };

  return (
    <DialogInnerWrapper
      title={formatMessage({ id: AppMessages['people.editUncertain.title'] })}
      loading={isLoading}
      onClose={onClose}
      submitText={formatMessage({ id: AppMessages['people.editUncertain.button.submit'] })}
      onSubmit={form.handleSubmit(onSubmit)}
      isFormValid={form.formState.isValid}
      additionalButtons={
        <Button
          variant="outlinedGray"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={handleRemove}
          disabled={isLoadingAssignments}
        >
          <Translation id="people.editUncertain.button.delete" />
        </Button>
      }
    >
      <FormProvider {...form}>
        <UncertainPersonForm operation="edit" loading={isLoading} />
      </FormProvider>
    </DialogInnerWrapper>
  );
};
