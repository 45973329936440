import { ChangeEvent } from 'react';

import { FormControl } from '@mui/material';
import { Select } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useAssignmentsFilters } from 'hooks/useAssignmentsFilters/useAssignmentsFilters';
import { AppMessages } from 'i18n/messages';
import { filtersData } from 'config/data/assignments/filters';

import * as styles from './AssignmentsSorting.styles';

export const AssignmentSorting = () => {
  const { filters, toggleFilter } = useAssignmentsFilters();
  const { formatMessage } = useLocale();

  const handleSortingChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    toggleFilter('sort', event.target.value);
  };

  const sortOptions = filtersData.sort.map((option) => ({
    id: option.labelTranslation,
    children: formatMessage({ id: option.labelTranslation }),
    value: option.value,
  }));

  return (
    <FormControl variant="outlined">
      <Select
        value={filters.sort}
        onChange={handleSortingChange}
        label={formatMessage({ id: AppMessages['sorting.label'] })}
        size="small"
        sx={styles.select}
        options={sortOptions}
      />
    </FormControl>
  );
};
