import { ElementType, forwardRef, Ref } from 'react';

import { Box } from '@mui/material';
import { Button, Tooltip } from 'lux/components';

import * as styles from './FloatingToolbar.styles';
import { FloatingToolbarProps, ToolbarButtonProps } from './FloatingToolbar.types';

export const ToolbarButton = forwardRef(
  <C extends ElementType>(
    { tooltipTitle, tooltipPlacement = 'top', Icon, onClick, ...props }: ToolbarButtonProps<C>,
    ref: Ref<HTMLButtonElement>,
  ) => (
    <Tooltip placement={tooltipPlacement} title={tooltipTitle}>
      <Button iconOnly size="small" variant="text" color="primary" onClick={onClick} {...props} ref={ref}>
        <Icon />
      </Button>
    </Tooltip>
  ),
);

export const FloatingToolbar = ({ placement = 'top-end', arrow, children, buttons }: FloatingToolbarProps) => (
  <Tooltip
    componentsProps={{
      tooltip: {
        sx: styles.tooltip,
      },
      arrow: {
        sx: styles.arrow,
      },
    }}
    arrow={arrow}
    placement={placement}
    title={<Box sx={styles.tooltipsContainer}>{buttons}</Box>}
  >
    {children}
  </Tooltip>
);

FloatingToolbar.Button = ToolbarButton;
