import { Styles } from 'styles/theme';

export const employeeBasicInfo: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  flexShrink: 1,
  overflow: 'hidden',
};

export const employeeInfoWrapper: Styles = {
  overflow: 'hidden',
};

export const avatar: Styles = {
  width: 32,
  height: 32,
};
