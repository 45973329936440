import { Card } from 'ui/card/Card';

import { NeedCardFooterSkeleton } from './needCardFooter/NeedCardFooter.skeleton';
import { NeedCardHeaderSkeleton } from './needCardHeader/NeedCardHeader.skeleton';
import * as styles from './NeedCard.styles';

export const NeedCardSkeleton = () => (
  <Card sx={styles.needCard}>
    <Card.Header>
      <NeedCardHeaderSkeleton />
    </Card.Header>

    <Card.Footer>
      <NeedCardFooterSkeleton />
    </Card.Footer>
  </Card>
);
