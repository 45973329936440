import { QueryFn } from 'hooks/useQuery/useQuery.types';

import { GetPersonProposalsParams, GetPersonProposalsResponse } from './getPersonProposals.types';

export const getPersonProposalsQuery: (
  personId: string,
) => QueryFn<GetPersonProposalsParams, GetPersonProposalsResponse> = (personId: string) => (args) => ({
  endpoint: `/people/${personId}/proposals`,
  args,
});
