import { useLayoutEffect } from 'react';

import { Navigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { AppRoute } from 'routing/AppRoute.enum';
import { currentUserKey } from 'utils/queryKeys';
import { useAuth } from '../../hooks/useAuth/useAuth';

export const Logout = () => {
  const queryClient = useQueryClient();
  const { logout } = useAuth();

  useLayoutEffect(() => {
    logout();
    queryClient.setQueryData(currentUserKey, null);
  }, []);

  return <Navigate to={`${AppRoute.login}?manual_logout=true`} />;
};
