import { useNeedsFilters } from 'hooks/useNeedsFilters/useNeedsFilters';
import { RoleFilter as SharedRoleFilter } from 'shared/filters/roleFilter/RoleFilter';

import { RoleFilterProps } from './RoleFilter.types';

export const RoleFilter = ({ variant }: RoleFilterProps) => {
  const {
    filters: { roles },
    setFilterValue,
    clearFilter,
  } = useNeedsFilters();

  const handleFilterChange = (value: string[]) => {
    setFilterValue('roles', value);
  };

  const clearRoles = () => {
    clearFilter('roles');
  };

  return (
    <SharedRoleFilter variant={variant} roles={roles} onFilterChange={handleFilterChange} onClearClick={clearRoles} />
  );
};
