import { Absence } from 'api/actions/getPersonAbsences/getPersonAbsences.types';
import { Assignment } from 'api/actions/getPersonAssignments/getPersonAssignments.types';
import { PersonProposal } from 'api/actions/getPersonProposals/getPersonProposals.types';
import { parseDate } from 'utils/dateUtils';

import { TimelineEventProps, TimelineEventType } from './timelineEventsGrid/timelineEvent/TimelineEvent.types';

export const mapToTimelineEvents = (
  assignments: Assignment[],
  proposals: PersonProposal[],
  absences: Absence['timePeriod'],
): TimelineEventProps[] => {
  const events: TimelineEventProps[] = [];

  assignments.forEach(({ id, startDate, endDate, project, status }) =>
    events.push({
      id,
      startDate: parseDate(startDate),
      endDate: endDate ? parseDate(endDate) : undefined,
      type: TimelineEventType.assignment,
      status,
      name: project.name,
    }),
  );

  proposals.forEach(({ id, need }) =>
    events.push({
      id,
      startDate: parseDate(need.startDate),
      endDate: need.endDate ? parseDate(need.endDate) : undefined,
      type: TimelineEventType.proposal,
      status: 'Proposed',
      name: need.project.name,
    }),
  );

  absences.forEach(({ from, to }) =>
    events.push({
      id: `${from}`,
      startDate: parseDate(from),
      endDate: parseDate(to),
      type: TimelineEventType.absence,
      name: 'Leave',
    }),
  );

  return events.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
};
