import { enqueueSnackbar } from 'notistack';
import { MutationKey, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { MutationFn } from 'hooks/useMutation/useMutation.types';
import { useMutation } from 'hooks/useMutation/useMutation';
import { getApiError } from '../../api/utils/getApiError';

import { DomainError, UseMutationDomainErrorOptions } from './useMutationDomainError.types';

export const useMutationDomainError = <TData = unknown, TParams = unknown, TContext = unknown>(
  mutationKey: MutationKey,
  mutation: MutationFn<TParams, TData, DomainError>,
  options?: UseMutationDomainErrorOptions<TData, AxiosError<DomainError>, TParams, TContext>,
) => {
  const _options: UseMutationOptions<TData, AxiosError<DomainError>, TParams, TContext> = {
    ...options,
    onError: (error) => {
      enqueueSnackbar(getApiError(error, { fallback: options?.fallbackErrorMessage }), { variant: 'error' });
    },
  };

  return useMutation(mutationKey, mutation, _options);
};
