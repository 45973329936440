import { ComponentType, ReactNode } from 'react';

import { Box } from '@mui/material';
import { Chip } from 'lux/components';

import * as styles from './Chips.styles';

export type ChipsProps = {
  children: ReactNode;
};

type ChipsComponent = {
  Chip: typeof Chip;
} & ComponentType<ChipsProps>;

export const Chips: ChipsComponent = ({ children, ...props }: ChipsProps) => (
  <Box {...props} sx={styles.chips}>
    {children}
  </Box>
);

Chips.Chip = Chip;
