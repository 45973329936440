import { Typography } from '@mui/material';

import { TableCell, TableCellCorner, TableHead, TableRow } from '../tableComponents/tableComponents';
import { useGetIsFirstDayOfProject } from 'hooks/useGetIsFirstDayOfProject/useGetIsFirstDayOfProject';
import { useProjectTimesheetData } from '../../../../../hooks/useProjectTimesheetData/useProjectTimesheetData';
import { useProjectStartDay } from 'hooks/useProjectStartDay/useProjectStartDay';

import * as styles from './TimesheetTableHead.styles';

export const TimesheetTableHead = () => {
  const { timesheetMonthDatesParsed: days } = useProjectTimesheetData();
  const getIsFirstDayOfProject = useGetIsFirstDayOfProject();
  const { projectStartDay, daysInMonth } = useProjectStartDay();

  return (
    <TableHead sx={styles.projectStartDayMarker(projectStartDay, daysInMonth)}>
      <TableRow>
        <TableCellCorner />
        {days.map((day) => {
          const { isProjectStartDayMarker, isAfterProjectEnd, isBeforeProjectStart } = getIsFirstDayOfProject(
            Number(day.number),
          );

          return (
            <TableCell
              isHeader
              key={day.number}
              isCurrentDay={day.isCurrent}
              isWeekend={day.isWeekend}
              isAbsent={day.isHoliday}
              className={day.isWeekend || day.isHoliday ? 'Absence' : undefined}
              isOutsideProjectDates={isAfterProjectEnd || isBeforeProjectStart}
              sx={styles.headerCell(day.isCurrent, isProjectStartDayMarker)}
            >
              <Typography>{day.name}</Typography>
              <Typography>{day.number}</Typography>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
