import { SxProps } from '@mui/material';

export const dimmedCell: SxProps = {
  backgroundColor: 'accent.lightGray',
  '&&& p': {
    color: 'text.secondary',
  },
  '& p:first-of-type': {
    marginLeft: 4,
  },
};
