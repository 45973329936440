import { Styles } from 'styles/theme';

export const dialogContent: Styles = {
  paddingBottom: 3,
};

export const dialogActions: Styles = {
  justifyContent: 'space-between',
};

export const cancelButton: Styles = {
  '&.MuiButton-root': {
    paddingX: 1,
  },
};
