import { Seniority } from 'api/types/Seniority.enum';

import { AssignWithoutNeedFormValues } from './AssignWithoutNeedModal.types';

export const defaultValues: AssignWithoutNeedFormValues = {
  role: '',
  seniority: Seniority.withoutLevel,
  fte: '',
  customFte: '',
  startDate: null,
  endDate: null,
  temporary: false,
  customRateEnabled: false,
  customRate: '',
};
