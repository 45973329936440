import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { ProjectStage } from 'api/types/ProjectStage.enum';
import { TabNeeds } from '../tabNeeds/TabNeeds';
import { TabNeedsPanel } from '../tabNeeds/TabNeedsPanel';
import { TabRejectedPanel } from '../tabRejected/TabRejectedPanel';
import { TabAssigned } from '../tabAssigned/TabAssigned';
import { TabRejected } from '../tabRejected/TabRejected';
import { TabAssignedPanel } from '../tabAssigned/TabAssignedPanel';
import { TeamDetailsTab } from '../TeamDetails.types';

const needs = { key: TeamDetailsTab.needs, tab: TabNeeds, panel: TabNeedsPanel };
const assigned = { key: TeamDetailsTab.assigned, tab: TabAssigned, panel: TabAssignedPanel };
const rejected = { key: TeamDetailsTab.rejected, tab: TabRejected, panel: TabRejectedPanel };

const getTabs = (projectStage: string) => {
  if (projectStage === ProjectStage.closed) {
    return [assigned, rejected];
  }

  return [assigned, needs, rejected];
};

const getInitialTab = (hasActiveAssignments: boolean, projectStage: string, redirectParam: string | null) => {
  if (redirectParam) {
    const tabs = getTabs(projectStage);

    const index = tabs.findIndex((tab) => tab.key === redirectParam);

    if (index !== -1) {
      return index;
    }
  }

  if (hasActiveAssignments) {
    return 0;
  }

  return 1;
};

export const useTeamDetailsTabs = ({
  hasActiveAssignments,
  projectStage,
}: {
  hasActiveAssignments: boolean;
  projectStage: string;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const [activeTabIndex, setActiveTabIndex] = useState<number>(() =>
    getInitialTab(hasActiveAssignments, projectStage, tab),
  );

  const onTabChange = (e: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
    searchParams.delete('tab');
    setSearchParams(searchParams);
  };

  const currentTabs = getTabs(projectStage);

  useEffect(() => {
    if (activeTabIndex >= currentTabs.length) {
      setActiveTabIndex(currentTabs.length - 1);
    }
  }, [projectStage]);

  return { activeTabIndex, tabs: currentTabs, onTabChange };
};
