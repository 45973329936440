import { Box } from '@mui/material';
import { Grid } from 'lux/components';

import { Skeleton } from 'ui/skeleton/Skeleton';
import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

import * as styles from './EmployeeCardFooter.styles';

export const EmployeeCardFooterSkeleton = ({
  withReason,
  withChips,
}: {
  withReason?: boolean;
  withChips?: boolean;
}) => (
  <Box sx={styles.employeeCardFooter}>
    <Grid item xs sx={styles.skillsWrapper}>
      {withChips && (
        <>
          <Skeleton width={52} height={24} />
          <Skeleton width={52} height={24} />
          <Skeleton width={52} height={24} />
        </>
      )}
      {withReason && <TextSkeleton width={320} variant="caption" />}
    </Grid>
  </Box>
);
