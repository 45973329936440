import type { Styles } from 'styles/theme';

export const card: Styles = {
  backgroundColor: 'surface.paper.default',

  '&': {
    border: (theme) => `1px solid ${theme.palette.border.default}`,
    borderRadius: 3,
    cursor: 'default',
  },

  '&&:hover': {
    backgroundColor: 'surface.paper.default',
  },
};
