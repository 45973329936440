import { alpha } from '@mui/system';

import { Styles } from 'styles/theme';

import { TimelineEventType, TimelineEventStyleParams } from './TimelineEvent.types';

export const timelineEvent = ({
  type,
  shouldWrapBadge,
  eventEndsAfterPeriod,
  eventStartsBeforePeriod,
}: TimelineEventStyleParams): Styles => ({
  backgroundColor: 'common.white',
  border: (theme) => `1px solid ${theme.palette.border.default}`,
  borderRadius: 2,
  paddingX: 2,
  paddingY: 3,
  height: '100%',
  position: 'relative',
  display: 'flex',
  maxHeight: 98,
  justifyContent: 'center',

  ...(type === TimelineEventType.assignment &&
    shouldWrapBadge && {
      justifyContent: 'flex-start',
      alignItems: 'center',
    }),

  ...(type === TimelineEventType.proposal && {
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingY: 0.5,
    backgroundColor: (theme) => alpha(theme.palette.common.white, 0.75),
  }),

  ...(type === TimelineEventType.absence && {
    backgroundColor: (theme) => alpha(theme.palette.common.white, 0.6),
    boxShadow: (theme) => theme.luxShadows[500],
    zIndex: 20,
    maxHeight: '100%',
  }),

  ...(eventStartsBeforePeriod && {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: 'none',
  }),

  ...(eventEndsAfterPeriod && {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 'none',
  }),
});
