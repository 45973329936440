import Joi from 'joi';

import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';
import { MAX_SUPPORTED_DATE, formatDate, parseDate } from 'utils/dateUtils';

export const planAssignmentRemovalSchema = (projectStartDate: string) => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object({
    plannedRemovalDate: Joi.date()
      .greater(parseDate(projectStartDate))
      .max(MAX_SUPPORTED_DATE)
      .messages({
        'date.base': formatMessage(
          { id: AppMessages['validation.date'] },
          { label: formatMessage({ id: AppMessages['assignment.planRemoval.plannedRemovalDate.label'] }) },
        ),
        'date.greater': formatMessage(
          { id: AppMessages['validation.greater'] },
          {
            label: formatMessage({ id: AppMessages['assignment.planRemoval.plannedRemovalDate.label'] }),
            limit: projectStartDate,
          },
        ),
        'date.max': formatMessage(
          { id: AppMessages['validation.max'] },
          {
            label: formatMessage({ id: AppMessages['assignment.removeImmediately.removalDate.label'] }),
            limit: formatDate(MAX_SUPPORTED_DATE),
          },
        ),
      }),
  });
};
