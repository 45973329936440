export enum DepartmentCode {
  am = 'AC',
  android = 'ANDROID',
  archit = 'ARCHIT',
  ba = 'BA',
  board = 'BOARD',
  devops = 'DEVOPS',
  fin = 'FIN',
  front = 'FRONT',
  hr = 'HR',
  ios = 'iOS',
  it = 'IT',
  java = 'JAVA',
  manage = 'MANAGE',
  marketin = 'MARKETIN',
  nmd = 'NMD',
  node = 'NODE',
  others = 'OTHERS',
  php = 'PHP',
  pm = 'PM',
  qa = 'QA',
  qaa = 'QAA',
  rp = 'RP',
  ruby = 'RUBY',
  sales = 'SALES',
  secspec = 'SECSPEC',
}

export enum StandardDepartmentCode {
  ARCHIT = 'ARCHIT',
  BA = 'BA',
  DEVOPS = 'DEVOPS',
  FRONT = 'FRONT',
  JAVA = 'JAVA',
  NMD = 'NMD',
  NODE = 'NODE',
  PHP = 'PHP',
  PM = 'PM',
  QA = 'QA',
  QAA = 'QAA',
}

export enum InvisibleDepartmentCode {
  am = 'AC',
  board = 'BOARD',
  fin = 'FIN',
  hr = 'HR',
  it = 'IT',
  marketin = 'MARKETIN',
  others = 'OTHERS',
  rp = 'RP',
  sales = 'SALES',
}
