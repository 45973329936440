import { Styles } from 'styles/theme';

export const headerTitle: Styles = {
  display: 'flex',
  alignItems: 'flex-start',
};
export const headerActions: Styles = {
  marginLeft: 'auto',
  flexShrink: 0,
};

export const buttonWrapper: Styles = {
  width: '100%',
};
