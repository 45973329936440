import { useState } from 'react';

import { Stack, Typography } from '@mui/material';

import { useAssignmentsFilters } from 'hooks/useAssignmentsFilters/useAssignmentsFilters';
import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';
import { useAssignments } from 'hooks/useAssignments/useAssignments';
import { useProject } from 'hooks/useProject/useProject';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useRejections } from 'hooks/useRejections/useRejections';
import { groupAssignmentsByEndDate, mapAssignmentData } from 'shared/assignments/Assignments.functions';
import { RejectionSource } from 'config/data/rejection/RejectionSource.enum';
import { EmptyState } from '../../../../ui/emptyState/EmptyState';
import { useLocale } from '../../../../hooks/useLocale/useLocale';
import { AssignmentId } from 'api/types/AssignmentType.enum';

import { AssignmentAccordion } from './assignmentAccordion/AssignmentAccordion';
import * as styles from './AssignmentList.styles';
import { AssignmentListSkeleton } from './AssignmentList.skeleton';

export const AssignmentsList = () => {
  const [expandedAssignment, setExpandedAssignment] = useState<string | null>();

  const { filters } = useAssignmentsFilters();
  const { projectDetails } = useProjectDetails();
  const { id: projectId } = projectDetails;
  const { t } = useLocale();
  const { data: assignmentData, isLoading: isAssignmentsLoading } = useAssignments({
    ...filters,
    project: projectId,
    status: [AssignmentStatus.left],
  });
  const { data: projectData, isLoading: isProjectLoading } = useProject(projectId);
  const { data: rejectionData, isLoading: isRejectionLoading } = useRejections({
    project: projectId,
    source: [RejectionSource.assignment],
  });

  const handleOpenAssignment =
    (assignmentId: AssignmentId) => (event: React.SyntheticEvent, isExpandedAssignment: boolean) => {
      setExpandedAssignment(isExpandedAssignment ? assignmentId : null);
    };

  const assignments = mapAssignmentData(assignmentData?.pages, rejectionData?.pages);

  const groupedAssignments = assignments && groupAssignmentsByEndDate(assignments);

  if (isAssignmentsLoading || isProjectLoading || isRejectionLoading) {
    return <AssignmentListSkeleton />;
  }

  const shouldRenderAssignments = groupedAssignments && groupedAssignments?.length > 0 && projectData;

  if (!shouldRenderAssignments) {
    return (
      <EmptyState
        illustration="emptyState"
        title={t('projectDetails.tab.assigned.history.empty.title')}
        message={t('projectDetails.tab.assigned.history.empty.subtitle')}
      />
    );
  }

  return (
    <>
      {groupedAssignments.map(([date, assignments]) => (
        <Stack key={date}>
          <Typography color="text.secondary" variant="caption" sx={styles.dateLabel}>
            {date}
          </Typography>
          <Stack>
            {assignments.map((assignment) => (
              <AssignmentAccordion
                key={assignment.id}
                assignment={assignment}
                projectCurrency={projectData.currency}
                expandedAssignment={expandedAssignment}
                onOpenAssignment={handleOpenAssignment(assignment.id)}
              />
            ))}
          </Stack>
        </Stack>
      ))}
    </>
  );
};
