import { Styles } from 'styles/theme';

export const wrapper: Styles = {
  display: 'flex',
  justifyContent: 'flex-end',
  flex: 1,
};

export const icon: Styles = {
  color: 'warning.main',
  width: 20,
  height: 20,
};

export const amount = (amount: number | undefined): Styles => ({
  width: 24,
  fontSize: 14,
  lineHeight: '20px',
  textAlign: 'center',
  color: 'text.primary',

  ...(!Number.isInteger(amount) && { mr: 2 }),
});
