import { AvailabilityPerson } from 'api/actions/getAvailability/getAvailability.types';

export type SplitValueProps = {
  data: {
    [key: string]: number;
  };
  totalInDepartment: number;
  showSeniorityHeaders?: boolean;
  people: AvailabilityPerson[];
  period: string;
  department: string;
};

export enum SeniorityLevel {
  junior = 'junior',
  regular = 'regular',
  senior = 'senior',
}
