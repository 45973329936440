import { GetProjectsParams, GetProjectsResponse } from 'api/actions/getProjects/getProjectsAction.types';
import { getInfiniteProjectsQuery } from 'api/actions/getProjects/getProjectsActions';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useInfiniteQuery } from 'hooks/useInfiniteQuery/useInfiniteQuery';
import { parseSortFilter } from 'utils/filters/filters';
import { getNextPageParam } from 'api/utils/getNextPageParam';
import { projectsKeys } from 'utils/queryKeys';

import { UseProjectsOptions, UseProjectsParams } from './useProjects.types';

export const useProjects = (params: UseProjectsParams, options: UseProjectsOptions = { enabled: true }) => {
  const { isAuthenticated } = useAuth();
  const queryKey = [...projectsKeys.projectsList, params];

  let args = {};

  if (params?.search) {
    args = { ...args, name: params.search };
  }

  if (params?.codename) {
    args = { ...args, codename: params.codename };
  }

  if (params?.sort) {
    const { field, order } = parseSortFilter(params.sort);

    args = {
      ...args,
      [`sort[${field}]`]: order,
    };
  }

  if (params?.type) {
    args = { ...args, type: params.type };
  }

  if (params?.stage) {
    args = { ...args, stage: params.stage };
  }

  if (params?.role) {
    args = { ...args, role: params.role };
  }

  if (params?.team) {
    args = { ...args, planning: [params.team] };
  }

  if (params?.tag) {
    args = { ...args, tag: params.tag.filter((tag) => tag !== '') };
  }

  if (params?.clientName) {
    args = { ...args, clientName: params.clientName };
  }

  return useInfiniteQuery<GetProjectsParams, GetProjectsResponse>(queryKey, getInfiniteProjectsQuery, {
    cursorKey: 'page',
    enabled: isAuthenticated && options?.enabled,
    refetchOnWindowFocus: false,
    startPage: 1,
    args: {
      ...args,
      limit: params.size || 25,
    },
    getNextPageParam: getNextPageParam,
  });
};
