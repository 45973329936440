import { useCallback } from 'react';

import { joiResolver } from '@hookform/resolvers/joi';
import { Typography, InlineEdit } from 'lux/components';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useEditMode } from 'hooks/useEditMode/useEditMode';
import * as styles from 'ui/form/inlineEdit/textFieldEdit/TextFieldEdit.styles';
import { codenameSchema } from '../ProjectDetails.utils';
import { useProjects } from 'hooks/useProjects/useProjects';

import { CodenameTextFieldEditProps } from './CodenameTextFieldEdit.types';

export const CodenameTextFieldEdit = ({
  codename,
  projectId,
  onSubmit,
  loading,
  disabled,
}: CodenameTextFieldEditProps) => {
  const { formatMessage } = useLocale();
  const { inputRef, isEditMode, setOpenEditMode, setCloseEditMode, onFormSubmit } = useEditMode(onSubmit, disabled);
  const { control, handleSubmit, watch, setError, clearErrors, formState } = useIntlForm({
    defaultValues: { codename },
    mode: 'all',
    resolver: joiResolver(codenameSchema()),
  });

  const codenameValue = watch('codename').toLowerCase().trim();
  const { refetch, isInitialLoading: isLoading } = useProjects(
    { codename: codenameValue.trim(), size: 2 },
    { enabled: false },
  );

  const checkCodename = useCallback(async () => {
    if (!codenameValue) return;

    const response = await refetch();
    const isDuplicateCodename = response.data?.pages[0].projects
      .filter((project) => project.id !== projectId)
      .some((project) => project.codename?.toLowerCase() === codenameValue);

    if (isDuplicateCodename) {
      setError('codename', {
        message: formatMessage({ id: AppMessages['projectDetails.accordions.details.codename.error.exits'] }),
      });
    } else if (codenameValue.length <= 15) {
      clearErrors('codename');
    }
  }, [codenameValue]);

  const handleCodenameSubmit = async () => {
    await checkCodename();

    if (!formState.errors.codename) {
      const submitForm = handleSubmit(onFormSubmit);
      await submitForm();
    }
  };

  const handleCancel = () => {
    setCloseEditMode();
    control._formValues['codename'] = codename;
    clearErrors('codename');
  };

  return (
    <InlineEdit
      header={formatMessage({ id: AppMessages['projectDetails.accordions.details.codename'] })}
      isActive={isEditMode}
      onDataDisplayClick={setOpenEditMode}
      onCancel={handleCancel}
      onSubmit={handleCodenameSubmit}
      loading={loading || isLoading}
      disabled={disabled || isLoading}
      submitDisabled={!!formState.errors.codename}
      input={
        <FormTextField
          name="codename"
          control={control}
          label={formatMessage({ id: AppMessages['projectDetails.accordions.details.codename'] })}
          inputRef={inputRef}
          size="small"
          autoFocus
          fullWidth
          maxLength={15}
        />
      }
    >
      <Typography variant="caption" sx={styles.editModeTypography}>
        {codename ? codename : '-'}
      </Typography>
    </InlineEdit>
  );
};
