import { useCallback } from 'react';

import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useTitle } from 'hooks/useTitle/useTitle';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { AppMessages } from 'i18n/messages';
import { useLocalStorage } from 'hooks/useLocalStorage/useLocalStorage';

import * as styles from './Project.styles';
import { ProjectHeader } from './projectHeader/ProjectHeader';
import { Sidebar } from './sidebar/Sidebar';

export const Project = () => {
  const { formatMessage } = useLocale();
  const {
    projectDetails: {
      client: { name: clientName },
      name: projectName,
    },
  } = useProjectDetails();

  const [sidebarPinned, setSidebarPinned] = useLocalStorage('sidebarPinned', false);

  useTitle(
    formatMessage({ id: AppMessages['title.projectDetails'] }, { projectName: `${clientName}: ${projectName}` }),
  );

  const togglePin = useCallback(() => {
    setSidebarPinned(!sidebarPinned);
  }, [sidebarPinned]);

  return (
    <Box sx={styles.projectViewWrapper(sidebarPinned)}>
      <Sidebar pinned={sidebarPinned} togglePin={togglePin} />
      <Box width="100%" display="flex" flexDirection="column">
        <ProjectHeader />
        <Outlet />
      </Box>
    </Box>
  );
};
