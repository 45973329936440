import React from 'react';

import { Box, Stack } from '@mui/material';

import { Skeleton } from 'ui/skeleton/Skeleton';
import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

import * as styles from './PersonBasicInfo.styles';

export const PersonBasicInfoSkeleton = () => (
  <Box sx={styles.employeeBasicInfo}>
    <Skeleton circular width={32} height={32} />
    <Stack sx={styles.employeeInfoWrapper}>
      <Stack direction="row" gap={1} alignItems="center">
        <TextSkeleton width={124} variant="body2" />
      </Stack>
      <TextSkeleton width={100} variant="caption" />
    </Stack>
  </Box>
);
