import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';
import { AdditionalValuesModal } from '../additionalValuesModal/AdditionalValuesModal';

import { EditDiscountModalProps } from './EditDiscountModal.types';
import { EditDiscountContent } from './editDiscountContent/EditDiscountContent';

export const EditDiscountModal = ({
  open,
  onClose,
  workStatementId,
  discount,
  billingCycleId,
  invoiceId,
}: EditDiscountModalProps) => {
  useDialogBlockScroll(open);

  return (
    <AdditionalValuesModal open={open} onClose={onClose}>
      <EditDiscountContent
        onClose={onClose}
        workStatementId={workStatementId}
        discount={discount}
        billingCycleId={billingCycleId}
        invoiceId={invoiceId}
      />
    </AdditionalValuesModal>
  );
};
