import { Styles } from 'styles/theme';

export const availabilityFiltersWrapper: Styles = {
  marginBottom: 3,
};

export const toggleButtonGroupWithGap: Styles = {
  display: 'flex',
  alignItems: 'center',

  '&&& .MuiToggleButton-root': {
    marginRight: 1,
    borderRadius: 2,
  },

  '&& .MuiToggleButton-root:last-of-type': {
    marginRight: 3,
  },

  '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    marginLeft: 0,
    borderLeft: (theme) => `1px solid ${theme.palette.border.default}`,
  },
};

export const checkbox: Styles = {
  '&.MuiFormControlLabel-root .MuiTypography-root': {
    fontSize: '0.875rem',
  },

  '&.MuiFormControlLabel-root .MuiButtonBase-root': {
    color: 'text.secondary',
  },

  '&.MuiFormControlLabel-root .Mui-checked': {
    color: 'primary.main',
  },
};
