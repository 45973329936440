import { Filters } from 'hooks/useFilters/useFilters.types';

export enum PeopleSortingField {
  name = 'name',
  seniority = 'seniority',
  department = 'department_code',
  project = 'current_project',
  employmentType = 'employment_type',
  availability = 'availability_status',
}

export type PeopleFilters = {
  search: string;
  sort: string;
  seniorities: string[];
  departments: string[];
  status: string[];
  fte: string;
  rateMin: string;
  rateMax: string;
  skills: string[];
  availabilityFrom: string;
  availabilityTo: string;
  employmentType: string[];
  includeUnemployed: 'true' | 'false';
  nonDevRoles: 'true' | 'false';
};

export type DefaultPeopleFilters = Partial<PeopleFilters>;

export type PeopleFiltersValue = {
  filters: PeopleFilters;
} & Filters<PeopleFilters>;

export enum EmploymentTypeFilter {
  tshEmployee = 'TSH_EMPLOYEE',
  subcontractor = 'SUBCONTRACTOR',
  uncertain = 'UNCERTAIN',
}
