import { addDays, isAfter, isBefore, isSameDay } from 'date-fns';

import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useProjectTimesheet } from 'hooks/useProjectTimesheet/useProjectTimesheet';
import { parseDate } from 'utils/dateUtils';

export const useGetIsFirstDayOfProject = () => {
  const { timesheetMonthStartDate } = useProjectTimesheet();
  const {
    startDate,
    projectDetails: { endDate },
  } = useProjectDetails();

  return (dayNumber: number) => {
    const currentDate = addDays(timesheetMonthStartDate, dayNumber - 1);

    return {
      isProjectStartDayMarker: isSameDay(startDate, currentDate),
      isBeforeProjectStart: isBefore(currentDate, startDate),
      isAfterProjectEnd: isAfter(currentDate, parseDate(endDate)),
    };
  };
};
