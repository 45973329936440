import { useState } from 'react';

import { Stack } from '@mui/material';
import { Button, InlineEdit } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { Translation } from 'ui/translation/Translation';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { useEditMode } from 'hooks/useEditMode/useEditMode';

import { IssueInvoiceActionProps } from './IssueInvoiceAction.types';
import { useIssueInvoiceForm } from './IssueInvoiceForm';

export const IssueInvoiceAction = ({
  isLoading,
  onSubmit,
  requestInvoiceCorrection,
  requestingInvoiceCorrection,
  isButtonDisabled,
  invoiceNumber,
}: IssueInvoiceActionProps) => {
  const [showInvoiceInput, setShowInvoiceInput] = useState(false);

  const { t } = useLocale();
  const { control, handleSubmit, clearErrors, formState } = useIssueInvoiceForm(
    invoiceNumber ? { invoiceNumber } : undefined,
  );
  const { inputRef, setOpenEditMode, setCloseEditMode } = useEditMode(onSubmit);

  const resetErrors = () => clearErrors('invoiceNumber');

  const handleCancel = () => {
    setCloseEditMode();
    setShowInvoiceInput(false);
    control._formValues['invoiceNumber'] = invoiceNumber;
    clearErrors('invoiceNumber');
  };

  return (
    <Stack direction="row" gap={1}>
      {showInvoiceInput ? (
        <InlineEdit
          header={t('projectDetails.invoices.addInvoice')}
          isActive={true}
          onDataDisplayClick={setOpenEditMode}
          onCancel={handleCancel}
          onSubmit={handleSubmit(onSubmit)}
          disabled={isButtonDisabled}
          submitDisabled={!!formState.errors.invoiceNumber}
          input={
            <FormTextField
              inputRef={inputRef}
              size="small"
              name="invoiceNumber"
              control={control}
              onFocus={resetErrors}
              label={t('projectDetails.invoices.addInvoice')}
            />
          }
        >
          -
        </InlineEdit>
      ) : (
        <>
          <Button
            type="submit"
            size="small"
            loadingPosition="center"
            onClick={() => setShowInvoiceInput(true)}
            loading={isLoading}
            disabled={isButtonDisabled}
          >
            {t('projectDetails.invoices.addInvoice')}
          </Button>
          <Button
            size="small"
            width={180}
            variant="outlined"
            loadingPosition="center"
            loading={requestingInvoiceCorrection}
            onClick={requestInvoiceCorrection}
            disabled={isButtonDisabled}
          >
            <Translation id="projectDetails.invoice.actions.toCorrect" />
          </Button>
        </>
      )}
    </Stack>
  );
};
