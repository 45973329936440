import { Checkbox } from 'lux/components';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useNeedsFilters } from 'hooks/useNeedsFilters/useNeedsFilters';

import { NeedsGroupingOptions } from './GroupByProject.types';

export const GroupByProject = () => {
  const { formatMessage } = useLocale();

  const {
    filters: { groupBy },
    setFilterValue,
  } = useNeedsFilters();

  return (
    <Checkbox
      label={formatMessage({ id: AppMessages['needs.groupByProject.label'] })}
      checked={groupBy === NeedsGroupingOptions.project}
      labelSize="lg"
      onChange={() =>
        setFilterValue(
          'groupBy',
          groupBy === NeedsGroupingOptions.project ? NeedsGroupingOptions.needs : NeedsGroupingOptions.project,
        )
      }
    />
  );
};
