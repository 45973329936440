import { Styles } from 'styles/theme';

export const table: Styles = {
  '&& .MuiTableCell-root': {
    whiteSpace: 'nowrap',
  },
  '&& .MuiTableRow-root:last-child .MuiTableCell-root': {
    paddingY: 1,
  },
};

export const totalAmount: Styles = {
  color: 'text.secondary',
};
