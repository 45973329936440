import { Styles } from 'styles/theme';
import { TIMELINE_DAY_WIDTH } from '../PersonTimeline.utils';

export const wrapper: Styles = {
  gridRow: '1',
  gridColumn: '1',
};

export const dayColumn: Styles = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  width: TIMELINE_DAY_WIDTH,
};

export const dayHeader = (isToday: boolean, isDayOff: boolean): Styles => ({
  justifyContent: 'center',
  alignItems: 'center',
  height: 48,
  width: TIMELINE_DAY_WIDTH,
  marginBottom: 1.5,
  color: 'text.secondary',
  ...(isDayOff && {
    color: 'text.disabled',
  }),
  ...(isToday && {
    backgroundColor: 'primary.main',
    color: 'text.white',
    borderRadius: '50%',
  }),
});

export const dayBackground = (isToday: boolean, isDayOff: boolean): Styles => ({
  display: 'flex',
  flex: 1,
  borderRight: '1px solid',
  borderColor: 'border.default',
  ...(isDayOff && {
    backgroundColor: 'surface.disabled',
  }),
  ...(isToday && {
    backgroundColor: 'primary.light',
  }),
});
