import { Assignment } from 'api/actions/getPersonAssignments/getPersonAssignments.types';
import { NeedDto } from 'api/types/Need.types';
import { sortCaseInsensitive } from 'utils/stringUtils';

export const getRolesStats = (needs: NeedDto[], assignments: Assignment[]) => {
  const needRoles = sortCaseInsensitive(
    needs.reduce<string[]>((acc, curr) => {
      if (!acc.includes(curr.role)) {
        acc.push(curr.role);
      }
      return acc;
    }, []),
  );

  const stats = needRoles.map((role) => {
    const assignedCount = assignments.filter((assignment) => assignment.role === role).length;
    const needCount = needs.filter((need) => need.role === role).length;
    const totalCount = assignedCount + needCount;
    return {
      role,
      assignedCount,
      totalCount,
    };
  });

  return stats;
};
