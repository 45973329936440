import { SxProps } from '@mui/system';

export const datePickerRow: SxProps = {
  display: 'flex',
  gap: 2,
  px: 1,
};

export const popoverActions: SxProps = {
  display: 'flex',
  gap: 1,

  '.MuiButtonBase-root:not(:last-of-type)': {
    m: 0,
  },
};
