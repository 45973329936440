import { SxProps } from '@mui/system';

export const dialogActions: SxProps = {
  justifyContent: 'right',
  paddingTop: 0,
};

export const cancelButton: SxProps = {
  textAlign: 'center',

  '&.MuiButton-root': {
    paddingX: 1,
  },
};

export const actionContainer: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
};

export const dialog: SxProps = {
  '.MuiDialog-paper': {
    p: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 808,
    overflow: 'hidden',
  },
};

export const mainWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
};

export const formText: SxProps = {
  color: 'text.secondary',
};

export const form: SxProps = {
  width: '550px',
};

export const title: SxProps = {
  textAlign: 'left',
  width: '100%',
  mb: '1rem',
};

export const container: SxProps = {
  backgroundColor: 'surface.paper.default',
  width: '400px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
};

export const avatar: SxProps = {
  width: 80,
  height: 80,
  mt: '2rem',
};

export const personName: SxProps = {
  color: 'text.main',
  fontSize: '1.1rem',
  mt: 2,
  textAlign: 'center',
};

export const personRole: SxProps = {
  color: 'text.secondary',
  fontSize: '0.8rem',
  m: '0.2rem',
  textAlign: 'center',
};
