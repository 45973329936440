import { Styles } from 'styles/theme';

export const needsGridWrapper: Styles = {
  paddingX: 6,
  flex: 1,
};

export const cardWrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden',
};
