import { FormControlLabel } from '@mui/material';
import { Checkbox } from 'lux/components';
import { StarsIcon, WorkStartIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Filter } from 'ui/filter/Filter';
import { filtersData } from 'config/data/people/filters';

import { SeniorityFilterProps as SharedSeniorityFilterProps } from './SeniorityFilter.types';

export const SeniorityFilter = ({
  variant,
  seniorities,
  dataCy,
  displayIcon = false,
  onFilterClick,
  onClearClick,
}: SharedSeniorityFilterProps) => {
  const { formatMessage } = useLocale();

  return (
    <Filter
      variant={variant}
      icon={<StarsIcon />}
      dataCy={dataCy}
      label={formatMessage({ id: AppMessages['filters.seniority.label'] })}
      popoverTitle={formatMessage({ id: AppMessages['filters.seniority.popover.title'] })}
      popoverActionLabel={formatMessage({ id: AppMessages['filters.clear'] })}
      valueSelected={seniorities.length > 0}
      selectedCount={seniorities.length}
      onPopoverActionClick={onClearClick}
    >
      {displayIcon && <WorkStartIcon />}
      {filtersData['seniorities'].map(({ labelTranslation, value }) => (
        <FormControlLabel
          key={value}
          label={formatMessage({ id: AppMessages[labelTranslation] })}
          control={
            <Checkbox value={value} checked={seniorities.includes(value)} onChange={() => onFilterClick(value)} />
          }
        />
      ))}
    </Filter>
  );
};
