import { Styles } from 'styles/theme';

export const employeeCardFooter: Styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 2,
  cursor: 'default',
};

export const skillsWrapper: Styles = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 0.5,
};
