import { convertRateToInteger } from 'utils/convertRate';
import { RateCardItem } from 'api/actions/createProject/createProjectActions.types';
import { Seniority } from 'api/types/Seniority.enum';
import { RateCardFormItem } from 'shared/project/rateCardItem/RateCardItem.types';

export const mapRateCard = (rateCards: RateCardFormItem[]): RateCardItem[] => {
  const rateCardItems: RateCardItem[] = [];

  rateCards.forEach((rate) => {
    if (!rate.seniorityEnabled) {
      if (rate.withoutLevelRate || rate.withoutLevelRate === 0) {
        rateCardItems.push({
          specialization: rate.role,
          seniority: Seniority.withoutLevel,
          amount: convertRateToInteger(rate.withoutLevelRate),
        });
      }
      return;
    }

    if (rate.juniorRate || rate.juniorRate === 0) {
      rateCardItems.push({
        specialization: rate.role,
        seniority: Seniority.junior,
        amount: convertRateToInteger(rate.juniorRate),
      });
    }
    if (rate.regularRate || rate.regularRate === 0) {
      rateCardItems.push({
        specialization: rate.role,
        seniority: Seniority.mid,
        amount: convertRateToInteger(rate.regularRate),
      });
    }
    if (rate.seniorRate || rate.seniorRate === 0) {
      rateCardItems.push({
        specialization: rate.role,
        seniority: Seniority.senior,
        amount: convertRateToInteger(rate.seniorRate),
      });
    }
  });

  return rateCardItems;
};
