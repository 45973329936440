import { Styles } from 'styles/theme';

export const card: Styles = {
  backgroundColor: 'surface.paper.default',
  '&:not(:last-of-type)': {
    marginBottom: 3,
  },
};

export const cardWrapper: Styles = {
  textDecoration: 'none',
  color: 'text.primary',
};

export const header: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const date: Styles = {
  marginRight: 6,
};

export const reason: Styles = {
  marginTop: 1,
  marginBottom: 0,
};
