import { Styles } from 'styles/theme';

export const column: Styles = {
  '&&&': {
    p: 0,
  },
};

export const row: Styles = {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  gap: 1,
  width: '100%',
  height: '100%',
  pl: 2,
  pr: 1,
  pb: 0.5,

  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    pointerEvents: 'none',
    width: '100%',
    boxShadow: (theme) => theme.luxShadows[500],
  },
};

export const actions: Styles = {
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 2,

  '.MuiButtonBase-root:not(:last-of-type)': {
    m: 0,
  },
};

export const popoverContent: Styles = {
  display: 'flex',
  gap: 2,
  px: 1,
};

export const datePickerRow: Styles = {
  display: 'flex',
  gap: 2,
  px: 1,
};

export const popoverActions: Styles = {
  display: 'flex',
  gap: 1,

  '.MuiButtonBase-root:not(:last-of-type)': {
    m: 0,
  },
};

export const warningBox: Styles = {
  display: 'flex',
  gap: 1,
  px: 1,

  'svg > path': {
    fill: 'currentColor',
  },
};
