import { Styles } from 'styles/theme';

export const popper: Styles = {
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'surface.paper.light',
    boxShadow: (theme) => theme.luxShadows[500],
    color: 'text.primary',
    minWidth: 415,
  },
  '& .MuiTooltip-arrow': {
    '&::before': {
      backgroundColor: 'surface.paper.light',
      boxShadow: (theme) => theme.luxShadows[500],
    },
  },
};

export const button: Styles = {
  width: '100%',
};

export const projectLinkLabelWrapper: Styles = {
  height: '100%',
  whiteSpace: 'nowrap',
};

export const projectLink: Styles = {
  color: 'primary.main',
  textDecoration: 'underline',
  cursor: 'pointer',
};

export const employeeLink: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  color: 'text.primary',
  textDecoration: 'none',
  width: 200,

  '&>svg': {
    display: 'none',
  },

  '&:hover': {
    color: 'primary.main',
    textDecoration: 'underline',

    '&>svg': {
      display: 'block',
    },
  },
};
