import { FormControlLabel } from '@mui/material';
import { Checkbox } from 'lux/components';

import { Filter } from 'ui/filter/Filter';
import { filtersData } from 'config/data/projects/filters';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useProjectsFilters } from 'hooks/useProjectsFilters/useProjectsFilters';
import { ProjectStage } from 'api/types/ProjectStage.enum';

import type { StageFilterProps } from './StageFilter.types';

export const StageFilter = ({ variant }: StageFilterProps) => {
  const { formatMessage } = useLocale();
  const {
    filters: { stage },
    toggleFilter,
  } = useProjectsFilters();

  const handleFilterChange = (value: ProjectStage) => {
    toggleFilter('stage', value);
  };

  return (
    <Filter variant={variant} label={formatMessage({ id: AppMessages['projects.filters.stage.label'] })}>
      {filtersData['stage'].map(({ labelTranslation, value }) => (
        <FormControlLabel
          key={value}
          label={formatMessage({ id: AppMessages[labelTranslation] })}
          control={
            <Checkbox
              value={value}
              checked={stage.includes(value as ProjectStage)}
              onChange={() => handleFilterChange(value as ProjectStage)}
            />
          }
        />
      ))}
    </Filter>
  );
};
