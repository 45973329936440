import { ReactNode } from 'react';

import { Controller, FieldValues } from 'react-hook-form';
import { Select as LuxSelect } from 'lux/components';

import { SelectProps } from './Select.types';

export const Select = <T extends FieldValues>(props: SelectProps<T>) => {
  const {
    name,
    control,
    label,
    size,
    variant,
    sx,
    options,
    fullWidth,
    required,
    isDisabled = false,
    autoFocus,
    isOpen,
    multiple,
    renderValue,
    selectedOptionCheckmark,
    ...rest
  } = props;

  const getRenderValue = () => {
    if (renderValue) {
      return renderValue;
    }

    if (selectedOptionCheckmark) {
      return (value: unknown) => value as ReactNode;
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LuxSelect
          size={size}
          label={label}
          value={value}
          fullWidth={fullWidth}
          sx={sx}
          variant={variant}
          onChange={onChange}
          disabled={isDisabled}
          autoFocus={autoFocus}
          InputLabelProps={{ required }}
          SelectProps={{
            multiple,
            defaultOpen: isOpen,
            renderValue: getRenderValue(),
          }}
          error={!!error}
          helperText={error?.message || ''}
          options={options}
          {...rest}
        />
      )}
    />
  );
};
