import { RateCard } from 'api/actions/getProject/getProject.types';
import type { NeedDto } from 'api/types/Need.types';

export type PeopleAssignImmediatelyModalProps = {
  projectId: string;
  needData: NeedDto;
  isOpen: boolean;
  onSuccess: VoidFunction;
  onClose: VoidFunction;
  rateCard?: RateCard;
};

export enum PeopleAssignImmediatelyModalStep {
  comparisonTable = 'comparisonTable',
}
