import { Box, Stack } from '@mui/material';

import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

import { AssignmentAccordionSkeleton } from './assignmentAccordion/AssignmentAccordion.skeleton';
import * as styles from './AssignmentList.styles';

export const AssignmentListSkeleton = () => (
  <Stack>
    <Box sx={styles.dateLabel}>
      <TextSkeleton width={66} variant="caption" />
    </Box>

    <Stack>
      {Array(4)
        .fill(null)
        .map((_, index) => (
          <AssignmentAccordionSkeleton key={index} />
        ))}
    </Stack>
  </Stack>
);
