import { CheckIcon } from 'lux/icons';
import { Table } from 'lux/components';

import { ComparisonRowProps } from './ComparisonRow.types';
import * as styles from './ComparisonRow.styles';

export const ComparisonRow = ({
  variant = 'info',
  heading,
  needCell,
  personCell,
  iconCell,
  highlightCondition,
  checkmarkCondition = !highlightCondition,
}: ComparisonRowProps) => (
  <Table.Row sx={styles.comparisonRow(highlightCondition, variant)}>
    <Table.Cell sx={styles.tableRowHeader}>{heading}</Table.Cell>
    <Table.Cell sx={styles.comparisonTableCell()}>{needCell}</Table.Cell>
    <Table.Cell sx={styles.comparisonTableCell(checkmarkCondition)}>
      {checkmarkCondition ? <CheckIcon color="success" /> : personCell}
    </Table.Cell>
    <Table.Cell sx={styles.comparisonTableCell(true)}>{iconCell}</Table.Cell>
  </Table.Row>
);
