import { useState } from 'react';

import { Button, Typography } from 'lux/components';
import { Box } from '@mui/material';
import { AddUserIcon, DeleteIcon, EditIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useEditKeyRoles } from 'hooks/useEditKeyRoles/useEditKeyRoles';
import { ManageRolesAutocomplete } from '../../manageRolesAutocomplete/ManageRolesAutocomplete';
import { PeopleActionsModal } from 'shared/peopleActionsModal/PeopleActionsModal';
import { useDialog } from 'hooks/useDialog/useDialog';
import { Person } from 'api/actions/getPerson/getPerson.types';
import { mapKeyRoles } from '../../PeopleInProject.utils';
import { FloatingToolbar } from 'ui/floatingToolbar/FloatingToolbar';

import * as styles from './RoleHeader.styles';
import { AssignButton } from './assignButton/AssignButton';
import { RoleHeaderProps } from './RoleHeader.types';

export const RoleHeader = (props: RoleHeaderProps) => {
  const { id, role, isSinglePersonAssigned, projectId, keyRoles } = props;
  const { formatMessage } = useLocale();
  const [isEditModeActive, setEditModeActive] = useState(false);
  const { mutateAsync } = useEditKeyRoles(projectId);
  const {
    isOpen: isAssignPersonDialogOpen,
    setClose: closeAssignPersonDialog,
    setOpen: openAssignPersonDialog,
  } = useDialog();
  const currentRolePeople = keyRoles.find((keyRole) => keyRole.id === id)?.people;

  const handleRoleDelete = async () => {
    const filteredKeyRoles = keyRoles.filter((keyRole) => keyRole.id !== id);

    await mutateAsync({ keyRoleItems: mapKeyRoles(filteredKeyRoles) });
  };

  const handleAddPerson = async (person: Person) => {
    const mappedKeyRoles = keyRoles.map((keyRole) => {
      const peopleIds = keyRole.people.map((value) => value.employeeId);

      if (keyRole.id === id) {
        peopleIds.unshift(person.employeeId);
      }

      return {
        role: keyRole.role,
        peopleIds,
      };
    });

    await mutateAsync({ keyRoleItems: mappedKeyRoles });

    closeAssignPersonDialog();
  };

  return (
    <>
      <Box>
        {isEditModeActive ? (
          <ManageRolesAutocomplete
            isActive={isEditModeActive}
            currentValue={role}
            projectId={projectId}
            keyRoles={keyRoles}
            closeEditMode={() => setEditModeActive(false)}
          />
        ) : (
          <FloatingToolbar
            buttons={
              <>
                <FloatingToolbar.Button
                  tooltipTitle={formatMessage({
                    id: AppMessages['projectDetails.accordions.peopleInProject.button.addPersonToRole.tooltip'],
                  })}
                  Icon={AddUserIcon}
                  onClick={openAssignPersonDialog}
                />
                <FloatingToolbar.Button
                  tooltipTitle={formatMessage({
                    id: AppMessages['projectDetails.accordions.peopleInProject.button.editRoleName.tooltip'],
                  })}
                  Icon={EditIcon}
                  onClick={() => setEditModeActive(true)}
                />
                <FloatingToolbar.Button
                  tooltipTitle={formatMessage({
                    id: AppMessages['projectDetails.accordions.peopleInProject.button.removeRoleFromProject.tooltip'],
                  })}
                  Icon={DeleteIcon}
                  onClick={handleRoleDelete}
                />
              </>
            }
          >
            <Typography sx={styles.header} variant="subtitle2">
              {role}
            </Typography>
          </FloatingToolbar>
        )}

        {!isSinglePersonAssigned && (
          <Button
            onClick={openAssignPersonDialog}
            size="small"
            variant="text"
            startIcon={<AddUserIcon />}
            sx={styles.addPeopleButton}
          >
            {formatMessage({ id: AppMessages['projectDetails.accordions.peopleInProject.button.addPeopleToRole'] })}
          </Button>
        )}
      </Box>

      <PeopleActionsModal
        onClose={closeAssignPersonDialog}
        isOpen={isAssignPersonDialogOpen}
        peopleListTitle={formatMessage({
          id: AppMessages['projectDetails.accordions.peopleInProject.peopleActionsModal.title'],
        })}
        steps={[]}
        personAction={(person) => (
          <AssignButton
            onClick={() => handleAddPerson(person)}
            disabled={currentRolePeople?.findIndex((employee) => employee.employeeId === person.employeeId) !== -1}
          />
        )}
        defaultPeopleFilters={{ status: [], nonDevRoles: 'true' }}
      />
    </>
  );
};
