import { Stack } from '@mui/system';

import { Skeleton } from 'ui/skeleton/Skeleton';

export const ProjectTimesheetSkeleton = () => (
  <Stack spacing={1}>
    <Skeleton height={72} width="65%" sx={{ ml: 'auto' }} />
    <Skeleton height={35} />
    <Skeleton height={35} />
    <Skeleton height={35} />
    <Skeleton height={35} />
  </Stack>
);
