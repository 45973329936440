import type { MouseEvent } from 'react';
import { useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { DeleteIcon, DuplicateIcon, EditIcon, MoreIcon } from 'lux/icons';
import { MenuItem, MenuList } from 'lux/components';
import { useSnackbar } from 'notistack';

import { useNeed } from 'hooks/useNeed/useNeed';
import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';
import { useRejections } from 'hooks/useRejections/useRejections';
import { useProposals } from 'hooks/useProposals/useProposals';
import { useProject } from 'hooks/useProject/useProject';
import { checkIsLeadFromHubSpot } from 'utils/checkers/checkIsLeadFromHubSpot';
import { RejectionSource } from 'config/data/rejection/RejectionSource.enum';
import { formatRoleName } from 'utils/stringUtils';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { permitProjectEdition } from 'utils/permissions/permitProjectEdition';
import { DeleteNeed } from 'app/need/delete/DeleteNeed';
import { EditNeed } from 'app/need/edit/EditNeed';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useRequestNeed } from 'hooks/useRequestNeed/useRequestNeed';
import type { RequestNeedParams } from 'api/actions/requestNeed/requestNeedActions.types';
import { Translation } from 'ui/translation/Translation';

import { NeedCardBody } from './body/NeedCardBody';
import { NeedCardModalHeader } from './header/NeedCardModalHeader';
import * as styles from './NeedCardModalContent.styles';
import type { NeedCardModalContentProps } from './NeedCardModalContent.types';

export const NeedCardModalContent = ({
  projectId,
  needId,
  onClose,
  onNestedModalToggle,
}: NeedCardModalContentProps) => {
  const { data: needData, isLoading: isNeedLoading } = useNeed(needId);
  const { data: projectDetails } = useProject(projectId);

  const { data: rejectionsData, isLoading: isRejectionsLoading } = useRejections({
    project: projectId,
    source: [RejectionSource.proposal],
  });
  const rejections = rejectionsData?.pages.flatMap((page) => page.rejections);
  const { data: proposals, isLoading: isProposalsLoading } = useProposals(needId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isOpen: isDeleteNeedOpen, setOpen: setDeleteNeedOpen, setClose: setDeleteNeedClose } = useDialog();
  const { isOpen: isEditNeedOpen, setOpen: setEditNeedOpen, setClose: setEditNeedClose } = useDialog();
  const { mutateAsync: duplicateNeed, isLoading: isDuplicating } = useRequestNeed(projectDetails?.id || '');
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useLocale();
  const isLeadFromHubSpot = checkIsLeadFromHubSpot(projectDetails);

  if (!needData || !rejections || !proposals || !projectDetails) {
    return null;
  }

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (callback: VoidFunction) => {
    onNestedModalToggle();
    callback();
    handleMenuClose();
  };

  const allowProjectEdition = permitProjectEdition(projectDetails);

  const handleDuplicateNeed = async (need: RequestNeedParams) => {
    const payload: RequestNeedParams = {
      role: need.role,
      fte: need.fte,
      startDate: need.startDate,
    };

    if (need.seniority) {
      payload.seniority = need.seniority;
    }

    if (need.endDate) {
      payload.endDate = need.endDate;
    }

    if (need.skillSet && need.skillSet.length > 0) {
      payload.skillSet = need.skillSet;
    }

    if (need.additionalInformation) {
      payload.additionalInformation = need.additionalInformation;
    }

    await duplicateNeed(payload, {
      onSuccess: () => {
        enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.duplicateNeed.success'] }));
      },
    });
  };

  const handleDuplicate = () => handleDuplicateNeed({ ...needData, fte: JSON.stringify(needData.fte) });

  const handleEditNeedClose = () => {
    setEditNeedClose();
    onNestedModalToggle();
  };

  const handleDeleteNeedClose = () => {
    setDeleteNeedClose();
    onNestedModalToggle();
  };

  return (
    <DialogInnerWrapper
      loading={isNeedLoading || isRejectionsLoading || isProposalsLoading}
      title={formatRoleName(needData.role, needData.seniority)}
      headerSx={styles.dialogHeader}
      onClose={onClose}
      hasHeaderActions={true}
      hasFooterActions={false}
      additionalHeaderButtons={
        <IconButton disabled={!allowProjectEdition} onClick={handleMenuOpen}>
          <MoreIcon />
        </IconButton>
      }
    >
      <Box>
        <NeedCardModalHeader
          startDate={needData.startDate}
          isHubSpotNeed={isLeadFromHubSpot}
          onClose={onClose}
          onNestedModalToggle={onNestedModalToggle}
          skillSet={needData.skillSet}
          showAllSkills
        />
      </Box>
      <NeedCardBody
        projectId={projectId}
        needData={needData}
        proposals={proposals}
        rejections={rejections}
        onClose={onClose}
        onNestedModalToggle={onNestedModalToggle}
      />

      <MenuList
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        id={'need-menu'}
        open={!!anchorEl}
        onClose={handleMenuClose}
        anchorEl={anchorEl}
        PaperProps={{
          sx: styles.menuPaper,
        }}
      >
        <MenuItem sx={styles.menuItem} icon={<EditIcon />} onClick={() => handleMenuItemClick(setEditNeedOpen)}>
          <Translation id="card.need.menu.edit" />
        </MenuItem>
        <MenuItem sx={styles.menuItem} icon={<DuplicateIcon />} disabled={isDuplicating} onClick={handleDuplicate}>
          <Translation id="card.need.menu.duplicate" />
        </MenuItem>
        <MenuItem sx={styles.errorItem} icon={<DeleteIcon />} onClick={() => handleMenuItemClick(setDeleteNeedOpen)}>
          <Translation id="card.need.menu.delete" />
        </MenuItem>
      </MenuList>

      <DeleteNeed
        projectId={projectDetails.id}
        needId={needData.id}
        open={isDeleteNeedOpen}
        onClose={handleDeleteNeedClose}
        onSuccess={onClose}
      />
      <EditNeed projectId={projectDetails.id} need={needData} open={isEditNeedOpen} onClose={handleEditNeedClose} />
    </DialogInnerWrapper>
  );
};
