import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material';
import { Badge, Typography, Button, Grid } from 'lux/components';
import { Link } from 'react-router-dom';
import { ChevronDownIcon, NoteIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { fteToDecimalFraction } from 'utils/fteToDecimalFraction';
import { ProjectTypeBadge } from 'ui/projectTypeBadge/ProjectTypeBadge';
import { ProjectType } from 'api/types/ProjectType.enum';

import * as styles from './ProjectAccordion.styles';
import { ProjectAccordionProps, ProjectData } from './ProjectAccordion.types';

export const ProjectAccordion = ({ project, fte, rate, currency }: ProjectAccordionProps) => {
  const { startDate, endDate, activeAssignments } = project;
  const { formatMessage } = useLocale();

  const projectsData: ProjectData[] = [
    {
      labelKey: 'personDetails.projectAccordion.projectStart',
      value: startDate,
    },
    {
      labelKey: 'personDetails.projectAccordion.projectEnd',
      value: endDate || '-',
    },
    {
      labelKey: 'personDetails.projectAccordion.notes',
      value: '-',
    },
  ];

  return (
    <Accordion sx={styles.accordion}>
      <AccordionSummary
        expandIcon={<ChevronDownIcon sx={styles.expandIcon} />}
        sx={styles.accordionSummary}
        aria-controls="assignments"
        id="assignments-header"
      >
        <Grid gutter={0} container spacing={1}>
          <Grid item xs>
            <Stack spacing={1}>
              <Typography variant="subtitle2">{project.name}</Typography>
              <Stack direction="row" spacing={1}>
                <Badge
                  isStatic
                  color="gray"
                  badgeContent={formatMessage(
                    { id: AppMessages['personDetails.projectAccordion.membersCount'] },
                    {
                      count: activeAssignments,
                    },
                  )}
                />
                <Badge isStatic color="gray" badgeContent={`${fteToDecimalFraction(fte)} FTE`} />
                {!!rate && !!currency && <Badge isStatic color="gray" badgeContent={`${rate} ${currency}`} />}
              </Stack>
            </Stack>
          </Grid>
          <Grid item display="flex" alignItems="center">
            <Stack direction="row" spacing={1}>
              {project.attributes.map((attribute) => (
                <ProjectTypeBadge type={attribute as ProjectType} key={attribute} />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetails}>
        <Grid gutter={0} container>
          {projectsData.map(({ labelKey, value }) => (
            <Grid item xs={6} key={labelKey}>
              <Stack spacing={0.5}>
                <Typography variant="caption" color="text.secondary">
                  {formatMessage({ id: AppMessages[labelKey] })}
                </Typography>
                <Typography variant="caption">{value}</Typography>
              </Stack>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              component={Link}
              to={`/projects/${project.id}`}
              target="_blank"
              variant="outlined"
              size="small"
              startIcon={<NoteIcon />}
            >
              {formatMessage({ id: AppMessages['personDetails.projectAccordion.buttonLabel'] })}
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
