import { useProjectDetails } from '../useProjectDetails/useProjectDetails';
import { useFormatCurrency } from '../useFormatCurrency/useFormatCurrency';
import { calculatePriceInDollars } from 'utils/types/priceUtils';

export const useBillingSummaryPrice = () => {
  const {
    projectDetails: { currency },
  } = useProjectDetails();
  const formatCurrency = useFormatCurrency(currency);

  return {
    formatPrice: (priceInCents: number) => formatCurrency(calculatePriceInDollars(priceInCents)),
  };
};
