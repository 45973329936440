import { convertRateToDecimal } from 'utils/convertRate';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { peopleKeys } from 'utils/queryKeys';
import {
  GetPersonProposalsParams,
  GetPersonProposalsResponse,
} from 'api/actions/getPersonProposals/getPersonProposals.types';
import { getPersonProposalsQuery } from 'api/actions/getPersonProposals/getPersonProposals';

export const usePersonProposals = (personId: string) => {
  const { isAuthenticated } = useAuth();

  const { data, ...response } = useQuery<GetPersonProposalsParams, GetPersonProposalsResponse>(
    peopleKeys.singlePersonProposals(personId),
    getPersonProposalsQuery(personId),
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
    },
  );

  if (!data) {
    return { data, ...response };
  }

  const mappedData: GetPersonProposalsResponse = {
    ...data,
    proposals: data.proposals.map((proposal) => ({
      ...proposal,
      rate: proposal.rate && convertRateToDecimal(proposal.rate),
    })),
  };

  return { ...response, data: mappedData };
};
