import { Styles } from 'styles/theme';

export const link: Styles = {
  '&.active': {
    backgroundColor: 'primary.light',
    color: 'primary.main',
  },
  color: 'text.secondary',
  overflowX: 'hidden',
};

export const label: (iconOnly: boolean) => Styles = (iconOnly) => ({
  overflow: 'hidden',
  width: iconOnly ? 0 : '124px',
  marginLeft: iconOnly ? 0 : 1.5,
  transition: (theme) =>
    theme.transitions.create(['width', 'margin-left'], {
      easing: theme.transitions.easing.sharp,
      duration: iconOnly ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen,
    }),
});
