import { Badge } from 'lux/components';

import { SelectEdit } from 'ui/form/inlineEdit/selectEdit/SelectEdit';
import { useEditDepositStatus } from 'hooks/useEditDepositStatus/useEditDepositStatus';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { useLocale } from 'hooks/useLocale/useLocale';
import { DepositStatus } from 'api/types/BillingSummary.types';
import { AppMessages } from 'i18n/messages';
import { mapKeyValueToSelectOptions } from 'ui/form/select/Select.mappers';

import { DepositStatusSelectProps } from './DepositStatusSelect.types';
import { depositStatusTranslations, getAvailableStatuses, getBadgeColor } from './DepositStatusSelect.utils';

export const DepositStatusSelect = ({ workStatementId, deposit }: DepositStatusSelectProps) => {
  const { mutateAsync, isLoading } = useEditDepositStatus(workStatementId, deposit.id);
  const { isInvoiceEditBlocked } = useProjectBillingSummaryData();
  const { isReport } = useProjectBillingSummaryData();
  const { formatMessage } = useLocale();

  return (
    <SelectEdit
      name="status"
      value={deposit.status}
      label="projectDetails.billingSummary.workStatementDetails.deposits.status"
      defaultValues={{ status: deposit.status }}
      onSubmit={(value) => mutateAsync({ status: value.status })}
      options={mapKeyValueToSelectOptions(getAvailableStatuses(deposit.status))}
      disabled={isReport || isInvoiceEditBlocked}
      renderBadge={(props) => (
        <Badge
          {...props}
          isStatic
          color={getBadgeColor(props.value as DepositStatus)}
          badgeContent={formatMessage({ id: AppMessages[depositStatusTranslations[props.value as DepositStatus]] })}
        />
      )}
      loading={isLoading}
    />
  );
};
