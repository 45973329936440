import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';

import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AdditionalValueForm } from '../AddiitionalValueForm.type';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { moneyInputNumberParser } from 'utils/createInputNumberChangeParser';

import * as styles from './AdditionalValue.styles';
import { AdditionalValueContentProps } from './AdditionalValueContent.types';

export const AdditionalValueContent = ({ amountLabel, titleLabel }: AdditionalValueContentProps) => {
  const { formatMessage } = useLocale();
  const { control } = useFormContext<AdditionalValueForm>();
  const { projectDetails } = useProjectDetails();

  return (
    <>
      <Stack direction={'row'} gap={3}>
        <FormTextField
          name="label"
          control={control}
          label={formatMessage({ id: AppMessages[titleLabel] })}
          fullWidth
          sx={styles.formElement}
        />
        <FormTextField
          name="amount"
          control={control}
          label={formatMessage({ id: AppMessages[amountLabel] })}
          fullWidth
          onChangeParser={moneyInputNumberParser}
          startAdornment={projectDetails.currency}
          sx={styles.formElement}
        />
      </Stack>
    </>
  );
};
