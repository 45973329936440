import { useContext } from 'react';

import { AssignmentInfoContext } from 'context/assignmentInfo/assignmentInfoContext/AssignmentInfoContext';

export const useAssignmentInfo = () => {
  const context = useContext(AssignmentInfoContext);

  if (!context) {
    throw new Error('AssignmentInfoContext must be within AssignmentInfoContextController');
  }

  return context;
};
