import { Stack } from '@mui/material';
import { Typography, Button } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectsFilters } from 'hooks/useProjectsFilters/useProjectsFilters';
import { AppMessages } from 'i18n/messages';

import { ProjectTypeFilter } from './projectType/ProjectTypeFilter';
import { RoleFilter } from './role/RoleFilter';
import { StageFilter } from './stage/StageFilter';
import { TeamFilter } from './team/TeamFilter';
import { ClientNameFilter } from './clientName/ClientNameFilter';
import { TagFilter } from './tag/TagFilter';
import * as styles from './ProjectsFilters.styles';

export const ProjectsFilters = () => {
  const { formatMessage } = useLocale();
  const { clearAllFilters } = useProjectsFilters();

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h6">{formatMessage({ id: AppMessages['filters.title'] })}</Typography>
        <Button onClick={() => clearAllFilters(['search'])} size="small" variant="text" color="primary">
          {formatMessage({ id: AppMessages['filters.clearAll'] })}
        </Button>
      </Stack>
      <Stack sx={styles.container}>
        <StageFilter variant="accordion" />
        <TeamFilter variant="accordion" />
        <ProjectTypeFilter variant="accordion" />
        <RoleFilter variant="accordion" />
        <ClientNameFilter variant="accordion" />
        <TagFilter />
      </Stack>
    </>
  );
};
