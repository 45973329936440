import { Translation } from 'i18n/messages';
import { assignmentsSortingOptions } from 'context/assignmentsFilters/assignmentsFiltersContextController/AssignmentsFiltersContextController';
import { AssignmentsFilters } from 'context/assignmentsFilters/assignmentsFiltersContext/AssignmentsFiltersContext.types';

type FilterOption = {
  labelTranslation: Translation;
  value: string;
};

type AssignmentsFiltersData = Record<keyof Pick<AssignmentsFilters, 'sort'>, FilterOption[]>;

export const filtersData: AssignmentsFiltersData = {
  sort: [
    {
      labelTranslation: 'assignments.filters.sort.latest',
      value: assignmentsSortingOptions.endDateDESC,
    },
    {
      labelTranslation: 'assignments.filters.sort.oldest',
      value: assignmentsSortingOptions.endDateASC,
    },
  ],
};
