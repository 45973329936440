import { Accent } from '@mui/material';

import { InvoiceStatus, InvoiceStatusEnum, Invoice } from 'api/types/BillingSummary.types';
import { Translation } from 'i18n/messages';
import { isDateInSameMonth, isPastDate, parseDate } from 'utils/dateUtils';
import { InvoiceListItem } from 'api/actions/getInvoices/getInvoices.types';

import { ColorsEnum } from './InvoiceStatusBadge.types';

export const getInvoiceCreatedStatusColorAndTranslation = (billingCycleEndDate: Date, currentDate: Date) => {
  if (isPastDate(billingCycleEndDate)) {
    return { color: ColorsEnum.orange, translation: 'projectDetails.invoice.status.inReview' } as const;
  }

  if (isDateInSameMonth(currentDate, billingCycleEndDate)) {
    return { color: ColorsEnum.yellow, translation: 'projectDetails.invoice.status.ongoing' } as const;
  }

  return {
    color: ColorsEnum.gray,
    translation: 'projectDetails.invoice.status.created',
  } as const;
};

export const getInvoiceSentStatusColorAndTranslation = (invoice: Invoice | InvoiceListItem) => {
  const isInvoiceSent = invoice.status === InvoiceStatusEnum.sent;
  const isPaymentDelay = isPastDate(parseDate(invoice.paymentDate));

  if (isInvoiceSent && isPaymentDelay) {
    return { color: ColorsEnum.red, translation: 'projectDetails.invoice.status.overdue' } as const;
  }

  return { color: ColorsEnum.yellow, translation: 'projectDetails.invoice.status.sent' } as const;
};

export const getInvoiceStatusColor = (
  billingCycleEndDate: Date,
  currentDate: Date,
  invoice: Invoice | InvoiceListItem,
  status: InvoiceStatus,
): keyof Accent => {
  switch (status) {
    case InvoiceStatusEnum.approved_by_pm:
      return ColorsEnum.yellow;
    case InvoiceStatusEnum.created:
      const { color: createdStatusColor } = getInvoiceCreatedStatusColorAndTranslation(
        billingCycleEndDate,
        currentDate,
      );

      return createdStatusColor;
    case InvoiceStatusEnum.sent:
      const { color: sentStatusColor } = getInvoiceSentStatusColorAndTranslation(invoice);

      return sentStatusColor;
    case InvoiceStatusEnum.to_correct:
      return ColorsEnum.red;
    case InvoiceStatusEnum.invoice_issued:
      return ColorsEnum.yellow;
    case InvoiceStatusEnum.internal_billing:
      return ColorsEnum.green;
    case InvoiceStatusEnum.paid:
      return ColorsEnum.green;
    case InvoiceStatusEnum.error:
      return ColorsEnum.red;
    default:
      return ColorsEnum.gray;
  }
};

export const getInvoiceStatusTranslation = (
  billingCycleEndDate: Date,
  currentDate: Date,
  invoice: Invoice | InvoiceListItem,
  status: InvoiceStatus,
): Translation => {
  switch (status) {
    case InvoiceStatusEnum.created:
      const { translation: createdStatusTranslation } = getInvoiceCreatedStatusColorAndTranslation(
        billingCycleEndDate,
        currentDate,
      );

      return createdStatusTranslation;
    case InvoiceStatusEnum.approved_by_pm:
      return 'projectDetails.invoice.status.approvedByPm';
    case InvoiceStatusEnum.to_correct:
      return 'projectDetails.invoice.status.toCorrect';
    case InvoiceStatusEnum.invoice_issued:
      return 'projectDetails.invoice.status.invoiceIssued';
    case InvoiceStatusEnum.sent:
      const { translation: sentStatusTranslation } = getInvoiceSentStatusColorAndTranslation(invoice);

      return sentStatusTranslation;
    case InvoiceStatusEnum.paid:
      return 'projectDetails.invoice.status.paid';
    case InvoiceStatusEnum.internal_billing:
      return 'projectDetails.invoice.status.internalBilling';
    case InvoiceStatusEnum.error:
      return 'projectDetails.invoice.status.error';
    default:
      return 'projectDetails.invoice.status.unknown';
  }
};
