import { Stack } from '@mui/system';

import { Skeleton } from 'ui/skeleton/Skeleton';
import { CostSummarySkeleton } from '../../../projectInvoices/costSummary/CostSummary.skeleton';

import * as styles from './WorkStatementSummary.styles';

export const WorkStatementSummarySkeleton = () => (
  <>
    <Stack sx={styles.subtotalWrapper}>
      <Stack sx={styles.actionButtons}>
        <Skeleton width={180} height={40} />
        <Skeleton width={148} height={40} />
      </Stack>
      <CostSummarySkeleton width={176} variant="subtitle1" />
    </Stack>

    <Stack sx={styles.boxesWrapper} />

    <CostSummarySkeleton width={260} />
  </>
);
