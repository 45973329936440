import { SxProps } from '@mui/material';

export const wrapper: SxProps = {
  '&.MuiGrid-root': {
    paddingLeft: '18px',

    ['@media print']: {
      display: 'none',
    },
  },
};

export const container: SxProps = {
  ['@media print']: {
    width: '300px',
    border: '0.5px solid',
    borderColor: 'border.light',
    borderRadius: 5,
    padding: '1rem',
  },
};

export const text: SxProps = {
  fontSize: '0.75rem',
  letterSpacing: 0,
  lineHeight: '1.25rem',
  fontFamily: 'Geologica, sans-serif',

  ['@media print']: {
    fontSize: 12,
  },
};

export const summaryContainer: SxProps = {
  ['@media print']: {
    justifyContent: 'center',
  },
};
