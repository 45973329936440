import type { Styles } from 'styles/theme';

export const twoButtonContainer: Styles = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 2,
  paddingTop: 2,

  '.MuiButton-text': {
    justifyContent: 'flex-start',
  },

  '.MuiButtonBase-root': {
    width: '100%',
  },
};

export const threeButtonContainer: Styles = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: 2,
  paddingTop: 2,

  '.MuiButton-text': {
    justifyContent: 'flex-start',
  },

  '.MuiButtonBase-root': {
    width: '100%',
  },
};

export const divider: Styles = {
  marginTop: 2,
};
