import { Styles } from 'styles/theme';

export const dialogTitle: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const dialogContent: Styles = {
  display: 'flex',
  flexDirection: 'column',
};
