import { AxiosRequestConfig } from 'axios';
import { AuthenticationResult } from '@azure/msal-common';
import { PublicClientApplication } from '@azure/msal-browser';

import { authStorage } from 'context/auth/authStorage/AuthStorage';
import { checkToken } from 'api/utils/token';
import { MSAL_CONFIGURATION } from '../../../config/msal/msalConfig';

let refreshInProgress: Promise<AuthenticationResult> | null = null;
const msalInstance = new PublicClientApplication(MSAL_CONFIGURATION);

export const requestSuccessInterceptor = async (
  config: AxiosRequestConfig,
  {
    onRefreshTokenSuccess,
    onRefreshTokenError,
  }: {
    onRefreshTokenSuccess: (token: { accessToken: string; expires: number; refreshToken: string }) => void;
    onRefreshTokenError: VoidFunction;
  },
) => {
  await msalInstance.initialize();
  if (authStorage.accessToken && authStorage.expires !== null) {
    const isTokenExpired = checkToken(authStorage.expires);

    if (isTokenExpired && !refreshInProgress) {
      const allAccounts = msalInstance.getAllAccounts();
      const account = allAccounts[0];
      if (!account) {
        throw new Error('Azure account is null');
      }
      try {
        const getTokenOptions = {
          scopes: [`${process.env.REACT_APP_ACTIVE_DIRECTORY_CLIENT_ID}/.default`],
          account,
        };
        refreshInProgress = msalInstance.acquireTokenSilent(getTokenOptions);

        const { accessToken, expiresOn: exp } = await refreshInProgress;
        refreshInProgress = null;

        onRefreshTokenSuccess({ accessToken, refreshToken: accessToken, expires: exp?.getTime() ?? 0 });
      } catch {
        onRefreshTokenError();
      }
    } else {
      await refreshInProgress;
    }

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${authStorage.accessToken}`,
      },
    };
  }
  return config;
};
