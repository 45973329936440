import { enqueueSnackbar } from 'notistack';

import { addWorkStatementMutation } from 'api/actions/addWorkStatement/addWorkStatementActions';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

export const useAddWorkStatement = () => {
  const { invalidateBillingSummaryCache } = useProjectBillingSummaryData();
  const { formatMessage } = useLocale();

  return useMutation(['addWorkStatementMutation'], addWorkStatementMutation(), {
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: AppMessages['projectDetails.workStatementForm.addWorkStatement.success'] }));
      invalidateBillingSummaryCache();
    },
    onError: () => {
      enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.addNewOrder.error'] }));
    },
  });
};
