import { Assignment, Timesheet } from 'api/types/Timesheet.types';
import { HourType } from 'api/types/HourType.enum';
import { TimesheetAssignment } from '../projectTimesheetDataContext/ProjectTimesheetDataContext.types';

export const teamMemberHourRates = (teamMemberHours: TimesheetAssignment['hours']) => {
  const hourRates: Record<HourType, number[]> = {
    [HourType.regular]: [],
    [HourType.overhour]: [],
    [HourType.absence]: [],
    [HourType.holiday]: [],
    [HourType.total]: [],
  };
  return teamMemberHours.reduce(
    (rates, { hours }) => {
      hours.forEach((hour) => {
        if (rates[hour.type] && !rates[hour.type].includes(hour.ratePercentage)) {
          rates[hour.type].push(hour.ratePercentage);
        }
      });

      return rates;
    },
    { ...hourRates },
  );
};

export const hasMultipleHourEntries = (assignment: Assignment) => {
  const uniqueHourRates = new Set();

  for (const billingDay of assignment.billingDays) {
    if (billingDay.hours.length > 1) return true;

    const hour = billingDay.hours[0];

    if (hour?.ratePercentage) {
      uniqueHourRates.add(hour.ratePercentage);
    }
  }

  return uniqueHourRates.size > 1;
};

export const mapToAssignmentHoursArray = (
  assignments: Timesheet['workStatements'][number]['assignments'],
): TimesheetAssignment[] =>
  assignments.map((assignment) => ({
    employeeId: assignment.employeeId,
    role: assignment.role,
    name: `${assignment.firstName} ${assignment.lastName}`,
    hasMultipleHourEntries: hasMultipleHourEntries(assignment),
    teamMemberHours: assignment.billingDays,
    totalHours: assignment.totalHours,
    rates: teamMemberHourRates(assignment.billingDays),
    hours: assignment.billingDays,
  }));
