import { Stack } from '@mui/material';

import { EmployeeCardSkeleton } from 'app/employee/card/EmployeeCard.skeleton';

export const TabRejectedPanelSkeleton = () => (
  <Stack spacing={3}>
    {Array(4)
      .fill(null)
      .map((_, index) => (
        <EmployeeCardSkeleton withReason key={index} />
      ))}
  </Stack>
);
