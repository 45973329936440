import { useContext } from 'react';

import { ProjectTimesheetContext } from 'context/projectTimesheet/projectTimesheetContext/ProjectTimesheetContext';

export const useProjectTimesheet = () => {
  const context = useContext(ProjectTimesheetContext);

  if (context === undefined) {
    throw new Error('ProjectTimesheetContext must be within ProjectTimesheetContextController');
  }

  return context;
};
