import { Box } from '@mui/material';
import { CardHeader } from 'lux/components';

import { useClient } from 'hooks/useClient/useClient';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useIndustries } from 'hooks/useIndustries/useIndustries';
import { useUpdateClient } from 'hooks/useUpdateClient/useUpdateClient';
import { ClientDetailsRows } from 'app/project/projectOverview/clientDetails/clientDetailsRows/ClientDetailsRows';
import { ClientDetailsRowSkeleton } from 'app/project/projectOverview/clientDetails/clientDetailsRows/ClientDetailsRowsSkeleton';
import { Client } from 'api/actions/getProject/getProject.types';
import { CardWrapper } from 'ui/cardWrapper/CardWrapper';

import * as styles from './ClientDetails.styles';

export const ClientDetails = () => {
  const { clientDetails, isLoading: isClientLoading } = useClient();
  const { data: industries = [] } = useIndustries();
  const { t } = useLocale();

  const clientProjectIds = clientDetails?.client.projects.map(({ id }) => id) ?? [];
  const clientId = clientDetails?.client.id ?? '';
  const { mutateAsync } = useUpdateClient(clientProjectIds, clientId);

  return (
    <CardWrapper>
      <CardHeader title={t('client.details.title')} />
      <Box sx={styles.container}>
        {isClientLoading ? (
          <ClientDetailsRowSkeleton />
        ) : (
          <ClientDetailsRows
            clientData={clientDetails?.client as Client}
            otherClientNames={[]}
            industries={industries}
            isLoading={isClientLoading}
            mutateAsync={mutateAsync}
            isClientPage
          />
        )}
      </Box>
    </CardWrapper>
  );
};
