import { Stack } from '@mui/material';
import { Avatar, AvatarGroup, Typography } from 'lux/components';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { PersonTooltip } from 'shared/people/personTooltip/PersonTooltip';

import { PeopleAvatarsProps } from './PeopleAvatars.types';
import * as styles from './PeopleAvatars.styles';

export const PeopleAvatars = ({ people }: PeopleAvatarsProps) => {
  const { formatMessage } = useLocale();

  const avatarSize = people.length > 1 ? 'xs' : 'sm';

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <AvatarGroup max={3} size={avatarSize} sx={styles.avatarGroup(avatarSize)}>
        {people.map((person) => (
          <span key={person.employeeId}>
            <PersonTooltip employeeId={person.employeeId}>
              <span>
                <Avatar image={person.picture} alt={`${person.firstName} ${person.lastName}`} key={person.employeeId} />
              </span>
            </PersonTooltip>
          </span>
        ))}
      </AvatarGroup>
      <Typography variant="body2" sx={styles.label}>
        {people.length === 1
          ? `${people[0].firstName} ${people[0].lastName}`
          : formatMessage(
              { id: AppMessages['data.listExamplePerson'] },
              { name: `${people[0].firstName} ${people[0].lastName}`, count: people.length - 1 },
            )}
      </Typography>
    </Stack>
  );
};
