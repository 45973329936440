import { parseISO } from 'date-fns';

import { ProjectStage } from 'api/types/ProjectStage.enum';
import { Assignment } from 'api/actions/getPersonAssignments/getPersonAssignments.types';
import { isPM } from 'utils/isPM';
import { TranslateFn } from 'i18n/messages';
import { compareDatesDesc, getCurrentDayStartDate, isDateBefore, parseDate } from 'utils/dateUtils';

export const getBulkAssignmentsRemovalDisabledReason = ({
  t,
  projectStage,
  selectedAssignments,
}: {
  t: TranslateFn;
  projectStage: ProjectStage;
  selectedAssignments: Assignment[];
}) => {
  if (projectStage === ProjectStage.lead) {
    return '';
  }

  const isAnyAssignmentPm = selectedAssignments.some((assignment) => isPM(assignment.role));
  const isAnyAssignmentFinished = selectedAssignments.some((assignment) =>
    assignment.endDate ? isDateBefore(parseISO(assignment.endDate), getCurrentDayStartDate()) : false,
  );

  const isEveryAssignmentFinished = selectedAssignments.every((assignment) =>
    assignment.endDate ? isDateBefore(parseISO(assignment.endDate), getCurrentDayStartDate()) : false,
  );

  let warningMessage = '';

  if (isAnyAssignmentFinished && !isEveryAssignmentFinished) {
    warningMessage = t('projectDetails.removeEmployeesModal.warning.assignments');
  }

  if (isAnyAssignmentPm) {
    warningMessage = t('assignment.removeImmediately.disabledReason.pm');
  }

  return warningMessage;
};

export const getMaxStartDate = (selectedAssignments: Assignment[], projectEndDate: string) => {
  if (selectedAssignments.length === 1) {
    return selectedAssignments[0].endDate || projectEndDate;
  }

  const selectedAssignmentsWithEndDate = selectedAssignments.filter((assignment) => !!assignment.endDate);

  if (selectedAssignmentsWithEndDate.length === 1) {
    return selectedAssignmentsWithEndDate[0].endDate || projectEndDate;
  }

  return (
    selectedAssignmentsWithEndDate.sort((a, b) =>
      compareDatesDesc(parseDate(a.endDate as string), parseDate(b.endDate as string)),
    )[0]?.endDate || projectEndDate
  );
};
