import { Badge } from 'lux/components';

import { Translation } from 'ui/translation/Translation';

import { BillingCycleStatusBadgeProps } from './BillingCycleStatusBadge.types';
import { getBillingCycleStatusColor, getBillingCycleStatusTranslation } from './BillingCycleStatusBadge.utils';

export const BillingCycleStatusBadge = ({ status }: BillingCycleStatusBadgeProps) => {
  const color = getBillingCycleStatusColor(status);
  const translation = getBillingCycleStatusTranslation(status);

  return <Badge isStatic color={color} badgeContent={<Translation id={translation} />} />;
};
