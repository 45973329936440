import { Project } from 'api/actions/getProject/getProject.types';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { Assignment } from 'api/actions/getPersonAssignments/getPersonAssignments.types';
import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';

import { getCurrentDayStartDate, isDateBefore, parseDate } from './dateUtils';
import { isPM } from './isPM';

export const canRequestNeed = (allowProjectEdition: boolean, endDate: string) =>
  allowProjectEdition && (!endDate || isDateBefore(getCurrentDayStartDate(), parseDate(endDate)));

export const getAssignmentRemovalDisabledReason = (
  projectData: Pick<Project, 'stage' | 'assignedPmCount'>,
  assignmentData: Assignment,
) => {
  const { formatMessage } = intl.currentIntl;

  if (projectData.stage !== ProjectStage.lead && isPM(assignmentData.role) && projectData.assignedPmCount < 2) {
    return formatMessage({ id: AppMessages['assignment.removeImmediately.disabledReason.pm'] });
  }

  return '';
};
