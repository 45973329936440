import { Box } from '@mui/material';

import { composeStyles } from 'utils/composeStyles';

import * as styles from './CardWrapper.styles';
import { CardWrapperProps } from './CardWrapperProps.types';

export const CardWrapper = ({ children, sx, shouldShow }: CardWrapperProps) => {
  if (!shouldShow) {
    return <Box sx={composeStyles([styles.cardWrapper, sx])}>{children}</Box>;
  }
  return <>{children}</>;
};
