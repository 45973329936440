import { useState } from 'react';

import { Box, FormControlLabel } from '@mui/material';
import { Typography, Checkbox } from 'lux/components';

import {
  addMonthToDate,
  formatDate,
  getEndOfMonthDate,
  getStartOfMonthDate,
  subtractMonthFromDate,
} from 'utils/dateUtils';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { DashboardCard } from 'ui/dashboardCard/DashboardCard';
import { usePersonAbsences } from 'hooks/usePersonAbsences/usePersonAbsences';
import { usePersonData } from 'hooks/usePersonData/usePersonData';

import { TimelineHeader } from './timelineHeader/TimelineHeader';
import { TimelineContent } from './timelineContent/TimelineContent';
import * as styles from './PersonTimeline.styles';
import { PersonTimelineProps } from './PersonTimeline.types';
import { mapToTimelineEvents } from './PersonTimeline.mappers';

export const PersonTimeline = ({ assignments, proposals }: PersonTimelineProps) => {
  const [selectedPeriod, setSelectedPeriod] = useState(new Date());
  const { person } = usePersonData();
  const { data: absencesData } = usePersonAbsences({
    personId: String(person.employeeId),
    fromDate: formatDate(getStartOfMonthDate(selectedPeriod)),
    toDate: formatDate(getEndOfMonthDate(selectedPeriod)),
  });

  const [hideProposed, setHideProposed] = useState(false);
  const { formatMessage } = useLocale();

  const handleMonthChange = (direction: 'previous' | 'next') => {
    if (direction === 'previous') {
      const previousMonthDate = subtractMonthFromDate(selectedPeriod);
      setSelectedPeriod(previousMonthDate);
      return;
    }

    if (direction === 'next') {
      const nextMonthDate = addMonthToDate(selectedPeriod);
      setSelectedPeriod(nextMonthDate);
    }
  };

  const timelineEvents = mapToTimelineEvents(
    assignments?.assignments || [],
    proposals?.proposals || [],
    absencesData?.absences.timePeriod || [],
  ).filter(({ type }) => !hideProposed || type !== 'proposal');

  return (
    <DashboardCard title={formatMessage({ id: AppMessages['personDetails.timeline.title'] })}>
      <Box sx={styles.wrapper}>
        <TimelineHeader selectedPeriod={selectedPeriod} onMonthChange={handleMonthChange} />
        <TimelineContent
          key={selectedPeriod.toDateString()}
          selectedPeriod={selectedPeriod}
          events={timelineEvents}
          bankHolidays={absencesData?.absences.bankHolidays ?? []}
        />
      </Box>
      <FormControlLabel
        control={<Checkbox size="small" checked={hideProposed} onChange={() => setHideProposed(!hideProposed)} />}
        label={
          <Typography variant="body2">
            {formatMessage({ id: AppMessages['personDetails.timeline.hideProposed'] })}
          </Typography>
        }
        checked={hideProposed}
      />
    </DashboardCard>
  );
};
