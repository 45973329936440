import { Box } from '@mui/material';
import { Grid } from 'lux/components';

import { Availability } from './availability/Availability';
import { Needs } from './needs/Needs';
import * as styles from './DashboardGrid.styles';
import { People } from './people/People';

export const DashboardGrid = () => (
  <Box sx={styles.dashboardGridWrapper}>
    <Grid container gutter={0}>
      <Grid item xs={12}>
        <Availability />
      </Grid>
      <Grid item xs={6}>
        <Needs />
      </Grid>
      <Grid item xs={6}>
        <People />
      </Grid>
    </Grid>
  </Box>
);
