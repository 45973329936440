import { Filter } from 'ui/filter/Filter';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useProjectsFilters } from 'hooks/useProjectsFilters/useProjectsFilters';
import { AutocompleteStack } from 'ui/autocompleteStack/AutocompleteStack';
import { useTags } from 'hooks/useTags/useTags';

export const TagFilter = () => {
  const {
    filters: { tag: tags },
    setFilterValue,
  } = useProjectsFilters();
  const { data: tagsDictionary = [] } = useTags();
  const { formatMessage } = useLocale();

  const handleTagsChange = (newValue: string[]) => {
    setFilterValue('tag', newValue);
  };

  return (
    <Filter variant="accordion" label={formatMessage({ id: AppMessages['projects.filters.tag.label'] })} shouldStretch>
      <AutocompleteStack
        label={formatMessage({ id: AppMessages['projects.filters.tag.label'] })}
        buttonLabel={formatMessage({ id: AppMessages['projects.filters.tag.actionBtn'] })}
        dictionary={tagsDictionary}
        values={tags}
        onFieldsChange={handleTagsChange}
      />
    </Filter>
  );
};
