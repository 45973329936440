import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { Avatar, Button } from 'lux/components';
import { useSnackbar } from 'notistack';
import { joiResolver } from '@hookform/resolvers/joi';
import { SubmitHandler } from 'react-hook-form';
import { parseISO } from 'date-fns';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { useUnsavedChangesDialogContext } from 'hooks/useUnsavedChangesDialogContext/useUnsavedChangesDialogContext';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useAssignmentsRemoval } from 'hooks/useAssignmentsRemoval/useAssignmentsRemoval';
import { OverflowTooltipTypography } from 'ui/overflowTooltipTypography/OverflowTooltipTypography';
import { DatePicker } from 'ui/form/datePicker/DatePicker';
import { Checkbox } from 'ui/form/checkbox/Checkbox';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { compareDatesDesc, formatDate, getCurrentDayStartDate, isDateBefore, parseDate } from 'utils/dateUtils';
import { Translation } from 'ui/translation/Translation';

import { removeEmployeesSchema } from './RemoveEmployeesModal.schema';
import * as styles from './RemoveEmployeesModal.styles';
import type { RemoveEmployeesFormValues, RemoveEmployeesModalProps } from './RemoveEmployeesModal.types';

export const RemoveEmployeesModal = (props: RemoveEmployeesModalProps) => {
  const { projectId, projectName, selectedAssignments, onBeforeRemoveAssignments, onClose } = props;

  const isEveryAssignmentFinished = selectedAssignments.every((assignment) =>
    assignment.endDate ? isDateBefore(parseISO(assignment.endDate), getCurrentDayStartDate()) : false,
  );

  const defaultValues = {
    includeInRejected: false,
    removalReason: '',
    removePermanently: false,
  };

  const latestAssignmentStartDate = selectedAssignments?.sort((a, b) =>
    compareDatesDesc(parseDate(a?.startDate), parseDate(b?.startDate)),
  )[0]?.startDate;

  const minDate = parseISO(latestAssignmentStartDate);

  const form = useIntlForm<RemoveEmployeesFormValues>({
    defaultValues: isEveryAssignmentFinished ? defaultValues : { ...defaultValues, removalDate: null },
    mode: 'onSubmit',
    resolver: joiResolver(removeEmployeesSchema(minDate)),
  });

  const { control, handleSubmit, watch } = form;

  const { mutate: removeAssignments, isLoading } = useAssignmentsRemoval(projectId);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocale();
  const { DialogClickAwayListenerWrapper } = useUnsavedChangesDialogContext();

  const includeInRejected = watch('includeInRejected');
  const removePermanently = watch('removePermanently');

  const onSubmit: SubmitHandler<RemoveEmployeesFormValues> = ({ removalDate, includeInRejected, removalReason }) => {
    onBeforeRemoveAssignments();

    const requestBody = {
      assignmentIds: selectedAssignments.map(({ id }) => id),
      includeInRejected,
      ...(includeInRejected && { reason: removalReason }),
      removePermanently,
    };

    removeAssignments(
      isEveryAssignmentFinished
        ? requestBody
        : {
            ...requestBody,
            endDate: removePermanently ? formatDate(new Date()) : formatDate(removalDate as Date),
          },
      {
        onSuccess: () => {
          onClose();
          enqueueSnackbar(t('projectDetails.removeEmployeesModal.snackbar.success'));
        },
      },
    );
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('projectDetails.removeEmployeesModal.title', { projectName })}</DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogClickAwayListenerWrapper defaultValues={defaultValues} form={form} onClose={onClose}>
          <DialogContent>
            <Stack sx={styles.container}>
              <Box sx={styles.employeesList}>
                {selectedAssignments.map(({ person }) => {
                  const personName = `${person.firstName} ${person.lastName}`;

                  return (
                    <Box key={person.employeeId} sx={styles.personDetails}>
                      <Avatar size="xs" image={person.picture} alt={personName} />
                      <OverflowTooltipTypography text={personName} variant="body2" />
                    </Box>
                  );
                })}
              </Box>

              <>
                {!isEveryAssignmentFinished && (
                  <DatePicker
                    fullWidth
                    minDate={minDate}
                    maxDate={getCurrentDayStartDate()}
                    disableFuture
                    isDisabled={isEveryAssignmentFinished}
                    label={t('projectDetails.removeEmployeesModal.removalDate.label')}
                    name="removalDate"
                    control={control}
                    defaultCalendarMonth={getCurrentDayStartDate()}
                  />
                )}
                <Checkbox
                  name="includeInRejected"
                  label={t('projectDetails.removeEmployeesModal.includeInRejected.label')}
                  control={control}
                />
                {includeInRejected && (
                  <FormTextField
                    label={t('assignment.removeImmediately.removalReason.label')}
                    fullWidth
                    name="removalReason"
                    control={control}
                  />
                )}
              </>
            </Stack>
          </DialogContent>

          <DialogActions sx={styles.dialogActions}>
            <Button variant="text" onClick={onClose}>
              <Translation id="button.cancel" />
            </Button>

            <Button loading={isLoading} variant="contained" color="error" size="large" type="submit">
              <Translation id="projectDetails.removeEmployeesModal.submit" />
            </Button>
          </DialogActions>
        </DialogClickAwayListenerWrapper>
      </form>
    </Dialog>
  );
};
