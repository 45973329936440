import { Stack } from '@mui/material';
import { Badge, Typography } from 'lux/components';

import { dashIfEmpty } from 'utils/stringUtils';

import { SelectedValueProps } from './SelectedValue.types';

export const SelectedValue = ({ renderBadge, value }: SelectedValueProps) => {
  if (Array.isArray(value)) {
    return (
      <Stack direction="row" flexWrap="wrap" gap={0.5}>
        {value.map((tag: string, index: number) =>
          renderBadge && typeof renderBadge === 'function' ? (
            renderBadge({
              isStatic: true,
              badgeContent: dashIfEmpty(tag),
              color: 'lightGray',
              key: `${tag}-${index}`,
            })
          ) : (
            <Badge isStatic key={`${tag}-${index}`} color="gray" badgeContent={tag} />
          ),
        )}
      </Stack>
    );
  }

  if (renderBadge) {
    if (typeof renderBadge === 'boolean') {
      return <Badge isStatic color="green" badgeContent={dashIfEmpty(value)} />;
    }
    if (typeof renderBadge === 'function')
      return renderBadge({
        isStatic: true,
        badgeContent: dashIfEmpty(value),
        color: 'lightGray',
        value,
      });
  }
  return <Typography variant="caption">{dashIfEmpty(value)}</Typography>;
};
