import { useQuery } from 'hooks/useQuery/useQuery';
import { useAuth } from 'hooks/useAuth/useAuth';
import { needsKeys } from 'utils/queryKeys';
import { getNeedsQuery } from 'api/actions/getNeeds/getNeeds';
import { GetNeedsParams, GetNeedsResponse } from 'api/actions/getNeeds/getNeeds.types';
import { parseSortFilter } from 'utils/filters/filters';

import { UseNeedsParams } from './useNeeds.types';

export const useNeeds = (params: UseNeedsParams) => {
  const { isAuthenticated } = useAuth();
  const queryKey = [...needsKeys.needsList, params];

  let args = {};

  if (params?.owner) {
    args = { ...args, owner: params.owner };
  }

  if (params?.search) {
    args = { ...args, name: params.search };
  }

  if (params?.sort) {
    const { field, order } = parseSortFilter(params.sort);

    args = {
      ...args,
      [`sort[${field}]`]: order,
    };
  }

  if (params?.roles) {
    args = { ...args, role: params.roles };
  }

  if (params?.seniorities) {
    args = { ...args, seniority: params.seniorities };
  }

  if (params?.fte) {
    args = { ...args, fte: Number(params.fte) };
  }

  if (params?.availabilityFrom) {
    args = { ...args, start_date_from: params.availabilityFrom };
  }

  if (params?.availabilityTo) {
    args = { ...args, start_date_to: params.availabilityTo };
  }

  if (params?.type) {
    args = { ...args, type: params.type };
  }

  return useQuery<GetNeedsParams, GetNeedsResponse>(queryKey, getNeedsQuery, {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    args,
  });
};
