import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { ArchiveLeadParams } from './archiveLeadActions.types';

export const archiveLeadMutation: (projectId: string) => MutationFn<ArchiveLeadParams> =
  (projectId: string) => (body) => ({
    endpoint: `projects/${projectId}/archive-project`,
    method: 'POST',
    params: body,
  });
