import { ComponentType } from 'react';

import {
  BlockchainIcon,
  BugIcon,
  CogwheelIcon,
  JsConsoleIcon,
  PaletteIcon,
  PcPlayIcon,
  BugAutoIcon,
  ChartIcon,
  CoffeeIcon,
} from 'lux/icons';

import { DepartmentCode } from 'api/types/DepartmentCode.enum';

enum DepartmentName {
  am = 'Account Management',
  android = 'Android',
  archit = 'Architect',
  ba = 'BA',
  board = 'Biuro',
  devops = 'DevOps',
  fin = 'Finance',
  front = 'Frontend',
  hr = 'HR',
  ios = 'iOS',
  it = 'IT',
  java = 'Java',
  manage = 'Management',
  marketin = 'Marketing',
  nmd = 'Design',
  node = 'Node.js',
  others = 'Others',
  php = 'PHP',
  pm = 'PM',
  qa = 'QA',
  qaa = 'QAA',
  rp = 'Radca prawny',
  ruby = 'Ruby on Rails',
  sales = 'Sales',
  secspec = 'Security Specialist',
}

const departmentNameMap = new Map<string, string>([
  [DepartmentCode.am, DepartmentName.am],
  [DepartmentCode.android, DepartmentName.android],
  [DepartmentCode.archit, DepartmentName.archit],
  [DepartmentCode.board, DepartmentName.board],
  [DepartmentCode.devops, DepartmentName.devops],
  [DepartmentCode.fin, DepartmentName.fin],
  [DepartmentCode.front, DepartmentName.front],
  [DepartmentCode.hr, DepartmentName.hr],
  [DepartmentCode.ios, DepartmentName.ios],
  [DepartmentCode.it, DepartmentName.it],
  [DepartmentCode.manage, DepartmentName.manage],
  [DepartmentCode.marketin, DepartmentName.marketin],
  [DepartmentCode.nmd, DepartmentName.nmd],
  [DepartmentCode.node, DepartmentName.node],
  [DepartmentCode.php, DepartmentName.php],
  [DepartmentCode.pm, DepartmentName.pm],
  [DepartmentCode.rp, DepartmentName.rp],
  [DepartmentCode.ruby, DepartmentName.ruby],
  [DepartmentCode.sales, DepartmentName.sales],
  [DepartmentCode.ba, DepartmentName.ba],
  [DepartmentCode.qa, DepartmentName.qa],
  [DepartmentCode.qaa, DepartmentName.qaa],
  [DepartmentCode.java, DepartmentName.java],
  [DepartmentCode.others, DepartmentName.others],
  [DepartmentCode.secspec, DepartmentName.secspec],
]);

const departmentIconMap: Record<string, ComponentType> = {
  TEST: BugIcon,
  QA: BugIcon,
  QAA: BugAutoIcon,
  BA: ChartIcon,
  ARCHIT: CogwheelIcon,
  JAVA: CoffeeIcon,
  NMD: PaletteIcon,
  NODE: BlockchainIcon,
  FRONT: PcPlayIcon,
  PHP: JsConsoleIcon,
  DEVOPS: CogwheelIcon,
  PM: PcPlayIcon,
  DEFAULT: CogwheelIcon,
};

export const createDepartmentLabel = (name: string) => departmentNameMap.get(name) ?? name;

export const getDepartmentIcon = (code: string) => departmentIconMap[code] || departmentIconMap.DEFAULT;
