import { useCallback, useEffect, useMemo, useState } from 'react';

import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useSearchParams } from 'react-router-dom';

import { useAcquireTokenSilent } from 'hooks/useAcquireTokenSilent/useAcquireTokenSilent';
import { AuthContext } from '../authContext/AuthContext';
import { authStorage } from '../authStorage/AuthStorage';
import { getActiveAccount } from '../../../utils/msalAccountUtils';

import { AuthContextControllerProps } from './AuthContextController.types';

export const AuthContextController = ({ children }: AuthContextControllerProps) => {
  const { fetchToken, loading: isLoading } = useAcquireTokenSilent();
  const { instance, inProgress } = useMsal();
  const [account, setAccount] = useState(getActiveAccount(instance));
  const [searchParams] = useSearchParams();

  useEffect(() => {
    instance.setActiveAccount(account);
  }, [account]);

  useEffect(() => {
    const assignTokenToAuthStorage = async () => {
      try {
        const tokenRes = await fetchToken();

        if (tokenRes) {
          authStorage.accessToken = tokenRes.accessToken;
          authStorage.expires = tokenRes.expiresOn?.getTime() ?? 0;
          setAccount(tokenRes.account);
        }
      } catch (e) {
        logout();
      }
    };

    if (!authStorage.accessToken && !searchParams.get('manual_logout') && inProgress === InteractionStatus.None) {
      assignTokenToAuthStorage();
    }
  }, [inProgress, authStorage.accessToken]);

  const logout = useCallback(() => {
    authStorage.accessToken = null;
    instance.clearCache();
    setAccount(null);
  }, [instance]);

  const contextValue = useMemo(
    () => ({
      isAuthenticated: !!account,
      isAuthenticating: isLoading,
      accessToken: authStorage.accessToken,
      expires: authStorage.expires,
      logout,
      account,
      setAccount,
    }),
    [isLoading, logout, account, setAccount],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
