import type { Styles } from 'lux/theme';

export const card: Styles = {
  '&': {
    padding: 0,
    border: (theme) => `1px solid ${theme.palette.border.default}`,
    borderRadius: 3,
  },

  '&&:hover': {
    backgroundColor: 'transparent',
  },
};

export const cardHeader: Styles = {
  backgroundColor: 'surface.paper.default',
  margin: '-1px',
  padding: 2,

  '&': {
    border: (theme) => `1px solid ${theme.palette.border.default}`,
    borderRadius: 3,
  },
};

export const cardBody: Styles = {
  py: 1,
  px: 3,
};

export const link: Styles = {
  '&.MuiButton-root': {
    padding: 0,
    minWidth: 'unset',
    minHeight: 'unset',
    alignSelf: 'flex-start',
    gap: 0.25,

    '&:hover': {
      backgroundColor: 'unset',
      textDecoration: 'underline',
    },
  },
};

export const table: Styles = {
  backgroundColor: 'common.white',

  'tr > td': {
    borderColor: (theme) => theme.palette.border.default,
  },

  'tr:last-of-type > td': {
    borderBottom: 0,
  },
};

export const rowHeader: Styles = {
  typography: 'caption',
  fontWeight: 600,
  color: 'text.secondary',
  width: '48%',

  '&&&': {
    paddingX: 1,
  },
};
