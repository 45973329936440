import { SxProps } from '@mui/system';

export const mainContainer: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#DAE9FF',
  border: '1px solid',
  borderColor: 'border.default',
  borderRadius: 1.5,
  mb: 2,
  p: 2,
};

export const wrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 3,
};

export const infoWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1,
};

export const title: SxProps = {
  color: 'text.secondary',
  fontSize: '0.8rem',
  m: 0,
};

export const buttonContainer: SxProps = {
  display: 'flex',
  gap: 5,
};

export const iconContainer: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'surface.paper.default',
  margin: 0.5,
  padding: 1,
  borderRadius: 1.5,

  path: {
    fill: 'currentColor',
  },
};
