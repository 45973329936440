import { Styles } from 'styles/theme';

export const wrapper: Styles = {};

export const editButton: Styles = {
  '& > svg': {
    width: 40,
    height: 40,

    path: {
      fill: (theme) => theme.palette.text.secondary,
    },
  },
};

export const editModeBox: Styles = {
  maxWidth: 296,
};
