import { Box } from '@mui/material';
import { Table } from 'lux/components';

import { composeStyles } from 'utils/composeStyles';
import { Skeleton } from 'ui/skeleton/Skeleton';
import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

import * as styles from './AvailabilityTableRow.styles';
import { AvailabilityTableCellSkeleton } from './availabilityTableCell/AvailabilityTableCellSkeleton';

export const AvailabilityTableRowSkeleton = ({
  splitBySeniority,
  showDepartmentIcon,
  showSeniorityHeaders,
}: {
  splitBySeniority: boolean;
  showDepartmentIcon: boolean;
  showSeniorityHeaders?: boolean;
}) => (
  <Table.Row sx={composeStyles([styles.row, splitBySeniority && styles.splitValuesRow])}>
    <Table.Cell sx={styles.departmentColumn} align="left">
      <Box sx={styles.tableCellWrapper}>
        {showDepartmentIcon && <Skeleton width={24} height={24} />}
        <TextSkeleton width={72} variant="body1" sx={{ ml: 1.5 }} />
      </Box>
    </Table.Cell>

    {Array(4)
      .fill(null)
      .map((_, index) => (
        <AvailabilityTableCellSkeleton
          key={index}
          filterBySeniority={splitBySeniority}
          showSeniorityHeaders={showSeniorityHeaders}
        />
      ))}
  </Table.Row>
);
