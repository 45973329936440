import { createIntl, createIntlCache, IntlCache, IntlShape } from 'react-intl';

import { AppLocale } from '../AppLocale.enum';
import { translations } from 'i18n/messages';
import { defaultLocale } from '../defaultLocale';

class Intl {
  private _cache: IntlCache;
  private _currentLocale: AppLocale;
  private _intls: Record<AppLocale, IntlShape>;

  constructor() {
    this._cache = createIntlCache();
    this._currentLocale = defaultLocale;
    this._intls = this.createIntls();
  }

  private createIntls() {
    return Object.values(AppLocale).reduce<Record<AppLocale, IntlShape>>(
      (intls, locale) => ({
        ...intls,
        [locale]: createIntl(
          {
            defaultLocale,
            locale,
            messages: translations[locale],
          },
          this._cache,
        ),
      }),
      {} as Record<AppLocale, IntlShape>,
    );
  }

  get currentIntl() {
    return this._intls[this._currentLocale];
  }

  set currentLocale(locale: AppLocale) {
    this._currentLocale = locale;
  }
}

export const intl = new Intl();
