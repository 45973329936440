import { Box } from '@mui/material';
import { Typography, Illustration } from 'lux/components';

import { EmptyStateProps } from './EmptyState.types';
import * as styles from './EmptyState.styles';

export const EmptyState = ({ illustration, title, message, actionBtn }: EmptyStateProps) => (
  <Box sx={styles.wrapper}>
    <Illustration type={illustration} />
    <Typography variant="h6">{title}</Typography>
    <Typography sx={styles.message} variant="body1">
      {message}
    </Typography>
    {actionBtn ?? null}
  </Box>
);
