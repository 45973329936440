import { Table } from 'lux/components';

import { fteToDecimalFraction } from 'utils/fteToDecimalFraction';
import { formatRoleName } from 'utils/stringUtils';
import { mapNeedsPeople } from 'shared/needs/Needs.functions';

import * as styles from './NeedsTable.styles';
import { NeedsTableProps } from './NeedsTable.types';
import { NeedsTableHead } from './needsTableHead/NeedsTableHead';
import { PeopleAvatars } from './peopleAvatars/PeopleAvatars';

export const NeedsTable = ({ needsData, onNeedClick }: NeedsTableProps) => (
  <Table>
    <Table.Table sx={styles.table} size="small">
      <NeedsTableHead />
      <Table.Body data-cy="needs-table_body">
        {needsData.map((need) => {
          const { proposed, approved } = mapNeedsPeople(need);
          return (
            <Table.Row hover sx={styles.tableRow} key={need.id} onClick={() => onNeedClick(need.id)}>
              <Table.Cell>{formatRoleName(need.role, need.seniority)}</Table.Cell>
              <Table.Cell>{need.startDate}</Table.Cell>
              <Table.Cell>{need.endDate || '-'}</Table.Cell>
              <Table.Cell>{fteToDecimalFraction(need.fte)}</Table.Cell>
              <Table.Cell>{need.proposalStats.proposed ? <PeopleAvatars people={proposed} /> : '-'}</Table.Cell>
              <Table.Cell>{need.proposalStats.approved ? <PeopleAvatars people={approved} /> : '-'}</Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table.Table>
  </Table>
);
