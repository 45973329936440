import { Table, Typography } from 'lux/components';
import { Box, Divider } from '@mui/material';

import { formatPeriodDate } from 'config/data/dashboard/availability';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser';

import { AvailabilityTableProps } from './AvailabilityTable.types';
import * as styles from './AvailabilityTable.styles';
import { AvailabilityTableRow } from './availabilityTableRow/AvailabilityTableRow';
import { getAvailabilityTableData } from './AvailabilityTable.utils';
import { PeopleListPopover } from './peopleListPopover/PeopleListPopover';
import { AvailabilityPeopleContextController } from './availabilityPeopleContext/AvailabilityPeopleContextController';

export const AvailabilityTable = ({ data, filters }: AvailabilityTableProps) => {
  const { formatMessage } = useLocale();
  const { splitBySeniority, period, includeProposed, includeExtension, isAvailabilityExpanded } = filters;

  const { data: user } = useCurrentUser();
  const managedDepartments = user?.person.managedDepartments;

  const { initialData, expandedData, summedData } = getAvailabilityTableData(data, period, managedDepartments);

  return (
    <AvailabilityPeopleContextController>
      <Table>
        <Table.Table
          sx={styles.table}
          aria-label={formatMessage({ id: AppMessages['dashboard.availability.table.title'] })}
        >
          <Table.Head>
            <Table.Row sx={styles.row}>
              <Table.Cell sx={styles.departmentColumn} />
              {data[period].map((currentPeriod) => (
                <Table.Cell align="left" key={currentPeriod.from}>
                  <Typography color="text.primary" variant="body1" fontWeight={500}>
                    {formatPeriodDate(currentPeriod.from, period)}
                  </Typography>
                </Table.Cell>
              ))}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {initialData.map(([department, values], index) => (
              <AvailabilityTableRow
                key={department}
                department={department}
                values={values}
                includeProposed={includeProposed}
                includeExtension={includeExtension}
                splitBySeniority={splitBySeniority}
                showSeniorityHeaders={index === 0}
              />
            ))}
          </Table.Body>
        </Table.Table>
      </Table>
      <Box sx={styles.tableToggleWrapper(isAvailabilityExpanded)}>
        <Table.Table
          sx={styles.table}
          aria-label={formatMessage({ id: AppMessages['dashboard.availability.table.title'] })}
        >
          <Table.Body>
            {expandedData.map(([department, values]) => (
              <AvailabilityTableRow
                key={department}
                department={department}
                values={values}
                includeProposed={includeProposed}
                includeExtension={includeExtension}
                splitBySeniority={splitBySeniority}
              />
            ))}
          </Table.Body>
        </Table.Table>
        <Divider />
        <Table.Table sx={styles.table} data-cy="availability_total-row">
          <Table.Body>
            <AvailabilityTableRow
              department={formatMessage({ id: AppMessages['dashboard.availability.table.level.allEmployees'] })}
              values={summedData}
              includeProposed={includeProposed}
              includeExtension={includeExtension}
              splitBySeniority={splitBySeniority}
              showDepartmentIcon={false}
            />
          </Table.Body>
        </Table.Table>
      </Box>
      <PeopleListPopover />
    </AvailabilityPeopleContextController>
  );
};
