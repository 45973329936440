import { Box } from '@mui/material';

import { Skeleton } from 'ui/skeleton/Skeleton';

import { NeedsTableSkeleton } from './needsTable/NeedsTable.skeleton';

export const NeedsListSkeleton = () => (
  <>
    <NeedsTableSkeleton />
    <Box marginTop={1}>
      <Skeleton width={104} height={48} />
    </Box>
  </>
);
