import { Badge } from 'lux/components';

import { ProjectType } from 'api/types/ProjectType.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { BadgeProps, ProjectTypeBadgeProps } from './ProjectTypeBadge.types';

export const getBadgeProps = (type: ProjectType): BadgeProps => {
  switch (type) {
    case ProjectType.existingCode:
      return {
        color: 'blue',
        translationId: 'projects.type.existingCode',
      };
    case ProjectType.greenfield:
      return {
        color: 'yellow',
        translationId: 'projects.type.greenfield',
      };
    case ProjectType.internal:
      return {
        color: 'green',
        translationId: 'projects.type.internal',
      };
    case ProjectType.peopleOutsourcing:
      return {
        color: 'orange',
        translationId: 'projects.type.peopleOutsourcing',
      };
    case ProjectType.projectOutsourcing:
      return {
        color: 'gray',
        translationId: 'projects.type.projectOutsourcing',
      };
    case ProjectType.workshop:
      return {
        color: 'orange',
        translationId: 'projects.type.workshop',
      };
    case ProjectType.fixedPrice:
      return {
        color: 'orange',
        translationId: 'projects.type.fixedPrice',
      };
    case ProjectType.timeAndMaterial:
      return {
        color: 'orange',
        translationId: 'projects.type.timeAndMaterial',
      };
  }
};

export const ProjectTypeBadge = ({ type }: ProjectTypeBadgeProps) => {
  const { formatMessage } = useLocale();

  const { color, translationId } = getBadgeProps(type);

  return <Badge isStatic color={color} badgeContent={formatMessage({ id: AppMessages[translationId] })} />;
};
