import { enqueueSnackbar } from 'notistack';

import { useMutation } from 'hooks/useMutation/useMutation';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { addDiscount } from 'api/actions/addDiscount/addDiscount';

export const useAddDiscount = (workStatementId: string) => {
  const { invalidateBillingSummaryCache } = useProjectBillingSummaryData();
  const { formatMessage } = useLocale();

  return useMutation(['addDiscount'], addDiscount(workStatementId), {
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: AppMessages['projectDetails.discounts.actions.add.success'] }));
      invalidateBillingSummaryCache();
    },
    onError: () => {
      enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.addDeposit.negativeTotalAmount'] }), {
        variant: 'error',
      });
    },
  });
};
