import { Stack } from '@mui/material';
import { Table } from 'lux/components';

import { formatSeniorityName, getPersonFullRole } from 'utils/stringUtils';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { fteToHumanValue, findRateByRoleAndSeniority } from 'app/need/utils';
import { useProject } from 'hooks/useProject/useProject';
import { usePerson } from 'hooks/usePerson/usePerson';
import { InfoBox } from 'ui/infoBox/InfoBox';
import { EmploymentType } from 'api/types/EmploymentType.enum';

import { RatesRow } from './ratesRow/RatesRow';
import * as styles from './ComparisonTable.styles';
import { ComparisonProps } from './ComparisonTable.types';
import { SectionHeaderRow } from './sectionHeaderRow/SectionHeaderRow';
import { ComparisonRow } from './comparisonRow/ComparisonRow';
import { HeadRow } from './headRow/HeadRow';
import { SkillRow } from './skillRow/SkillRow';
import { ComparisonTableSkeleton } from './ComparisonTable.skeleton';

export const ComparisonTable = ({
  personId,
  projectId,
  needData,
  effectiveRate,
  onEffectiveRateChange,
}: ComparisonProps) => {
  const { formatMessage } = useLocale();
  const { data: projectData, isLoading: isProjectDataLoading } = useProject(projectId);
  const { data: personData, isLoading: isPersonDataLoading } = usePerson(personId);

  if (isProjectDataLoading || isPersonDataLoading) {
    return <ComparisonTableSkeleton />;
  }

  if (!projectData || !personData) {
    return null;
  }

  const { person } = personData;
  const { currentRateCard } = projectData;
  const needRate = findRateByRoleAndSeniority(currentRateCard.items, needData.role, needData.seniority);
  const hasNeedRate = needRate !== undefined;
  const hasCustomRate = effectiveRate !== null;

  return (
    <Stack spacing={3} marginBottom={3}>
      <Table>
        <Table.Table sx={styles.table}>
          <Table.Head>
            <HeadRow projectName={projectData.name} person={person} />
          </Table.Head>
          <Table.Body data-cy="comparision-table_body">
            <SectionHeaderRow title={formatMessage({ id: AppMessages['card.need.comparisonTable.margin'] })} />
            <RatesRow
              employmentType={person.employmentType}
              needRate={needRate}
              personRates={person.currentPersonRate?.rates}
              currency={projectData.currency}
              effectiveRate={effectiveRate}
              onEffectiveRateChange={onEffectiveRateChange}
            />

            <SectionHeaderRow
              spacingTop
              title={formatMessage({ id: AppMessages['card.need.comparisonTable.basicInformation'] })}
            />
            <ComparisonRow
              highlightCondition={person.seniority !== needData.seniority}
              heading={formatMessage({ id: AppMessages['card.need.comparisonTable.seniority'] })}
              needCell={formatSeniorityName(needData.seniority)}
              personCell={formatSeniorityName(person.seniority)}
            />
            <ComparisonRow
              highlightCondition={false}
              heading={formatMessage({ id: AppMessages['card.need.comparisonTable.availableFrom'] })}
              needCell={needData.startDate}
              checkmarkCondition={false}
              personCell={'TODO'}
            />
            <ComparisonRow
              highlightCondition={needData.fte > person.fte}
              heading={formatMessage({ id: AppMessages['card.need.comparisonTable.fte'] })}
              needCell={formatMessage({ id: AppMessages[fteToHumanValue(needData.fte)] }, { fte: needData.fte })}
              personCell={formatMessage({ id: AppMessages[fteToHumanValue(Number(person.fte))] }, { fte: person.fte })}
            />

            <SectionHeaderRow
              spacingTop
              title={formatMessage({ id: AppMessages['card.need.comparisonTable.requiredSkills'] })}
            />
            {needData.skillSet?.map((skill) => (
              <SkillRow key={skill.name} requiredSkill={skill} personSkills={person.skills} />
            ))}
          </Table.Body>
        </Table.Table>
      </Table>
      {!hasNeedRate && !hasCustomRate && (
        <InfoBox
          variant="warning"
          title={formatMessage(
            { id: AppMessages['card.need.comparisonTable.warnings.missingNeedRate.title'] },
            { fullRole: getPersonFullRole(needData.seniority, needData.role) },
          )}
          actionLabel={formatMessage({
            id: AppMessages['card.need.comparisonTable.warnings.missingNeedRate.actionBtn'],
          })}
          onAction={() => {
            window.open(`${window.location.origin}/projects/${projectId}`, '_blank', 'noopener,noreferrer');
          }}
        >
          {formatMessage({ id: AppMessages['card.need.comparisonTable.warnings.missingNeedRate.message'] })}
        </InfoBox>
      )}
      {person.employmentType === EmploymentType.LTSH && !person.currentPersonRate?.rate && !hasCustomRate && (
        <InfoBox
          variant="warning"
          title={formatMessage({ id: AppMessages['card.need.comparisonTable.warnings.missingPersonRate.title'] })}
          actionLabel={formatMessage({
            id: AppMessages['card.need.comparisonTable.warnings.missingPersonRate.actionBtn'],
          })}
          onAction={() => {
            window.open(`${window.location.origin}/people/${personId}`, '_blank', 'noopener,noreferrer');
          }}
        >
          {formatMessage({ id: AppMessages['card.need.comparisonTable.warnings.missingPersonRate.message'] })}
        </InfoBox>
      )}
    </Stack>
  );
};
