import { Box, Dialog } from '@mui/material';

import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';

import { EditAssignmentContent } from './editAssignmentContent/EditAssignmentContent';
import { EditAssignmentSidebar } from './editAssignmentSidebar/EditAssignmentSidebar';
import type { EditAssignmentModalProps } from './EditAssignmentModal.types';
import * as styles from './EditAssignmentModal.styles';

export const EditAssignmentModal = ({ assignmentData, onClose, open }: EditAssignmentModalProps) => {
  useDialogBlockScroll(open);

  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose} sx={styles.dialog}>
      <EditAssignmentSidebar assignmentData={assignmentData} />

      <Box component="section" sx={styles.content}>
        <EditAssignmentContent onClose={onClose} assignmentData={assignmentData} />
      </Box>
    </Dialog>
  );
};
