import { useEffect } from 'react';

import { Divider } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Searchbar } from 'ui/searchbar/Searchbar';
import { useNeedsFilters } from 'hooks/useNeedsFilters/useNeedsFilters';
import { NeedsActiveFilters } from 'shared/needs/needsFilters/needsActiveFilters/NeedsActiveFilters';

import { NeedsSortingBar } from './needsSortingBar/NeedsSortingBar';
import * as styles from './NeedsListTopbar.styles';
import { SearchbarFormValues } from './NeedsListTopbar.types';

export const NeedsListTopbar = () => {
  const { formatMessage } = useLocale();

  const { filters, setFilterValue } = useNeedsFilters();

  const { control, handleSubmit, setValue, watch } = useForm<SearchbarFormValues>({
    defaultValues: {
      search: filters.search || '',
    },
  });

  const onSubmit: SubmitHandler<SearchbarFormValues> = (data) => {
    setFilterValue('search', data.search);
  };

  const handleClear = () => {
    setValue('search', '');
    setFilterValue('search', '');
  };

  useEffect(() => {
    setValue('search', filters.search || '');
  }, [filters.search]);

  return (
    <>
      <Searchbar
        label={formatMessage({ id: AppMessages['needs.searchbar.label'] })}
        name="search"
        control={control}
        onSubmit={handleSubmit(onSubmit)}
        isClearable={watch('search').length > 0}
        onClear={handleClear}
        focus={true}
      />
      <Divider sx={styles.divider} />
      <NeedsActiveFilters />
      <NeedsSortingBar />
    </>
  );
};
