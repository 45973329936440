import { useContext } from 'react';

import { ConfettiContext } from 'context/confetti/confettiContext/ConfettiContext';

export const useConfetti = () => {
  const context = useContext(ConfettiContext);

  if (!context) {
    throw new Error('ConfettiContext must be within ConfettiProvider');
  }

  return context;
};
