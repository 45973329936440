import { useIntl } from 'react-intl';

import { AppMessages } from 'i18n/messages';
import { WorkStatementTeamMemberHour } from 'api/types/BillingSummary.types';
import { useBillingSummaryPrice } from '../useBillingSummaryPrice/useBillingSummaryPrice';
import { roundHours } from 'utils/roundHours';

import { RateSummary } from './useBillingSummaryMemberHours.types';

const calculateRate = (hour: WorkStatementTeamMemberHour): number => hour.ratePercentage * hour.rateValue;

export const useBillingSummaryMemberHours = (
  hours: WorkStatementTeamMemberHour[],
  useDashForMultipleHours = false,
): RateSummary => {
  const { formatMessage } = useIntl();
  const { formatPrice } = useBillingSummaryPrice();

  const totalHours = roundHours(hours.reduce((acc, { amount }) => acc + amount, 0));
  let rateText = formatPrice(hours?.[0]?.rateValue || 0);

  if (hours.length) {
    if (useDashForMultipleHours && hours.length > 1) {
      rateText = '-';
    } else {
      const rateToCompare = calculateRate(hours[0]);
      if (hours.some((hour) => calculateRate(hour) !== rateToCompare)) {
        rateText = formatMessage({ id: AppMessages['projectDetails.billingSummary.multipleRates'] });
      }
    }
  }

  return {
    totalHours,
    rateText,
  };
};
