import { Styles } from 'styles/theme';

export const addButton: Styles = {
  '&.MuiButton-root': {
    alignSelf: 'flex-start',
    paddingX: 1,

    '&:disabled svg path': {
      fill: (theme) => theme.palette.action.disabled,
    },
  },
};

export const autocomplete: Styles = {
  flex: 1,
};

export const deleteButton: Styles = {
  '&:hover': {
    backgroundColor: 'error.light',
  },

  '& svg path': {
    fill: (theme) => theme.palette.error.main,
  },
};
