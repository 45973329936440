import { Accordion, AccordionSummary, AccordionDetails, Stack } from '@mui/material';
import { Typography } from 'lux/components';
import { ChevronDownIcon } from 'lux/icons';

import { AccordionFilterProps } from './AccordionFilter.types';
import * as styles from './AccordionFilter.styles';

export const AccordionFilter = ({ label, shouldStretch, dataCy, children }: AccordionFilterProps) => (
  <Accordion sx={styles.wrapper} defaultExpanded data-cy={dataCy}>
    <AccordionSummary sx={styles.summary} expandIcon={<ChevronDownIcon />}>
      <Typography variant="subtitle2">{label}</Typography>
    </AccordionSummary>
    <AccordionDetails sx={styles.details}>
      <Stack alignItems={shouldStretch ? 'stretch' : 'flex-start'}>{children}</Stack>
    </AccordionDetails>
  </Accordion>
);
