import { useContext } from 'react';

import { NeedModalContext } from 'context/needModal/needModalContext/NeedModalContext';

export const useNeedModal = () => {
  const context = useContext(NeedModalContext);

  if (!context) {
    throw new Error('NeedModalContext must be within NeedModalProvider');
  }

  return context;
};
