import { Box, Stack, Tooltip } from '@mui/material';
import { Badge, Emoji, Typography } from 'lux/components';
import { Link } from 'react-router-dom';

import { Card } from 'ui/card/Card';
import { TeamDetailsTab } from 'app/project/teamDetails/TeamDetails.types';
import { RejectionSource } from 'config/data/rejection/RejectionSource.enum';
import { Translation } from 'ui/translation/Translation';

import * as styles from './PersonRejection.styles';
import { PersonRejectionProps } from './PersonRejection.types';

export const PersonRejection = ({ rejection }: PersonRejectionProps) => (
  <Card sx={styles.card}>
    <Box
      component={Link}
      to={`/projects/${rejection.project.id}?tab=${TeamDetailsTab.rejected}`}
      target="_blank"
      sx={styles.cardWrapper}
    >
      <Card.Header>
        <Box sx={styles.header}>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant="subtitle2">{rejection.project.name}</Typography>
            {rejection.assignment?.isLeader && (
              <Tooltip title={<Translation id="emojiBadge.leader" />} placement="top" arrow>
                <Box>
                  <Emoji type="star" />
                </Box>
              </Tooltip>
            )}
          </Stack>
          <Box>
            <Typography sx={styles.date} variant="caption">
              <Translation
                id="personDetails.history.tab.rejected.rejectionDate"
                values={{ rejectionDate: rejection.rejectionDate }}
              />
            </Typography>
            <Badge
              isStatic
              color="red"
              badgeContent={
                <>
                  <Translation id="card.need.badge.rejected" />{' '}
                  {rejection.source && (
                    <Translation
                      id={
                        rejection.source === RejectionSource.assignment
                          ? 'card.need.badge.rejected.source.assignment'
                          : 'card.need.badge.rejected.source.need'
                      }
                    />
                  )}
                </>
              }
            />
          </Box>
        </Box>
      </Card.Header>
      <Card.Footer>
        {rejection.reason && (
          <Box sx={styles.reason}>
            <Typography variant="badgeLabel" color="text.secondary">
              <Translation
                id="personDetails.history.tab.rejected.reason"
                values={{
                  reason: rejection.reason,
                }}
              />
            </Typography>
          </Box>
        )}
      </Card.Footer>
    </Box>
  </Card>
);
