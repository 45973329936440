import { BadgeProps } from 'lux/components/atoms/badge/Badge.types';

import { DepositStatus } from 'api/types/BillingSummary.types';
import { intl } from 'context/locale/intl/Intl';
import { AppMessages, Translation } from 'i18n/messages';

export const depositStatusTranslations: Record<DepositStatus, Translation> = {
  [DepositStatus.WAITING_FOR_PAYMENT]:
    'projectDetails.billingSummary.workStatementDetails.deposits.status.waitingForPayment',
  [DepositStatus.PAID]: 'projectDetails.billingSummary.workStatementDetails.deposits.status.paid',
  [DepositStatus.OVERDUE]: 'projectDetails.billingSummary.workStatementDetails.deposits.status.overdue',
  [DepositStatus.RETURNED]: 'projectDetails.billingSummary.workStatementDetails.deposits.status.returned',
  [DepositStatus.CANCELED]: 'projectDetails.billingSummary.workStatementDetails.deposits.status.canceled',
};

const availableStatuses: Record<DepositStatus, DepositStatus[]> = {
  [DepositStatus.WAITING_FOR_PAYMENT]: [
    DepositStatus.WAITING_FOR_PAYMENT,
    DepositStatus.PAID,
    DepositStatus.OVERDUE,
    DepositStatus.CANCELED,
  ],
  [DepositStatus.OVERDUE]: [DepositStatus.OVERDUE, DepositStatus.PAID, DepositStatus.CANCELED],
  [DepositStatus.PAID]: [DepositStatus.PAID, DepositStatus.RETURNED],
  [DepositStatus.RETURNED]: [DepositStatus.PAID, DepositStatus.RETURNED],
  [DepositStatus.CANCELED]: [],
};

export const getAvailableStatuses = (currentStatus: DepositStatus): { value: string; label: string }[] => {
  const { formatMessage } = intl.currentIntl;

  const filteredDepositStatus: Partial<Record<DepositStatus, string>> = Object.keys(depositStatusTranslations)
    .filter((status) => availableStatuses[currentStatus].includes(status as DepositStatus))
    .reduce((acc, status) => {
      acc[status as DepositStatus] = depositStatusTranslations[status as DepositStatus];
      return acc;
    }, {} as Partial<Record<DepositStatus, string>>);

  return Object.keys(filteredDepositStatus).map((status) => ({
    value: status,
    label: formatMessage({ id: AppMessages[filteredDepositStatus[status as DepositStatus] as Translation] }),
  }));
};

export const getBadgeColor = (currentStatus: DepositStatus): BadgeProps['color'] => {
  switch (currentStatus) {
    case DepositStatus.WAITING_FOR_PAYMENT:
      return 'yellow';
    case DepositStatus.OVERDUE:
      return 'red';
    case DepositStatus.PAID:
      return 'green';
    case DepositStatus.RETURNED:
      return 'green';
    default:
      return 'lightGray';
  }
};
