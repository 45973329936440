import { DepartmentCode } from 'api/types/DepartmentCode.enum';
import { EmploymentType } from 'api/types/EmploymentType.enum';
import { Role } from 'api/types/Role.enum';
import { Seniority } from 'api/types/Seniority.enum';

export type CurrentUserPerson = {
  employeeId: number;
  availableFte: number;
  availabilityStatus: string;
  role: Role;
  firstName: string;
  lastName: string;
  email: string;
  employmentType: EmploymentType;
  departmentCode: DepartmentCode;
  fte: string;
  isEmployed: boolean;
  visibleOnProjects: boolean;
  updatedAt: string;
  supervisorId: number;
  picture: string | null;
  rate: number | null;
  currency: string | null;
  seniority: Seniority | null;
  managedDepartments: string[];
};

export type CurrentUserMetadata = {
  hasAccess: boolean;
  accessType: AccessType;
};

export type CurrentUser = {
  person: CurrentUserPerson;
  metadata: CurrentUserMetadata;
};

export enum AccessType {
  pm = 'LUX_PM',
  sales = 'LUX_SALES',
  rpt = 'LUX_RPT',
  noAccess = 'NO_ACCESS',
}
