import { forwardRef } from 'react';

import { TableVirtuoso } from 'react-virtuoso';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from 'lux/icons';
import { Table } from 'lux/components';

import { createDepartmentLabel } from 'config/data/departments';
import { formatSeniorityName } from 'utils/stringUtils';
import { PersonStatusBadge } from 'ui/personStatusBadge/PersonStatusBadge';
import { SquareIconButton } from 'ui/squareIconButton/SquareIconButton';
import { PersonBasicInfo } from 'shared/people/personBasicInfo/PersonBasicInfo';
import { Role } from 'api/types/Role.enum';
import { convertRateToDecimal } from 'utils/convertRate';

import { PeopleTableHead } from './peopleTableHead/PeopleTableHead';
import * as styles from './PeopleTable.styles';
import { PeopleTableProps } from './PeopleTable.types';

/*
    To meet the requirement that the whole table row should be a native link to person's profile
    we're using divs instead of <table>, <tr> etc. That's because using native HTML table elements,
    it not valid HTML to wrap an anchor (<a>) element around a table row (<tr>) element.
*/

export const PeopleTable = ({ peopleData, personAction, onEndReached }: PeopleTableProps) => {
  const hasCustomPersonAction = Boolean(personAction);

  return (
    <TableVirtuoso
      data={peopleData}
      fixedHeaderContent={PeopleTableHead}
      endReached={onEndReached}
      defaultItemHeight={53}
      increaseViewportBy={150}
      components={{
        FillerRow: (props) => (
          <Table.Row component="div">
            <Table.Cell component="div" style={{ height: props.height }} {...props} />
          </Table.Row>
        ),
        TableBody: forwardRef((props, ref) => (
          <Table.Body {...props} component="div" ref={ref} data-cy="people-table_body" sx={styles.tableWrapper} />
        )),
        TableHead: forwardRef((props, ref) => <Table.Head component="div" {...props} ref={ref} />),
        Table: (props) => <Table.Table sx={styles.table} size="small" component="div" {...props} />,
        TableRow: ({ item: person, ...props }) => (
          <Table.Row
            hover
            {...props}
            sx={styles.tableRow(!hasCustomPersonAction, person.role === Role.uncertain)}
            key={person.employeeId}
            component={hasCustomPersonAction ? 'div' : Link}
            to={`/people/${person.employeeId}`}
          />
        ),
      }}
      itemContent={(index, person) => {
        const mappedPerson = { ...person, rate: person.rate && convertRateToDecimal(person.rate) };
        return (
          <>
            <Table.Cell component="div">
              {hasCustomPersonAction ? (
                <Box
                  component={Link}
                  to={`/people/${mappedPerson.employeeId}`}
                  sx={styles.employeeLink}
                  target="_blank"
                >
                  <PersonBasicInfo
                    firstName={mappedPerson.firstName}
                    lastName={mappedPerson.lastName}
                    avatarSrc={mappedPerson.picture}
                    employmentType={mappedPerson.employmentType}
                    role={mappedPerson.role === Role.uncertain ? mappedPerson.role : undefined}
                    isLeader={mappedPerson.isLeader}
                    proposalCount={mappedPerson.proposalCount}
                    employedTo={mappedPerson.employedTo}
                    absences={mappedPerson.absences}
                  />
                </Box>
              ) : (
                <PersonBasicInfo
                  firstName={mappedPerson.firstName}
                  lastName={mappedPerson.lastName}
                  avatarSrc={mappedPerson.picture}
                  employmentType={mappedPerson.employmentType}
                  role={mappedPerson.role === Role.uncertain ? mappedPerson.role : undefined}
                  isLeader={mappedPerson.isLeader}
                  proposalCount={mappedPerson.proposalCount}
                  employedTo={mappedPerson.employedTo}
                  absences={mappedPerson.absences}
                />
              )}
            </Table.Cell>
            <Table.Cell component="div">{formatSeniorityName(mappedPerson.seniority)}</Table.Cell>
            <Table.Cell component="div">{createDepartmentLabel(mappedPerson.departmentCode)}</Table.Cell>
            <Table.Cell component="div">{mappedPerson.currentProject}</Table.Cell>
            <Table.Cell component="div">
              <PersonStatusBadge status={mappedPerson.availabilityStatus} availableFte={mappedPerson.availableFte} />
            </Table.Cell>
            <Table.Cell padding="none" component="div">
              <Box data-cy="people-table_person-action" className="hoverVisible">
                {personAction ? (
                  personAction(mappedPerson)
                ) : (
                  <SquareIconButton disableRipple size="small">
                    <ChevronRightIcon />
                  </SquareIconButton>
                )}
              </Box>
            </Table.Cell>
          </>
        );
      }}
    />
  );
};
