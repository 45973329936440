import { Box } from '@mui/material';

import { NeedsDataProps } from '../../Needs.types';
import { useNeedsFilters } from 'hooks/useNeedsFilters/useNeedsFilters';
import { NeedsGroupingOptions } from 'shared/needs/needsFilters/groupByProject/GroupByProject.types';
import { groupNeedsByProject } from 'shared/needs/Needs.functions';

import { ProjectsListSkeleton } from './projectsList/ProjectsList.skeleton';
import { NeedsListSkeleton } from './needsList/NeedsList.skeleton';
import { NeedsList } from './needsList/NeedsList';
import { ProjectsList } from './projectsList/ProjectsList';
import * as styles from './NeedsData.styles';

export const NeedsData = ({ needs, onNeedClick }: NeedsDataProps) => {
  const {
    filters: { groupBy },
  } = useNeedsFilters();

  return (
    <Box sx={styles.needsDataWrapper}>
      {groupBy === NeedsGroupingOptions.project ? (
        needs ? (
          <ProjectsList groupedNeeds={groupNeedsByProject(needs)} onNeedClick={onNeedClick} />
        ) : (
          <ProjectsListSkeleton />
        )
      ) : needs ? (
        <NeedsList needs={needs} onNeedClick={onNeedClick} />
      ) : (
        <NeedsListSkeleton />
      )}
    </Box>
  );
};
