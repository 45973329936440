import { AccessType } from 'context/auth/currentUser/CurrentUser.types';

const basicActions = {
  viewMenuTabs: 'VIEW_MENU_TABS',
} as const;

export type ActionsValue = typeof actions[keyof typeof actions];

export const actions = { ...basicActions };

export const permissions: Record<AccessType, ActionsValue[]> = {
  [AccessType.sales]: [actions.viewMenuTabs],
  [AccessType.pm]: [actions.viewMenuTabs],
  [AccessType.rpt]: [actions.viewMenuTabs],
  [AccessType.noAccess]: [],
};
