import { useLayoutEffect } from 'react';

import { Box } from '@mui/material';

import { ProjectTimesheet } from 'app/project/projectTimesheet/ProjectTimesheet';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';
import { useProjectTimesheet } from 'hooks/useProjectTimesheet/useProjectTimesheet';
import { isDateInSameMonth } from 'utils/dateUtils';

import { ClientTimesheetAccordionItemProps } from './ClientTimesheetAccordionItem.types';
import * as styles from './ClientTimesheetAccordionItem.styles';

export const ClientTimesheetAccordionItem = (props: ClientTimesheetAccordionItemProps) => {
  const {
    timesheetNumber,
    setFirstTimesheetMonthStartDate,
    firstTimesheetMonthStartDate,
    tsNumberToShowNav,
    setTsNumberToShowNav,
  } = props;

  const { timesheetMonthStartDate, setTimesheetMonthStartDate } = useProjectTimesheet();
  const { timesheetData, isLoadingTimesheetData } = useProjectTimesheetData();

  const firstTsNumer = 0;
  const isTheFirstTs = timesheetNumber === firstTsNumer;
  const isBillingInformationProjectsEmpty = tsNumberToShowNav < 0 && timesheetNumber !== firstTsNumer;

  // Below code is responsible for setting the same month for all ts and also controls above which ts should appear navigation and row with the days of the month

  useLayoutEffect(() => {
    const areDateNotEqual = !isDateInSameMonth(timesheetMonthStartDate, firstTimesheetMonthStartDate);

    if (isTheFirstTs && areDateNotEqual) {
      setFirstTimesheetMonthStartDate(timesheetMonthStartDate);
    }

    if (!isTheFirstTs && areDateNotEqual) {
      setTimesheetMonthStartDate(firstTimesheetMonthStartDate);
    }
  }, [firstTimesheetMonthStartDate, timesheetMonthStartDate]);

  useLayoutEffect(() => {
    const notSelectedFirstTs = -1;

    // Condition 1: No timesheet data and the current timesheetNumber is selected for navigation
    if (!timesheetData.length && timesheetNumber === tsNumberToShowNav && !isLoadingTimesheetData) {
      setTsNumberToShowNav(notSelectedFirstTs);
    }

    // Condition 2: Valid timesheet data and tsNumberToShowNav is greater than current timesheetNumber
    if (tsNumberToShowNav > timesheetNumber && timesheetData.length) {
      setTsNumberToShowNav(timesheetNumber);
    }

    // Condition 3: Valid timesheet data and tsNumberToShowNav is less than firstTsNumer
    if (tsNumberToShowNav < firstTsNumer && timesheetData.length) {
      setTsNumberToShowNav(timesheetNumber);
    }

    // Condition 4: Valid timesheet data and current timesheetNumber is the first timesheet
    if (timesheetData.length && timesheetNumber === firstTsNumer) {
      setTsNumberToShowNav(firstTsNumer);
    }
  }, [timesheetData, timesheetNumber, tsNumberToShowNav, isLoadingTimesheetData]);

  return (
    <Box sx={isBillingInformationProjectsEmpty ? styles.itemWrapper : {}}>
      <ProjectTimesheet isClientView={true} timesheetNumber={timesheetNumber} tsNumberToShowNav={tsNumberToShowNav} />
    </Box>
  );
};
