import type { ComponentType } from 'react';

import { Alert, Button } from 'lux/components';
import { ErrorFilledIcon, SuccessFilledIcon, WarningFilledIcon, InfoFilledIcon } from 'lux/icons';

import * as styles from './InfoBox.styles';
import type { InfoBoxProps, InfoBoxVariant } from './InfoBox.types';

const alertIcons: Record<InfoBoxVariant, ComponentType> = {
  success: SuccessFilledIcon,
  error: ErrorFilledIcon,
  info: InfoFilledIcon,
  warning: WarningFilledIcon,
};

export const InfoBox = ({ variant = 'info', onAction, actionLabel, title, children, sx }: InfoBoxProps) => {
  const IconComponent = alertIcons[variant];

  return (
    <Alert
      icon={<IconComponent />}
      title={title}
      description={children}
      severity={variant}
      sx={sx || styles.alert}
      action={
        actionLabel ? (
          <Button variant="text" sx={styles.actionButton} size="small" onClick={onAction}>
            {actionLabel}
          </Button>
        ) : undefined
      }
    />
  );
};
