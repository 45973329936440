import { useState } from 'react';

import { Table } from 'lux/components';
import { Stack } from '@mui/material';

import { CollapseButton } from 'ui/collapseButton/CollapseButton';
import { useBillingSummaryMemberHours } from 'hooks/useBillingSummaryMemberHours/useBillingSummaryMemberHours';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { useBillingSummaryPrice } from 'hooks/useBillingSummaryPrice/useBillingSummaryPrice';
import { CollapseRow } from '../../collapseRow/CollapseRow';
import { HourDetailsRow } from '../../hourDetailsRow/HourDetailsRow';
import { WorkStatementAvatarCell } from '../../workStatementAvatarCell/WorkStatementAvatarCell';

import { WorkStatementAssignmentProps } from './WorkStatementMember.types';
import * as styles from './WorkStatementMember.styles';

export const WorkStatementAssignment = ({
  assignment: { firstName, lastName, picture, role, hours, total },
}: WorkStatementAssignmentProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const { formatPrice } = useBillingSummaryPrice();
  const { totalHours, rateText } = useBillingSummaryMemberHours(hours, false);
  const { isReport } = useProjectBillingSummaryData();

  const hasMultipleHours = hours.length > 1;

  return (
    <>
      <Table.Row>
        <Table.Cell>
          <Stack direction="row" alignItems="center" gap={1}>
            <WorkStatementAvatarCell fullName={`${firstName} ${lastName}`} picture={picture} />
            {hasMultipleHours && !isReport && (
              <CollapseButton
                sx={styles.collapseButton}
                isExpanded={isExpanded}
                onClick={() => setIsExpanded(!isExpanded)}
              />
            )}
          </Stack>
        </Table.Cell>
        <Table.Cell>{role}</Table.Cell>
        <Table.Cell>{totalHours}</Table.Cell>
        <Table.Cell>{rateText}</Table.Cell>
        <Table.Cell>{formatPrice(total)}</Table.Cell>
      </Table.Row>
      {hasMultipleHours && hours && (
        <CollapseRow isExpanded={isExpanded} colSpan={5}>
          {hours.map((hour, idx) => (
            <HourDetailsRow key={idx} hour={hour} isLastRow={idx === hours.length - 1} />
          ))}
        </CollapseRow>
      )}
    </>
  );
};
