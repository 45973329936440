import { Styles } from 'styles/theme';

export const dashboardCardWrapper: Styles = {
  padding: 3,
  border: (theme) => `1px solid ${theme.palette.border.default}`,
  borderRadius: 2,
  backgroundColor: 'surface.default',
};

export const dashboardCardHeader: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 3,
};
