import { Typography } from 'lux/components';
import { useFormContext } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { getSeniorityRateAmounts } from 'utils/getSeniorityRateAmounts';
import { AssignWithoutNeedFormValues } from '../../AssignWithoutNeedModal.types';

import { RatesDetailsProps } from './RatesDetails.types';
import { Rate } from './rate/Rate';

export const RatesDetails = ({ rateCard, selectedRoleRates }: RatesDetailsProps) => {
  const { formatMessage } = useLocale();
  const { watch } = useFormContext<AssignWithoutNeedFormValues>();

  const selectedRole = watch('role');

  const [juniorRate, regularRate, seniorRate, sennaRate] = getSeniorityRateAmounts(selectedRoleRates);

  return (
    <>
      {selectedRole && (
        <Typography variant="caption" color="text.secondary">
          {formatMessage(
            {
              id: AppMessages[
                sennaRate ? 'assignWithoutNeed.formModal.sennaRate' : 'assignWithoutNeed.formModal.projectRates'
              ],
            },
            {
              role: selectedRole,
              juniorRate: <Rate currency={rateCard.currency} rate={juniorRate} />,
              midRate: <Rate currency={rateCard.currency} rate={regularRate} />,
              seniorRate: <Rate currency={rateCard.currency} rate={seniorRate} />,
              sennaRate: <Rate currency={rateCard.currency} rate={sennaRate} />,
            },
          )}
        </Typography>
      )}
    </>
  );
};
