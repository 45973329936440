import { Styles } from 'styles/theme';

export const button = (isOpen: boolean): Styles => ({
  backgroundColor: isOpen ? 'primary.light' : undefined,
});

export const menu: Styles = {
  marginLeft: 1.25,

  '.MuiMenu-paper ul': {
    borderRadius: 2,
  },

  '.MuiList-root': {
    padding: 0,
  },

  '.MuiButtonBase-root': {
    '&:first-of-type': {
      borderTopLeftRadius: 'inherit',
      borderTopRightRadius: 'inherit',
    },

    '&:last-of-type': {
      borderBottomLeftRadius: 'inherit',
      borderBottomRightRadius: 'inherit',
    },
  },
};
