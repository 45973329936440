import { Avatar, Badge, Typography } from 'lux/components';
import { Box } from '@mui/material';

import { formatSeniorityName } from 'utils/stringUtils';
import { createDepartmentLabel } from 'config/data/departments';
import { Translation } from 'ui/translation/Translation';
import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';

import * as styles from './EditAssignmentSidebar.styles';
import type { EditAssignmentSidebarProps } from './EditAssignmentSidebar.types';

export const EditAssignmentSidebar = ({ assignmentData }: EditAssignmentSidebarProps) => {
  const { person } = assignmentData;

  const personName = `${person.firstName} ${person.lastName}`;
  const roleWithSeniority = `${formatSeniorityName(person.seniority)} ${createDepartmentLabel(person.departmentCode)}`;

  return (
    <Box sx={styles.sidebar}>
      <Avatar size="xxl" image={person.picture} alt={personName} />

      <span>
        <Typography variant="h6">{personName}</Typography>
        <Typography variant="body2" sx={styles.roleName}>
          {roleWithSeniority}
        </Typography>
      </span>

      {assignmentData.status === AssignmentStatus.temporary && (
        <Badge isStatic color="blue" badgeContent={<Translation id="assignmentStatus.temporary" />} />
      )}
    </Box>
  );
};
