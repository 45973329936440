import type { Styles } from 'styles/theme';

export const container: Styles = {
  gap: 3,

  '& .MuiPaper-root': {
    alignItems: 'flex-start',

    '& .MuiAlert-icon': {
      mt: 0.3,
    },
  },
};

export const employeesList: Styles = {
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: 2,
  rowGap: 1,
  ml: 1,
};

export const personDetails: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  flexShrink: 1,
  overflow: 'hidden',
};

export const dialogActions: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  pt: 6,
};
