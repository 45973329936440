import { Stack } from '@mui/material';

import { Skeleton } from 'ui/skeleton/Skeleton';
import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

export const PeopleAvatarsSkeleton = ({ width }: { width?: string | number }) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <Skeleton circular width={32} height={32} />
    <TextSkeleton width={width || 128} variant="body2" sx={{ ml: 1 }} />
  </Stack>
);
