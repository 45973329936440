export enum Seniority {
  junior = 'JUNIOR',
  mid = 'MID',
  senior = 'SENIOR',
  withoutLevel = 'SENNA',
}

export enum FormSeniority {
  junior = 'JUNIOR',
  mid = 'MID',
  senior = 'SENIOR',
}
