import { useEffect, useState } from 'react';

import { Typography, Box } from '@mui/material';
import { DateTimePicker, TextField } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { DEFAULT_DATE_FORMAT, MAX_SUPPORTED_DATE, isDateBefore, isPastOrPresentDate } from 'utils/dateUtils';
import { Translation } from 'ui/translation/Translation';

import * as styles from './PersonRatesInput.styles';
import type { PersonRatesInputProps } from './PersonRatesInput.types';

export const PersonRatesInput = ({
  assignmentRates,
  currentRate,
  formCost,
  formApplyFrom,
  onFormCostChange,
  onFormApplyFromChange,
  onFormError,
  errors,
}: PersonRatesInputProps) => {
  const { t } = useLocale();

  const detectHighRateInForm = () =>
    assignmentRates.some((assignmentRate) => assignmentRate.rate < parseFloat(formCost));

  const [formHighRateWarning, setFormHighRateWarning] = useState(detectHighRateInForm());

  useEffect(() => {
    setFormHighRateWarning(detectHighRateInForm());
  }, [formCost, assignmentRates]);

  const handleRateChange = (value: string) => {
    const newCost = parseFloat(value);

    const costError = isNaN(newCost);
    onFormError({ formCostError: costError });

    if (costError) {
      onFormCostChange('');
    } else {
      onFormCostChange((newCost < 0 ? 0 : newCost).toString());
    }
  };

  const handleApplyDateChange = (value: Date | null) => {
    onFormError({
      formApplyFromError: !value || isNaN(+value) || isPastOrPresentDate(value, new Date(currentRate.appliesFrom)),
    });
    onFormApplyFromChange(value);
  };

  const calculateMinDate = () => {
    const date = new Date(currentRate.appliesFrom);
    date.setDate(date.getDate() + 1);

    if (formApplyFrom && isDateBefore(formApplyFrom, date)) {
      handleApplyDateChange(date);
    }

    return date;
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" gap={0.5} flexDirection="row" paddingBottom={formHighRateWarning ? 0 : 2.5}>
        <TextField
          fullWidth
          label={t('personDetails.accordion.details.personCost')}
          size="small"
          type="number"
          startAdornment={currentRate.currency}
          value={formCost}
          onChange={(event) => handleRateChange(event.target.value)}
          InputProps={{
            error: errors.formCost,
          }}
        />
        <DateTimePicker
          value={formApplyFrom}
          InputProps={{
            error: errors.formApplyFrom,
          }}
          label={t('personDetails.accordion.details.personCost.applyFrom')}
          views={['day']}
          minDate={calculateMinDate()}
          maxDate={MAX_SUPPORTED_DATE}
          onChange={(newValue) => handleApplyDateChange(newValue)}
          renderInput={(params) => <TextField fullWidth={true} {...params} size="small" />}
          inputFormat={DEFAULT_DATE_FORMAT}
        />
      </Box>

      {formHighRateWarning && (
        <Typography variant="caption" color="red" sx={styles.newRateCaption}>
          <Translation id="personDetails.accordion.details.personCost.edit.higher.message" />
        </Typography>
      )}
    </Box>
  );
};
