import { Table } from 'lux/components';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { parseSortFilter, stringifySortFilter } from 'utils/filters/filters';
import { SortOrder } from 'utils/filters/filters.types';
import { NeedsSortingField } from 'context/needsFilters/needsFiltersContext/NeedsFiltersContext.types';
import { useProjectNeedsFilters } from 'hooks/useProjectNeedsFilters/useProjectNeedsFilters';

import * as styles from './NeedsTableHead.styles';

export const NeedsTableHead = () => {
  const { formatMessage } = useLocale();

  const { setFilterValue, filters } = useProjectNeedsFilters();
  const { field: currentField, order } = parseSortFilter(filters.sort);

  const getDirection = (field: NeedsSortingField) => {
    if (currentField !== field) {
      return undefined;
    }
    return order.toLowerCase() as Lowercase<SortOrder>;
  };

  const handleLabelClick = (field: NeedsSortingField) => {
    const newOrder = currentField === field && order === SortOrder.asc ? SortOrder.desc : SortOrder.asc;

    setFilterValue('sort', stringifySortFilter(field, newOrder));
  };

  return (
    <Table.Head>
      <Table.Row>
        <Table.Cell width={280} sx={styles.tableHeadCell}>
          <Table.SortLabel
            active={currentField === NeedsSortingField.role}
            direction={getDirection(NeedsSortingField.role)}
            onClick={() => {
              handleLabelClick(NeedsSortingField.role);
            }}
          >
            {formatMessage({ id: AppMessages['projectDetails.tab.needs.table.role'] })}
          </Table.SortLabel>
        </Table.Cell>
        <Table.Cell width={130} sx={styles.tableHeadCell}>
          {formatMessage({ id: AppMessages['projectDetails.tab.needs.table.from'] })}
        </Table.Cell>
        <Table.Cell width={130} sx={styles.tableHeadCell}>
          {formatMessage({ id: AppMessages['projectDetails.tab.needs.table.to'] })}
        </Table.Cell>
        <Table.Cell width={100} sx={styles.tableHeadCell}>
          {formatMessage({ id: AppMessages['projectDetails.tab.needs.table.fte'] })}
        </Table.Cell>
        <Table.Cell width={320} sx={styles.tableHeadCell}>
          {formatMessage({ id: AppMessages['projectDetails.tab.needs.table.proposed'] })}
        </Table.Cell>
        <Table.Cell width={320} sx={styles.tableHeadCell}>
          {formatMessage({ id: AppMessages['projectDetails.tab.needs.table.approved'] })}
        </Table.Cell>
      </Table.Row>
    </Table.Head>
  );
};
