import { useSnackbar } from 'notistack';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { useQuery } from 'hooks/useQuery/useQuery';
import { currentUserKey } from 'utils/queryKeys';
import { getCurrentUserQuery } from 'api/actions/getCurrentUser/getCurrentUser';
import { GetCurrentUserParams, GetCurrentUserResponse } from 'api/actions/getCurrentUser/getCurrentUser.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { redirectToLogout } from 'utils/redirectToLogout';
import { useAuth } from '../useAuth/useAuth';

export const useCurrentUser = () => {
  const isAuthenticated = useIsAuthenticated();

  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useLocale();
  const { instance } = useMsal();
  const { logout } = useAuth();

  return useQuery<GetCurrentUserParams, GetCurrentUserResponse>(currentUserKey, getCurrentUserQuery, {
    enabled: isAuthenticated,
    staleTime: Infinity,
    onError: (error) => {
      const statusCode = error.response?.status;
      logout();

      if (statusCode === 403) {
        enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.currentUser.error.forbidden'] }), {
          variant: 'error',
          persist: true,
          key: 'user-auth-message',
          luxSnackbarAction: () => redirectToLogout(instance),
          luxSnackbarActionLabel: formatMessage({ id: AppMessages['snackbar.currentUser.revokeSession'] }),
          isWide: true,
        });

        return;
      }

      if (statusCode === 401) {
        enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.currentUser.error.unauthorized'] }), {
          persist: true,
          key: 'user-auth-message',
        });

        return;
      }

      if (statusCode === 404) {
        return;
      }

      enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.currentUser.error.unknown'] }), {
        variant: 'error',
        persist: true,
        key: 'user-auth-message',
        luxSnackbarAction: () => redirectToLogout(instance),
        luxSnackbarActionLabel: formatMessage({ id: AppMessages['snackbar.currentUser.revokeSession'] }),
        isWide: true,
      });
    },
  });
};
