import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { reopenProjectMutation } from 'api/actions/reopenProject/reopenProjectActions';
import { projectsKeys } from 'utils/queryKeys';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';

import { UseReopenProjectParams } from './useReopenProject.types';

export const useReopenProject = (params: UseReopenProjectParams) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();

  return useMutationDomainError(['reopenProject'], reopenProjectMutation(params.projectId), {
    onSuccess: () => {
      queryClient.invalidateQueries(projectsKeys.singleProject(params.projectId));
      queryClient.invalidateQueries(projectsKeys.projectsList);
      enqueueSnackbar(
        formatMessage({ id: AppMessages['snackbar.reopenProject.success'] }, { projectName: params.projectName }),
      );
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.reopenProject.error'] }),
  });
};
