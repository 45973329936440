import { getIndustriesQuery } from 'api/actions/getIndustries/getIndustries';
import { GetIndustriesParams, GetIndustriesResponse } from 'api/actions/getIndustries/getIndustries.types';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { clientsKeys } from 'utils/queryKeys';

export const useIndustries = () => {
  const { isAuthenticated } = useAuth();

  return useQuery<GetIndustriesParams, GetIndustriesResponse>(clientsKeys.industriesList, getIndustriesQuery, {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });
};
