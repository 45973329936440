import { Box } from '@mui/material';

import { Card } from 'ui/card/Card';

import * as styles from './EmployeeCard.styles';
import { EmployeeCardHeaderSkeleton } from './header/EmployeeCardHeader.skeleton';
import { EmployeeCardFooterSkeleton } from './footer/EmployeeCardFooter.skeleton';

export const EmployeeCardSkeleton = ({ withReason, withChips }: { withReason?: boolean; withChips?: boolean }) => (
  <Card sx={styles.employeeCard}>
    <Box sx={styles.employeeCardWrapper}>
      <Card.Header>
        <EmployeeCardHeaderSkeleton />
      </Card.Header>
      <Card.Footer>
        <EmployeeCardFooterSkeleton withReason={withReason} withChips={withChips} />
      </Card.Footer>
    </Box>
  </Card>
);
