import { useRef } from 'react';

import { Controller, FieldValues } from 'react-hook-form';

import { TextField } from 'ui/textField/TextField';

import { TextFieldProps } from './FormTextField.types';

export const FormTextField = <T extends FieldValues>(props: TextFieldProps<T>) => {
  const {
    name,
    control,
    label,
    sx,
    variant = 'outlined',
    isDisabled = false,
    type = 'text',
    startAdornment,
    endAdornment,
    size,
    fullWidth,
    multiline,
    rows,
    maxRows = 4,
    required,
    inputLabelShrink,
    hideHelperText = false,
    inputRef,
    autoFocus,
    onFocus = () => {},
    onBlur = () => {},
    onChangeParser,
    onKeyDown,
    ...rest
  } = props;

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          value={value}
          onChange={(event) => {
            const position = event.target.selectionStart;
            const parserResponse = onChangeParser?.(event) ?? { isValid: true, value: event.target.value };

            if (parserResponse.isValid) {
              onChange(parserResponse.value);
            }

            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }

            timerRef.current = setTimeout(() => {
              if (position) {
                event.target.selectionEnd = parserResponse.isValid ? position : position - 1;
              }

              timerRef.current = null;
            }, 0);
          }}
          label={label}
          sx={sx}
          inputRef={inputRef}
          type={type}
          size={size}
          fullWidth={fullWidth}
          multiline={multiline}
          minRows={rows}
          maxRows={maxRows}
          variant={variant}
          disabled={isDisabled}
          autoFocus={autoFocus}
          onFocus={onFocus}
          onBlur={onBlur}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          InputProps={{
            onKeyDown: onKeyDown,
            inputProps: { min: props.min, max: props.max, maxLength: props.maxLength },
          }}
          InputLabelProps={{ required, shrink: inputLabelShrink }}
          error={!!error}
          helperText={!!error?.message && !hideHelperText ? error.message : ''}
          {...rest}
        />
      )}
    />
  );
};
