import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { ProjectDetailsRoute } from 'routing/AppRoute.enum';

export const useReport = () => {
  const [isReport, setIsReport] = useState(false);
  const [reportDate, setReportDate] = useState<string | null>(null);

  const { pathname, search } = useLocation();

  const queryParams = new URLSearchParams(search);

  useEffect(() => {
    setIsReport(pathname.includes(ProjectDetailsRoute.report));

    const queryParamDate = queryParams.get('date');

    setReportDate(queryParamDate);
  }, [pathname]);

  return { isReport, reportDate };
};
