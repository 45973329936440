import { getSkillsQuery } from 'api/actions/getSkills/getSkills';
import { GetSkillsParams, GetSkillsResponse } from 'api/actions/getSkills/getSkills.types';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { dictionariesKeys } from 'utils/queryKeys';

export const useSkills = () => {
  const { isAuthenticated } = useAuth();

  return useQuery<GetSkillsParams, GetSkillsResponse>(dictionariesKeys.skillsList, getSkillsQuery, {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });
};
