import { Tabs } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectNeeds } from 'hooks/useProjectNeeds/useProjectNeeds';
import { AppMessages } from 'i18n/messages';
import { useProjectNeedsFilters } from 'hooks/useProjectNeedsFilters/useProjectNeedsFilters';

import { TabNeedsProps } from './TabNeeds.types';

export const TabNeeds = ({ projectId, ...rest }: TabNeedsProps) => {
  const { formatMessage } = useLocale();
  const { filters } = useProjectNeedsFilters();

  const { data: needs = [] } = useProjectNeeds({ projectId, ...filters });

  return (
    <Tabs.Tab
      {...rest}
      label={formatMessage({ id: AppMessages['projectDetails.teamDetails.needs.header'] }, { needs: needs.length })}
    />
  );
};
