import { Dialog } from '@mui/material';

import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';

import { CloseProjectModalProps } from './CloseProjectModal.types';
import { CloseProjectContent } from './closeProjectContent/CloseProjectContent';

export const CloseProjectModal = ({
  open,
  onClose,
  projectId,
  projectStartDate,
  projectEndDate,
  projectName,
}: CloseProjectModalProps) => {
  useDialogBlockScroll(open);

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <CloseProjectContent
        onClose={onClose}
        projectId={projectId}
        projectStartDate={projectStartDate}
        projectEndDate={projectEndDate}
        projectName={projectName}
      />
    </Dialog>
  );
};
