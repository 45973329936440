import { Styles } from 'styles/theme';

export const datePicker: Styles = {
  mt: 3.5,
  width: 360,

  '> *': {
    width: '100%',
  },
};

export const rateCardsList: Styles = {
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
};

export const divider: Styles = {
  borderColor: 'border.default',
};
