import { useQueryClient } from '@tanstack/react-query';

import { useMutation } from 'hooks/useMutation/useMutation';
import { assignmentsRemovalMutation } from 'api/actions/assignmentsRemoval/assignmentsRemoval';
import { assignmentsKeys, projectsKeys, rejectionsKeys } from 'utils/queryKeys';

export const useAssignmentsRemoval = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(['assignmentsRemoval'], assignmentsRemovalMutation(projectId), {
    onSuccess: () => {
      queryClient.invalidateQueries(assignmentsKeys.assignmentsList);
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(rejectionsKeys.rejectionsList);
    },
  });
};
