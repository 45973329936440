import { Stack, Box } from '@mui/material';
import { Typography } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { InfoBox } from 'ui/infoBox/InfoBox';
import { fteToDecimalFraction } from 'utils/fteToDecimalFraction';

import { FteWarningProps } from './FteWarning.types';

export const FteWarning = ({ warning, employeeId }: FteWarningProps) => {
  const { formatMessage } = useLocale();

  return (
    <InfoBox
      title={formatMessage({ id: AppMessages['assignOrProposeAnyway.warnings.fte.title'] })}
      variant="warning"
      actionLabel={formatMessage({ id: AppMessages['assignOrProposeAnyway.warnings.fte.action'] })}
      onAction={() => window.open(`${window.location.origin}/people/${employeeId}`, '_blank', 'noopener,noreferrer')}
    >
      <Stack direction="row" spacing={1.25}>
        <Box>
          {formatMessage({ id: AppMessages['assignOrProposeAnyway.warnings.fte.needed'] })}{' '}
          <Typography component="span" variant="inherit" fontWeight={500}>
            {fteToDecimalFraction(warning.expected)}
          </Typography>
        </Box>
        <Box>
          {formatMessage({ id: AppMessages['assignOrProposeAnyway.warnings.fte.available'] })}{' '}
          <Typography component="span" variant="inherit" fontWeight={500}>
            {fteToDecimalFraction(warning.available)}
          </Typography>
        </Box>
      </Stack>
    </InfoBox>
  );
};
