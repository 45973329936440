import { Styles } from 'styles/theme';

export const personContainer: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  borderRadius: 1,

  '&.MuiBox-root': {
    marginY: 0.5,
    padding: 1,
  },

  '&:hover': {
    backgroundColor: 'primary.light',
  },

  '&:hover .MuiButton-root.icon-only': {
    visibility: 'visible',
  },
};

export const deleteButton: Styles = {
  marginLeft: 'auto',
  visibility: 'hidden',
};
