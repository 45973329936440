import { useAuth } from 'hooks/useAuth/useAuth';
import { useInfiniteQuery } from 'hooks/useInfiniteQuery/useInfiniteQuery';
import { GetRejectionsParams, GetRejectionsResponse } from 'api/actions/getRejections/getRejections.types';
import { getInfiniteRejectionsQuery } from 'api/actions/getRejections/getRejections';
import { rejectionsKeys } from 'utils/queryKeys';
import { getNextPageParam } from 'api/utils/getNextPageParam';

import { UseRejectionsParams } from './useRejections.types';

export const useRejections = (params: UseRejectionsParams) => {
  const { isAuthenticated } = useAuth();
  const queryKey = [...rejectionsKeys.rejectionsList, params];

  return useInfiniteQuery<GetRejectionsParams, GetRejectionsResponse>(queryKey, getInfiniteRejectionsQuery, {
    cursorKey: 'page',
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    startPage: 1,
    args: {
      ...(params.person && { person: params.person }),
      ...(params.project && { project: params.project }),
      ...(params.source && { source: params.source }),
      limit: params.size || 25,
      ...(params['sort[rejectionDate]'] && { 'sort[rejectionDate]': params['sort[rejectionDate]'] }),
    },
    getNextPageParam: getNextPageParam,
  });
};
