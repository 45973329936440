import { Box } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { InfoBox } from 'ui/infoBox/InfoBox';

export const RouteErrorBoundary = () => {
  const { formatMessage } = useLocale();

  return (
    <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
      <InfoBox variant="error" title={formatMessage({ id: AppMessages['routeErrorBoundary.title'] })}>
        {formatMessage({ id: AppMessages['routeErrorBoundary.message'] })}
      </InfoBox>
    </Box>
  );
};
