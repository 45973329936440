import { LinkIcon } from 'lux/icons';

import { ReactComponent as AtlassianFavicon } from 'assets/images/favicons/favicon-atlassian.svg';
import { ReactComponent as ConfluenceFavicon } from 'assets/images/favicons/favicon-confluence.svg';
import { ReactComponent as DribbbleFavicon } from 'assets/images/favicons/favicon-dribbble.svg';
import { ReactComponent as GoogleDriveFavicon } from 'assets/images/favicons/favicon-google-drive.svg';
import { ReactComponent as SlackFavicon } from 'assets/images/favicons/favicon-slack.svg';

import { ProjectLinkFaviconProps } from './ProjectLinkFavicon.types';

export const ProjectLinkFavicon = ({ url }: ProjectLinkFaviconProps) => {
  if (url.includes('atlassian.net/wiki')) return <ConfluenceFavicon />;
  if (url.includes('atlassian.net')) return <AtlassianFavicon />;
  if (url.includes('dribbble.com')) return <DribbbleFavicon />;
  if (url.includes('drive.google.com')) return <GoogleDriveFavicon />;
  if (url.includes('slack.com')) return <SlackFavicon />;

  return <LinkIcon />;
};
