import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';
import { Filters } from 'hooks/useFilters/useFilters.types';

export enum AssignmentsSortingField {
  startDate = 'start_date',
  endDate = 'end_date',
  role = 'role',
}

export type AssignmentsFilters = {
  status: AssignmentStatus[];
  owner: string;
  sort: string;
  seniorities: string[];
  roles: string[];
  fte: string;
  startDate: string;
  endDate: string;
};

export type AssignmentsFiltersValue = {
  filters: AssignmentsFilters;
} & Filters<AssignmentsFilters>;
