import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Button } from 'lux/components';

import { useArchiveLead } from 'hooks/useArchiveLead/useArchiveLead';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';
import { AppMessages } from 'i18n/messages';

import * as styles from './ArchiveLeadModal.styles';
import { ArchiveLeadModalProps } from './ArchiveLeadModal.types';

export const ArchiveLeadModal = ({ projectId, projectName, open, onClose }: ArchiveLeadModalProps) => {
  const { formatMessage } = useLocale();
  const { mutate, isLoading } = useArchiveLead({ projectId, projectName });
  useDialogBlockScroll(open);

  const handleSubmitButtonClick = () => {
    mutate(
      {},
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>{formatMessage({ id: AppMessages['projectDetails.archiveLeadModal.title'] })}</DialogTitle>
      <DialogContent>{formatMessage({ id: AppMessages['projectDetails.archiveLeadModal.message'] })}</DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button variant="text" sx={styles.cancelButton} onClick={onClose}>
          {formatMessage({ id: AppMessages['button.cancel'] })}
        </Button>
        <Button loading={isLoading} variant="contained" onClick={handleSubmitButtonClick}>
          {formatMessage({ id: AppMessages['projectDetails.archiveLeadModal.button.submit'] })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
