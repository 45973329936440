import { Styles } from 'styles/theme';

export const boxWrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  columnGap: 1,
};

export const tooltipChildElement: Styles = {
  display: 'flex',
};
