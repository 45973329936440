import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { ClientContext } from '../clientContext/ClientContext';
import { ClientValue } from '../clientContext/ClientContext.types';
import { useClientDetails } from 'hooks/useClientDetails/useClientDetails';

import { ClientsContextControllerProps } from './ClientContextController.types';

export const ClientContextController = ({ children }: ClientsContextControllerProps) => {
  const { clientId } = useParams<{ clientId: string }>();
  const { data: clientDetails, isLoading } = useClientDetails(clientId);

  const contextValues: ClientValue = useMemo(() => ({ clientDetails, isLoading }), [clientDetails, isLoading]);

  return <ClientContext.Provider value={contextValues}>{children}</ClientContext.Provider>;
};
