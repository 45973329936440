import { Translation } from 'i18n/messages';
import { ActiveFilters } from 'ui/activeFilters/ActiveFilters';
import { filtersData } from 'config/data/people/filters';
import { ActiveFilter } from 'ui/activeFilters/ActiveFilters.types';
import { createActiveFilters } from 'ui/activeFilters/createActiveFilters';
import { useClientsFilters } from '../../../../hooks/useClientsFilters/useClientsFilters';
import { ClientsFilters } from '../../../../context/clientsFilters/clientsFiltersContext/ClientsFiltersContext.types';

const translationsBasedOnName: { name: string; labelTranslation: Translation }[] = [
  { name: 'search', labelTranslation: 'filters.search.value' },
];

export const ClientsActiveFilters = () => {
  const { filters, toggleFilter } = useClientsFilters();

  const handleFilterDelete = ({ name, value }: ActiveFilter) => {
    toggleFilter(name as keyof ClientsFilters, value);
  };

  const activeFiltersData = createActiveFilters(filters, {
    exclude: ['sort'],
    translationsBasedOnValue: filtersData,
    translationsBasedOnName: translationsBasedOnName,
  });

  return (
    <ActiveFilters
      activeFiltersData={activeFiltersData}
      onFilterDelete={handleFilterDelete}
      dataCy="available-clients_active-filters"
    />
  );
};
