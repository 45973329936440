import Joi from 'joi';

export type AddBillingCycleFormProps = {
  onClose: () => void;
};

export const addBillingCycleFormSchema = Joi.object().keys({
  amount: Joi.number().required(),
});

export type AddBillingCycleFormData = {
  amount: number;
};
