import { Translation } from 'i18n/messages';
import { ActiveFilters } from 'ui/activeFilters/ActiveFilters';
import { filtersData } from 'config/data/assignments/filters';
import { ActiveFilter } from 'ui/activeFilters/ActiveFilters.types';
import { createActiveFilters } from 'ui/activeFilters/createActiveFilters';
import { useAssignmentsFilters } from 'hooks/useAssignmentsFilters/useAssignmentsFilters';
import { AssignmentsFilters } from 'context/assignmentsFilters/assignmentsFiltersContext/AssignmentsFiltersContext.types';

const translationsBasedOnName: { name: string; labelTranslation: Translation }[] = [
  { name: 'startDate', labelTranslation: 'filters.availability.from.value' },
  { name: 'endDate', labelTranslation: 'filters.availability.to.value' },
];

export const AssignmentsActiveFilters = () => {
  const { filters, toggleFilter } = useAssignmentsFilters();

  const handleFilterDelete = ({ name, value }: ActiveFilter) => {
    toggleFilter(name as keyof AssignmentsFilters, value);
  };

  const activeFiltersData = createActiveFilters(filters, {
    exclude: ['owner', 'sort'],
    translationsBasedOnValue: filtersData,
    translationsBasedOnName: translationsBasedOnName,
  });

  return <ActiveFilters activeFiltersData={activeFiltersData} onFilterDelete={handleFilterDelete} />;
};
