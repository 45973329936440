import { Styles } from 'lux/theme';

export const workStatementHeader: Styles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  px: 2,
  py: 1,
  borderRadius: 2,
  backgroundColor: 'primary.light',
  [`@media print`]: {
    display: 'none',
  },
};

export const workStatementTitle: Styles = {
  fontWeight: 600,
  fontSize: 16,
  ml: 0.4,
};

export const workStatementActions: Styles = {
  ml: 'auto',
  display: 'flex',
  alignItems: 'center',
  gap: 1,

  '& .MuiButtonBase-root': {
    whiteSpace: 'nowrap',
  },
};

export const infoIcon: Styles = {
  path: {
    color: 'info.main',
    fill: 'currentColor',
  },
};

export const skeleton: Styles = {
  mt: 2,
};
