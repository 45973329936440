import { useQueryClient } from '@tanstack/react-query';

import { assignmentsKeys, projectsKeys } from 'utils/queryKeys';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { updateAssignmentsMutation } from 'api/actions/updateAssignments/updateAssignmentsActions';

export const useUpdateAssignments = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutationDomainError(['updateAssignments'], updateAssignmentsMutation(projectId), {
    onSuccess: () => {
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(assignmentsKeys.assignmentsList);
    },
  });
};
