import { Styles } from 'styles/theme';

export const formElementBox: Styles = {
  marginBottom: 3,
};

export const formElementBoxInline: Styles = {
  display: 'flex',
  marginBottom: 3,
};

export const formElementWrapperFixed: Styles = {
  width: 496,
  marginBottom: 3,
};

export const datePickerWrapper: Styles = {
  marginBottom: 3,
};
