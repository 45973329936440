import { useEffect } from 'react';

import { useInView } from 'react-intersection-observer';
import { UseInfiniteQueryResult } from '@tanstack/react-query';

export const useInfiniteScroll = <T extends UseInfiniteQueryResult>(infiniteQuery: T) => {
  const { ref, inView, entry } = useInView();

  const queryMethods = infiniteQuery;

  useEffect(() => {
    if (inView && !queryMethods.isFetching && queryMethods.hasNextPage) {
      queryMethods.fetchNextPage();
    }
  }, [inView, entry]);

  return { ref, queryMethods };
};
