import { useEffect, useRef, useState } from 'react';

import { Box, Popover } from '@mui/material';
import { Button } from 'lux/components';

import * as styles from './ButtonWithPopover.styles';
import type { ButtonWithPopoverProps } from './ButtonWithPopover.types';

export const ButtonWithPopover = ({
  active,
  children,
  count,
  dataCy,
  icon,
  label,
  open,
  activeOnOpen = false,
  popoverSpacing = 1,
  ButtonProps,
  PopoverProps,
  onClose,
}: ButtonWithPopoverProps) => {
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(open || false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (open !== undefined) {
      setOptionsMenuOpen(open);
    }
  }, [open]);

  const handleButtonClick = () => {
    setOptionsMenuOpen(true);
  };

  const closeMenu = () => {
    setOptionsMenuOpen(false);
    onClose?.();
  };
  return (
    <>
      <Button
        data-cy={dataCy}
        size="small"
        startIcon={icon}
        variant="contained"
        onClick={handleButtonClick}
        ref={buttonRef}
        sx={styles.popoverButton(active || (activeOnOpen && optionsMenuOpen), count)}
        {...ButtonProps}
      >
        {label}
      </Button>
      <Popover
        open={optionsMenuOpen}
        onClose={closeMenu}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={styles.popover(popoverSpacing)}
        {...PopoverProps}
      >
        <Box sx={styles.popoverContent}>{children}</Box>
      </Popover>
    </>
  );
};
