import { FormProvider } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useUnsavedChangesDialogContext } from 'hooks/useUnsavedChangesDialogContext/useUnsavedChangesDialogContext';
import { AppMessages } from 'i18n/messages';
import { useAddUncertainPerson } from 'hooks/useAddUncertain/useAddUncertain';
import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';
import { peopleKeys } from 'utils/queryKeys';
import { uncertainPersonSchema } from 'shared/people/UncertainPersonForm/UncertainPerson.schema';
import { UncertainPersonForm } from 'shared/people/UncertainPersonForm/UncertainPersonForm';
import { UncertainPersonFormValues } from 'shared/people/UncertainPersonForm/UncertainPersonForm.types';
import { createUncertainPersonPayload } from 'shared/people/UncertainPersonForm/createUncertainPersonPayload';

import { AddUncertainContentProps } from './AddUncertainContent.types';

const defaultValues: UncertainPersonFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  departmentCode: '',
  seniorityEnabled: false,
  seniority: '',
  fte: '',
  fteCustom: '',
  employmentType: '',
  rate: '',
  currency: '',
};

export const AddUncertainContent = ({ onClose }: AddUncertainContentProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { DialogClickAwayListenerWrapper } = useUnsavedChangesDialogContext();

  const { mutate, isLoading } = useAddUncertainPerson();

  const form = useIntlForm<UncertainPersonFormValues>({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(uncertainPersonSchema()),
  });

  const onSubmit = (data: UncertainPersonFormValues) => {
    const payload = createUncertainPersonPayload(data);

    mutate(payload, {
      onSuccess: () => {
        enqueueSnackbar(
          formatMessage(
            { id: AppMessages['people.addUncertain.submit.success'] },
            { fullName: `${payload.firstName} ${payload.lastName}` },
          ),
        );
        queryClient.invalidateQueries(peopleKeys.peopleList);
        onClose();
      },
    });
  };

  return (
    <DialogClickAwayListenerWrapper defaultValues={defaultValues} form={form} onClose={onClose}>
      <DialogInnerWrapper
        title={formatMessage({ id: AppMessages['people.addUncertain.title'] })}
        loading={isLoading}
        onClose={() => {
          onClose();
        }}
        submitText={formatMessage({ id: AppMessages['people.addUncertain.actionBtn.text'] })}
        onSubmit={form.handleSubmit(onSubmit)}
        isFormValid={form.formState.isValid}
      >
        <FormProvider {...form}>
          <UncertainPersonForm operation="add" loading={isLoading} />
        </FormProvider>
      </DialogInnerWrapper>
    </DialogClickAwayListenerWrapper>
  );
};
