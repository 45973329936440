import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { AddChargeParams } from './addCharge.types';

export const addCharge: (workStatementId: string) => MutationFn<AddChargeParams> =
  (workStatementId: string) => (body) => ({
    endpoint: `/work-statements/${workStatementId}/charges`,
    method: 'POST',
    params: body,
  });
