import { EmployeeCardSkeleton } from 'app/employee/card/EmployeeCard.skeleton';

export const PeopleListSkeleton = () => (
  <>
    {Array(10)
      .fill(null)
      .map((_, index) => (
        <EmployeeCardSkeleton withChips key={index} />
      ))}
  </>
);
