import { InvoiceStatusEnum } from '../api/types/BillingSummary.types';

export const allowedInvoiceStatuses = [
  InvoiceStatusEnum.created,
  InvoiceStatusEnum.to_correct,
  InvoiceStatusEnum.internal_billing,
];

export const isInvoiceModifiable = (invoiceStatus?: InvoiceStatusEnum): boolean =>
  !!invoiceStatus && allowedInvoiceStatuses.includes(invoiceStatus);
