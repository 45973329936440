import { useTimeRange } from 'hooks/useTimeRange/useTimeRange';
import { TimeRangeKeys, NullableDate } from 'hooks/useTimeRange/useTimeRange.types';

import { UseAvailabilityRangeParams, AvailabilityRangeKeys } from './useAvailabilityRange.types';

export const useAvailabilityRange = ({ filters, setFilter, clearFilter }: UseAvailabilityRangeParams) => {
  const internalValues = { startDate: filters.availabilityFrom, endDate: filters.availabilityTo };

  const setInternalValue = (key: TimeRangeKeys, value: string) => {
    if (key === 'startDate') {
      setFilter('availabilityFrom', value);
    }

    if (key === 'endDate') {
      setFilter('availabilityTo', value);
    }
  };

  const clearInternalValue = (key: TimeRangeKeys) => {
    if (key === 'startDate') {
      clearFilter('availabilityFrom');
    }

    if (key === 'endDate') {
      clearFilter('availabilityTo');
    }
  };

  const { clear, values, errors, onDateChange } = useTimeRange({
    filters: internalValues,
    setFilter: setInternalValue,
    clearFilter: clearInternalValue,
  });

  const handleDateChange = (newValue: NullableDate, field: AvailabilityRangeKeys) => {
    if (field === 'availabilityFrom') {
      onDateChange(newValue, 'startDate');
    }

    if (field === 'availabilityTo') {
      onDateChange(newValue, 'endDate');
    }
  };

  return {
    values: { availabilityFrom: values.startDate, availabilityTo: values.endDate },
    clear,
    errors: { availabilityFrom: errors.startDate, availabilityTo: errors.endDate },
    onDateChange: handleDateChange,
  };
};
