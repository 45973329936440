import { useEffect, useState } from 'react';

import { Typography, Box } from '@mui/material';
import { DateTimePicker, Select, TextField } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { DEFAULT_DATE_FORMAT, MAX_SUPPORTED_DATE, ZERO_UNIX_DATE, isFutureDate } from 'utils/dateUtils';
import * as styles from '../../personRates/personRatesInput/PersonRatesInput.styles';
import { mapToSelectOptions } from 'ui/form/select/Select.mappers';
import { Currency } from 'api/types/Currency.enum';

import { PersonNoRatesInputProps } from './PersonNoRatesInput.types';

export const PersonNoRatesInput = ({
  assignmentRates,
  formCost,
  formApplyFrom,
  formCurrency,
  onFormCostChange,
  onFormApplyFromChange,
  onFormCurrencyChange,
  onFormError,
  errors,
}: PersonNoRatesInputProps) => {
  const { formatMessage } = useLocale();

  const detectHighRateInForm = () =>
    !!assignmentRates?.some((assignmentRate) => assignmentRate.rate < parseInt(formCost));

  const [formHighRateWarning, setFormHighRateWarning] = useState(detectHighRateInForm());

  useEffect(() => {
    setFormHighRateWarning(detectHighRateInForm());
  }, [formCost, assignmentRates]);

  const handleRateChange = (value: string) => {
    const newCost = parseInt(value);

    const costError = isNaN(newCost);
    onFormError({ formCostError: costError });

    if (costError) {
      onFormCostChange('');
    } else {
      onFormCostChange((newCost < 0 ? 0 : newCost).toString());
    }
  };

  const handleApplyDateChange = (value: Date | null) => {
    onFormError({
      formApplyFromError: !value || isNaN(+value) || isFutureDate(value),
    });
    onFormApplyFromChange(value);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" gap={0.5} flexDirection="row" paddingBottom={formHighRateWarning ? 0 : 2.5}>
        <Select
          name="currency"
          size="small"
          label={formatMessage({ id: AppMessages['uncertainPerson.form.currency.label'] })}
          options={mapToSelectOptions(Object.values(Currency))}
          value={formCurrency}
          onChange={(event) => onFormCurrencyChange(event.target.value)}
          fullWidth
        />
        <TextField
          fullWidth
          name="person.rate"
          label={formatMessage({ id: AppMessages['uncertainPerson.form.rate.label'] })}
          size="small"
          type="number"
          value={formCost}
          onChange={(event) => handleRateChange(event.target.value)}
          InputProps={{
            error: errors.formCost,
          }}
        />
        <DateTimePicker
          value={formApplyFrom}
          InputProps={{
            error: errors.formApplyFrom,
          }}
          label={formatMessage({ id: AppMessages['personDetails.accordion.details.personCost.applyFrom'] })}
          views={['day']}
          minDate={ZERO_UNIX_DATE}
          maxDate={MAX_SUPPORTED_DATE}
          disableFuture={true}
          onChange={(newValue) => handleApplyDateChange(newValue)}
          renderInput={(params) => <TextField {...params} size="small" fullWidth />}
          inputFormat={DEFAULT_DATE_FORMAT}
        />
      </Box>

      {formHighRateWarning && (
        <Typography variant="caption" color="red" sx={styles.newRateCaption}>
          {formatMessage({ id: AppMessages['personDetails.accordion.details.personCost.edit.higher.message'] })}
        </Typography>
      )}
    </Box>
  );
};
