import { useState } from 'react';

import { InlineEdit, Typography } from 'lux/components';

import { useModifyPersonRate } from 'hooks/useModifyPersonRate/useModifyPersonRate';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Currency } from 'api/types/Currency.enum';
import { convertRateToInteger } from 'utils/convertRate';
import { formatDate } from 'utils/dateUtils';

import { PersonNoRatesProps } from './PersonNoRates.types';
import { PersonNoRatesInput } from './personNoRatesInput/PersonNoRatesInput';
import { PersonRateErrors } from './personNoRatesInput/PersonNoRatesInput.types';

export const PersonNoRates = ({ isPersonPlaceholder, personId, assignmentRates }: PersonNoRatesProps) => {
  const { formatMessage } = useLocale();
  const [editMode, setEditMode] = useState(false);

  const [formCost, setFormCost] = useState('');
  const [formCurrency, setFormCurrency] = useState('');
  const [formApplyFrom, setFormApplyFrom] = useState<Date | null>(null);

  const [errors, setErrors] = useState({
    formCost: false,
    formApplyFrom: false,
    formCurrency: false,
  });

  const { mutateAsync: modifyRate } = useModifyPersonRate(personId);

  const handleSubmit = async () => {
    if (!formApplyFrom || !formCost || errors.formApplyFrom || errors.formCost) return;

    await modifyRate({
      appliesFrom: formatDate(formApplyFrom),
      rate: { rate: convertRateToInteger(formCost), currency: formCurrency as Currency },
    });

    setEditMode(false);
  };

  return (
    <InlineEdit
      header={formatMessage({ id: AppMessages['personDetails.accordion.details.personCost'] })}
      disabled={isPersonPlaceholder}
      input={
        <PersonNoRatesInput
          formCost={formCost}
          formApplyFrom={formApplyFrom}
          formCurrency={formCurrency}
          onFormCostChange={setFormCost}
          onFormApplyFromChange={setFormApplyFrom}
          onFormCurrencyChange={setFormCurrency}
          errors={errors}
          onFormError={({ formApplyFromError, formCostError, formCurrencyError }: PersonRateErrors) => {
            setErrors({
              formCost: formCostError ?? errors.formCost,
              formApplyFrom: formApplyFromError ?? errors.formApplyFrom,
              formCurrency: formCurrencyError ?? errors.formCurrency,
            });
          }}
          assignmentRates={assignmentRates}
        />
      }
      onSubmit={handleSubmit}
      isActive={editMode}
      onCancel={() => setEditMode(false)}
      onDataDisplayClick={() => setEditMode(true)}
    >
      <Typography>-</Typography>
    </InlineEdit>
  );
};
