import { useQueryClient } from '@tanstack/react-query';

import { AppMessages } from 'i18n/messages';
import { updateProjectMutation } from 'api/actions/updateProject/updateProjectActions';
import { projectsKeys, dictionariesKeys, assignmentsKeys } from 'utils/queryKeys';
import { useMutationDomainError } from '../useMutationDomainError/useMutationDomainError';
import { useLocale } from '../useLocale/useLocale';

export const useUpdateProject = (projectId: string) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();

  return useMutationDomainError(['updateProjectMutation'], updateProjectMutation(projectId), {
    onSuccess: () => {
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(dictionariesKeys.tagsList);
      queryClient.invalidateQueries(assignmentsKeys.assignmentsList);
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.updateProject.error'] }),
  });
};
