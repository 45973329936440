import React, { forwardRef } from 'react';

import { Box, Stack } from '@mui/material';
import { Avatar, Typography } from 'lux/components';

import { formatCompanyPosition } from 'utils/stringUtils';
import { EmojiBadges } from 'ui/emojiBadges/EmojiBadges';
import { OverflowTooltipTypography } from 'ui/overflowTooltipTypography/OverflowTooltipTypography';

import * as styles from './PersonBasicInfo.styles';
import { PersonBasicInfoProps } from './PersonBasicInfo.types';

export const PersonBasicInfo = forwardRef<HTMLDivElement, PersonBasicInfoProps>(
  (
    {
      firstName,
      lastName,
      avatarSrc,
      role,
      seniorityRole,
      employmentType,
      isLeader,
      proposalCount,
      nameVariant = 'body2',
      employedTo,
      absences,
      ...rest
    },
    ref,
  ) => (
    <Box sx={styles.employeeBasicInfo} {...rest} ref={ref}>
      <Avatar image={avatarSrc} name={`${firstName} ${lastName}`} sx={styles.avatar} alt={`${firstName} ${lastName}`} />
      <Stack sx={styles.employeeInfoWrapper} ref={ref}>
        <Stack direction="row" gap={1} alignItems="center">
          <OverflowTooltipTypography text={`${firstName} ${lastName}`} variant={nameVariant} />
          <EmojiBadges
            employmentType={employmentType}
            isLeader={isLeader}
            proposalCount={proposalCount}
            employedTo={employedTo}
            absences={absences}
          />
        </Stack>
        {role && (
          <Typography variant="caption" color="text.secondary">
            {seniorityRole ? seniorityRole : formatCompanyPosition(role)}
          </Typography>
        )}
      </Stack>
    </Box>
  ),
);
