import { getDaysInMonth, isSameMonth, isSameYear } from 'date-fns';

import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useProjectTimesheet } from 'hooks/useProjectTimesheet/useProjectTimesheet';

export const useProjectStartDay = () => {
  const { timesheetMonthStartDate } = useProjectTimesheet();
  const { startDate } = useProjectDetails();

  const projectStartDay =
    isSameMonth(startDate, timesheetMonthStartDate) && isSameYear(startDate, timesheetMonthStartDate)
      ? startDate.getDate()
      : null;

  const daysInMonth = getDaysInMonth(startDate);

  return { projectStartDay, daysInMonth };
};
