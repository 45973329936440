import { AvailabilityData, AvailabilityDetails } from 'api/actions/getAvailability/getAvailability.types';
import { AvailabilityByDepartment, AvailabilityByDepartmentOption } from '../AvailabilityContent.types';
import { PeriodType } from 'context/availabilityFilters/availabilityFiltersContext/AvailabilityFiltersContext.types';
import { safeSum } from 'utils/safeSum';
import { safeMerge } from 'utils/safeMerge';

export const INITIAL_DATA_ROWS_COUNT = 1;

export const getAvailabilityTableData = (
  data: AvailabilityData,
  period: PeriodType,
  managedDepartments: string[] = [],
) => {
  const dataByDepartment = data[period].reduce<AvailabilityByDepartment>((acc, curr) => {
    for (const department in curr.people) {
      acc = {
        ...acc,
        [department]: [...(acc?.[department] || []), { from: curr.from, ...curr.people[department] }],
      };
    }

    return acc;
  }, {});

  const summedData = data[period].reduce<AvailabilityByDepartmentOption[]>((acc, option) => {
    const details = Object.values(option.people).reduce<AvailabilityDetails | null>((prev, curr) => {
      if (prev === null) {
        return curr;
      }

      return {
        available: {
          all: safeSum(prev.available?.all, curr.available?.all),
          junior: safeSum(prev.available?.junior, curr.available?.junior),
          regular: safeSum(prev.available?.regular, curr.available?.regular),
          senior: safeSum(prev.available?.senior, curr.available?.senior),
        },
        availableWithProposed: {
          all: safeSum(prev.availableWithProposed?.all, curr.availableWithProposed?.all),
          junior: safeSum(prev.availableWithProposed?.junior, curr.availableWithProposed?.junior),
          regular: safeSum(prev.availableWithProposed?.regular, curr.availableWithProposed?.regular),
          senior: safeSum(prev.availableWithProposed?.senior, curr.availableWithProposed?.senior),
        },
        availableWithExtensions: {
          all: safeSum(prev.availableWithExtensions?.all, curr.availableWithExtensions?.all),
          junior: safeSum(prev.availableWithExtensions?.junior, curr.availableWithExtensions?.junior),
          regular: safeSum(prev.availableWithExtensions?.regular, curr.availableWithExtensions?.regular),
          senior: safeSum(prev.availableWithExtensions?.senior, curr.availableWithExtensions?.senior),
        },
        availableWithAll: {
          all: safeSum(prev.availableWithAll?.all, curr.availableWithAll?.all),
          junior: safeSum(prev.availableWithAll?.junior, curr.availableWithAll?.junior),
          regular: safeSum(prev.availableWithAll?.regular, curr.availableWithAll?.regular),
          senior: safeSum(prev.availableWithAll?.senior, curr.availableWithAll?.senior),
        },
        availableNames: safeMerge(prev.availableNames, curr.availableNames),
        availableAllNames: safeMerge(prev.availableAllNames, curr.availableAllNames),
        availableNamesAssignments: safeMerge(prev.availableNamesAssignments, curr.availableNamesAssignments),
        availableNamesWithExtension: safeMerge(prev.availableNamesWithExtension, curr.availableNamesWithExtension),
        availableNamesWithProposed: safeMerge(prev.availableNamesWithProposed, curr.availableNamesWithProposed),
        availableNamesWithoutProposed: safeMerge(
          prev.availableNamesWithoutProposed,
          curr.availableNamesWithoutProposed,
        ),
        totalInDepartment: prev.totalInDepartment + curr.totalInDepartment,
      };
    }, null);

    if (details) {
      return [...acc, { from: option.from, ...details }];
    }

    return acc;
  }, []);

  const availabilityData = Object.entries(dataByDepartment);

  let initialData = managedDepartments.reduce<typeof availabilityData>((acc, managedDepartment) => {
    const availabilityManagedDepartmentIndex = availabilityData.findIndex(
      ([availabilityDepartment]) => availabilityDepartment === managedDepartment,
    );
    if (availabilityManagedDepartmentIndex === -1) {
      return acc;
    }

    return [...acc, ...availabilityData.splice(availabilityManagedDepartmentIndex, INITIAL_DATA_ROWS_COUNT)];
  }, []);

  if (!initialData.length) {
    initialData = availabilityData.splice(0, INITIAL_DATA_ROWS_COUNT);
  }

  return { initialData, expandedData: availabilityData, summedData };
};
