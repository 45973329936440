import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { RemoveUncertainPersonRequest, RemoveUncertainPersonResponse } from './removeUncertainPerson.types';

export const removeUncertainPersonMutation: (
  id: number,
) => MutationFn<RemoveUncertainPersonRequest, RemoveUncertainPersonResponse> = (employeeID: number) => (body) => ({
  endpoint: `/people/${employeeID}/remove-uncertain`,
  method: 'DELETE',
  params: body,
});
