import { Grid } from 'lux/components';

import { DashboardCard } from 'ui/dashboardCard/DashboardCard';
import { NeedsListTopbar } from '../needsListTopbar/NeedsListTopbar';
import { NeedsAccordionFilters } from '../needsAccordionFilters/NeedsAccordionFilters';
import { NeedsContent } from 'shared/needs/needsContent/NeedsContent';

import * as styles from './NeedsGrid.styles';

export const NeedsGrid = () => (
  <Grid container sx={styles.needsGridWrapper} gutter={0} overflow="hidden">
    <Grid overflow="hidden" display="flex" height="100%" flexDirection="column" item xs={4}>
      <DashboardCard
        sx={{
          wrapper: styles.cardWrapper,
        }}
      >
        <NeedsAccordionFilters />
      </DashboardCard>
    </Grid>

    <Grid item xs={8} display="flex" flexDirection="column" height="100%" overflow="hidden">
      <DashboardCard
        sx={{
          wrapper: styles.cardWrapper,
        }}
      >
        <NeedsListTopbar />
        <NeedsContent />
      </DashboardCard>
    </Grid>
  </Grid>
);
