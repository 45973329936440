import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';

export enum UncertainEmploymentType {
  inhouse = 'inhouse',
  subcontractor = 'subcontractor',
}

export const employmentTypeList = () => {
  const { formatMessage } = intl.currentIntl;

  return [
    {
      id: '1',
      children: formatMessage({ id: AppMessages['people.employmentType.inhouse'] }),
      value: UncertainEmploymentType.inhouse,
    },
    {
      id: '2',
      children: formatMessage({ id: AppMessages['people.employmentType.subcontractor'] }),
      value: UncertainEmploymentType.subcontractor,
    },
  ];
};
