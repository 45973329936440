import { Styles } from 'styles/theme';

export const snackbar = (wide?: boolean): Styles => ({
  minWidth: 400,
  width: wide ? 800 : undefined,

  '& .MuiAlert-action': {
    display: 'flex',
    alignItems: 'center',
  },
  '.MuiPaper-root': {
    flexWrap: 'nowrap',
  },
  '.MuiSnackbarContent-action': {
    gap: 1,

    '.MuiButtonBase-root': {
      ml: 0,
      whiteSpace: 'nowrap',
    },
  },
});
