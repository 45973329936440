import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { PlanProjectExtensionPayload } from './planProjectExtensionAction.types';

export const planProjectExtensionMutation: (projectId: string) => MutationFn<PlanProjectExtensionPayload> =
  (projectId: string) => (body) => ({
    endpoint: `/projects/${projectId}/plan-extension`,
    method: 'PATCH',
    params: body,
  });
