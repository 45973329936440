import { Box, Stack } from '@mui/material';
import { Badge, DataDisplay, Accordion, Tooltip } from 'lux/components';

import { PersonBasicInfo } from 'shared/people/personBasicInfo/PersonBasicInfo';
import { useLocale } from 'hooks/useLocale/useLocale';
import { usePersonData } from 'hooks/usePersonData/usePersonData';
import { AppMessages } from 'i18n/messages';
import { getEmploymentTypeTranslationKey } from 'utils/stringUtils';
import { EmploymentType } from 'api/types/EmploymentType.enum';
import { isPlaceholder } from 'utils/isPlaceholder';

import * as styles from './PersonDetails.styles';
import { PersonRates } from './personRates/PersonRates';
import { PersonNoRates } from './personNoRates/PersonNoRates';
import { PersonDetailsProps } from './PersonDetails.types';

export const PersonDetails = ({ assignmentRates }: PersonDetailsProps) => {
  const {
    person: { employmentType, skills, supervisor, ...personData },
  } = usePersonData();
  const { formatMessage } = useLocale();

  const isPersonPlaceholder = isPlaceholder(personData);

  return (
    <Accordion summary={formatMessage({ id: AppMessages['personDetails.accordion.details'] })} defaultExpanded>
      <Accordion.Details>
        <Stack spacing={2} sx={styles.stack}>
          {!!supervisor && (
            <DataDisplay header={formatMessage({ id: AppMessages['personDetails.accordion.details.supervisor'] })}>
              <PersonBasicInfo
                firstName={supervisor.firstName}
                lastName={supervisor.lastName}
                avatarSrc={supervisor.picture}
                employmentType={supervisor.employmentType}
                isLeader={supervisor.isLeader}
                proposalCount={supervisor.proposalCount}
                nameVariant="caption"
                employedTo={supervisor.employedTo}
                absences={supervisor.absences}
              />
            </DataDisplay>
          )}

          <DataDisplay header={formatMessage({ id: AppMessages['personDetails.accordion.details.employmentType'] })}>
            <Stack direction="row" gap={1}>
              <Badge
                isStatic
                color="blue"
                badgeContent={formatMessage({
                  id: AppMessages[getEmploymentTypeTranslationKey(employmentType)],
                })}
              />
              {isPlaceholder(personData) && (
                <Badge
                  isStatic
                  color="blue"
                  badgeContent={formatMessage({
                    id: AppMessages['people.employmentType.uncertain'],
                  })}
                />
              )}
            </Stack>
          </DataDisplay>

          {employmentType === EmploymentType.LTSH && (
            <>
              {personData.currentPersonRate && (
                <PersonRates
                  assignmentRates={assignmentRates}
                  currentRate={personData.currentPersonRate}
                  futureRate={personData.futurePersonRate}
                  personId={personData.employeeId}
                  isPersonPlaceholder={isPersonPlaceholder}
                />
              )}
              {!personData.currentPersonRate && !isPersonPlaceholder && (
                <PersonNoRates
                  assignmentRates={assignmentRates}
                  personId={personData.employeeId}
                  isPersonPlaceholder={isPersonPlaceholder}
                />
              )}
            </>
          )}

          {skills.length > 0 && (
            <Stack width={'100%'} overflow={'hidden'}>
              <DataDisplay header={formatMessage({ id: AppMessages['personDetails.accordion.details.skills'] })}>
                <Stack gap={1} direction="row" flexWrap="wrap" width={'100%'}>
                  {skills.map(({ id, name }) => (
                    <Tooltip title={name} placement="top" arrow key={id}>
                      <Box>
                        <Badge isStatic color="gray" badgeContent={name} sx={styles.badge} />
                      </Box>
                    </Tooltip>
                  ))}
                </Stack>
              </DataDisplay>
            </Stack>
          )}
        </Stack>
      </Accordion.Details>
    </Accordion>
  );
};
