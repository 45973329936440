import { useMutation } from 'hooks/useMutation/useMutation';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { editDepositStatusMutation } from 'api/actions/editDepositStatus/editDepositStatusActions';

export const useEditDepositStatus = (workStatementId: string, depositId: string) => {
  const { invalidateBillingSummaryCache } = useProjectBillingSummaryData();

  return useMutation(['editDepositStatusMutation'], editDepositStatusMutation(workStatementId, depositId), {
    onSuccess: () => {
      invalidateBillingSummaryCache();
    },
  });
};
