import { Badge } from 'lux/components';
import { Accent } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ProjectStage } from 'api/types/ProjectStage.enum';

import { ProjectStageBadgeProps } from './ProjectStageBadge.types';

const getStageColor = (stage: string): keyof Accent => {
  switch (stage) {
    case ProjectStage.ongoing:
      return 'green';
    case ProjectStage.lead:
      return 'yellow';
    case ProjectStage.closing:
      return 'orange';
    case ProjectStage.closed:
    case ProjectStage.archived:
    default:
      return 'gray';
  }
};

const stageBadgeLabel = {
  [ProjectStage.lead]: 'projects.filters.stage.option.lead',
  [ProjectStage.ongoing]: 'projects.filters.stage.option.ongoing',
  [ProjectStage.closing]: 'projects.filters.stage.option.closing',
  [ProjectStage.closed]: 'projects.filters.stage.option.closed',
  [ProjectStage.archived]: 'projects.filters.stage.option.archived',
} as const;

export const ProjectStageBadge = ({ stage }: ProjectStageBadgeProps) => {
  const { formatMessage } = useLocale();

  return (
    <Badge
      isStatic
      color={getStageColor(stage)}
      badgeContent={
        stage in stageBadgeLabel
          ? formatMessage({ id: AppMessages[stageBadgeLabel[stage as keyof typeof stageBadgeLabel]] })
          : stage
      }
    />
  );
};
