import { FormLabel, Box } from '@mui/material';
import { Typography, Button } from 'lux/components';

import { PopoverHeadingProps } from './PopoverHeading.types';
import * as styles from './PopoverHeading.styles';

export const PopoverHeading = ({ title, actionLabel, onActionClick }: PopoverHeadingProps) => (
  <Box sx={styles.wrapper}>
    {title && (
      <FormLabel component="legend">
        <Typography variant="subtitle2" sx={styles.title}>
          {title}
        </Typography>
      </FormLabel>
    )}

    {actionLabel && (
      <Button
        onClick={onActionClick}
        size="small"
        variant="text"
        color="primary"
        data-cy="popover-filter_action-button"
      >
        {actionLabel}
      </Button>
    )}
  </Box>
);
