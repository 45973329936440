import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { ModifyPersonRateParams } from './modifyPersonRateAction.types';

export const modifyPersonRateMutation: (personId: number) => MutationFn<ModifyPersonRateParams> =
  (personId: number) => (body) => ({
    endpoint: `/people/${personId}/modify-rate`,
    method: 'POST',
    params: body,
  });
