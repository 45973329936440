import { useEffect } from 'react';

import { Divider } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Searchbar } from 'ui/searchbar/Searchbar';
import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { PeopleActiveFilters } from 'shared/people/peopleFilters/peopleActiveFilters/PeopleActiveFilters';

import * as styles from './PeopleListTopbar.styles';
import { SearchbarFormValues } from './PeopleListTopbar.types';

export const PeopleListTopbar = () => {
  const { formatMessage } = useLocale();

  const { filters, setFilterValue } = usePeopleFilters();

  const { control, handleSubmit, setValue, watch } = useForm<SearchbarFormValues>({
    defaultValues: {
      search: filters.search || '',
    },
  });

  const onSubmit: SubmitHandler<SearchbarFormValues> = (data) => {
    setFilterValue('search', data.search);
  };

  const handleClear = () => {
    setValue('search', '');
    setFilterValue('search', '');
  };

  useEffect(() => {
    setValue('search', filters.search || '');
  }, [filters.search]);

  return (
    <>
      <Searchbar
        label={formatMessage({ id: AppMessages['people.searchbar.label'] })}
        name="search"
        control={control}
        onSubmit={handleSubmit(onSubmit)}
        isClearable={watch('search').length > 0}
        onClear={handleClear}
        focus={true}
      />
      <Divider sx={styles.divider} />
      <PeopleActiveFilters />
    </>
  );
};
