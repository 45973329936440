import { Styles } from 'styles/theme';

export const searchbarWrapper: Styles = {
  display: 'flex',
  gap: 3,
  marginBottom: 3,
  label: {
    left: -3,
    paddingRight: 0.5,
  },
};
