import { useContext } from 'react';

import { AvailabilityPeopleContext } from '../availabilityPeopleContext/AvailabilityPeopleContext';

export const useAvailabilityPeople = () => {
  const context = useContext(AvailabilityPeopleContext);

  if (!context) {
    throw new Error('AvailabilityPeopleContext must be within AvailabilityPeopleContextProvider');
  }

  return context;
};
