import { useSnackbar } from 'notistack';

import { getApiError } from 'api/utils/getApiError';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useAssignPerson } from 'hooks/useAssignPerson/useAssignPerson';
import { AssignWithoutNeedHandler } from '../assignWithoutNeedModal/AssignWithoutNeedModal.types';
import { AssignWithoutNeedModal } from '../assignWithoutNeedModal/AssignWithoutNeedModal';

import { AssignWithoutNeedContainerProps } from './AssignWithoutNeedContainer.types';

export const AssignWithoutNeedContainer = ({
  defaultFilters,
  projectId,
  isOpen,
  onClose,
}: AssignWithoutNeedContainerProps) => {
  const { t } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isLoading } = useAssignPerson(projectId);

  const handleAssignWithoutNeed: AssignWithoutNeedHandler = (payload, { onSuccess, onError }, assignedEmployee) => {
    mutate(payload, {
      onSuccess: () => {
        onSuccess();
        enqueueSnackbar(t('assignWithoutNeed.request.success', assignedEmployee));
      },
      onError: (err) => {
        onError(err);

        if (err.response?.status !== 409) {
          enqueueSnackbar(getApiError(err));
        }
      },
    });
  };

  return (
    <AssignWithoutNeedModal
      defaultFilters={defaultFilters}
      projectId={projectId}
      isOpen={isOpen}
      isAssignmentInProgress={isLoading}
      onAssign={handleAssignWithoutNeed}
      onClose={onClose}
    />
  );
};
