import { Box, Stack } from '@mui/material';
import { Emoji, Typography } from 'lux/components';

import { BannerProps, BannerVariant } from './Banner.types';
import * as styles from './Banner.styles';

export const Banner = ({ title, emoji, subtitle, actions, variant = BannerVariant.info }: BannerProps) => (
  <Box sx={styles.bannerContainer}>
    <Stack sx={styles.banner(variant)}>
      <Stack sx={styles.contentWrapper}>
        <Box sx={styles.icon}>
          <Emoji type={emoji} />
        </Box>
        <Stack sx={styles.content}>
          <Typography variant="body1" sx={styles.heading}>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="body2" sx={styles.description}>
              {subtitle}
            </Typography>
          )}
        </Stack>
      </Stack>
      {actions && (
        <Stack sx={styles.actions} direction="row" gap={2}>
          {actions}
        </Stack>
      )}
    </Stack>
  </Box>
);
