import { Person } from 'api/actions/getPerson/getPerson.types';

import { formatDate, parseISODate } from './dateUtils';

const convertDate = (date: string) => formatDate(parseISODate(date));

export const convertPeopleDates = (people: Person[]): Person[] =>
  people.map(({ employedSince, employedTo, ...rest }) => ({
    ...rest,
    employedSince: convertDate(employedSince),
    employedTo: employedTo ? convertDate(employedTo) : null,
  }));
