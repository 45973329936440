import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Filter } from 'ui/filter/Filter';
import { countries } from 'config/data/countries';
import { useClientsFilters } from 'hooks/useClientsFilters/useClientsFilters';
import { AutocompleteStack } from 'ui/autocompleteStack/AutocompleteStack';

import { ClientCountryFilterProps } from './ClientCountryFilter.types';

export const ClientCountryFilter = ({ variant }: ClientCountryFilterProps) => {
  const {
    filters: { country },
    setFilterValue,
  } = useClientsFilters();
  const { formatMessage } = useLocale();

  const handleCountryChange = (newValue: string[]) => {
    setFilterValue('country', newValue);
  };

  return (
    <Filter
      variant={variant}
      label={formatMessage({ id: AppMessages['clients.clientsList.filter.country'] })}
      shouldStretch
    >
      <AutocompleteStack
        label={formatMessage({ id: AppMessages['clients.clientsList.filter.country'] })}
        buttonLabel={formatMessage({ id: AppMessages['clients.clientsList.filter.actionBtn.country'] })}
        dictionary={countries}
        values={country}
        onFieldsChange={handleCountryChange}
      />
    </Filter>
  );
};
