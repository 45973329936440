import Joi from 'joi';

import { Currency } from 'api/types/Currency.enum';
import { AppMessages } from 'i18n/messages';
import { Seniority } from 'api/types/Seniority.enum';
import { UncertainEmploymentType } from 'config/data/people/uncertain';
import { intl } from 'context/locale/intl/Intl';

export const uncertainPersonSchema = () => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object().keys({
    firstName: Joi.string()
      .required()
      .messages({
        'string.empty': formatMessage(
          { id: AppMessages['validation.empty'] },
          { label: formatMessage({ id: AppMessages['uncertainPerson.form.firstName.label'] }) },
        ),
      }),
    lastName: Joi.string()
      .required()
      .messages({
        'string.empty': formatMessage(
          { id: AppMessages['validation.empty'] },
          { label: formatMessage({ id: AppMessages['uncertainPerson.form.lastName.label'] }) },
        ),
      }),
    email: Joi.string()
      .email({ minDomainSegments: 2, tlds: { allow: false } })
      .allow('')
      .optional()
      .messages({
        'string.email': formatMessage({ id: 'uncertainPerson.form.email.error' }),
      }),
    departmentCode: Joi.string()
      .required()
      .messages({
        'string.empty': formatMessage(
          { id: AppMessages['validation.empty'] },
          { label: formatMessage({ id: AppMessages['uncertainPerson.form.departmentCode.label'] }) },
        ),
      }),
    seniorityEnabled: Joi.boolean().optional().strip(),
    seniority: Joi.when('seniorityEnabled', {
      is: true,
      then: Joi.string()
        .valid(...Object.values(Seniority))
        .required(),
      otherwise: Joi.string().allow('').optional().strip(),
    }),
    fte: Joi.alternatives().try(Joi.number(), Joi.string()).required(),
    fteCustom: Joi.when('fte', {
      is: Joi.string().valid('other'),
      then: Joi.number().min(1).max(40).required(),
      otherwise: Joi.number().allow('').optional().strip(),
    }).messages({
      'number.base': formatMessage(
        { id: AppMessages['validation.number'] },
        { label: formatMessage({ id: AppMessages['uncertainPerson.form.fteCustom.label'] }) },
      ),
      'number.min': formatMessage(
        { id: AppMessages['validation.min'] },
        { label: formatMessage({ id: AppMessages['uncertainPerson.form.fteCustom.label'] }), limit: 1 },
      ),
      'number.max': formatMessage(
        { id: AppMessages['validation.max'] },
        { label: formatMessage({ id: AppMessages['uncertainPerson.form.fteCustom.label'] }), limit: 40 },
      ),
    }),
    employmentType: Joi.string()
      .required()
      .messages({
        'string.empty': formatMessage(
          { id: AppMessages['validation.empty'] },
          { label: formatMessage({ id: AppMessages['uncertainPerson.form.employmentType.label'] }) },
        ),
      }),
    rate: Joi.when('employmentType', {
      is: Joi.string().valid(UncertainEmploymentType.subcontractor),
      then: Joi.number().required(),
      otherwise: Joi.number().allow('').optional().strip(),
    }).messages({
      'number.base': formatMessage(
        { id: AppMessages['validation.number'] },
        { label: formatMessage({ id: AppMessages['uncertainPerson.form.rate.label'] }) },
      ),
    }),
    currency: Joi.when('employmentType', {
      is: Joi.string().valid(UncertainEmploymentType.subcontractor),
      then: Joi.string()
        .valid(...Object.values(Currency))
        .required(),
      otherwise: Joi.string().allow('').optional().strip(),
    }),
  });
};
