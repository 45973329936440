import { Box } from '@mui/material';
import { AddIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useTitle } from 'hooks/useTitle/useTitle';
import { AppMessages } from 'i18n/messages';
import { PageHeader } from 'ui/pageHeader/PageHeader';

import { ClientsGrid } from './clientsGrid/ClientsGrid';
import * as styles from './Clients.styles';

export const Clients = () => {
  const { formatMessage } = useLocale();
  useTitle(formatMessage({ id: AppMessages['title.clients'] }));

  return (
    <Box sx={styles.clientsPageWrapper}>
      <PageHeader
        title={formatMessage({ id: AppMessages['clients.header.title'] })}
        actionButton={{
          actionTitle: formatMessage({ id: AppMessages['clients.button.addClient'] }),
          icon: <AddIcon />,
          disabled: true,
          tooltip: formatMessage({ id: AppMessages['clients.button.addClient.tooltip'] }),
          tooltipPlacement: 'left',
        }}
      />
      <ClientsGrid />
    </Box>
  );
};
