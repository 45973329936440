import { ChangeEvent } from 'react';

import { Select } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { AppMessages } from 'i18n/messages';
import { filtersData } from 'config/data/people/filters';

import * as styles from './PeopleSorting.styles';

export const PeopleSorting = () => {
  const { filters, toggleFilter } = usePeopleFilters();
  const { formatMessage } = useLocale();

  const handleSortingChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    toggleFilter('sort', event.target.value);
  };

  const sortOptions = filtersData.sort.map((option) => ({
    id: option.labelTranslation,
    children: formatMessage({ id: option.labelTranslation }),
    value: option.value,
  }));

  return (
    <Select
      value={filters.sort}
      onChange={handleSortingChange}
      label={formatMessage({ id: AppMessages['sorting.label'] })}
      size="small"
      options={sortOptions}
      sx={styles.select}
      data-cy="available-people_sort"
    />
  );
};
