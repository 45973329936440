import { Grid } from 'lux/components';

import { DetailsGridProps } from './DetailsGrid.types';
import * as styles from './DetailsGrid.styles';

export const DetailsGrid = ({ children }: DetailsGridProps) => (
  <Grid container columns={2} sx={styles.gridContainer}>
    {children}
  </Grid>
);
