import { useState, useCallback } from 'react';

import { Table, Typography } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { SkillsListProps } from './SkillsList.types';

export const SkillsList = ({ skillSet }: SkillsListProps) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const { formatMessage } = useLocale();
  const showSkills = skillSet && skillSet?.length > 0;
  const showPaginations = skillSet && skillSet.length > 5;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  return (
    <>
      {showSkills && (
        <Table component="div">
          <Table.Table size="small" aria-label="List of required skills">
            <Table.Head>
              <Table.Row>
                <Table.Cell>
                  <Typography fontWeight={600} color="text.secondary" variant="caption">
                    {formatMessage({ id: AppMessages['requestNeed.table.header.name'] })}
                  </Typography>
                </Table.Cell>
                <Table.Cell>
                  <Typography fontWeight={600} color="text.secondary" variant="caption">
                    {formatMessage({ id: AppMessages['requestNeed.table.header.weight'] })}
                  </Typography>
                </Table.Cell>
                <Table.Cell>
                  <Typography fontWeight={600} color="text.secondary" variant="caption">
                    {formatMessage({ id: AppMessages['requestNeed.table.header.note'] })}
                  </Typography>
                </Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {skillSet.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                <Table.Row key={`${item.name}-${index}`}>
                  <Table.Cell component="th" scope="row">
                    <Typography variant="caption">{item.name}</Typography>
                  </Table.Cell>
                  <Table.Cell>
                    <Typography variant="caption">{item.weight}</Typography>
                  </Table.Cell>
                  <Table.Cell>{item?.note && <Typography variant="caption">{item.note}</Typography>}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Table>
        </Table>
      )}
      {showPaginations && (
        <Table.Pagination
          rowsPerPageOptions={[5, 10, 25]}
          SelectProps={{
            native: true,
          }}
          backIconButtonProps={{
            color: 'secondary',
          }}
          component="div"
          count={skillSet.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};
