import { joiResolver } from '@hookform/resolvers/joi';
import { Stack } from '@mui/material';
import { Badge, InlineEdit } from 'lux/components';
import { FieldValues } from 'react-hook-form';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { MultipleAutocomplete } from 'ui/form/autocomplete/MultipleAutocomplete';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useEditMode } from 'hooks/useEditMode/useEditMode';

import { AutocompleteEditProps } from './AutocompleteEdit.types';
import { multipleAutocompleteEditSchema } from './MultipleAutocompleteEdit.schema';

export const MultipleAutocompleteEdit = <T extends FieldValues>(props: AutocompleteEditProps<T>) => {
  const {
    name,
    label,
    value,
    defaultValues,
    onSubmit,
    options,
    isFreeSolo,
    loading,
    disabled,
    inputMaxLength,
    customRenderTags,
    disableClearable,
  } = props;
  const { formatMessage } = useLocale();
  const { isEditMode, setOpenEditMode, setCloseEditMode, onFormSubmit } = useEditMode(onSubmit, disabled);

  const { control, handleSubmit } = useIntlForm({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(multipleAutocompleteEditSchema(name)),
  });

  return (
    <InlineEdit
      isActive={isEditMode}
      header={formatMessage({ id: AppMessages[label] })}
      onCancel={setCloseEditMode}
      onSubmit={handleSubmit(onFormSubmit)}
      onDataDisplayClick={setOpenEditMode}
      loading={loading}
      disabled={disabled}
      input={
        <MultipleAutocomplete
          disableClearable={disableClearable}
          customRenderTags={customRenderTags}
          name={name}
          size="small"
          control={control}
          label={formatMessage({ id: AppMessages[label] })}
          options={options}
          freeSolo={isFreeSolo}
          autoFocus
          fullWidth
          inputMaxLength={inputMaxLength}
        />
      }
    >
      <Stack direction="row" flexWrap="wrap" gap={0.5}>
        {value.map((tag: string, index: number) => (
          <Badge isStatic key={`${tag}-${index}`} color="gray" badgeContent={tag} />
        ))}
      </Stack>
    </InlineEdit>
  );
};
