import { SxProps } from '@mui/system';

import {
  centerOfMarkerBorderLineWidth,
  halfOfMarkerBorderTriangleWidth,
  markerBorderTriangleWidth,
  nonStartDayCellWidth,
  startDayCellWidth,
} from 'utils/timesheetProjectStartDayIndicator';

export const projectStartDayMarker = (startDay: number | null, daysInMonth: number): SxProps => ({
  ...(!!startDay && {
    position: 'relative',
    width: startDayCellWidth,

    '&::after': {
      position: 'absolute',
      right:
        // here we are styling the triangle indicator in the center of border line - it STARTS rendering in the RIGHT BOTTOM CORNER
        nonStartDayCellWidth * (daysInMonth - startDay) +
        startDayCellWidth -
        halfOfMarkerBorderTriangleWidth -
        centerOfMarkerBorderLineWidth,
      bottom: 0,
      content: '""',
      display: 'block',
      borderRight: `solid ${halfOfMarkerBorderTriangleWidth}px transparent`,
      ...(startDay !== 1 && { borderLeft: `solid ${halfOfMarkerBorderTriangleWidth}px transparent` }),
      borderBottom: `solid ${markerBorderTriangleWidth}px orange`,
    },
  }),
});
