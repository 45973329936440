import { Styles } from 'styles/theme';

export const personHeaderWrapper: Styles = {
  padding: 3,
  border: (theme) => `1px solid ${theme.palette.border.default}`,
  borderRadius: 2,
};

export const personAvatar: Styles = {
  width: 88,
  height: 88,
};
