import { useState } from 'react';

import { Button, DateTimePicker, TextField, Tooltip } from 'lux/components';
import { Stack, Box } from '@mui/system';
import { CheckIcon, CloseIcon } from 'lux/icons';

import { useConfirmInvoice } from 'hooks/useConfirmInvoice/useConfirmInvoice';
import { useIssueInvoice } from 'hooks/useIssueInvoice/useIssueInvoice';
import { useSendInvoice } from 'hooks/useSendInvoice/useSendInvoice';
import { useInvoiceCorrection } from 'hooks/useInvoiceCorrection/useInvoiceCorrection';
import { usePayInvoice } from 'hooks/usePayInvoice/usePayInvoice';
import { Translation } from 'ui/translation/Translation';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser';
import { ApproveBillingCycleWarningModal } from '../additionalValuesModals/approveBillingCycleWarningModal/ApproveBillingCycleWarningModal';
import { getAssignments } from '../discrepancyHoursWarning/warningMessage/WarningMessage';
import { InvoiceStatusEnum } from 'api/types/BillingSummary.types';
import { DEFAULT_DATE_FORMAT, formatDate, isFutureDate, isPastOrPresentDate, parseDate } from 'utils/dateUtils';
import { canEditInvoice } from 'utils/canEditInvoice';
import { ButtonWithPopover } from 'ui/buttonWithPopover/ButtonWithPopover';
import { PopoverHeading } from 'ui/popoverHeading/PopoverHeading';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProject } from 'hooks/useProject/useProject';
import { isCurrentUserPmInProject } from 'utils/isCurrentUserPmInProject';

import { BillingSummaryActionsProps } from './BillingSummaryActions.types';
import { AddInvoiceFormSubmitFn } from './issueInvoiceAction/IssueInvoiceAction.types';
import { IssueInvoiceAction } from './issueInvoiceAction/IssueInvoiceAction';
import * as styles from './BillingSummaryActions.styles';

export const BillingSummaryActions = ({ invoice, billingCycle }: BillingSummaryActionsProps) => {
  const [paymentDate, setPaymentDate] = useState<Date | null>(new Date());
  const [paymentDatePopoverOpen, setPaymentDatePopoverOpen] = useState(false);
  const { isConfirmingInvoice, confirmInvoiceAsPm, isOpenWarningModal, setIsOpenWarningModal, forceConfirm } =
    useConfirmInvoice(invoice.id);
  const { isLoading: isIssuingInvoice, mutate: issueInvoice } = useIssueInvoice(invoice.id);
  const { isLoading: isSendingInvoice, mutate: sendInvoice } = useSendInvoice(invoice.id);
  const { isLoading: requestingInvoiceCorrection, mutate: requestInvoiceCorrection } = useInvoiceCorrection(invoice.id);
  const { isLoading: isMarkingInvoicePaid, mutate: markInvoicePaid } = usePayInvoice(invoice.id);
  const { billingSummaryAssignments, confirmInvoiceError, projectId } = useProjectBillingSummaryData();
  const { data: user } = useCurrentUser();
  const { data } = useProject(billingCycle.projectId);
  const { t } = useLocale();

  const isButtonDisabled = !canEditInvoice(user);

  const handleIssueInvoice: AddInvoiceFormSubmitFn = (data) => {
    issueInvoice({
      invoiceNumber: data.invoiceNumber,
      billingCycleId: billingCycle.id,
      projectId,
      // TODO: For testing purposes now, to be handled later
      source: 'FINANCE',
    });
  };

  const handleMarkInvoicePaid = async () => {
    if (paymentDate) {
      markInvoicePaid({
        paymentDate: formatDate(paymentDate),
      });
      closePaymentDatePopover();
    }
  };

  const closePaymentDatePopover = () => {
    setPaymentDatePopoverOpen(false);
  };

  const isPastOrCurrentBillingCycle = !isFutureDate(parseDate(billingCycle.startDate));

  const isPaymentDateValid = !!paymentDate && isPastOrPresentDate(paymentDate);

  const isPermittedToClickConfirmInvoice = isCurrentUserPmInProject(user, data?.assignments) || canEditInvoice(user);

  if (confirmInvoiceError.errorType && invoice.status !== InvoiceStatusEnum.to_correct) {
    return (
      <IssueInvoiceAction
        isLoading={isIssuingInvoice}
        onSubmit={handleIssueInvoice}
        invoiceNumber={invoice.number}
        requestingInvoiceCorrection={requestingInvoiceCorrection}
        requestInvoiceCorrection={requestInvoiceCorrection}
        isButtonDisabled={isButtonDisabled}
      />
    );
  }

  if (invoice.status === InvoiceStatusEnum.created) {
    return (
      <>
        <ApproveBillingCycleWarningModal
          open={isOpenWarningModal}
          forceConfirm={forceConfirm}
          assignments={getAssignments(billingSummaryAssignments)}
          onClose={() => {
            setIsOpenWarningModal(false);
          }}
        />
        {isPastOrCurrentBillingCycle && (
          <Tooltip
            placement="top"
            arrow
            title={isPermittedToClickConfirmInvoice && t('projectDetails.invoices.confirm.tooltip.message')}
          >
            <span>
              <Button
                size="small"
                loadingPosition="center"
                loading={isConfirmingInvoice}
                onClick={confirmInvoiceAsPm}
                disabled={!isPermittedToClickConfirmInvoice}
              >
                <Translation id="projectDetails.invoice.actions.confirm" />
              </Button>
            </span>
          </Tooltip>
        )}
      </>
    );
  }

  if (invoice.status === InvoiceStatusEnum.approved_by_pm) {
    return (
      <IssueInvoiceAction
        isLoading={isIssuingInvoice}
        onSubmit={handleIssueInvoice}
        invoiceNumber={invoice.number}
        requestingInvoiceCorrection={requestingInvoiceCorrection}
        requestInvoiceCorrection={requestInvoiceCorrection}
        isButtonDisabled={isButtonDisabled}
      />
    );
  }

  if (invoice.status === InvoiceStatusEnum.invoice_issued) {
    return (
      <>
        <Button size="small" loadingPosition="center" loading={isSendingInvoice} onClick={sendInvoice}>
          <Translation id="projectDetails.invoice.actions.sendInvoice" />
        </Button>
        <Button
          size="small"
          variant="outlined"
          loadingPosition="center"
          loading={requestingInvoiceCorrection}
          onClick={requestInvoiceCorrection}
          disabled={isButtonDisabled}
        >
          <Translation id="projectDetails.invoice.actions.toCorrect" />
        </Button>
      </>
    );
  }

  if (invoice.status === InvoiceStatusEnum.to_correct) {
    return (
      <>
        <ApproveBillingCycleWarningModal
          forceConfirm={forceConfirm}
          open={isOpenWarningModal}
          assignments={getAssignments(billingSummaryAssignments)}
          onClose={() => {
            setIsOpenWarningModal(false);
          }}
        />
        <Button size="small" loadingPosition="center" loading={isConfirmingInvoice} onClick={confirmInvoiceAsPm}>
          <Translation id="projectDetails.invoice.actions.confirmCorrected" />
        </Button>
      </>
    );
  }

  const renderButtons = (onSubmit: VoidFunction, onCancel: VoidFunction, submitDisabled?: boolean) => (
    <Box sx={styles.popoverActions}>
      <Button
        iconOnly
        size="small"
        onClick={onSubmit}
        disabled={submitDisabled || isMarkingInvoicePaid}
        loading={isMarkingInvoicePaid}
        loadingPosition="center"
      >
        <CheckIcon />
      </Button>

      <Button iconOnly size="small" variant="outlined" onClick={onCancel || isMarkingInvoicePaid}>
        <CloseIcon />
      </Button>
    </Box>
  );

  if (invoice.status === InvoiceStatusEnum.sent) {
    return (
      <ButtonWithPopover
        open={paymentDatePopoverOpen}
        label={t('projectDetails.invoice.actions.selectPaymentDate')}
        popoverSpacing={3}
        activeOnOpen
        ButtonProps={{ onClick: () => setPaymentDatePopoverOpen(true), sx: {} }}
        onClose={closePaymentDatePopover}
      >
        <Stack gap={2}>
          <PopoverHeading title={t('projectDetails.invoice.actions.selectPaymentDate')} />
          <Box sx={styles.datePickerRow}>
            <DateTimePicker
              inputFormat={DEFAULT_DATE_FORMAT}
              maxDate={new Date()}
              value={paymentDate}
              views={['day']}
              onChange={(date) => setPaymentDate(date)}
              renderInput={(params) => <TextField {...params} size="small" autoFocus />}
            />
            {renderButtons(handleMarkInvoicePaid, closePaymentDatePopover, !isPaymentDateValid)}
          </Box>
        </Stack>
      </ButtonWithPopover>
    );
  }

  return null;
};
