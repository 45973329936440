import { useFormContext } from 'react-hook-form';

import { checkIsInternal } from 'utils/check-internal-project';
import { ClientDetails } from '../clientDetails/ClientDetails';
import { NewProjectDetails } from '../newProjectDetails/NewProjectDetails';
import { NoRateCardRequired } from '../noRateCardRequired/NoRateCardRequired';
import { RateCard } from '../rateCard/RateCard';
import { useFormNavigation } from '../useFormNavigation/useFormNavigation';

export const FormStep = () => {
  const { activeStep } = useFormNavigation();
  const form = useFormContext();
  const clientName = form.watch('client.name');
  const isInternal = checkIsInternal(clientName);

  switch (activeStep) {
    case 1:
      return <NewProjectDetails />;
    case 2:
      return !isInternal ? <RateCard /> : <NoRateCardRequired />;
    default:
      return <ClientDetails />;
  }
};
