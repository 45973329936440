import { FormControlLabel } from '@mui/material';
import { Checkbox } from 'lux/components';
import { DuplicateIcon } from 'lux/icons';

import { Filter } from 'ui/filter/Filter';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useNeedsFilters } from 'hooks/useNeedsFilters/useNeedsFilters';
import { AppMessages } from 'i18n/messages';
import { filtersData } from 'config/data/needs/filters';

import { TypeFilterFilterProps } from './TypeFilter.types';

export const TypeFilter = ({ variant }: TypeFilterFilterProps) => {
  const { formatMessage } = useLocale();

  const {
    filters: { type },
    toggleFilter,
    clearFilter,
  } = useNeedsFilters();

  const handleFilterChange = (value: string) => {
    toggleFilter('type', value);
  };

  const clearType = () => {
    clearFilter('type');
  };

  return (
    <Filter
      variant={variant}
      icon={<DuplicateIcon />}
      dataCy="needs-filter-type"
      label={formatMessage({ id: AppMessages['filters.type.label'] })}
      valueSelected={type.length > 0}
      selectedCount={type.length}
      popoverTitle={formatMessage({ id: AppMessages['filters.type.popover.title'] })}
      popoverActionLabel={formatMessage({ id: AppMessages['filters.clear'] })}
      onPopoverActionClick={clearType}
    >
      {filtersData['type'].map(({ labelTranslation, value }) => (
        <FormControlLabel
          key={value}
          label={formatMessage({ id: AppMessages[labelTranslation] })}
          control={<Checkbox value={value} checked={type.includes(value)} onChange={() => handleFilterChange(value)} />}
        />
      ))}
    </Filter>
  );
};
