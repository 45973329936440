import { Filters } from 'hooks/useFilters/useFilters.types';

export enum NeedsSortingField {
  name = 'name',
  startDate = 'start_date',
  role = 'role',
  createdAt = 'created_at',
}

export type NeedsFilters = {
  owner: string;
  search: string;
  groupBy: string;
  sort: string;
  seniorities: string[];
  roles: string[];
  fte: string;
  availabilityFrom: string;
  availabilityTo: string;
  type: string[];
};

export type NeedsFiltersValue = {
  filters: NeedsFilters;
} & Filters<NeedsFilters>;
