import type { Styles } from 'lux/theme';

export const menuItem: Styles = {
  pr: 3,
  fontSize: 14,

  '.MuiListItemIcon-root > svg > path': {
    stroke: '#7C94B7',
  },
};

export const errorItem: Styles = {
  pr: 3,
  '&:hover': {
    backgroundColor: 'error.light',
  },

  '&, .MuiListItemIcon-root': {
    color: 'error.main',
  },

  '.MuiListItemIcon-root > svg > path': {
    fill: 'currentColor',
  },
};

export const menuPaper: Styles = {
  '&&': { borderRadius: 2 },
};

export const dialogHeader: Styles = {
  display: 'flex',
  alignItems: 'flex-start',
  pb: 3.5,
};
