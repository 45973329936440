import { useState, MouseEvent } from 'react';

import { Button } from '@mui/material';
import { MenuList, MenuItem } from 'lux/components';
import { AddIcon, AddUserIcon, SendIcon } from 'lux/icons';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useDialog } from 'hooks/useDialog/useDialog';
import { AssignWithoutNeedContainer } from 'shared/assignWithoutNeedContainer/AssignWithoutNeedContainer';
import { RequestNeed } from 'app/need/request/RequestNeed';

import * as styles from './AddButton.styles';
import { AddButtonProps } from './AddButton.types';

export const AddButton = ({ projectId }: AddButtonProps) => {
  const { formatMessage } = useLocale();
  const {
    isOpen: isRequestNeedModalOpen,
    setOpen: setRequestNeedModalOpen,
    setClose: setRequestNeedModalClose,
  } = useDialog();
  const {
    isOpen: isAssignWithoutNeedModalOpen,
    setOpen: setAssignWithoutNeedModalOpen,
    setClose: setAssignWithoutNeedModalClose,
  } = useDialog();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (callback: VoidFunction) => {
    callback();
    handleMenuClose();
  };

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        aria-controls={open ? 'add-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenuOpen}
        sx={styles.button(open)}
      >
        {formatMessage({ id: AppMessages['projectDetails.teamDetails.button.add'] })}
      </Button>
      <MenuList
        sx={styles.menu}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={'add-menu'}
        open={open}
        onClose={handleMenuClose}
        anchorEl={anchorEl}
      >
        <MenuItem icon={<SendIcon />} onClick={() => handleMenuItemClick(setRequestNeedModalOpen)}>
          {formatMessage({ id: AppMessages['projectDetails.teamDetails.button.add.menu.requestNeed'] })}
        </MenuItem>
        <MenuItem icon={<AddUserIcon />} onClick={() => handleMenuItemClick(setAssignWithoutNeedModalOpen)}>
          {formatMessage({ id: AppMessages['projectDetails.teamDetails.button.add.menu.assignWithoutNeed'] })}
        </MenuItem>
      </MenuList>
      <RequestNeed projectId={projectId} open={isRequestNeedModalOpen} onClose={setRequestNeedModalClose} />
      <AssignWithoutNeedContainer
        projectId={projectId}
        isOpen={isAssignWithoutNeedModalOpen}
        onClose={setAssignWithoutNeedModalClose}
      />
    </>
  );
};
