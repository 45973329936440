import { CircularProgress, Grid } from 'lux/components';

import { LoaderProps } from './Loader.types';
import * as styles from './Loader.styles';

export const Loader = ({ sx, fullHeight = true }: LoaderProps) => (
  <Grid
    sx={styles.container(fullHeight, sx)}
    alignItems="center"
    justifyContent="center"
    container
    gutter={0}
    spacing={0}
  >
    <Grid item xs="auto">
      <CircularProgress size="large" />
    </Grid>
  </Grid>
);
