import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { projectsKeys } from 'utils/queryKeys';
import { getLastGeneratedBillingCycle } from '../../api/actions/getLastGeneratedBillingCycle/getLastGeneratedBillingCycle';

export const useLastGeneratedBillingCycle = (projectId: string) => {
  const { isAuthenticated } = useAuth();

  return useQuery(projectsKeys.projectLastGeneratedBillingCycle(projectId), getLastGeneratedBillingCycle(projectId), {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    args: null,
  });
};
