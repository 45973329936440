import { Avatar, Typography } from 'lux/components';
import { Stack } from '@mui/material';

import { WorkStatementAvatarCellProps } from './WorkStatementAvatarCell.types';
import * as styles from './WorkStatementAvatarCell.styles';

export const WorkStatementAvatarCell = ({ fullName, picture, role }: WorkStatementAvatarCellProps) => (
  <Stack direction="row" alignItems="center" gap={1}>
    <Avatar alt={fullName} image={picture} size="sm" />
    {fullName}
    {role && <Typography sx={styles.memberRole}>{role}</Typography>}
  </Stack>
);
