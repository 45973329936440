import { CurrentUser } from 'context/auth/currentUser/CurrentUser.types';
import { Assignment } from 'api/actions/getPersonAssignments/getPersonAssignments.types';
import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';

import { isPM } from './isPM';

const statuses = [
  AssignmentStatus.active,
  AssignmentStatus.leaving,
  AssignmentStatus.preassigned,
  AssignmentStatus.temporary,
];

export const isCurrentUserPmInProject = (currentUser?: CurrentUser, assignments?: Assignment[]) => {
  const currentUserAssignment = assignments?.find(
    (assignment) =>
      assignment.person.employeeId === currentUser?.person.employeeId &&
      statuses.includes(assignment.status) &&
      isPM(assignment.role),
  );

  return !!currentUserAssignment;
};
