import { Table } from 'lux/components';
import { Box } from '@mui/material';

import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';
import { Skeleton } from 'ui/skeleton/Skeleton';

import * as styles from './InvoiceRow.styles';

export const InvoiceRowSkeleton = () => (
  <Table.Row sx={styles.row} hover>
    <Table.Cell>
      <TextSkeleton variant="body1" width={80} lineHeight={1.25} />
    </Table.Cell>
    <Table.Cell>
      <Skeleton width={99} height={24} />
      <Box sx={styles.statusInfo}>
        <TextSkeleton variant="caption" width={80} lineHeight={1.25} />
      </Box>
    </Table.Cell>
    <Table.Cell sx={styles.workstatementNumberSkeleton}>
      <TextSkeleton width={253} />
    </Table.Cell>
    <Table.Cell>
      <Box>
        <TextSkeleton width={116} variant="body1" />
        <TextSkeleton width={114} variant="body1" />
      </Box>
    </Table.Cell>
    <Table.Cell>
      <TextSkeleton width={60} variant="body1" />
    </Table.Cell>
    <Table.Cell width={80}>
      <Skeleton width={20} height={20} sx={styles.buttonSkeleton} />
    </Table.Cell>
  </Table.Row>
);
