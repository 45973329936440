type EndDateExtensionError = {
  code: string;
  message: string;
};

export type UseEndDateExtensionError = {
  error: EndDateExtensionError;
  stack: string;
};

export enum EndDateExtensionErrorEnum {
  ALREADY_PLANNED = 'error.projectAlreadyIsPlannedToBeExtended',
}
