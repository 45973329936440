import { useNeedsFilters } from 'hooks/useNeedsFilters/useNeedsFilters';
import { SeniorityFilter as SharedSeniorityFilter } from 'shared/filters/seniorityFilter/SeniorityFilter';

import { SeniorityFilterProps } from './SeniorityFilter.types';

export const SeniorityFilter = ({ variant }: SeniorityFilterProps) => {
  const {
    filters: { seniorities },
    toggleFilter,
    clearFilter,
  } = useNeedsFilters();

  const handleFilterChange = (value: string) => {
    toggleFilter('seniorities', value);
  };

  const clearSeniorities = () => {
    clearFilter('seniorities');
  };

  return (
    <SharedSeniorityFilter
      variant={variant}
      seniorities={seniorities}
      onFilterClick={handleFilterChange}
      onClearClick={clearSeniorities}
    />
  );
};
