import { Badge } from 'lux/components';
import { Accent } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';

import { AssignmentStatusBadgeProps } from './AssignmentStatusBadge.types';
import * as styles from './AssignmentStatusBadge.styles';

const getStatusColor = (status: string): keyof Accent => {
  switch (status) {
    case AssignmentStatus.active:
      return 'blue';
    case AssignmentStatus.leaving:
      return 'orange';
    case AssignmentStatus.left:
      return 'gray';
    case AssignmentStatus.temporary:
      return 'lightGray';
    case AssignmentStatus.preassigned:
      return 'blue';
    default:
      return 'lightGray';
  }
};

const statusBadgeLabel = {
  [AssignmentStatus.active]: 'assignment.status.active',
  [AssignmentStatus.leaving]: 'assignment.status.leaving',
  [AssignmentStatus.left]: 'assignment.status.left',
  [AssignmentStatus.temporary]: 'assignment.status.temporary',
  [AssignmentStatus.preassigned]: 'assignment.status.preassigned',
} as const;

export const AssignmentStatusBadge = ({ status }: AssignmentStatusBadgeProps) => {
  const { formatMessage } = useLocale();

  return (
    <Badge
      isStatic
      color={getStatusColor(status)}
      badgeContent={
        status in statusBadgeLabel
          ? formatMessage({ id: AppMessages[statusBadgeLabel[status as keyof typeof statusBadgeLabel]] })
          : status
      }
      sx={styles.badge}
    />
  );
};
