import { Box, styled } from '@mui/system';
import { Collapse } from '@mui/material';

import { CalendarCellProps, GetColorArgs, TableRowCollapsedProps } from './tableComponents.types';

export const CELL_HEIGHT = 40;
const CELL_WIDTH = 30;
const TABLE_HEAD_HEIGHT = 72;
export const FIRST_COLUMN_MIN_WIDTH = 330;
export const DEFAULT_BORDER_PROPERTIES = '0.5px solid';

const getBackgroundColor = (args: GetColorArgs) => {
  const {
    isCurrentDay,
    isSelected,
    isWeekend,
    theme,
    isOutsideProjectDates,
    isDataMismatch,
    isReport,
    showTimesheetSnapshot,
    isDetailsRow,
    isAbsent,
    isEmpty,
  } = args;

  const lightGrayOrDefault = showTimesheetSnapshot
    ? theme.palette.accent.lightGray
    : theme.palette.surface.paper.default;

  const backgroundLight = theme.palette.border.light;
  const backgroundMain = theme.palette.primary.main;
  const backgroundLightGray = theme.palette.accent.lightGray;
  const backgroundWhite = theme.palette.text.white;
  const backgroundDefault = theme.palette.surface.paper.default;

  const isMissmatchDataWorkingDay = isDataMismatch && !isReport && !isWeekend;

  // Background for expanded cells which aren't weekends
  if (isDetailsRow && !isWeekend) {
    return backgroundLightGray;
  }

  // Background for cells with mismatch error (snashot vs currentData)
  if (isMissmatchDataWorkingDay) {
    return backgroundLight;
  }

  // Background for cells with snapshot data
  if (showTimesheetSnapshot) {
    return backgroundLightGray;
  }

  // Background for cells which are not in person start/end date range
  if (isOutsideProjectDates && !isWeekend) {
    return backgroundLightGray;
  }

  // Background for cell which is focused
  if (isCurrentDay || isSelected) {
    return backgroundMain;
  }

  // Background for cells which are person days off and public holidays
  if (isAbsent && !isWeekend && !isEmpty) {
    return lightGrayOrDefault;
  }

  // Background for cells which are weekend
  if (isWeekend) {
    return backgroundDefault;
  }

  // Missing background
  return backgroundWhite;
};

const getBorderColor = (args: GetColorArgs) => {
  const { isEmpty, isWeekend, isAbsent, theme, isOutsideProjectDates, showTimesheetSnapshot } = args;

  const lightGrayOrDefault = showTimesheetSnapshot
    ? theme.palette.accent.lightGray
    : theme.palette.surface.paper.default;

  const borderLight = theme.palette.border.light;
  const borderGray = theme.palette.accent.gray;
  const borderLightGray = theme.palette.accent.lightGray;
  const borderWhite = theme.palette.text.white;

  const isOutStartDateEndDateRange = isAbsent && !isWeekend && !isEmpty;

  if (isOutsideProjectDates) {
    // Border for cells which are outside start/end date range and are empty
    if (isEmpty) {
      return borderGray;
    }

    // Border for cells which are outside start/end date range and are weekends or absents
    if (isWeekend || isAbsent) {
      return lightGrayOrDefault;
    }

    // Missing border for cells which are outside start/end date range
    return borderLight;
  }

  // Missing border for cells which are outside start/end date range
  if (isOutStartDateEndDateRange) {
    return showTimesheetSnapshot ? borderLightGray : borderLight;
  }

  // Border for cells which are absent or weekend
  if (isWeekend || isAbsent) {
    return lightGrayOrDefault;
  }

  // Border for cells with snapshot data
  if (showTimesheetSnapshot) {
    return borderLight;
  }

  // Border for empty cells
  if (isEmpty) {
    return borderWhite;
  }

  // Missing border
  return borderLight;
};

const FIRST_COLUMN_PROPERTIES = {
  position: 'sticky',
  left: 0,
  flex: 1,
  minWidth: FIRST_COLUMN_MIN_WIDTH,
};

export const TableWrapper = styled(Box)({
  overflowX: 'auto',
  overflowY: 'hidden',
  width: '100%',
  display: 'flex',

  ['@media print']: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
    overflowX: 'visible',
    overflowY: 'visible',
    transform: 'scale(0.82)',
  },
});

export const Table = styled('table')(({ theme }) => ({
  borderSpacing: 0,
  width: '100%',
  borderBottom: DEFAULT_BORDER_PROPERTIES,
  borderColor: theme.palette.border.light,

  table: {
    borderBottom: 'none',
  },
}));

export const TableHead = styled('thead')({
  '& > tr': {
    height: TABLE_HEAD_HEIGHT,
  },
});

export const TableBody = styled('tbody')({});

export const TableRow = styled('tr')({
  height: CELL_HEIGHT,
  width: '100%',
  display: 'flex',
});

export const TableCell = styled('td')<CalendarCellProps>(
  ({
    theme,
    isWeekend,
    isAbsent,
    isCurrentDay,
    isClickable,
    isSelected,
    isHeader,
    isEmpty,
    showTimesheetSnapshot,
    isOutsideProjectDates,
    isDataMismatch,
    isReport,
    isDetailsRow,
  }) => ({
    borderTop: DEFAULT_BORDER_PROPERTIES,
    borderRight: DEFAULT_BORDER_PROPERTIES,
    borderColor: isHeader
      ? theme.palette.border.light
      : getBorderColor({
          isEmpty,
          isWeekend,
          isAbsent,
          theme,
          isOutsideProjectDates,
          showTimesheetSnapshot,
          isHeader,
        }),
    // This line of code takes each last element in group of siblings having certain class
    '&:has(+ .Weekend + :not(.Weekend)) + .Weekend, &:has(+ .Absence + :not(.Absence)) + .Absence': {
      borderRightColor: showTimesheetSnapshot ? theme.palette.border.light : theme.palette.accent.lightGray,
    },
    backgroundColor: getBackgroundColor({
      isCurrentDay,
      isSelected,
      isWeekend,
      isAbsent,
      theme,
      isOutsideProjectDates,
      isDataMismatch,
      isReport,
      showTimesheetSnapshot,
      isDetailsRow,
    }),
    cursor: isClickable ? 'pointer' : 'default',
    width: CELL_WIDTH,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    padding: 0,
    zIndex: 0,
    color: isSelected ? theme.palette.text.white : undefined,
    '&:first-of-type': {
      ...FIRST_COLUMN_PROPERTIES,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: 16,
      borderLeft: DEFAULT_BORDER_PROPERTIES,
      borderColor: theme.palette.border.light,
      fontSize: 14,
      zIndex: 1,
      ['@media print']: {
        position: 'relative',
      },
    },
    '&:hover': {
      backgroundColor: isClickable ? theme.palette.accent.blue.main : undefined,
    },
    '& > *': {
      color: isCurrentDay || isSelected ? theme.palette.text.white : theme.palette.text.primary,
    },
    p: {
      fontSize: 14,
    },
    '&&&':
      !isEmpty && isOutsideProjectDates && !isWeekend
        ? {
            borderRightColor: theme.palette.border.light,
            borderTopColor: theme.palette.border.light,
          }
        : undefined,
  }),
);

const TableCellNestedWrapper = styled('td')(() => ({
  padding: 0,
}));

export const TableCellCorner = styled('td')(({ theme }) => ({
  ...FIRST_COLUMN_PROPERTIES,
  position: 'sticky',
  backgroundColor: theme.palette.text.white,
  borderRight: DEFAULT_BORDER_PROPERTIES,
  borderColor: theme.palette.border.light,
  zIndex: 1,
  ['@media print']: {
    position: 'relative',
  },
}));

export const TableRowCollapsed = ({ children, isExpanded }: TableRowCollapsedProps) => (
  <tr>
    <TableCellNestedWrapper>
      <Collapse in={isExpanded}>
        <Table>
          <TableBody>{children}</TableBody>
        </Table>
      </Collapse>
    </TableCellNestedWrapper>
  </tr>
);
