import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { UpdateInvoiceNotePayload } from './updateInvoiceNote.types';

export const updateInvoiceNote: (invoiceId: string, note: string) => MutationFn<UpdateInvoiceNotePayload> =
  (invoiceId: string) => (body) => ({
    endpoint: `/invoices/${invoiceId}/note`,
    method: 'PATCH',
    params: body,
  });
