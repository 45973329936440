import { forwardRef } from 'react';

import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import { Card } from 'ui/card/Card';

import { EmployeeCardFooter } from './footer/EmployeeCardFooter';
import { EmployeeCardHeader } from './header/EmployeeCardHeader';
import * as styles from './EmployeeCard.styles';
import type { EmployeeCardProps } from './EmployeeCard.types';

export const EmployeeCard = forwardRef<HTMLElement, EmployeeCardProps>(
  ({ employee, status, availabilityStatus, reason = '', rejectionDate = null, rejectionSource, disabled }, ref) => (
    <Card ref={ref} key={employee.employeeId} sx={styles.employeeCard}>
      <Box component={Link} to={`/people/${employee.employeeId}`} target="_blank" sx={styles.employeeCardWrapper}>
        <Card.Header>
          <EmployeeCardHeader
            employee={employee}
            status={status}
            availabilityStatus={availabilityStatus}
            rejectionDate={rejectionDate}
            rejectionSource={rejectionSource}
            disabled={disabled}
          />
        </Card.Header>
        <Card.Footer>
          <EmployeeCardFooter reason={reason} skills={employee.skills} />
        </Card.Footer>
      </Box>
    </Card>
  ),
);
