import { Styles } from 'styles/theme';
import needCardBgImage from 'assets/images/need-card-bg-image.svg';

export const needsList: Styles = {
  '> .MuiBox-root:not(:last-of-type)': {
    marginBottom: 2,
  },
};

export const needCard: Styles = {
  backgroundColor: 'white',
  backgroundImage: `url(${needCardBgImage})`,
};

export const needCardBorder: Styles = {
  backgroundSize: 'calc(100% - 0.5px) calc(100% - 0.5px)',
  backgroundRepeat: 'no-repeat',
};
