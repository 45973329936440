import { Badge } from 'lux/components';
import { Accent } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { PersonAvailabilityStatus } from 'api/types/PersonAvailabilityStatus.enum';
import { fteToHumanValue } from 'app/need/utils';

import { PersonStatusBadgeProps } from './PersonStatusBadge.types';
import * as styles from './PersonStatusBadge.styles';

const getStatusColor = (status: string, fte: number): keyof Accent => {
  switch (status) {
    case PersonAvailabilityStatus.available:
      if (fte === 40) return 'green';
      return 'yellow';
    case PersonAvailabilityStatus.availableSoon:
      return 'orange';
    case PersonAvailabilityStatus.assigned:
    case PersonAvailabilityStatus.preassigned:
      return 'blue';
    case PersonAvailabilityStatus.inactive:
      return 'gray';
    default:
      return 'lightGray';
  }
};

const statusBadgeLabel = {
  [PersonAvailabilityStatus.available]: 'people.availabilityStatus.available',
  [PersonAvailabilityStatus.availableSoon]: 'people.availabilityStatus.availableSoon',
  [PersonAvailabilityStatus.assigned]: 'people.availabilityStatus.assigned',
  [PersonAvailabilityStatus.inactive]: 'people.availabilityStatus.inactive',
  [PersonAvailabilityStatus.preassigned]: 'people.availabilityStatus.preassigned',
} as const;

export const PersonStatusBadge = ({ status, availableFte }: PersonStatusBadgeProps) => {
  const { formatMessage } = useLocale();

  const fte = parseInt(availableFte);

  const formattedStatus =
    status in statusBadgeLabel
      ? formatMessage({ id: AppMessages[statusBadgeLabel[status as keyof typeof statusBadgeLabel]] })
      : status;
  const formattedFte =
    status === PersonAvailabilityStatus.available
      ? formatMessage({ id: AppMessages[`people.availabilityStatus.${fteToHumanValue(fte)}`] }, { fte })
      : '';

  return (
    <Badge
      isStatic
      color={getStatusColor(status, fte)}
      badgeContent={`${formattedStatus} ${formattedFte}`}
      sx={styles.badge}
    />
  );
};
