import { forwardRef } from 'react';

import { TableVirtuoso } from 'react-virtuoso';
import { Table } from 'lux/components';

import { PersonBasicInfoSkeleton } from 'shared/people/personBasicInfo/PersonBasicInfo.skeleton';
import { Skeleton } from 'ui/skeleton/Skeleton';

import { PeopleTableHead } from './peopleTable/peopleTableHead/PeopleTableHead';
import * as styles from './peopleTable/PeopleTable.styles';

export const PeopleListSkeleton = () => (
  <TableVirtuoso
    data={Array(15).fill(null)}
    fixedHeaderContent={PeopleTableHead}
    defaultItemHeight={53}
    increaseViewportBy={150}
    components={{
      FillerRow: (props) => (
        <Table.Row component="div">
          <Table.Cell component="div" style={{ height: props.height }} {...props} />
        </Table.Row>
      ),
      TableBody: forwardRef((props, ref) => <Table.Body {...props} component="div" ref={ref} />),
      TableHead: forwardRef((props, ref) => <Table.Head component="div" {...props} ref={ref} />),
      Table: (props) => <Table.Table sx={styles.table} size="small" component="div" {...props} />,
      TableRow: ({ item, ...props }) => (
        <Table.Row {...props} sx={styles.tableRow(false, false)} key={item} component="div" />
      ),
    }}
    itemContent={() => (
      <>
        <Table.Cell component="div">
          <PersonBasicInfoSkeleton />
        </Table.Cell>
        <Table.Cell component="div">
          <Skeleton width={52} height={15} />
        </Table.Cell>
        <Table.Cell component="div">
          <Skeleton width={38} height={15} />
        </Table.Cell>
        <Table.Cell component="div">
          <Skeleton width={56} height={15} />
        </Table.Cell>
        <Table.Cell component="div">
          <Skeleton width={56} height={15} />
        </Table.Cell>
        <Table.Cell component="div">
          <Skeleton width={116} height={24} />
        </Table.Cell>
        <Table.Cell padding="none" component="div" />
      </>
    )}
  />
);
