import { Box, Stack } from '@mui/material';
import { Grid } from 'lux/components';

import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';
import { Skeleton } from 'ui/skeleton/Skeleton';

import * as styles from './ProjectCard.styles';

export const ProjectCardSkeleton = () => (
  <Box sx={styles.projectCardWrapper}>
    <Stack position="relative" width="100%" justifyContent="space-between">
      <Box sx={styles.topBar}>
        <TextSkeleton width={180} variant="h6" />
        <Skeleton width={48} height={24} />
      </Box>

      <Grid
        className="hoverInvisible"
        container
        alignItems="center"
        spacing={0}
        columnSpacing={5}
        justifyContent="space-between"
        gutter={0}
      >
        <Grid item xs sx={styles.dateWrapper}>
          <Skeleton width={24} height={24} />
          <TextSkeleton width={72} variant="body2" />
        </Grid>

        <Grid item>
          <Skeleton width={80} height={24} />
        </Grid>
      </Grid>

      <Stack direction="row" flexWrap="wrap" gap={0.5} marginTop={1} className="hoverInvisible">
        <Skeleton width={40} height={24} />
        <Skeleton width={40} height={24} />
        <Skeleton width={40} height={24} />
      </Stack>
    </Stack>
  </Box>
);
