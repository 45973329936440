import { Dialog } from '@mui/material';
import { Typography } from 'lux/components';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useRejectPerson } from 'hooks/useRejectPerson/useRejectPerson';
import { AppMessages } from 'i18n/messages';
import { needsKeys, projectsKeys, proposalsKeys, rejectionsKeys } from 'utils/queryKeys';
import { useNeedModal } from 'hooks/useNeedModal/useNeedModal';
import { useUnsavedChangesDialogContext } from 'hooks/useUnsavedChangesDialogContext/useUnsavedChangesDialogContext';
import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';
import { useIntlForm } from '../../../../../../../hooks/useIntlForm/useIntlForm';
import { FormTextField } from '../../../../../../../ui/form/formTextEdit/FormTextField';

import type { ConfirmRejectModalProps } from './ConfirmRejectModal.types';
import * as styles from './ConfirmRejectModal.styles';
import { confirmRejectModalSchema } from './ConfirmRejectModal.schema';

export type ConfirmRejectModalForm = { reason: string };

const defaultValues: ConfirmRejectModalForm = {
  reason: '',
};

export const ConfirmRejectModal = ({ open }: ConfirmRejectModalProps) => {
  const { projectDetails, needData, selectedProposal, closeConfirmReject } = useNeedModal();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();
  const { DialogClickAwayListenerWrapper } = useUnsavedChangesDialogContext();

  const form = useIntlForm<ConfirmRejectModalForm>({
    defaultValues,
    mode: 'onSubmit',
    resolver: joiResolver(confirmRejectModalSchema()),
  });
  useDialogBlockScroll(open);

  const { mutate: rejectPerson, isLoading: isRejectPersonLoading } = useRejectPerson();

  if (!projectDetails || !needData || !selectedProposal) {
    return null;
  }

  const employee = selectedProposal.person;
  const handleReject = (payload: ConfirmRejectModalForm) => {
    const proposalId = selectedProposal.id;
    const projectId = projectDetails.id;
    const needId = needData.id;

    rejectPerson(
      {
        reason: payload.reason.trim(),
        projectId,
        proposalId,
        needId,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(
            formatMessage(
              { id: AppMessages['snackbar.rejectPerson.success'] },
              {
                personName: `${employee.firstName} ${employee.lastName}`,
              },
            ),
          );
          queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
          queryClient.invalidateQueries(projectsKeys.singleProjectNeeds(projectId));
          queryClient.invalidateQueries(needsKeys.singleNeed(needId));
          queryClient.invalidateQueries(needsKeys.needsList);
          queryClient.invalidateQueries(rejectionsKeys.rejectionsList);
          queryClient.invalidateQueries(proposalsKeys.proposalsList(needId));
          closeConfirmReject();
        },
        onError: () => {
          enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.rejectPerson.error'] }), {
            variant: 'error',
          });
        },
      },
    );
  };

  return (
    <Dialog open={open} onClose={closeConfirmReject} fullWidth maxWidth="sm" sx={styles.customDialog}>
      <form onSubmit={form.handleSubmit(handleReject)}>
        <DialogClickAwayListenerWrapper defaultValues={defaultValues} form={form} onClose={closeConfirmReject}>
          <DialogInnerWrapper
            title={formatMessage(
              { id: AppMessages['card.need.proposed.rejectPersonModal.title'] },
              {
                personName: `${employee.firstName} ${employee.lastName}`,
              },
            )}
            loading={isRejectPersonLoading}
            onClose={closeConfirmReject}
            submitText={formatMessage({ id: AppMessages['button.reject'] })}
          >
            <FormProvider {...form}>
              <Typography variant="body1" sx={styles.dialogDescription}>
                {formatMessage({ id: AppMessages['card.need.proposed.rejectPersonModal.description'] })}
              </Typography>
              <FormTextField
                name="reason"
                control={form.control}
                label={formatMessage({ id: AppMessages['card.need.proposed.rejectPersonModal.reason'] })}
                multiline
                fullWidth
              />
            </FormProvider>
          </DialogInnerWrapper>
        </DialogClickAwayListenerWrapper>
      </form>
    </Dialog>
  );
};
