import { useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useProjects } from 'hooks/useProjects/useProjects';
import { UniqueValueField } from '../uniqueValueField/UniqueValueField';

export const CodenameField = ({ dataCy }: { dataCy?: string }) => {
  const form = useFormContext();
  const { formatMessage } = useLocale();
  const codename = form.watch('project.codename').toLowerCase().trim();
  const { refetch, isInitialLoading: isLoading } = useProjects(
    { codename: codename.trim(), size: 2 },
    { enabled: false },
  );

  const checkCodename = useCallback(async () => {
    if (!codename) return;

    const response = await refetch();
    const data = response.data?.pages[0].projects.map((project) => project.codename);

    if (data?.some((c) => c?.toLowerCase() === codename)) {
      form.setError('project.codename', {
        message: formatMessage({ id: AppMessages['createProject.schema.project.codename.error.exists'] }),
      });
    } else if (codename.length <= 15) {
      form.clearErrors('project.codename');
    }
  }, [codename]);

  return (
    <UniqueValueField
      fieldName="project.codename"
      label={formatMessage({ id: AppMessages['createProject.project.form.codename'] })}
      checkUniqueValue={checkCodename}
      isLoading={isLoading}
      isTypingEnabled
      data-cy={dataCy}
    />
  );
};
