import { SortOrder, SortFilter } from './filters.types';

export const parseSortFilter = (filter: string) => {
  const [field, order] = filter.split('#');

  return { field, order };
};

export const generateSortFilter = <T extends Record<string, string>>(fields: T) => {
  const entries = Object.entries(fields);
  return entries.reduce<SortFilter<T>>(
    (acc, [currKey, currVal]) => ({
      ...acc,
      ...Object.values(SortOrder).reduce(
        (accOrder, sortOrder) => ({ ...accOrder, [`${currKey}${sortOrder}`]: `${currVal}#${sortOrder}` }),
        {} as SortFilter<T>,
      ),
    }),
    {} as SortFilter<T>,
  );
};

export const stringifySortFilter = <T extends string>(field: T, order: SortOrder) => `${field}#${order}` as const;
