import { editDiscountMutation } from 'api/actions/editDiscount/editDiscountActions';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { AppMessages } from 'i18n/messages';

export const useEditDiscount = (workStatementId: string, discountId: string) => {
  const { invalidateBillingSummaryCache } = useProjectBillingSummaryData();
  const { formatMessage } = useLocale();

  return useMutationDomainError(['editDiscount'], editDiscountMutation(workStatementId, discountId), {
    onSuccess: () => {
      invalidateBillingSummaryCache();
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.editDiscount.error'] }),
  });
};
