import { NavLink } from 'react-router-dom';
import { Button, Typography } from 'lux/components';

import * as styles from './SidebarButton.styles';
import { SidebarButtonProps } from './SidebarButton.types';

export const SidebarButton = ({ to, Icon, iconOnly = false, label }: SidebarButtonProps) => (
  <Button variant="text" size="small" color="primary" sx={styles.link} to={to} component={NavLink} iconOnly end>
    <Icon />
    <Typography variant="body2" sx={styles.label(iconOnly)}>
      {label}
    </Typography>
  </Button>
);
