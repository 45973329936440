import { RoleHeader } from './roleHeader/RoleHeader';
import { RoleAssignedPerson } from './roleAssignedPerson/RoleAssignedPerson';
import { RoleSectionProps } from './RoleSection.types';

export const RoleSection = ({ role, projectId, keyRoles }: RoleSectionProps) => (
  <>
    <RoleHeader
      id={role.id}
      projectId={projectId}
      role={role.role}
      isSinglePersonAssigned={role.people.length !== 0}
      keyRoles={keyRoles}
    />
    {role.people.map((person) => (
      <RoleAssignedPerson
        key={person.employeeId}
        employeeId={person.employeeId}
        sectionId={role.id}
        firstName={person.firstName}
        lastName={person.lastName}
        picture={person.picture}
        projectId={projectId}
        keyRoles={keyRoles}
      />
    ))}
  </>
);
