import { SxProps } from '@mui/system';

import { Styles } from 'styles/theme';

export const timesheetTitle: Styles = {
  display: 'block',
  color: 'text.secondary',
  fontWeight: 500,
  mt: 3,
};

export const navigationBar: Styles = {
  my: 2,
};

export const titleContainer: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const infoAlert: Styles = {
  margin: '2rem 0',
  alignItems: 'center',

  '& .MuiAlert-message ': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '& .MuiTypography-root': {
    mb: 0,
  },
};

export const moneyEmoji: Styles = {
  alignItems: 'center',
  fontSize: '1rem',

  '&.MuiPaper-root::before': {
    backgroundColor: 'unset',
  },

  '> .MuiAlert-icon': {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '1rem',
    aspectRatio: 1,
    backgroundColor: 'white',
    borderRadius: '50%',
    padding: 2.5,
  },
};

export const clientTimesheetsWrapper: SxProps = {
  border: 'none',
  paddingY: 1,
};
