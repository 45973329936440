import { NeededRole, NeededRoleSeniority } from 'app/project/projectOverview/rateCardDetails/RateCardDetails.types';
import { Seniority } from 'api/types/Seniority.enum';

export const getMissingSeniorities = (
  neededRoles: NeededRole[] | undefined,
  rates: [string, string, string],
  roleName: string,
): NeededRoleSeniority[] => {
  if (!roleName || !neededRoles) {
    return [];
  }

  const needsForCurrentRole = neededRoles.find((neededRole) => neededRole.role === roleName);
  if (!needsForCurrentRole) {
    return [];
  }

  const missingSeniorities: NeededRoleSeniority[] = [];
  const definedSeniorities = [
    rates[0] ? Seniority.junior : null,
    rates[1] ? Seniority.mid : null,
    rates[2] ? Seniority.senior : null,
  ];

  for (const neededSeniority of needsForCurrentRole.seniorities) {
    if (!definedSeniorities.includes(neededSeniority.value) && neededSeniority.value !== Seniority.withoutLevel) {
      missingSeniorities.push(neededSeniority);
    }
  }

  return missingSeniorities;
};
