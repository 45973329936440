import { MappedRateCard } from 'api/actions/getProject/getProject.types';
import { SkillSetDto } from 'api/types/Need.types';
import { SkillWeight } from '../Need.enums';
import { Seniority } from 'api/types/Seniority.enum';

const skillLimit = 3;

export const fteToHumanValue = (fte: number) => {
  switch (fte) {
    case 40:
      return 'fte.fullTime';
    case 20:
      return 'fte.halfTime';
    default:
      return `fte.other`;
  }
};

export const findRateByRoleAndSeniority = (rateCard: MappedRateCard, role: string, seniority?: string) => {
  let rateAmount: number | undefined;

  Object.entries(rateCard).forEach(([key, rateItems]) => {
    if (key === role) {
      let foundRate = rateItems.find(
        (rateItem) => rateItem.seniority === seniority || rateItem.seniority === Seniority.withoutLevel,
      );

      if (!foundRate && seniority === Seniority.withoutLevel) {
        foundRate = rateItems.reduce((highestRate, currentRate) =>
          currentRate.amount > highestRate.amount ? currentRate : highestRate,
        );
      }

      if (foundRate) {
        rateAmount = foundRate.amount;
      }
    }
  });

  return rateAmount;
};

export const chooseMainSkillsByWeight = (skills: SkillSetDto) => {
  if (!skills.length) return [];

  return skills.filter((skill) => skill.weight === SkillWeight.mustHave).slice(0, skillLimit);
};
