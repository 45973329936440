import { useRef, useId } from 'react';

import { ButtonBase } from '@mui/material';
import { Typography } from 'lux/components';

import { getTextColor } from 'config/data/dashboard/availability';
import { useAvailabilityPeople } from '../../../useAvailabilityPeople/useAvailabilityPeople';

import * as styles from './SingleValue.styles';
import { SingleValueProps } from './SingleValue.types';

export const SingleValue = ({ value, totalInDepartment, people }: SingleValueProps) => {
  const ref = useRef<HTMLButtonElement | null>(null);
  const { setSelectedPeople, setAnchor, setActiveCell, activeCellId } = useAvailabilityPeople();
  const cellId = useId();

  return (
    <Typography
      component={ButtonBase}
      onClick={(event) => {
        setSelectedPeople(people);
        setAnchor(ref.current);
        setActiveCell(cellId);
        event.stopPropagation();
      }}
      ref={ref}
      sx={styles.button}
      variant="body1"
      id={cellId}
      aria-haspopup="true"
      aria-expanded={cellId === activeCellId}
      aria-controls="availability-people-list"
      fontWeight={500}
      disabled={value === 0}
      disableRipple
      width="100%"
      height="100%"
      color={getTextColor(value, totalInDepartment)}
    >
      {value}
    </Typography>
  );
};
