import type { MutationFn } from 'hooks/useMutation/useMutation.types';

import type { AssignPersonArguments, AssignPersonError, AssignPersonResponse } from './assignPersonActions.types';

export const assignPersonMutation: (
  projectId: string,
) => MutationFn<AssignPersonArguments, AssignPersonResponse, AssignPersonError> = (projectId: string) => (body) => ({
  endpoint: `/projects/${projectId}/assign-person`,
  method: 'POST',
  params: body,
});
