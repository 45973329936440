import { Styles } from 'styles/theme';

export const table: Styles = {
  minWidth: 760,
  tableLayout: 'fixed',
  borderCollapse: 'separate',
};

export const tableHeadCell: Styles = {
  typography: 'caption',
  fontWeight: 600,
  color: 'text.secondary',
};

export const tableRow = (isClickable: boolean): Styles => ({
  height: 53,
  textDecoration: 'none',

  ...(isClickable && {
    cursor: 'pointer',
  }),

  '& .hoverVisible': {
    visibility: 'hidden',
  },

  '&:hover .hoverVisible': {
    visibility: 'visible',
  },
});

export const clientProjectLength: Styles = {
  textAlign: 'center',
};

export const clientLink: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  color: 'text.primary',
  textDecoration: 'none',

  '&>svg': {
    display: 'none',
  },

  '&:hover': {
    color: 'primary.main',
    textDecoration: 'underline',

    '&>svg': {
      display: 'block',
    },
  },
};

export const tableWrapper: Styles = {
  '.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall': {
    paddingLeft: 1,
  },
};
