import { Stack } from '@mui/material';
import { Typography } from 'lux/components';

import { ReactComponent as TshLogo } from 'assets/images/tsh-logo.svg';
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useReport } from 'hooks/useReport/useReport';
import { formatDate, getDateYear, getMonthName, parseDate, parseISODate } from 'utils/dateUtils';
import { Translation } from 'ui/translation/Translation';

import * as styles from './TshBanner.styles';
import { TshBannerProps } from './TshBanner.types';

export const TshBanner = ({ workStatementNumber, workStatementStartDate }: TshBannerProps) => {
  const { projectDetails } = useProjectDetails();
  const { data: user } = useCurrentUser();
  const { reportDate } = useReport();

  const fullCurrentDate = formatDate(new Date());

  const currentDate = Date.now();

  const parsedWsDate = workStatementStartDate ? parseDate(workStatementStartDate) : currentDate;
  const parsedBcDate = reportDate ? parseISODate(reportDate) : currentDate;

  const wsMonth = getMonthName(parsedWsDate);
  const wsYear = getDateYear(parsedWsDate);
  const bcMonth = getMonthName(parsedBcDate);
  const bcYear = getDateYear(parsedBcDate);

  return (
    <Stack sx={styles.bannerContainer} direction="row" justifyContent="space-between">
      <Stack direction="column" gap={2}>
        <TshLogo />
        <Typography fontSize={20}>
          <Translation
            id="projectDetails.billingSummary.workStatementDetails.banner.title"
            values={{ projectName: projectDetails.name }}
          />
          <Translation
            id="projectDetails.billingSummary.workStatementDetails.banner.date"
            values={{ month: bcMonth, year: bcYear }}
          />
        </Typography>
        <Stack direction="row">
          <Typography fontSize={10} color="text.secondary">
            <Translation
              id="projectDetails.billingSummary.workStatementDetails.banner.currentBillingCycle"
              values={{ month: wsMonth, year: wsYear, workStatementNumber: workStatementNumber || '' }}
            />
          </Typography>
        </Stack>
      </Stack>
      <Typography fontSize={10} color="text.secondary">
        <Translation
          id="projectDetails.billingSummary.workStatementDetails.banner.info"
          values={{ fullCurrentDate, firstName: user?.person.firstName, secondName: user?.person.lastName }}
        />
      </Typography>
    </Stack>
  );
};
