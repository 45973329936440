import Joi from 'joi';

import { intl } from 'context/locale/intl/Intl';
import { Translation } from 'i18n/messages';

const { formatMessage } = intl.currentIntl;

export const getAdditionalValueContentSchema = ({
  titleLabel,
  amountLabel,
}: {
  titleLabel: Translation;
  amountLabel: Translation;
}) =>
  Joi.object().keys({
    label: Joi.string()
      .required()
      .label(formatMessage({ id: titleLabel })),
    amount: Joi.number()
      .positive()
      .required()
      .label(formatMessage({ id: amountLabel })),
  });
