import { Stack } from '@mui/material';
import { Typography, Button, Table } from 'lux/components';

import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';
import { Skeleton } from 'ui/skeleton/Skeleton';

import * as styles from './HeadRow.styles';

export const HeadRowSkeleton = () => (
  <Table.Row sx={styles.row}>
    <Table.Cell sx={styles.headRowCell}></Table.Cell>
    <Table.Cell padding="none" sx={styles.headRowCell}>
      <Stack gap={0.5}>
        <TextSkeleton width={92} variant="caption" />
        <Skeleton width={43} height={24} />
      </Stack>
    </Table.Cell>

    <Table.Cell padding="none" sx={styles.headRowCell}>
      <Button size="small" sx={styles.personLink} variant="text">
        <Skeleton circular width={48} height={48} />

        <Typography sx={styles.personDetails} variant="caption">
          <TextSkeleton width={128} variant="caption" />
          <Stack direction="row" spacing={1}>
            <Skeleton width={112} height={24} />
          </Stack>
        </Typography>

        <TextSkeleton width={76} variant="body2" />
      </Button>
    </Table.Cell>
    <Table.Cell width={48} padding="none" sx={styles.headRowCell}></Table.Cell>
  </Table.Row>
);
