import { useState } from 'react';

import { Box } from '@mui/material';
import { Tabs } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { DashboardCard } from 'ui/dashboardCard/DashboardCard';
import { AppMessages } from 'i18n/messages';

import { PersonAssignments } from './personAssignments/PersonAssignments';
import { PersonProposals } from './personProposals/PersonProposals';
import * as styles from './PersonProjects.styles';
import { PersonProjectsProps } from './PersonProjects.types';

export const PersonProjects = ({
  assignments,
  isLoadingAssignments,
  proposals,
  isLoadingProposals,
}: PersonProjectsProps) => {
  const { formatMessage } = useLocale();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const handleTabIndex = (e: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  return (
    <DashboardCard title={formatMessage({ id: AppMessages['personDetails.projects.title'] })}>
      <Tabs>
        <Tabs.TabList value={activeTabIndex} onChange={handleTabIndex} variant="fullWidth">
          <Tabs.Tab
            label={formatMessage({ id: AppMessages['personDetails.projects.assigned'] }, { count: assignments.length })}
          />
          <Tabs.Tab
            label={formatMessage({ id: AppMessages['personDetails.projects.proposed'] }, { count: proposals.length })}
          />
        </Tabs.TabList>
        <Box sx={styles.tabPanelWrapper}>
          <Tabs.TabPanel value={activeTabIndex} index={0}>
            <PersonAssignments assignments={assignments} isLoading={isLoadingAssignments} />
          </Tabs.TabPanel>
          <Tabs.TabPanel value={activeTabIndex} index={1}>
            <PersonProposals proposals={proposals} isLoading={isLoadingProposals} />
          </Tabs.TabPanel>
        </Box>
      </Tabs>
    </DashboardCard>
  );
};
