import { useState } from 'react';

type UseDialogConfig = {
  initialState?: boolean;
};

export const useDialog = (config: UseDialogConfig = { initialState: false }) => {
  const [isOpen, setIsOpen] = useState<boolean>(config.initialState || false);

  const toggle = () => setIsOpen((prev) => !prev);
  const setOpen = () => setIsOpen(true);
  const setClose = () => setIsOpen(false);

  return {
    isOpen,
    toggle,
    setOpen,
    setClose,
  };
};
