import Joi from 'joi';

import { AppMessages } from 'i18n/messages';
import { Seniority } from 'api/types/Seniority.enum';
import { intl } from 'context/locale/intl/Intl';
import { Fte } from 'config/data/fteList';
import { rateCardSchema } from 'shared/project/schema/rateCard.schema';
import { MAX_SUPPORTED_DATE, ZERO_UNIX_DATE, formatDate, parseDate } from 'utils/dateUtils';

export const editAssignmentSchema = (projectStartDate?: string) => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object({
    role: Joi.string().required(),
    seniorityEnabled: Joi.boolean().required(),
    seniority: Joi.string()
      .valid(...Object.values(Seniority))
      .required(),
    fte: Joi.alternatives()
      .try(Joi.number(), Joi.string())
      .required()
      .messages({
        'any.required': formatMessage(
          { id: AppMessages['validation.empty'] },
          {
            label: formatMessage({ id: AppMessages['assignment.editAssignment.form.fte'] }),
          },
        ),
      }),
    startDate: Joi.date()
      .required()
      .min(projectStartDate ? parseDate(projectStartDate) : ZERO_UNIX_DATE)
      .max(MAX_SUPPORTED_DATE)
      .messages({
        'date.base': formatMessage(
          { id: AppMessages['validation.date'] },
          { label: formatMessage({ id: AppMessages['assignment.editAssignment.form.startDate'] }) },
        ),
        'date.min': formatMessage(
          {
            id: AppMessages['validation.greater'],
          },
          {
            label: formatMessage({ id: AppMessages['assignment.editAssignment.form.startDate'] }),
            limit: projectStartDate || formatDate(ZERO_UNIX_DATE),
          },
        ),
        'date.max': formatMessage(
          {
            id: AppMessages['validation.max'],
          },
          {
            label: formatMessage({ id: AppMessages['assignment.editAssignment.form.startDate'] }),
            limit: formatDate(MAX_SUPPORTED_DATE),
          },
        ),
      }),
    endDate: Joi.date()
      .min(Joi.ref('startDate'))
      .allow(null)
      .optional()
      .messages({
        'date.base': formatMessage(
          { id: AppMessages['validation.date'] },
          { label: formatMessage({ id: AppMessages['assignment.editAssignment.form.endDate'] }) },
        ),
        'date.min': formatMessage(
          {
            id: AppMessages['validation.min'],
          },
          {
            label: formatMessage({ id: AppMessages['assignment.editAssignment.form.endDate'] }),
            limit: `"${formatMessage({ id: AppMessages['assignment.editAssignment.form.startDate'] })}"`,
          },
        ),
      }),
    customFte: Joi.when('fte', {
      is: Fte.other,
      then: Joi.number().min(1).max(40).required(),
      otherwise: Joi.number().allow('').optional().strip(),
    }).messages({
      'number.base': formatMessage(
        {
          id: AppMessages['validation.number'],
        },
        {
          label: formatMessage({ id: AppMessages['assignment.editAssignment.form.customFte'] }),
        },
      ),
      'number.min': formatMessage(
        {
          id: AppMessages['validation.min'],
        },
        {
          label: formatMessage({ id: AppMessages['assignment.editAssignment.form.customFte'] }),
          limit: 1,
        },
      ),
      'number.max': formatMessage(
        {
          id: AppMessages['validation.max'],
        },
        {
          label: formatMessage({ id: AppMessages['assignment.editAssignment.form.customFte'] }),
          limit: 40,
        },
      ),
    }),
    customRateEnabled: Joi.boolean(),
    customRate: Joi.when('customRateEnabled', {
      is: true,
      then: Joi.number().required().min(0),
      otherwise: Joi.number().optional(),
    }).messages({
      'number.base': formatMessage(
        { id: AppMessages['validation.number'] },
        { label: formatMessage({ id: AppMessages['assignment.editAssignment.form.rate.custom'] }) },
      ),
      'number.min': formatMessage(
        { id: AppMessages['validation.min'] },
        {
          label: formatMessage({ id: AppMessages['assignment.editAssignment.form.rate.custom'] }),
          limit: 0,
        },
      ),
    }),
    isTemporary: Joi.boolean(),
    notes: Joi.string().empty(''),
  });
};

const editableRateCardSchema = () =>
  rateCardSchema().append({
    isDraft: Joi.boolean().optional().strip(),
  });

export const correctAssignmentRateCardSchema = () =>
  Joi.object({
    rateCards: Joi.array().min(1).items(editableRateCardSchema()),
  });
