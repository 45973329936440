import { Styles } from 'styles/theme';

export const avatarGroup = (size: 'xs' | 'sm'): Styles => {
  const avatarSize = {
    xs: 24,
    sm: 32,
  }[size];

  return {
    span: {
      marginLeft: `calc(-${avatarSize}px / 2)`,

      '&:first-child': {
        marginLeft: 0,
      },
    },
  };
};

export const label: Styles = {
  '@media (max-width: 1100px)': {
    display: 'none',
  },
};
