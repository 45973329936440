import type { MutationFn } from 'hooks/useMutation/useMutation.types';

import type { UpdateAssignmentsBody } from './updateAssignmentsActions.types';

export const updateAssignmentsMutation: (projectId: string) => MutationFn<UpdateAssignmentsBody> =
  (projectId) => (body) => ({
    endpoint: `/projects/${projectId}/assignments`,
    method: 'PATCH',
    params: body,
  });
