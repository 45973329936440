import { Table } from 'lux/components';
import { Collapse } from '@mui/material';

import * as styles from './CollapseRow.styles';
import { CollapseRowProps } from './CollapseRow.types';

export const CollapseRow = ({ children, isExpanded, colSpan = 4 }: CollapseRowProps) => (
  <Table.Row>
    <Table.Cell colSpan={colSpan} sx={styles.cell}>
      <Collapse in={isExpanded}>
        <Table.Table>
          <Table.Body>{children}</Table.Body>
        </Table.Table>
      </Collapse>
    </Table.Cell>
  </Table.Row>
);
