import { Styles } from 'styles/theme';
import { createContainerQueries } from '../../styles/container';

import { BannerVariant } from './Banner.types';

const containerName = 'banner';
const { containerUp } = createContainerQueries(containerName);

export const bannerContainer: Styles = {
  height: '100%',
  width: '100%',
  minWidth: 312,
  containerType: 'inline-size',
  containerName,
};

export const banner = (variant: BannerVariant): Styles => ({
  width: '100%',
  height: '100%',
  backgroundColor: `${variant}.light`,
  padding: 2,
  borderRadius: 4,
  boxShadow: 'none',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',

  [containerUp(1000)]: {
    flexDirection: 'row',
  },
});

export const contentWrapper: Styles = {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: 3,
  width: '100%',
};

export const content: Styles = {
  alignSelf: 'center',
};

export const icon: Styles = {
  fontSize: 32,
  height: 56,
  width: 56,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'text.white',
  borderRadius: '100%',
};

export const heading: Styles = {
  fontWeight: 500,
};

export const description: Styles = {
  color: 'text.secondary',
};

export const actions: Styles = {
  marginLeft: 'auto',
  marginTop: 3,

  '.MuiButtonBase-root': {
    textWrap: 'nowrap',
  },

  [containerUp(1000)]: {
    marginLeft: 4,
    marginTop: 0,
  },
};
