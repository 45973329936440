import { useState } from 'react';

import { Box } from '@mui/material';
import { InlineEdit, Select, TextField } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { Currency } from 'api/types/Currency.enum';
import { mapToSelectOptions } from 'ui/form/select/Select.mappers';
import { convertRateToInteger } from 'utils/convertRate';
import { useModifyPersonRate } from 'hooks/useModifyPersonRate/useModifyPersonRate';

import type { AddPersonRateInputProps } from './AddPersonRateInput.types';

export const AddPersonRateInput = (props: AddPersonRateInputProps) => {
  const { personId, isPersonPlaceholder, isEditMode, setIsEditMode } = props;
  const { t } = useLocale();
  const [formCurrency, setFormCurrency] = useState<Currency>(Currency.pln);
  const [formCost, setFormCost] = useState('');
  const [isError, setIsError] = useState(false);

  const { mutateAsync: modifyRate } = useModifyPersonRate(personId);

  const handleRateChange = (value: string) => {
    const newCost = parseFloat(value);
    const costError = isNaN(newCost);

    setIsError(costError);

    if (costError) {
      setFormCost('');
    } else {
      setFormCost((newCost < 0 ? 0 : newCost).toString());
    }
  };

  const handleSubmit = async () => {
    if (!formCost || isError) {
      return;
    }

    await modifyRate({
      rate: { rate: convertRateToInteger(formCost), currency: formCurrency },
    });

    setIsEditMode(false);
  };

  return (
    <InlineEdit
      header={t('personDetails.accordion.details.personCost')}
      disabled={isPersonPlaceholder}
      submitDisabled={!formCost || isError}
      input={
        <Box display="flex" flexDirection="column">
          <Box display="flex" gap={0.5} flexDirection="row">
            <Select
              fullWidth
              options={mapToSelectOptions(Object.values(Currency))}
              label={t('projectDetails.accordions.details.currency')}
              sx={{ flex: 2 }}
              size="small"
              value={formCurrency}
              onChange={(event) => setFormCurrency(event.target.value as Currency)}
            />
            <TextField
              label={t('personDetails.accordion.details.personCost')}
              fullWidth
              size="small"
              type="number"
              value={formCost}
              onChange={(event) => handleRateChange(event.target.value)}
              sx={{ flex: 3 }}
              InputProps={{ error: isError }}
            />
          </Box>
        </Box>
      }
      onSubmit={handleSubmit}
      isActive={isEditMode}
      onCancel={() => setIsEditMode(false)}
      onDataDisplayClick={() => setIsEditMode(true)}
    >
      -
    </InlineEdit>
  );
};
