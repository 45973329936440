import { Button } from 'lux/components';

import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const ResetFiltersButton = () => {
  const { formatMessage } = useLocale();
  const { resetAllFiltersToDefault } = usePeopleFilters();

  const handleClick = () => {
    resetAllFiltersToDefault(['sort']);
  };

  return (
    <Button size="sm" variant="text" onClick={handleClick} data-cy="available-people_reset-filters">
      {formatMessage({ id: AppMessages['filters.reset'] })}
    </Button>
  );
};
