import { Seniority } from 'api/types/Seniority.enum';
import type { RateCard } from 'api/actions/getProject/getProject.types';
import type { NeedDto } from 'api/types/Need.types';
import { isDateAfter, parseDate } from 'utils/dateUtils';
import { sortArr } from 'utils/sortArr';

import type { NeededRole, NeededRoleSeniority } from './RateCardDetails.types';

export const extractNeededRoles = (needs?: NeedDto[], sortSeniorities?: boolean): NeededRole[] => {
  if (!needs) return [];

  const requiredRoles = needs.reduce<NeededRole[]>((result, need) => {
    const newItem: NeededRoleSeniority = {
      value: need.seniority,
      startDate: need.startDate,
      endDate: need.endDate,
    };

    const roleIndex = result.findIndex((requirement) => requirement.role === need.role);
    if (roleIndex === -1) {
      result.push({ role: need.role, seniorities: [newItem] });
      return result;
    }

    // role already exists

    const seniorityIndex = result[roleIndex].seniorities.findIndex(({ value }) => value === newItem.value);
    if (seniorityIndex === -1) {
      result[roleIndex].seniorities.push(newItem);
      return result;
    }

    // seniority already exists

    const existingStartDate = result[roleIndex].seniorities[seniorityIndex].startDate;
    if (isDateAfter(parseDate(existingStartDate), parseDate(newItem.startDate))) {
      // find earliest start date
      result[roleIndex].seniorities[seniorityIndex].startDate = newItem.startDate;
    }
    const existingEndDate = result[roleIndex].seniorities[seniorityIndex].endDate;
    if (!existingEndDate || (newItem.endDate && isDateAfter(parseDate(newItem.endDate), parseDate(existingEndDate)))) {
      // find latest end date
      result[roleIndex].seniorities[seniorityIndex].endDate = newItem.endDate;
    }
    return result;
  }, []);

  if (sortSeniorities) {
    for (const role of requiredRoles) {
      role.seniorities = sortArr(role.seniorities, (a, b) => a.value.localeCompare(b.value));
    }
  }

  return requiredRoles;
};

export const getMissingNeededRatesCount = (neededRoles: NeededRole[], rateCard: RateCard) => {
  if (!neededRoles.length) {
    return 0;
  }

  let count = 0;

  for (const neededRole of neededRoles) {
    const rateCardForNeededRole = rateCard.items[neededRole.role];

    if (!rateCardForNeededRole) {
      continue;
    }

    const existingRates = rateCardForNeededRole.map(({ seniority }) => seniority);

    for (const neededSeniority of neededRole.seniorities) {
      if (
        !existingRates.includes(neededSeniority.value) &&
        existingRates[0] !== Seniority.withoutLevel &&
        neededSeniority.value !== Seniority.withoutLevel
      ) {
        count += 1;
      }
    }
  }

  return count;
};
