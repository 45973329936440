import { enqueueSnackbar } from 'notistack';

import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { addDeposit } from 'api/actions/addDeposit/addDeposit';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';

export const useAddDeposit = (workStatementId: string) => {
  const { invalidateBillingSummaryCache } = useProjectBillingSummaryData();
  const { formatMessage } = useLocale();

  return useMutationDomainError(['addDeposit'], addDeposit(workStatementId), {
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.addDeposit.success'] }));
      invalidateBillingSummaryCache();
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.addDeposit.error'] }),
  });
};
