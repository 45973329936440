import { useParams } from 'react-router-dom';

import { PersonDataContext } from 'context/personData/personDataContext/PersonDataContext';
import { usePerson } from 'hooks/usePerson/usePerson';

import { PersonDataContextControllerProps } from './PersonDataContextController.types';

export const PersonDataContextController = ({ children }: PersonDataContextControllerProps) => {
  const { personId } = useParams();

  if (!personId) {
    throw new Error('Person id is required');
  }

  const { data } = usePerson(personId);

  if (!data) {
    return null;
  }

  return <PersonDataContext.Provider value={data}>{children}</PersonDataContext.Provider>;
};
