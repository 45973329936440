import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Typography } from 'lux/components';
import { ChevronDownIcon } from 'lux/icons';

import { NeedsGroupedDefaultProps } from '../../../Needs.types';
import { NeedCard } from 'app/need/card/NeedCard';

import { StatsItems } from './statsItems/StatsItems';
import * as styles from './ProjectsList.styles';

export const ProjectsList = ({ groupedNeeds, onNeedClick }: NeedsGroupedDefaultProps) => {
  const handleNeedClick = (needId: string) => {
    onNeedClick(needId);
  };

  return (
    <>
      {groupedNeeds.map((project) => (
        <Accordion key={project.id} sx={styles.accordion}>
          <AccordionSummary
            expandIcon={<ChevronDownIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={styles.accordionSummary}
          >
            <Typography variant="body2" sx={styles.projectName}>
              {project.name}
            </Typography>
            <StatsItems needs={project.needs} projectId={project.id} />
          </AccordionSummary>
          <AccordionDetails sx={styles.accordionDetails}>
            {project.needs.map((need) => (
              <NeedCard key={need.id} need={need} onNeedClick={() => handleNeedClick(need.id)} />
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
