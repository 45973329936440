import { useRef } from 'react';

import { Button } from 'lux/components';
import { DeleteIcon, EditIcon } from 'lux/icons';
import { Box } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ReactComponent as LinkIcon } from 'assets/images/icons/link.svg';
import { FloatingToolbar } from 'ui/floatingToolbar/FloatingToolbar';
import { ensureHttpProtocol } from '../../../../../utils/ensureHttpProtocol';

import { ProjectLinkProps } from './ProjectLink.types';
import * as styles from './ProjectLink.styles';
import { ProjectLinkFavicon } from './projectLinkFavicon/ProjectLinkFavicon';

export const ProjectLink = ({ name, url, onLinkDelete, onLinkEdit }: ProjectLinkProps) => {
  const { formatMessage } = useLocale();

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleLinkEdit = () => {
    onLinkEdit(anchorRef);
  };

  return (
    <Box ref={anchorRef}>
      <FloatingToolbar
        placement="top"
        arrow
        buttons={
          <>
            <FloatingToolbar.Button
              tooltipTitle={formatMessage({
                id: AppMessages['projectDetails.links.edit'],
              })}
              Icon={EditIcon}
              onClick={handleLinkEdit}
            />
            <FloatingToolbar.Button
              tooltipTitle={formatMessage({
                id: AppMessages['projectDetails.links.open'],
              })}
              Icon={LinkIcon}
              href={ensureHttpProtocol(url)}
              target="_blank"
              rel="noreferrer"
            />
            <FloatingToolbar.Button
              tooltipTitle={formatMessage({
                id: AppMessages['projectDetails.links.delete'],
              })}
              Icon={DeleteIcon}
              onClick={onLinkDelete}
            />
          </>
        }
      >
        <Button
          href={ensureHttpProtocol(url)}
          target="_blank"
          rel="noreferrer"
          variant="text"
          size="small"
          sx={styles.button}
          startIcon={<ProjectLinkFavicon url={url} />}
        >
          {name}
        </Button>
      </FloatingToolbar>
    </Box>
  );
};
