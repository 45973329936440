import { alpha } from '@mui/material';

import { getBackgroundColor } from 'config/data/dashboard/availability';
import { Styles } from 'styles/theme';

export const tableCell = (available: number, total: number): Styles => ({
  backgroundColor: getBackgroundColor(available, total),
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  justifyContent: 'center',
  '&:not(:last-child)': {
    borderRight: (theme) => `2px solid ${theme.palette.common.white}`,
  },
});

export const valuesContainer: Styles = {
  display: 'flex',
  borderRadius: 2,
  overflow: 'hidden',
  minHeight: 32,
};

export const labelContainer: Styles = {
  display: 'flex',
  marginBottom: 1,
};

export const button: Styles = {
  '&:focus-visible': {
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
  },
};
