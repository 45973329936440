import { useState } from 'react';

import { Box, Stack, Tooltip } from '@mui/material';
import { Button, DateTimePicker, Table, TextField, Typography } from 'lux/components';
import { CheckIcon, CloseIcon, EndDateIcon, RemoveUserIcon, StartDateIcon, WarningFilledIcon } from 'lux/icons';

import { ButtonWithPopover } from 'ui/buttonWithPopover/ButtonWithPopover';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';
import { PopoverHeading } from 'ui/popoverHeading/PopoverHeading';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import {
  addDaysToDate,
  compareDatesDesc,
  DEFAULT_DATE_FORMAT,
  formatDate,
  isDateAfter,
  isPastOrPresentDate,
  parseDate,
} from 'utils/dateUtils';
import { useUpdateAssignments } from 'hooks/useUpdateAssignments/useUpdateAssignments';
import { useEndDateExtension } from 'hooks/useEndDateExtension/useEndDateExtension';
import { Translation } from 'ui/translation/Translation';

import { RemoveEmployeesModal } from './removeEmployeesModal/RemoveEmployeesModal';
import * as styles from './AssignmentsBulkActionsBar.styles';
import type { AssignmentsBulkActionsBarProps } from './AssignmentsBulkActionsBar.types';
import { getBulkAssignmentsRemovalDisabledReason, getMaxStartDate } from './AssignmentsBulkActionsBar.utils';

export const AssignmentsBulkActionsBar = (props: AssignmentsBulkActionsBarProps) => {
  const { rows, selected, assignments, onSelectAll, onBeforeRemoveAssignments } = props;
  const { projectDetails } = useProjectDetails();
  const { t } = useLocale();
  const [workStartPopoverOpen, setWorkStartPopoverOpen] = useState(false);
  const [extendEndDatePopoverOpen, setExtendEndDatePopoverOpen] = useState(false);
  const [workEndPopoverOpen, setWorkEndPopoverOpen] = useState(false);
  const [workStart, setWorkStart] = useState<Date | null>(null);
  const [workEnd, setWorkEnd] = useState<Date | null>(null);
  const [extensionEndDate, setExtensionEndDate] = useState<Date | null>(null);
  const {
    isOpen: isRemoveEmployeesModalOpen,
    setOpen: setRemoveEmployeesModalOpen,
    setClose: setRemoveEmployeesModalClose,
  } = useDialog();
  const projectStartDate = parseDate(projectDetails.startDate);
  const { mutateAsync, isLoading } = useUpdateAssignments(projectDetails.id);
  const { mutateAsync: mutateAsyncEndDateExtension, isLoading: isEndDateExtensionLoading } = useEndDateExtension(
    projectDetails.id,
  );

  useDialogBlockScroll(isRemoveEmployeesModalOpen);

  const selectedAssignments = assignments.filter(({ person, role, id }) =>
    selected.has(`${person.employeeId}-${role}-${id}`),
  );
  const isSelectedAssignmentsHasEndDate = !selectedAssignments.some(({ endDate }) => endDate === null);

  const removalBtnDisabledReason = getBulkAssignmentsRemovalDisabledReason({
    t,
    projectStage: projectDetails.stage,
    selectedAssignments,
  });
  const isRemovalBtnDisabled = Boolean(removalBtnDisabledReason);

  const latestAssignmentStartDate =
    selectedAssignments &&
    selectedAssignments.sort((a, b) => compareDatesDesc(parseDate(a?.startDate), parseDate(b?.startDate)))[0]
      ?.startDate;
  const maxStartDate = getMaxStartDate(selectedAssignments, projectDetails.endDate);

  const workStartValue = workStart ?? parseDate(latestAssignmentStartDate);

  const isWorkStartValid =
    !!workStartValue &&
    isPastOrPresentDate(projectStartDate, workStartValue) &&
    (!maxStartDate || isPastOrPresentDate(workStartValue, parseDate(maxStartDate)));

  const closestExtensionDate = maxStartDate
    ? addDaysToDate(parseDate(maxStartDate), 1)
    : parseDate(latestAssignmentStartDate);

  const workEndValue = workEnd ?? closestExtensionDate;

  const isWorkEndValid =
    !!workEndValue &&
    isPastOrPresentDate(projectStartDate, workEndValue) &&
    isPastOrPresentDate(parseDate(latestAssignmentStartDate), workEndValue);

  const extendEndDateValue = extensionEndDate ?? closestExtensionDate;

  const isExtendEndDateValid = !!(
    extendEndDateValue && isDateAfter(extendEndDateValue, new Date(projectDetails.startDate))
  );

  const isWorkEndAfterProjectEnd =
    !!(workEndValue && projectDetails.endDate) && isDateAfter(workEndValue, new Date(projectDetails.endDate));

  const closeWorkStartPopover = () => {
    setWorkStartPopoverOpen(false);
    setWorkStart(null);
  };

  const closeExtendEndDatePopover = () => {
    setExtendEndDatePopoverOpen(false);
    setExtensionEndDate(null);
  };

  const handleExtendEndDateChange = async () => {
    if (extendEndDateValue) {
      await mutateAsyncEndDateExtension({
        assignmentIds: selectedAssignments.map(({ id }) => id),
        extensionDate: formatDate(extendEndDateValue).toString(),
      });
      closeExtendEndDatePopover();
    }
  };

  const handleWorkStartChange = async () => {
    if (workStartValue) {
      await mutateAsync({
        assignmentIds: selectedAssignments.map(({ id }) => id),
        startDate: formatDate(workStartValue),
      });
      closeWorkStartPopover();
    }
  };

  const closeWorkEndPopover = () => {
    setWorkEndPopoverOpen(false);
    setWorkEnd(null);
  };

  const handleWorkEndChange = async () => {
    if (workEndValue) {
      await mutateAsync({
        assignmentIds: selectedAssignments.map(({ id }) => id),
        endDate: formatDate(workEndValue),
      });
      closeWorkEndPopover();
    }
  };

  const renderButtons = (onSubmit: VoidFunction, onCancel: VoidFunction, submitDisabled?: boolean) => (
    <Box sx={styles.popoverActions}>
      <Button
        iconOnly
        size="small"
        onClick={onSubmit}
        disabled={submitDisabled || isLoading}
        loading={isLoading}
        loadingPosition="center"
      >
        <CheckIcon />
      </Button>

      <Button iconOnly size="small" variant="outlined" onClick={onCancel || isLoading}>
        <CloseIcon />
      </Button>
    </Box>
  );

  return (
    <Table.Head>
      <Table.Row>
        <Table.Cell colSpan={100} sx={styles.column}>
          <Box sx={styles.row}>
            <Table.HeadCheckbox rows={rows} selected={selected} onClick={onSelectAll} />

            <Typography variant="body2">
              {t('projectDetails.teamDetails.assigned.table.bulk.selected', { count: selected.size })}
            </Typography>

            <Box sx={styles.actions}>
              <Tooltip
                arrow
                title={t('projectDetails.teamDetails.assigned.table.bulk.extendEndDate.blocked.button.tooltip')}
                disableInteractive={isSelectedAssignmentsHasEndDate}
                disableFocusListener={isSelectedAssignmentsHasEndDate}
                disableHoverListener={isSelectedAssignmentsHasEndDate}
              >
                <Box>
                  <ButtonWithPopover
                    open={extendEndDatePopoverOpen}
                    label={t('projectDetails.teamDetails.assigned.table.bulk.extendEndDate')}
                    icon={<StartDateIcon />}
                    popoverSpacing={3}
                    activeOnOpen
                    ButtonProps={{
                      onClick: () => setExtendEndDatePopoverOpen(true),
                      disabled: !isSelectedAssignmentsHasEndDate,
                    }}
                    onClose={closeExtendEndDatePopover}
                  >
                    <Stack gap={2}>
                      <PopoverHeading title={t('projectDetails.teamDetails.assigned.table.bulk.extendEndDate.title')} />
                      <Box sx={styles.datePickerRow}>
                        <DateTimePicker
                          inputFormat={DEFAULT_DATE_FORMAT}
                          minDate={closestExtensionDate}
                          value={extendEndDateValue}
                          views={['day']}
                          onChange={(date) => setExtensionEndDate(date)}
                          renderInput={(params) => <TextField {...params} size="small" autoFocus />}
                        />
                        {renderButtons(
                          handleExtendEndDateChange,
                          closeExtendEndDatePopover,
                          !isExtendEndDateValid || isEndDateExtensionLoading,
                        )}
                      </Box>
                    </Stack>
                  </ButtonWithPopover>
                </Box>
              </Tooltip>

              <ButtonWithPopover
                open={workStartPopoverOpen}
                label={t('projectDetails.teamDetails.assigned.table.bulk.changeStart')}
                icon={<StartDateIcon />}
                popoverSpacing={3}
                activeOnOpen
                ButtonProps={{ onClick: () => setWorkStartPopoverOpen(true) }}
                onClose={closeWorkStartPopover}
              >
                <Stack gap={2}>
                  <PopoverHeading title={t('projectDetails.teamDetails.assigned.table.bulk.changeStart')} />
                  <Box sx={styles.datePickerRow}>
                    <DateTimePicker
                      inputFormat={DEFAULT_DATE_FORMAT}
                      minDate={projectStartDate}
                      {...(maxStartDate && { maxDate: parseDate(maxStartDate) })}
                      value={workStartValue}
                      views={['day']}
                      onChange={(date) => setWorkStart(date)}
                      renderInput={(params) => <TextField {...params} size="small" autoFocus />}
                    />
                    {renderButtons(handleWorkStartChange, closeWorkStartPopover, !isWorkStartValid)}
                  </Box>
                </Stack>
              </ButtonWithPopover>

              <ButtonWithPopover
                open={workEndPopoverOpen}
                label={t('projectDetails.teamDetails.assigned.table.bulk.changeEnd')}
                icon={<EndDateIcon />}
                popoverSpacing={3}
                activeOnOpen
                ButtonProps={{ onClick: () => setWorkEndPopoverOpen(true) }}
                onClose={closeWorkEndPopover}
              >
                <Stack gap={2}>
                  <PopoverHeading title={t('projectDetails.teamDetails.assigned.table.bulk.changeEnd')} />
                  <Box sx={styles.datePickerRow}>
                    <DateTimePicker
                      inputFormat={DEFAULT_DATE_FORMAT}
                      minDate={parseDate(latestAssignmentStartDate)}
                      value={workEndValue}
                      views={['day']}
                      onChange={(date) => setWorkEnd(date)}
                      renderInput={(params) => <TextField {...params} size="small" autoFocus />}
                    />
                    {renderButtons(handleWorkEndChange, closeWorkEndPopover, !isWorkEndValid)}
                  </Box>
                  {isWorkEndAfterProjectEnd && (
                    <Box sx={styles.warningBox}>
                      <WarningFilledIcon color="warning" />
                      <Typography variant="body2">
                        <Translation
                          id="projectDetails.teamDetails.assigned.table.bulk.remove.warning.assignmentEndAfterProjectEnd"
                          values={{ previousEndDate: projectDetails.endDate, endDate: formatDate(workEndValue) }}
                        />
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </ButtonWithPopover>

              <Tooltip arrow placement="left" title={removalBtnDisabledReason}>
                <Box>
                  <ButtonWithPopover
                    label={t('projectDetails.teamDetails.assigned.table.bulk.remove')}
                    icon={<RemoveUserIcon />}
                    ButtonProps={{ onClick: setRemoveEmployeesModalOpen, disabled: isRemovalBtnDisabled }}
                  />
                </Box>
              </Tooltip>
            </Box>

            {isRemoveEmployeesModalOpen && (
              <RemoveEmployeesModal
                projectId={projectDetails.id}
                projectName={projectDetails.name}
                selectedAssignments={selectedAssignments}
                onBeforeRemoveAssignments={onBeforeRemoveAssignments}
                onClose={setRemoveEmployeesModalClose}
              />
            )}
          </Box>
        </Table.Cell>
      </Table.Row>
    </Table.Head>
  );
};
