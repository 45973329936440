import { Table } from 'lux/components';

import { WorkStatementTableCell as TableCell } from '../workStatementTableCell/WorkStatementTableCell';
import { Translation } from 'ui/translation/Translation';
import { getTheLongestFullNameFromAssignments } from '../BillingSummaryWorkStatement.utils';

import { table } from './WorkStatementByMember.styles';
import { WorkStatementByMembersProps } from './WorkStatementByMember.types';
import { WorkStatementAssignment } from './workStatementMember/WorkStatementMember';
import * as styles from './WorkStatementByMember.styles';

const FIRST_COLUMN_MIN_SIZE_IN_CHARS = 20; // INFO: ('Regular works (100%)'.length === 20)
const FIRST_COLUMN_SPACE_IN_CHARS = 4; // INFO: space and collapse button

export const WorkStatementByMembers = ({ workStatement }: WorkStatementByMembersProps) => {
  const firstColumnSize =
    Math.max(
      workStatement.assignments ? getTheLongestFullNameFromAssignments(workStatement.assignments) : 0,
      FIRST_COLUMN_MIN_SIZE_IN_CHARS,
    ) + FIRST_COLUMN_SPACE_IN_CHARS;

  return (
    <Table.Table sx={table}>
      <Table.Head>
        <Table.Row>
          <TableCell isLabel autoWidth sx={styles.firstColumn(firstColumnSize)}>
            <Translation id="projectDetails.billingSummary.member" />
          </TableCell>
          <TableCell isLabel autoWidth>
            <Translation id="projectDetails.billingSummary.role" />
          </TableCell>
          <TableCell isLabel autoWidth sx={styles.hoursCell}>
            <Translation id="projectDetails.billingSummary.hours" />
          </TableCell>
          <TableCell isLabel>
            <Translation id="projectDetails.billingSummary.rate" />
          </TableCell>
          <TableCell isLabel>
            <Translation id="projectDetails.billingSummary.total" />
          </TableCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {workStatement.assignments?.map((assignment) => (
          <WorkStatementAssignment assignment={assignment} key={assignment.employeeId} />
        ))}
      </Table.Body>
    </Table.Table>
  );
};
