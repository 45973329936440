import { Fragment } from 'react';

import { Stepper as MuiStepper } from '@mui/material';

import * as styles from './Stepper.styles';
import { StepperProps } from './Stepper.types';

export const Stepper = ({ size = 'large', hideConnectors, sx, ...props }: StepperProps) => (
  <MuiStepper
    sx={[...(Array.isArray(sx) ? sx : [sx]), styles.stepper(size)]}
    {...(hideConnectors && { connector: <Fragment /> })}
    {...props}
  />
);
