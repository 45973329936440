import { Styles } from 'lux/theme';

export const messageContainer: Styles = {
  display: 'flex',
  flexDirection: 'column',
};

export const hoursWrapper: Styles = {
  display: 'flex',
  gap: 1,
};

export const title: Styles = {
  color: 'text.secondary',
};
