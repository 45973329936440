import { useEffect, useState } from 'react';

import { SubmitHandler } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { InlineEdit } from 'lux/components';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { useProjectRoles } from 'hooks/useProjectRoles/useProjectRoles';
import { Autocomplete } from 'ui/form/autocomplete/Autocomplete';
import { mapToAutocompleteOptions } from 'ui/form/autocomplete/Autocomplete.functions';
import { useEditKeyRoles } from 'hooks/useEditKeyRoles/useEditKeyRoles';
import { KeyRoleItem } from 'api/actions/editKeyRoles/editKeyRolesActions.types';
import { mapKeyRoles } from '../PeopleInProject.utils';

import { ManageRolesFormValues, ManageRolesAutocompleteProps } from './ManageRolesAutocomplete.types';
import { manageRolesSchema } from './ManageRolesAutocomplete.schema';

export const ManageRolesAutocomplete = ({
  isActive,
  projectId,
  keyRoles,
  closeEditMode,
  currentValue,
}: ManageRolesAutocompleteProps) => {
  const { data, isLoading } = useProjectRoles();
  const { mutateAsync: addRole, isLoading: isAddingRole } = useEditKeyRoles(projectId);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const { control, handleSubmit } = useIntlForm<ManageRolesFormValues>({
    defaultValues: { role: currentValue || '' },
    mode: 'all',
    resolver: joiResolver(manageRolesSchema(keyRoles, currentValue)),
  });

  const onSubmit: SubmitHandler<ManageRolesFormValues> = async (formValues) => {
    const mappedKeyRoles: KeyRoleItem[] = mapKeyRoles(keyRoles).map((keyRole) => {
      if (currentValue && currentValue === keyRole.role) {
        return { ...keyRole, role: formValues.role };
      }

      return keyRole;
    });

    if (!formValues.role || (currentValue && formValues.role === currentValue)) {
      closeEditMode();
      return;
    }

    if (!currentValue) {
      mappedKeyRoles.push({ ...formValues });
    }

    await addRole({
      keyRoleItems: mappedKeyRoles,
    });

    closeEditMode();
  };

  return (
    <InlineEdit
      header=""
      isActive={isActive}
      onCancel={closeEditMode}
      onSubmit={handleSubmit(onSubmit)}
      loading={isAddingRole}
      input={
        <Autocomplete
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          loading={isLoading}
          name="role"
          options={data ? mapToAutocompleteOptions(data) : []}
          control={control}
          freeSolo
          autoFocus
          size="small"
        />
      }
    >
      {''}
    </InlineEdit>
  );
};
