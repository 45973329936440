import { Styles } from 'styles/theme';

export const modifyRateInput: Styles = {
  margin: 0,
  padding: 0,
  width: '160px',
};

export const modifyRateButton: Styles = {
  textDecoration: 'underline',
  color: 'primary.main',
  fontWeight: 500,
};
