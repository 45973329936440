import { Box, Stack } from '@mui/material';

import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';
import { Skeleton } from 'ui/skeleton/Skeleton';

import * as styles from './NeedCardHeader.styles';

export const NeedCardHeaderSkeleton = () => (
  <Box sx={styles.needCardHeaderWrapper}>
    <Stack direction="row" justifyContent="space-between">
      <Stack gap={0.5}>
        <TextSkeleton width={180} variant="body2" />
        <TextSkeleton width={160} variant="caption" />
      </Stack>

      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
          <TextSkeleton width={108} variant="caption" />
        </Stack>

        <Box sx={styles.skillsWrapper}>
          <Skeleton width={48} height={24} />
        </Box>
      </Stack>
    </Stack>
  </Box>
);
