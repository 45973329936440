import { Dialog } from '@mui/material';

import { RemoveImmediatelyModalProps } from './RemoveImmediatelyModal.types';
import { RemoveImmediatelyContent } from './removeImmediatelyContent/RemoveImmediatelyContent';

export const RemoveImmediatelyModal = ({ open, onClose, assignmentData }: RemoveImmediatelyModalProps) => (
  <Dialog open={open} fullWidth onClose={onClose}>
    <RemoveImmediatelyContent onClose={onClose} assignmentData={assignmentData} />
  </Dialog>
);
