import { Dialog } from '@mui/material';

import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';

import { AddBillingCycleForm } from './addBillingCycleForm/AddBillingCycleForm';
import { AddBillingCycleModalProps } from './AddBillingCycleModal.types';

export const AddBillingCycleModal = ({ open, onClose }: AddBillingCycleModalProps) => {
  useDialogBlockScroll(open);

  return (
    <Dialog open={open} onClose={onClose}>
      <AddBillingCycleForm onClose={onClose} />
    </Dialog>
  );
};
