import { Table } from 'lux/components';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { ClientsSortingField } from 'context/clientsFilters/clientsFiltersContext/ClientsFiltersContext.types';
import { parseSortFilter, stringifySortFilter } from 'utils/filters/filters';
import { SortOrder } from 'utils/filters/filters.types';
import { useClientsFilters } from '../../../../../hooks/useClientsFilters/useClientsFilters';

import * as styles from './ClientsTableHead.styles';

export const ClientsTableHead = () => {
  const { formatMessage } = useLocale();
  const { setFilterValue, filters } = useClientsFilters();

  const { field: currentField, order } = parseSortFilter(filters.sort);

  const getDirection = (field: ClientsSortingField) => {
    if (currentField === field) {
      return order.toLowerCase() as Lowercase<SortOrder>;
    }
  };

  const handleLabelClick = (field: ClientsSortingField) => {
    const newOrder = currentField === field && order === SortOrder.asc ? SortOrder.desc : SortOrder.asc;

    setFilterValue('sort', stringifySortFilter(field, newOrder));
  };

  return (
    <Table.Row component="div">
      <Table.Cell sx={{ ...styles.tableHeadCell, width: '40%' }} component="div" padding="none">
        <Table.SortLabel
          active={currentField === ClientsSortingField.name}
          direction={getDirection(ClientsSortingField.name)}
          onClick={() => {
            handleLabelClick(ClientsSortingField.name);
          }}
        >
          {formatMessage({ id: AppMessages['clients.clientsList.tableHead.client'] })}
        </Table.SortLabel>
      </Table.Cell>
      <Table.Cell sx={{ ...styles.tableHeadCell, width: '20%' }} component="div" padding="none">
        <Table.SortLabel
          active={currentField === ClientsSortingField.country}
          direction={getDirection(ClientsSortingField.country)}
          onClick={() => handleLabelClick(ClientsSortingField.country)}
        >
          {formatMessage({ id: AppMessages['clients.clientsList.tableHead.country'] })}
        </Table.SortLabel>
      </Table.Cell>
      <Table.Cell sx={{ ...styles.tableHeadCell, width: '30%' }} component="div" padding="none">
        <Table.SortLabel
          active={currentField === ClientsSortingField.industries}
          direction={getDirection(ClientsSortingField.industries)}
          onClick={() => handleLabelClick(ClientsSortingField.industries)}
        >
          {formatMessage({ id: AppMessages['clients.clientsList.tableHead.industries'] })}
        </Table.SortLabel>
      </Table.Cell>
      <Table.Cell sx={{ ...styles.tableHeadCell, width: '10%' }} component="div" padding="none">
        <Table.SortLabel
          active={currentField === ClientsSortingField.projectsCount}
          direction={getDirection(ClientsSortingField.projectsCount)}
          disabled={true}
          onClick={() => handleLabelClick(ClientsSortingField.projectsCount)}
        >
          {formatMessage({ id: AppMessages['clients.clientsList.tableHead.projectsCount'] })}
        </Table.SortLabel>
      </Table.Cell>
      <Table.Cell sx={{ ...styles.tableHeadCell, width: 60 }} component="div" padding="none"></Table.Cell>
    </Table.Row>
  );
};
