import { Styles } from 'lux/theme';

export const dialog: Styles = {
  '.MuiDialog-paper': {
    maxWidth: '900px',
  },

  '.MuiStack-root': {
    justifyContent: 'flex-end',
  },
};
