import { Stack } from '@mui/material';

import { NeedsContent } from 'shared/needs/needsContent/NeedsContent';
import { useLocale } from 'hooks/useLocale/useLocale';
import { DashboardCard } from 'ui/dashboardCard/DashboardCard';
import { AppMessages } from 'i18n/messages';
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser';
import { AccessType } from 'context/auth/currentUser/CurrentUser.types';
import { NeedsFiltersContextController } from 'context/needsFilters/needsFiltersContextController/NeedsFiltersContextController';
import { NeedsFilters as NeedsFiltersType } from 'context/needsFilters/needsFiltersContext/NeedsFiltersContext.types';
import { DepartmentCode } from 'api/types/DepartmentCode.enum';
import * as styles from '../DashboardGrid.styles';

import { NeedsSortingBar } from './needsSortingBar/NeedsSortingBar';
import { NeedsFilters } from './needsFilters/NeedsFilters';
import { NeedsRedirect } from './needsRedirect/NeedsRedirect';

const departmentToRolesMap = {
  [DepartmentCode.pm]: ['PM', 'Project Manager'],
  [DepartmentCode.nmd]: ['Design', 'Designer'],
  [DepartmentCode.php]: ['PHP', 'Backend'],
  [DepartmentCode.node]: ['Node', 'Node.js', 'Backend'],
  [DepartmentCode.qa]: ['QA'],
  [DepartmentCode.qaa]: ['QAA'],
  [DepartmentCode.ba]: ['BA', 'Business Analyst'],
  [DepartmentCode.java]: ['JAVA', 'Java'],
  [DepartmentCode.front]: ['FE', 'Frontend', 'Front', 'React'],
  [DepartmentCode.devops]: ['Devops'],
};

export const Needs = () => {
  const { formatMessage } = useLocale();
  const { data: user } = useCurrentUser();

  const initialNeedsFilters: Partial<NeedsFiltersType> = {
    roles:
      user?.person?.managedDepartments?.flatMap((department) => {
        if (department in departmentToRolesMap) {
          return departmentToRolesMap[department as keyof typeof departmentToRolesMap];
        }
        return [];
      }) ?? [],
    groupBy: 'project',
  };

  return (
    <NeedsFiltersContextController initialFilters={initialNeedsFilters} storageKey="dashboardNeeds">
      <DashboardCard
        sx={{ wrapper: styles.cardWrapper }}
        title={formatMessage({
          id: AppMessages[
            user?.metadata.accessType === AccessType.sales ? 'dashboard.myNeeds.title' : 'dashboard.needs.title'
          ],
        })}
        actions={<NeedsRedirect />}
      >
        <Stack overflow="hidden">
          <Stack>
            <NeedsFilters />
            <NeedsSortingBar />
          </Stack>
          <Stack spacing={2} sx={styles.listWrapper}>
            <NeedsContent />
          </Stack>
        </Stack>
      </DashboardCard>
    </NeedsFiltersContextController>
  );
};
