import { AutocompleteStack } from 'ui/autocompleteStack/AutocompleteStack';
import { Filter } from 'ui/filter/Filter';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useClientsFilters } from 'hooks/useClientsFilters/useClientsFilters';
import { useIndustries } from 'hooks/useIndustries/useIndustries';

import { ClientIndustriesFilterProps } from './ClientIndustriesFilter.types';

export const ClientIndustriesFilter = ({ variant }: ClientIndustriesFilterProps) => {
  const { t, formatMessage } = useLocale();
  const { data: industriesData = [] } = useIndustries();
  const {
    filters: { industries },
    setFilterValue,
  } = useClientsFilters();

  const handleIndustriesChange = (newValue: string[]) => {
    setFilterValue('industries', newValue);
  };

  return (
    <Filter variant={variant} label={t('clients.clientsList.filter.industries')} shouldStretch>
      <AutocompleteStack
        label={t('clients.clientsList.filter.industries')}
        buttonLabel={formatMessage({ id: AppMessages['clients.clientsList.filter.actionBtn.industries'] })}
        dictionary={industriesData}
        values={industries}
        onFieldsChange={handleIndustriesChange}
      />
    </Filter>
  );
};
