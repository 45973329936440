export const mapToSelectOptions = (
  optionValues: string[],
  options: { clearable?: boolean; emptyOptionLabel?: string } = {},
) => {
  const mappedOptionValues = optionValues.map((value, id) => ({
    id: String(id),
    children: value,
    value,
  }));

  if (options.clearable) {
    mappedOptionValues.unshift({
      id: String(optionValues.length),
      children: options.emptyOptionLabel || 'None',
      value: '',
    });
  }

  return mappedOptionValues;
};

export const mapKeyValueToSelectOptions = (optionValues: { value: string; label: string }[]) => {
  const mappedOptionValues = optionValues.map((item) => ({
    id: item.value,
    children: item.label,
    value: item.value,
  }));

  return mappedOptionValues;
};
