import { Styles, theme } from 'styles/theme';

export const card: Styles = {
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.border.default}`,
  borderRadius: '12px',
  p: 2,
  backgroundColor: 'surface.paper.default',
  rowGap: 2,
};

export const title: Styles = {
  fontSize: 16,
  lineHeight: '28px',
};

export const addRoleButton: Styles = {
  alignSelf: 'flex-start',
  '&&': {
    fontSize: 16,
  },

  '.MuiButton-startIcon, .MuiButton-startIcon svg': {
    width: 24,
    height: 24,
  },
};
