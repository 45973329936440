import { SxProps } from '@mui/system';

export const container: SxProps = {
  mt: 4,
  borderRadius: 0,
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  borderBottomLeftRadius: 20,
  borderBottomRightRadius: 20,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'border.default',
  padding: 2,
  width: 400,
};
