import { Styles } from 'lux/theme';

import { theme } from 'styles/theme';

export const container: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
};

export const icon: Styles = {
  '&.MuiSvgIcon-root path': {
    fill: theme.palette.accent.blue.text,
  },
};
