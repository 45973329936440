import { SxProps } from '@mui/system';

export const options: SxProps = {
  minWidth: 250,

  '& .MuiMenu-paper': {
    width: '80px !important',
    minWidth: 'unset',
  },
};
