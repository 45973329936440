import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { AddDepositParams } from './addDeposit.types';

export const addDeposit: (workStatementId: string) => MutationFn<AddDepositParams> =
  (workStatementId: string) => (body) => ({
    endpoint: `/work-statements/${workStatementId}/deposits`,
    method: 'POST',
    params: body,
  });
