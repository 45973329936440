import { Button } from '@mui/material';
import { SendIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { EmptyState } from 'ui/emptyState/EmptyState';
import { useDialog } from 'hooks/useDialog/useDialog';
import { RequestNeed } from 'app/need/request/RequestNeed';

import { AssignmentsEmptyStateProps } from './AssignmentsEmptyState.types';

export const AssignmentsEmptyState = ({ projectId, allowProjectEdition }: AssignmentsEmptyStateProps) => {
  const { formatMessage } = useLocale();
  const {
    isOpen: isRequestNeedModalOpen,
    setOpen: setRequestNeedModalOpen,
    setClose: setRequestNeedModalClose,
  } = useDialog();

  return (
    <EmptyState
      illustration="relaxPeople"
      title={formatMessage({ id: AppMessages['projectDetails.teamDetails.assigned.empty.title'] })}
      message={formatMessage({ id: AppMessages['projectDetails.teamDetails.assigned.empty.subtitle'] })}
      actionBtn={
        <>
          <Button
            variant="contained"
            onClick={setRequestNeedModalOpen}
            startIcon={<SendIcon />}
            disabled={!allowProjectEdition}
          >
            {formatMessage({ id: AppMessages['projectDetails.teamDetails.assigned.empty.actionBtn'] })}
          </Button>
          <RequestNeed projectId={projectId} open={isRequestNeedModalOpen} onClose={setRequestNeedModalClose} />
        </>
      }
    />
  );
};
