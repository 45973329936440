import { useContext } from 'react';

import { ProjectNeedsFiltersContext } from 'context/projectNeedsFilters/projectNeedsFiltersContext/ProjectNeedsFiltersContext';

export const useProjectNeedsFilters = () => {
  const context = useContext(ProjectNeedsFiltersContext);

  if (context === undefined) {
    throw new Error('ProjectNeedsFiltersContext must be within ProjectNeedsFiltersContextProvider');
  }

  return context;
};
