import { useContext } from 'react';

import { UnsavedChangesDialogContext } from '../../context/modalUnsavedChanges/UnsavedChangesDialogContext/UnsavedChangesDialogContext';

export const useUnsavedChangesDialogContext = () => {
  const context = useContext(UnsavedChangesDialogContext);

  if (!context) {
    throw new Error('UnsavedChangesDialogContext must be within UnsavedChangesDialogContextController');
  }

  return context;
};
