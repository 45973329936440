import { DialogTitle, DialogContent, DialogActions, IconButton, Stack, Box } from '@mui/material';
import { Button } from 'lux/components';
import { CloseIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Loader } from 'ui/loader/Loader';

import { DialogInnerWrapperProps } from './DialogInnerWrapper.types';
import * as styles from './DialogInnerWrapper.styles';

export const DialogInnerWrapper = ({
  children,
  title,
  loading,
  onClose,
  onSubmit,
  submitText,
  hasHeaderActions = false,
  hasFooterActions = true,
  additionalButtons = null,
  additionalHeaderButtons = null,
  headerSx,
  contentSx,
}: DialogInnerWrapperProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <DialogTitle component="h5" sx={headerSx || styles.headerTitle}>
        <span>{title}</span>

        {hasHeaderActions && (
          <Box sx={styles.headerActions}>
            {additionalHeaderButtons}
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </DialogTitle>
      <DialogContent sx={contentSx}>{!loading ? children : <Loader fullHeight={false} />}</DialogContent>
      {hasFooterActions && (
        <DialogActions>
          {!loading && (
            <Stack direction="row" justifyContent="space-between" gap={2} sx={styles.buttonWrapper}>
              {additionalButtons}
              <Stack direction="row" gap={2}>
                <Button
                  data-cy="dialog-btn_cancel"
                  variant="outlinedGray"
                  color="primary"
                  size="medium"
                  onClick={onClose}
                  disabled={loading}
                >
                  {formatMessage({ id: AppMessages['button.cancel'] })}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  loading={loading}
                  onClick={onSubmit}
                  data-cy="dialog-btn_submit"
                >
                  {submitText ?? title}
                </Button>
              </Stack>
            </Stack>
          )}
        </DialogActions>
      )}
    </>
  );
};
