import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useMutation } from 'hooks/useMutation/useMutation';
import { assignmentsKeys, projectsKeys } from 'utils/queryKeys';
import { getApiError } from 'api/utils/getApiError';
import { cancelProjectExtensionMutation } from 'api/actions/cancelProjectExtension/cancelProjectExtensionAction';

export const useCancelProjectExtension = (projectId: string) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(['cancelProjectExtensionMutation'], cancelProjectExtensionMutation(projectId), {
    onSuccess: () => {
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(assignmentsKeys.assignmentsList);
    },
    onError: (error) => {
      enqueueSnackbar(getApiError(error));
    },
  });
};
