import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { EndDateExtensionArguments, EndDateExtensionResponse } from './personEndDateExtension.types';

export const personEndDateExtensionMutation: (
  projectId: string,
) => MutationFn<EndDateExtensionArguments, EndDateExtensionResponse> = (projectId: string) => (body) => ({
  endpoint: `/projects/${projectId}/assignments/plan-extension`,
  method: 'POST',
  params: body,
});
