import { Button } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { ShowMoreButtonProps } from './ShowMoreButton.types';
import * as styles from './ShowMoreButton.styles';

export const ShowMoreButton = ({ isExpanded, toggleExpanded }: ShowMoreButtonProps) => {
  const { formatMessage } = useLocale();

  return (
    <Button sx={styles.button} size="small" variant="text" onClick={toggleExpanded}>
      {isExpanded
        ? formatMessage({ id: AppMessages['button.showLess'] })
        : formatMessage({ id: AppMessages['button.showMore'] })}
    </Button>
  );
};
