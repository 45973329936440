import { Styles } from 'styles/theme';

export const bannerGroup = (isBannerOpen: boolean): Styles => ({
  paddingBottom: isBannerOpen ? 3 : 0,
  flexDirection: 'column',
});

export const planExtensionBtn: Styles = {
  width: '200px',
};
