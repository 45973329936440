import { useQueryClient } from '@tanstack/react-query';

import { useMutation } from 'hooks/useMutation/useMutation';
import { assignPersonMutation } from 'api/actions/assignPerson/assignPersonActions';
import { assignmentsKeys, needsKeys, projectsKeys } from 'utils/queryKeys';
import { getApiError } from '../../api/utils/getApiError';

export const useAssignPerson = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(['assignPerson'], assignPersonMutation(projectId), {
    onSuccess: () => {
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(projectsKeys.singleProjectNeeds(projectId));
      queryClient.invalidateQueries(needsKeys.needsList);
      queryClient.invalidateQueries(assignmentsKeys.assignmentsList);
    },
    onError: (error) => {
      getApiError(error);
    },
  });
};
