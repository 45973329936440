import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { Translation } from 'ui/translation/Translation';
import { WorkStatementStatusBadge } from 'ui/workStatementStatusBadge/WorkStatementStatusBadge';

import { CurrentBillingCycleProps } from './CurrentBillingCycle.types';
import * as styles from './CurrentBillingCycle.styles';

export const CurrentBillingCycle = ({ billingCycle, workStatement }: CurrentBillingCycleProps) => (
  <Box sx={styles.container}>
    <Typography variant="subtitle2" mb={1.5}>
      <Translation id="projectDetails.billingSummary.workStatementDetails.currentBillingCycle" />
    </Typography>
    <Box px={1}>
      <Typography variant="caption" color="text.secondary">
        <Translation id="projectDetails.billingSummary.workStatementDetails.currentBillingCycle.date" />
      </Typography>
      <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
        <Typography variant="caption" sx={styles.text}>
          <Translation
            id="projectDetails.billingSummary.workStatementDetails.currentBillingCycle.from"
            values={{
              from: billingCycle?.startDate || '-',
            }}
          />
          <br />
          <Translation
            id="projectDetails.billingSummary.workStatementDetails.currentBillingCycle.to"
            values={{
              to: billingCycle?.endDate || '-',
            }}
          />
        </Typography>
        {billingCycle && <WorkStatementStatusBadge workStatement={workStatement} billingCycle={billingCycle} />}
      </Stack>
    </Box>
  </Box>
);
