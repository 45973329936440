import { useContext } from 'react';

import { ProjectDetailsContext } from 'context/projectDetails/projectDetailsContext/ProjectDetailsContext';

export const useProjectDetails = () => {
  const context = useContext(ProjectDetailsContext);

  if (!context) {
    throw new Error('ProjectDetailsContext must be within ProjectDetailsProvider');
  }

  return context;
};
