import { useContext } from 'react';

import { ProjectBillingSummaryDataContext } from '../../context/projectBillingSummary/projectBillingSummaryDataContext/ProjectBillingSummaryDataContext';

export const useProjectBillingSummaryData = () => {
  const context = useContext(ProjectBillingSummaryDataContext);

  if (context === undefined) {
    throw new Error('ProjectBillingSummaryDataContext must be within ProjectBillingSummaryDataContextController');
  }

  return context;
};
