import { useContext } from 'react';

import { ProjectsFiltersContext } from 'context/projectsFilters/projectsFiltersContext/ProjectsFiltersContext';

export const useProjectsFilters = () => {
  const context = useContext(ProjectsFiltersContext);

  if (context === undefined) {
    throw new Error('ProjectsFiltersContext must be within ProjectsFiltersContextController');
  }

  return context;
};
