import { Styles } from 'styles/theme';

export const actionButton: Styles = {
  width: 'max-content',
};

export const alert: Styles = {
  alignItems: 'center',
  pl: 2.75,
  pr: 2,
  borderRadius: 3,
  overflow: 'hidden',

  '> .MuiAlert-message': {
    flex: 1,

    '> .MuiAlertTitle-root': {
      fontWeight: 500,
      lineHeight: '28px',
    },
  },

  '> .MuiAlert-icon': {
    mr: 1,

    '.MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },

  '> .MuiAlert-action': {
    alignSelf: 'center',
    m: 0,
    p: 0,

    '> .MuiButtonBase-root': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },

  '& .MuiTypography-root': {
    margin: 0,
  },
};
