import { Box, FormControlLabel } from '@mui/material';
import { Checkbox, Switch, ToggleButton, ToggleButtonGroup } from 'lux/components';

import { PeriodType } from 'context/availabilityFilters/availabilityFiltersContext/AvailabilityFiltersContext.types';
import { useAvailabilityFilters } from 'hooks/useAvailabilityFilters/useAvailabilityFilters';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Translation } from 'ui/translation/Translation';

import * as styles from './AvailabilityFilters.styles';

export const AvailabilityFilters = () => {
  const { formatMessage } = useLocale();
  const {
    filters: { splitBySeniority, period, includeProposed, includeExtension },
    setFilterValue,
  } = useAvailabilityFilters();

  return (
    <Box sx={styles.availabilityFiltersWrapper}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box sx={styles.toggleButtonGroupWithGap}>
          <Switch
            checked={splitBySeniority}
            color="primary"
            data-cy="availability-filters_seniority"
            label={formatMessage({ id: AppMessages['dashboard.availability.filters.seniority'] })}
            onChange={() => setFilterValue('splitBySeniority', !splitBySeniority)}
          />
          <FormControlLabel
            checked={includeProposed}
            control={<Checkbox onChange={() => setFilterValue('includeProposed', !includeProposed)} />}
            data-cy="availability-filters_include-proposed-people"
            label={<Translation id="dashboard.availability.filters.proposed.label" />}
            sx={styles.checkbox}
          />
          <FormControlLabel
            checked={includeExtension}
            control={<Checkbox onChange={() => setFilterValue('includeExtension', !includeExtension)} />}
            data-cy="availability-filters_include-booked"
            label={<Translation id="dashboard.availability.filters.preassigned.label" />}
            sx={styles.checkbox}
          />
        </Box>
        <ToggleButtonGroup
          value={period}
          exclusive
          onChange={(_, value) => {
            if (value) {
              setFilterValue('period', value);
            }
          }}
          aria-label={formatMessage({ id: AppMessages['dashboard.availability.filters.period.label'] })}
        >
          <ToggleButton value={PeriodType.weekly} data-cy="availability-filters-period-toggle_weekly">
            {formatMessage({ id: AppMessages['dashboard.availability.filters.period.week'] })}
          </ToggleButton>
          <ToggleButton value={PeriodType.monthly} data-cy="availability-filters-period-toggle_monthly">
            {formatMessage({ id: AppMessages['dashboard.availability.filters.period.month'] })}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};
