import { Styles } from 'styles/theme';
import needCardBgImage from 'assets/images/need-card-bg-image.svg';

export const accordion: Styles = {
  backgroundColor: 'surface.paper.default',
  borderColor: 'surface.paper.default',

  '.MuiAccordionSummary-expandIconWrapper > svg > path': {
    fill: (theme) => `${theme.palette.text.secondary}`,
  },

  '.MuiAccordionDetails-root': {
    marginTop: 0,
    borderTop: 0,
  },

  '&:not(:last-of-type)': {
    marginBottom: 2,
  },
};

export const accordionSummary: Styles = {
  '.MuiAccordionSummary-content': {
    flexDirection: 'column',
    marginRight: 2,
    alignItems: 'flex-start',
  },

  '.MuiAccordionSummary-content.Mui-expanded': {
    marginRight: 2,
  },
};

export const accordionDetails: Styles = {
  '>.MuiBox-root': {
    marginBottom: 2,
  },
};

export const projectName: Styles = {
  fontWeight: 500,
  marginBottom: 1,
};

export const needCard: Styles = {
  backgroundColor: 'white',
  backgroundImage: `url(${needCardBgImage})`,
};
