import { FormControlLabel } from '@mui/material';
import { Checkbox } from 'lux/components';
import { UserIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Filter } from 'ui/filter/Filter';
import { filtersData } from 'config/data/people/filters';

import { DepartmentFilterProps as SharedRoleFilterProps } from './DepartmentFilter.types';

export const DepartmentFilter = ({
  variant,
  departments,
  dataCy,
  onFilterClick,
  onClearClick,
}: SharedRoleFilterProps) => {
  const { formatMessage } = useLocale();

  return (
    <Filter
      variant={variant}
      dataCy={dataCy}
      icon={<UserIcon />}
      label={formatMessage({ id: AppMessages['filters.department.label'] })}
      popoverTitle={formatMessage({ id: AppMessages['filters.department.popover.title'] })}
      popoverActionLabel={formatMessage({ id: AppMessages['filters.clear'] })}
      valueSelected={departments.length > 0}
      selectedCount={departments.length}
      onPopoverActionClick={onClearClick}
    >
      {filtersData.departments.map(({ labelTranslation, value }) => (
        <FormControlLabel
          key={value}
          label={formatMessage({ id: AppMessages[labelTranslation] })}
          control={
            <Checkbox value={value} checked={departments.includes(value)} onChange={() => onFilterClick(value)} />
          }
        />
      ))}
    </Filter>
  );
};
