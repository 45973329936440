import { NeedCardProps } from 'app/need/card/NeedCard.types';
import { Card } from 'ui/card/Card';

import * as styles from './NeedCard.styles';
import { NeedCardHeader } from './needCardHeader/NeedCardHeader';
import { NeedCardFooter } from './needCardFooter/NeedCardFooter';

export const NeedCard = ({ need, onNeedClick }: NeedCardProps) => (
  <Card onClick={() => onNeedClick(need.id)} sx={styles.needCard}>
    <Card.Header>
      <NeedCardHeader
        role={need.role}
        seniority={need.seniority}
        projectName={need.project.name}
        clientName={need.project.client.name}
        startDate={need.startDate}
        skillSet={need.skillSet}
        createdAt={need.createdAt}
        additionalInformation={need.additionalInformation}
      />
    </Card.Header>
    {!!need.proposals?.length && (
      <Card.Footer>
        <NeedCardFooter proposals={need.proposals} />
      </Card.Footer>
    )}
  </Card>
);
