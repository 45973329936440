import { useState } from 'react';

import { AssignmentInfoContext } from 'context/assignmentInfo/assignmentInfoContext/AssignmentInfoContext';
import { Assignment } from 'api/actions/getPersonAssignments/getPersonAssignments.types';
import { useAssignmentsFilters } from 'hooks/useAssignmentsFilters/useAssignmentsFilters';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useAssignments } from 'hooks/useAssignments/useAssignments';
import { mapAssignmentData } from 'shared/assignments/Assignments.functions';

import { AssignmentInfoContextControllerProps } from './AssignmentInfoContextController.types';

export const AssignmentInfoContextController = ({ children }: AssignmentInfoContextControllerProps) => {
  const { filters } = useAssignmentsFilters();
  const {
    projectDetails: { id },
  } = useProjectDetails();
  const { data: assignmentsData } = useAssignments({
    project: id,
    ...filters,
  });
  const [selectedAssignmentId, setSelectedAssignmentId] = useState<Assignment['id'] | null>(null);

  const handleOpen = (id: Assignment['id']) => {
    setSelectedAssignmentId(id);
  };

  const handleClose = () => {
    setSelectedAssignmentId(null);
  };

  const mappedAssignments = mapAssignmentData(assignmentsData?.pages) ?? [];

  const checkAvailableRoleForUser = (employeeId: number) => {
    let availableRole;

    mappedAssignments.forEach((assignment) => {
      const { person } = assignment;

      if (person.employeeId === employeeId) {
        availableRole = assignment.role;
      }
    });

    return availableRole;
  };

  const data = {
    assignment: mappedAssignments.find((assignment) => assignment.id === selectedAssignmentId) || null,
    projectAssignments: mappedAssignments,
    isOpen: Boolean(selectedAssignmentId),
    selectedAssignmentId,
    setOpen: handleOpen,
    setClose: handleClose,
    checkAvailableRoleForUser,
  };

  return <AssignmentInfoContext.Provider value={data}>{children}</AssignmentInfoContext.Provider>;
};
