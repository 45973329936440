import MuiSkeleton from '@mui/material/Skeleton';
import { Variant } from '@mui/material/styles/createTypography';

import { theme } from 'styles/theme';

import type { TextSkeletonProps } from './TextSkeleton.types';
import * as styles from './TextSkeleton.styles';

const parseThemeValue = (value?: string | number): string | undefined => {
  if (!value) {
    return undefined;
  }
  if (typeof value === 'number') {
    return value + 'px';
  }
  if (value.includes('rem')) {
    return parseFloat(value) * 16 + 'px';
  }
  return value;
};

const getFontSizeAndLineHeightFromVariant = (variant?: Variant): [string | undefined, string | undefined] => {
  if (!variant) {
    return [undefined, undefined];
  }
  return [parseThemeValue(theme.typography[variant].fontSize), parseThemeValue(theme.typography[variant].lineHeight)];
};

export const TextSkeleton = (props: TextSkeletonProps) => {
  const { variant, width, fontSize, lineHeight, sx } = props;

  const [variantFontSize, variantLineHeight] = getFontSizeAndLineHeightFromVariant(variant);
  const finalFontSize = fontSize || variantFontSize;
  const finalLineHeight = lineHeight || variantLineHeight;

  return (
    <MuiSkeleton variant="rectangular" width={width} sx={styles.textSkeleton(finalFontSize, finalLineHeight, sx)} />
  );
};
