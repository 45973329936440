import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { SelectedBillingCycleKey } from 'api/types/Timesheet.types';

export const userNavigationState = (projectId: string, date: string) => ({
  projectId,
  date,
});

export const useLeaveRoute = (projectId: string, idToCompare: string) => {
  const [isNavigationReset, setIsNavigationReset] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (!idToCompare.includes(projectId)) {
      sessionStorage.removeItem(SelectedBillingCycleKey.SelectedBillingCycle);
      setIsNavigationReset(true);
    }
  }, [location.pathname, projectId]);

  return [isNavigationReset];
};
