import { Box } from '@mui/material';
import { Typography } from 'lux/components';

import { WarningTooltipProps } from '../WarningTooltip.types';
import { useLocale } from 'hooks/useLocale/useLocale';

import * as styles from './WarningTooltipContent.styles';

export const WarningTooltipContent = ({ assignmentHoursData }: WarningTooltipProps) => {
  const { t } = useLocale();

  return (
    <Box sx={styles.messageContainer}>
      <Typography sx={styles.title}>{t('projectDetails.timesheet.tooltip.warning.title')}</Typography>
      <Box sx={styles.hoursWrapper}>
        <Typography>{t('projectDetails.timesheet.tooltip.warning.planedHours')}</Typography>
        <Typography>{assignmentHoursData.fteHoursThisMonth}</Typography>
      </Box>
      <Box sx={styles.hoursWrapper}>
        <Typography>{t('projectDetails.timesheet.tooltip.warning.recordedHours')}</Typography>
        <Typography>{assignmentHoursData.hoursSum}</Typography>
      </Box>
    </Box>
  );
};
