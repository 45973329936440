import { Stack } from '@mui/system';
import { Badge, Typography } from 'lux/components';

import { parseDate, isPastDate, getNumberOfCalendarDaysBetweenDates } from 'utils/dateUtils';
import { Translation } from 'ui/translation/Translation';
import { InvoiceStatusBadge } from 'ui/invoiceStatusBadge/InvoiceStatusBadge';
import { BillingCycleStatusBadge } from 'ui/billingCycleStatusBadge/BillingCycleStatusBadge';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { InvoiceStatusEnum } from 'api/types/BillingSummary.types';

import { WorkStatementStatusBadgeProps } from './WorkStatementStatusBadge.types';

export const WorkStatementStatusBadge = ({ workStatement, billingCycle }: WorkStatementStatusBadgeProps) => {
  const { confirmInvoiceError } = useProjectBillingSummaryData();
  const { invoice } = workStatement;
  const currentDate = new Date();

  if (invoice) {
    const isInvoiceSent = invoice.status === InvoiceStatusEnum.sent;
    const numberOfDaysAfterPaymentDate = getNumberOfCalendarDaysBetweenDates(
      currentDate,
      parseDate(invoice.paymentDate),
    );

    const isPaymentDelay = isPastDate(parseDate(invoice.paymentDate));

    const daysSuffix =
      numberOfDaysAfterPaymentDate > 1 ? (
        <Translation id={'projectDetails.workStatementForm.paymentPeriod.days'} />
      ) : (
        <Translation id={'projectDetails.invoices.day'} />
      );

    if (isInvoiceSent && isPaymentDelay) {
      return (
        <Badge
          isStatic
          color="red"
          badgeContent={
            <Stack gap={0.5} direction={'row'} alignItems={'center'}>
              <Translation id={'projectDetails.invoice.status.overdue'} />
              <Typography fontSize={13}>
                {numberOfDaysAfterPaymentDate} {daysSuffix}
              </Typography>
            </Stack>
          }
        />
      );
    }

    // If invoice is defined for work statement - show status of the invoice
    return (
      <InvoiceStatusBadge
        status={confirmInvoiceError.errorType ? InvoiceStatusEnum.error : invoice.status}
        invoice={invoice}
        billingCycleEndDate={parseDate(billingCycle.endDate)}
        currentDate={currentDate}
      />
    );
  }

  // If invoice is not defined yet for work statement show status of billing cycle
  return <BillingCycleStatusBadge status={billingCycle.status} />;
};
