import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useLocale } from '../useLocale/useLocale';
import { updateInvoiceNote } from 'api/actions/updateInvoiceNote/updateInvoiceNote';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';

export const useInvoiceNote = (invoiceId: string, note: string) => {
  const { invalidateBillingSummaryCache } = useProjectBillingSummaryData();

  const { formatMessage } = useLocale();

  return useMutation(['updateInvoiceNoteMutation'], updateInvoiceNote(invoiceId, note), {
    onSuccess: async () => {
      invalidateBillingSummaryCache();
    },
    onError: () => formatMessage({ id: AppMessages['snackbar.updateInvoiceNote.error'] }),
  });
};
