import { Box, Stack } from '@mui/material';

import { Skeleton } from 'ui/skeleton/Skeleton';
import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

import * as styles from './EmployeeCardHeader.styles';

export const EmployeeCardHeaderSkeleton = () => (
  <Box sx={styles.employeeCardHeaderWrapper}>
    <Box sx={styles.employeeCardHeaderWrapper}>
      <Box>
        <Skeleton circular width={40} height={40} />
      </Box>

      <Box sx={styles.employeeCardHeaderProfileDetails}>
        <Stack direction="row" gap={1} alignItems="center">
          <TextSkeleton width={128} variant="caption" />
        </Stack>

        <TextSkeleton width={48} variant="caption" />
      </Box>
    </Box>

    <Box sx={styles.employeeCardHeaderWrapper}>
      <Skeleton width={112} height={24} />
    </Box>
  </Box>
);
