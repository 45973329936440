import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { EditNeedParams } from './editNeedActions.types';

export const editNeedMutation: (projectId: string, needId: string) => MutationFn<EditNeedParams> =
  (projectId: string, needId: string) => (body) => ({
    endpoint: `/projects/${projectId}/update-need/${needId}`,
    method: 'PUT',
    params: body,
  });
