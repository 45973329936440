import { QueryFn } from 'hooks/useQuery/useQuery.types';

import { GetPersonAssignmentsParams, GetPersonAssignmentsResponse } from './getPersonAssignments.types';

export const getPersonAssignmentsQuery: (
  personId: string,
) => QueryFn<GetPersonAssignmentsParams, GetPersonAssignmentsResponse> = (personId: string) => (args) => ({
  endpoint: `/people/${personId}/assignments`,
  args,
});
