import { Styles } from 'lux/theme';

export const dialogHeader: Styles = {
  pb: 3,
};

export const dialogContent: Styles = {
  overflow: 'auto',
  px: 5,

  '&&&': {
    py: 0,
  },
};

const row: Styles = {
  display: 'grid',
  columnGap: 2,

  '&& > *': {
    minWidth: 'unset',
  },
};

export const seniorityRoleRow = (seniorityVisible: boolean): Styles => ({
  ...row,
  gridTemplateColumns: seniorityVisible ? '150px 1fr' : '1fr',
});

export const fteHoursRow = (hoursVisible: boolean): Styles => ({
  ...row,
  gridTemplateColumns: hoursVisible ? '1fr 140px' : '1fr',
});

export const radioWrapper = (checked: boolean): Styles => ({
  display: 'grid',
  gridTemplateColumns: '1fr 220px',
  alignItems: 'center',
  p: 1,
  border: '1px solid',
  borderColor: checked ? 'primary.main' : 'border.default',
  borderRadius: 4,

  '&& > *': {
    minWidth: 'unset',
  },
});

export const currentRate: Styles = {
  color: 'text.secondary',
  fontSize: 14,
};
