import { useNavigate } from 'react-router-dom';
import { AddIcon } from 'lux/icons';
import { Box } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useTitle } from 'hooks/useTitle/useTitle';
import { AppMessages } from 'i18n/messages';
import { PageHeader } from 'ui/pageHeader/PageHeader';
import { AppRoute } from 'routing/AppRoute.enum';

import { ProjectsGrid } from './projectsGrid/ProjectsGrid';
import * as styles from './Projects.styles';

export const Projects = () => {
  const { formatMessage } = useLocale();
  const navigate = useNavigate();

  useTitle(formatMessage({ id: AppMessages['title.projects'] }));

  return (
    <Box sx={styles.container}>
      <PageHeader
        title={formatMessage({ id: AppMessages['projects.header.title'] })}
        actionButton={{
          actionTitle: formatMessage({ id: AppMessages['dashboard.header.actionBtn.text'] }),
          icon: <AddIcon />,
          onClick: () => navigate(AppRoute.createProject, { state: { from: AppRoute.projects } }),
        }}
      />
      <ProjectsGrid />
    </Box>
  );
};
