import { Styles } from 'styles/theme';

export const customDialog: Styles = {
  '.MuiButtonBase-root:last-of-type': {
    backgroundColor: 'error.main',

    '&.Mui-disabled': {
      backgroundColor: 'surface.disabled',
    },

    '&:hover': {
      backgroundColor: 'error.dark',
    },
  },
};
