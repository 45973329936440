import { Box } from '@mui/material';

import { getArrayWithDatesInMonth, getEndOfMonthDate, getStartOfMonthDate } from 'utils/dateUtils';
import { TimelineBackgroundGrid } from '../timelineBackgroundGrid/TimelineBackgroundGrid';
import { TimelineEventsGrid } from '../timelineEventsGrid/TimelineEventsGrid';

import { TimelineContentProps } from './TimelineContent.types';
import * as styles from './TimelineContent.styles';

export const TimelineContent = ({ selectedPeriod, events, bankHolidays }: TimelineContentProps) => {
  const startOfPeriod = getStartOfMonthDate(selectedPeriod);
  const endOfPeriod = getEndOfMonthDate(selectedPeriod);
  const daysInPeriod = getArrayWithDatesInMonth(selectedPeriod);

  const gridColumnsCount = daysInPeriod.length;

  return (
    <Box sx={styles.gridsWrapper}>
      <TimelineBackgroundGrid daysInPeriod={daysInPeriod} columnsCount={gridColumnsCount} bankHolidays={bankHolidays} />
      <TimelineEventsGrid
        startOfPeriod={startOfPeriod}
        endOfPeriod={endOfPeriod}
        columnsCount={gridColumnsCount}
        events={events}
      />
    </Box>
  );
};
