import { useQueryClient } from '@tanstack/react-query';

import { useMutation } from 'hooks/useMutation/useMutation';
import { projectsKeys } from 'utils/queryKeys';
import { closeProject } from 'api/actions/closeProject/closeProject';

export const useCloseProject = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(['closeProject'], closeProject(projectId), {
    onSuccess: () => {
      queryClient.invalidateQueries(projectsKeys.projectsList);
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
    },
  });
};
