import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';
import { AssignmentInfoContextController } from 'context/assignmentInfo/assignmentInfoContextController/AssignmentInfoContextController';
import { AssignmentsFiltersContextController } from 'context/assignmentsFilters/assignmentsFiltersContextController/AssignmentsFiltersContextController';

import { ProjectOverview } from './ProjectOverview';

const initialFilters = {
  status: [AssignmentStatus.active, AssignmentStatus.leaving, AssignmentStatus.temporary, AssignmentStatus.preassigned],
};

export const ProjectOverviewContainer = () => (
  <AssignmentsFiltersContextController initialFilters={initialFilters}>
    <AssignmentInfoContextController>
      <ProjectOverview />
    </AssignmentInfoContextController>
  </AssignmentsFiltersContextController>
);
