import { useMemo } from 'react';

import { Grid } from 'lux/components';

import {
  areTimeIntervalsOverlapping,
  getDayOfTheMonth,
  getEndOfDayDate,
  isDateAfter,
  isDateBefore,
} from 'utils/dateUtils';
import { TIMELINE_DAY_WIDTH } from '../PersonTimeline.utils';

import { TimelineEvent } from './timelineEvent/TimelineEvent';
import { TimelineEventsGridProps } from './TimelineEventsGrid.types';
import * as styles from './TimelineEventsGrid.styles';
import { TimelineEventType } from './timelineEvent/TimelineEvent.types';

export const TimelineEventsGrid = ({ startOfPeriod, endOfPeriod, columnsCount, events }: TimelineEventsGridProps) => {
  const eventsInPeriod = useMemo(
    () =>
      events.filter(({ startDate, endDate }) => {
        if (!endDate) {
          return isDateBefore(startDate, endOfPeriod);
        }

        if (isDateBefore(endDate, startDate)) {
          return false;
        }

        return areTimeIntervalsOverlapping(
          {
            start: startOfPeriod,
            end: endOfPeriod,
          },
          {
            start: startDate,
            end: getEndOfDayDate(endDate),
          },
        );
      }),
    [events, startOfPeriod, endOfPeriod],
  );

  const rowsCount = eventsInPeriod.filter(({ type }) => type !== TimelineEventType.absence).length;
  let currentRow = 1;

  return (
    <Grid
      container
      columns={columnsCount}
      display="grid"
      gridTemplateColumns={`repeat(${columnsCount}, minmax(${TIMELINE_DAY_WIDTH}px, 1fr))`}
      gridTemplateRows={`repeat(${rowsCount}, auto) 1fr`}
      spacing={0}
      rowSpacing={0.25}
      sx={styles.eventsGrid}
      gutter={0}
    >
      {eventsInPeriod.map((event) => {
        const { id, type, startDate, endDate } = event;

        const eventStartsBeforePeriod = isDateBefore(startDate, startOfPeriod);
        const eventEndsAfterPeriod = !endDate || isDateAfter(endDate, endOfPeriod);

        const columnStart = eventStartsBeforePeriod ? 1 : getDayOfTheMonth(startDate);
        const columnEnd = eventEndsAfterPeriod ? columnsCount : getDayOfTheMonth(endDate);
        const gridColumn = `${columnStart} / ${columnEnd + 1}`;

        if (type === TimelineEventType.absence) {
          return (
            <Grid item gridRow="1/-1" gridColumn={gridColumn} key={id}>
              <TimelineEvent
                eventStartsBeforePeriod={eventStartsBeforePeriod}
                eventEndsAfterPeriod={eventEndsAfterPeriod}
                columnCount={columnEnd - columnStart}
                rowsCount={rowsCount}
                {...event}
              />
            </Grid>
          );
        }

        return (
          <Grid item gridRow={currentRow++} gridColumn={gridColumn} key={id}>
            <TimelineEvent
              eventStartsBeforePeriod={eventStartsBeforePeriod}
              eventEndsAfterPeriod={eventEndsAfterPeriod}
              columnCount={columnEnd - columnStart}
              rowsCount={rowsCount}
              {...event}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
