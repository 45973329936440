import { Box } from '@mui/material';
import { Button, Tooltip } from 'lux/components';
import { ChevronLeftIcon } from 'lux/icons';

import { Translation } from '../../../../../../../../../../ui/translation/Translation';

import type { RejectedProposalButtonsProps } from './RejectedProposalButtons.types';

export const RejectedProposalButtons = ({ onUndo, disabled }: RejectedProposalButtonsProps) => (
  <Box>
    <Tooltip arrow placement="top" title={disabled ? <Translation id="button.undoRejection.tooltip" /> : null}>
      <Box>
        <Button
          disabled={disabled}
          variant="text"
          onClick={onUndo}
          color="secondary"
          size="small"
          startIcon={<ChevronLeftIcon />}
        >
          <Translation id="button.undoRejection" />
        </Button>
      </Box>
    </Tooltip>
  </Box>
);
