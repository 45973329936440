import { Styles } from 'styles/theme';

export const ACTIONS_WRAPPER_WIDTH = 104;
export const SLIDE_IN_ANIMATION_DURATION = 300;
const MARGIN = 24;

export const rowWrapper: Styles = {
  flexDirection: 'row',
  minHeight: 64,
  px: 2,
  py: 1,
  borderRadius: 2,
  backgroundColor: 'surface.paper.default',
  transition: 'background-color 0.2s',
  overflow: 'hidden',
  position: 'relative',
  '&&:hover': {
    backgroundColor: 'primary.light',
  },
};
export const contentWrapper = (isShrunk = false): Styles => ({
  width: isShrunk ? `calc(100% - ${ACTIONS_WRAPPER_WIDTH + MARGIN}px)` : '100%',
  transition: `width ease-in-out ${SLIDE_IN_ANIMATION_DURATION}ms`,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const actionsWrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: 0,
  width: ACTIONS_WRAPPER_WIDTH,
  position: 'absolute',
  right: 16,
};
