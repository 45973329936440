import Joi from 'joi';

import { Seniority } from 'api/types/Seniority.enum';
import { AppMessages } from 'i18n/messages';
import { intl } from 'context/locale/intl/Intl';
import { MAX_SUPPORTED_DATE, ZERO_UNIX_DATE, formatDate, parseDate } from 'utils/dateUtils';

export const assignWithoutNeedSchema = (projectStartDate?: string, projectEndDate?: string) => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object().keys({
    role: Joi.string().required(),
    seniority: Joi.string()
      .valid(...Object.values(Seniority))
      .required(),
    fte: Joi.alternatives()
      .try(Joi.number(), Joi.string())
      .required()
      .messages({
        'any.required': formatMessage(
          { id: AppMessages['validation.required'] },
          { label: formatMessage({ id: AppMessages['assignWithoutNeed.formModal.fte'] }) },
        ),
      }),
    customFte: Joi.when('fte', {
      is: 'other',
      then: Joi.number().min(1).max(40).required(),
      otherwise: Joi.number().allow('').optional().strip(),
    }).messages({
      'any.required': formatMessage(
        { id: AppMessages['validation.required'] },
        { label: formatMessage({ id: AppMessages['assignWithoutNeed.formModal.customFte'] }) },
      ),
      'number.base': formatMessage(
        { id: AppMessages['validation.number'] },
        { label: formatMessage({ id: AppMessages['assignWithoutNeed.formModal.customFte'] }) },
      ),
      'number.min': formatMessage(
        { id: AppMessages['validation.min'] },
        { limit: 1, label: formatMessage({ id: AppMessages['assignWithoutNeed.formModal.customFte'] }) },
      ),
      'number.max': formatMessage(
        { id: AppMessages['validation.max'] },
        { limit: 40, label: formatMessage({ id: AppMessages['assignWithoutNeed.formModal.customFte'] }) },
      ),
    }),
    startDate: Joi.date()
      .required()
      .min(projectStartDate ? parseDate(projectStartDate) : ZERO_UNIX_DATE)
      .max(MAX_SUPPORTED_DATE)
      .messages({
        'date.base': formatMessage(
          { id: AppMessages['validation.date'] },
          { label: formatMessage({ id: AppMessages['assignWithoutNeed.formModal.assignFrom'] }) },
        ),
        'date.min': formatMessage(
          {
            id: AppMessages['validation.greater'],
          },

          {
            label: formatMessage({ id: AppMessages['assignWithoutNeed.formModal.assignFrom'] }),
            limit: projectStartDate || formatDate(ZERO_UNIX_DATE),
          },
        ),
        'date.max': formatMessage(
          {
            id: AppMessages['validation.max'],
          },

          {
            label: formatMessage({ id: AppMessages['assignWithoutNeed.formModal.assignFrom'] }),
            limit: formatDate(MAX_SUPPORTED_DATE),
          },
        ),
      }),
    endDate: Joi.date()
      .min(Joi.ref('startDate'))
      .max(projectEndDate ? parseDate(projectEndDate) : MAX_SUPPORTED_DATE)
      .allow(null)
      .optional()
      .messages({
        'date.base': formatMessage(
          { id: AppMessages['validation.date'] },
          { label: formatMessage({ id: AppMessages['assignWithoutNeed.formModal.assignTo'] }) },
        ),
        'date.min': formatMessage(
          {
            id: AppMessages['validation.min'],
          },
          {
            label: formatMessage({ id: AppMessages['assignWithoutNeed.formModal.assignTo'] }),
            limit: formatMessage({ id: AppMessages['assignWithoutNeed.formModal.assignFrom'] }),
          },
        ),
        'date.max': formatMessage(
          {
            id: AppMessages['validation.max'],
          },
          {
            label: formatMessage({ id: AppMessages['assignWithoutNeed.formModal.assignTo'] }),
            limit: projectEndDate ?? formatDate(MAX_SUPPORTED_DATE),
          },
        ),
      }),
    temporary: Joi.boolean(),
    customRateEnabled: Joi.boolean(),
    customRate: Joi.when('customRateEnabled', {
      is: true,
      then: Joi.number().required().min(0),
      otherwise: Joi.number().allow('').optional(),
    }).messages({
      'number.base': formatMessage(
        { id: AppMessages['validation.number'] },
        { label: formatMessage({ id: AppMessages['assignWithoutNeed.formModal.customRate'] }) },
      ),
      'number.min': formatMessage(
        { id: AppMessages['validation.min'] },
        {
          label: formatMessage({ id: AppMessages['assignWithoutNeed.formModal.customRate'] }),
          limit: 0,
        },
      ),
    }),
  });
};
