import { useEffect, useState } from 'react';

import { Box, Divider } from '@mui/material';
import { Typography } from 'lux/components';

import { ProjectBillingSummaryContextController } from 'context/projectBillingSummary/projectBillingSummaryContextController/ProjectBillingSummaryContextController';
import { ProjectBillingSummaryDataContextController } from 'context/projectBillingSummary/projectBillingSummaryDataContextController/ProjectBillingSummaryDataContextController';
import { ProjectDetailsContextController } from 'context/projectDetails/projectDetailsContextController/ProjectDetailsContextController';
import { ProjectDiscrepancyContextController } from 'context/projectDiscrepancy/projectDiscrepancyContextController/ProjectDiscrepancyContextController';
import { Loader } from 'ui/loader/Loader';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useClient } from 'hooks/useClient/useClient';
import { AppMessages } from 'i18n/messages';
import { BillingSummaryGroupBy } from 'api/types/BillingSummary.types';
import { EmptyState } from 'ui/emptyState/EmptyState';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { useFormatCurrency } from 'hooks/useFormatCurrency/useFormatCurrency';
import { CardWrapper } from 'ui/cardWrapper/CardWrapper';

import { TotalAmountOfProjects } from './clientBillingSummaryAccordionItem/ClientBillingSummaryAccordionItem.types';
import { ClientBillingSummaryAccordionItem } from './clientBillingSummaryAccordionItem/ClientBillingSummaryAccordionItem';
import * as styles from './ClientBillingSummary.styles';

export const ClientBillingSummary = () => {
  const [firstBIMonthStartDate, setFirstBIMonthStartDate] = useState(new Date());
  const [groupByAllBI, setGroupByAllBI] = useState(BillingSummaryGroupBy.MEMBERS);
  const [totalAmountOfBI, setTotalAmountOfBI] = useState<TotalAmountOfProjects[]>([]);
  const [loading, setIsLoading] = useState<Record<number, boolean> | null>(null);
  const [bINumberToShowNav, setBINumberToShowNav] = useState(0);
  const totalAmountDivisor = 100;

  const { clientDetails, isLoading: isClientLoading } = useClient();
  const formatCurrency = useFormatCurrency(clientDetails?.client.projects[0]?.currency ?? 'USD');
  const { formatMessage } = useLocale();
  const { t } = useLocale();

  const handleLoading = (id: number, loading: boolean) => {
    setIsLoading((prev) => (prev ? { ...prev, ...{ [id]: loading } } : { [id]: loading }));
  };

  useEffect(() => {
    setTotalAmountOfBI([]);
  }, [firstBIMonthStartDate]);

  if (isClientLoading) {
    return (
      <CardWrapper>
        <Loader />;
      </CardWrapper>
    );
  }

  const activeProjects = clientDetails
    ? clientDetails.client.projects.filter(({ stage }) => [ProjectStage.ongoing, ProjectStage.closing].includes(stage))
    : [];

  const getTotalAmount = formatCurrency(
    totalAmountOfBI.reduce((acc, currentValue) => acc + currentValue.amount, 0) / totalAmountDivisor,
  );

  const isLoading = Object.values(loading ?? []).some((el) => el);

  return (
    <CardWrapper>
      {activeProjects?.map((project, index) => (
        <ProjectDetailsContextController key={project.id} projectId={project.id}>
          <ProjectDiscrepancyContextController projectId={project.id}>
            <ProjectBillingSummaryContextController>
              <ProjectBillingSummaryDataContextController>
                <ClientBillingSummaryAccordionItem
                  bINumber={index}
                  firstBIMonthStartDate={firstBIMonthStartDate}
                  setFirstBIMonthStartDate={setFirstBIMonthStartDate}
                  bINumberToShowNav={bINumberToShowNav}
                  setBINumberToShowNav={setBINumberToShowNav}
                  groupByAllBI={groupByAllBI}
                  setLoading={handleLoading}
                  setGroupByAllBI={setGroupByAllBI}
                  setTotalAmountOfBI={setTotalAmountOfBI}
                  totalAmountOfBI={totalAmountOfBI}
                />
              </ProjectBillingSummaryDataContextController>
            </ProjectBillingSummaryContextController>
          </ProjectDiscrepancyContextController>
        </ProjectDetailsContextController>
      ))}
      <Divider variant="middle" />
      {totalAmountOfBI.length !== 0 && (
        <Box sx={styles.totalClientMoneyAmountContainer}>
          <Typography variant="h6" sx={styles.totalClientMoneyAmount}>
            {formatMessage(
              { id: AppMessages['client.billing.information.total.amount'] },
              {
                client: clientDetails?.client.name,
              },
            )}
          </Typography>
          <Typography variant="h6" sx={styles.amount}>
            {getTotalAmount}
          </Typography>
        </Box>
      )}

      {!isLoading && totalAmountOfBI.length === 0 && (
        <Box mt={10}>
          <EmptyState
            illustration="emptyBillingInfo"
            title={t('client.billing.information.not.found.title')}
            message={''}
          />
        </Box>
      )}
    </CardWrapper>
  );
};
