import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useMutation } from 'hooks/useMutation/useMutation';
import { assignmentsKeys, projectsKeys } from 'utils/queryKeys';
import { getApiError } from 'api/utils/getApiError';
import { cancelEndDateExtension } from 'api/actions/cancelEndDateExtension/cancelEndDateExtension';

export const useCancelEndDateExtension = (projectId: string) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocale();

  return useMutation(['CancelEndDateExtensionMutation'], cancelEndDateExtension(projectId), {
    onSuccess: () => {
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(assignmentsKeys.assignmentsList);
      enqueueSnackbar(t('assignment.teamMember.extension.cancel.snackbar.success'));
    },
    onError: (error) => {
      enqueueSnackbar(getApiError(error));
    },
  });
};
