import { joiResolver } from '@hookform/resolvers/joi';
import { InlineEdit } from 'lux/components';
import { FieldValues } from 'react-hook-form';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { Select } from 'ui/form/select/Select';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useEditMode } from 'hooks/useEditMode/useEditMode';

import { SelectEditProps } from './SelectEdit.types';
import { selectEditSchema } from './SelectEdit.schema';
import { SelectedValue } from './selectedValue/SelectedValue';

export const SelectEdit = <T extends FieldValues>(props: SelectEditProps<T>) => {
  const {
    name,
    label,
    value,
    defaultValues,
    onSubmit,
    options,
    renderBadge,
    loading,
    disabled,
    renderValue,
    selectComponentSx,
  } = props;
  const { formatMessage } = useLocale();
  const { isEditMode, setOpenEditMode, setCloseEditMode, onFormSubmit } = useEditMode(onSubmit, disabled);
  const isMultiple = Array.isArray(value);

  const { control, handleSubmit } = useIntlForm({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(selectEditSchema(name, isMultiple)),
  });

  return (
    <InlineEdit
      header={formatMessage({ id: AppMessages[label] })}
      isActive={isEditMode}
      onDataDisplayClick={setOpenEditMode}
      onCancel={setCloseEditMode}
      onSubmit={handleSubmit(onFormSubmit)}
      loading={loading}
      disabled={disabled}
      input={
        <Select
          name={name}
          size="small"
          control={control}
          label={formatMessage({ id: AppMessages[label] })}
          options={options}
          isOpen={isEditMode}
          multiple={isMultiple}
          autoFocus
          fullWidth
          renderValue={renderValue}
          sx={selectComponentSx}
        />
      }
    >
      <SelectedValue value={value} renderBadge={renderBadge} />
    </InlineEdit>
  );
};
