import { Box, Typography } from '@mui/material';
import { CalendarIcon } from 'lux/icons';
import { Button } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useConfirmEndDateExtension } from 'hooks/useConfirmEndDateExtension/useConfirmEndDateExtension';
import { useCancelEndDateExtension } from 'hooks/useCancelEndDateExtension/useCancelEndDateExtension';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';

import * as styles from './AssignmentExtensionDetails.styles';
import { AssignmentExtensionDetailsProps } from './AssignmentExtensionDetails.types';

export const AssignmentExtensionDetails = ({ date, assignmentId }: AssignmentExtensionDetailsProps) => {
  const { t } = useLocale();
  const {
    projectDetails: { id },
  } = useProjectDetails();
  const { mutateAsync: muteAsyncConfirmEndDate, isLoading: isConfirmEndDateLoading } = useConfirmEndDateExtension(id);
  const { mutateAsync: muteAsyncCancelEndDate, isLoading: isCancelEndDateLoading } = useCancelEndDateExtension(id);

  const onExtensionCancel = () => muteAsyncCancelEndDate({ assignmentIds: [assignmentId] });
  const onExtensionConfirm = () => muteAsyncConfirmEndDate({ assignmentIds: [assignmentId] });

  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.infoWrapper}>
          <Box sx={styles.iconContainer}>
            <CalendarIcon color="info" fill="info" />
          </Box>
          <Box>
            <Typography sx={styles.title}>{t('projectDetails.teamDetails.extension.possible')}</Typography>
            <Typography fontSize={13}>{date}</Typography>
          </Box>
        </Box>
        <Box sx={styles.buttonContainer}>
          <Button variant="outlined" type="button" loading={isCancelEndDateLoading} onClick={onExtensionCancel}>
            {t('projectDetails.teamDetails.extension.cancelButton')}
          </Button>
          <Button
            color="info"
            type="submit"
            variant="contained"
            loading={isConfirmEndDateLoading}
            onClick={onExtensionConfirm}
          >
            {t('projectDetails.teamDetails.extension.extendButton')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
