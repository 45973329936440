import { useCallback } from 'react';

import { Box, IconButton, Stack } from '@mui/material';
import { InlineEdit, Typography } from 'lux/components';
import { joiResolver } from '@hookform/resolvers/joi';
import { EditIcon } from 'lux/icons';

import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useUpdateProject } from 'hooks/useUpdateProject/useUpdateProject';
import { ProjectStageBadge } from 'ui/projectStageBadge/ProjectStageBadge';
import { useEditMode } from 'hooks/useEditMode/useEditMode';
import { HubspotBadge } from 'ui/hubspotBadge/HubspotBadge';
import { useProjects } from 'hooks/useProjects/useProjects';

import { ProjectNameProps, UpdateProjectNameFormType } from './ProjectName.types';
import * as styles from './ProjectName.styles';
import { updateProjectNameSchema } from './ProjectName.schema';

export const ProjectName = ({ id, name, stage, allowEdition, isLeadFromHubSpot, clientId }: ProjectNameProps) => {
  const { formatMessage } = useLocale();
  const { mutateAsync, isLoading } = useUpdateProject(id);

  const { control, handleSubmit, watch, setError, clearErrors, formState } = useIntlForm<UpdateProjectNameFormType>({
    defaultValues: {
      name,
      clientId,
    },
    mode: 'all',
    resolver: joiResolver(updateProjectNameSchema()),
  });

  const onSubmit = async ({ name, clientId }: UpdateProjectNameFormType) => {
    await mutateAsync({ name, clientId });
  };

  const { isEditMode, onFormSubmit, setCloseEditMode, setOpenEditMode } = useEditMode(onSubmit);

  const projectName = watch('name').toLowerCase().trim();
  const { refetch, isInitialLoading: isProjectsLoading } = useProjects(
    { search: projectName.trim(), size: 5 },
    { enabled: false },
  );

  const checkProjectName = useCallback(async () => {
    if (!projectName) return;

    const response = await refetch();
    const data = response.data?.pages[0].projects
      .filter((project) => project.id !== id)
      .map((project) => ({
        projectName: project.name,
        clientId: project.client.id,
      }));

    if (data?.some((project) => project.projectName.toLowerCase() === projectName && project.clientId === clientId)) {
      setError('name', {
        message: formatMessage({ id: AppMessages['projectDetails.projectName.error.exists'] }),
      });
    } else {
      clearErrors('name');
    }
  }, [projectName]);

  const handleProjectNameSubmit = async () => {
    await checkProjectName();

    if (!formState.errors.name) {
      const submit = handleSubmit(onFormSubmit);
      await submit();
    }
  };

  const handleCancel = () => {
    setCloseEditMode();
    control._formValues['name'] = name;
    clearErrors('name');
  };

  return (
    <Box maxWidth={isEditMode ? 296 : undefined}>
      <InlineEdit
        disabled={!allowEdition || isProjectsLoading}
        onSubmit={async () => {
          await handleProjectNameSubmit();
        }}
        isActive={isEditMode}
        onCancel={handleCancel}
        onDataDisplayClick={setOpenEditMode}
        loading={isLoading || isProjectsLoading}
        submitDisabled={!!formState.errors.name}
        input={
          <FormTextField
            name="name"
            label={formatMessage({ id: AppMessages['projectDetails.projectName'] })}
            fullWidth
            autoFocus
            size="small"
            control={control}
          />
        }
        renderDataDisplay={(params) => (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h4">{name}</Typography>
            {allowEdition && (
              <IconButton sx={styles.editButton} {...params}>
                <EditIcon />
              </IconButton>
            )}
            <ProjectStageBadge stage={stage} />
            {isLeadFromHubSpot && (
              <HubspotBadge
                title={formatMessage({ id: AppMessages['projects.projectCard.badge.hubspot.deal.title'] })}
                tooltipDescription={formatMessage({
                  id: AppMessages['projects.projectCard.badge.hubspot.deal.tooltip'],
                })}
              />
            )}
          </Stack>
        )}
      />
    </Box>
  );
};
