import Joi from 'joi';

import { getCurrentDayStartDate, formatDate, ZERO_UNIX_DATE } from 'utils/dateUtils';
import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';

export const removeEmployeesSchema = (minDate: Date) => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object({
    removalDate: Joi.when('removePermanently', {
      is: true,
      then: Joi.date().allow(null).optional(),
      otherwise: Joi.date()
        .min(minDate ?? ZERO_UNIX_DATE)
        .max(getCurrentDayStartDate()),
    })
      .optional()
      .messages({
        'date.base': formatMessage(
          { id: AppMessages['validation.date'] },
          { label: formatMessage({ id: AppMessages['projectDetails.removeEmployeesModal.removalDate.label'] }) },
        ),
        'date.min': formatMessage(
          {
            id: AppMessages['validation.greater'],
          },
          {
            label: formatMessage({ id: AppMessages['projectDetails.removeEmployeesModal.removalDate.label'] }),
            limit: formatDate(minDate ?? ZERO_UNIX_DATE),
          },
        ),
        'date.max': formatMessage(
          { id: AppMessages['validation.max'] },
          {
            label: formatMessage({ id: AppMessages['projectDetails.removeEmployeesModal.removalDate.label'] }),
            limit: formatDate(getCurrentDayStartDate()),
          },
        ),
      }),
    removePermanently: Joi.boolean(),
    includeInRejected: Joi.boolean(),
    removalReason: Joi.when('includeInRejected', {
      is: true,
      then: Joi.string().required(),
      otherwise: Joi.string().allow('').optional(),
    }).messages({
      'string.empty': formatMessage(
        { id: AppMessages['validation.empty'] },
        { label: formatMessage({ id: AppMessages['projectDetails.removeEmployeesModal.removalReason.label'] }) },
      ),
    }),
  });
};
