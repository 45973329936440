import { useEffect, useState } from 'react';

import {
  MAX_SUPPORTED_DATE,
  ZERO_UNIX_DATE,
  formatDate,
  isDateAfter,
  isDateBefore,
  isValidDate,
  parseDate,
} from 'utils/dateUtils';

import { UseTimeRangeParams, TimeRangeKeys, NullableDate } from './useTimeRange.types';

const FILTER_DEBOUNCE_DELAY = 500;

export const useTimeRange = ({ filters, setFilter, clearFilter }: UseTimeRangeParams) => {
  const [errors, setErrors] = useState<{ startDate: boolean; endDate: boolean }>({
    startDate: false,
    endDate: false,
  });

  const [internalValues, setInternalValues] = useState<{ startDate: NullableDate; endDate: NullableDate }>({
    startDate: filters.startDate ? parseDate(filters.startDate) : null,
    endDate: filters.endDate ? parseDate(filters.endDate) : null,
  });

  useEffect(() => {
    if (!filters.startDate) {
      clearInternalValue('startDate');
    }
    if (!filters.endDate) {
      clearInternalValue('endDate');
    }
  }, [filters.startDate, filters.endDate]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      (Object.entries(internalValues) as Array<[TimeRangeKeys, NullableDate]>).forEach(([key, value]) => {
        const formattedDate = value && isValidDate(value) ? formatDate(value) : '';

        if (formattedDate === filters[key] || errors[key]) return;
        setFilter(key, formattedDate);
      });
    }, FILTER_DEBOUNCE_DELAY);

    return () => clearTimeout(timeoutId);
  }, [internalValues.startDate, internalValues.endDate]);

  const clearInternalValue = (key: TimeRangeKeys) => setInternalValues((prev) => ({ ...prev, [key]: null }));

  const handleDateChange = (newValue: NullableDate, field: TimeRangeKeys) => {
    setInternalValues((prev) => ({ ...prev, [field]: newValue }));

    if (!newValue) {
      clearFilter(field);
      setErrors((prev) => ({ ...prev, [field]: false }));
      return;
    }

    if (!isValidDate(newValue) || isDateAfter(newValue, MAX_SUPPORTED_DATE) || isDateBefore(newValue, ZERO_UNIX_DATE)) {
      setErrors((prev) => ({ ...prev, [field]: true }));
      return;
    }

    if (field === 'startDate' && newValue.getTime() > parseDate(filters.endDate).getTime()) {
      setErrors((prev) => ({ ...prev, [field]: true }));
      return;
    }

    if (field === 'endDate' && newValue.getTime() < parseDate(filters.startDate).getTime()) {
      setErrors((prev) => ({ ...prev, [field]: true }));
      return;
    }

    setErrors((prev) => ({ ...prev, [field]: false }));
  };

  const clear = () => {
    clearFilter('startDate');
    clearFilter('endDate');
    setErrors({ startDate: false, endDate: false });
  };

  return { errors, onDateChange: handleDateChange, values: internalValues, clear };
};
