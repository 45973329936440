import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { Grid } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Checkbox } from 'ui/form/checkbox/Checkbox';
import { Loader } from 'ui/loader/Loader';
import { Select } from 'ui/form/select/Select';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { mapToSelectOptions } from 'ui/form/select/Select.mappers';
import { UncertainEmploymentType, employmentTypeList } from 'config/data/people/uncertain';
import { fteList, Fte } from 'config/data/fteList';
import { seniorityList } from 'config/data/seniorityList';
import { Currency } from 'api/types/Currency.enum';
import { DepartmentCode, InvisibleDepartmentCode } from 'api/types/DepartmentCode.enum';
import { createDepartmentLabel } from 'config/data/departments';
import { moneyInputNumberParser } from 'utils/createInputNumberChangeParser';

import { UncertainPersonFormValues, UncertainPersonFormProps } from './UncertainPersonForm.types';

export const UncertainPersonForm = ({ operation, loading }: UncertainPersonFormProps) => {
  const { formatMessage } = useLocale();
  const { watch, control } = useFormContext<UncertainPersonFormValues>();

  if (loading) {
    return <Loader fullHeight={false} />;
  }

  const [employmentType, fte] = watch(['employmentType', 'fte']);

  const invisibleDepartments = Object.values(InvisibleDepartmentCode) as string[];

  const departments = Object.values(DepartmentCode)
    .filter((department) => !invisibleDepartments.includes(department))
    .filter((department) => department !== DepartmentCode.others)
    .map((department, i) => ({
      id: String(i),
      children: createDepartmentLabel(department),
      value: department,
    }))
    .sort((a, b) => a.children.localeCompare(b.children));

  return (
    <Grid container gutter={0}>
      <Grid item xs={6}>
        <FormTextField
          name="firstName"
          control={control}
          label={formatMessage({ id: AppMessages['uncertainPerson.form.firstName.label'] })}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          name="lastName"
          control={control}
          label={formatMessage({ id: AppMessages['uncertainPerson.form.lastName.label'] })}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          name="email"
          control={control}
          label={formatMessage({ id: AppMessages['uncertainPerson.form.email.label'] })}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Select
          name="departmentCode"
          control={control}
          label={formatMessage({ id: AppMessages['uncertainPerson.form.departmentCode.label'] })}
          options={departments}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Stack alignItems="center" direction="row">
          <Checkbox control={control} name="seniorityEnabled" size="medium" />
          <Select
            name="seniority"
            control={control}
            label={formatMessage({ id: AppMessages['uncertainPerson.form.seniority.label'] })}
            options={seniorityList()}
            isDisabled={!watch('seniorityEnabled')}
            fullWidth
          />
        </Stack>
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Select
          name="fte"
          control={control}
          label={formatMessage({ id: AppMessages['uncertainPerson.form.fte.label'] })}
          options={fteList()}
          fullWidth
        />
      </Grid>
      {fte === Fte.other && (
        <>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <FormTextField
              name="fteCustom"
              control={control}
              label={formatMessage({ id: AppMessages['uncertainPerson.form.fteCustom.label'] })}
              type="number"
              min={1}
              max={40}
              fullWidth
            />
          </Grid>
        </>
      )}
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Select
          name="employmentType"
          control={control}
          label={formatMessage({ id: AppMessages['uncertainPerson.form.employmentType.label'] })}
          options={employmentTypeList()}
          isDisabled={operation !== 'add'}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Select
          name="currency"
          control={control}
          label={formatMessage({ id: AppMessages['uncertainPerson.form.currency.label'] })}
          options={mapToSelectOptions(Object.values(Currency))}
          isDisabled={employmentType !== UncertainEmploymentType.subcontractor}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          name="rate"
          control={control}
          label={formatMessage({ id: AppMessages['uncertainPerson.form.rate.label'] })}
          isDisabled={employmentType !== UncertainEmploymentType.subcontractor}
          fullWidth
          onChangeParser={moneyInputNumberParser}
        />
      </Grid>
    </Grid>
  );
};
