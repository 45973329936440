import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { DeleteNeedParams } from './deleteNeedActions.types';

export const deleteNeedMutation: (projectId: string) => MutationFn<DeleteNeedParams> =
  (projectId: string) =>
  ({ needId }) => ({
    endpoint: `/projects/${projectId}/delete-need/${needId}`,
    method: 'DELETE',
    params: { needId },
  });
