import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { peopleKeys } from 'utils/queryKeys';
import { modifyPersonRateMutation } from 'api/actions/modifyPersonRate/modifyPersonRateActions';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';

export const useModifyPersonRate = (personId: number) => {
  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutationDomainError(['modifyRateMutation'], modifyPersonRateMutation(personId), {
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.modifyPersonRate.success'] }));
      queryClient.invalidateQueries(peopleKeys.singlePerson(String(personId)));
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.modifyPersonRate.error'] }),
  });
};
