import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ScrollRestoration } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import { LocaleContextController } from 'context/locale/localeContextController/LocaleContextController';
import { ApiClientContextController } from 'context/apiClient/apiClientContextController/ApiClientContextController';
import { AuthContextController } from 'context/auth/authContextController/AuthContextController';
import { ConfettiContextController } from 'context/confetti/confettiContextController/ConfettiContextController';
import { UnsavedChangesDialogContextController } from 'context/modalUnsavedChanges/UnsavedChangesDialogContextController/UnsavedChangesDialogContextController';
import { ApiVersionContextController } from 'context/apiVersion/apiVersionContextController/ApiVersionContextController';
import { UnsavedChangesDialog } from 'ui/UnsavedChangesDialog/UnsavedChangesDialog';
import { MSAL_CONFIGURATION } from '../config/msal/msalConfig';

import { AppProvidersProps } from './AppProviders.types';
import { SnackbarProvider } from './snackbar/SnackbarProvider';

const openReactQueryDevtools = process.env.NODE_ENV === 'development';
const msalPca = new PublicClientApplication(MSAL_CONFIGURATION);

export const AppProviders = ({ children }: AppProvidersProps) => (
  <MsalProvider instance={msalPca}>
    <LocaleContextController>
      <AuthContextController>
        <SnackbarProvider>
          <ApiClientContextController>
            <ConfettiContextController>
              <UnsavedChangesDialogContextController>
                <ApiVersionContextController>
                  <ScrollRestoration
                    getKey={(location) => {
                      if (location.state?.previousKey) {
                        return location.state.previousKey;
                      }
                      return location.key;
                    }}
                  />
                  {children}
                  {openReactQueryDevtools && <ReactQueryDevtools initialIsOpen={false} />}
                </ApiVersionContextController>
                <UnsavedChangesDialog />
              </UnsavedChangesDialogContextController>
            </ConfettiContextController>
          </ApiClientContextController>
        </SnackbarProvider>
      </AuthContextController>
    </LocaleContextController>
  </MsalProvider>
);
