import { useQuery } from 'hooks/useQuery/useQuery';
import { clientsKeys } from 'utils/queryKeys';
import { useAuth } from 'hooks/useAuth/useAuth';
import { getClientInvoices } from 'api/actions/getClientInvoices/getClientInvoices';
import {
  GetClientInvoicesParams,
  GetClientInvoicesResponse,
} from 'api/actions/getClientInvoices/getClientInvoices.types';

export const useClientInvoices = (clientId?: string) => {
  const { isAuthenticated } = useAuth();
  const queryKey = clientsKeys.clientInvoices(clientId);

  return useQuery<GetClientInvoicesParams, GetClientInvoicesResponse>(queryKey, getClientInvoices(clientId), {
    enabled: isAuthenticated && !!clientId,
    refetchOnWindowFocus: false,
  });
};
