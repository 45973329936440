import { Typography, Button } from 'lux/components';
import { ChevronDownIcon, ChevronUpIcon } from 'lux/icons';

import { AppMessages } from 'i18n/messages';
import { useAvailability } from 'hooks/useAvailability/useAvailability';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useAvailabilityFilters } from 'hooks/useAvailabilityFilters/useAvailabilityFilters';
import { AvailabilityContentSkeleton } from 'app/dashboard/dashboardGrid/availability/availabilityContent/AvailabilityContent.skeleton';

import { AvailabilityFilters } from './availabilityFilters/AvailabilityFilters';
import { AvailabilityTable } from './availabilityTable/AvailabilityTable';
import * as styles from './AvailabilityContent.styles';

export const AvailabilityContent = () => {
  const { formatMessage } = useLocale();

  const { data, isLoading } = useAvailability();
  const { filters, setFilterValue } = useAvailabilityFilters();

  if (isLoading) {
    return <AvailabilityContentSkeleton filters={filters} />;
  }

  if (!data) {
    return <Typography variant="subtitle2">{formatMessage({ id: AppMessages['data.notFound'] })}</Typography>;
  }

  const handleToggle = () => {
    setFilterValue('isAvailabilityExpanded', !filters.isAvailabilityExpanded);
  };

  return (
    <>
      <AvailabilityFilters />
      <AvailabilityTable filters={filters} data={data} />
      <Button
        onClick={handleToggle}
        variant="text"
        color="primary"
        size="small"
        endIcon={filters.isAvailabilityExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
        sx={styles.showMoreButton}
        data-cy="availability-content_btn-less-more"
      >
        {filters.isAvailabilityExpanded
          ? formatMessage({ id: AppMessages['dashboard.availability.showLess'] })
          : formatMessage({ id: AppMessages['dashboard.availability.showMore'] })}
      </Button>
    </>
  );
};
