import Joi from 'joi';

import { AppMessages } from 'i18n/messages';
import { intl } from 'context/locale/intl/Intl';
import { RateCardFormItem } from '../rateCardItem/RateCardItem.types';
import { StandardDepartmentCode } from '../../../api/types/DepartmentCode.enum';

type RateKey = 'juniorRate' | 'regularRate' | 'seniorRate';

const minRateValidation = Joi.ref('role', {
  adjust: (value) => Number(value !== StandardDepartmentCode.PM),
});

const emptyRateValidation = (key1: RateKey, key2: RateKey) => ({
  is: Joi.object({ [key1]: Joi.number().equal(''), [key2]: Joi.number().equal('') }).unknown(),
  otherwise: Joi.number().allow(''),
});

export const rateCardSchema = () => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object({
    role: Joi.string()
      .required()
      .custom((value, helpers) => {
        if (helpers.state.path) {
          const currentIndex = helpers.state.path[1];
          const otherRateCards = helpers.state.ancestors[1].filter(
            (rateCard: RateCardFormItem, index: number) => index !== Number(currentIndex),
          );
          if (otherRateCards.some(({ role }: RateCardFormItem) => role === value)) {
            return helpers.error('notUnique');
          }
        }

        return value;
      })
      .messages({
        'string.empty': formatMessage(
          {
            id: AppMessages['validation.empty'],
          },
          { label: formatMessage({ id: AppMessages['rateCard.form.role.label'] }) },
        ),
        notUnique: formatMessage({
          id: AppMessages['rateCard.form.role.validation.role.notUnique'],
        }),
      }),
    seniorityEnabled: Joi.boolean().required(),
  }).when(Joi.object({ seniorityEnabled: true }).unknown(), {
    then: Joi.object({
      withoutLevelRate: Joi.number().optional().allow(''),
      juniorRate: Joi.number()
        .min(minRateValidation)
        .precision(2)
        .label(formatMessage({ id: AppMessages['rateCard.form.juniorRate.label'] }))
        .when('..', emptyRateValidation('regularRate', 'seniorRate'))
        .required()
        .messages({
          'number.min': formatMessage(
            { id: AppMessages['validation.positive'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.juniorRate.label'] }),
            },
          ),
          'number.unsafe': formatMessage(
            { id: AppMessages['validation.unsafe'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.juniorRate.label'] }),
              maxValue: Number.MAX_SAFE_INTEGER,
            },
          ),
        }),
      regularRate: Joi.number()
        .label(formatMessage({ id: AppMessages['rateCard.form.midRate.label'] }))
        .min(minRateValidation)
        .precision(2)
        .when('juniorRate', {
          is: Joi.number(),
          then: Joi.number()
            .when('role', {
              is: Joi.not(StandardDepartmentCode.PM),
              then: Joi.number().min(Joi.ref('juniorRate')),
            })
            .when('role', {
              is: StandardDepartmentCode.PM,
              then: Joi.number().when('regularRate', {
                is: Joi.number().not(0),
                then: Joi.number().min(Joi.ref('juniorRate')),
              }),
            })
            .messages({
              'number.min': formatMessage(
                { id: AppMessages['validation.min'] },
                {
                  label: formatMessage({ id: AppMessages['rateCard.form.midRate.label'] }),
                  limit: formatMessage({ id: AppMessages['rateCard.form.juniorRate.label'] }),
                },
              ),
            }),
        })
        .when('..', emptyRateValidation('juniorRate', 'seniorRate'))
        .optional()
        .messages({
          'number.min': formatMessage(
            { id: AppMessages['validation.positive'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.midRate.label'] }),
            },
          ),
          'number.unsafe': formatMessage(
            { id: AppMessages['validation.unsafe'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.midRate.label'] }),
              maxValue: Number.MAX_SAFE_INTEGER,
            },
          ),
        }),
      seniorRate: Joi.number()
        .min(minRateValidation)
        .label(formatMessage({ id: AppMessages['rateCard.form.seniorRate.label'] }))
        .when('..', emptyRateValidation('juniorRate', 'regularRate'))
        .precision(2)
        .when('juniorRate', {
          is: Joi.number(),
          then: Joi.number()
            .when('role', {
              is: Joi.not(StandardDepartmentCode.PM),
              then: Joi.number().min(Joi.ref('juniorRate')),
            })
            .when('role', {
              is: StandardDepartmentCode.PM,
              then: Joi.number().when('seniorRate', {
                is: Joi.number().not(0),
                then: Joi.number().min(Joi.ref('juniorRate')),
              }),
            })
            .messages({
              'number.min': formatMessage(
                { id: AppMessages['validation.min'] },
                {
                  label: formatMessage({ id: AppMessages['rateCard.form.seniorRate.label'] }),
                  limit: formatMessage({ id: AppMessages['rateCard.form.juniorRate.label'] }),
                },
              ),
            }),
        })
        .when('regularRate', {
          is: Joi.number(),
          then: Joi.number()
            .when('role', {
              is: Joi.not(StandardDepartmentCode.PM),
              then: Joi.number().min(Joi.ref('regularRate')),
            })
            .when('role', {
              is: StandardDepartmentCode.PM,
              then: Joi.number().when('seniorRate', {
                is: Joi.number().not(0),
                then: Joi.number().min(Joi.ref('regularRate')),
              }),
            })
            .messages({
              'number.min': formatMessage(
                { id: AppMessages['validation.min'] },
                {
                  label: formatMessage({ id: AppMessages['rateCard.form.seniorRate.label'] }),
                  limit: formatMessage({ id: AppMessages['rateCard.form.midRate.label'] }),
                },
              ),
            }),
        })
        .optional()
        .messages({
          'number.min': formatMessage(
            { id: AppMessages['validation.positive'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.seniorRate.label'] }),
            },
          ),
          'number.unsafe': formatMessage(
            { id: AppMessages['validation.unsafe'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.seniorRate.label'] }),
              maxValue: Number.MAX_SAFE_INTEGER,
            },
          ),
        }),
    }),
    otherwise: Joi.object({
      withoutLevelRate: Joi.number()
        .positive()
        .when('role', {
          is: StandardDepartmentCode.PM,
          then: Joi.allow(0),
        })
        .required()
        .precision(2)
        .messages({
          'number.positive': formatMessage(
            { id: AppMessages['validation.positive'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.withoutLevelRate.label'] }),
            },
          ),
          'number.base': formatMessage(
            { id: AppMessages['validation.required'] },
            { label: formatMessage({ id: AppMessages['rateCard.form.withoutLevelRate.label'] }) },
          ),
          'number.unsafe': formatMessage(
            { id: AppMessages['validation.unsafe'] },
            {
              label: formatMessage({ id: AppMessages['rateCard.form.withoutLevelRate.label'] }),
              maxValue: Number.MAX_SAFE_INTEGER,
            },
          ),
        }),
      juniorRate: Joi.number().allow('').optional(),
      regularRate: Joi.number().allow('').optional(),
      seniorRate: Joi.number().allow('').optional(),
    }),
  });
};
