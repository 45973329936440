import { useRef, useState } from 'react';

import { FieldValues } from 'react-hook-form';

import { convertRateToInteger } from 'utils/convertRate';
import { formatDate, isValidDate } from 'utils/dateUtils';

export const useEditMode = <T extends FieldValues>(
  onSubmit: (data: T) => unknown,
  disabled?: boolean,
  sendAsInt?: boolean,
) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const setOpenEditMode = () => {
    if (disabled) {
      return;
    }
    inputRef.current?.focus();
    setIsEditMode(true);
  };

  const setCloseEditMode = () => {
    setIsEditMode(false);
  };

  const onFormSubmit = async (data: T) => {
    await onSubmit(
      Object.fromEntries(
        Object.entries(data).map(([key, value]) => {
          if (sendAsInt) return [key, String(convertRateToInteger(value))];
          if (isValidDate(value)) return [key, formatDate(value)];
          return [key, value];
        }),
      ) as T,
    );
    setCloseEditMode();
  };

  return {
    inputRef,
    isEditMode,
    setOpenEditMode,
    setCloseEditMode,
    onFormSubmit,
  };
};
