import type { Styles } from 'lux/theme';

export const dialog: Styles = {
  '.MuiDialog-paper': {
    p: 0,
    flexDirection: 'row',
    minWidth: 808,
    overflow: 'hidden',
  },
};

export const content: Styles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'scroll',
};
