import { AppMessages } from 'i18n/messages';
import { intl } from 'context/locale/intl/Intl';

import { CreateActiveFiltersOptions } from './ActiveFilters.types';

export const createActiveFilters = <T extends Record<string, string | string[]>>(
  filters: T,
  options?: CreateActiveFiltersOptions<T>,
) => {
  const { formatMessage } = intl.currentIntl;
  const defaultOptions = {
    exclude: [],
    translationsBasedOnValue: {} as NonNullable<CreateActiveFiltersOptions<T>['translationsBasedOnValue']>,
    translationsBasedOnName: [],
    valueFormatters: {} as NonNullable<CreateActiveFiltersOptions<T>['valueFormatters']>,
  };
  const { exclude, translationsBasedOnName, translationsBasedOnValue, valueFormatters } = {
    ...defaultOptions,
    ...options,
  };

  const activeFilters = Object.entries(filters)
    .flatMap(([filterKey, filterValue]) => {
      if (Array.isArray(filterValue)) {
        return filterValue.map((value) => ({ name: filterKey, value }));
      }

      return { name: filterKey, value: filterValue };
    })
    .filter(({ name, value }) => !exclude.includes(name) && !!value);

  const getActiveFilterLabel = ({ name, value }: { name: string; value: string }) => {
    let filterData;

    if (name in translationsBasedOnValue) {
      filterData = translationsBasedOnValue[name].find((filter) => filter.value === value);
    }

    if (!filterData) {
      filterData = translationsBasedOnName.find((filter) => filter.name === name);
    }

    const formattedValue = valueFormatters[name]?.(value) ?? value;

    if (!filterData) {
      return formattedValue;
    }

    return formatMessage(
      {
        id: AppMessages[filterData.labelTranslation],
      },
      { value: formattedValue },
    );
  };

  return activeFilters.map((filter) => ({ ...filter, label: getActiveFilterLabel(filter) }));
};
