import { Stack, Divider } from '@mui/material';

import { DepartmentFilter } from 'shared/people/peopleFilters/departmentFilter/DepartmentFilter';
import { SeniorityFilter } from 'shared/people/peopleFilters/seniorityFilter/SeniorityFilter';
import { FteFilter } from 'shared/people/peopleFilters/fteFilter/FteFilter';
import { StatusFilter } from 'shared/people/peopleFilters/statusFilter/StatusFilter';
import { PeopleActiveFilters } from 'shared/people/peopleFilters/peopleActiveFilters/PeopleActiveFilters';
import * as styles from '../../DashboardGrid.styles';

export const PeopleFiltersSection = () => (
  <>
    <Stack mb={1} direction="row" flexWrap="wrap" gap={1}>
      <DepartmentFilter variant="popover" />
      <SeniorityFilter variant="popover" />
      <FteFilter variant="popover" />
      <StatusFilter variant="popover" />
    </Stack>
    <Divider sx={styles.divider} />
    <PeopleActiveFilters />
  </>
);
