import { useEffect, useRef, useState } from 'react';

import { Accent } from '@mui/material';

import { ProposalStatus } from 'config/data/need/ProposalStatus.enum';
import { Translation } from 'i18n/messages';

export const useDynamicAvatars = () => {
  const proposalTabRef = useRef<HTMLDivElement>();
  const contentRef = useRef<HTMLDivElement>();
  const avatarWidth = 32;

  const [avatarsToDisplay, setAvatarsToDisplay] = useState(5);

  const handleResize = () => {
    if (proposalTabRef.current && contentRef.current) {
      const wrapperWidth = proposalTabRef.current.offsetWidth;
      const contentWidthWithMargin = contentRef.current.offsetWidth + 50;

      setAvatarsToDisplay(Math.floor((wrapperWidth - contentWidthWithMargin) / avatarWidth));
    }
  };

  useEffect(() => {
    handleResize();
  }, [proposalTabRef, contentRef]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { proposalTabRef, contentRef, avatarsToDisplay };
};

export const getColorByProposalStatus = (proposalStatus: ProposalStatus): keyof Accent => {
  switch (proposalStatus) {
    case ProposalStatus.approved:
      return 'green';
    case ProposalStatus.pending:
      return 'blue';
    case ProposalStatus.rejected:
      return 'red';
  }
};

export const getTranslationByProposalStatus = (proposalStatus: ProposalStatus): Translation => {
  switch (proposalStatus) {
    case ProposalStatus.approved:
      return 'card.need.approved';
    case ProposalStatus.pending:
      return 'card.need.proposed';
    case ProposalStatus.rejected:
      return 'card.need.rejected';
  }
};
