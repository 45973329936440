import { Styles } from 'lux/theme';

export const errorText: Styles = {
  alignItems: 'flex-start',
  fontSize: '1rem',
  color: 'text.primary',
  marginBottom: 2,

  '> .MuiAlert-icon': {
    marginRight: '1rem',
    width: '1.25rem',
    aspectRatio: 1,
    alignItems: 'center',
  },

  '> :nth-of-type(2)': {
    color: 'text.secondary',
  },

  '.MuiAlertTitle-root': {
    fontWeight: 500,
    color: 'text.primary',
  },
};
