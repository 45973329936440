import { useState, useRef, RefObject } from 'react';

import { Stack, Box } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useSnackbar } from 'notistack';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { AppMessages } from 'i18n/messages';
import { useUpdateProject } from 'hooks/useUpdateProject/useUpdateProject';
import { Link } from 'api/actions/getProject/getProject.types';
import { UpdateProjectParams } from 'api/actions/updateProject/updateProjectActions.types';

import { ProjectLinkProps } from './ProjectLinks.types';
import * as styles from './ProjectLinks.styles';
import { ProjectLink } from './projectLink/ProjectLink';
import { ManageLinkPopover } from './manageLinkPopover/ManageLinkPopover';
import { linkSchema } from './schema/Link.schema';
import { AddLinkButton } from './addLinkButton/AddLinkButton';

const defaultValues = { name: '', url: '' };

export const ProjectLinks = ({ projectId, links }: ProjectLinkProps) => {
  const { mutateAsync } = useUpdateProject(projectId);

  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();

  const addLinkRef = useRef<HTMLDivElement>(null);

  const [isManageLinkPopoverOpen, setManageLinkPopoverOpen] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState<RefObject<HTMLDivElement>>(addLinkRef);
  const [editedIndex, setEditedIndex] = useState<number>(-1);

  const form = useIntlForm<Link>({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(linkSchema()),
  });

  const resetPopovers = () => {
    setManageLinkPopoverOpen(false);
    setEditedIndex(-1);
    form.reset(defaultValues);
    setPopoverAnchor(addLinkRef);
  };

  const handleUpdateLink = async (link: Link) => {
    const isAdding = !links || editedIndex === -1;

    const payload: UpdateProjectParams = {};

    if (isAdding) {
      payload.links = links ? [...links, link] : [link];
    } else {
      payload.links = links.map((l, i) => {
        if (i === editedIndex) return link;

        return l;
      });
    }

    await mutateAsync(payload, {
      onSuccess: () => {
        if (isAdding) {
          enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.addLink.success'] }));
        } else {
          enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.editLink.success'] }));
        }

        resetPopovers();
      },
    });
  };

  const handleLinkDelete = async (index: number) => {
    if (!links || !links.length) return;

    const newLinks = [...links.slice(0, index), ...links.slice(index + 1)];

    const payload: UpdateProjectParams = {
      links: newLinks,
    };

    await mutateAsync(payload, {
      onSuccess: () => {
        enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.removeLink.success'] }));
      },
      onError: () => {
        enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.removeLink.error'] }), { variant: 'error' });
      },
    });
  };

  const handleLinkEdit = async (index: number, editLinkAnchor: RefObject<HTMLDivElement>) => {
    if (!links || !links.length) return;

    const editedLink = links[index];
    form.setValue('name', editedLink.name);
    form.setValue('url', editedLink.url);

    setPopoverAnchor(editLinkAnchor);
    setEditedIndex(index);
    setManageLinkPopoverOpen(true);
  };

  return (
    <>
      <Stack direction="row" sx={styles.links} gap={1}>
        {links &&
          links.map((link, index) => (
            <ProjectLink
              key={`${link.name}-${index}`}
              {...link}
              onLinkDelete={() => handleLinkDelete(index)}
              onLinkEdit={(anchor) => handleLinkEdit(index, anchor)}
            />
          ))}
        <Box minHeight={40} ref={addLinkRef}>
          {!isManageLinkPopoverOpen && (
            <AddLinkButton
              showExtended={!links || !links.length}
              onClick={() => setManageLinkPopoverOpen(true)}
              message={formatMessage({ id: AppMessages['projectDetails.links.add'] })}
            />
          )}
        </Box>
      </Stack>
      <FormProvider {...form}>
        <ManageLinkPopover
          isOpen={isManageLinkPopoverOpen}
          onSubmit={form.handleSubmit(handleUpdateLink)}
          onClose={() => resetPopovers()}
          anchor={popoverAnchor.current}
        />
      </FormProvider>
    </>
  );
};
