import { Box } from '@mui/material';
import { Chip } from 'lux/components';

import * as styles from './SelectChips.styles';
import { SelectChipsProps } from './SelectChips.types';

export const SelectChips = ({ selectedValues }: SelectChipsProps) => (
  <Box sx={styles.selectBox}>
    {selectedValues.map((value) => (
      <Chip key={value} label={value} sx={styles.selectChip} />
    ))}
  </Box>
);
