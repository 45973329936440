import { AppMessages } from 'i18n/messages';
import { intl } from 'context/locale/intl/Intl';

export const useTitle = (title?: string) => {
  if (!title) {
    const { formatMessage } = intl.currentIntl;
    document.title = formatMessage({ id: AppMessages['title.base'] });

    return;
  }

  if (document.title !== title) document.title = title;
};
