import { DialogTitle, DialogContent, DialogActions, Stack } from '@mui/material';
import { Button } from 'lux/components';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { DatePicker } from 'ui/form/datePicker/DatePicker';
import { useConvertLead } from 'hooks/useConvertLead/useConvertLead';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { formatDate, parseDate } from 'utils/dateUtils';

import * as styles from './ConvertLeadModal.styles';
import { convertLeadSchema } from './ConvertLead.schema';
import { ConvertLeadContentProps, ConvertLeadFormValues } from './ConvertLeadContent.types';

export const ConvertLeadContent = ({
  projectId,
  projectName,
  startDate,
  onClose,
  onSuccess,
}: ConvertLeadContentProps) => {
  const { formatMessage } = useLocale();

  const defaultValues = { startDate: parseDate(startDate) };

  const { mutate, isLoading } = useConvertLead(projectId);
  const { control, handleSubmit } = useForm<ConvertLeadFormValues>({
    defaultValues,
    mode: 'onSubmit',
    resolver: joiResolver(convertLeadSchema()),
  });

  const onSubmit = (data: ConvertLeadFormValues) => {
    mutate(
      { startDate: formatDate(data.startDate) },
      {
        onSuccess: () => {
          onSuccess();
        },
      },
    );
  };

  return (
    <>
      <DialogTitle>
        {formatMessage({ id: AppMessages['projectDetails.convertLeadModal.title'] }, { projectName })}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack gap={3}>
            {formatMessage({ id: AppMessages['projectDetails.convertLeadModal.message'] })}
            <DatePicker
              label={formatMessage({ id: AppMessages['projectDetails.convertLeadModal.startDate'] })}
              name="startDate"
              disableFuture
              control={control}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button type="button" variant="text" sx={styles.cancelButton} onClick={onClose}>
            {formatMessage({ id: AppMessages['button.cancel'] })}
          </Button>
          <Button type="submit" loading={isLoading} variant="contained">
            {formatMessage({ id: AppMessages['projectDetails.convertLeadModal.button.submit'] })}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};
