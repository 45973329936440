export type WorkStatementForm = {
  statementNumber: string;
  billingCycle: string;
  startDate: Date | null;
  endDate?: Date | null;
  paymentPeriod: number | '';
  additionalInformation?: string;
  depositAmount?: string;
  depositPaymentDate?: Date | null;
};

export enum WorkStatementFormType {
  add = 'add',
  edit = 'edit',
}

export type WorkStatementFormContentProps = {
  type: WorkStatementFormType.add | WorkStatementFormType.edit;
  loading: boolean;
  projectStartDate: string;
  projectCurrency: string;
  projectEndDate?: string;
  useProjectStartDate: boolean;
};
