import { getLastConfirmedInvoice } from 'api/actions/getLastConfirmedInvoice/getLastConfirmedInvoice';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { projectsKeys } from 'utils/queryKeys';

export const useLastConfirmedInvoice = (projectId: string) => {
  const { isAuthenticated } = useAuth();

  const { data, ...rest } = useQuery(projectsKeys.lastConfirmedInvoice(projectId), getLastConfirmedInvoice(projectId), {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });

  return { invoice: data?.invoice, ...rest };
};
