import { Box, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { OpenInNewIcon } from 'lux/icons';

import { PersonStatusBadge } from 'ui/personStatusBadge/PersonStatusBadge';
import { Translation } from 'ui/translation/Translation';
import { usePerson } from 'hooks/usePerson/usePerson';
import { usePersonProposals } from 'hooks/usePersonProposals/usePersonProposals';
import { usePersonAssignments } from 'hooks/usePersonAssignments/usePersonAssignments';
import { compareDatesDesc } from 'utils/dateUtils';
import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';
import { PersonBasicInfo } from 'shared/people/personBasicInfo/PersonBasicInfo';
import * as styles from '../PersonTooltip.styles';
import { PersonTooltipContentSkeleton } from 'shared/people/personTooltip/personTooltipContent/PersonTooltipContent.skeleton';
import { getSeniorityRole } from 'utils/stringUtils';

import { PersonTooltipContentProps } from './PersonTooltipContent.types';

const activeStatuses: readonly AssignmentStatus[] = [AssignmentStatus.active, AssignmentStatus.leaving] as const;

export const PersonTooltipContent = ({ employeeId }: PersonTooltipContentProps) => {
  const { data, isLoading } = usePerson(String(employeeId));
  const { data: proposalData, isLoading: isProposalsLoading } = usePersonProposals(String(employeeId));
  const { data: assignmentData, isLoading: isAssignmentsLoading } = usePersonAssignments(String(employeeId));

  if (isLoading || !data || isProposalsLoading || isAssignmentsLoading) {
    return <PersonTooltipContentSkeleton />;
  }

  const {
    firstName,
    lastName,
    picture,
    role,
    availableFte,
    availabilityStatus,
    employmentType,
    proposalCount,
    isLeader,
    employedTo,
    seniority,
    departmentCode,
    absences,
  } = data.person;

  const availableFrom = assignmentData?.assignments
    .filter((assignment) => activeStatuses.includes(assignment.status) && assignment.endDate)
    .sort((a, b) =>
      compareDatesDesc(a.endDate ? new Date(a.endDate) : null, b.endDate ? new Date(b.endDate) : null),
    )?.[0]?.endDate;

  const currentAssignments = assignmentData?.assignments.filter((assignment) =>
    activeStatuses.includes(assignment.status),
  );

  return (
    <Stack gap={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box component={Link} to={`/people/${employeeId}`} sx={styles.employeeLink} target="_blank">
          <PersonBasicInfo
            firstName={firstName}
            lastName={lastName}
            avatarSrc={picture}
            seniorityRole={getSeniorityRole(seniority, departmentCode)}
            role={role}
            employmentType={employmentType}
            proposalCount={proposalCount}
            isLeader={isLeader}
            employedTo={employedTo}
            absences={absences}
          />
          <OpenInNewIcon />
        </Box>
        <PersonStatusBadge status={availabilityStatus} availableFte={availableFte} />
      </Stack>
      <Stack>
        {availableFrom && (
          <Stack direction="row" gap={1}>
            <Typography color="text.secondary" variant="caption">
              <Translation id="personTooltip.availableFrom" />
            </Typography>
            <Typography variant="caption">{availableFrom}</Typography>
          </Stack>
        )}
        {currentAssignments && !!currentAssignments.length && (
          <Stack direction="row" gap={1} justifyContent="start">
            <Box sx={styles.projectLinkLabelWrapper}>
              <Typography color="text.secondary" variant="caption">
                <Translation id="personTooltip.currentProject" />
              </Typography>
            </Box>
            <Stack direction="row" gap={1} justifyContent="start" flexWrap="wrap">
              {currentAssignments.map((assignment) => (
                <Typography
                  key={assignment.id}
                  variant="caption"
                  component={Link}
                  to={`/projects/${assignment.project.id}`}
                  target="_blank"
                  sx={styles.projectLink}
                >
                  {assignment.project.name}
                </Typography>
              ))}
            </Stack>
          </Stack>
        )}
        {proposalData && !!proposalData.proposals.length && (
          <Stack direction="row" gap={1} justifyContent="start">
            <Box sx={styles.projectLinkLabelWrapper}>
              <Typography color="text.secondary" variant="caption">
                <Translation id="personTooltip.proposed" />
              </Typography>
            </Box>
            <Stack direction="row" gap={1} justifyContent="start" flexWrap="wrap">
              {proposalData.proposals.map((proposal) => (
                <Typography
                  key={proposal.id}
                  component={Link}
                  to={`/projects/${proposal.need.project.id}`}
                  target="_blank"
                  variant="caption"
                  sx={styles.projectLink}
                >
                  {proposal.need.project.name}
                </Typography>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
