import { ButtonBase, Stack, Box, InputAdornment } from '@mui/material';
import { Typography, InlineEdit } from 'lux/components';
import { SubmitHandler } from 'react-hook-form';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { useEditMode } from 'hooks/useEditMode/useEditMode';
import { moneyInputNumberParser } from 'utils/createInputNumberChangeParser';

import * as styles from './NeedRate.styles';
import { ModifyRateFormValues, NeedRateProps } from './NeedRate.types';

export const NeedRate = ({ needRate, currency, effectiveRate, onEffectiveRateChange }: NeedRateProps) => {
  const { formatMessage } = useLocale();
  const { control, handleSubmit } = useIntlForm<ModifyRateFormValues>({
    defaultValues: {
      effectiveRate: '',
    },
  });
  const onSubmit: SubmitHandler<ModifyRateFormValues> = ({ effectiveRate }) => {
    onEffectiveRateChange(effectiveRate === '' ? null : Number(effectiveRate));
  };
  const { inputRef, onFormSubmit, setCloseEditMode, setOpenEditMode, isEditMode } = useEditMode(onSubmit);

  const hasCustomRate = effectiveRate !== null;
  const hasNeedRate = needRate !== undefined;

  return (
    <Stack
      spacing={hasCustomRate && !isEditMode ? 0 : 2}
      direction={hasCustomRate && !isEditMode ? 'column' : 'row'}
      alignItems={hasCustomRate && !isEditMode ? 'flex-start' : 'center'}
      marginY={hasCustomRate || isEditMode ? -1.25 : 0}
    >
      {!hasNeedRate ? (
        <Box>{formatMessage({ id: AppMessages['card.need.comparisonTable.hourlyRate.notDefined'] })}</Box>
      ) : (
        <Stack direction="row" spacing={0.5}>
          {hasCustomRate && (
            <Typography color="text.secondary" variant="caption" fontWeight={600}>
              {formatMessage({ id: AppMessages['card.need.comparisonTable.hourlyRate.baseRate'] })}
            </Typography>
          )}
          <span>{needRate}</span>
          <Typography color="text.secondary" variant="caption" fontWeight={600}>
            {currency}
          </Typography>
        </Stack>
      )}
      <Stack direction="row" spacing={2}>
        {!isEditMode && hasCustomRate && (
          <Stack direction="row" spacing={0.5}>
            <Typography color="text.secondary" variant="caption" fontWeight={600}>
              {formatMessage({ id: AppMessages['card.need.comparisonTable.hourlyRate.effectiveRate'] })}
            </Typography>
            <span>{effectiveRate}</span>
            <Typography color="text.secondary" variant="caption" fontWeight={600}>
              {currency}
            </Typography>
          </Stack>
        )}
        <InlineEdit
          isActive={isEditMode}
          onSubmit={handleSubmit(onFormSubmit)}
          onCancel={setCloseEditMode}
          onDataDisplayClick={setOpenEditMode}
          input={
            <FormTextField
              startAdornment={<InputAdornment position="start">{currency}</InputAdornment>}
              autoFocus
              sx={styles.modifyRateInput}
              control={control}
              inputRef={inputRef}
              label={formatMessage({ id: AppMessages['card.need.comparisonTable.hourlyRate.modifyRate.input'] })}
              name="effectiveRate"
              size="small"
              onChangeParser={moneyInputNumberParser}
            />
          }
          renderDataDisplay={(params) => (
            <ButtonBase sx={styles.modifyRateButton} disableRipple {...params}>
              {formatMessage({ id: AppMessages['card.need.comparisonTable.hourlyRate.modifyRate.button'] })}
            </ButtonBase>
          )}
        />
      </Stack>
    </Stack>
  );
};
