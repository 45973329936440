import { SxProps } from '@mui/material';
import { Styles } from 'lux/theme';

import { markerBorderLineWidth, startDayCellWidth } from 'utils/timesheetProjectStartDayIndicator';

export const workStatementCell = (isProjectStartDayMarker: boolean): SxProps => ({
  backgroundColor: 'primary.light',
  display: 'flex',

  '&&&': {
    borderRightColor: 'primary.light',
  },

  ...(isProjectStartDayMarker && {
    borderLeft: `${markerBorderLineWidth}px solid orange`,
    width: startDayCellWidth,
    position: 'relative',

    // we need to mask a piece of bottom border in a cell with orange color
    '&:before': {
      position: 'absolute',
      left: -markerBorderLineWidth,
      top: -2,
      content: '""',
      display: 'block',
      height: 2,
      width: markerBorderLineWidth,
      backgroundColor: 'orange',
    },
  }),
});

export const workStatementName: Styles = {
  flex: 1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export const workStatementEdit: Styles = {
  color: 'primary.main',
};

export const accordionSummary: Styles = {
  padding: 0,
  border: 0,
  borderRadius: 0,
};
export const accordionDetails: Styles = {
  padding: 0,
  border: 'none',
  margin: 0,
};
