import { Styles } from 'styles/theme';

export const employeeCard: Styles = {
  backgroundColor: 'surface.paper.default',
};

export const employeeCardWrapper: Styles = {
  textDecoration: 'none',
  color: 'text.primary',
};
