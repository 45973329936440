import { SxProps } from '@mui/system';

export const container: SxProps = {
  ['@media print']: {
    width: '300px',
    border: '0.5px solid',
    borderColor: 'border.light',
    borderRadius: 5,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export const text: SxProps = {
  ['@media print']: {
    width: '100%',
  },
};
