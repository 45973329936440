import { Fragment, useCallback, useEffect } from 'react';

import { Box, Divider } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { RateCardItem } from 'shared/project/rateCardItem/RateCardItem';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { DatePicker } from 'ui/form/datePicker/DatePicker';
import { AppMessages, Translation } from 'i18n/messages';
import { getCurrency } from 'utils/getCurrency';
import { isDateBefore, parseDate } from 'utils/dateUtils';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';
import { RateCardItemsFormType } from '../UpdateRateCardContent.types';
import { extractNeededRoles } from '../../../RateCardDetails.utils';
import { useSuggestedRoles } from '../../../../../../../hooks/useSuggestedRoles/useSuggestedRoles';
import { suggestedRoles } from '../../../../../../../config/data/need/suggestedRoles';
import { isPM } from 'utils/isPM';

import { RateCardProps } from './RateCard.types';
import * as styles from './RateCard.styles';
import { RateCardSuggestions } from './rateCardSuggestions/RateCardSuggestions';

const getDisableDeleteButtonMessage = (isAssignmentUsingRateCard: boolean) => {
  if (isAssignmentUsingRateCard) {
    return 'projectDetails.accordions.details.rateCard.tooltip.inUse';
  }

  return 'projectDetails.accordions.details.rateCard.tooltip.isPM';
};

export const RateCard = ({ rateCard, minDate }: RateCardProps) => {
  const {
    projectDetails: { stage, assignments, needs },
  } = useProjectDetails();

  const form = useFormContext<RateCardItemsFormType>();

  const { fields, append, remove } = useFieldArray<RateCardItemsFormType, 'rateCards', 'id'>({
    name: 'rateCards',
    keyName: 'id',
  });

  const { formatMessage } = useLocale();
  const neededRoles = extractNeededRoles(needs, true);
  const { chips: suggestedRolesChips, setChips, isSuggestedRole } = useSuggestedRoles({ rateCard, neededRoles });
  const { t } = useLocale();

  useEffect(() => {
    const { unsubscribe } = form.watch((data, { name, type }) => {
      if (type !== 'change' || !name) {
        return;
      }

      const changedRateCard = /rateCards.\d+/.exec(name);
      const isSeniorityRateChange = /(juniorRate|regularRate|seniorRate)/.test(name);
      if (!changedRateCard || !isSeniorityRateChange) {
        return;
      }

      type SeniorityRate = 'juniorRate' | 'regularRate' | 'seniorRate';
      form.trigger([
        `${changedRateCard[0]}.juniorRate`,
        `${changedRateCard[0]}.regularRate`,
        `${changedRateCard[0]}.seniorRate`,
      ] as `rateCards.${number}.${SeniorityRate}`[]);
    });

    return unsubscribe;
  }, [form.watch]);

  const appliesFrom = form.watch('appliesFrom');

  const handleRemove = (index: number) => {
    const { role } = fields[index];

    const foundRole = suggestedRoles
      .find((suggestedRole) => t(suggestedRole.label.toLowerCase() as Translation) === role)
      ?.value.toLowerCase();

    if (foundRole && isSuggestedRole(foundRole)) {
      setChips((prevState) => [...prevState, role]);
    }
    remove(index);

    const duplicateRoleIndex = form.watch('rateCards').findIndex((field) => field.role === role);
    form.clearErrors(`rateCards.${duplicateRoleIndex}.role`);
  };

  const handleAddRole = useCallback(
    (role = '') => {
      setChips((prevState) => prevState.filter((chip) => chip !== role));
      append({
        role,
        juniorRate: '',
        regularRate: '',
        seniorRate: '',
        withoutLevelRate: '',
        seniorityEnabled: true,
        isDraft: true,
      });
    },
    [append],
  );

  return (
    <Box sx={styles.rateCardsList}>
      <Box sx={styles.datePicker}>
        <DatePicker
          name="appliesFrom"
          isDisabled={stage === ProjectStage.lead}
          minDate={minDate}
          control={form.control}
          label={formatMessage({
            id: AppMessages['projectDetails.accordions.details.rateCard.updateRateCard.applyFrom.title'],
          })}
        />
      </Box>

      <Divider sx={styles.divider} />

      {fields.map((item, index) => {
        const isAssignmentUsingRateCard = assignments.some(
          (assignment) =>
            assignment.role === item.role &&
            assignment.status !== AssignmentStatus.left &&
            (!assignment.endDate || isDateBefore(appliesFrom, parseDate(assignment.endDate))),
        );

        return (
          <Fragment key={item.id}>
            <RateCardItem
              index={index}
              control={form.control}
              onRemove={handleRemove}
              currency={getCurrency(rateCard.currency)}
              disableDeleteButton={isPM(item.role) || isAssignmentUsingRateCard}
              disabledDeleteButtonMessage={formatMessage({
                id: AppMessages[getDisableDeleteButtonMessage(isAssignmentUsingRateCard)],
              })}
              isDraft={item.isDraft}
              roleName={item.role}
              neededRoles={neededRoles}
            />
          </Fragment>
        );
      })}
      <RateCardSuggestions chips={suggestedRolesChips} onSuggestionClick={handleAddRole} onAddRole={handleAddRole} />
    </Box>
  );
};
