import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { deleteNeedMutation } from 'api/actions/deleteNeed/deleteNeedActions';
import { needsKeys, projectsKeys } from 'utils/queryKeys';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';

export const useDeleteNeed = (projectId: string) => {
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutationDomainError(['deleteNeedMutation'], deleteNeedMutation(projectId), {
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.deleteNeed.success'] }));
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(projectsKeys.singleProjectNeeds(projectId));
      queryClient.invalidateQueries(needsKeys.needsList);
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.deleteNeed.error'] }),
  });
};
