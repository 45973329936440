import { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { NeedModalContext } from 'context/needModal/needModalContext/NeedModalContext';
import { AppMessages } from 'i18n/messages';
import { needsKeys, projectsKeys, proposalsKeys } from 'utils/queryKeys';
import { useApprovePerson } from 'hooks/useApprovePerson/useApprovePerson';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useDialog } from 'hooks/useDialog/useDialog';
import type { NeedModalValue } from 'context/needModal/needModalContext/NeedModalContext.types';
import type { ProjectDetails } from 'api/actions/getProject/getProject.types';
import type { NeedDto } from 'api/types/Need.types';
import type { Proposal } from 'shared/needs/Needs.types';
import type { Rejection } from 'api/actions/getRejections/getRejections.types';
import { useRemovePersonRejection } from '../../../hooks/useCancelPersonRejection/useRemovePersonRejection';

import type { NeedModalControllerProps } from './NeedModalContextController.types';

export const NeedModalContextController = ({ children }: NeedModalControllerProps) => {
  const [projectDetails, setProjectDetails] = useState<ProjectDetails | undefined>(undefined);
  const [selectedProposal, setSelectedProposal] = useState<Proposal | Rejection | undefined>(undefined);
  const [needData, setNeedData] = useState<NeedDto | undefined>(undefined);
  const [rejections, setRejections] = useState<Rejection[] | undefined>(undefined);
  const [isConfirmAssignOpen, setIsConfirmAssignOpen] = useState(false);
  const { isOpen: isConfirmRejectOpen, setOpen: setConfirmRejectOpen, setClose: setConfirmRejectClose } = useDialog();
  const { isOpen: isConfirmRemoveOpen, setOpen: setConfirmRemoveOpen, setClose: setConfirmRemoveClose } = useDialog();
  const [canToggleDetails, setCanToggleDetails] = useState(false);
  const {
    isOpen: detailsVisible,
    toggle: toggleDetails,
    setOpen: setDetailsOpen,
    setClose: setDetailsClose,
  } = useDialog();
  const { mutate: approvePerson, isLoading: isApprovePersonLoading } = useApprovePerson();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();
  const { mutate } = useRemovePersonRejection(needData?.id);

  useEffect(() => {
    if (needData && rejections) {
      const proposalCount = needData.proposalStats.proposed + needData.proposalStats.approved;
      const hasAnyProposalsOrRejections = !!(proposalCount || rejections.length);

      setCanToggleDetails(hasAnyProposalsOrRejections);

      if (hasAnyProposalsOrRejections) {
        setDetailsClose();
      } else {
        setDetailsOpen();
      }
    }
  }, [needData?.proposals, rejections]);

  const onUndoAcceptation = useCallback(() => {
    // @todo
  }, []);

  const onAssign = useCallback((proposal: Proposal) => {
    setSelectedProposal(proposal);
    setIsConfirmAssignOpen(true);
  }, []);

  const onAccept = useCallback(
    (proposal: Proposal) => {
      const employee = proposal.person;
      const proposalId = proposal.id;
      const projectId = projectDetails?.id;
      const needId = needData?.id;

      if (!projectId || !needId) {
        return;
      }

      approvePerson(
        { needId, proposalId, projectId },
        {
          onSuccess: () => {
            enqueueSnackbar(
              formatMessage(
                { id: AppMessages['snackbar.approvePerson.success'] },
                {
                  personName: `${employee.firstName} ${employee.lastName}`,
                },
              ),
            );
            queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
            queryClient.invalidateQueries(projectsKeys.singleProjectNeeds(projectId));
            queryClient.invalidateQueries(needsKeys.singleNeed(needId));
            queryClient.invalidateQueries(needsKeys.needsList);
            queryClient.invalidateQueries(proposalsKeys.proposalsList(needId));
          },
        },
      );
    },
    [projectDetails, needData, approvePerson, enqueueSnackbar, formatMessage, queryClient],
  );

  const onReject = useCallback((rejection: Rejection) => {
    setSelectedProposal(rejection);
    setConfirmRejectOpen();
  }, []);

  const onRemove = useCallback((proposal: Proposal) => {
    setSelectedProposal(proposal);
    setConfirmRemoveOpen();
  }, []);

  const onUndoRejection = useCallback(
    (rejection: Rejection) => {
      if (rejection?.id && projectDetails?.id) {
        mutate({ rejectionId: rejection.id, projectId: projectDetails?.id });
      }
    },
    [projectDetails?.id, mutate],
  );

  const closeConfirmAssign = useCallback(() => {
    setSelectedProposal(undefined);
    setIsConfirmAssignOpen(false);
  }, []);

  const closeConfirmReject = useCallback(() => {
    setSelectedProposal(undefined);
    setConfirmRejectClose();
  }, []);

  const closeConfirmRemove = useCallback(() => {
    setSelectedProposal(undefined);
    setConfirmRemoveClose();
  }, []);

  const contextValues: NeedModalValue = {
    projectDetails,
    needData,
    selectedProposal,
    isConfirmAssignOpen,
    isConfirmRejectOpen,
    isApprovePersonLoading,
    detailsVisible,
    canToggleDetails,
    setProjectDetails,
    setNeedData,
    setRejections,
    toggleDetails,
    onAccept,
    onAssign,
    onReject,
    onRemove,
    onUndoAcceptation,
    onUndoRejection,
    closeConfirmAssign,
    closeConfirmReject,
    closeConfirmRemove,
    isConfirmRemoveOpen,
  };

  return <NeedModalContext.Provider value={contextValues}>{children}</NeedModalContext.Provider>;
};
