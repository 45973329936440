import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { UpdateWorkStatementNoteParams } from './updateWorkStatementNote.types';

export const updateWorkStatementNote: (workStatementId: string) => MutationFn<UpdateWorkStatementNoteParams> =
  (workStatementId) => (body) => ({
    endpoint: `/work-statements/${workStatementId}/note`,
    method: 'PATCH',
    params: body,
  });
