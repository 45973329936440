import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { GetPersonParams, GetPersonResponse, PersonRate } from 'api/actions/getPerson/getPerson.types';
import { peopleKeys } from 'utils/queryKeys';
import { getPersonQuery } from 'api/actions/getPerson/getPerson';
import { convertRateToDecimal } from 'utils/convertRate';

export const usePerson = (personId: string) => {
  const { isAuthenticated } = useAuth();

  const { data, ...response } = useQuery<GetPersonParams, GetPersonResponse>(
    peopleKeys.singlePerson(personId),
    getPersonQuery(personId),
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
    },
  );

  if (!data) {
    return { data, ...response };
  }

  const mappedData = {
    person: {
      ...data.person,
      previousPersonRates:
        data.person.previousPersonRates.length > 0
          ? data.person.previousPersonRates.map((prevRate) => ({
              ...prevRate,
              rate: convertRateToDecimal(prevRate.rate),
              rates:
                prevRate.rates &&
                (Object.fromEntries(
                  Object.entries(prevRate.rates).map(([currency, value]) => [currency, convertRateToDecimal(value)]),
                ) as PersonRate['rates']),
            }))
          : [],
      currentPersonRate: data.person.currentPersonRate && {
        ...data.person.currentPersonRate,
        rate: convertRateToDecimal(data.person.currentPersonRate.rate),
        rates:
          data.person.currentPersonRate.rates &&
          (Object.fromEntries(
            Object.entries(data.person.currentPersonRate.rates).map(([currency, value]) => [
              currency,
              convertRateToDecimal(value),
            ]),
          ) as PersonRate['rates']),
      },
      futurePersonRate: data.person.futurePersonRate && {
        ...data.person.futurePersonRate,
        rate: convertRateToDecimal(data.person.futurePersonRate.rate),
        rates:
          data.person.futurePersonRate.rates &&
          (Object.fromEntries(
            Object.entries(data.person.futurePersonRate.rates).map(([currency, value]) => [
              currency,
              convertRateToDecimal(value),
            ]),
          ) as PersonRate['rates']),
      },
    },
  };

  return { data: mappedData, ...response };
};
