import { ProjectDiscrepancyData } from '../projectDiscrepancyContext/ProjectDiscrepancyContext.types';

const sessionKey = 'discrepancyData';

export const getDiscrepancySessionData = (): ProjectDiscrepancyData | null => {
  const discrepancyDataJson = sessionStorage.getItem(sessionKey);

  return discrepancyDataJson ? JSON.parse(discrepancyDataJson) : null;
};

export const removeDiscrepancyData = () => sessionStorage.removeItem(sessionKey);

export const setDiscrepancySessionData = (data: ProjectDiscrepancyData) =>
  sessionStorage.setItem(sessionKey, JSON.stringify(data));
