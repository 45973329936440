import { Dialog } from '@mui/material';

import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';

import { EditWorkStatementContent } from './editWorkStatementContent.tsx/EditWorkStatementContent';
import { EditWorkStatementModalProps } from './EditWorkStatementModal.types';

export const EditWorkStatementModal = ({
  open,
  onClose,
  projectStartDate,
  projectEndDate,
  projectCurrency,
  workStatement,
  useProjectStartDate,
}: EditWorkStatementModalProps) => {
  useDialogBlockScroll(open);

  return (
    <Dialog open={open} onClose={onClose}>
      <EditWorkStatementContent
        onClose={onClose}
        useProjectStartDate={useProjectStartDate}
        projectStartDate={projectStartDate}
        projectEndDate={projectEndDate}
        projectCurrency={projectCurrency}
        workStatement={workStatement}
      />
    </Dialog>
  );
};
