import { Dialog } from '@mui/material';

import { DeleteNeedProps } from '../Need.types';
import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';

import { DeleteNeedContent } from './deleteNeedContent/DeleteNeedContent';
import * as styles from './DeleteNeed.styles';

export const DeleteNeed = ({ projectId, needId, open, onClose, onSuccess }: DeleteNeedProps) => {
  useDialogBlockScroll(open);

  return (
    <Dialog open={open} onClose={onClose} sx={styles.customDialog}>
      <DeleteNeedContent projectId={projectId} needId={needId} onClose={onClose} onSuccess={onSuccess} />
    </Dialog>
  );
};
