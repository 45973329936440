import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { ErrorIcon } from 'lux/icons';

import { DetailsGrid } from '../detailsGrid/DetailsGrid';
import { DetailsGridItem } from '../detailsGrid/detailsGridItem/DetailsGridItem';
import { Translation } from 'ui/translation/Translation';
import { TextFieldEdit } from 'ui/form/inlineEdit/textFieldEdit/TextFieldEdit';
import { useUpdateInvoice } from 'hooks/useUpdateInvoice/useUpdateInvoice';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { DatePickerEdit } from 'ui/form/inlineEdit/datePickerEdit/DatePickerEdit';
import { IssueInvoiceErrorEnum } from 'hooks/useIssueInvoice/useIssueInvoice.types';
import { AddCommentButton } from '../addCommentButton/AddCommentButton';

import * as styles from './InvoiceDetails.styles';
import { InvoiceDetailsProps } from './InvoiceDetails.types';

export const InvoiceDetails = ({ invoice }: InvoiceDetailsProps) => {
  const {
    projectId,
    showWorkStatementSnapshot,
    confirmInvoiceError: { errorType },
    isReport,
  } = useProjectBillingSummaryData();
  const { mutateAsync, isLoading } = useUpdateInvoice(invoice.id, projectId);

  const commonProps = {
    onSubmit: mutateAsync,
    loading: isLoading,
    disabled: showWorkStatementSnapshot || isReport || process.env.REACT_APP_MVP2_FEATURE_ENABLED !== 'true',
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="subtitle2" mb={2} sx={styles.titleWrapper}>
        <Translation id="projectDetails.billingSummary.workStatementDetails.invoiceDetails" />
        {errorType && <ErrorIcon color="error" />}
      </Typography>
      <DetailsGrid>
        <DetailsGridItem sx={styles.wrapper}>
          <TextFieldEdit
            name="number"
            value={invoice.number || '-'}
            label="projectDetails.billingSummary.workStatementDetails.invoiceDetails.invoiceNumber"
            defaultValues={{ number: invoice.number || '-' }}
            showErrorIcon={errorType?.includes(IssueInvoiceErrorEnum.invoiceNotFoundError)}
            {...commonProps}
          />
        </DetailsGridItem>
        <DetailsGridItem sx={styles.wrapper}>
          <DatePickerEdit
            name="issueDate"
            value={invoice.issueDate}
            label="projectDetails.billingSummary.workStatementDetails.invoiceDetails.dateOfInvoiceIssue"
            defaultValues={{ issueDate: invoice.issueDate }}
            {...commonProps}
          />
        </DetailsGridItem>
        <DetailsGridItem sx={styles.wrapper}>
          <DatePickerEdit
            name="saleDate"
            value={invoice.saleDate}
            label="projectDetails.billingSummary.workStatementDetails.invoiceDetails.saleDate"
            defaultValues={{ saleDate: invoice.saleDate }}
            {...commonProps}
          />
        </DetailsGridItem>
        <DetailsGridItem sx={styles.wrapper}>
          <DatePickerEdit
            name="dueDate"
            value={invoice.dueDate}
            label="projectDetails.billingSummary.workStatementDetails.invoiceDetails.dueDate"
            defaultValues={{ dueDate: invoice.dueDate }}
            {...commonProps}
          />
        </DetailsGridItem>
        <DetailsGridItem sx={styles.wrapper}>
          <DatePickerEdit
            name="paymentDate"
            value={invoice.paymentDate}
            label="projectDetails.billingSummary.workStatementDetails.invoiceDetails.paymentDate"
            defaultValues={{ paymentDate: invoice.paymentDate }}
            {...commonProps}
          />
        </DetailsGridItem>
        {!isReport && (
          <DetailsGridItem span={2} sx={styles.commentWrapper}>
            <AddCommentButton invoice={invoice} />
          </DetailsGridItem>
        )}
      </DetailsGrid>
    </Box>
  );
};
