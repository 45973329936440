import Joi from 'joi';

import { AppMessages } from 'i18n/messages';
import { intl } from 'context/locale/intl/Intl';

export const convertLeadSchema = () => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object().keys({
    startDate: Joi.date()
      .required()
      .messages({
        'date.base': formatMessage(
          {
            id: AppMessages['validation.date'],
          },
          { label: formatMessage({ id: AppMessages['projectDetails.convertLeadModal.startDate'] }) },
        ),
        'date.max': formatMessage(
          { id: AppMessages['validation.date.max.today'] },
          {
            label: formatMessage({ id: AppMessages['projectDetails.convertLeadModal.startDate'] }),
          },
        ),
      }),
  });
};
