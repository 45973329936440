import { useProjectDetails } from '../useProjectDetails/useProjectDetails';
import { isPastDate, parseDateToEndOfDay } from 'utils/dateUtils';
import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';

export const useProjectDetailsOutdatedAssignmentsCount = () => {
  const { projectDetails } = useProjectDetails();

  return projectDetails.assignments.reduce(
    (total, assignment) =>
      total +
      Number(
        assignment.endDate &&
          isPastDate(parseDateToEndOfDay(assignment.endDate)) &&
          assignment.status !== AssignmentStatus.left,
      ),
    0,
  );
};
