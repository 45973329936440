import { createFilterOptions, FilterOptionsState } from '@mui/material';
import { AutocompleteOption } from 'lux/components';

import { Aliases } from './Autocomplete.types';

const filter = createFilterOptions<AutocompleteOption>({ trim: true });

export const getValue = (newValue: string | AutocompleteOption): string => {
  if (!newValue) {
    return '';
  }
  if (typeof newValue === 'string') {
    return newValue;
  }
  if (newValue.inputValue) {
    return newValue.inputValue;
  }

  return newValue.value;
};

const mapAliasToValue = (input: string, options: AutocompleteOption[], aliases: Aliases) => {
  if (input.length < 2) {
    return;
  }

  const foundValue = aliases.find((alias) => alias[0].startsWith(input.toLowerCase()));
  const isOption = !!foundValue && options.some((option) => option.value === foundValue[1]);

  return isOption ? { value: foundValue[1] } : undefined;
};

export const filterOptions =
  (isFreeSolo?: boolean, aliases?: Aliases) =>
  (options: AutocompleteOption[], params: FilterOptionsState<AutocompleteOption>) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    const trimmedInputValue = inputValue.trim();
    const mappedAlias = aliases && mapAliasToValue(trimmedInputValue, options, aliases);

    if (mappedAlias) {
      filtered.push(mappedAlias);
    }

    const isExistingOption =
      !!mappedAlias || options.some((option) => trimmedInputValue.toLowerCase() === option.value.toLowerCase());

    if (trimmedInputValue !== '' && !isExistingOption && isFreeSolo) {
      filtered.push({
        inputValue: trimmedInputValue,
        value: `Add "${trimmedInputValue}"`,
      });
    }

    return filtered;
  };

export const mapToAutocompleteOptions = (optionValues: string[]): AutocompleteOption[] =>
  optionValues.map((value) => ({ value }));

export const capitalizeFirstLetter = (data: string[]) =>
  data.map((item) => item.charAt(0).toUpperCase() + item.slice(1));

export const normalizeValue = (
  newValue: string | AutocompleteOption | null,
  options: AutocompleteOption[],
  returnCustomValues?: boolean,
) => {
  if (!newValue) {
    return '';
  }
  const value = getValue(newValue).trim();
  const existingOption = options.find((option) => option.value.toLowerCase() === value.toLowerCase());
  if (!returnCustomValues && !existingOption) {
    return '';
  }
  return existingOption?.value ?? value;
};
