import { ComponentType, ElementType } from 'react';

import { Box } from '@mui/material';
import { Button as LuxButton, CardHeader } from 'lux/components';
import { ButtonProps } from 'lux/components/atoms/button/Button.types';

import { composeStyles } from 'utils/composeStyles';

import { DashboardCardProps } from './DashboardCardProps.types';
import * as styles from './DashboardCard.styles';

const Button = <C extends ElementType>(props: ButtonProps<C>) => (
  <LuxButton size="small" variant="text" color="primary" {...props} />
);

type DashboardCardComponent = {
  Button: typeof Button;
} & ComponentType<DashboardCardProps>;

export const DashboardCard: DashboardCardComponent = ({ title, children, actions, sx }: DashboardCardProps) => (
  <Box sx={composeStyles([styles.dashboardCardWrapper, sx?.wrapper])}>
    <CardHeader title={title} sx={{ wrapper: sx?.header, heading: sx?.headerTypography }}>
      {actions}
    </CardHeader>
    {children}
  </Box>
);

DashboardCard.Button = Button;
