import { Checkbox as LuxCheckbox } from 'lux/components';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller, FieldValues } from 'react-hook-form';

import { CheckboxControllerProps } from './CheckboxController.types';
export const CheckboxController = <T extends FieldValues>(props: CheckboxControllerProps<T>) => {
  const { name, control, size, label, required, isDisabled, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          control={
            <LuxCheckbox
              checked={value}
              size={size}
              onChange={onChange}
              value={value}
              required={required}
              disabled={isDisabled}
              {...rest}
            />
          }
          label={label}
        />
      )}
    />
  );
};
