import { Styles } from 'styles/theme';

export const wrapper: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  rowGap: 1,
  width: '100%',
  paddingX: 1,
};

export const title: Styles = {
  color: 'text.primary',
};
