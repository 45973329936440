import { SkillWeight } from 'app/need/Need.enums';
import { Skill } from 'api/types/Need.types';

export const sortSkillsByWeight = (skills: Skill[]) =>
  skills.sort((a, b) => {
    const weightToPriority = {
      [SkillWeight.mustHave]: 3,
      [SkillWeight.shouldHave]: 2,
      [SkillWeight.niceToHave]: 1,
    };

    return weightToPriority[b.weight as SkillWeight] - weightToPriority[a.weight as SkillWeight];
  });
