import { useContext } from 'react';

import { ProjectTimesheetDataContext } from 'context/projectTimesheet/projectTimesheetDataContext/ProjectTimesheetDataContext';

export const useProjectTimesheetData = () => {
  const context = useContext(ProjectTimesheetDataContext);

  if (context === undefined) {
    throw new Error('ProjectTimesheetDataContext must be within ProjectTimesheetDataContextController');
  }

  return context;
};
