import { MutationFn } from 'hooks/useMutation/useMutation.types';
import { AssignmentId } from 'api/types/AssignmentType.enum';

import { EditAssignmentParams } from './editNeedActions.types';

export const editAssignmentMutation: (
  projectId: string,
  assignmentId: AssignmentId,
) => MutationFn<EditAssignmentParams> = (projectId: string, assignmentId: AssignmentId) => (body) => ({
  endpoint: `/projects/${projectId}/assignments/${assignmentId}`,
  method: 'PATCH',
  params: body,
});
