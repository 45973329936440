import * as React from 'react';

import { closeSnackbar, SnackbarContent } from 'notistack';
import { Snackbar as LuxSnackbar } from 'lux/components';

import * as styles from './Snackbar.styles';
import { SnackbarProps } from './Snackbar.types';

export const Snackbar = React.forwardRef<HTMLDivElement, SnackbarProps>(
  (
    {
      id,
      message,
      variant,
      anchorOrigin,
      autoHideDuration,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      persist,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      iconVariant,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      hideIconVariant,
      luxSnackbarActionLabel,
      luxSnackbarAction,
      isWide,
      ...props
    },
    ref,
  ) => (
    <SnackbarContent data-cy="snackbar" ref={ref} role="alert" {...props}>
      <LuxSnackbar
        open
        hasCloseButton
        severity={variant === 'default' ? undefined : variant}
        sx={styles.snackbar(isWide)}
        onClose={() => closeSnackbar(id)}
        message={message}
        action={luxSnackbarAction}
        actionLabel={luxSnackbarActionLabel}
        anchorOrigin={anchorOrigin}
        autoHideDuration={autoHideDuration}
      />
    </SnackbarContent>
  ),
);
