import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { convertPeopleDates } from 'utils/convertPeopleDates';
import { usePeople } from 'hooks/usePeople/usePeople';
import { EmptyState } from 'ui/emptyState/EmptyState';
import { useLocale } from 'hooks/useLocale/useLocale';
import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { AppRoute } from 'routing/AppRoute.enum';

import { PeopleListSkeleton } from './PeopleList.skeleton';
import { PeopleTable } from './peopleTable/PeopleTable';
import { PeopleListProps } from './PeopleList.types';

export const PeopleList = ({ personAction }: PeopleListProps) => {
  const navigate = useNavigate();
  const { t } = useLocale();
  const { filters, resetAllFiltersToDefault } = usePeopleFilters();
  const { availabilityFrom, availabilityTo } = filters;

  useEffect(() => {
    if (availabilityFrom && availabilityTo && availabilityTo < availabilityFrom) {
      navigate(AppRoute.people);
    }
  }, [availabilityFrom, availabilityTo, navigate]);

  const { data: peopleData, isLoading, isFetching, fetchNextPage, hasNextPage } = usePeople(filters);

  if (isLoading) {
    return <PeopleListSkeleton />;
  }

  const handleListEndReached = () => {
    if (!hasNextPage || isFetching) {
      return;
    }
    fetchNextPage();
  };

  const people = peopleData?.pages.flatMap((page) => page.people) || [];

  const peopleWithFormattedDates = convertPeopleDates(people);

  return (
    <>
      {!people?.length ? (
        <EmptyState
          illustration="searchPeople"
          title={t('people.peopleList.empty.title')}
          message={t('people.peopleList.empty.message')}
          actionBtn={<Button onClick={() => resetAllFiltersToDefault()}>{t('filters.reset')}</Button>}
        />
      ) : (
        <PeopleTable
          onEndReached={handleListEndReached}
          peopleData={peopleWithFormattedDates}
          personAction={personAction}
        />
      )}
    </>
  );
};
