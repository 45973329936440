import { RateCard } from 'api/actions/getProject/getProject.types';
import { Seniority } from 'api/types/Seniority.enum';

import { RateHistoryEntry, RatesHistoryData, SeniorityRates } from './RatesHistoryModal.types';

const senioritiesOrder = {
  [Seniority.junior]: 1,
  [Seniority.mid]: 2,
  [Seniority.senior]: 3,
  [Seniority.withoutLevel]: 4,
};

const sortSeniorities = (seniorities: Seniority[]) =>
  [...seniorities].sort((a, b) => senioritiesOrder[a] - senioritiesOrder[b]);

const getAllRolesAndSeniorities = (rateCards: RateCard[]) => {
  const rolesAndSeniorities = rateCards.reduce<Record<string, Seniority[]>>((acc, rateCard) => {
    Object.entries(rateCard.items).forEach(([key, rateCardItems]) => {
      if (!acc[key]) {
        acc[key] = [];
      }

      rateCardItems.forEach((rateCardItem) => {
        if (acc[key] && !acc[key].includes(rateCardItem.seniority)) {
          acc[key].push(rateCardItem.seniority);
        }
      });
    });
    return acc;
  }, {});

  const sortedRolesAndSenioritiesEntries = Object.entries(rolesAndSeniorities)
    .sort((a, b) => a[0].toLowerCase().localeCompare(b[0].toLowerCase()))
    .reduce<[string, Seniority[]][]>((acc, [role, seniorities]) => [...acc, [role, sortSeniorities(seniorities)]], []);

  return Object.fromEntries(sortedRolesAndSenioritiesEntries);
};

const getRatesForRoleAndSeniority = (rateCards: RateCard[], role: string, seniority: Seniority) =>
  rateCards.reduce<RateHistoryEntry[]>((acc, rateCard) => {
    const foundRateForRoleAndSeniority =
      rateCard.items[role] && rateCard.items[role].find((rateCardItem) => rateCardItem.seniority === seniority);
    return [
      ...acc,
      {
        amount: foundRateForRoleAndSeniority ? foundRateForRoleAndSeniority.amount : undefined,
        currency: rateCard.currency,
      },
    ];
  }, []);

export const getRatesHistoryData = (rateCards: RateCard[]) => {
  const allRolesAndSeniorities = getAllRolesAndSeniorities(rateCards);

  const ratesHistoryData = Object.entries(allRolesAndSeniorities).reduce<RatesHistoryData>(
    (acc, [role, seniorities]) => ({
      ...acc,
      [role]: seniorities.reduce<SeniorityRates>((acc, curr) => {
        acc[curr] = getRatesForRoleAndSeniority(rateCards, role, curr);
        return acc;
      }, {}),
    }),
    {},
  );

  return ratesHistoryData;
};
