import { useEffect, useState } from 'react';

import { Box, DialogActions, DialogContent, DialogTitle, Divider, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { joiResolver } from '@hookform/resolvers/joi';
import { SubmitHandler } from 'react-hook-form';
import { Button, Tooltip } from 'lux/components';
import { InfoIcon } from 'lux/icons';

import { AssignmentId } from 'api/types/AssignmentType.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { usePlanProjectExtension } from 'hooks/usePlanProjectExtension/usePlanProjectExtension';
import {
  MAX_SUPPORTED_DATE,
  addDaysToDate,
  formatDate,
  getCurrentDayStartDate,
  parseDate,
  subtractDaysFromDate,
} from 'utils/dateUtils';
import { Checkbox } from 'ui/form/checkbox/Checkbox';
import { DatePicker } from 'ui/form/datePicker/DatePicker';
import { InfoBox } from 'ui/infoBox/InfoBox';
import { SquareIconButton } from 'ui/squareIconButton/SquareIconButton';
import { Translation } from 'ui/translation/Translation';
import { useAssignmentsFilters } from 'hooks/useAssignmentsFilters/useAssignmentsFilters';
import { useAssignments } from 'hooks/useAssignments/useAssignments';
import { mapAssignmentData } from 'shared/assignments/Assignments.functions';

import { PlanProjectExtensionFormValues, PlanProjectExtensionContentProps } from './PlanProjectExtensionContent.types';
import { planProjectExtensionSchema } from './PlanProjectExtension.schema';
import * as styles from './PlanProjectExtension.styles';
import { PlanAssignmentExtensionTable } from './planAssignmentExtensionTable/PlanAssignmentExtensionTable';

export const PlanProjectExtensionContent = ({
  projectEndDate,
  projectId,
  onClose,
}: PlanProjectExtensionContentProps) => {
  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isLoading } = usePlanProjectExtension(projectId);

  const { filters } = useAssignmentsFilters();

  const { data } = useAssignments({
    project: projectId,
    ...filters,
  });

  const projectAssignments = mapAssignmentData(data?.pages) ?? [];
  const [selectedAssignmentIds, setSelectedAssignmentIds] = useState<Record<string, boolean>>(
    Object.fromEntries(projectAssignments.map((assignment) => [assignment.id, false])),
  );

  const { control, handleSubmit, watch, setValue, getFieldState } = useIntlForm<PlanProjectExtensionFormValues>({
    defaultValues: {
      endDate: parseDate(projectEndDate),
      extensionDate: null,
      extendIndefinitely: false,
    },
    mode: 'onSubmit',
    resolver: joiResolver(planProjectExtensionSchema()),
  });

  const onSubmit: SubmitHandler<PlanProjectExtensionFormValues> = (data) => {
    mutate(
      {
        ...(!data.extendIndefinitely && { extensionDate: formatDate(data.extensionDate as Date) }),
        assignmentIds: Object.entries(selectedAssignmentIds)
          .filter((entry) => entry[1])
          .map((entry) => entry[0]),
      },
      {
        onSuccess: () => {
          onClose();
          enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.planProjectExtension.success'] }));
        },
      },
    );
  };

  const [extendIndefinitely, endDate, extensionDate] = watch(['extendIndefinitely', 'endDate', 'extensionDate']);

  useEffect(() => {
    if (extendIndefinitely) {
      setValue('extensionDate', null);
    }
  }, [extendIndefinitely]);

  const handleChangeAssignment = (assignmentId: AssignmentId, value: boolean) => {
    setSelectedAssignmentIds((prev) => ({ ...prev, [assignmentId]: value }));
  };

  const handleChangeAllAssignments = (value: boolean) => {
    setSelectedAssignmentIds(
      Object.fromEntries(Object.entries(selectedAssignmentIds).map((entry) => [entry[0], value])),
    );
  };

  return (
    <>
      <DialogTitle>
        <Translation id="projectDetails.planProjectExtensionModal.title" />
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={3} alignItems="flex-start">
            <Checkbox
              name="extendIndefinitely"
              label={formatMessage({
                id: AppMessages['projectDetails.planProjectExtensionModal.extendIndefinitely.label'],
              })}
              control={control}
            />
            <Stack sx={styles.datePickersWrapper}>
              <DatePicker
                minDate={getCurrentDayStartDate()}
                maxDate={extensionDate ? subtractDaysFromDate(extensionDate, 1) : MAX_SUPPORTED_DATE}
                name="endDate"
                control={control}
                label={formatMessage({ id: AppMessages['projectDetails.planProjectExtensionModal.endDate.label'] })}
                disableUntilDate={subtractDaysFromDate(getCurrentDayStartDate(), 1)}
                fullWidth
                isDisabled
              />
              <DatePicker
                minDate={addDaysToDate(endDate, 1)}
                name="extensionDate"
                control={control}
                label={formatMessage({
                  id: AppMessages['projectDetails.planProjectExtensionModal.extensionDate.label'],
                })}
                disableUntilDate={addDaysToDate(endDate, 1)}
                isDisabled={extendIndefinitely || !!getFieldState('endDate').error}
                fullWidth
                defaultCalendarMonth={addDaysToDate(endDate, 1)}
              />
              <Tooltip
                title={formatMessage({
                  id: AppMessages['projectDetails.planProjectExtensionModal.infoIcon.tooltip'],
                })}
                arrow
                placement="top"
              >
                <Box>
                  <SquareIconButton disabledOpaque disableRipple style={styles.infoIcon}>
                    <InfoIcon />
                  </SquareIconButton>
                </Box>
              </Tooltip>
            </Stack>
            <Divider sx={styles.divider} />
            <InfoBox
              title={formatMessage({
                id: AppMessages['projectDetails.planProjectExtensionModal.infoBox'],
              })}
            />
            <PlanAssignmentExtensionTable
              assignments={projectAssignments}
              onChangeAssignment={handleChangeAssignment}
              onChangeAllAssignments={handleChangeAllAssignments}
              selectedAssignmentIds={selectedAssignmentIds}
              extensionDate={extensionDate}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="text" type="button" onClick={onClose}>
            <Translation id="button.cancel" />
          </Button>
          <Button loading={isLoading} type="submit" variant="contained">
            <Translation id="projectDetails.planProjectExtensionModal.button.submit" />
          </Button>
        </DialogActions>
      </form>
    </>
  );
};
