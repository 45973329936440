import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { MAX_SUPPORTED_DATE, parseDate } from 'utils/dateUtils';
import { DatePickerEdit } from 'ui/form/inlineEdit/datePickerEdit/DatePickerEdit';
import { useEditDeposit } from 'hooks/useEditDepositAmount/useEditDepositAmount';
import { isDepositChangeAllowed } from '../Deposits.utils';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';

import { DepositDateEditProps } from './DepositDateEdit.types';

export const DepositDateEdit = ({ workStatement, deposit }: DepositDateEditProps) => {
  const { mutateAsync, isLoading } = useEditDeposit(workStatement.id, deposit.id);
  const { projectDetails } = useProjectDetails();
  const { isInvoiceEditBlocked } = useProjectBillingSummaryData();
  const { isReport } = useProjectBillingSummaryData();

  const getMaxDate = () => {
    if (workStatement.endDate) {
      return parseDate(workStatement.endDate);
    }

    if (projectDetails.endDate) {
      return parseDate(projectDetails.endDate);
    } else {
      MAX_SUPPORTED_DATE;
    }
  };

  const getMinDate = () => {
    if (workStatement.startDate) {
      return parseDate(workStatement.startDate);
    }

    if (projectDetails.startDate) {
      return parseDate(projectDetails.startDate);
    }
  };

  return (
    <DatePickerEdit
      name="paymentDue"
      value={deposit.paymentDue}
      minDate={getMinDate()}
      maxDate={getMaxDate()}
      label="projectDetails.billingSummary.workStatementDetails.deposits.paymentDue"
      defaultValues={{ paymentDue: null }}
      onSubmit={(value) => mutateAsync({ paymentDue: value.paymentDue })}
      loading={isLoading}
      disabled={!isDepositChangeAllowed(deposit.status) || isInvoiceEditBlocked || isReport}
    />
  );
};
