import { Stack, Box } from '@mui/material';
import { Typography } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { InfoBox } from 'ui/infoBox/InfoBox';
import { convertRateToDecimal } from 'utils/convertRate';

import { RateWarningProps } from './RateWarning.types';

export const RateWarning = ({ warning, employeeId }: RateWarningProps) => {
  const { formatMessage } = useLocale();

  return (
    <InfoBox
      title={formatMessage({ id: AppMessages['assignOrProposeAnyway.warnings.rate.title'] })}
      variant="warning"
      actionLabel={formatMessage({ id: AppMessages['assignOrProposeAnyway.warnings.rate.action'] })}
      onAction={() => window.open(`${window.location.origin}/people/${employeeId}`, '_blank', 'noopener,noreferrer')}
    >
      <Stack direction="row" spacing={1.25}>
        <Box>
          {formatMessage({ id: AppMessages['assignOrProposeAnyway.warnings.rate.projectRate'] })}{' '}
          <Typography component="span" variant="inherit" fontWeight={500}>
            {convertRateToDecimal(warning.inProject.rate)} {warning.inProject.currency}
          </Typography>
        </Box>
        <Box>
          {formatMessage({ id: AppMessages['assignOrProposeAnyway.warnings.rate.personCost'] })}{' '}
          <Typography component="span" variant="inherit" fontWeight={500}>
            {convertRateToDecimal(warning.person.rate)} {warning.person.currency}
          </Typography>
        </Box>
      </Stack>
    </InfoBox>
  );
};
