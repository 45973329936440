import { Stack, Box } from '@mui/material';
import { Badge, Tooltip, Typography } from 'lux/components';

import { AssignmentStatusBadge } from 'ui/assignmentStatusBadge/AssignmentStatusBadge';
import { formatDate } from 'utils/dateUtils';

import * as styles from './TimelineEvent.styles';
import { TimelineEventProps, TimelineEventStyleParams, TimelineEventType } from './TimelineEvent.types';

export const TimelineEvent = ({
  columnCount,
  type,
  name,
  status,
  startDate,
  endDate,
  rowsCount = 1,
  eventStartsBeforePeriod,
  eventEndsAfterPeriod,
}: TimelineEventProps) => {
  const shouldWrapBadge = rowsCount > 5;
  const shouldDisplayEventContent = columnCount === undefined || columnCount >= 2;

  const styleParams: TimelineEventStyleParams = {
    type,
    shouldWrapBadge,
    eventStartsBeforePeriod,
    eventEndsAfterPeriod,
  };

  const event = {
    [TimelineEventType.absence]: (
      <Stack sx={styles.timelineEvent(styleParams)} justifyContent="space-between">
        {shouldDisplayEventContent && <Typography variant="h6">{name}</Typography>}
        {shouldDisplayEventContent && startDate && endDate && (
          <Typography variant="caption" color="text.secondary">
            {formatDate(new Date(startDate))} - {formatDate(new Date(endDate))}
          </Typography>
        )}
      </Stack>
    ),
    [TimelineEventType.proposal]: (
      <Stack direction="row" minHeight={35} spacing={1} sx={styles.timelineEvent(styleParams)}>
        {shouldDisplayEventContent && (
          <Typography noWrap variant="caption">
            {name}
          </Typography>
        )}
        {status && shouldDisplayEventContent && <Badge isStatic color="gray" badgeContent={status} />}
      </Stack>
    ),
    [TimelineEventType.assignment]: (
      <Stack
        minHeight={98}
        direction={shouldWrapBadge ? 'row' : 'column'}
        spacing={1}
        sx={styles.timelineEvent(styleParams)}
      >
        {shouldDisplayEventContent && <Typography variant="subtitle2">{name}</Typography>}
        {status && shouldDisplayEventContent && <AssignmentStatusBadge status={status} />}
      </Stack>
    ),
  };

  const tooltipContent = {
    [TimelineEventType.absence]: (
      <>
        <Box>{name}</Box>
        {startDate && endDate && !shouldDisplayEventContent && (
          <Box>
            {formatDate(new Date(startDate))} - {formatDate(new Date(endDate))}
          </Box>
        )}
      </>
    ),
    [TimelineEventType.proposal]: (
      <Box display="flex" alignItems="center" gap={1.5}>
        <Box component="span">{name}</Box>
        {status && !shouldDisplayEventContent && <Badge isStatic badgeContent={status} color="gray" />}
      </Box>
    ),
    [TimelineEventType.assignment]: (
      <Box display="flex" alignItems="center" gap={1.5}>
        <Box component="span">{name}</Box>
        {status && !shouldDisplayEventContent && <AssignmentStatusBadge status={status} />}
      </Box>
    ),
  };

  return (
    <Tooltip
      title={shouldDisplayEventContent ? name : tooltipContent[type]}
      arrow
      followCursor={shouldDisplayEventContent}
      placement={shouldDisplayEventContent ? 'bottom' : 'right'}
    >
      {event[type]}
    </Tooltip>
  );
};
