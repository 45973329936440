import Joi from 'joi';

import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';

export const selectEditSchema = (name: string, isMultiple?: boolean) => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object({
    [name]: isMultiple
      ? Joi.array()
          .items(Joi.string().required())
          .messages({
            'array.includesRequiredUnknowns': formatMessage({ id: AppMessages['validation.empty'] }, { label: name }),
          })
      : Joi.string().required(),
  });
};
