import { FormProvider } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { useLocale } from 'hooks/useLocale/useLocale';
import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';
import { getAdditionalValueContentSchema } from '../../additionalValueContent/AdditionalValueContent.schema';
import { AdditionalValueForm } from '../../AddiitionalValueForm.type';
import { AdditionalValueContent } from '../../additionalValueContent/AdditionalValueContent';
import { useAddDiscount } from 'hooks/useAddDiscount/useAddDiscount';
import { AddDiscountParams } from 'api/actions/addDiscount/addDiscount.types';
import { convertRateToInteger } from 'utils/convertRate';
import { Translation } from 'i18n/messages';

import { AddDiscountContentProps } from './AddDiscountContent.types';

const defaultValues: AdditionalValueForm = {
  amount: 0,
  label: '',
};

const amountLabel: Translation = 'projectDetails.additionalValuesForm.discount.add.amount.label';
const titleLabel: Translation = 'projectDetails.additionalValuesForm.discount.add.title.label';

export const AddDiscountContent = ({
  onClose,
  workStatementId,
  billingCycleId,
  invoiceId,
}: AddDiscountContentProps) => {
  const { t } = useLocale();
  const { mutate, isLoading } = useAddDiscount(workStatementId);

  const form = useIntlForm<AdditionalValueForm>({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(
      getAdditionalValueContentSchema({
        titleLabel,
        amountLabel,
      }),
    ),
  });

  const onSubmit = (data: AdditionalValueForm) => {
    const payload: AddDiscountParams = {
      amount: convertRateToInteger(data.amount),
      label: data.label,
      billingCycleId,
      invoiceId,
    };
    mutate(payload, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <DialogInnerWrapper
      title={t('projectDetails.additionalValuesForm.discount.add.title')}
      loading={isLoading}
      onClose={onClose}
      submitText={t('button.save')}
      onSubmit={form.handleSubmit(onSubmit)}
      isFormValid={form.formState.isValid}
    >
      <FormProvider {...form}>
        <AdditionalValueContent amountLabel={amountLabel} titleLabel={titleLabel} onClose={onClose} />
      </FormProvider>
    </DialogInnerWrapper>
  );
};
