import { Table, Typography } from 'lux/components';

import { Translation } from 'ui/translation/Translation';
import { useInvoices } from 'hooks/useInvoices/useInvoices';
import { EmptyState } from 'ui/emptyState/EmptyState';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { sortArr } from 'utils/sortArr';
import { CardWrapper } from 'ui/cardWrapper/CardWrapper';

import * as styles from './ProjectInvoices.styles';
import { InvoiceRow } from './invoiceRow/InvoiceRow';
import { LoadMoreInvoices } from './loadMoreInvoices/LoadMoreInvoices';
import { CostSummary } from './costSummary/CostSummary';
import { ProjectInvoicesSkeleton } from './ProjectInvoices.skeleton';

export const ProjectInvoices = () => {
  const { t } = useLocale();
  const {
    projectDetails: { id: projectId },
  } = useProjectDetails();
  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } = useInvoices({ projectId });

  if (isLoading) {
    return (
      <CardWrapper>
        <ProjectInvoicesSkeleton />;
      </CardWrapper>
    );
  }
  const invoicesData = data?.pages.flatMap((page) => page.invoices);

  const totalAmount = data?.pages[0].totalProjectCost;

  const sortedInvoices = invoicesData
    ? sortArr(
        invoicesData,
        (invoiceOne, invoiceTwo) =>
          new Date(invoiceOne.billingCycle.start).getTime() - new Date(invoiceTwo.billingCycle.start).getTime(),
      )
    : [];

  return (
    <CardWrapper>
      {!sortedInvoices.length ? (
        <EmptyState
          illustration="relaxPeople"
          title={t('projectDetails.invoices.emptyInvoices.title')}
          message={t('projectDetails.invoices.emptyInvoices.message')}
        />
      ) : (
        <>
          <Typography>
            <Translation id="projectDetails.invoices.title" />
          </Typography>
          <Table.Table sx={styles.table}>
            <Table.Head>
              <Table.Row>
                <Table.Cell size="small">
                  <Translation id="projectDetails.invoices.invoiceNumber" />
                </Table.Cell>
                <Table.Cell size="small">
                  <Translation id="projectDetails.invoices.status" />
                </Table.Cell>
                <Table.Cell size="small">
                  <Translation id="projectDetails.invoices.workStatement" />
                </Table.Cell>
                <Table.Cell size="small">
                  <Translation id="projectDetails.invoices.billingCycle" />
                </Table.Cell>
                <Table.Cell size="small">
                  <Translation id="projectDetails.invoices.invoiceAmount" />
                </Table.Cell>
                <Table.Cell size="small" />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {sortedInvoices.map((invoice) => (
                <InvoiceRow key={invoice.id} invoice={invoice} isClientView={false} />
              ))}
              {hasNextPage && <LoadMoreInvoices handleLoad={fetchNextPage} isLoading={isFetching} />}
            </Table.Body>
          </Table.Table>
          {totalAmount?.value && (
            <CostSummary label="projectDetails.invoices.projectCostSummary" amount={totalAmount.value} />
          )}
        </>
      )}
    </CardWrapper>
  );
};
