import { Currency } from 'api/types/Currency.enum';

export const getCurrency = (currency: string) => {
  switch (currency.toLowerCase()) {
    case 'pln':
      return Currency.pln;
    case 'usd':
      return Currency.usd;
    case 'gbp':
      return Currency.gbp;
    case 'eur':
      return Currency.eur;
    default:
      return Currency.pln;
  }
};
