import { Styles } from 'styles/theme';

export const table: Styles = {
  '>.MuiBox-root:not(:last-of-type)': {
    marginBottom: 3,
  },
};

export const tableRow: Styles = {
  height: 56,
  textDecoration: 'none',
  cursor: 'pointer',

  '& .hoverVisible': {
    visibility: 'hidden',
  },

  '&:hover .hoverVisible': {
    visibility: 'visible',
  },
};
