import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { Button } from 'lux/components';
import { ChevronLeftIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useTitle } from 'hooks/useTitle/useTitle';
import { usePersonData } from 'hooks/usePersonData/usePersonData';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';

import * as styles from './Person.styles';
import { PersonHeader } from './personHeader/PersonHeader';
import { PersonGrid } from './personGrid/PersonGrid';

export const Person = () => {
  const { formatMessage } = useLocale();
  const {
    person: { firstName, lastName },
  } = usePersonData();

  useTitle(formatMessage({ id: AppMessages['title.personDetails'] }, { personName: `${firstName} ${lastName}` }));

  return (
    <Box paddingX={6} paddingTop={5} paddingBottom={3}>
      <Button
        sx={styles.backButton}
        size="large"
        variant="text"
        component={Link}
        to={AppRoute.people}
        color="secondary"
        startIcon={<ChevronLeftIcon />}
      >
        {formatMessage({ id: AppMessages['button.back'] })}
      </Button>
      <PersonHeader />
      <PersonGrid />
    </Box>
  );
};
