import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { FteFilter as SharedFteFilter } from 'shared/filters/fteFilter/FteFilter';

import { FteFilterProps } from './FteFilter.types';

export const FteFilter = ({ variant }: FteFilterProps) => {
  const {
    filters: { fte },
    toggleFilter,
    clearFilter,
  } = usePeopleFilters();

  const handleFilterChange = (value: string) => {
    toggleFilter('fte', value);
  };

  const clearFte = () => {
    clearFilter('fte');
  };

  return (
    <SharedFteFilter
      variant={variant}
      fte={fte}
      dataCy="available-people_filter-fte"
      onFilterChange={handleFilterChange}
      onClear={clearFte}
    />
  );
};
