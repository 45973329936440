import { Styles } from 'styles/theme';

export const button: Styles = {
  '&.MuiButton-root': {
    paddingY: 0,
    paddingX: 1,
    fontSize: 12,
    '&:hover': {
      background: 'transparent',
    },
  },
};
