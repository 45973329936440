import { EmploymentType } from 'api/types/EmploymentType.enum';
import { Seniority } from 'api/types/Seniority.enum';
import { createDepartmentLabel } from 'config/data/departments';
import { AppMessages, Translation } from 'i18n/messages';
import { intl } from 'context/locale/intl/Intl';
import { Role } from '../api/types/Role.enum';
import { DepartmentCode } from '../api/types/DepartmentCode.enum';

import { sortArr } from './sortArr';

export const dashIfEmpty = (str: string | number | string[]) => str || '-';

export const formatSeniorityName = (name: string | null, withoutLvlDash = true) => {
  const { formatMessage } = intl.currentIntl;

  switch (name) {
    case Seniority.junior:
      return formatMessage({ id: AppMessages['people.seniority.junior'] });
    case Seniority.mid:
      return formatMessage({ id: AppMessages['people.seniority.mid'] });
    case Seniority.senior:
      return formatMessage({ id: AppMessages['people.seniority.senior'] });
    case Seniority.withoutLevel:
      return withoutLvlDash ? '' : formatMessage({ id: AppMessages['people.seniority.withoutLevel'] });
    default:
      return '';
  }
};

export const formatRoleName = (role: string, seniority: Seniority) =>
  `${seniority !== Seniority.withoutLevel ? formatSeniorityName(seniority) : ''} ${role}`;

export const formatCompanyPosition = (role: Role) => {
  const { formatMessage } = intl.currentIntl;

  switch (role) {
    case 'TEAM_MANAGER':
      return formatMessage({ id: AppMessages['people.role.teamManager'] });
    case 'EMPLOYEE':
      return formatMessage({ id: AppMessages['people.role.employee'] });
    case 'HEAD':
      return formatMessage({ id: AppMessages['people.role.head'] });
    case 'BOARD':
      return formatMessage({ id: AppMessages['people.role.board'] });
    case 'UNCERTAIN':
      return formatMessage({ id: AppMessages['people.role.uncertain'] });
    default:
      return null;
  }
};

export const getEmploymentTypeTranslationKey = (type: string): Translation => {
  switch (type) {
    case EmploymentType.LTSH:
      return 'people.employmentType.subcontractor';
    case EmploymentType.NPR:
      return 'people.employmentType.unemployed';
    default:
      return 'people.employmentType.inhouse';
  }
};

export const getPersonFullRole = (seniority: Seniority | null, departmentCode: string) => {
  const departmentName = createDepartmentLabel(departmentCode);

  if (!seniority || seniority === Seniority.withoutLevel) {
    return departmentName;
  }

  return `${formatSeniorityName(seniority)} ${departmentName}`;
};

export const sortCaseInsensitive = (arr: string[]) =>
  sortArr(arr, (a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));

export const urlRegex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;

export const getSeniorityRole = (seniority: Seniority | null, departmentCode: DepartmentCode) => {
  const seniorityPart = seniority ? `${formatSeniorityName(seniority)} ` : '';
  const departmentPart = createDepartmentLabel(departmentCode);

  return `${seniorityPart} ${departmentPart}`;
};
