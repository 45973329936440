import { useState } from 'react';

import { Button } from 'lux/components';
import { Box, Stack } from '@mui/material';

import { useDialog } from 'hooks/useDialog/useDialog';
import { AddPersonRateInput } from 'app/person/personDetails/personRates/addPersonRateInput/AddPersonRateInput';
import { EditPersonRateInput } from 'app/person/personDetails/personRates/editPersonRateInput/EditPersonRateInput';
import { Translation } from 'ui/translation/Translation';

import { PersonRatesHistoryModal } from './personRatesHistoryModal/PersonRatesHistoryModal';
import * as styles from './PersonRates.styles';
import type { PersonRatesProps } from './PersonRates.types';

export const PersonRates = ({
  assignmentRates,
  currentRate,
  futureRate,
  personId,
  isPersonPlaceholder,
}: PersonRatesProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { isOpen, setOpen, setClose } = useDialog();

  return (
    <Stack sx={styles.stack} paddingBottom={isEditMode ? 5 : 1}>
      {!currentRate ? (
        <AddPersonRateInput
          personId={personId}
          isPersonPlaceholder={isPersonPlaceholder}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
        />
      ) : (
        <EditPersonRateInput
          assignmentRates={assignmentRates}
          currentRate={currentRate}
          futureRate={futureRate}
          personId={personId}
          isPersonPlaceholder={isPersonPlaceholder}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
        />
      )}

      {!isEditMode && currentRate && !isPersonPlaceholder && (
        <Box>
          <Button onClick={setOpen} variant="text" size="small" color="primary" sx={styles.button}>
            <Translation id="personDetails.accordion.details.personCost.historyButton" />
          </Button>
        </Box>
      )}

      <PersonRatesHistoryModal isOpen={isOpen} onClose={setClose} />
    </Stack>
  );
};
