import { useQueryClient } from '@tanstack/react-query';

import { updateBillingDay } from 'api/actions/updateBillingDay/updateBillingDay';
import { useProjectTimesheetData } from '../useProjectTimesheetData/useProjectTimesheetData';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { projectsKeys } from 'utils/queryKeys';

export const useUpdateBillingDay = () => {
  const { invalidateBillingCycleCache, projectId } = useProjectTimesheetData();
  const queryClient = useQueryClient();

  const { formatMessage } = useLocale();

  return useMutationDomainError(['updateBillingDay'], updateBillingDay, {
    onSuccess: () => {
      invalidateBillingCycleCache();
      queryClient.invalidateQueries(projectsKeys.projectBillingCycle(projectId));
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.updateBillingDay.error'] }),
  });
};
