import { CreateProjectForm } from './CreateProject.types';

export const defaultValues: CreateProjectForm = {
  client: {
    id: '',
    name: '',
    industries: [],
    country: '',
    accountNumber: '',
  },
  clientId: '',
  project: {
    name: '',
    description: '',
    startDate: null,
    endDate: null,
    attributes: [],
    tags: [],
    currency: '',
    timeDiff: '',
    budget: '',
    contactPerson: '',
    codename: '',
  },
  rateCards: [
    {
      role: '',
      juniorRate: '',
      regularRate: '',
      seniorRate: '',
      withoutLevelRate: '',
      seniorityEnabled: false,
    },
  ],
};
