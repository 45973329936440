import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useMutation } from 'hooks/useMutation/useMutation';
import { removeUncertainPersonMutation } from 'api/actions/removeUncertainPerson/removeUncertainPerson';
import { peopleKeys } from 'utils/queryKeys';
import { getApiError } from '../../api/utils/getApiError';

export const useRemoveUncertainPerson = (employeeId: number) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  return useMutation(['removeUncertainPersonMutation'], removeUncertainPersonMutation(employeeId), {
    onSuccess: () => {
      queryClient.invalidateQueries(peopleKeys.peopleList);
      queryClient.invalidateQueries(peopleKeys.singlePerson(String(employeeId)));
    },
    onError: (e) => {
      enqueueSnackbar(getApiError(e), { variant: 'error' });
    },
  });
};
