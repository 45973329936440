import { Typography } from 'lux/components';
import { Box } from '@mui/system';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Loader } from 'ui/loader/Loader';

import * as styles from './LoadingSpinner.styles';

export const LoadingSpinner = () => {
  const { formatMessage } = useLocale();

  return (
    <Box sx={styles.wrapper}>
      <Loader fullHeight={false} />
      <Typography variant="h6" sx={styles.text}>
        {formatMessage({ id: AppMessages['projectDetails.loadingData'] })}
      </Typography>
    </Box>
  );
};
