import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { EditChargeParams } from './editChargeActions.types';

export const editChargeMutation: (workStatementId: string, chargeId: string) => MutationFn<EditChargeParams> =
  (workStatementId: string, chargeId: string) => (body) => ({
    endpoint: `/work-statements/${workStatementId}/charges/${chargeId}`,
    method: 'PATCH',
    params: body,
  });
