import { Box } from '@mui/material';

import { Chips } from 'ui/chips/Chips';

import { ActiveFiltersProps } from './ActiveFilters.types';
import * as styles from './ActiveFilters.styles';

export const ActiveFilters = ({ dataCy, activeFiltersData, onFilterDelete }: ActiveFiltersProps) => (
  <>
    {activeFiltersData.length > 0 && (
      <Box data-cy="active-filter" sx={styles.container}>
        <Chips data-cy={dataCy}>
          {activeFiltersData.map((activeFilter, i) => (
            <Chips.Chip
              key={`${activeFilter.value}.${i}`}
              label={activeFilter.label}
              onDelete={() => onFilterDelete(activeFilter)}
            />
          ))}
        </Chips>
      </Box>
    )}
  </>
);
