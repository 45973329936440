import Joi from 'joi';

import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';

export const defaultTextAreaEditSchema = (name: string, required?: boolean) => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object({
    [name]: required
      ? Joi.string()
          .required()
          .messages({ 'string.empty': formatMessage({ id: AppMessages['validation.empty'] }, { label: name }) })
      : Joi.string().empty('').default(null),
  });
};
