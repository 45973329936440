import { Box, Stack } from '@mui/material';
import { Grid, Typography } from 'lux/components';

import {
  areDatesInTheSameDay,
  formatDate,
  getDateTimestamp,
  isCurrentDate,
  isWeekendDate,
  parseDate,
} from 'utils/dateUtils';

import * as styles from './TimelineBackgroundGrid.styles';
import { TimelineBackgroundGridProps } from './TimelineBackgroundGrid.types';

export const TimelineBackgroundGrid = ({ daysInPeriod, columnsCount, bankHolidays }: TimelineBackgroundGridProps) => (
  <Grid container columns={columnsCount} flexWrap="nowrap" sx={styles.wrapper} spacing={0} gutter={0}>
    {daysInPeriod.map((day) => {
      const today = isCurrentDate(day);
      const weekend = isWeekendDate(day);
      const holiday = bankHolidays.some((holiday) => areDatesInTheSameDay(parseDate(holiday), day));

      return (
        <Grid key={getDateTimestamp(day)} item sx={styles.dayColumn}>
          <Stack sx={styles.dayHeader(today, weekend || holiday)}>
            <Typography variant="caption">{formatDate(day, 'E.')}</Typography>
            <Typography variant="subtitle2">{formatDate(day, 'dd')}</Typography>
          </Stack>
          <Box sx={styles.dayBackground(today, weekend || holiday)} />
        </Grid>
      );
    })}
  </Grid>
);
