import { Box, Stack } from '@mui/system';

import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

export const ClientDetailsRowSkeleton = () => (
  <Stack gap={3} height={'268px'} mt={0.5} justifyContent={'center'}>
    <Box gap={0.5}>
      <TextSkeleton variant="body1" width={80} />
      <TextSkeleton variant="body1" width={200} />
    </Box>
    <Box gap={0.5}>
      <TextSkeleton variant="body1" width={100} />
      <Stack gap={0.5} flexDirection={'row'}>
        <TextSkeleton variant="body1" width={100} />
        <TextSkeleton variant="body1" width={180} />
      </Stack>
    </Box>
    <Box gap={0.5}>
      <TextSkeleton variant="body1" width={100} />
      <TextSkeleton variant="body1" width={150} />
    </Box>
    <Box gap={0.5}>
      <TextSkeleton variant="body1" width={200} />
      <TextSkeleton variant="body1" width={150} />
    </Box>
  </Stack>
);
