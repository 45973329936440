import { useQueryClient } from '@tanstack/react-query';

import { dictionariesKeys, projectsKeys } from 'utils/queryKeys';
import { editKeyRolesMutation } from '../../api/actions/editKeyRoles/editKeyRolesActions';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useEditKeyRoles = (projectId: string) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();

  return useMutationDomainError(['editKeyRoleMutation'], editKeyRolesMutation(projectId), {
    onSuccess: () => {
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(dictionariesKeys.projectRolesList);
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.deleteNeed.error'] }),
  });
};
