import { Styles, theme } from 'styles/theme';

export const needCard: Styles = {
  '&': {
    border: `1px solid ${theme.palette.border.default}`,
    borderRadius: '12px',
    p: '0',
    backgroundColor: theme.palette.surface.default,
  },

  ':hover': {
    '> .proposalTabs': {
      borderColor: 'border.default',
      '> * ': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
};
