import { SxProps } from '@mui/system';

import { Styles } from 'styles/theme';

export const title: Styles = {
  p: 1,
};

export const container: SxProps = {
  pageBreakInside: 'avoid',
};

export const subtotalWrapper: SxProps = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const boxesWrapper: SxProps = {
  gap: 0.5,
  mb: 1.5,

  ['@media print']: {
    mb: 0.5,
  },
};

export const actionButtons: SxProps = {
  gap: 2,
  my: 1.25,
  flexDirection: 'row',
};
