import { Stack, Box, Popover } from '@mui/material';
import { CheckIcon, CloseIcon } from 'lux/icons';
import { useFormContext } from 'react-hook-form';

import { SquareIconButton } from 'ui/squareIconButton/SquareIconButton';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import * as styles from '../ProjectLinks.styles';
import { Link } from 'api/actions/getProject/getProject.types';

import { ManageLinkPopoverProps } from './ManageLinkPopover.types';

export const ManageLinkPopover = ({ isOpen, anchor, onSubmit, onClose }: ManageLinkPopoverProps) => {
  const { formatMessage } = useLocale();
  const {
    control,
    formState: { isValid },
  } = useFormContext<Link>();

  return (
    <Popover
      open={isOpen}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={styles.popover}
    >
      <Stack gap={1}>
        <Stack direction="row" gap={1}>
          <FormTextField
            name="name"
            control={control}
            size="small"
            label={formatMessage({ id: AppMessages['projectDetails.links.linkName'] })}
          />
          <FormTextField
            name="url"
            control={control}
            size="small"
            label={formatMessage({ id: AppMessages['projectDetails.links.url'] })}
          />
        </Stack>
        <Stack direction="row-reverse" gap={1}>
          <SquareIconButton
            variant="contained"
            size="small"
            onClick={onSubmit}
            type="submit"
            disabled={!isValid}
            disabledOpaque
          >
            <CheckIcon />
          </SquareIconButton>
          <Box sx={styles.closeButtonWrapper}>
            <SquareIconButton variant="outlinedGray" size="small" color="secondary" onClick={onClose} disabledOpaque>
              <CloseIcon />
            </SquareIconButton>
          </Box>
        </Stack>
      </Stack>
    </Popover>
  );
};
