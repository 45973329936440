import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { CancelEndDateExtensionPayload } from './cancelEndDateExtension.types.';

export const cancelEndDateExtension: (projectId: string) => MutationFn<CancelEndDateExtensionPayload> =
  (projectId: string) => (body) => ({
    endpoint: `projects/${projectId}/assignments/cancel-extension`,
    method: 'POST',
    params: body,
  });
