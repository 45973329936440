import { useMemo, useState } from 'react';

import { AvailabilityPerson } from 'api/actions/getAvailability/getAvailability.types';

import { AvailabilityPeopleContext } from './AvailabilityPeopleContext';
import {
  AvailabilityPeopleContextControllerProps,
  AvailabilityPeopleContextValueType,
} from './AvailabilityPeopleContext.types';

export const AvailabilityPeopleContextController = ({ children }: AvailabilityPeopleContextControllerProps) => {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);
  const [activeCellId, setActiveCellId] = useState<string | null>(null);
  const [selectedPeopleValue, setSelectedPeopleValue] = useState<AvailabilityPerson[] | null>(null);

  const onPopoverClose = () => {
    setPopoverAnchor(null);
    setSelectedPeopleValue(null);
    setActiveCellId(null);
  };

  const setSelectedPeople = (people: AvailabilityPerson[]) => {
    setSelectedPeopleValue(people);
  };

  const value: AvailabilityPeopleContextValueType = useMemo(
    () => ({
      anchor: popoverAnchor,
      setAnchor: (ref: HTMLElement | null) => setPopoverAnchor(ref),
      selectedValue: selectedPeopleValue,
      onPopoverClose,
      setSelectedPeople,
      activeCellId,
      setActiveCell: (activeCell: string) => setActiveCellId(activeCell),
    }),
    [
      popoverAnchor,
      setPopoverAnchor,
      selectedPeopleValue,
      onPopoverClose,
      setSelectedPeople,
      activeCellId,
      setActiveCellId,
    ],
  );

  return <AvailabilityPeopleContext.Provider value={value}>{children}</AvailabilityPeopleContext.Provider>;
};
