import type { Styles } from 'styles/theme';

export const detailsFooter: Styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 1,

  '> button': {
    flex: 1,
  },
};
