import React from 'react';

import { ComparisonRowSkeleton } from '../comparisonRow/ComparisonRow.skeleton';
import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

export const SkillRowSkeleton = () => (
  <ComparisonRowSkeleton
    needCell={<TextSkeleton width={70} variant="caption" />}
    personCell={<TextSkeleton width={70} variant="caption" />}
  />
);
