import { Button, Table } from 'lux/components';
import { ReplaceIcon } from 'lux/icons';

import { Translation } from 'ui/translation/Translation';

import { LoadMoreInvoicesProps } from './LoadMoreInvoices.types';

export const LoadMoreInvoices = ({ handleLoad, isLoading }: LoadMoreInvoicesProps) => (
  <Table.Row>
    <Table.Cell colSpan={6} align="center">
      <Button
        onClick={handleLoad}
        size="medium"
        variant="text"
        color="primary"
        loading={isLoading}
        disabled={isLoading}
        startIcon={<ReplaceIcon />}
      >
        <Translation id="projectDetails.invoices.loadMore" />
      </Button>
    </Table.Cell>
  </Table.Row>
);
