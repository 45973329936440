import { DialogTitle, DialogContent, DialogActions, Stack, Dialog, Box } from '@mui/material';
import { SubmitHandler } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useSnackbar } from 'notistack';
import { Avatar, Button, Typography } from 'lux/components';
import { parseISO } from 'date-fns';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { PersonStatusBadge } from 'ui/personStatusBadge/PersonStatusBadge';
import { useLocale } from 'hooks/useLocale/useLocale';
import { DatePicker } from 'ui/form/datePicker/DatePicker';
import { AppMessages } from 'i18n/messages';
import { useEndDateExtension } from 'hooks/useEndDateExtension/useEndDateExtension';
import { getPersonFullRole } from 'utils/stringUtils';
import { addDaysToDate, formatDate, isDateAfter } from 'utils/dateUtils';

import { extendEndDateSchema } from './ExtendEndDate.schema';
import { EndDateExtensionContentProps, EndDateExtensionFormValues } from './ExtendEndDateModal.types';
import * as styles from './ExtendEndDateModal.styles';

export const ExtendEndDateModal = ({ open, onClose, assignmentData }: EndDateExtensionContentProps) => {
  const { formatMessage, t } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isLoading } = useEndDateExtension(assignmentData.project.id);

  const getMinDate = () => {
    const parsedStartDate = parseISO(assignmentData.startDate);

    if (assignmentData.endDate) {
      const parsedEndDate = parseISO(assignmentData.endDate);

      return parsedEndDate;
    }

    if (isDateAfter(parsedStartDate, new Date())) {
      return parsedStartDate;
    }

    return new Date();
  };

  const defaultValues = {
    extensionDate: addDaysToDate(getMinDate(), 1),
  };

  const form = useIntlForm<EndDateExtensionFormValues>({
    defaultValues,
    mode: 'onSubmit',
    resolver: joiResolver(extendEndDateSchema(getMinDate())),
  });
  const { control, handleSubmit } = form;

  const personFullName = `${assignmentData.person.firstName} ${assignmentData.person.lastName}`;

  const onSubmit: SubmitHandler<EndDateExtensionFormValues> = async ({ extensionDate }) => {
    if (!extensionDate) {
      return;
    }

    await mutateAsync(
      {
        assignmentIds: [assignmentData.id],
        extensionDate: formatDate(extensionDate),
      },
      {
        onSuccess: () => {
          enqueueSnackbar(
            formatMessage(
              { id: AppMessages['assignment.teamMember.extension.snackbar.success'] },
              {
                personName: personFullName,
                projectName: assignmentData.project.name,
              },
            ),
          );
          onClose();
        },
      },
    );
  };

  return (
    <Dialog open={open} maxWidth="sm" onClose={onClose} sx={styles.dialog}>
      <Stack sx={styles.mainWrapper}>
        <Box sx={styles.container}>
          <Avatar alt={assignmentData.person.firstName} image={assignmentData.person.picture} sx={styles.avatar} />
          <Typography sx={styles.personName}>{personFullName}</Typography>
          <Typography sx={styles.personRole}>
            {getPersonFullRole(assignmentData.person.seniority, assignmentData.role)}
          </Typography>
          <PersonStatusBadge
            availableFte={assignmentData.person.availableFte}
            status={assignmentData.person.availabilityStatus}
          />
        </Box>
        <Stack width={'100%'} sx={styles.actionContainer}>
          <DialogTitle sx={styles.title}>
            {formatMessage(
              { id: AppMessages['assignment.teamMember.extension.title'] },
              { personName: personFullName, projectName: assignmentData.project.name },
            )}
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <Stack marginBottom={3} spacing={2} alignItems="flex-start" sx={styles.form}>
                <Typography fontSize={14} ml={0.5} variant="subtitle2" sx={styles.formText}>
                  {t('assignment.teamMember.extension.chooseDate')}
                </Typography>
                <DatePicker
                  fullWidth
                  disableUntilDate={addDaysToDate(getMinDate(), 1)}
                  label={formatMessage({ id: AppMessages['assignment.teamMember.extension.extensionTo'] })}
                  name="extensionDate"
                  control={control}
                />
              </Stack>
            </DialogContent>
            <DialogActions sx={styles.dialogActions}>
              <Button variant="outlined" type="button" onClick={onClose}>
                {formatMessage({ id: AppMessages['button.cancel'] })}
              </Button>
              <Button color="info" loading={isLoading} type="submit" variant="contained">
                {formatMessage({ id: AppMessages['projectDetails.teamDetails.extension.extendButton'] })}
              </Button>
            </DialogActions>
          </form>
        </Stack>
      </Stack>
    </Dialog>
  );
};
