import { enqueueSnackbar } from 'notistack';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { updateWorkStatementNote } from '../../api/actions/updateWorkStatementNote/updateWorkStatementNote';
import { useProjectBillingSummaryData } from '../useProjectBillingSummaryData/useProjectBillingSummaryData';

export const useUpdateWorkStatementNote = (workStatementId: string) => {
  const { formatMessage } = useLocale();
  const { invalidateBillingSummaryCache } = useProjectBillingSummaryData();

  return useMutationDomainError(['updateWorkStatementNote'], updateWorkStatementNote(workStatementId), {
    onSuccess: () => {
      invalidateBillingSummaryCache();
      enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.updateWorkStatementNote.success'] }));
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.updateWorkStatementNote.error'] }),
  });
};
