import { Tabs } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useRejections } from 'hooks/useRejections/useRejections';
import { RejectionSource } from 'config/data/rejection/RejectionSource.enum';

import { TabRejectedProps } from './TabRejected.types';

export const TabRejected = ({ projectId, ...rest }: TabRejectedProps) => {
  const { formatMessage } = useLocale();

  const { data: rejectionsData } = useRejections({
    project: projectId,
    source: [RejectionSource.proposal],
  });

  return (
    <Tabs.Tab
      {...rest}
      label={formatMessage(
        { id: AppMessages['projectDetails.teamDetails.rejected.header'] },
        { rejected: rejectionsData?.pages[0].meta.total ?? 0 },
      )}
    />
  );
};
