import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { peopleKeys } from 'utils/queryKeys';
import { removeFuturePersonRateMutation } from '../../api/actions/removeFuturePersonRate/removeFuturePersonRateActions';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';

export const useRemoveFuturePersonRate = (personId: number) => {
  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutationDomainError(['removeFutureRateMutation'], removeFuturePersonRateMutation(personId), {
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.removeFuturePersonRate.success'] }));
      queryClient.invalidateQueries(peopleKeys.singlePerson(String(personId)));
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.removeFuturePersonRate.error'] }),
  });
};
