import { useCallback, useState } from 'react';

import { Drawer } from '@mui/material';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DollarIcon,
  InfoIcon,
  NoteIcon,
  OpenFolderIcon,
  InvoicesIcon,
} from 'lux/icons';
import { Button } from 'lux/components';

import { ClientDetailsRoute } from 'routing/AppRoute.enum';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useClient } from 'hooks/useClient/useClient';

import * as styles from './Sidebar.styles';
import { SidebarButton } from './sidebarButton/SidebarButton';
import { SidebarHeader } from './sidebarHeader/SidebarHeader';
import { SidebarProps } from './Sidebar.types';

export const Sidebar = ({ pinned, togglePin }: SidebarProps) => {
  const { formatMessage } = useLocale();
  const { clientDetails } = useClient();
  const [open, setOpen] = useState(pinned || false);

  const openSidebar = useCallback(() => {
    if (!pinned) {
      setOpen(true);
    }
  }, [pinned]);

  const closeSidebar = useCallback(() => {
    if (!pinned) {
      setOpen(false);
    }
  }, [pinned]);

  return (
    <Drawer
      open
      hideBackdrop
      onMouseEnter={openSidebar}
      onMouseLeave={closeSidebar}
      sx={styles.drawer(open)}
      variant="temporary"
      elevation={0}
      ModalProps={{
        disableScrollLock: true,
        disableEnforceFocus: true,
      }}
    >
      <SidebarHeader title={clientDetails?.client?.name || ''} iconOnly={!open} />
      <Button variant="text" size="small" iconOnly onClick={togglePin}>
        {pinned ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </Button>
      <SidebarButton
        to={ClientDetailsRoute.overview}
        Icon={InfoIcon}
        label={formatMessage({ id: AppMessages['sidebar.client.details'] })}
        iconOnly={!open}
      />
      <SidebarButton
        to={ClientDetailsRoute.projects}
        Icon={OpenFolderIcon}
        label={formatMessage({ id: AppMessages['sidebar.client.projects'] })}
        iconOnly={!open}
      />
      <SidebarButton
        to={ClientDetailsRoute.timesheets}
        Icon={NoteIcon}
        label={formatMessage({ id: AppMessages['sidebar.menu.timesheet'] })}
        iconOnly={!open}
      />
      <SidebarButton
        to={ClientDetailsRoute.summary}
        Icon={DollarIcon}
        label={formatMessage({ id: AppMessages['sidebar.menu.billing'] })}
        iconOnly={!open}
      />
      <SidebarButton
        to={ClientDetailsRoute.invoices}
        Icon={InvoicesIcon}
        label={formatMessage({ id: AppMessages['sidebar.client.invoices'] })}
        iconOnly={!open}
      />
    </Drawer>
  );
};
