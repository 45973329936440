import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { Stack, Box } from '@mui/material';
import { Badge, Typography } from 'lux/components';
import { useFormContext } from 'react-hook-form';

import { fteList } from 'config/data/fteList';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { DatePicker } from 'ui/form/datePicker/DatePicker';
import { Select } from 'ui/form/select/Select';
import { parseDate } from 'utils/dateUtils';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { fteToDecimalFraction } from 'utils/fteToDecimalFraction';
import { createDepartmentLabel } from 'config/data/departments';
import { Seniority } from 'api/types/Seniority.enum';
import { seniorityList } from 'config/data/seniorityList';
import { Checkbox } from 'ui/form/checkbox/Checkbox';
import { AssignWithoutNeedFormValues } from '../AssignWithoutNeedModal.types';
import { InfoBox } from 'ui/infoBox/InfoBox';
import { useAssignmentInfo } from 'hooks/useAssignmentInfo/useAssignmentInfo';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { moneyInputNumberParser } from 'utils/createInputNumberChangeParser';
import { getMaxAvailableAssignDate } from 'shared/assignments/Assignments.functions';

import { RatesDetails } from './ratesDetails/RatesDetails';
import { AssignmentDetailsProps } from './AssignmentDetails.types';
import * as styles from './AssignmentDetails.styles';

export const AssignmentDetails = ({
  project,
  person,
  rateCard,
  isSenna,
  selectedRoleRates,
  isRateDefined,
}: AssignmentDetailsProps) => {
  const [availableRole, setAvailableRole] = useState<string | undefined>();
  const { formatMessage } = useLocale();
  const { control, watch, setValue } = useFormContext<AssignWithoutNeedFormValues>();
  const { employeeAssignments } = useProjectDetails();

  const { checkAvailableRoleForUser } = useAssignmentInfo();

  const [selectedStartDate, selectedEndDate, selectedFte, customRateEnabled, role] = watch([
    'startDate',
    'endDate',
    'fte',
    'customRateEnabled',
    'role',
  ]);

  useLayoutEffect(() => {
    const seniorities = getSeniorityList();
    if (role) {
      setValue('seniority', seniorities?.[0]?.value);
    } else {
      setValue('seniority', Seniority.withoutLevel);
    }
  }, [role]);

  const maxAvailableAssignDate = useMemo(() => {
    const { employeeId } = person;
    return getMaxAvailableAssignDate(employeeAssignments, selectedStartDate, employeeId);
  }, [person, selectedStartDate]);

  useEffect(() => {
    setAvailableRole(checkAvailableRoleForUser(person.employeeId));
  }, []);

  const projectStartDate = parseDate(project.startDate);
  const projectEndDate = project.endDate ? parseDate(project.endDate) : null;
  const firstIndex = 0;
  const multipleRoleOptions = Object.keys(rateCard.items).map((role, index) => ({
    id: String(index),
    children: createDepartmentLabel(role),
    value: role,
  }));

  const roles = availableRole
    ? [{ id: String(firstIndex), children: createDepartmentLabel(availableRole), value: availableRole }]
    : multipleRoleOptions;

  const getSeniorityList = () => {
    const itemWithoutSeniority = {
      id: '4',
      children: formatMessage({ id: AppMessages['people.seniority.senna'] }),
      value: Seniority.withoutLevel,
    };
    const defaultSeniorityList = seniorityList();
    const senioritiesWithRates = selectedRoleRates?.filter(({ amount }) => amount).map(({ seniority }) => seniority);

    const seniorityListWithSpecifiedRates = defaultSeniorityList.filter((item) =>
      senioritiesWithRates?.includes(item.value),
    );

    if (senioritiesWithRates?.includes(Seniority.withoutLevel)) {
      return [itemWithoutSeniority];
    } else if (role && !seniorityListWithSpecifiedRates.length) {
      return defaultSeniorityList;
    } else if (role) {
      return seniorityListWithSpecifiedRates;
    }

    return [itemWithoutSeniority, ...defaultSeniorityList];
  };

  const seniorities = getSeniorityList();

  useEffect(() => {
    if (!selectedStartDate) {
      setValue('endDate', null);
    }
  }, [selectedStartDate]);

  return (
    <Stack rowGap={3}>
      <Typography fontWeight={500}>
        {formatMessage({ id: AppMessages['assignWithoutNeed.formModal.assignmentDetails'] })}
      </Typography>
      <Stack direction="row" columnGap={1}>
        <Badge
          isStatic
          color="gray"
          badgeContent={formatMessage(
            { id: AppMessages['assignWithoutNeed.formModal.availableFte'] },
            {
              fte: fteToDecimalFraction(Number(person.availableFte)),
            },
          )}
        />
        <Badge
          isStatic
          color="gray"
          badgeContent={
            person.rate && person.currency
              ? formatMessage(
                  { id: AppMessages['assignWithoutNeed.formModal.personRate'] },
                  {
                    rate: person.rate,
                    currency: person.currency,
                  },
                )
              : formatMessage({ id: AppMessages['assignWithoutNeed.formModal.personRate.notDefined'] })
          }
        />
      </Stack>
      <Stack rowGap={3} sx={styles.formFieldsWrapper}>
        <DatePicker
          minDate={projectStartDate}
          maxDate={selectedEndDate ?? (projectEndDate || undefined)}
          name="startDate"
          control={control}
          label={formatMessage({ id: AppMessages['assignWithoutNeed.formModal.assignFrom'] })}
        />
        <DatePicker
          minDate={selectedStartDate ?? projectStartDate}
          maxDate={maxAvailableAssignDate}
          name="endDate"
          control={control}
          label={formatMessage({ id: AppMessages['assignWithoutNeed.formModal.assignTo'] })}
        />
        <Checkbox
          name="temporary"
          control={control}
          label={formatMessage({ id: AppMessages['assignWithoutNeed.formModal.temporary'] })}
          tooltipTitle={formatMessage({ id: AppMessages['assignWithoutNeed.formModal.temporary.info'] })}
        />
        <Select
          fullWidth
          name="fte"
          control={control}
          label={formatMessage({ id: AppMessages['assignWithoutNeed.formModal.fte'] })}
          options={fteList()}
        />
        {selectedFte === 'other' && (
          <FormTextField
            type="number"
            min={1}
            max={40}
            fullWidth
            name="customFte"
            control={control}
            label={formatMessage({ id: AppMessages['assignWithoutNeed.formModal.customFte'] })}
          />
        )}
        <Select
          fullWidth
          name="role"
          label={formatMessage({ id: AppMessages['assignWithoutNeed.formModal.personRole'] })}
          control={control}
          options={roles}
        />
        <Select
          fullWidth
          name="seniority"
          label={formatMessage({ id: AppMessages['assignWithoutNeed.formModal.personSeniority'] })}
          control={control}
          options={seniorities}
          isDisabled={isSenna || !role}
        />
      </Stack>
      <RatesDetails rateCard={rateCard} selectedRoleRates={selectedRoleRates} />
      <Stack rowGap={3} sx={styles.formFieldsWrapper}>
        <Box>
          <Checkbox
            control={control}
            name="customRateEnabled"
            label={formatMessage({ id: AppMessages['assignWithoutNeed.formModal.customRateEnabled'] })}
            size="medium"
          />
        </Box>
        {customRateEnabled && (
          <FormTextField
            fullWidth
            name="customRate"
            startAdornment={project.currency}
            onChangeParser={moneyInputNumberParser}
            control={control}
            label={formatMessage({ id: AppMessages['assignWithoutNeed.formModal.customRate'] })}
          />
        )}
      </Stack>
      {!isRateDefined && (
        <InfoBox variant="error" title={formatMessage({ id: AppMessages['assignWithoutNeed.formModal.alertTitle'] })}>
          <Typography variant="body2" color="text.secondary">
            {formatMessage({ id: AppMessages['assignWithoutNeed.formModal.alertDescription'] })}
          </Typography>
        </InfoBox>
      )}
    </Stack>
  );
};
