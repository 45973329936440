import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { EditKeyRolesParams } from './editKeyRolesActions.types';

export const editKeyRolesMutation: (projectId: string) => MutationFn<EditKeyRolesParams> =
  (projectId: string) => (body) => ({
    endpoint: `/projects/${projectId}/edit-key-roles`,
    method: 'PUT',
    params: body,
  });
