import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { CloseProjectParams, CloseProjectResponse } from './closeProject.types';

export const closeProject: (projectId: string) => MutationFn<CloseProjectParams, CloseProjectResponse> =
  (projectId: string) => (body) => ({
    endpoint: `/projects/${projectId}/close`,
    method: 'PATCH',
    params: body,
  });
