import { Styles } from 'styles/theme';

export const needCardHeaderWrapper: Styles = {
  p: 2,
};

export const skillsWrapper: Styles = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 1,
  color: 'text.secondary',
};
