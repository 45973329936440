export const useFormatCurrency = (currency: string) => {
  const formatter = new Intl.NumberFormat('pl-PL', {
    currency,
    style: 'currency',
    currencyDisplay: 'code',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return (price: number) => formatter.format(price);
};
