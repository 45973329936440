import { Select } from 'lux/components';
import { FormControl } from '@mui/material';

import { BillingSummaryGroupBy } from 'api/types/BillingSummary.types';
import { Translation } from 'ui/translation/Translation';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';

import { BillingSummaryFiltersProps } from './BillingSummaryFilters.types';
import * as styles from './BillingSummaryFilters.styles';

export const BillingSummaryFilters = ({ groupBy, onGroupByChange }: BillingSummaryFiltersProps) => {
  const billingSummaryActiveGroupBy = Object.values(BillingSummaryGroupBy);
  const groupByOptions = billingSummaryActiveGroupBy.map((value) => ({
    id: value,
    children: <Translation id={`projectDetails.billingSummary.filters.groupBy.${value}`} />,
    value,
  }));

  const { showWorkStatementSnapshot } = useProjectBillingSummaryData();

  return (
    <FormControl variant="outlined" sx={styles.formControl}>
      <Select
        value={groupBy}
        disabled={showWorkStatementSnapshot}
        onChange={(event) => onGroupByChange(event.target.value as BillingSummaryGroupBy)}
        label={<Translation id="projectDetails.billingSummary.filters.groupBy" />}
        size="small"
        options={groupByOptions}
        sx={styles.select}
      />
    </FormControl>
  );
};
