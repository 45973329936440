import { useState } from 'react';

import { Box, Slide, Stack } from '@mui/material';
import { Button, Tooltip, Typography } from 'lux/components';
import { CloseIcon, EditIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import * as styles from './BoxWithSlideInActions.styles';
import { BoxWithSlideInActionsProps } from './BoxWithSlideInActions.types';

const TIMEOUT = {
  enter: styles.SLIDE_IN_ANIMATION_DURATION,
  exit: styles.SLIDE_IN_ANIMATION_DURATION,
} as const;
const EASING = {
  enter: 'ease-in-out',
  exit: 'ease-in-out',
} as const;

export const BoxWithSlideInActions = ({
  label,
  value,
  isDeleting = false,
  onDelete,
  onEdit,
  isDisabled,
}: BoxWithSlideInActionsProps) => {
  const { formatMessage } = useLocale();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const tooltipTitle = isDisabled
    ? formatMessage({ id: AppMessages['projectDetails.billingSummary.workStatementDetails.tooltip'] })
    : undefined;

  return (
    <>
      <Stack sx={styles.rowWrapper} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Stack sx={styles.contentWrapper(isHovered)}>
          <Typography variant="body2">{label}</Typography>
          <Typography variant="body2">{value}</Typography>
        </Stack>

        <Slide in={isHovered} direction="left" timeout={TIMEOUT} easing={EASING} mountOnEnter unmountOnExit>
          <Box sx={styles.actionsWrapper}>
            <Tooltip title={tooltipTitle} placement="top" arrow>
              <Box>
                <Button iconOnly variant="text" onClick={onEdit} disabled={isDeleting || isDisabled}>
                  <EditIcon fontSize="medium" />
                </Button>
              </Box>
            </Tooltip>
            <Tooltip title={tooltipTitle} placement="top" arrow>
              <Box>
                <Button
                  iconOnly
                  variant="text"
                  onClick={onDelete}
                  loading={isDeleting}
                  disabled={isDeleting || isDisabled}
                >
                  <CloseIcon />
                </Button>
              </Box>
            </Tooltip>
          </Box>
        </Slide>
      </Stack>
    </>
  );
};
