import { Dialog } from '@mui/material';

import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';

import { UpdateRateCardModalProps } from './UpdateRateCardModal.types';
import { UpdateRateCardContent } from './updateRateCardContent/UpdateRateCardContent';
import * as styles from './UpdateRateCardModal.styles';

export const UpdateRateCardModal = ({
  open,
  onClose,
  updatedRateCard,
  minDate,
  title,
  currentRateCard,
}: UpdateRateCardModalProps) => {
  useDialogBlockScroll(open);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose} sx={styles.customDialog}>
      <UpdateRateCardContent
        onClose={onClose}
        updatedRateCard={updatedRateCard}
        minDate={minDate}
        title={title}
        currentRateCard={currentRateCard}
      />
    </Dialog>
  );
};
