import { useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useProjects } from 'hooks/useProjects/useProjects';
import { UniqueValueField } from '../uniqueValueField/UniqueValueField';

export const ProjectNameField = ({ dataCy }: { dataCy?: string }) => {
  const form = useFormContext();
  const { formatMessage } = useLocale();
  const projectName = form.watch('project.name').toLowerCase().trim();
  const clientId = form.watch('clientId');
  const { refetch, isInitialLoading: isLoading } = useProjects(
    { search: projectName.trim(), size: 5 },
    { enabled: false },
  );

  const checkProjectName = useCallback(async () => {
    if (!projectName) return;

    const response = await refetch();
    const data = response.data?.pages[0].projects.map((project) => ({
      projectName: project.name,
      clientId: project.client.id,
    }));

    if (data?.some((project) => project.projectName.toLowerCase() === projectName && project.clientId === clientId)) {
      form.setError('project.name', {
        message: formatMessage({ id: AppMessages['createProject.schema.project.name.error.exists'] }),
      });
    } else {
      form.clearErrors('project.name');
    }
  }, [projectName, clientId]);

  return (
    <UniqueValueField
      fieldName="project.name"
      label={formatMessage({ id: AppMessages['createProject.project.form.name'] })}
      checkUniqueValue={checkProjectName}
      isLoading={isLoading}
      isTypingEnabled={!!clientId}
      data-cy={dataCy}
    />
  );
};
