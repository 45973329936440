import { Dialog } from '@mui/material';

import { useDialog } from 'hooks/useDialog/useDialog';
import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';
import { RemoveImmediatelyModal } from '../removeImmediatelyModal/RemoveImmediatelyModal';

import { PlanRemovalModalProps } from './PlanRemovalModal.types';
import { PlanRemovalContent } from './planRemovalContent/PlanRemovalContent';

export const PlanRemovalModal = ({ open, onClose, assignmentData }: PlanRemovalModalProps) => {
  const {
    isOpen: isRemoveImmediatelyModalOpen,
    setOpen: setRemoveImmediatelyModalOpen,
    setClose: setRemoveImmediatelyModalClose,
  } = useDialog();
  useDialogBlockScroll(isRemoveImmediatelyModalOpen);

  const handleRemoveImmediatelyClick = () => {
    onClose();
    setRemoveImmediatelyModalOpen();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <PlanRemovalContent
          onClose={onClose}
          assignmentData={assignmentData}
          onRemoveImmediatelyClick={handleRemoveImmediatelyClick}
        />
      </Dialog>
      <RemoveImmediatelyModal
        onClose={setRemoveImmediatelyModalClose}
        open={isRemoveImmediatelyModalOpen}
        assignmentData={assignmentData}
      />
    </>
  );
};
