import { Styles } from 'styles/theme';

export const headRowCell: Styles = {
  typography: 'caption',
  fontWeight: 600,
  borderBottom: 'none',
};

export const personLink: Styles = {
  '&.MuiButton-root': {
    width: '100%',
    padding: '1rem 0',
    paddingRight: 3,
    justifyContent: 'space-between',
    gap: 1.5,
    maxHeight: 'unset',
  },
};

export const avatar: Styles = {
  width: 48,
  height: 48,
};

export const row: Styles = {
  '&.MuiTableRow-root:hover': {
    background: 'transparent',
  },
};

export const personDetails = {
  display: 'flex',
  flexDirection: 'column',
  gap: 0.5,
  flexGrow: 1,
};
