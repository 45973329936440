import { getCurrentDayStartDate, isFutureDate, parseDate } from 'utils/dateUtils';

export const getDefaultClosingDate = (endDate?: string) => {
  if (!endDate) {
    return null;
  }

  if (isFutureDate(new Date(endDate))) {
    return getCurrentDayStartDate();
  }

  return parseDate(endDate);
};
