import { ProposalStatus } from 'config/data/need/ProposalStatus.enum';
import { Styles } from 'styles/theme';

import { getColorByProposalStatus } from './ProposalTab.utils';

export const proposalTabWrapper = (proposalStatus: ProposalStatus): Styles => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  gap: 2,
  overflow: 'hidden',
  alignItems: 'center',
  backgroundColor: `accent.${getColorByProposalStatus(proposalStatus)}.pale`,
  py: 1,
  pl: 2,
});

export const proposalContentWrapper: Styles = {
  display: 'flex',
  gap: 2.5,
};

export const proposalBadgeWrapper = (proposalStatus: ProposalStatus): Styles => ({
  '> span > .MuiBadge-badge': {
    backgroundColor: `accent.${getColorByProposalStatus(proposalStatus)}.main`,
  },
});

export const proposalAvatarsWrapper = {
  display: 'inline-flex',
  flexDirection: 'row',
  ml: 1,
  '> div:not(:first-of-type)': {
    ml: -1.5,
  },
};
