import { useEffect, useRef, useState } from 'react';

import { Tooltip, Typography } from 'lux/components';

import { OverflowTooltipTypographyProps } from './OverflowTooltipTypography.types';
import * as styles from './OverflowTooltipTypography.styles';

export const OverflowTooltipTypography = ({ text, variant, color }: OverflowTooltipTypographyProps) => {
  const typographyRef = useRef<HTMLSpanElement | null>(null);

  const [hoverStatus, setHover] = useState(false);

  useEffect(() => {
    const compareSize = () => {
      const compare = typographyRef.current && typographyRef.current.scrollWidth > typographyRef.current.clientWidth;

      if (compare !== null) setHover(compare);
    };

    compareSize();
    window.addEventListener('resize', compareSize);

    return () => window.removeEventListener('resize', compareSize);
  }, []);

  return (
    <Tooltip title={text} placement="top" arrow disableHoverListener={!hoverStatus}>
      <Typography variant={variant} color={color} component="span" ref={typographyRef} sx={styles.text}>
        {text}
      </Typography>
    </Tooltip>
  );
};
