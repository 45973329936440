import { useState } from 'react';

import { Stack } from '@mui/material';
import { Button } from 'lux/components';

import { useRefreshEmployee } from '../../hooks/useRefreshEmployee/useRefreshEmployee';
import { isStaging } from '../../utils/isStaging';

type RefreshType = 'data' | 'skills' | 'status';

export const RefreshDWHDataButtons = () => {
  const [type, setType] = useState<RefreshType>('data');
  const { refreshEmployee, isRefreshing } = useRefreshEmployee(type);

  const refreshDWH = (type: RefreshType) => {
    setType(type);
    refreshEmployee(type);
  };

  if (!isStaging) {
    return null;
  }

  return (
    <Stack p={5} spacing={2} direction="row">
      <Button disabled={isRefreshing} onClick={() => refreshDWH('data')}>
        Refresh data
      </Button>
      <Button disabled={isRefreshing} onClick={() => refreshDWH('skills')}>
        Refresh skills
      </Button>
      <Button disabled={isRefreshing} onClick={() => refreshDWH('status')}>
        Refresh status
      </Button>
    </Stack>
  );
};
