import { useEffect, useState } from 'react';

import { Button, Grid, Illustration, Logo, Typography } from 'lux/components';
import { useSnackbar } from 'notistack';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

import { useAuth } from 'hooks/useAuth/useAuth';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useTitle } from 'hooks/useTitle/useTitle';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { Loader } from 'ui/loader/Loader';
import { redirectToLogout } from 'utils/redirectToLogout';
import { authStorage } from '../../context/auth/authStorage/AuthStorage';
import { getActiveAccount } from '../../utils/msalAccountUtils';
import { useCurrentUser } from '../../hooks/useCurrentUser/useCurrentUser';

import * as styles from './Login.styles';

export const loginRedirectKey = 'loginRedirect';

export const getTokenFromAzureAD = async (
  instance: IPublicClientApplication,
  account: AccountInfo | null,
  setAccount: (account: AccountInfo | null) => void,
) => {
  if (!account) {
    localStorage.setItem(loginRedirectKey, 'true');
    await instance.loginRedirect();

    return;
  }

  const getTokenOptions = {
    scopes: [`${process.env.REACT_APP_ACTIVE_DIRECTORY_CLIENT_ID}/.default`],
    account,
  };

  try {
    const response = await instance.acquireTokenSilent(getTokenOptions);

    if (response) {
      authStorage.accessToken = response.accessToken;
      authStorage.expires = response.expiresOn?.getTime() ?? 0;
      setAccount(account);

      return response.accessToken;
    }
  } catch (e) {
    instance.acquireTokenRedirect(getTokenOptions);
  }
};

export const Login = () => {
  const { formatMessage } = useLocale();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const { instance } = useMsal();
  const isMsalAuthenticated = useIsAuthenticated();
  const { isAuthenticated, isAuthenticating, setAccount, account } = useAuth();
  const { data: user } = useCurrentUser();
  const [isLoginRedirect, setIsLoginRedirect] = useState(!!localStorage.getItem(loginRedirectKey));

  useEffect(() => {
    if (isAuthenticated || isAuthenticating) {
      return;
    }

    setAccount(getActiveAccount(instance, user?.person.email));
  }, [instance, isMsalAuthenticated, isAuthenticated, isAuthenticating]);

  useEffect(() => {
    if (searchParams.get('manual_logout')) {
      enqueueSnackbar(formatMessage({ id: AppMessages['login.snackbar.manualLogout'] }), {
        luxSnackbarAction: () => {
          redirectToLogout(instance, account);
        },
        luxSnackbarActionLabel: formatMessage({ id: AppMessages['login.snackbar.manualLogout.button'] }),
        isWide: true,
      });
      searchParams.delete('manual_logout');
      setSearchParams(searchParams);
    }

    if (searchParams.get('logout_success')) {
      enqueueSnackbar(formatMessage({ id: AppMessages['login.snackbar.sessionRevoked'] }));
      searchParams.delete('logout_success');
      setSearchParams(searchParams);
    }
  }, []);

  useTitle(formatMessage({ id: AppMessages['title.login'] }));
  const fromPathname = location.state ? location.state.from.pathname : AppRoute.dashboard;

  if (isAuthenticating || (!isAuthenticated && isMsalAuthenticated && isLoginRedirect)) {
    return <Loader />;
  }

  if (isAuthenticated) {
    if (isLoginRedirect) {
      setIsLoginRedirect(false);
      localStorage.removeItem(loginRedirectKey);
    }

    return <Navigate to={fromPathname} replace />;
  }

  return (
    <>
      <Grid sx={styles.loginContainer} alignItems="center" justifyContent="center" container spacing={0} gutter={0}>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <Logo height="64" width="auto" />
          <Typography sx={styles.loginTitle} variant="h3">
            {formatMessage({ id: AppMessages['login.welcomeBack'] })}
          </Typography>
          <Typography sx={styles.loginDescriptions} variant="h6">
            {formatMessage({ id: AppMessages['login.description'] })}
          </Typography>
          <Button
            sx={styles.loginButton}
            size="large"
            variant="contained"
            data-cy="login-with-azure-button"
            onClick={() => getTokenFromAzureAD(instance, account, setAccount)}
          >
            {formatMessage({ id: AppMessages['login.button.text'] })}
          </Button>
        </Grid>
        <Grid item xs={1} />
        <Grid container sx={styles.loginBackground} item xs={6}>
          <Illustration type="secureLogin" sx={styles.authIcon} />
        </Grid>
      </Grid>
    </>
  );
};
