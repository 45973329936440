import { stringify } from 'qs';
import { Link } from 'react-router-dom';

import { DashboardCard } from 'ui/dashboardCard/DashboardCard';
import { useNeedsFilters } from 'hooks/useNeedsFilters/useNeedsFilters';
import { AppRoute } from 'routing/AppRoute.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const NeedsRedirect = () => {
  const { filters } = useNeedsFilters();
  const { formatMessage } = useLocale();

  return (
    <DashboardCard.Button component={Link} to={`${AppRoute.needs}?${stringify(filters)}`}>
      {formatMessage({ id: AppMessages['dashboard.seeAll'] })}
    </DashboardCard.Button>
  );
};
