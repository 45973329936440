import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';

export enum Fte {
  fullTime = '40',
  halfTime = '20',
  other = 'other',
}

export const fteList = () => {
  const { formatMessage } = intl.currentIntl;

  return [
    { id: '1', children: formatMessage({ id: AppMessages['filters.fte.option.fullTime'] }), value: Fte.fullTime },
    { id: '2', children: formatMessage({ id: AppMessages['filters.fte.option.halfTime'] }), value: Fte.halfTime },
    { id: '3', children: formatMessage({ id: AppMessages['filters.fte.option.other'] }), value: Fte.other },
  ];
};

export const convertFteToOptionValue = (fte: number) => {
  switch (fte) {
    case 40:
      return Fte.fullTime;
    case 20:
      return Fte.halfTime;
    default:
      return Fte.other;
  }
};
