import { Styles } from 'styles/theme';

export const links: Styles = {
  marginBottom: 2,
};

export const closeButtonWrapper: Styles = {
  borderRadius: 2,
  backgroundColor: 'white',
  color: 'text.secondary',
  border: (theme) => `1px solid ${theme.palette.border.default}`,
};

export const popover: Styles = {
  '.MuiPopover-paper': {
    backgroundColor: 'transparent',
    boxShadow: (theme) => theme.shadows[0],
    overflow: 'visible',
  },
};
