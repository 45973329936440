import { Stack } from '@mui/material';
import { Typography } from 'lux/components';
import { DollarIcon } from 'lux/icons';

import { Filter } from 'ui/filter/Filter';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { DebouncedTextField } from 'ui/debouncedTextField/DebouncedTextField';
import { createNumberKeyDownHandler } from 'utils/createNumberKeyDownHandler';

export const RatesFilter = () => {
  const { formatMessage } = useLocale();
  const {
    filters: { rateMin, rateMax },
    setFilterValue,
  } = usePeopleFilters();

  return (
    <Filter variant="accordion" label={formatMessage({ id: AppMessages['people.filters.rate.label'] })}>
      <Stack direction="row" alignItems="center" spacing={0.5} maxWidth={470} width="100%">
        <DebouncedTextField
          size="small"
          type="number"
          label={formatMessage({ id: AppMessages['people.filters.rate.min.label'] })}
          fullWidth
          endAdornment={<DollarIcon />}
          InputProps={{
            onKeyDown: createNumberKeyDownHandler({ allowNegative: false, decimalPrecision: 2 }),
            inputProps: {
              min: 0,
            },
          }}
          defaultValue={rateMin}
          value={rateMin}
          onChange={(event) => setFilterValue('rateMin', event.target.value)}
        />
        <Typography color="text.secondary">-</Typography>
        <DebouncedTextField
          size="small"
          type="number"
          label={formatMessage({ id: AppMessages['people.filters.rate.max.label'] })}
          fullWidth
          endAdornment={<DollarIcon />}
          InputProps={{
            onKeyDown: createNumberKeyDownHandler({ allowNegative: false, decimalPrecision: 2 }),
            inputProps: {
              min: rateMin || 0,
            },
          }}
          defaultValue={rateMax}
          value={rateMax}
          onChange={(event) => setFilterValue('rateMax', event.target.value)}
        />
      </Stack>
    </Filter>
  );
};
