import { StepProgress as LuxStepProgress } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { StepProgressProps } from './StepProgress.types';
import * as styles from './StepProgress.styles';

export const StepProgress = ({
  totalSteps: totalSteps,
  completedSteps,
  nextStepName,
  nextStepPlacement = 'top',
  dataCy,
}: StepProgressProps) => {
  const { formatMessage } = useLocale();

  return (
    <LuxStepProgress
      completedSteps={completedSteps}
      totalSteps={totalSteps}
      nextStepName={nextStepName}
      nextStepLabel={formatMessage({ id: AppMessages['stepProgress.nextStepLabel'] })}
      {...(nextStepPlacement === 'bottom' && { className: '--reverse' })}
      sx={styles.progressBar}
      data-cy={dataCy}
    />
  );
};
