import { useEffect } from 'react';

import { FieldValues, useForm, Path, UseFormProps } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';

import { getErrorPaths } from './getErrorPaths';

export const useIntlForm = <T extends FieldValues>(props: UseFormProps<T>) => {
  const { locale } = useLocale();

  const form = useForm<T>(props);

  useEffect(() => {
    form.trigger(getErrorPaths(form.formState.errors) as Path<T>[]);
  }, [locale]);

  return form;
};
