import { convertRateToInteger } from 'utils/convertRate';
import { UncertainPerson } from 'api/types/UncertainPerson.types';
import { Seniority } from 'api/types/Seniority.enum';
import { UncertainEmploymentType } from 'config/data/people/uncertain';

import { UncertainPersonFormValues } from './UncertainPersonForm.types';

export const createUncertainPersonPayload = (
  uncertainPersonFormValues: UncertainPersonFormValues,
): UncertainPerson => ({
  firstName: uncertainPersonFormValues.firstName,
  lastName: uncertainPersonFormValues.lastName,
  ...(uncertainPersonFormValues.email && { email: uncertainPersonFormValues.email }),
  departmentCode: uncertainPersonFormValues.departmentCode,
  ...(uncertainPersonFormValues.seniority
    ? { seniority: uncertainPersonFormValues.seniority }
    : { seniority: Seniority.withoutLevel }),
  isInhouse: uncertainPersonFormValues.employmentType === UncertainEmploymentType.inhouse,
  ...(uncertainPersonFormValues.fteCustom
    ? { fte: Number(uncertainPersonFormValues.fteCustom) }
    : { fte: Number(uncertainPersonFormValues.fte) }),
  ...(uncertainPersonFormValues.rate &&
    uncertainPersonFormValues.currency && {
      rate: {
        rate: convertRateToInteger(uncertainPersonFormValues.rate),
        currency: uncertainPersonFormValues.currency,
      },
    }),
});
