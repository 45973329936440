import { FocusEvent } from 'react';

import { Controller, FieldValues } from 'react-hook-form';
import { Autocomplete } from 'lux/components';

import { hubspotTag } from '../../../utils/hubspotTag';

import { AutocompleteProps } from './Autocomplete.types';
import { filterOptions, getValue, normalizeValue } from './Autocomplete.functions';

export const MultipleAutocomplete = <T extends FieldValues>(props: AutocompleteProps<T> & { dataCy?: string }) => {
  const { name, control, inputMaxLength, dataCy, customRenderTags, disableClearable, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          {...rest}
          multiple
          disableClearable={disableClearable}
          value={value}
          onChange={(event, newValue) => {
            const isArray = Array.isArray(newValue);
            const isHubspot = value?.includes(hubspotTag);
            const isHubspotTagDeleted = isHubspot && isArray && !newValue.includes(hubspotTag);

            if (isHubspotTagDeleted) {
              return;
            }

            if (!isArray) return;

            const normalizedValues: string[] = [];
            newValue.forEach((newValue) => {
              const normalizedValue = normalizeValue(newValue, props.options, props.freeSolo);
              if (normalizedValue) {
                normalizedValues.push(normalizedValue);
              }
            });
            onChange(normalizedValues);
          }}
          onBlur={(event: FocusEvent<HTMLInputElement>) => {
            const inputValue = event.target.value;
            const normalizedValue = normalizeValue(inputValue, props.options, props.freeSolo);
            if (!!normalizedValue) {
              onChange([...value, normalizedValue]);
            }
          }}
          renderTags={customRenderTags}
          clearOnBlur
          filterOptions={filterOptions(props.freeSolo)}
          getOptionLabel={getValue}
          error={!!error}
          helperText={!!error?.message ? error.message : ''}
          inputProps={{ maxLength: inputMaxLength }}
          data-cy={dataCy}
        />
      )}
    />
  );
};
