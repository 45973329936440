import { Tabs } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useAssignments } from 'hooks/useAssignments/useAssignments';
import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';

import { TabAssignedProps } from './TabAssigned.types';

export const TabAssigned = ({ projectId, ...rest }: TabAssignedProps) => {
  const { formatMessage } = useLocale();
  const { data: assignmentsData } = useAssignments({
    project: projectId,
    status: [
      AssignmentStatus.active,
      AssignmentStatus.leaving,
      AssignmentStatus.temporary,
      AssignmentStatus.preassigned,
    ],
  });

  return (
    <Tabs.Tab
      {...rest}
      label={formatMessage(
        { id: AppMessages['projectDetails.teamDetails.assigned.header'] },
        { assigned: assignmentsData?.pages[0].meta.total ?? 0 },
      )}
    />
  );
};
