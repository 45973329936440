import { Box } from '@mui/material';

import { Card } from 'ui/card/Card';
import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

import * as styles from './ProjectsList.styles';

export const ProjectsListSkeleton = () => (
  <>
    {Array(5)
      .fill(null)
      .map((_, index) => (
        <Box key={index} sx={styles.accordion} borderRadius={1}>
          <Card sx={styles.accordionSummary}>
            <Box sx={{ display: 'flex', mb: 1 }}>
              <TextSkeleton width={80} variant="body2" />
            </Box>
            <TextSkeleton width={400} variant="caption" />
          </Card>
        </Box>
      ))}
  </>
);
