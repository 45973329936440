import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { convertLeadMutation } from 'api/actions/convertLead/convertLeadActions';
import { assignmentsKeys, projectsKeys } from 'utils/queryKeys';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';

export const useConvertLead = (projectId: string) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();

  return useMutationDomainError(['startProject'], convertLeadMutation(projectId), {
    onSuccess: () => {
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(projectsKeys.projectsList);
      queryClient.invalidateQueries(assignmentsKeys.assignmentsList);
      enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.convertLead.success'] }));
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.convertLead.error'] }),
  });
};
