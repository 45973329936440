import { Box, Dialog } from '@mui/material';
import { Avatar, Typography } from 'lux/components';

import { getPersonFullRole } from 'utils/stringUtils';

import { AbsenceReplacementModalProps } from './AbsenceReplacementModal.types';
import { AbsenceReplacementContent } from './absenceReplacementContent/AbsenceReplacementContent';
import * as styles from './AbsenceReplacementModal.styles';

export const AbsenceReplacementModal = ({ projectId, open, onClose, assignmentData }: AbsenceReplacementModalProps) => {
  const personFullName = `${assignmentData.person.firstName} ${assignmentData.person.lastName}`;

  return (
    <Dialog open={open} maxWidth={'sm'} fullWidth onClose={onClose} sx={styles.dialog}>
      <Box sx={styles.container}>
        <Avatar alt={assignmentData.person.firstName} image={assignmentData.person.picture} sx={styles.avatar} />
        <Typography sx={styles.personName}>{personFullName}</Typography>
        <Typography sx={styles.personRole}>
          {getPersonFullRole(assignmentData.person.seniority, assignmentData.role)}
        </Typography>
      </Box>
      <AbsenceReplacementContent projectId={projectId} onClose={onClose} assignmentData={assignmentData} />
    </Dialog>
  );
};
