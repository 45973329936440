import { Stack } from '@mui/material';

import { useInvoiceNote } from 'hooks/useInvoiceNote/useInvoiceNote';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { TextAreaEdit } from 'ui/form/inlineEdit/textAreaEdit/TextAreaEdit';
import { hideInPdf } from 'app/project/projectReport/ProjectReport.styles';

import { AddCommentButtonProps } from './AddCommentButton.types';

export const AddCommentButton = ({ invoice }: AddCommentButtonProps) => {
  const { invoiceNote } = useProjectBillingSummaryData();

  const invoiceId = invoice?.id || '';
  const initialNote = invoiceNote || '-';
  const { mutateAsync: updateNote } = useInvoiceNote(invoiceId, initialNote);

  return (
    <Stack sx={hideInPdf}>
      <TextAreaEdit
        name="invoiceNote"
        value={initialNote}
        label="projectDetails.billingSummary.workStatementDetails.invoiceDetails.commentForInvoice"
        defaultValues={{ invoiceNote: initialNote }}
        onSubmit={(value) =>
          updateNote({
            note: value.invoiceNote,
          })
        }
      />
    </Stack>
  );
};
