import { useQueryClient } from '@tanstack/react-query';

import { useMutation } from 'hooks/useMutation/useMutation';
import { assignmentRemovalMutation } from 'api/actions/assignmentRemoval/assignmentRemoval';
import { assignmentsKeys, projectsKeys, rejectionsKeys } from 'utils/queryKeys';
import { AssignmentId } from 'api/types/AssignmentType.enum';

export const useAssignmentRemoval = (projectId: string, assignmentId: AssignmentId) => {
  const queryClient = useQueryClient();

  return useMutation(['assignmentRemoval'], assignmentRemovalMutation(projectId, assignmentId), {
    onSuccess: () => {
      queryClient.invalidateQueries(assignmentsKeys.assignmentsList);
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(rejectionsKeys.rejectionsList);
    },
  });
};
