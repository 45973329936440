import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser';

import { ActionsValue, permissions } from './Permissions';

export const usePermission = () => {
  const { data: user } = useCurrentUser();

  const hasPermission = (action: ActionsValue) => {
    if (!user) return false;

    return permissions[user.metadata.accessType].includes(action);
  };

  return { hasPermission };
};
