import { Typography } from 'lux/components';

import { GetApiVersionResponse } from 'api/actions/getApiVersion/getApiVersion.types';

import * as styles from './Version.styles';

export const Version = ({ apiVersion }: GetApiVersionResponse) => {
  const spaVersionTag = process.env.REACT_APP_VERSION ?? 'unknown';
  const commitHash = process.env.REACT_APP_COMMIT_HASH ?? 'unknown';

  const spaVersionHash = spaVersionTag.slice(-8);

  const spaVersion = spaVersionHash === commitHash ? `#${commitHash}` : spaVersionTag;
  const footer = spaVersion === apiVersion ? spaVersion : `FE: ${spaVersion} / BE: ${apiVersion}`;

  return <Typography sx={styles.version}>{footer}</Typography>;
};
