import { Alert } from 'lux/components';
import { ErrorFilledIcon } from 'lux/icons';

import { Translation } from 'ui/translation/Translation';
import { Translation as TranslationType } from 'i18n/messages';

import * as styles from './InvoiceErrorBanner.styles';
import { InvoiceErrorBannerProps } from './InvoiceErrorBanner.types';

export const InvoiceErrorBanner = ({ title, message }: InvoiceErrorBannerProps) => (
  <Alert
    severity="error"
    icon={<ErrorFilledIcon fontSize="medium" color="error" />}
    title={title}
    description={<Translation id={message as TranslationType} />}
    sx={styles.errorText}
  />
);
