import { Styles } from 'styles/theme';

export const employeeCell: Styles = {
  paddingY: 1,
};

export const errorIcon: Styles = {
  '&.MuiSvgIcon-root': {
    width: 18,
    height: 18,
  },
};

export const actionCell: Styles = {
  padding: 0,
};

export const tableRow: Styles = {
  cursor: 'pointer',
  height: 56,

  '.MuiTableCell-root': {
    paddingX: 1,
  },
};

export const employeeLink: Styles = {
  color: 'text.primary',
  textDecoration: 'none',
  maxWidth: 'max-content',
  width: 500,

  '&>svg': {
    visibility: 'hidden',
  },

  '&:hover': {
    color: 'primary.main',
    textDecoration: 'underline',

    '&>svg': {
      visibility: 'visible',
    },
  },
};
