import Joi from 'joi';

import { AppMessages } from 'i18n/messages';
import { intl } from 'context/locale/intl/Intl';
import { MAX_SUPPORTED_DATE, formatDate, parseDate, parseDateToEndOfDay } from 'utils/dateUtils';

export const workStatementFormSchema = (
  useProjectStartDate: boolean,
  projectStartDate: string,
  projectEndDate?: string,
) => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object().keys({
    statementNumber: Joi.string()
      .required()
      .messages({
        'string.empty': formatMessage(
          { id: AppMessages['validation.empty'] },
          { label: formatMessage({ id: AppMessages['projectDetails.workStatementForm.statementNumber'] }) },
        ),
      }),
    startDate: Joi.date()
      .min(parseDate(projectStartDate))
      .max(projectEndDate ? parseDate(projectEndDate) : MAX_SUPPORTED_DATE)
      .required()
      .messages({
        'date.base': formatMessage(
          { id: AppMessages['validation.date'] },
          { label: formatMessage({ id: AppMessages['projectDetails.workStatementForm.startDate'] }) },
        ),
        'date.min': formatMessage(
          { id: AppMessages['validation.min'] },
          {
            limit: projectStartDate,
            label: formatMessage({ id: AppMessages['projectDetails.workStatementForm.startDate'] }),
          },
        ),
        'date.max': formatMessage(
          { id: AppMessages['validation.max'] },
          {
            limit: formatDate(MAX_SUPPORTED_DATE),
            label: formatMessage({ id: AppMessages['projectDetails.workStatementForm.startDate'] }),
          },
        ),
      }),
    endDate: Joi.date()
      .max(projectEndDate ? parseDateToEndOfDay(projectEndDate) : MAX_SUPPORTED_DATE)
      .greater(Joi.ref('startDate'))
      .allow(null)
      .optional()
      .messages({
        'date.base': formatMessage(
          { id: AppMessages['validation.date'] },
          { label: formatMessage({ id: AppMessages['projectDetails.workStatementForm.endDate'] }) },
        ),
        'date.greater': formatMessage({ id: 'createProject.schema.project.endDate.error.greater' }),
        'date.max': formatMessage(
          { id: AppMessages['validation.max'] },
          {
            limit: projectEndDate || formatDate(MAX_SUPPORTED_DATE),
            label: formatMessage({ id: AppMessages['projectDetails.workStatementForm.endDate'] }),
          },
        ),
      }),
    billingCycle: Joi.string()
      .required()
      .messages({
        'string.empty': formatMessage(
          { id: AppMessages['validation.empty'] },
          { label: formatMessage({ id: AppMessages['projectDetails.workStatementForm.billingPeriod.label'] }) },
        ),
      }),
    paymentPeriod: Joi.number()
      .allow('')
      .integer()
      .min(0)
      .optional()
      .messages({
        'string.empty': formatMessage(
          { id: AppMessages['validation.empty'] },
          { label: formatMessage({ id: AppMessages['projectDetails.workStatementForm.paymentPeriod'] }) },
        ),
      }),
    depositAmount: Joi.number()
      .allow('')
      .optional()
      .messages({
        'string.empty': formatMessage(
          { id: AppMessages['validation.empty'] },
          { label: formatMessage({ id: AppMessages['projectDetails.workStatementForm.depositAmount'] }) },
        ),
      }),
    depositPaymentDate: Joi.when('depositAmount', {
      is: Joi.number().required(),
      then: Joi.date()
        .min(Joi.ref('startDate'))
        .max(MAX_SUPPORTED_DATE)
        .required()
        .messages({
          'date.base': formatMessage(
            { id: AppMessages['validation.date'] },
            { label: formatMessage({ id: AppMessages['projectDetails.workStatementForm.depositPaymentDue'] }) },
          ),
          'date.min': formatMessage(
            { id: AppMessages['validation.min'] },
            {
              limit: projectStartDate,
              label: formatMessage({ id: AppMessages['projectDetails.workStatementForm.depositPaymentDue'] }),
            },
          ),
          'date.max': formatMessage(
            { id: AppMessages['validation.max'] },
            {
              limit: formatDate(MAX_SUPPORTED_DATE),
              label: formatMessage({ id: AppMessages['projectDetails.workStatementForm.depositPaymentDue'] }),
            },
          ),
        }),
      otherwise: Joi.date().allow(null),
    }),
  });
};
