import { Stack, Box } from '@mui/material';
import { Typography, Button } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useClientsFilters } from 'hooks/useClientsFilters/useClientsFilters';
import { ClientCountryFilter } from 'shared/clients/clientsFilters/clientCountryFilter/ClientCountryFilter';
import { ClientIndustriesFilter } from 'shared/clients/clientsFilters/clientIndustriesFilter/ClientIndustriesFilter';

import * as styles from './ClientsFilters.styles';

export const ClientsFilters = () => {
  const { formatMessage } = useLocale();
  const { clearAllFilters } = useClientsFilters();

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h6">{formatMessage({ id: AppMessages['filters.title'] })}</Typography>
        <Button onClick={() => clearAllFilters(['search', 'sort'])} size="small" variant="text" color="primary">
          {formatMessage({ id: AppMessages['filters.clearAll'] })}
        </Button>
      </Stack>
      <Box sx={styles.filtersWrapper}>
        <ClientIndustriesFilter variant="accordion" />
        <ClientCountryFilter variant="accordion" />
      </Box>
    </>
  );
};
