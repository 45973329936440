import { AutocompleteStack } from 'ui/autocompleteStack/AutocompleteStack';
import { Filter } from 'ui/filter/Filter';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSkills } from 'hooks/useSkills/useSkills';
import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';

export const SkillsFilter = () => {
  const { data: skillsDictionary = [] } = useSkills();
  const { formatMessage } = useLocale();

  const {
    filters: { skills },
    setFilterValue,
  } = usePeopleFilters();

  const handleSkillsChange = (newValue: string[]) => {
    setFilterValue('skills', newValue);
  };

  return (
    <Filter variant="accordion" label={formatMessage({ id: AppMessages['people.filters.skills.label'] })} shouldStretch>
      <AutocompleteStack
        dictionary={skillsDictionary}
        label={formatMessage({ id: AppMessages['people.filters.skills.field.label'] })}
        onFieldsChange={handleSkillsChange}
        buttonLabel={formatMessage({ id: AppMessages['people.filters.skills.addSkill'] })}
        values={skills}
      />
    </Filter>
  );
};
