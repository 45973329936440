import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { ConfirmEndDateExtensionPayload } from './confirmEndDateExtension.types';

export const confirmEndDateExtension: (projectId: string) => MutationFn<ConfirmEndDateExtensionPayload> =
  (projectId: string) => (body) => ({
    endpoint: `projects/${projectId}/assignments/extend`,
    method: 'POST',
    params: body,
  });
