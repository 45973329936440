import { Dialog } from '@mui/material';

import { intl } from 'context/locale/intl/Intl';
import { useUnsavedChangesDialogContext } from 'hooks/useUnsavedChangesDialogContext/useUnsavedChangesDialogContext';
import { AppMessages } from 'i18n/messages';
import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';

export const UnsavedChangesDialog = () => {
  const { isUnsavedChangesDialogOpen, setUnsavedChangesDialogClose, onCloseRef } = useUnsavedChangesDialogContext();
  const { formatMessage } = intl.currentIntl;

  return (
    <Dialog open={isUnsavedChangesDialogOpen} fullWidth onClose={setUnsavedChangesDialogClose}>
      <DialogInnerWrapper
        title={formatMessage({ id: AppMessages['dialog.warning'] })}
        submitText={formatMessage({ id: AppMessages['dialog.discardChanges'] })}
        onSubmit={() => {
          setUnsavedChangesDialogClose();
          onCloseRef?.current && onCloseRef.current();
        }}
        onClose={setUnsavedChangesDialogClose}
      >
        {formatMessage({ id: AppMessages['dialog.unsavedChanges'] })}
      </DialogInnerWrapper>
    </Dialog>
  );
};
