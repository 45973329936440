import { useState } from 'react';

import { Box, Stack } from '@mui/material';
import { Accordion, Chip, Tooltip, Typography } from 'lux/components';

import { useUpdateProject } from 'hooks/useUpdateProject/useUpdateProject';
import { mapToAutocompleteOptions } from 'ui/form/autocomplete/Autocomplete.functions';
import { mapToSelectOptions } from 'ui/form/select/Select.mappers';
import { TextFieldEdit } from 'ui/form/inlineEdit/textFieldEdit/TextFieldEdit';
import { SelectEdit } from 'ui/form/inlineEdit/selectEdit/SelectEdit';
import { MultipleAutocompleteEdit } from 'ui/form/inlineEdit/autocompleteEdit/MultipleAutocompleteEdit';
import { DatePickerEdit } from 'ui/form/inlineEdit/datePickerEdit/DatePickerEdit';
import { timeDifferences } from 'config/data/timeDifferences';
import { attributes } from 'config/data/attributes';
import { addDaysToDate, subtractDaysFromDate, isDateInThePast, parseDate } from 'utils/dateUtils';
import * as styles from '../../Project.styles';
import { SelectChips } from 'ui/form/select/selectChip/SelectChips';
import { Translation } from 'ui/translation/Translation';
import { useTags } from 'hooks/useTags/useTags';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { checkIsProjectClosedOrArchived } from 'utils/checkers/checkIsProjectClosedOrArchived';
import { useFormatCurrency } from 'hooks/useFormatCurrency/useFormatCurrency';
import { moneyInputNumberParser } from 'utils/createInputNumberChangeParser';

import { ProjectDetailsProps } from './ProjectDetails.types';
import { CodenameTextFieldEdit } from './codenameTextFieldEdit/CodenameTextFieldEdit';

export const ProjectDetails = (props: ProjectDetailsProps) => {
  const [expanded, setExpanded] = useState(true);
  const { projectData, disabled } = props;
  const { data: tags } = useTags();
  const { mutateAsync, isLoading } = useUpdateProject(projectData.id);
  const formatCurrency = useFormatCurrency(projectData.currency);

  const isProjectClosedOrArchived = checkIsProjectClosedOrArchived(projectData.stage);
  const submitField = <T extends object>(fieldData: T) =>
    mutateAsync({ clientId: projectData.client.id, ...fieldData });

  return (
    <Accordion
      summary={<Translation id="projectDetails.accordions.details" />}
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
    >
      <Accordion.Details>
        <Stack gap={1}>
          <DatePickerEdit
            name="startDate"
            value={projectData.startDate}
            maxDate={projectData.endDate ? subtractDaysFromDate(parseDate(projectData.endDate), 1) : undefined}
            label="projectDetails.accordions.details.start"
            defaultValues={{ startDate: parseDate(projectData.startDate) }}
            onSubmit={(fieldData) => submitField(fieldData)}
            loading={isLoading}
            disabled={disabled}
            required
          />
          {!projectData.plannedExtension ? (
            <DatePickerEdit
              name="endDate"
              value={projectData.endDate || '-'}
              minDate={parseDate(projectData.startDate)}
              label="projectDetails.accordions.details.end"
              defaultValues={{ endDate: projectData.endDate ? parseDate(projectData.endDate) : null }}
              onSubmit={(fieldData) => submitField(fieldData)}
              loading={isLoading}
              disableUntilDate={parseDate(projectData.startDate)}
              disabled={disabled}
              defaultCalendarMonth={
                isDateInThePast(parseDate(projectData.startDate))
                  ? undefined
                  : addDaysToDate(parseDate(projectData.startDate), 1)
              }
            />
          ) : (
            <Box sx={styles.accordionDetailsItem}>
              <Box>
                <Typography sx={styles.projectAccordionCaption} variant="caption" fontWeight={600}>
                  <Translation id="projectDetails.accordions.details.end" />
                </Typography>
              </Box>
              <Tooltip title={<Translation id="projectDetails.accordions.details.end.tooltip" />} arrow placement="top">
                <Box>
                  <Typography variant="caption">{projectData.endDate || '-'}</Typography>
                </Box>
              </Tooltip>
            </Box>
          )}
          {projectData.stage === ProjectStage.archived && (
            <Box sx={styles.accordionDetailsItem}>
              <Box>
                <Typography sx={styles.projectAccordionCaption} variant="caption" fontWeight={600}>
                  <Translation id="projectDetails.accordions.details.archived" />
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">{projectData.archivingDate || '-'}</Typography>
              </Box>
            </Box>
          )}
          {projectData.plannedExtension && (
            <Box sx={styles.accordionDetailsItem}>
              <Box>
                <Typography sx={styles.projectAccordionCaption} variant="caption" fontWeight={600}>
                  <Translation id="projectDetails.accordions.details.extension" />
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">{projectData.extensionDate || '-'}</Typography>
              </Box>
            </Box>
          )}

          <SelectEdit
            name="attributes"
            value={projectData.attributes}
            label="projectDetails.accordions.details.attributes"
            defaultValues={{ attributes: projectData.attributes }}
            onSubmit={(fieldData) => submitField(fieldData)}
            options={mapToSelectOptions(attributes)}
            loading={isLoading}
            disabled={disabled}
            renderValue={(selected) => (Array.isArray(selected) ? <SelectChips selectedValues={selected} /> : null)}
          />
          <MultipleAutocompleteEdit
            name="tags"
            value={projectData.tags}
            label="projectDetails.accordions.details.tags"
            defaultValues={{ tags: projectData.tags }}
            onSubmit={(fieldData) => submitField(fieldData)}
            options={mapToAutocompleteOptions(tags || [])}
            isFreeSolo
            loading={isLoading}
            disabled={isProjectClosedOrArchived}
            disableClearable
            inputMaxLength={20}
            customRenderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                // getTagProps has key props
                // eslint-disable-next-line react/jsx-key
                <Chip label={String(option)} {...getTagProps({ index })} disabled={String(option) === 'HubSpot deal'} />
              ))
            }
          />

          <SelectEdit
            name="timeDifference"
            value={projectData.timeDifference || ''}
            label="projectDetails.accordions.details.timeDifference"
            defaultValues={{ timeDifference: projectData.timeDifference }}
            onSubmit={(fieldData) => submitField(fieldData)}
            options={mapToSelectOptions(timeDifferences)}
            loading={isLoading}
            disabled={disabled}
          />
          <CodenameTextFieldEdit
            projectId={projectData.id}
            codename={projectData.codename || ''}
            onSubmit={(fieldData) => submitField(fieldData)}
            loading={isLoading}
            disabled={isProjectClosedOrArchived}
          />
          <TextFieldEdit
            name="slackChannelId"
            value={projectData.slackChannelId ?? ''}
            label="projectDetails.accordions.details.slackChannelId"
            defaultValues={{ slackChannelId: projectData.slackChannelId }}
            onSubmit={(fieldData) => submitField(fieldData)}
            loading={isLoading}
            disabled={disabled}
          />
          <Box sx={styles.accordionDetailsItem}>
            <Box>
              <Typography sx={styles.projectAccordionCaption} variant="caption" fontWeight={600}>
                <Translation id="projectDetails.accordions.details.currency" />
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">{projectData.currency}</Typography>
            </Box>
          </Box>
          <TextFieldEdit
            name="budget"
            value={projectData.budget ? formatCurrency(Number(projectData.budget)) : ''}
            label="projectDetails.accordions.details.budget"
            defaultValues={{ budget: projectData.budget }}
            onSubmit={(fieldData) => submitField(fieldData)}
            loading={isLoading}
            disabled={disabled}
            sendAsInt
            onChangeParser={moneyInputNumberParser}
          />
          <TextFieldEdit
            name="contactPerson"
            value={projectData.contactPerson || ''}
            label="projectDetails.accordions.details.contactPerson"
            defaultValues={{ contactPerson: projectData.contactPerson }}
            onSubmit={(fieldData) => submitField(fieldData)}
            loading={isLoading}
            disabled={disabled}
          />
        </Stack>
      </Accordion.Details>
    </Accordion>
  );
};
