import { Box, Stack } from '@mui/material';
import { Avatar, Badge, Typography } from 'lux/components';

import { Translation } from 'ui/translation/Translation';
import { PersonTooltip } from 'shared/people/personTooltip/PersonTooltip';

import { ProposalTabProps } from './ProposalTab.types';
import { getColorByProposalStatus, getTranslationByProposalStatus, useDynamicAvatars } from './ProposalTab.utils';
import * as styles from './ProposalTab.styles';

export const ProposalTab = ({ proposals, proposalStatus }: ProposalTabProps) => {
  const { proposalTabRef, contentRef, avatarsToDisplay } = useDynamicAvatars();

  if (!proposals.length) {
    return null;
  }

  const additionalAvatarTitle = `${proposals.length - avatarsToDisplay > 9 ? '>' : '+'}${
    proposals.length - avatarsToDisplay >= 9 ? 9 : proposals.length - avatarsToDisplay
  }`;

  return (
    <Box ref={proposalTabRef} sx={styles.proposalTabWrapper(proposalStatus)}>
      <Box ref={contentRef} sx={styles.proposalContentWrapper}>
        <Box>
          <Typography variant="caption">
            <Translation id={getTranslationByProposalStatus(proposalStatus)} />
          </Typography>
        </Box>
        <Box sx={styles.proposalBadgeWrapper(proposalStatus)}>
          <Badge variant="digit" color={getColorByProposalStatus(proposalStatus)} badgeContent={proposals.length} />
        </Box>
      </Box>
      <Stack sx={styles.proposalAvatarsWrapper}>
        {proposals.slice(0, avatarsToDisplay).map(({ id, person }) => (
          <PersonTooltip key={id} employeeId={person.employeeId}>
            <Box>
              <Avatar
                size="sm"
                alt={`${person.firstName} ${person.lastName}`}
                name={`${person.firstName} ${person.lastName}`}
                image={person.picture}
              />
            </Box>
          </PersonTooltip>
        ))}
        {proposals.length > avatarsToDisplay && (
          <Avatar alt="other" size="sm">
            {additionalAvatarTitle}
          </Avatar>
        )}
      </Stack>
    </Box>
  );
};
