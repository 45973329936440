import { EmptyState } from 'ui/emptyState/EmptyState';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ProjectAccordion } from '../projectAccordion/ProjectAccordion';
import { Loader } from 'ui/loader/Loader';

import { PersonProposalsProps } from './PersonProposals.types';

export const PersonProposals = ({ proposals, isLoading }: PersonProposalsProps) => {
  const { formatMessage } = useLocale();

  if (isLoading) {
    return <Loader fullHeight={false} />;
  }

  if (proposals.length === 0) {
    return (
      <EmptyState
        illustration="requestMailbox"
        title={formatMessage({ id: AppMessages['personDetails.projects.proposals.empty.title'] })}
        message={formatMessage({ id: AppMessages['personDetails.projects.proposals.empty.message'] })}
      />
    );
  }

  return (
    <>
      {proposals.map(({ id, rate, currency, need }) => (
        <ProjectAccordion key={id} project={need.project} fte={need.fte} rate={rate} currency={currency} />
      ))}
    </>
  );
};
