import { getAllClientsQuery } from 'api/actions/getAllClients/getAllClients';
import { GetAllClientsParams, GetAllClientsResponse } from 'api/actions/getAllClients/getAllClients.types';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { clientsKeys } from 'utils/queryKeys';

export const useAllClients = () => {
  const { isAuthenticated } = useAuth();

  return useQuery<GetAllClientsParams, GetAllClientsResponse>(clientsKeys.allClients, getAllClientsQuery, {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });
};
