import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { AddWorkStatementRequest, AddWorkStatementResponse } from './addWorkStatementActions.types';

export const addWorkStatementMutation: () => MutationFn<AddWorkStatementRequest, AddWorkStatementResponse> =
  () => (body) => ({
    endpoint: `/work-statements`,
    method: 'POST',
    params: body,
  });
