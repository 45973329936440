import { Typography } from 'lux/components';
import { Box } from '@mui/material';

import { HoursSumProps } from './HoursSum.types';
import * as styles from './HoursSum.styles';

export const HoursSum = ({ children, sx, isDimmed }: HoursSumProps) => (
  <Box sx={() => ({ ...styles.hoursSum(isDimmed), ...sx })}>
    <Typography variant="buttonSmall">{children}</Typography>
  </Box>
);
