import { useMemo } from 'react';

import { QueryKey, UseQueryResult, useQuery as useRqQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useApiClient } from '../useApiClient/useApiClient';

import { QueryFn, UseQueryOptions } from './useQuery.types';

export const useQuery = <TArgs, TQueryFnData = unknown, TError = unknown, TData = TQueryFnData>(
  queryKey: QueryKey,
  query: QueryFn<TArgs, TQueryFnData, TData>,
  options?: UseQueryOptions<TArgs, TQueryFnData, AxiosError<TError>, TData>,
): UseQueryResult<TData, AxiosError<TError>> => {
  const { queryFn } = useApiClient();
  const _queryFn = useMemo(
    () => queryFn<TArgs, TQueryFnData, TData, AxiosError<TError>>(query, options),
    [queryFn, options],
  );
  return useRqQuery<TQueryFnData, AxiosError<TError>, TData, QueryKey>(queryKey, _queryFn, options);
};
