import { ReactNode, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Button } from 'lux/components';
import { useSnackbar } from 'notistack';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { useLocale } from 'hooks/useLocale/useLocale';
import { ProposalStatus } from 'config/data/need/ProposalStatus.enum';
import { AppMessages } from 'i18n/messages';
import { useProject } from 'hooks/useProject/useProject';
import { Loader } from 'ui/loader/Loader';
import { useNeedModal } from 'hooks/useNeedModal/useNeedModal';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useAssignPerson } from 'hooks/useAssignPerson/useAssignPerson';
import { AssignOrProposeAnywayModal } from 'shared/assignOrProposeAnywayModal/AssignOrProposeAnywayModal';
import type { Proposal } from 'shared/needs/Needs.types';
import type { AssignPersonError } from 'api/actions/assignPerson/assignPersonActions.types';
import { Translation } from 'ui/translation/Translation';
import { useConfetti } from 'hooks/useConfetti/useConfetti';
import { AppRoute } from 'routing/AppRoute.enum';
import { sortArr } from 'utils/sortArr';
import { AssignmentInfoContextController } from '../../../../../../context/assignmentInfo/assignmentInfoContextController/AssignmentInfoContextController';
import { AssignmentsFiltersContextController } from '../../../../../../context/assignmentsFilters/assignmentsFiltersContextController/AssignmentsFiltersContextController';
import { ProjectDetailsContextController } from '../../../../../../context/projectDetails/projectDetailsContextController/ProjectDetailsContextController';
import { getApiError } from '../../../../../../api/utils/getApiError';

import { ProposalsList } from './proposalsList/ProposalsList';
import { NeedDetails } from './needDetails/NeedDetails';
import { ConfirmRejectModal } from './confirmRejectModal/ConfirmRejectModal';
import { ConfirmRemoveModal } from './confirmRemoveModal/ConfirmRemoveModal';
import { PeopleAssignImmediatelyModal } from './assignImmediatelyModal/PeopleAssignImmediatelyModal';
import { PeopleProposeModal } from './proposeModal/PeopleProposeModal';
import { AssigningSummary } from './assigningSummary/AssigningSummary';
import * as styles from './NeedCardBody.styles';
import type { NeedCardBodyProps } from './NeedCardBody.types';

const sortFn = (a: Proposal, b: Proposal): 1 | 0 | -1 => {
  if (a.status === b.status) {
    return 0;
  }

  if (a.status === ProposalStatus.approved || b.status === ProposalStatus.rejected) {
    return -1;
  }

  return 1;
};

const NeedCardBodyContextWrapper = ({ children, projectId }: { children: ReactNode; projectId?: string }) => (
  <AssignmentsFiltersContextController>
    <ProjectDetailsContextController projectId={projectId}>
      <AssignmentInfoContextController>{children}</AssignmentInfoContextController>
    </ProjectDetailsContextController>
  </AssignmentsFiltersContextController>
);

export const NeedCardBody = ({
  projectId,
  needData,
  onClose,
  onNestedModalToggle,
  proposals,
  rejections,
}: NeedCardBodyProps) => {
  const { data: projectDetails, isLoading: isProjectDataLoading, status: projectDetailsStatus } = useProject(projectId);
  const { formatMessage } = useLocale();
  const {
    isConfirmAssignOpen,
    isConfirmRejectOpen,
    isConfirmRemoveOpen,
    selectedProposal,
    detailsVisible,
    setProjectDetails,
    setNeedData,
    setRejections,
    closeConfirmAssign,
  } = useNeedModal();
  const { mutate: assignPersonToProject, isLoading: isAssignPersonToProjectLoading } = useAssignPerson(projectId);
  const { isOpen: isAssignModalOpen, setOpen: setAssignModalOpen, setClose: setAssignModalClose } = useDialog();
  const [immediately, setImmediately] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [warnings, setWarnings] = useState<AssignPersonError | null>(null);
  const { fireConfetti } = useConfetti();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isOpen: isForceAssignModalOpen,
    setOpen: setForceAssignModalOpen,
    setClose: setForceAssignModalClose,
  } = useDialog();

  useEffect(() => {
    if (projectDetailsStatus === 'success') {
      setProjectDetails(projectDetails);
    }
  }, [projectDetailsStatus]);

  useEffect(() => {
    setNeedData(needData);
  }, [needData]);

  useEffect(() => {
    setRejections(rejections);
  }, [rejections]);

  const hasAnyProposals =
    needData.proposalStats.proposed > 0 || needData.proposalStats.approved > 0 || rejections.length > 0;
  const proposalsAndRejections = [...sortArr(proposals, sortFn), ...rejections];

  if (isProjectDataLoading) {
    return <Loader fullHeight={false} />;
  }

  if (!projectDetails) {
    return null;
  }

  const handleAssignImmediately = () => {
    setImmediately(true);
    setAssignModalOpen();
  };

  const handlePropose = () => {
    setImmediately(false);
    setAssignModalOpen();
  };

  const handleAssignClose = () => {
    setImmediately(false);
    setAssignModalClose();
  };

  const handleAssignImmediatelyClose = () => {
    handleAssignClose();
    onClose();
  };

  const handleAssign = (force = false) => {
    if (!selectedProposal) {
      return;
    }

    const pathToProject = generatePath(AppRoute.projectDetails, { projectId: projectDetails.id });

    assignPersonToProject(
      {
        proposal: {
          needId: needData.id,
          id: selectedProposal.id,
        },
        force,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(
            formatMessage(
              { id: AppMessages['snackbar.assignPersonToProject.success'] },
              {
                personName: `${selectedProposal.person.firstName} ${selectedProposal.person.lastName}`,
              },
            ),
            location.pathname !== pathToProject
              ? {
                  luxSnackbarAction: () => {
                    navigate(pathToProject);
                  },
                  luxSnackbarActionLabel: formatMessage({
                    id: AppMessages['snackbar.assignPersonToProject.success.button'],
                  }),
                }
              : undefined,
          );
          fireConfetti({ times: 7 });
          onClose();
        },
        onError: (error) => {
          if (error.response?.status === 409) {
            setWarnings(error.response.data);
            setForceAssignModalOpen();
          } else {
            enqueueSnackbar(
              formatMessage({ id: getApiError(error, { fallback: 'snackbar.assignPersonToProject.error' }) }),
              { variant: 'error' },
            );
          }
        },
      },
    );
  };

  if (isConfirmAssignOpen && selectedProposal) {
    return (
      <NeedCardBodyContextWrapper projectId={projectId}>
        {detailsVisible && <NeedDetails projectDetails={projectDetails} needData={needData} />}

        <AssigningSummary project={projectDetails} proposal={selectedProposal as Proposal} need={needData} />

        {warnings && selectedProposal && (
          <AssignOrProposeAnywayModal
            mode="assign"
            open={isForceAssignModalOpen}
            onClose={() => {
              setForceAssignModalClose();
              setWarnings(null);
              onNestedModalToggle();
            }}
            startDate={needData.startDate}
            projectId={projectId}
            loading={isAssignPersonToProjectLoading}
            warnings={warnings}
            employeeId={selectedProposal.person.employeeId}
            onSubmit={() => handleAssign(true)}
          />
        )}

        <Box sx={styles.detailsFooter}>
          <Button data-cy="need-card-body_btn-back" variant="outlined" onClick={closeConfirmAssign}>
            <Translation id="button.back" />
          </Button>

          <Button data-cy="need-card-body_btn-confirm" variant="contained" onClick={() => handleAssign()}>
            <Translation id="button.confirm" />
          </Button>
        </Box>
      </NeedCardBodyContextWrapper>
    );
  }

  const assignModal = immediately ? (
    <PeopleAssignImmediatelyModal
      projectId={projectId}
      needData={needData}
      rateCard={projectDetails.currentRateCard}
      isOpen
      onClose={handleAssignClose}
      onSuccess={handleAssignImmediatelyClose}
    />
  ) : (
    <PeopleProposeModal projectId={projectId} needData={needData} isOpen onClose={handleAssignClose} />
  );

  return (
    <>
      {detailsVisible && <NeedDetails projectDetails={projectDetails} needData={needData} />}

      {hasAnyProposals && <ProposalsList items={proposalsAndRejections} />}

      <Box sx={styles.detailsFooter}>
        <Button variant="outlined" onClick={handleAssignImmediately} data-cy="need-modal_assign-btn">
          <Translation id="button.assignTeamMember" />
        </Button>

        <Button variant="contained" onClick={handlePropose} data-cy="need-modal_propose-btn">
          <Translation id="button.proposeEmployee" />
        </Button>
      </Box>
      {isConfirmRejectOpen && <ConfirmRejectModal open />}
      {isConfirmRemoveOpen && <ConfirmRemoveModal open />}
      <NeedCardBodyContextWrapper projectId={projectId}>{isAssignModalOpen && assignModal}</NeedCardBodyContextWrapper>
    </>
  );
};
