import { Button } from 'lux/components';

import { BannerGroups } from 'ui/bannerGroup/BannerGroup';
import { Banner } from 'ui/banner/Banner';
import { BannerVariant } from 'ui/banner/Banner.types';
import { Translation } from 'ui/translation/Translation';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useConfirmProjectExtension } from 'hooks/useExtendProject/useExtendProject';
import { useProjectDetailsOutdatedAssignmentsCount } from 'hooks/useProjectDetailsOutdatedAssignmentsCount/useProjectDetailsOutdatedAssignmentsCount';

import * as styles from './ProjectBanners.styles';
import { ProjectBannersProps } from './ProjectBanners.types';

export const ProjectBanners = ({
  isCancelExtensionBannerOpen,
  isPlanExtensionBannerOpen,
  onCloseProjectExtension,
  onConfirmProjectExtension,
  onCancelProjectExtension,
}: ProjectBannersProps) => {
  const { projectDetails } = useProjectDetails();
  const { formatMessage } = useLocale();

  const { mutate: confirmProjectExtension, isLoading: isConfirmProjectExtensionLoading } = useConfirmProjectExtension(
    projectDetails.id,
  );

  const outdatedAssignmentsCount = useProjectDetailsOutdatedAssignmentsCount();
  const isAssignmentsOutOfDateOpen = !!outdatedAssignmentsCount;

  return (
    <BannerGroups
      sx={styles.bannerGroup(isPlanExtensionBannerOpen || isCancelExtensionBannerOpen || isAssignmentsOutOfDateOpen)}
    >
      {isAssignmentsOutOfDateOpen && (
        <Banner
          emoji="alarm"
          variant={BannerVariant.error}
          title={formatMessage(
            { id: AppMessages['projectDetails.assignmentsOutOfDateBanner.title'] },
            {
              count: outdatedAssignmentsCount,
            },
          )}
          subtitle={formatMessage({ id: AppMessages['projectDetails.assignmentsOutOfDateBanner.subtitle'] })}
        />
      )}
      {isPlanExtensionBannerOpen && (
        <Banner
          emoji="flag"
          title={formatMessage({ id: AppMessages['projectDetails.planProjectExtensionBanner.title'] })}
          subtitle={formatMessage({ id: AppMessages['projectDetails.planProjectExtensionBanner.subtitle'] })}
          actions={
            <>
              <Button size="small" variant="text" onClick={onCloseProjectExtension}>
                <Translation id="button.close" />
              </Button>
              <Button size="small" onClick={onConfirmProjectExtension} sx={styles.planExtensionBtn}>
                <Translation id="projectDetails.planProjectExtensionBanner.confirm" />
              </Button>
            </>
          }
        />
      )}
      {isCancelExtensionBannerOpen && (
        <Banner
          emoji="flag"
          title={formatMessage({ id: AppMessages['projectDetails.cancelProjectExtensionBanner.title'] })}
          actions={
            <>
              <Button size="small" variant="text" onClick={onCancelProjectExtension}>
                <Translation id="button.cancel" />
              </Button>
              <Button
                size="small"
                onClick={() => confirmProjectExtension({})}
                loading={isConfirmProjectExtensionLoading}
              >
                <Translation id="button.apply" />
              </Button>
            </>
          }
        />
      )}
    </BannerGroups>
  );
};
