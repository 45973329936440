import { Styles } from 'styles/theme';

export const accordionDetails = (onlyCurrentVisible: boolean): Styles => ({
  '&&': {
    pt: 0,
    px: 0,

    '.MuiTabs-root': {
      ...(onlyCurrentVisible ? { display: 'none' } : { minHeight: 40 }),
    },

    '.MuiTab-root': {
      minHeight: 40,
      py: 1.25,
    },
  },
});

export const titleBadge: Styles = {
  ml: 1,

  '&& .MuiBadge-badge': {
    backgroundColor: 'accent.orange.main',
    color: 'text.primary',
    py: 0,
    px: 0.5,
  },
};
