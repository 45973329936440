import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { SeniorityFilter as SharedSeniorityFilter } from 'shared/filters/seniorityFilter/SeniorityFilter';

import { SeniorityFilterProps } from './SeniorityFilter.types';

export const SeniorityFilter = ({ variant }: SeniorityFilterProps) => {
  const {
    filters: { seniorities },
    toggleFilter,
    clearFilter,
  } = usePeopleFilters();

  const handleFilterChange = (value: string) => {
    toggleFilter('seniorities', value);
  };

  const clearSeniorities = () => {
    clearFilter('seniorities');
  };

  return (
    <SharedSeniorityFilter
      variant={variant}
      seniorities={seniorities}
      dataCy="available-people_filter-seniority"
      onFilterClick={handleFilterChange}
      onClearClick={clearSeniorities}
    />
  );
};
