import { Box } from '@mui/material';
import { Button, Typography } from 'lux/components';
import { AddIcon } from 'lux/icons';

import { Translation } from 'ui/translation/Translation';
import { Chips } from 'ui/chips/Chips';

import { RateCardSuggestionsProps } from './RateCardSuggestions.types';
import * as styles from './RateCardSuggestions.styles';

export const RateCardSuggestions = ({ onSuggestionClick, onAddRole, chips }: RateCardSuggestionsProps) => (
  <Box sx={styles.card}>
    <Typography variant="h6" sx={styles.title}>
      <Translation id="rateCard.form.suggestedRoles" />
    </Typography>

    <Chips>
      {chips.map((role) => (
        <Chips.Chip key={role} label={role} onClick={() => onSuggestionClick(role)} />
      ))}
    </Chips>

    <Button
      variant="text"
      startIcon={<AddIcon fontSize="large" />}
      sx={styles.addRoleButton}
      size="small"
      onClick={() => onAddRole()}
    >
      <Translation id="projectDetails.accordions.details.rateCard.updateRateCard.addRole" />
    </Button>
  </Box>
);
