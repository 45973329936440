import { Styles } from 'styles/theme';

export const WORK_STATEMENT_DETAILS_WIDTH = 432;
export const WORK_STATEMENT_DETAILS_ANIMATION_DURATION = 300;

export const container: Styles = {
  position: 'relative',
  [`@media print`]: {
    width: '100%',
    padding: '0 1rem',
  },
};

export const hideInPdf: Styles = {
  [`@media print`]: {
    display: 'none !important',
  },
};

export const wrapper = (isShrunk = false): Styles => ({
  border: 'none',
  width: isShrunk ? `calc(100% - ${WORK_STATEMENT_DETAILS_WIDTH}px)` : '100%',
  transition: `ease-in-out ${WORK_STATEMENT_DETAILS_ANIMATION_DURATION}ms`,

  ['@page']: { size: 'portrait' },
});

export const title: Styles = {
  display: 'block',
  color: 'text.secondary',
  fontWeight: 500,
  mb: 2,
  marginTop: 0,

  [`@media print`]: {
    marginTop: 0,
  },
};

export const mainContainer: Styles = {
  [`@media print`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
  },
};

export const billingSummaryTitleContainer: Styles = {
  display: 'flex',
  flexDirection: 'row',
  marginTop: 3,
  justifyContent: 'space-between',
  [`@media print`]: {
    marginTop: 0,
  },
};

export const ProjectRaportWrapperStyles: Styles = {
  flex: 1,
  backgroundColor: 'common.white',
  padding: 5,
  borderRadius: 0,
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  borderBottomLeftRadius: 20,
  borderBottomRightRadius: 20,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'border.light',

  [`@media print`]: {
    padding: 2,
  },
};

export const timesheetTitle: Styles = {
  display: 'block',
  width: '100%',
  textAlign: 'left',
  color: 'text.secondary',
  fontWeight: 500,
  mt: 3,
};

export const titleContainer: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  mb: 3,
};

export const timesheetWrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyItems: 'center',

  ['@media print']: {
    pageBreakBefore: 'always',
    textAlign: 'center',
    verticalAlign: 'middle',
    border: '0.5px solid',
    borderColor: 'accent.lightGray',
  },

  ['@page']: { size: 'landscape' },
};

export const bannerContainer: Styles = {
  display: 'none',

  ['@media print']: {
    display: 'block',
  },
};

export const pdfButton: Styles = {
  width: 250,
};
