import { Styles } from 'styles/theme';

export const comparisonRow = (isHighlighted: boolean, variant: 'info' | 'warning' = 'info'): Styles => ({
  ...(isHighlighted && { backgroundColor: `${variant}.light` }),
});

export const tableRowHeader: Styles = {
  typography: 'caption',
  fontWeight: 600,
  color: 'text.secondary',
  paddingX: 1,
  paddingY: 1.25,
};

export const comparisonTableCell = (hasIcon = false): Styles => ({
  typography: 'caption',
  paddingX: 1,
  paddingY: hasIcon ? 1 : 1.25,

  '&.MuiTableCell-root.MuiTableCell-root': {
    paddingLeft: '0',
    marginRight: '2rem',
  },

  svg: {
    display: 'block',
  },
});
