import { SxProps } from '@mui/system';
import { Styles } from 'lux/theme';

export const button: Styles = {
  mt: 2,

  ['@media print']: {
    display: 'none',
  },
};

export const inputWrapper: SxProps = {
  pb: 6,
};

export const wrapper: SxProps = {
  mt: 2,
  flexDirection: 'row',
  gap: 1,
};
