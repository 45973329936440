import { getBackgroundColor } from 'config/data/dashboard/availability';
import { Styles } from 'styles/theme';

export const cellBase: Styles = {
  '&&&': {
    padding: 0,
    overflow: 'hidden',
  },
};

export const cell = (available: number, total: number): Styles => ({
  backgroundColor: getBackgroundColor(available, total),
});

export const cellUnstyled: Styles = {
  '&.MuiTableCell-root': {
    backgroundColor: (theme) => theme.palette.common.white,
  },
};
