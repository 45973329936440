import { Table } from 'lux/components';

import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

import * as styles from './NeedsTable.styles';
import { NeedsTableHead } from './needsTableHead/NeedsTableHead';
import { PeopleAvatarsSkeleton } from './peopleAvatars/PeopleAvatars.skeleton';

export const NeedsTableSkeleton = () => (
  <Table>
    <Table.Table sx={styles.table} size="small">
      <NeedsTableHead />
      <Table.Body>
        {Array(8)
          .fill(null)
          .map((_, index) => (
            <Table.Row sx={styles.tableRow} key={index}>
              <Table.Cell>
                <TextSkeleton width={156} variant="caption" />
              </Table.Cell>
              <Table.Cell>
                <TextSkeleton width={66} variant="caption" />
              </Table.Cell>
              <Table.Cell>
                <TextSkeleton width={66} variant="caption" />
              </Table.Cell>
              <Table.Cell>
                <TextSkeleton width={16} variant="caption" />
              </Table.Cell>
              <Table.Cell>
                <PeopleAvatarsSkeleton width={128} />
              </Table.Cell>
              <Table.Cell>
                <PeopleAvatarsSkeleton width={180} />
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table.Table>
  </Table>
);
