import { Box, Stack } from '@mui/material';
import { Typography, Grid } from 'lux/components';
import { ChevronLeftIcon, ChevronRightIcon } from 'lux/icons';

import { formatDate } from 'utils/dateUtils';
import { SquareIconButton } from 'ui/squareIconButton/SquareIconButton';

import { TimelineHeaderProps } from './TimelineHeader.types';

export const TimelineHeader = ({ selectedPeriod, onMonthChange }: TimelineHeaderProps) => (
  <Box padding={3}>
    <Grid container alignItems="center" gutter={0} spacing={0}>
      <Grid item xs>
        <Typography variant="h6" color="text.secondary">
          {formatDate(selectedPeriod, 'MMMM yyyy')}
        </Typography>
      </Grid>
      <Grid item>
        <Stack direction="row" spacing={1}>
          <SquareIconButton size="small" variant="outlinedGray" onClick={() => onMonthChange('previous')}>
            <ChevronLeftIcon />
          </SquareIconButton>
          <SquareIconButton size="small" variant="outlinedGray" onClick={() => onMonthChange('next')}>
            <ChevronRightIcon />
          </SquareIconButton>
        </Stack>
      </Grid>
    </Grid>
  </Box>
);
