import { FocusEvent } from 'react';

import { Autocomplete as LuxAutocomplete } from 'lux/components';
import { FieldValues, Controller } from 'react-hook-form';

import { AutocompleteProps } from './Autocomplete.types';
import { filterOptions, getValue, normalizeValue } from './Autocomplete.functions';

export const Autocomplete = <T extends FieldValues>(props: AutocompleteProps<T>) => {
  const { name, control, label, size, aliases, inputMaxLength, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LuxAutocomplete
          {...rest}
          value={value || null}
          onChange={(_event, newValue) => {
            onChange(normalizeValue(newValue, props.options, props.freeSolo));
          }}
          label={label}
          size={size}
          filterOptions={filterOptions(props.freeSolo, aliases)}
          getOptionLabel={getValue}
          onBlur={(event: FocusEvent<HTMLInputElement>) => {
            const inputValue = event.target.value;
            onChange(normalizeValue(inputValue, props.options, props.freeSolo));
          }}
          error={!!error}
          helperText={!!error?.message ? error.message : ''}
          inputProps={{ maxLength: inputMaxLength }}
        />
      )}
    />
  );
};
