import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Avatar, Tooltip, Typography } from 'lux/components';

import { areDatesEqual, getStartOfMonthDate, parseDate } from 'utils/dateUtils';
import { TableCell, TableRowCollapsed, TableRow } from '../../tableComponents/tableComponents';
import { HoursPerDayCell } from '../hoursPerDayCell/HoursPerDayCell';
import { HoursSum } from '../hoursSum/HoursSum';
import { CollapseButton } from 'ui/collapseButton/CollapseButton';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';
import { AppMessages } from 'i18n/messages';
import { HourType } from 'api/types/HourType.enum';
import { roundNumber } from 'utils/roundNumber';
import { useProjectTimesheet } from 'hooks/useProjectTimesheet/useProjectTimesheet';
import { WarningTooltip } from '../warningTooltip/WarningTooltip';
import { useProjectDiscrepancy } from 'hooks/useProjectDiscrepancy/useProjectDiscrepancy';

import * as styles from './TeamMemberRow.styles';
import { AssignmentRowProps } from './TeamMemberRow.types';
import { AssignmentHoursDetailsRow } from './teamMemberHoursDetailsRow/TeamMemberHoursDetailsRow';

export const TeamMemberRow = ({ assignment }: AssignmentRowProps) => {
  const [isAssignmentRowExpanded, setIsAssignmentRowExpanded] = useState(false);
  const [isRootSelected, setIsRootSelected] = useState(false);
  const [assignmentHoursData, setAssignmentHoursData] = useState({
    fteHoursThisMonth: 0,
    hoursSum: 0,
    isConflict: false,
  });

  const { formatMessage } = useLocale();
  const { timesheetMonthStartDate } = useProjectTimesheet();
  const { setDiscrepancyData, discrepancyData } = useProjectDiscrepancy();
  const { isReport } = useProjectTimesheetData();

  const hoursSum = roundNumber(assignment.totalHours, 2);

  useEffect(() => {
    setAssignmentHoursData((prevValue) => ({ ...prevValue, isConflict: false }));

    if (!discrepancyData) {
      return;
    }

    const { startDate, discrepancyIdsWithHours } = discrepancyData;

    const currentUserDiscrepancyData = discrepancyIdsWithHours.find(
      ({ employeeId }) => employeeId === assignment.employeeId,
    );

    if (currentUserDiscrepancyData?.scheduledHours === hoursSum) {
      const updatedDiscrepancyPeopleIds = discrepancyData.discrepancyPeopleEmployeeIds.filter(
        (employeeId) => employeeId !== assignment.employeeId,
      );
      const updatedDiscrepancyIdsWithHours = discrepancyIdsWithHours.filter(
        ({ employeeId }) => employeeId !== assignment.employeeId,
      );
      const updatedDiscrepancyData = {
        ...discrepancyData,
        discrepancyIdsWithHours: updatedDiscrepancyIdsWithHours,
        discrepancyPeopleIds: updatedDiscrepancyPeopleIds,
      };

      setDiscrepancyData(updatedDiscrepancyPeopleIds.length === 0 ? null : updatedDiscrepancyData);
    }

    if (
      !currentUserDiscrepancyData ||
      !areDatesEqual(parseDate(startDate), getStartOfMonthDate(timesheetMonthStartDate))
    ) {
      return;
    }

    const { scheduledHours } = currentUserDiscrepancyData;

    const areValuesEquals = hoursSum === scheduledHours;

    !areValuesEquals && setAssignmentHoursData({ fteHoursThisMonth: scheduledHours, hoursSum, isConflict: true });
  }, [timesheetMonthStartDate, assignment]);

  const isPersonExpanded = () => {
    if (isReport) {
      return true;
    }

    return isAssignmentRowExpanded;
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Box sx={styles.innerWrapper}>
            <Box sx={styles.assignmentInfo}>
              <Box sx={styles.userInfoWrapper}>
                <Avatar size="xs" name={assignment.name} alt={assignment.name} sx={styles.avatar} />
                <Typography sx={styles.assignmentTitle}>{assignment.name}</Typography>
                <Tooltip title={<Typography>{assignment.role}</Typography>} arrow>
                  <Typography component="span" sx={styles.assignmentRole}>
                    {assignment.role}
                  </Typography>
                </Tooltip>
              </Box>
              {assignmentHoursData.isConflict && <WarningTooltip assignmentHoursData={assignmentHoursData} />}
            </Box>
            {assignment.hasMultipleHourEntries && !isReport && (
              <CollapseButton
                isExpanded={isAssignmentRowExpanded}
                onClick={() => setIsAssignmentRowExpanded(!isAssignmentRowExpanded)}
              />
            )}
            <HoursSum>{hoursSum}</HoursSum>
          </Box>
        </TableCell>
        {assignment.hours.map((assignmentHour, idx) => (
          <HoursPerDayCell
            dayNumber={idx + 1}
            key={idx}
            assignmentHour={assignmentHour}
            employeeId={assignment.employeeId}
            assignmentId={assignmentHour.assignmentId}
            setIsRootSelected={setIsRootSelected}
          />
        ))}
      </TableRow>

      {assignment.hasMultipleHourEntries && (
        <TableRowCollapsed isExpanded={isPersonExpanded()}>
          <>
            {assignment.rates.REGULAR.map((rate) => (
              <AssignmentHoursDetailsRow
                key={rate}
                title={formatMessage({ id: AppMessages['projectDetails.timesheet.regularWorks.rate'] }, { rate })}
                hourType={HourType.regular}
                assignmentHours={assignment.hours}
                employeeId={assignment.employeeId}
                rateToDisplay={rate}
                isRootSelected={isRootSelected}
              />
            ))}
            {assignment.rates.OVERHOUR.map((rate) => (
              <AssignmentHoursDetailsRow
                key={rate}
                title={formatMessage({ id: AppMessages['projectDetails.timesheet.overtime.rate'] }, { rate })}
                hourType={HourType.overhour}
                assignmentHours={assignment.hours}
                employeeId={assignment.employeeId}
                rateToDisplay={rate}
                isRootSelected={isRootSelected}
              />
            ))}
          </>
        </TableRowCollapsed>
      )}
    </>
  );
};
