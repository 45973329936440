import { SxProps } from '@mui/material';

import { Styles } from 'styles/theme';

export const loginContainer: Styles = {
  height: '100vh',
};

export const loginTitle: Styles = {
  marginTop: 4,
  marginBottom: 2,
};

export const loginDescriptions: Styles = {
  marginBottom: 8,
};

export const loginButton: Styles = {
  width: '100%',
};

export const loginBackground: Styles = {
  height: '100%',
  backgroundColor: 'surface.paper.default',
};

export const authIcon: SxProps = {
  width: '100%',
};
