import { useContext } from 'react';

import { AvailabilityFiltersContext } from 'context/availabilityFilters/availabilityFiltersContext/AvailabilityFiltersContext';

export const useAvailabilityFilters = () => {
  const context = useContext(AvailabilityFiltersContext);

  if (context === undefined) {
    throw new Error('AvailabilityFiltersContext must be within AvailabilityFiltersContextProvider');
  }

  return context;
};
