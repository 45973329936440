import { Table } from 'lux/components';

import { WorkStatementTableCell as TableCell } from '../workStatementTableCell/WorkStatementTableCell';
import { Translation } from 'ui/translation/Translation';

import { WorkStatementRole } from './workStatementRole/WorkStatementRole';
import { table } from './WorkStatementByRole.styles';
import { WorkStatementByRoleProps } from './WorkStatementByRole.types';
import * as styles from './WorkStatementByRole.styles';

export const WorkStatementByRole = ({ workStatement }: WorkStatementByRoleProps) => (
  <Table.Table sx={table}>
    <Table.Head>
      <Table.Row>
        <TableCell isLabel autoWidth>
          <Translation id="projectDetails.billingSummary.role" />
        </TableCell>
        <TableCell isLabel autoWidth sx={styles.hoursCell}>
          <Translation id="projectDetails.billingSummary.hours" />
        </TableCell>
        <TableCell isLabel>
          <Translation id="projectDetails.billingSummary.rate" />
        </TableCell>
        <TableCell isLabel>
          <Translation id="projectDetails.billingSummary.total" />
        </TableCell>
      </Table.Row>
    </Table.Head>
    <Table.Body>
      {workStatement.roles?.map((role) => (
        <WorkStatementRole key={role.name} role={role} />
      ))}
    </Table.Body>
  </Table.Table>
);
