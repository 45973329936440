export enum TimelineEventType {
  assignment = 'assignment',
  proposal = 'proposal',
  absence = 'absence',
}

export type TimelineEventProps = {
  id: string;
  name: string;
  startDate: Date;
  type: TimelineEventType;
  status?: string;
  endDate?: Date;
  rowsCount?: number;
  columnCount?: number;
  eventStartsBeforePeriod?: boolean;
  eventEndsAfterPeriod?: boolean;
};

export type TimelineEventStyleParams = {
  type: TimelineEventType;
  shouldWrapBadge: boolean;
  eventStartsBeforePeriod?: boolean;
  eventEndsAfterPeriod?: boolean;
};
