import Joi from 'joi';

import { AppMessages } from 'i18n/messages';
import { intl } from 'context/locale/intl/Intl';

export const updateProjectNameSchema = () => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object({
    name: Joi.string()
      .required()
      .messages({
        'string.empty': formatMessage(
          { id: AppMessages['validation.empty'] },
          { label: formatMessage({ id: AppMessages['projectDetails.projectName'] }) },
        ),
      }),
    clientId: Joi.string().required(),
  });
};
