import { convertRateToDecimal } from 'utils/convertRate';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { projectsKeys } from 'utils/queryKeys';
import { getProjectQuery } from 'api/actions/getProject/getProject';
import { GetProjectParams, GetProjectResponse } from 'api/actions/getProject/getProject.types';

export const useProject = (projectId: string) => {
  const { isAuthenticated } = useAuth();

  const { data, ...response } = useQuery<GetProjectParams, GetProjectResponse>(
    projectsKeys.singleProject(projectId),
    getProjectQuery(projectId),
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
    },
  );

  if (!data) {
    return { data, ...response };
  }

  const mappedData: GetProjectResponse = {
    ...data,
    budget: data.budget && String(convertRateToDecimal(data.budget)),
    currentRateCard: {
      ...data.currentRateCard,
      items: Object.fromEntries(
        Object.entries(data.currentRateCard.items).map(([seniority, details]) => [
          seniority,
          details.map((detail) => ({ ...detail, amount: convertRateToDecimal(detail.amount) })),
        ]),
      ),
    },
    futureRateCard: data.futureRateCard && {
      ...data.futureRateCard,
      items: Object.fromEntries(
        Object.entries(data.futureRateCard.items).map(([seniority, details]) => [
          seniority,
          details.map((detail) => ({ ...detail, amount: convertRateToDecimal(detail.amount) })),
        ]),
      ),
    },
    previousRateCards: data.previousRateCards.map((rateCard) => ({
      ...rateCard,
      items: Object.fromEntries(
        Object.entries(rateCard.items).map(([seniority, details]) => [
          seniority,
          details.map((detail) => ({ ...detail, amount: convertRateToDecimal(detail.amount) })),
        ]),
      ),
    })),
  };

  return { data: mappedData, ...response };
};
