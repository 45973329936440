import { Box } from '@mui/material';

import { NeedCardSkeleton } from 'app/need/card/NeedCard.skeleton';

import * as styles from './NeedsList.styles';

export const NeedsListSkeleton = () => (
  <Box sx={styles.needsList}>
    {Array(6)
      .fill(null)
      .map((_, index) => (
        <NeedCardSkeleton key={index} />
      ))}
  </Box>
);
