import { AccordionFilter } from './accordionFilter/AccordionFilter';
import { AccordionFilterProps } from './accordionFilter/AccordionFilter.types';
import { FilterProps } from './Filter.types';
import { PopoverFilter } from './popoverFilter/PopoverFilter';
import { PopoverFilterProps } from './popoverFilter/PopoverFilter.types';

export const Filter = ({ variant, ...rest }: FilterProps) => (
  <>
    {variant === 'popover' && <PopoverFilter {...(rest as PopoverFilterProps)} />}
    {variant === 'accordion' && <AccordionFilter {...(rest as AccordionFilterProps)} />}
  </>
);
