import { Styles } from 'styles/theme';

export const dashboardSideCardWrapper: Styles = {
  borderRadius: 2,
  backgroundColor: 'surface.default',
  width: '100%',
  minHeight: '100%',
};

export const container: Styles = {
  ['@media print']: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    pageBreakInside: 'avoid',
  },
};
