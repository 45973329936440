import { Box, Stack } from '@mui/material';
import { Avatar, Typography, Button, Grid } from 'lux/components';
import { EditIcon } from 'lux/icons';

import { useDialog } from 'hooks/useDialog/useDialog';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { usePersonData } from 'hooks/usePersonData/usePersonData';
import { getPersonFullRole } from 'utils/stringUtils';
import { PersonStatusBadge } from 'ui/personStatusBadge/PersonStatusBadge';
import { EmojiBadges } from 'ui/emojiBadges/EmojiBadges';
import { isPlaceholder } from 'utils/isPlaceholder';

import { EditUncertainModal } from './editUncertainModal/EditUncertainModal';
import * as styles from './PersonHeader.styles';

export const PersonHeader = () => {
  const { formatMessage } = useLocale();
  const { isOpen, setOpen, setClose } = useDialog();

  const { person } = usePersonData();
  const {
    firstName,
    lastName,
    seniority,
    employmentType,
    departmentCode,
    picture,
    availabilityStatus,
    availableFte,
    proposalCount,
    isLeader,
    employedTo,
    absences,
  } = person;

  const name = `${firstName} ${lastName}`;

  return (
    <Box sx={styles.personHeaderWrapper}>
      <Grid container alignItems="center" justifyContent="space-between" gutter={0}>
        <Grid item container xs alignItems="center" spacing={3}>
          <Grid item>
            <Avatar alt={name} sx={styles.personAvatar} image={picture} />
          </Grid>
          <Grid item>
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography variant="h5" fontWeight={500}>
                {name}
              </Typography>
              <EmojiBadges
                employmentType={employmentType}
                proposalCount={proposalCount}
                isLeader={isLeader}
                employedTo={employedTo}
                absences={absences}
              />
              <Box>
                <PersonStatusBadge status={availabilityStatus} availableFte={availableFte} />
              </Box>
            </Stack>
            <Typography color="text.secondary" fontWeight={500}>
              {getPersonFullRole(seniority, departmentCode)}
            </Typography>
          </Grid>
        </Grid>
        {isPlaceholder(person) && (
          <Grid item>
            <Button onClick={setOpen} size="large" variant="outlined" color="primary" startIcon={<EditIcon />}>
              {formatMessage({ id: AppMessages['personDetails.header.button.editUncertain'] })}
            </Button>
          </Grid>
        )}
      </Grid>
      <EditUncertainModal open={isOpen} onClose={setClose} />
    </Box>
  );
};
