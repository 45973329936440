import { stringify } from 'qs';

import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { DeleteChargeParams } from './deleteChargeActions.types';

export const deleteChargeMutation: (workStatementId: string) => MutationFn<DeleteChargeParams> =
  (workStatementId: string) => (params) => ({
    endpoint: `/work-statements/${workStatementId}/charges/${params.chargeId}?${stringify({
      billingCycleId: params.billingCycleId,
      invoiceId: params.invoiceId,
    })}`,
    method: 'DELETE',
  });
