import { Dialog } from '@mui/material';

import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';

import { AddUncertainModalProps } from './AddUncertainModal.types';
import { AddUncertainContent } from './addUncertainContent/AddUncertainContent';

export const AddUncertainModal = ({ open, onClose }: AddUncertainModalProps) => {
  useDialogBlockScroll(open);

  return (
    <Dialog open={open} onClose={onClose}>
      <AddUncertainContent onClose={onClose} />
    </Dialog>
  );
};
