import { Typography } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { InfoBox } from 'ui/infoBox/InfoBox';

import { RejectionsWarningProps } from './RejectionsWarning.types';

export const RejectionsWarning = ({ rejections, projectId }: RejectionsWarningProps) => {
  const { formatMessage } = useLocale();

  return (
    <InfoBox
      title={formatMessage({ id: AppMessages['assignOrProposeAnyway.warnings.rejections.title'] })}
      variant="warning"
      actionLabel={formatMessage({ id: AppMessages['assignOrProposeAnyway.warnings.rejections.action'] })}
      onAction={() => window.open(`${window.location.origin}/projects/${projectId}`, '_blank', 'noopener,noreferrer')}
    >
      {rejections.map((rejection) => (
        <Typography variant="body2" key={rejection.id}>
          {rejection.rejectionDate}:{' '}
          <Typography component="span" variant="inherit" fontWeight={500}>
            {rejection.reason}
          </Typography>
        </Typography>
      ))}
    </InfoBox>
  );
};
