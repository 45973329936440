import { Filters } from 'hooks/useFilters/useFilters.types';

export enum PeriodType {
  weekly = 'weekly',
  monthly = 'monthly',
}

export type AvailabilityFilters = {
  splitBySeniority: boolean;
  period: PeriodType;
  includeProposed: boolean;
  includeExtension: boolean;
  isAvailabilityExpanded: boolean;
};

export type AvailabilityFiltersValue = {
  filters: AvailabilityFilters;
} & Filters<AvailabilityFilters>;
