import { FormProvider } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useAddWorkStatement } from 'hooks/useAddWorkStatement/useAddWorkStatement';
import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';
import { workStatementFormSchema } from '../../workStatementFormContent/WorkStatementFormContent.schema';
import { WorkStatementForm } from '../../WorkStatementModals.types';
import { WorkStatementFormContent } from '../../workStatementFormContent/WorkStatementFormContent';
import { formatDate, parseDate } from 'utils/dateUtils';
import { WorkStatementFormType } from '../../workStatementFormContent/WorkStatementFormContent.types';

import { AddWorkStatementContentProps, AddWorkStatementPayload } from './AddWorkStatementContent.types';

const defaultValues: WorkStatementForm = {
  statementNumber: '',
  startDate: null,
  endDate: null,
  depositPaymentDate: null,
  depositAmount: '',
  billingCycle: 'month',
  paymentPeriod: 14,
};

export const AddWorkStatementContent = ({
  onClose,
  projectId,
  useProjectStartDate,
  projectStartDate,
  projectEndDate,
  projectCurrency,
}: AddWorkStatementContentProps) => {
  const { formatMessage } = useLocale();

  const { mutate, isLoading } = useAddWorkStatement();

  const form = useIntlForm<WorkStatementForm>({
    defaultValues: {
      ...defaultValues,
      ...(useProjectStartDate && { startDate: parseDate(projectStartDate) }),
    },
    mode: 'all',
    resolver: joiResolver(workStatementFormSchema(useProjectStartDate, projectStartDate, projectEndDate)),
  });

  const onSubmit = (data: WorkStatementForm) => {
    const payload: AddWorkStatementPayload = {
      projectId: projectId,
      workStatement: {
        statementNumber: data.statementNumber,
        startDate: data.startDate ? formatDate(data.startDate) : '',
        ...(data.paymentPeriod && { paymentPeriod: data.paymentPeriod }),
        ...(data.endDate && { endDate: formatDate(data.endDate) }),
        ...(typeof data.depositAmount === 'number' && data.depositPaymentDate
          ? {
              deposits: [
                {
                  amount: data.depositAmount,
                  paymentDue: data.depositPaymentDate ? formatDate(data.depositPaymentDate) : '',
                },
              ],
            }
          : {}),
      },
    };
    mutate(payload, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <DialogInnerWrapper
      title={formatMessage({ id: AppMessages['projectDetails.workStatementForm.addWorkStatement.title'] })}
      loading={isLoading}
      onClose={onClose}
      submitText={formatMessage({ id: AppMessages['button.save'] })}
      onSubmit={form.handleSubmit(onSubmit)}
      isFormValid={form.formState.isValid}
    >
      <FormProvider {...form}>
        <WorkStatementFormContent
          type={WorkStatementFormType.add}
          loading={isLoading}
          projectStartDate={projectStartDate}
          projectEndDate={projectEndDate}
          projectCurrency={projectCurrency}
          useProjectStartDate={useProjectStartDate}
        />
      </FormProvider>
    </DialogInnerWrapper>
  );
};
