import { FormControlLabel } from '@mui/material';
import { Checkbox } from 'lux/components';
import { ClockIcon } from 'lux/icons';

import { Filter } from 'ui/filter/Filter';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { filtersData } from 'config/data/people/filters';

import { StatusFilterProps } from './StatusFilter.types';

export const StatusFilter = ({ variant }: StatusFilterProps) => {
  const { formatMessage } = useLocale();
  const {
    filters: { status },
    toggleFilter,
    clearFilter,
  } = usePeopleFilters();

  const handleFilterChange = (value: string) => {
    toggleFilter('status', value);
  };

  const clearStatus = () => {
    clearFilter('status');
  };

  return (
    <Filter
      variant={variant}
      icon={<ClockIcon />}
      dataCy="available-people_filter-status"
      label={formatMessage({ id: AppMessages['filters.status.label'] })}
      popoverTitle={formatMessage({ id: AppMessages['filters.status.popover.title'] })}
      popoverActionLabel={formatMessage({ id: AppMessages['filters.clear'] })}
      valueSelected={status.length > 0}
      selectedCount={status.length}
      onPopoverActionClick={clearStatus}
    >
      {filtersData['status'].map(({ labelTranslation, value }) => (
        <FormControlLabel
          key={value}
          label={formatMessage({ id: AppMessages[labelTranslation] })}
          control={
            <Checkbox value={value} checked={status.includes(value)} onChange={() => handleFilterChange(value)} />
          }
        />
      ))}
    </Filter>
  );
};
