import { Stack, Box } from '@mui/material';
import { Button, Typography } from 'lux/components';
import { InfoFilledIcon } from 'lux/icons';

import { WorkStatement } from 'api/types/BillingSummary.types';
import { Translation } from 'ui/translation/Translation';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { WorkStatementStatusBadge } from 'ui/workStatementStatusBadge/WorkStatementStatusBadge';
import { BillingSummaryActions } from '../billingSummaryActions/BillingSummaryActions';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';

import * as styles from './BillingSummaryWorkStatement.styles';
import { BillingSummaryWorkStatementProps } from './BillingSummaryWorkStatement.types';
import { WorkStatementSummary } from './workStatementSummary/WorkStatementSummary';
import { WorkStatementByMembers } from './workStatementByMember/WorkStatementByMember';
import { WorkStatementByRole } from './workStatementByRole/WorkStatementByRole';

const renderWorkStatement = (workStatement: WorkStatement) => {
  if (workStatement.roles) {
    return <WorkStatementByRole workStatement={workStatement} />;
  } else if (workStatement.assignments) {
    return <WorkStatementByMembers workStatement={workStatement} />;
  }
};

export const BillingSummaryWorkStatement = ({
  workStatement,
  billingCycle,
  onClick,
  isClientView = false,
}: BillingSummaryWorkStatementProps) => {
  const { snapshotCreateDate, showWorkStatementSnapshot } = useProjectBillingSummaryData();
  const { isReport } = useProjectBillingSummaryData();
  const { projectDetails } = useProjectDetails();
  const { formatMessage } = useLocale();

  return (
    <>
      {!isReport && (
        <Stack sx={styles.workStatementHeader}>
          <Stack>
            <Stack direction="row" alignItems="center">
              <Typography sx={styles.workStatementTitle}>
                {isClientView && ' ' + projectDetails.name + ' - '}
                <Translation id="projectDetails.billingSummary.workStatement" />
                {' ' + workStatement.statementNumber}
              </Typography>
              {!isClientView && (
                <Button variant="text" onClick={() => onClick(workStatement)}>
                  <Translation id="projectDetails.billingSummary.showDetails" />
                </Button>
              )}
            </Stack>
            {showWorkStatementSnapshot && (
              <Stack direction="row" alignItems="center" gap={1} mb={2}>
                <InfoFilledIcon color="info" sx={styles.infoIcon} />
                <Typography>
                  {formatMessage(
                    { id: AppMessages['projectDetails.billingSummary.snapshot.info.alert'] },
                    {
                      date: snapshotCreateDate,
                    },
                  )}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Box sx={styles.workStatementActions}>
            {billingCycle && <WorkStatementStatusBadge workStatement={workStatement} billingCycle={billingCycle} />}
            {billingCycle && workStatement.invoice && (
              <BillingSummaryActions
                invoice={workStatement.invoice}
                billingCycle={billingCycle}
                isClientView={isClientView}
              />
            )}
          </Box>
        </Stack>
      )}

      {renderWorkStatement(workStatement)}

      <WorkStatementSummary workStatement={workStatement} billingCycle={billingCycle} isClientView={isClientView} />
    </>
  );
};
