import { useEffect, useState } from 'react';

import { Typography, Tooltip } from 'lux/components';
import { Box, Stack } from '@mui/material';
import { ErrorFilledIcon } from 'lux/icons';

import { Translation } from 'ui/translation/Translation';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useBillingSummaryPrice } from 'hooks/useBillingSummaryPrice/useBillingSummaryPrice';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { AppMessages } from 'i18n/messages';
import { hideInPdf } from '../../projectReport/ProjectReport.styles';

import * as styles from './CostSummary.styles';
import { CostSummaryProps } from './CostSummary.types';

export const CostSummary = ({ label, amount, variant = 'h6', isClientView }: CostSummaryProps) => {
  const [showError, setShowError] = useState(false);

  const { formatMessage } = useLocale();
  const { formatPrice } = useBillingSummaryPrice();
  const { dataMismatch, snapshotCreateDate, showWorkStatementSnapshot } = useProjectBillingSummaryData();
  const { projectDetails } = useProjectDetails();

  const subtotalValueText = 'projectDetauls.billingSummary.workStatementDetails.subtotal';
  const totalValueText = 'projectDetauls.billingSummary.workStatementDetails.total';

  useEffect(() => {
    const isSubtotalComponent = Boolean(dataMismatch?.subTotal && label.includes(subtotalValueText));
    const isTotalComponent = Boolean(dataMismatch?.total && label.includes(totalValueText));

    setShowError(isSubtotalComponent || isTotalComponent);
  }, [dataMismatch?.total, dataMismatch?.subTotal]);

  const getMoneyValue = () => {
    const mismatchSubTotalObj = dataMismatch?.subTotal;
    const mismatchTotalObj = dataMismatch?.total;
    const isSubtotalText = label.includes(subtotalValueText);

    if (showWorkStatementSnapshot) {
      return isSubtotalText ? mismatchSubTotalObj?.current : mismatchTotalObj?.current;
    }

    return isSubtotalText ? mismatchSubTotalObj?.snapshot : mismatchTotalObj?.snapshot;
  };

  return (
    <Stack sx={styles.costSummaryWrapper}>
      <Typography variant={variant} sx={styles.costSummaryLabel}>
        {isClientView && projectDetails.name + ' '}
        <Translation id={label} />
        <Typography variant={variant} component="span" sx={styles.costSummaryValue(showError)}>
          {formatPrice(amount)}
        </Typography>
        <Tooltip
          sx={hideInPdf}
          title={formatMessage(
            { id: AppMessages['projectDetauls.billingSummary.dataMismatch.error.tooltip'] },
            {
              date: snapshotCreateDate,
              snapshot: formatPrice(getMoneyValue() as number),
            },
          )}
          arrow
        >
          <Box sx={hideInPdf}>{showError && <ErrorFilledIcon color="error" />}</Box>
        </Tooltip>
      </Typography>
    </Stack>
  );
};
