import { useCallback, useMemo } from 'react';

import Axios, { AxiosRequestConfig } from 'axios';
import { MutationFunction, QueryFunction } from '@tanstack/react-query';

import { stringifyQueryParams } from 'api/utils/queryString';
import { ApiClientContextValue } from 'context/apiClient/apiClientContext/ApiClientContext.types';
import { MutationFn } from 'hooks/useMutation/useMutation.types';
import { InfiniteQueryFn, UseInfiniteQueryOptions } from 'hooks/useInfiniteQuery/useInfiniteQuery.types';
import { QueryFn, UseQueryOptions } from 'hooks/useQuery/useQuery.types';
import { authStorage } from '../../context/auth/authStorage/AuthStorage';
import { useAuth } from '../useAuth/useAuth';

import { responseFailureInterceptor, responseSuccessInterceptor } from './interceptors/responseInterceptors';
import { requestSuccessInterceptor } from './interceptors/requestInterceptors';

interface Token {
  accessToken: string;
  expires: number;
  refreshToken: string;
}

export const useAxiosStrategy = (): ApiClientContextValue => {
  const { logout } = useAuth();
  const client = useMemo(() => {
    const axios = Axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/api`,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    axios.interceptors.request.use((config) =>
      requestSuccessInterceptor(config, {
        onRefreshTokenSuccess: (token: Token) => {
          authStorage.expires = token.expires;
          authStorage.accessToken = token.accessToken;
        },
        onRefreshTokenError: logout,
      }),
    );
    axios.interceptors.response.use(responseSuccessInterceptor, (error) =>
      responseFailureInterceptor(error, {
        onRefreshTokenSuccess: (token: Token) => {
          authStorage.expires = token.expires;
          authStorage.accessToken = token.accessToken;
        },
        onRefreshTokenError: logout,
      }),
    );

    return axios;
  }, []);

  const queryFn = useCallback(
    <TArgs, TQueryFnData, TData, TError>(
        query: QueryFn<TArgs, TQueryFnData, TData, TError>,
        options?: UseQueryOptions<TArgs, TQueryFnData, TError, TData>,
      ): QueryFunction<TQueryFnData> =>
      async ({ signal }) => {
        const { endpoint, args } = query(options?.args);
        const queryString = stringifyQueryParams(args);

        const { data } = await client.get(`${endpoint}${queryString}`, { signal });
        return data;
      },
    [client],
  );

  const infiniteQueryFn = useCallback(
    <TArgs, TParams, TResponse, TError>(
        query: InfiniteQueryFn<TArgs, TParams, TResponse>,
        options?: UseInfiniteQueryOptions<TArgs, TParams, TError, TResponse>,
      ): QueryFunction<TParams> =>
      async ({ pageParam = options?.startPage ?? 0, signal }) => {
        const { endpoint, args } = query(options?.args);
        const cursorKey = options?.cursorKey;

        const queryParams = {
          ...(cursorKey ? { [cursorKey]: pageParam } : {}),
          ...(args || {}),
        };
        const queryString = stringifyQueryParams(queryParams);

        // End format of url is e.g /users?page=2&sortOrder=ASC&limit=5&sortBy=name
        const { data } = await client.get(`/${endpoint}${queryString}`, { signal });
        return data;
      },
    [client],
  );

  const mutationFn = useCallback(
    <TParams = unknown, TData = unknown>(mutation: MutationFn<TParams, TData>): MutationFunction<TData, TParams> =>
      async (variables) => {
        const { endpoint, params, method } = mutation(variables);

        const axiosConfig: AxiosRequestConfig = {
          url: endpoint,
          data: params,
          method: method || 'POST',
        };

        const { data } = await client.request(axiosConfig);
        return data;
      },
    [client],
  );

  return {
    queryFn,
    infiniteQueryFn,
    mutationFn,
  };
};
