import MuiSkeleton from '@mui/material/Skeleton';

import type { SkeletonProps } from './Skeleton.types';
import * as styles from './Skeleton.styles';

export const Skeleton = (props: SkeletonProps) => {
  const { circular, width, height, sx } = props;
  return (
    <MuiSkeleton
      variant={circular ? 'circular' : 'rectangular'}
      width={width}
      height={height}
      sx={styles.skeleton(circular, sx)}
    />
  );
};
