import { Styles } from 'styles/theme';

export const gridsWrapper: Styles = {
  minHeight: 256,
  maxHeight: 410,
  display: 'grid',
  gridTemplateRows: '1fr',
  gridTemplateColumns: '1fr',
  paddingY: 1,
  overflowX: 'auto',
  '@media screen and (max-width: 2000px)': {
    paddingX: 3,
  },
};
