import Joi from 'joi';

import { AppMessages } from 'i18n/messages';
import { rateCardSchema } from 'shared/project/schema/rateCard.schema';
import { intl } from 'context/locale/intl/Intl';
import { MAX_SUPPORTED_DATE, formatDate } from 'utils/dateUtils';

const editableRateCardSchema = () =>
  rateCardSchema().append({
    isDraft: Joi.boolean().optional().strip(),
  });

export const updateRateCardModalSchema = (minDate: Date) => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object({
    appliesFrom: Joi.date()
      .required()
      .min(minDate)
      .max(MAX_SUPPORTED_DATE)
      .messages({
        'date.base': formatMessage(
          {
            id: AppMessages['validation.date'],
          },
          {
            label: formatMessage({
              id: AppMessages['projectDetails.accordions.details.rateCard.updateRateCard.applyFrom.title'],
            }),
          },
        ),
        'date.min': formatMessage(
          {
            id: AppMessages['validation.greater'],
          },
          {
            label: formatMessage({
              id: AppMessages['projectDetails.accordions.details.rateCard.updateRateCard.applyFrom.title'],
            }),
            limit: formatDate(minDate),
          },
        ),
        'date.max': formatMessage(
          {
            id: AppMessages['validation.max'],
          },
          {
            label: formatMessage({
              id: AppMessages['projectDetails.accordions.details.rateCard.updateRateCard.applyFrom.title'],
            }),
            limit: formatDate(MAX_SUPPORTED_DATE),
          },
        ),
      }),
    rateCards: Joi.array().min(1).items(editableRateCardSchema()),
  });
};
