import { Box } from '@mui/material';

import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';
import { Skeleton } from 'ui/skeleton/Skeleton';

import * as styles from './SplitValue.styles';

export const SplitValueSkeleton = ({ showSeniorityHeaders }: { showSeniorityHeaders?: boolean }) => (
  <>
    {showSeniorityHeaders && (
      <Box sx={styles.labelContainer}>
        <Box flex={1} key="junior" sx={{ display: 'flex', justifyContent: 'center' }}>
          <TextSkeleton width={36} variant="caption" />
        </Box>
        <Box flex={1} key="mid" sx={{ display: 'flex', justifyContent: 'center' }}>
          <TextSkeleton width={24} variant="caption" />
        </Box>
        <Box flex={1} key="senior" sx={{ display: 'flex', justifyContent: 'center' }}>
          <TextSkeleton width={40} variant="caption" />
        </Box>
      </Box>
    )}
    <Box sx={styles.valuesContainer}>
      <Skeleton width="100%" height={32} />
    </Box>
  </>
);
