import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useMutation } from 'hooks/useMutation/useMutation';
import { projectsKeys, assignmentsKeys } from 'utils/queryKeys';
import { getApiError } from 'api/utils/getApiError';
import { planProjectExtensionMutation } from 'api/actions/planProjectExtension/planProjectExtensionAction';

export const usePlanProjectExtension = (projectId: string) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(['planProjectExtensionMutation'], planProjectExtensionMutation(projectId), {
    onSuccess: () => {
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(assignmentsKeys.assignmentsList);
    },
    onError: (error) => {
      enqueueSnackbar(getApiError(error));
    },
  });
};
