import { GetInvoicesParams, GetInvoicesResponse } from 'api/actions/getInvoices/getInvoices.types';
import { getInvoices } from 'api/actions/getInvoices/getInvoices';
import { getInvoicesQueryKey } from 'utils/queryKeys';
import { useAuth } from 'hooks/useAuth/useAuth';
import { getNextPageParam } from 'api/utils/getNextPageParam';
import { useInfiniteQuery } from 'hooks/useInfiniteQuery/useInfiniteQuery';

export const useInvoices = (params?: GetInvoicesParams) => {
  const { isAuthenticated } = useAuth();
  const queryKey = getInvoicesQueryKey(params);

  return useInfiniteQuery<GetInvoicesParams, GetInvoicesResponse>(queryKey, getInvoices, {
    cursorKey: 'page',
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    startPage: 1,
    args: {
      ...params,
    },
    getNextPageParam: getNextPageParam,
  });
};
