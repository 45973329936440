import { Styles } from 'styles/theme';

export const dashboardGridWrapper: Styles = {
  paddingX: 6,
  paddingY: 1,
};

export const listWrapper: Styles = {
  overflow: 'auto',
};

export const divider: Styles = {
  marginY: 3,
};

export const cardWrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 'calc(100vh - 24px)',
  overflow: 'hidden',
  height: '100vh',
};
