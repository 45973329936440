import { joiResolver } from '@hookform/resolvers/joi';

import { Select } from 'ui/form/select/Select';
import { useLocale } from 'hooks/useLocale/useLocale';
import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';
import { useAddBillingCycle } from 'hooks/useAddBillingCycle/useAddBillingCycle';
import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { useProjectTimesheet } from 'hooks/useProjectTimesheet/useProjectTimesheet';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useLastGeneratedBillingCycle } from 'hooks/useLastGeneratedBillingCycle/useLastGeneratedBillingCycle';
import { addMonthToDate } from 'utils/dateUtils';

import {
  AddBillingCycleFormData,
  AddBillingCycleFormProps,
  addBillingCycleFormSchema,
} from './AddBillingCycleForm.types';
import { defaultValues, getOptions } from './AddBillingCycleForm.utils';
import * as styles from './AddBillingCycleForm.styles';

export const AddBillingCycleForm = ({ onClose }: AddBillingCycleFormProps) => {
  const { t } = useLocale();
  const {
    projectDetails: { id: projectId },
    endDate: projectEndDate,
  } = useProjectDetails();
  const { timesheetMonthStartDate } = useProjectTimesheet();
  const { data: billingCycle } = useLastGeneratedBillingCycle(projectId);
  const { mutate, isLoading } = useAddBillingCycle();

  const {
    handleSubmit,
    formState: { isValid },
    control,
  } = useIntlForm<AddBillingCycleFormData>({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(addBillingCycleFormSchema),
  });

  const onSubmit = (data: AddBillingCycleFormData) => {
    mutate(
      { ...data, projectId },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <DialogInnerWrapper
      title={t('projectDetails.timesheet.empty.addTimesheet')}
      loading={isLoading}
      onClose={onClose}
      submitText={t('projectDetails.timesheet.empty.addTimesheet.submitButton')}
      onSubmit={handleSubmit(onSubmit)}
      isFormValid={isValid}
    >
      <Select
        sx={styles.select}
        name="amount"
        label={t('projectDetails.timesheet.empty.addTimesheet.inputLabel')}
        control={control}
        options={getOptions(
          billingCycle?.startDate ? addMonthToDate(new Date(billingCycle.startDate)) : timesheetMonthStartDate,
          projectEndDate,
        )}
      />
    </DialogInnerWrapper>
  );
};
