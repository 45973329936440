import { SxProps } from '@mui/material';

import { WORK_STATEMENT_DETAILS_WIDTH } from '../ProjectBillingSummary.styles';

const MARGIN = 24;

export const wrapper: SxProps = {
  width: WORK_STATEMENT_DETAILS_WIDTH - MARGIN,
  padding: 0,
};

export const sticky: SxProps = {
  position: 'sticky',
  top: 20,
  bottom: 20,
  height: `calc(100vh - ${MARGIN * 2}px)`,
  overflowY: 'auto',
  overflowX: 'hidden',
  borderRadius: 2,

  ['@media print']: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    height: 'auto',
    width: '100%',
    overflowY: 'visible',
    overflowX: 'visible',

    '& .MuiDivider-root': {
      display: 'none',
    },
  },
};

export const detailsWrapper: SxProps = {
  '& > *': {
    mt: 4,
  },
  '& > div > div': {
    px: 0,
  },

  ['@media print']: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    gap: 3,
  },
};

export const actions: SxProps = {
  mt: 1.5,

  '& .MuiButtonBase-root': {
    mr: 1,
  },

  [`@media print`]: {
    display: 'none',
  },
};

export const workStatementTitle: SxProps = {
  ['@media print']: {
    fontSize: 16,
    color: 'text.secondary',
    width: '100%',
    textAlign: 'left',
  },
};

export const exitButton = (isReport: boolean): SxProps => ({
  ...(isReport && {
    '& .MuiStack-root .MuiButtonBase-root': {
      display: 'none',
    },

    '& .MuiStack-root': {
      padding: 0,
    },
  }),
});
