import { Route, Navigate, Outlet, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { Login } from 'app/login/Login';
import { Logout } from 'app/logout/Logout';
import { Forbidden } from 'app/forbidden/Forbidden';
import { Dashboard } from 'app/dashboard/Dashboard';
import { CreateProject } from 'app/createProject/CreateProject';
import { Project } from 'app/project/Project';
import { ProjectDetailsContextController } from 'context/projectDetails/projectDetailsContextController/ProjectDetailsContextController';
import { Projects } from 'app/projects/Projects';
import { AppProviders } from 'providers/AppProviders';
import { Layout } from 'app/layout/Layout';
import { Needs } from 'app/needs/Needs';
import { NeedsFiltersContextController } from 'context/needsFilters/needsFiltersContextController/NeedsFiltersContextController';
import { Person } from 'app/person/Person';
import { PersonDataContextController } from 'context/personData/personDataContextController/PersonDataContextController';
import { People } from 'app/people/People';
import { PeopleFiltersContextController } from 'context/peopleFilters/peopleFiltersContextController/PeopleFiltersContextController';
import { ProjectsFiltersContextController } from 'context/projectsFilters/projectsFiltersContextController/ProjectsFiltersContextController';
import { ProjectOverviewContainer } from 'app/project/projectOverview/ProjectOverviewContainer';
import { AssignmentHistory } from 'app/project/assignmentHistory/AssignmentHistory';
import { TeamDetailsContainer } from 'app/project/teamDetails/TeamDetailsContainer';
import { ProjectTimesheet } from 'app/project/projectTimesheet/ProjectTimesheet';
import { ProjectReport } from 'app/project/projectReport/ProjectReport';
import { ProjectTimesheetContextController } from 'context/projectTimesheet/projectTimesheetContextController/ProjectTimesheetContextController';
import { ProjectTimesheetDataContextController } from 'context/projectTimesheet/projectTimesheetDataContextController/ProjectTimesheetDataContextController';
import { ProjectBillingSummary } from 'app/project/projectBillingSummary/ProjectBillingSummary';
import { ProjectBillingSummaryContextController } from 'context/projectBillingSummary/projectBillingSummaryContextController/ProjectBillingSummaryContextController';
import { ProjectBillingSummaryDataContextController } from 'context/projectBillingSummary/projectBillingSummaryDataContextController/ProjectBillingSummaryDataContextController';
import { AssignmentInfoContextController } from 'context/assignmentInfo/assignmentInfoContextController/AssignmentInfoContextController';
import { AssignmentsFiltersContextController } from 'context/assignmentsFilters/assignmentsFiltersContextController/AssignmentsFiltersContextController';
import { ProjectDiscrepancyContextController } from 'context/projectDiscrepancy/projectDiscrepancyContextController/ProjectDiscrepancyContextController';
import { ClientsFiltersContextController } from '../context/clientsFilters/clientsFiltersContextController/ClientsFiltersContextController';
import { ClientContextController } from 'context/clients/clientContextController/ClientContextController';
import { Clients } from '../app/clients/Clients';
import { Client } from 'app/client/Client';
import { ClientDetails } from 'app/client/clientDetails/ClientDetails';
import { ClientProjects } from 'app/client/clientProjects/ClientProjects';
import { ClientTimesheets } from 'app/client/clientTimesheets/ClientTimesheets';
import { ClientBillingSummary } from 'app/client/clientBillingSummary/ClientBillingSummary';
import { ClientInvoices } from 'app/client/clientInvoices/ClientInvoices';
import { ProjectInvoices } from 'app/project/projectInvoices/ProjectInvoices';

import { AppRoute, ClientDetailsRoute, ProjectDetailsRoute } from './AppRoute.enum';
import { AuthorizedRoute } from './AuthorizedRoute';
import { RouteErrorBoundary } from './RouteErrorBoundary';

export const AppRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <AppProviders>
          <Layout />
        </AppProviders>
      }
    >
      <Route path="/" element={<Outlet />} errorElement={<RouteErrorBoundary />}>
        <Route path="/" element={<AuthorizedRoute />}>
          <Route path={AppRoute.dashboard} element={<Dashboard />} />
          <Route path={AppRoute.createProject} element={<CreateProject />} />
          <Route
            path={AppRoute.projects}
            element={
              <ProjectsFiltersContextController withParams storageKey="projects-filters" storageType="session">
                <Projects />
              </ProjectsFiltersContextController>
            }
          />
          <Route
            path={AppRoute.people}
            element={
              <PeopleFiltersContextController withParams storageKey="people-filters" storageType="session">
                <People />
              </PeopleFiltersContextController>
            }
          />
          <Route
            path={AppRoute.clients}
            element={
              <ClientsFiltersContextController withParams storageKey="clients-filters" storageType="session">
                <ClientContextController>
                  <Clients />
                </ClientContextController>
              </ClientsFiltersContextController>
            }
          />
          <Route
            path={AppRoute.needs}
            element={
              <NeedsFiltersContextController withParams storageKey="needs-filters" storageType="session">
                <Needs />
              </NeedsFiltersContextController>
            }
          />
          <Route
            path={AppRoute.personDetails}
            element={
              <PersonDataContextController>
                <Person />
              </PersonDataContextController>
            }
          />
          <Route
            path={AppRoute.projectDetails}
            element={
              <ProjectDetailsContextController>
                <ProjectDiscrepancyContextController>
                  <Project />
                </ProjectDiscrepancyContextController>
              </ProjectDetailsContextController>
            }
          >
            <Route path={ProjectDetailsRoute.overview} element={<ProjectOverviewContainer />} />
            <Route path={ProjectDetailsRoute.team} element={<TeamDetailsContainer />} />
            <Route path={ProjectDetailsRoute.history} element={<AssignmentHistory />} />
            <Route path={ProjectDetailsRoute.invoice} element={<ProjectInvoices />} />
            <Route
              path={ProjectDetailsRoute.timesheet}
              element={
                <AssignmentsFiltersContextController>
                  <AssignmentInfoContextController>
                    <ProjectTimesheetContextController>
                      <ProjectTimesheetDataContextController>
                        <ProjectTimesheet />
                      </ProjectTimesheetDataContextController>
                    </ProjectTimesheetContextController>
                  </AssignmentInfoContextController>
                </AssignmentsFiltersContextController>
              }
            />
            <Route
              path={ProjectDetailsRoute.summary}
              element={
                <ProjectBillingSummaryContextController>
                  <ProjectBillingSummaryDataContextController>
                    <ProjectBillingSummary />
                  </ProjectBillingSummaryDataContextController>
                </ProjectBillingSummaryContextController>
              }
            />
            <Route
              path={ProjectDetailsRoute.report}
              element={
                <ProjectTimesheetContextController>
                  <ProjectTimesheetDataContextController>
                    <ProjectBillingSummaryContextController>
                      <ProjectBillingSummaryDataContextController>
                        <ProjectReport />
                      </ProjectBillingSummaryDataContextController>
                    </ProjectBillingSummaryContextController>
                  </ProjectTimesheetDataContextController>
                </ProjectTimesheetContextController>
              }
            />
            <Route path="*" element={<Navigate to={ProjectDetailsRoute.overview} replace />} />
          </Route>
          <Route
            path={AppRoute.clientDetails}
            element={
              <ClientsFiltersContextController withParams storageKey="clients-filters" storageType="session">
                <ClientContextController>
                  <Client />
                </ClientContextController>
              </ClientsFiltersContextController>
            }
          >
            <Route path={ClientDetailsRoute.overview} element={<ClientDetails />} />
            <Route path={ClientDetailsRoute.invoices} element={<ClientInvoices />} />
            <Route
              path={ClientDetailsRoute.projects}
              element={
                <ProjectsFiltersContextController>
                  <ClientProjects />
                </ProjectsFiltersContextController>
              }
            />
            <Route path={ClientDetailsRoute.timesheets} element={<ClientTimesheets />} />
            <Route path={ClientDetailsRoute.summary} element={<ClientBillingSummary />} />
            <Route path="*" element={<Navigate to={ClientDetailsRoute.overview} replace />} />
          </Route>
          <Route path="/" element={<Navigate to={AppRoute.dashboard} replace />} />
          <Route path="*" element={<Navigate to={AppRoute.dashboard} replace />} />
        </Route>
        <Route path={AppRoute.login} element={<Login />} />
        <Route path={AppRoute.logout} element={<Logout />} />
        <Route path={AppRoute.forbidden} element={<Forbidden />} />
      </Route>
    </Route>,
  ),
);
