import { Box } from '@mui/material';

import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';
import { Skeleton } from 'ui/skeleton/Skeleton';

import * as styles from './AvailabilityFilters.styles';

export const AvailabilityFiltersSkeleton = () => (
  <Box sx={styles.availabilityFiltersWrapper}>
    <Box display="flex" alignItems="center" justifyContent="space-between" height={40}>
      <Box sx={styles.toggleButtonGroupWithGap}>
        <TextSkeleton width={128} variant="body1" sx={{ mr: 2 }} />
        <TextSkeleton width={208} variant="body2" sx={{ mr: 2 }} />
      </Box>

      <Skeleton width={134} height={34} />
    </Box>
  </Box>
);
