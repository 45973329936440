import { Styles } from 'styles/theme';

export const headerWrapper: Styles = {
  paddingY: 5,
};

export const menuListIcon: Styles = {
  '.MuiListItemIcon-root': {
    path: {
      fill: (theme) => theme.palette.error.main,
    },
  },
};

export const dropdownMenu: Styles = {
  marginTop: 1,

  '.MuiMenu-paper': {
    minWidth: '245px',
    borderRadius: 2,
  },
};
