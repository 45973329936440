import { Box } from '@mui/material';

import { NeedsGroupedByProjectProps } from 'shared/needs/Needs.types';
import { NeedCard } from 'app/need/card/NeedCard';

import * as styles from './NeedsList.styles';

export const NeedsList = ({ needs, onNeedClick }: NeedsGroupedByProjectProps) => (
  <Box sx={styles.needsList}>
    {needs.map((need) => (
      <NeedCard key={need.id} need={need} onNeedClick={() => onNeedClick(need.id)} />
    ))}
  </Box>
);
