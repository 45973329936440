import { Box } from '@mui/material';
import { Typography } from 'lux/components';
import { CalendarUpdateIcon } from 'lux/icons';

import { formatDate, DATE_WITH_HOURS_FORMAT } from 'utils/dateUtils';
import { Translation } from 'ui/translation/Translation';

import { LastUpdateProps } from './LastUpdate.types';
import * as styles from './LastUpdate.styles';

/* updatedAt.replace('Z', '') part is solution/workaround for problem with +1 hour on dev and stage environments because frontend code is deployed on bucket located in Ireland which is +1. If you have better solution feel free to update existing one (just remember to check it on dev/stage env) */

export const LastUpdate = ({ username = '', updatedAt = '' }: LastUpdateProps) => (
  <Box sx={styles.container}>
    <CalendarUpdateIcon sx={styles.icon} />
    <Typography variant="body2">{username}</Typography>
    <Typography color="text.secondary" variant="body2">
      <Translation id="projectDetails.timesheet.lastUpdate" />
    </Typography>
    <Typography variant="body2">{formatDate(new Date(updatedAt.replace('Z', '')), DATE_WITH_HOURS_FORMAT)}</Typography>
  </Box>
);
