import { useSnackbar } from 'notistack';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { deleteChargeMutation } from 'api/actions/deleteCharge/deleteChargeActions';

export const useDeleteCharge = (workStatementId: string) => {
  const { formatMessage } = useLocale();
  const { invalidateBillingSummaryCache } = useProjectBillingSummaryData();
  const { enqueueSnackbar } = useSnackbar();

  return useMutationDomainError(['deleteChargeMutation'], deleteChargeMutation(workStatementId), {
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.deleteCharge.success'] }));
      invalidateBillingSummaryCache();
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.deleteCharge.error'] }),
  });
};
