import { Filters } from 'hooks/useFilters/useFilters.types';

export enum ProjectNeedsSortingField {
  startDate = 'start_date',
  role = 'role',
}

export type ProjectNeedsFilters = {
  sort: string;
};

export type ProjectNeedsFiltersValue = {
  filters: ProjectNeedsFilters;
} & Filters<ProjectNeedsFilters>;
