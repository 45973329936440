import { Box, Stack } from '@mui/material';
import { Avatar, Badge, Typography } from 'lux/components';

import { PersonStatusBadge } from 'ui/personStatusBadge/PersonStatusBadge';
import { formatDate } from 'utils/dateUtils';
import { getPersonFullRole } from 'utils/stringUtils';
import { Translation } from 'ui/translation/Translation';
import { RejectionSource } from 'config/data/rejection/RejectionSource.enum';
import { EmojiBadges } from 'ui/emojiBadges/EmojiBadges';
import { PersonTooltip } from 'shared/people/personTooltip/PersonTooltip';
import { OverflowTooltipTypography } from 'ui/overflowTooltipTypography/OverflowTooltipTypography';
import { ProposalStatus } from 'config/data/need/ProposalStatus.enum';

import * as styles from './EmployeeCardHeader.styles';
import type { EmployeeCardHeaderProps } from './EmployeeCardHeader.types';

export const EmployeeCardHeader = ({
  employee,
  status,
  availabilityStatus,
  rejectionDate,
  rejectionSource,
  disabled,
}: EmployeeCardHeaderProps) => (
  <Box data-cy="employee-card_header-wrapper" sx={styles.employeeCardHeaderWrapper}>
    <Box sx={styles.employeeCardHeaderWrapper} {...(disabled && { className: 'disabled' })}>
      <PersonTooltip employeeId={employee.employeeId}>
        <Box>
          <Avatar image={employee.picture} sx={styles.avatar} alt={`${employee.firstName} ${employee.lastName}`} />
        </Box>
      </PersonTooltip>
      <Box sx={styles.employeeCardHeaderProfileDetails}>
        <Stack direction="row" gap={1} alignItems="center">
          <OverflowTooltipTypography text={`${employee.firstName} ${employee.lastName}`} variant="caption" />
          <EmojiBadges
            employmentType={employee.employmentType}
            proposalCount={employee.proposalCount}
            isLeader={employee.isLeader}
            employedTo={employee.employedTo}
            absences={employee.absences}
          />
        </Stack>
        <Typography color="text.secondary" variant="caption">
          {getPersonFullRole(employee.seniority, employee.departmentCode)}
        </Typography>
      </Box>
    </Box>

    <Box sx={styles.employeeCardHeaderWrapper}>
      {rejectionDate && (
        <Typography sx={styles.employeeCardHeaderDate} variant="caption">
          <Translation
            id="card.need.body.rejected.rejectionDate"
            values={{ rejectionDate: formatDate(rejectionDate) }}
          />
        </Typography>
      )}
      {status === ProposalStatus.approved && (
        <Badge isStatic color="yellow" badgeContent={<Translation id="card.need.badge.approved" />} />
      )}
      {status === ProposalStatus.rejected && (
        <Badge
          isStatic
          color="red"
          badgeContent={
            <>
              <Translation id="card.need.badge.rejected" />{' '}
              {rejectionSource && (
                <Translation
                  id={
                    rejectionSource === RejectionSource.assignment
                      ? 'card.need.badge.rejected.source.assignment'
                      : 'card.need.badge.rejected.source.need'
                  }
                />
              )}
            </>
          }
        />
      )}
      {availabilityStatus && <PersonStatusBadge status={availabilityStatus} availableFte={employee.availableFte} />}
    </Box>
  </Box>
);
