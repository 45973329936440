import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { NeedsGroupingOptions } from 'shared/needs/needsFilters/groupByProject/GroupByProject.types';
import { NeedsFiltersContext } from '../needsFiltersContext/NeedsFiltersContext';
import { useFilters } from 'hooks/useFilters/useFilters';
import { NeedsSortingField } from '../needsFiltersContext/NeedsFiltersContext.types';
import { generateSortFilter } from 'utils/filters/filters';
import { filterEmptyQueryParams, stringifyQueryParams } from 'api/utils/queryString';
import { useOnRedirectHandler } from 'hooks/useOnRedirectHandler/useOnRedirectHandler';
import type { NeedsFilters } from '../needsFiltersContext/NeedsFiltersContext.types';

import type { NeedsFiltersContextControllerProps } from './NeedsFiltersContextController.types';

export const needsSortingOptions = generateSortFilter(NeedsSortingField);
const defaultFilters = {
  owner: '',
  search: '',
  groupBy: NeedsGroupingOptions.needs,
  sort: needsSortingOptions.startDateASC,
  seniorities: [],
  roles: [],
  fte: '',
  availabilityFrom: '',
  availabilityTo: '',
  type: [],
};

export const NeedsFiltersContextController = ({
  initialFilters,
  children,
  withParams,
  storageKey,
  storageType,
}: NeedsFiltersContextControllerProps) => {
  const [, setParamsFilters] = useSearchParams();

  const contextValues = useFilters<NeedsFilters>(
    {
      ...defaultFilters,
      ...initialFilters,
    },
    { withParams, storageKey, storageType },
  );

  useEffect(() => {
    if (withParams) {
      setParamsFilters(stringifyQueryParams(filterEmptyQueryParams(contextValues.filters, defaultFilters)), {
        replace: true,
      });
    }
  }, [withParams]);

  useOnRedirectHandler(() => {
    contextValues.setStorageFiltersState({
      search: undefined,
    });
  });

  return <NeedsFiltersContext.Provider value={contextValues}>{children}</NeedsFiltersContext.Provider>;
};
