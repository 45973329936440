import { RateCard } from 'api/actions/getProject/getProject.types';
import { areTimeIntervalsOverlapping, parseDate } from 'utils/dateUtils';
import { NeededRole, NeededRoleSeniority } from '../RateCardDetails.types';
import { Seniority } from 'api/types/Seniority.enum';

const getInterval = (startString: string, endString?: string): Interval => ({
  start: parseDate(startString),
  end: parseDate(endString || '9999-12-31'),
});

export const getMissingSeniorities = (
  rateCard: RateCard,
  role: string,
  neededRoles: NeededRole[],
): NeededRoleSeniority[] => {
  const needsForCurrentRole = neededRoles.find((neededRole) => neededRole.role === role);
  if (!needsForCurrentRole) {
    return [];
  }

  const missingSeniorities: NeededRoleSeniority[] = [];
  const definedSeniorities = rateCard.items[role].map(({ seniority }) => seniority);

  const rateCardInterval = getInterval(rateCard.appliesFrom, rateCard.appliesUntil);

  for (const requiredSeniority of needsForCurrentRole.seniorities) {
    if (!!definedSeniorities.length && requiredSeniority.value === Seniority.withoutLevel) {
      continue;
    }

    if (!definedSeniorities.includes(requiredSeniority.value)) {
      const requiredSeniorityInterval = getInterval(requiredSeniority.startDate, requiredSeniority.endDate);
      if (areTimeIntervalsOverlapping(rateCardInterval, requiredSeniorityInterval)) {
        missingSeniorities.push(requiredSeniority);
      }
    }
  }

  return missingSeniorities;
};
