import { AxiosError } from 'axios';

import { DefaultPeopleFilters } from 'context/peopleFilters/peopleFiltersContext/PeopleFiltersContext.types';
import { Seniority } from 'api/types/Seniority.enum';
import { AssignPersonArguments } from '../../api/actions/assignPerson/assignPersonActions.types';

export type AssignWithoutNeedHandler = (
  payload: AssignPersonArguments,
  options: { onSuccess: VoidFunction; onError: (error: AxiosError) => void },
  assignedEmployee: { employeeName: string; projectName: string },
) => void;

export type AssignWithoutNeedModalProps = {
  projectId: string;
  isOpen: boolean;
  onClose: VoidFunction;
  onAssign: AssignWithoutNeedHandler;
  isAssignmentInProgress?: boolean;
  defaultFilters?: DefaultPeopleFilters;
};

export enum AssignWithoutNeedModalStep {
  assignForm = 'assignForm',
}

export type AssignWithoutNeedFormValues = {
  role: string;
  seniority: Seniority | null;
  fte: string;
  startDate: Date | null;
  customFte: string;
  endDate: Date | null;
  temporary: boolean;
  customRateEnabled: boolean;
  customRate: string;
};
