import { FormControlLabel } from '@mui/material';
import { Checkbox } from 'lux/components';

import { Filter } from 'ui/filter/Filter';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { filtersData } from 'config/data/people/filters';

export const EmploymentFilter = () => {
  const { formatMessage } = useLocale();
  const {
    filters: { employmentType },
    toggleFilter,
  } = usePeopleFilters();

  const handleFilterChange = (value: string) => {
    toggleFilter('employmentType', value);
  };

  return (
    <Filter variant="accordion" label={formatMessage({ id: AppMessages['people.filters.employmentType.label'] })}>
      {filtersData['employmentType'].map(({ labelTranslation, value }) => (
        <FormControlLabel
          key={value}
          label={formatMessage({ id: AppMessages[labelTranslation] })}
          control={
            <Checkbox
              value={value}
              checked={employmentType.includes(value)}
              onChange={() => handleFilterChange(value)}
            />
          }
        />
      ))}
    </Filter>
  );
};
