import { Accordion, AccordionSummary, Box, Stack } from '@mui/material';
import { ChevronDownIcon } from 'lux/icons';

import { composeStyles } from 'utils/composeStyles';
import { Skeleton } from 'ui/skeleton/Skeleton';
import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

import * as styles from './AssignmentAccordion.styles';

export const AssignmentAccordionSkeleton = () => (
  <Accordion expanded={false} sx={styles.accordion}>
    <AccordionSummary
      expandIcon={<ChevronDownIcon sx={styles.expandIcon} />}
      aria-controls="panel1bh-content"
      id="panel1bh-header"
      sx={styles.accordionSummary}
    >
      <Box sx={composeStyles([styles.accordionHeaderWrapper, styles.headerContent])}>
        <Box sx={styles.accordionHeaderWrapper}>
          <Skeleton circular width={40} height={40} sx={styles.accordionItemHeader} />

          <Box sx={styles.accordionHeaderProfileDetails}>
            <Stack direction="row" alignItems="center" gap={1}>
              <TextSkeleton width={90} variant="caption" />
            </Stack>
            <TextSkeleton width={120} variant="caption" />
          </Box>
        </Box>

        <Box sx={composeStyles([styles.accordionHeaderWrapper, styles.badgesContainer])}>
          <Skeleton width={60} height={24} />
        </Box>
      </Box>
    </AccordionSummary>
  </Accordion>
);
