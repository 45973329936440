import { formatDate } from 'utils/dateUtils';

export const formatPeriodDate = (date: string, period: string) => {
  const isPeriodWeekly = period === 'weekly';
  const newDate = new Date(date);
  const formattedDate = formatDate(newDate, isPeriodWeekly ? 'dd.MM' : 'MMMM');

  return isPeriodWeekly ? `from ${formattedDate}` : formattedDate;
};

const calculateAvailability = (available: number, total: number) => (available / total) * 100;

export const getBackgroundColor = (available: number, total: number) => {
  if (available === 0) {
    return 'surface.paper.default';
  }

  const result = calculateAvailability(available, total);

  if (result < 10) {
    return 'accent.orange.pastel';
  } else if (result >= 10 && result < 20) {
    return 'accent.orange.main';
  } else if (result >= 20 && result < 30) {
    return 'warning.main';
  } else {
    return 'error.main';
  }
};

export const getTextColor = (available: number, total: number) => {
  const result = calculateAvailability(available, total);

  if (result >= 30) {
    return 'text.white';
  }
  return '';
};
