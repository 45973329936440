import { Switch as LuxSwitch } from 'lux/components';
import { Controller, FieldValues } from 'react-hook-form';

import { SwitchProps } from './Switch.types';

export const Switch = <T extends FieldValues>(props: SwitchProps<T>) => {
  const { name, control, size, label, required, isDisabled, ...rest } = props;

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <LuxSwitch
            checked={value}
            label={label}
            size={size}
            onChange={onChange}
            value={value}
            required={required}
            disabled={isDisabled}
            {...rest}
          />
        )}
      />
    </>
  );
};
