import { getSpecializationsQuery } from 'api/actions/getSpecializations/getSpecializations';
import {
  GetSpecializationsParams,
  GetSpecializationsResponse,
} from 'api/actions/getSpecializations/getSpecializations.types';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { dictionariesKeys } from 'utils/queryKeys';

export const useSpecializations = () => {
  const { isAuthenticated } = useAuth();

  return useQuery<GetSpecializationsParams, GetSpecializationsResponse>(
    dictionariesKeys.specializationsList,
    getSpecializationsQuery,
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
    },
  );
};
