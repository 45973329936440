export const getErrorPaths = (obj: unknown, currentPath?: string): string[] => {
  const paths: string[] = [];

  if (!obj || typeof obj !== 'object') {
    return [];
  }

  if (obj.hasOwnProperty('message') && currentPath) {
    paths.push(currentPath);
  }

  Object.entries(obj).forEach(([key, value]) => {
    const path = currentPath ? `${currentPath}.${key}` : key;
    paths.push(...getErrorPaths(value, path));
  });

  return paths;
};
