import { LogLevel } from '@azure/msal-browser';
/*  eslint-disable no-console */

const MSAL_LOG_LEVEL_TO_LOGGER_FN_MAP = {
  [LogLevel.Error]: console.error,
  [LogLevel.Info]: console.info,
  [LogLevel.Trace]: console.trace,
  [LogLevel.Verbose]: console.log,
  [LogLevel.Warning]: console.warn,
};

export const MSAL_CONFIGURATION = {
  auth: {
    clientId: process.env.REACT_APP_ACTIVE_DIRECTORY_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_ACTIVE_DIRECTORY_AUTHORITY ?? '',
    redirectUri: process.env.REACT_APP_ACTIVE_DIRECTORY_REDIRECT_URI ?? '',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }

        if (process.env.REACT_APP_ENVIRONMENT === 'development') {
          MSAL_LOG_LEVEL_TO_LOGGER_FN_MAP[level](message);
        }
      },
    },
  },
};
