import { Box } from '@mui/material';
import { AddIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useTitle } from 'hooks/useTitle/useTitle';
import { AppMessages } from 'i18n/messages';
import { useDialog } from 'hooks/useDialog/useDialog';
import { PageHeader } from 'ui/pageHeader/PageHeader';
import { RefreshDWHDataButtons } from '../../ui/refreshDWHDataButtons/RefreshDWHDataButtons';

import { PeopleGrid } from './peopleGrid/PeopleGrid';
import { AddUncertainModal } from './addUncertainModal/AddUncertainModal';
import * as styles from './People.styles';

export const People = () => {
  const { formatMessage } = useLocale();
  const { isOpen, setOpen, setClose } = useDialog();
  useTitle(formatMessage({ id: AppMessages['title.people'] }));

  return (
    <Box sx={styles.peoplePageWrapper}>
      <PageHeader
        title={formatMessage({ id: AppMessages['people.header.title'] })}
        actionButton={{
          actionTitle: formatMessage({ id: AppMessages['people.addUncertain.actionBtn.text'] }),
          icon: <AddIcon />,
          variant: 'outlined',
          onClick: () => setOpen(),
        }}
      />
      <PeopleGrid />
      <AddUncertainModal open={isOpen} onClose={setClose} />
      <RefreshDWHDataButtons />
    </Box>
  );
};
