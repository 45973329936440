import { FormControlLabel } from '@mui/material';
import { Switch } from 'lux/components';

import { Filter } from 'ui/filter/Filter';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';

export const NonDevRolesFilter = () => {
  const { formatMessage } = useLocale();
  const {
    filters: { nonDevRoles },
    toggleFilter,
  } = usePeopleFilters();

  const handleFilterChange = () => {
    toggleFilter('nonDevRoles', nonDevRoles === 'true' ? 'false' : 'true');
  };

  return (
    <Filter variant="accordion" label={formatMessage({ id: AppMessages['people.filters.nonDevRoles.label'] })}>
      <FormControlLabel
        key="nonDevRoles"
        label={formatMessage({ id: AppMessages['people.filters.nonDevRoles.value'] })}
        control={<Switch checked={nonDevRoles === 'true'} onChange={() => handleFilterChange()} />}
      />
    </Filter>
  );
};
