import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';

import { Box } from '@mui/material';

import { CardHeader } from './header/CardHeader';
import { CardFooter } from './footer/CardFooter';
import { CardProps } from './Card.types';
import * as styles from './Card.styles';

type CardComponent = ForwardRefExoticComponent<CardProps & RefAttributes<HTMLElement>> & {
  Header: typeof CardHeader;
  Footer: typeof CardFooter;
};

export const Card = forwardRef<HTMLElement, CardProps>(({ children, onClick = () => {}, sx = [], ...props }, ref) => (
  <Box ref={ref} sx={[...(Array.isArray(sx) ? sx : [sx]), styles.cardWrapper]} onClick={onClick} {...props}>
    {children}
  </Box>
)) as CardComponent;

Card.Header = CardHeader;
Card.Footer = CardFooter;
