import { useNeedsFilters } from 'hooks/useNeedsFilters/useNeedsFilters';
import { FteFilter as SharedFteFilter } from 'shared/filters/fteFilter/FteFilter';

import { FteFilterProps } from './FteFilter.types';

export const FteFilter = ({ variant }: FteFilterProps) => {
  const {
    filters: { fte },
    toggleFilter,
    clearFilter,
  } = useNeedsFilters();

  const handleFilterChange = (value: string) => {
    toggleFilter('fte', value);
  };

  const clearFte = () => {
    clearFilter('fte');
  };

  return <SharedFteFilter variant={variant} fte={fte} onFilterChange={handleFilterChange} onClear={clearFte} />;
};
