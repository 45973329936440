import { Stepper as LuxStepper, Step } from 'lux/components';

import { steps } from 'config/data/create-project/steps';
import { useFormNavigation } from '../useFormNavigation/useFormNavigation';

import * as styles from './Stepper.styles';

export const Stepper = () => {
  const { activeStep, isStepInvalid, shouldDisableStepperStep, handleStepperNavigation } = useFormNavigation();

  return (
    <LuxStepper sx={styles.stepper} activeStep={activeStep} orientation="vertical" largeGap>
      {steps().map((step, index) => (
        <Step
          label={step.label}
          active={index === activeStep}
          disabled={shouldDisableStepperStep(index)}
          key={step.label}
          completed={!isStepInvalid(index)}
          onClick={() => handleStepperNavigation(index)}
          data-cy={`stepper_step-${step.id}`}
        />
      ))}
    </LuxStepper>
  );
};
