import { Grid } from 'lux/components';
import { Typography } from '@mui/material';

import { Translation } from 'ui/translation/Translation';

import { DetailsGridItemProps } from './DetailsGridItem.types';

export const DetailsGridItem = ({ children, labelTranslationId, span = 1, sx }: DetailsGridItemProps) => (
  <Grid item xs={span} sx={sx}>
    {labelTranslationId && (
      <Typography variant="caption" color="text.secondary" component="p" fontWeight={600}>
        <Translation id={labelTranslationId} />
      </Typography>
    )}
    {children}
  </Grid>
);
