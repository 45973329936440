import { Styles } from 'lux/theme';

export const costSummaryWrapper: Styles = {
  paddingY: 1,
  flexDirection: 'row',
  justifyContent: 'flex-end',
  fontSize: 20,
  pageBreakInside: 'avoid',

  ['@media print']: {
    mt: 0.5,
  },
};

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const costSummaryLabel: Styles = {
  ...flexCenter,
  fontWeight: 400,
  gap: 1,
  color: 'text.secondary',
  path: {
    fill: (theme) => theme.palette.error.main,
  },

  '& .MuiBox-root': {
    ...flexCenter,
  },
  ['@media print']: {
    fontSize: 16,
  },
};

export const costSummaryValue = (showError: boolean): Styles => ({
  color: showError ? 'text.error' : 'text.primary',

  ['@media print']: {
    fontSize: 16,
    color: 'text.primary',
  },
});
