import { useEffect, useState } from 'react';

import { useLocale } from '../useLocale/useLocale';
import { suggestedRoles, suggestedRolesValues } from '../../config/data/need/suggestedRoles';
import { NeededRole } from '../../app/project/projectOverview/rateCardDetails/RateCardDetails.types';
import { RateCard as RateCardType, RateCard } from '../../api/actions/getProject/getProject.types';

export type UseSuggestedRolesProps = {
  rateCard: RateCard;
  neededRoles: NeededRole[];
};

const getMissingRoles = (neededRoles: NeededRole[], rateCard: RateCardType): string[] => {
  if (!neededRoles.length) {
    return [];
  }

  const definedRoles = Object.keys(rateCard.items);

  return neededRoles.filter(({ role }) => !definedRoles.includes(role)).map(({ role }) => role);
};

export const useSuggestedRoles = ({ rateCard, neededRoles }: UseSuggestedRolesProps) => {
  const [chips, setChips] = useState<string[]>([]);
  const { t } = useLocale();

  const isSuggestedRole = (role: string) => suggestedRolesValues.includes(role.toLowerCase());
  const translatedSuggestedRolesLabel = suggestedRoles.map((role) => t(role.label));

  useEffect(() => {
    const missingRoles = getMissingRoles(neededRoles, rateCard);
    const rateCardItems = Object.keys(rateCard.items).map((role) => role.toLowerCase());
    const uniqueRoles = [...new Set([...missingRoles, ...translatedSuggestedRolesLabel])];
    const roleValuesToRender = uniqueRoles
      .filter((role) => !rateCardItems.includes(role.toLowerCase()))
      .map((role) => {
        const suggestedRole = suggestedRoles.find((suggestedRole) => suggestedRole.value === role);

        return suggestedRole ? t(suggestedRole.label) : role;
      });

    setChips(roleValuesToRender);
  }, []);

  return {
    chips,
    setChips,
    isSuggestedRole,
  };
};
