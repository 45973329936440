import type { Styles } from 'styles/theme';

export const detailsTab: Styles = {
  backgroundColor: 'surface.paper.default',
  border: (theme) => `1px solid ${theme.palette.border.default}`,
  borderRadius: 5,
  p: 3,
};

export const detailsItem: Styles = {
  marginBottom: 3,
};

export const seeDetailsLink: Styles = {
  '&.MuiButton-root': {
    padding: 0,
    minWidth: 'unset',
    minHeight: 'unset',
    alignSelf: 'flex-start',

    '&:hover': {
      backgroundColor: 'unset',
      textDecoration: 'underline',
    },
  },
};
