import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';
import { AdditionalValuesModal } from '../additionalValuesModal/AdditionalValuesModal';

import { AddChargeModalProps } from './AddChargeModal.types';
import { AddChargeContent } from './addChargeContent/AddChargeContent';

export const AddChargeModal = ({ open, onClose, workStatementId, billingCycleId, invoiceId }: AddChargeModalProps) => {
  useDialogBlockScroll(open);

  return (
    <AdditionalValuesModal open={open} onClose={onClose}>
      <AddChargeContent
        onClose={onClose}
        workStatementId={workStatementId}
        billingCycleId={billingCycleId}
        invoiceId={invoiceId}
      />
    </AdditionalValuesModal>
  );
};
