import Joi from 'joi';

import { ZERO_UNIX_DATE, MAX_SUPPORTED_DATE, formatDate } from 'utils/dateUtils';
import { Currency } from 'api/types/Currency.enum';
import { AppMessages } from 'i18n/messages';
import { rateCardSchema } from 'shared/project/schema/rateCard.schema';
import { intl } from 'context/locale/intl/Intl';

export const clientSchema = () => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object({
    client: Joi.object({
      id: Joi.string().allow('').optional(),
      name: Joi.string()
        .required()
        .messages({
          'any.required': formatMessage(
            { id: AppMessages['validation.required'] },
            { label: formatMessage({ id: AppMessages['createProject.client.form.name'] }) },
          ),
          'string.empty': formatMessage(
            { id: AppMessages['validation.empty'] },
            { label: formatMessage({ id: AppMessages['createProject.client.form.name'] }) },
          ),
        }),
      industries: Joi.array()
        .items(Joi.string().required())
        .messages({
          'array.includesRequiredUnknowns': formatMessage(
            { id: AppMessages['validation.empty'] },
            { label: formatMessage({ id: AppMessages['createProject.client.form.industry'] }) },
          ),
        }),
      country: Joi.string()
        .required()
        .messages({
          'any.required': formatMessage(
            { id: AppMessages['validation.required'] },
            { label: formatMessage({ id: AppMessages['createProject.client.form.country'] }) },
          ),
          'string.empty': formatMessage(
            { id: AppMessages['validation.empty'] },
            { label: formatMessage({ id: AppMessages['createProject.client.form.country'] }) },
          ),
        }),
      accountNumber: Joi.string().allow('').optional(),
    }),
    clientId: Joi.string().guid().allow(''),
  }).or('client', 'clientId');
};

export const projectSchema = () => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object({
    project: Joi.object({
      name: Joi.string()
        .required()
        .messages({
          'string.empty': formatMessage(
            { id: AppMessages['validation.empty'] },
            { label: formatMessage({ id: AppMessages['createProject.project.form.name'] }) },
          ),
        }),
      description: Joi.string()
        .required()
        .messages({
          'string.empty': formatMessage(
            { id: AppMessages['validation.empty'] },
            { label: formatMessage({ id: AppMessages['createProject.project.form.description'] }) },
          ),
        }),
      startDate: Joi.date()
        .greater(ZERO_UNIX_DATE)
        .max(MAX_SUPPORTED_DATE)
        .required()
        .messages({
          'date.base': formatMessage(
            { id: AppMessages['validation.date'] },
            { label: formatMessage({ id: AppMessages['createProject.project.form.start'] }) },
          ),
          'date.greater': formatMessage(
            {
              id: 'validation.greater',
            },
            {
              limit: formatDate(ZERO_UNIX_DATE),
              label: formatMessage({ id: AppMessages['createProject.project.form.start'] }),
            },
          ),
          'date.max': formatMessage(
            { id: 'validation.max' },
            {
              limit: formatDate(MAX_SUPPORTED_DATE),
              label: formatMessage({ id: AppMessages['createProject.project.form.start'] }),
            },
          ),
        }),
      endDate: Joi.date()
        .max(MAX_SUPPORTED_DATE)
        .min(Joi.ref('startDate'))
        .allow(null)
        .optional()
        .messages({
          'date.base': formatMessage(
            { id: AppMessages['validation.date'] },
            { label: formatMessage({ id: AppMessages['createProject.project.form.end'] }) },
          ),
          'date.min': formatMessage({ id: 'createProject.schema.project.endDate.error.min' }),
          'date.max': formatMessage(
            { id: 'validation.max' },
            {
              limit: formatDate(MAX_SUPPORTED_DATE),
              label: formatMessage({ id: AppMessages['createProject.project.form.end'] }),
            },
          ),
        }),
      attributes: Joi.array()
        .items(Joi.string().required())
        .messages({
          'array.includesRequiredUnknowns': formatMessage(
            { id: AppMessages['validation.empty'] },
            { label: formatMessage({ id: AppMessages['createProject.project.form.attributes'] }) },
          ),
        }),
      tags: Joi.array()
        .items(Joi.string().required())
        .messages({
          'array.includesRequiredUnknowns': formatMessage(
            { id: AppMessages['validation.empty'] },
            { label: formatMessage({ id: AppMessages['createProject.project.form.tags'] }) },
          ),
        }),
      currency: Joi.string()
        .valid(...Object.values(Currency))
        .required()
        .label(formatMessage({ id: AppMessages['createProject.project.form.currency'] })),
      timeDiff: Joi.string().allow('').optional(),
      budget: Joi.number().allow('').optional(),
      contactPerson: Joi.string().allow('').optional(),
      slackChannelId: Joi.string().allow('').optional(),
      codename: Joi.string()
        .allow('')
        .trim()
        .max(15)
        .optional()
        .messages({
          'string.max': formatMessage(
            { id: AppMessages['validation.tooLong'] },
            { label: formatMessage({ id: AppMessages['createProject.project.form.codename'] }), limit: 15 },
          ),
        }),
    }),
  });
};

export const rateCardsSchema = () =>
  Joi.object({
    rateCards: Joi.array().items(rateCardSchema()),
  });

export const formSchema = () => Joi.object().concat(clientSchema()).concat(projectSchema()).concat(rateCardsSchema());
