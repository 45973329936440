import { Box } from '@mui/material';

import { ProjectCardSkeleton } from 'app/projects/projectsGrid/projectsList/projectCard/ProjectCard.skeleton';

import * as styles from './ClientProjects.styles';

export const ClientProjectsSkeleton = () => (
  <Box sx={styles.centeredContainer}>
    {Array(6)
      .fill(null)
      .map((_, index) => (
        <Box sx={styles.projectCardContainer} key={index}>
          <ProjectCardSkeleton />
        </Box>
      ))}
  </Box>
);
