import { Styles } from 'styles/theme';

export const tableCellWrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
};

export const typographyHead: Styles = {
  fontWeight: 500,
  marginLeft: 1.5,
  whiteSpace: 'nowrap',
};

export const splitValuesRow: Styles = {
  '&.MuiTableRow-root': {
    verticalAlign: 'bottom',
  },
};

export const row: Styles = {
  '&.MuiTableRow-root:hover': {
    background: 'transparent',
  },
};

export const departmentColumn: Styles = {
  '&&': { width: 150 },
};
