import { NeedDto } from 'api/types/Need.types';
import { Person } from 'api/actions/getPerson/getPerson.types';
import { ProposalStatus } from 'config/data/need/ProposalStatus.enum';
import { parseDate } from 'utils/dateUtils';

import { NeedsGroupedDefault } from './Needs.types';

export const groupNeedsByProject = (needs: NeedDto[]) =>
  Object.values(
    needs.reduce<{ [key: string]: NeedsGroupedDefault }>((needs, need) => {
      if (!needs[need.project.id]) {
        needs[need.project.id] = {
          id: need.project.id,
          name: need.project.name,
          stage: need.project.stage,
          startDate: parseDate(need.project.startDate),
          endDate: need.project.endDate ? parseDate(need.project.endDate) : undefined,
          needs: [],
        };
      }
      needs[need.project.id].needs.push(need);

      return needs;
    }, {}),
  );

export const mapNeedsPeople = (need: NeedDto): { proposed: Person[]; approved: Person[] } => {
  const initial = {
    proposed: [],
    approved: [],
  };

  if (!need.proposals) {
    return initial;
  }

  return need.proposals.reduce<{ proposed: Person[]; approved: Person[] }>((people, proposal) => {
    if (proposal.status === ProposalStatus.approved) {
      people.approved.push(proposal.person);
    } else if (proposal.status === ProposalStatus.pending) {
      people.proposed.push(proposal.person);
    }

    return people;
  }, initial);
};
