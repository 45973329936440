import { SxProps } from '@mui/material';

import {
  centerOfMarkerBorderLineWidth,
  halfOfMarkerBorderTriangleWidth,
  markerBorderLineWidth,
  markerBorderTriangleWidth,
  nonStartDayCellWidth,
  startDayCellWidth,
} from 'utils/timesheetProjectStartDayIndicator';

export const headerCell = (isCurrentDay: boolean, isProjectStartDayMarker: boolean): SxProps => ({
  'p:first-of-type': {
    color: isCurrentDay ? 'text.white' : 'primary.main',
    fontWeight: 600,
    fontSize: 12,
  },
  'p:last-of-type': {
    fontWeight: 500,
    fontSize: 14,
  },

  ...(isProjectStartDayMarker && {
    borderLeft: `${markerBorderLineWidth}px solid orange`,
    position: 'relative',
    width: startDayCellWidth,
  }),
});

export const projectStartDayMarker = (startDay: number | null, daysInMonth: number): SxProps => ({
  ...(!!startDay && {
    position: 'relative',

    '&::after': {
      position: 'absolute',
      right:
        // here we are styling the triangle indicator in the center of border line - it STARTS rendering in the RIGHT TOP CORNER
        nonStartDayCellWidth * (daysInMonth - startDay) +
        startDayCellWidth -
        halfOfMarkerBorderTriangleWidth -
        centerOfMarkerBorderLineWidth,
      top: 0,
      content: '""',
      display: 'block',
      borderRight: `solid ${halfOfMarkerBorderTriangleWidth}px transparent`,
      ...(startDay !== 1 && { borderLeft: `solid ${halfOfMarkerBorderTriangleWidth}px transparent` }),
      borderTop: `solid ${markerBorderTriangleWidth}px orange`,
    },
  }),
});
