import { Alert } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useTitle } from 'hooks/useTitle/useTitle';

export const Forbidden = () => {
  const { formatMessage } = useLocale();

  useTitle(formatMessage({ id: AppMessages['title.forbidden'] }));

  return (
    <div className="forbidden">
      <Alert severity="error">You do not have permission.</Alert>
    </div>
  );
};
