import { Dialog } from '@mui/material';

import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';

import { AssignOrProposeAnywayModalProps } from './AssignOrProposeAnywayModal.types';
import { AssignOrProposeAnywayContent } from './assignOrProposeAnywayContent/AssignOrProposeAnywayContent';

export const AssignOrProposeAnywayModal = ({ open, ...props }: AssignOrProposeAnywayModalProps) => {
  useDialogBlockScroll(open);

  return (
    <Dialog open={open} maxWidth="sm" onClose={props.onClose}>
      <AssignOrProposeAnywayContent {...props} />
    </Dialog>
  );
};
