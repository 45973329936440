import { Controller, FieldValues } from 'react-hook-form';
import { DateTimePicker, TextField } from 'lux/components';

import { DEFAULT_DATE_FORMAT } from 'utils/dateUtils';

import * as styles from './DatePicker.styles';
import { DatePickerProps } from './DatePicker.types';

export const DatePicker = <T extends FieldValues>(props: DatePickerProps<T>) => {
  const {
    name,
    disablePast,
    minDate,
    maxDate,
    control,
    label,
    required,
    isDisabled,
    fullWidth,
    size,
    autoFocus,
    disableUntilDate,
    disableFuture,
    defaultCalendarMonth,
    ...rest
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <DateTimePicker
          label={label}
          value={value}
          disabled={isDisabled}
          onChange={onChange}
          disablePast={disablePast}
          defaultCalendarMonth={defaultCalendarMonth}
          disableFuture={disableFuture}
          minDate={minDate}
          maxDate={maxDate}
          views={['day']}
          inputFormat={DEFAULT_DATE_FORMAT}
          shouldDisableDate={(date) =>
            disableUntilDate ? new Date(date).getTime() < new Date(disableUntilDate).getTime() : false
          }
          renderInput={(params) => (
            <TextField
              fullWidth={fullWidth}
              {...params}
              size={size}
              sx={styles.textfield}
              autoFocus={autoFocus}
              InputLabelProps={{ required }}
              error={!!error}
              helperText={!!error?.message ? error.message : ''}
              {...rest}
            />
          )}
        />
      )}
    />
  );
};
