import { Styles } from 'lux/theme';

export const stack: Styles = {
  pl: 1,
  marginBottom: 1,
  alignItems: 'flex-start',
  width: '100%',
};

export const badge: Styles = {
  '& .MuiBadge-badge': {
    overflow: 'hidden',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '336px',
  },
};
