import { useState } from 'react';

import { Table } from 'lux/components';
import { Stack } from '@mui/material';

import { CollapseRow } from '../../collapseRow/CollapseRow';
import { CollapseButton } from 'ui/collapseButton/CollapseButton';
import { useBillingSummaryPrice } from 'hooks/useBillingSummaryPrice/useBillingSummaryPrice';
import { HourDetailsRow } from '../../hourDetailsRow/HourDetailsRow';
import { roundHours } from 'utils/roundHours';
import { useBillingSummaryMemberHours } from 'hooks/useBillingSummaryMemberHours/useBillingSummaryMemberHours';

import * as styles from './WorkStatementRole.styles';
import { WorkStatementRoleProps } from './WorkStatementRole.types';

export const WorkStatementRole = ({ role: { name, hours, total } }: WorkStatementRoleProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { formatPrice } = useBillingSummaryPrice();

  const roleHours = roundHours(hours.reduce((acc, { amount }) => acc + amount, 0));
  const { rateText } = useBillingSummaryMemberHours(hours, true);

  return (
    <>
      <Table.Row>
        <Table.Cell>
          <Stack direction="row" alignItems="center" gap={1}>
            {name}{' '}
            <CollapseButton
              sx={styles.collapseButton}
              isExpanded={isExpanded}
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </Stack>
        </Table.Cell>
        <Table.Cell>{roleHours}</Table.Cell>
        <Table.Cell>{rateText}</Table.Cell>
        <Table.Cell>{formatPrice(total)}</Table.Cell>
      </Table.Row>
      <CollapseRow isExpanded={isExpanded}>
        {hours && hours.map((hour, idx) => <HourDetailsRow hour={hour} key={idx} hasPadding={false} />)}
      </CollapseRow>
    </>
  );
};
