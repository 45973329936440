import { useMemo, useRef } from 'react';

import { Box, ClickAwayListener } from '@mui/material';

import { UnsavedChangesDialogContext } from '../UnsavedChangesDialogContext/UnsavedChangesDialogContext';
import { useDialog } from 'hooks/useDialog/useDialog';
import { UnsavedChangesDialogContextValue } from '../UnsavedChangesDialogContext/UnsavedChangesDialogContext.types';

import type {
  DialogClickAwayListenerProps,
  THandleOnCloseArgs,
  UnsavedChangesDialogContextControllerProps,
} from './UnsavedChangesDialogContextController.types';

export const UnsavedChangesDialogContextController = ({ children }: UnsavedChangesDialogContextControllerProps) => {
  const onCloseRef = useRef<VoidFunction>();

  const {
    isOpen: isUnsavedChangesDialogOpen,
    setOpen: setIsUnsavedChangesDialogOpen,
    setClose: setUnsavedChangesDialogClose,
  } = useDialog();

  const handleOnCloseDialog = ({ defaultValues, form, onClose }: THandleOnCloseArgs) => {
    const isDirty = JSON.stringify(defaultValues) !== JSON.stringify(form.getValues());

    if (isDirty) {
      onCloseRef.current = onClose;
      return setIsUnsavedChangesDialogOpen();
    }

    return onClose();
  };

  const DialogClickAwayListenerWrapper = useMemo(
    () => (props: DialogClickAwayListenerProps) => {
      const { children, defaultValues, form, onClose } = props;
      return (
        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={() => handleOnCloseDialog({ defaultValues, form, onClose })}
        >
          <Box>{children}</Box>
        </ClickAwayListener>
      );
    },
    [children],
  );

  const contextValues: UnsavedChangesDialogContextValue = useMemo(
    () => ({
      isUnsavedChangesDialogOpen,
      setUnsavedChangesDialogClose,
      handleOnCloseDialog,
      onCloseRef,
      DialogClickAwayListenerWrapper,
    }),
    [
      isUnsavedChangesDialogOpen,
      setUnsavedChangesDialogClose,
      handleOnCloseDialog,
      onCloseRef,
      DialogClickAwayListenerWrapper,
    ],
  );

  return <UnsavedChangesDialogContext.Provider value={contextValues}>{children}</UnsavedChangesDialogContext.Provider>;
};
