import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

import { getActiveAccount } from './msalAccountUtils';

export const redirectToLogout = async (instance: IPublicClientApplication, account?: AccountInfo | null) => {
  const logoutRequest = {
    account: account ?? getActiveAccount(instance),
    postLogoutRedirectUri: `${window.location.origin}/login?logout_success=true`,
  };

  await instance.logoutRedirect(logoutRequest);
};
