import { Box } from '@mui/material';
import { Typography } from 'lux/components';
import { ErrorIcon } from 'lux/icons';

import { useAssignments } from 'hooks/useAssignments/useAssignments';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll/useInfiniteScroll';
import { mapAssignmentData } from 'shared/assignments/Assignments.functions';
import { useAssignmentsFilters } from 'hooks/useAssignmentsFilters/useAssignmentsFilters';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AddButton } from '../addButton/AddButton';

import { TabAssignedPanelSkeleton } from './TabAssignedPanel.skeleton';
import { TabAssignedPanelProps } from './TabAssigned.types';
import { AssignmentsTable } from './assignmentsTable/AssignmentsTable';
import { AssignmentsEmptyState } from './assignmentsEmptyState/AssignmentsEmptyState';

export const TabAssignedPanel = ({ projectId, allowProjectEdition }: TabAssignedPanelProps) => {
  const { formatMessage } = useLocale();
  const { filters } = useAssignmentsFilters();

  const { ref, queryMethods } = useInfiniteScroll(
    useAssignments({
      project: projectId,
      ...filters,
    }),
  );
  const { data, isLoading } = queryMethods;

  const assignments = mapAssignmentData(data?.pages);
  const outdatedAssignmentsCount = data?.pages[0].outdatedAssignmentsCount;

  if (isLoading) {
    return <TabAssignedPanelSkeleton />;
  }

  if (!assignments?.length) {
    return <AssignmentsEmptyState projectId={projectId} allowProjectEdition={allowProjectEdition} />;
  }

  return (
    <>
      {Boolean(outdatedAssignmentsCount) && (
        <Typography component="p" variant="badgeLabel" display="flex" alignItems="center" gap={1} marginBottom={3}>
          <ErrorIcon fontSize="small" color="error" />
          {formatMessage(
            { id: AppMessages['projectDetails.teamDetails.assigned.outdatedAssignments'] },
            {
              count: outdatedAssignmentsCount,
            },
          )}
        </Typography>
      )}
      <AssignmentsTable ref={ref} assignments={assignments} />
      <Box marginTop={1}>{allowProjectEdition && <AddButton projectId={projectId} />}</Box>
    </>
  );
};
