import { QueryFn } from 'hooks/useQuery/useQuery.types';

import { GetBillingCycleDetailsPayload, GetBillingCycleDetailsResponse } from './getBillingCycleDetails.types';

export const getBillingCycleDetails: (
  projectId: string,
) => QueryFn<GetBillingCycleDetailsPayload, GetBillingCycleDetailsResponse> = (projectId: string) => (args) => ({
  endpoint: `/billing-cycles/project/${projectId}`,
  args,
});
