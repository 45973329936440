import { Box } from '@mui/material';
import { AddUserIcon } from 'lux/icons';
import { Tooltip } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { EmploymentType } from 'api/types/EmploymentType.enum';
import { SquareIconButton } from 'ui/squareIconButton/SquareIconButton';

import { AssignPersonButtonProps } from './AssignPersonButton.types';
import * as styles from './AssignPersonButton.styles';

export const AssignPersonButton = ({
  person,
  onClick,
  tooltipPlacement = 'top',
  tooltipMaxWidth,
  customDisable,
  customMessage,
}: AssignPersonButtonProps) => {
  const { formatMessage } = useLocale();

  const disableAssignPerson = customDisable || (!person.rate && person.employmentType === EmploymentType.LTSH);

  const message =
    customDisable && customMessage ? customMessage : formatMessage({ id: AppMessages['people.assignPerson.tooltip'] });

  return (
    <Tooltip
      title={disableAssignPerson ? message : ''}
      placement={tooltipPlacement}
      arrow
      disableFocusListener
      componentsProps={{
        tooltip: {
          sx: styles.tooltip(tooltipMaxWidth),
        },
      }}
    >
      <Box display="flex" justifyContent="center">
        <SquareIconButton
          onClick={() => {
            onClick(person);
          }}
          size="small"
          variant="contained"
          disabled={disableAssignPerson}
        >
          <AddUserIcon />
        </SquareIconButton>
      </Box>
    </Tooltip>
  );
};
