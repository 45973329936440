import { SnackbarProvider as BaseSnackbarProvider } from 'notistack';

import { Snackbar } from 'ui/snackbar/Snackbar';

import { SnackbarProviderProps } from './SnackbarProvider.types';

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => (
  <BaseSnackbarProvider
    maxSnack={3}
    variant="default"
    autoHideDuration={3000}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    preventDuplicate
    Components={{
      default: Snackbar,
      success: Snackbar,
      error: Snackbar,
      info: Snackbar,
      warning: Snackbar,
    }}
  >
    {children}
  </BaseSnackbarProvider>
);
