import { useState } from 'react';

import { Accordion } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ClientDetailsProps } from 'app/project/projectOverview/clientDetails/ClientDetails.types';
import { useUpdateClient } from 'hooks/useUpdateClient/useUpdateClient';
import { useIndustries } from 'hooks/useIndustries/useIndustries';
import { useUpdateProject } from 'hooks/useUpdateProject/useUpdateProject';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useAllClients } from 'hooks/useAllClients/useAllClients';

import { ClientDetailsRows } from './clientDetailsRows/ClientDetailsRows';

export const ClientDetails = (props: ClientDetailsProps) => {
  const [expanded, setExpanded] = useState(true);
  const { clientData, projectId, disabled } = props;

  const { formatMessage } = useLocale();
  const { data: industries = [] } = useIndustries();
  const { projectDetails } = useProjectDetails();
  const { data } = useAllClients();
  const { mutateAsync, isLoading } = useUpdateClient([projectId], clientData.id);
  const { mutateAsync: muteAsyncProject, isLoading: isProjectUpdateLoading } = useUpdateProject(projectId);
  const clients = data?.clients ?? [];

  const otherClientNames = clients.map((client) => client.name).filter((clientName) => clientName !== clientData.name);

  const updateClientName = async (name: string) => {
    const selectedClient = clients.find((client) => client.name === name);
    muteAsyncProject({ name: projectDetails.name, clientId: selectedClient?.id });
  };

  return (
    <Accordion
      summary={formatMessage({ id: AppMessages['projectDetails.accordions.details.client'] })}
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
    >
      <Accordion.Details>
        <ClientDetailsRows
          clientData={clientData}
          industries={industries}
          isLoading={isLoading || isProjectUpdateLoading}
          mutateAsync={mutateAsync}
          otherClientNames={otherClientNames}
          updateClientName={updateClientName}
          disabled={disabled}
          isClientPage={false}
        />
      </Accordion.Details>
    </Accordion>
  );
};
