import { Styles } from 'styles/theme';
import { DRAWER_OPENED_WIDTH, DRAWER_WIDTH } from 'app/project/sidebar/Sidebar.styles';

const PADDING_LEFT = Math.floor(DRAWER_WIDTH / 8);
const PADDING_LEFT_PINNED = Math.floor(DRAWER_OPENED_WIDTH / 8);

export const clientViewWrapper: (sidebarPinned: boolean) => Styles = (sidebarPinned) => ({
  display: 'flex',
  paddingRight: 6,
  paddingLeft: sidebarPinned ? PADDING_LEFT_PINNED + 6 : PADDING_LEFT + 6,
  paddingBottom: 0,
  backgroundColor: 'surface.paper.default',
  minHeight: (theme) => `calc(100vh - ${theme.topBarHeight * 8}px)`,
});

export const clientAccordionCaption: Styles = {
  color: 'text.secondary',
};

export const accordionDetailsItem: Styles = {
  padding: 1,
};

export const externalLinkIcon = (isDisabled?: boolean): Styles => ({
  color: isDisabled ? 'text.disabled' : 'primary.main',
});
