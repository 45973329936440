import { Button, Typography } from 'lux/components';
import { Box } from '@mui/system';

import { Translation as TranslationKey } from 'i18n/messages';
import TimesheetImg from 'assets/images/empty-timesheet.svg';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { Translation } from 'ui/translation/Translation';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useProjectTimesheet } from 'hooks/useProjectTimesheet/useProjectTimesheet';
import { isDateAfter, isDateBefore } from 'utils/dateUtils';

import * as styles from './TimesheetEmptyState.styles';
import { AddBillingCycleModal } from './addBillingCycleModal/AddBillingCycleModal';

export const TimesheetEmptyState = () => {
  const {
    projectDetails: { stage },
    startDate: projectStartDate,
    endDate: projectEndDate,
  } = useProjectDetails();
  const { timesheetMonthStartDate } = useProjectTimesheet();
  const isProjectBeforeStart = stage === ProjectStage.lead;
  const isProjectClosed = stage === ProjectStage.closed;
  const isProjectOngoing = stage === ProjectStage.ongoing || stage === ProjectStage.closing;

  const isDateBeforeProjectStart = isDateBefore(timesheetMonthStartDate, projectStartDate);
  const isDateAfterProjectEnd = projectEndDate && isDateAfter(timesheetMonthStartDate, projectEndDate);

  const emptyStateTextTranslation: TranslationKey = (() => {
    if (isProjectBeforeStart) {
      return 'projectDetails.timesheet.empty.lead';
    }

    if (isDateBeforeProjectStart) {
      return 'projectDetails.timesheet.empty.beforeStartDate';
    }

    if (isDateAfterProjectEnd) {
      return 'projectDetails.timesheet.empty.afterEndDate';
    }

    if (isProjectClosed) {
      return 'projectDetails.timesheet.empty.closed';
    }

    return 'projectDetails.timesheet.empty.noData';
  })();

  const { isOpen, setClose, setOpen } = useDialog();

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.imageWrapper}>
        <img src={TimesheetImg} />
      </Box>
      <Typography variant="h6" sx={styles.text}>
        <Translation id={emptyStateTextTranslation} />
      </Typography>
      {isProjectOngoing && !isDateBeforeProjectStart && !isDateAfterProjectEnd && (
        <>
          <Button size="small" onClick={setOpen}>
            <Translation id="projectDetails.timesheet.empty.addTimesheet" />
          </Button>
          <AddBillingCycleModal onClose={setClose} open={isOpen} />
        </>
      )}
    </Box>
  );
};
