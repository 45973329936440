import { useState } from 'react';

import { useParams } from 'react-router-dom';

import { useProject } from 'hooks/useProject/useProject';
import { areDatesEqual, getStartOfMonthDate, parseDate } from 'utils/dateUtils';
import {
  ProjectDiscrepancyData,
  ProjectDiscrepancyValue,
} from '../projectDiscrepancyContext/ProjectDiscrepancyContext.types';
import { ProjectDiscrepancyContext } from '../projectDiscrepancyContext/ProjectDiscrepancyContext';

import { ProjectDetailsControllerProps } from './ProjectDiscrepancyContextController.types';
import {
  getDiscrepancySessionData,
  removeDiscrepancyData,
  setDiscrepancySessionData,
} from './ProjectDiscrepancyContextController.utils';

export const ProjectDiscrepancyContextController = ({
  children,
  projectId: passedProjectId,
}: ProjectDetailsControllerProps) => {
  const { projectId } = useParams();
  const [discrepancyData, setDiscrepancyData] = useState(getDiscrepancySessionData());

  if (!projectId && !passedProjectId) {
    throw new Error('Project id is required');
  }

  const { data } = useProject((projectId || passedProjectId) as string);

  if (!data) {
    return null;
  }

  const handleDiscrepancyDataChange = (data: ProjectDiscrepancyData | null) => {
    if (!data) {
      removeDiscrepancyData();
      setDiscrepancyData(null);
      return;
    }

    setDiscrepancySessionData(data);
    setDiscrepancyData(data);
  };

  const hasBillingCycleDiscrepancy = (projectId: string, startDate: Date) => {
    if (!(discrepancyData && startDate)) {
      return false;
    }

    const isDiscrepancyInCurrentProject = discrepancyData.projectId === projectId;
    const isDiscrepancyInCurrentMonth = areDatesEqual(
      parseDate(discrepancyData.startDate),
      getStartOfMonthDate(startDate),
    );

    return isDiscrepancyInCurrentProject && isDiscrepancyInCurrentMonth;
  };

  const contextValues: ProjectDiscrepancyValue = {
    discrepancyData,
    setDiscrepancyData: handleDiscrepancyDataChange,
    hasBillingCycleDiscrepancy,
  };

  return <ProjectDiscrepancyContext.Provider value={contextValues}>{children}</ProjectDiscrepancyContext.Provider>;
};
