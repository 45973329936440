import { ProjectStage } from 'api/types/ProjectStage.enum';
import type { Filters } from 'hooks/useFilters/useFilters.types';

export enum ProjectsSortingField {
  name = 'name',
}

export type ProjectsFilters = {
  search: string;
  sort: string;
  type: string[];
  stage: ProjectStage[];
  team: string;
  role: string[];
  tag: string[];
  clientName: string[];
};

export type ProjectsFiltersValue = {
  filters: ProjectsFilters;
} & Filters<ProjectsFilters>;
