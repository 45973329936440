import { useEffect, useLayoutEffect } from 'react';

import { Box } from '@mui/material';

import { ProjectBillingSummary } from 'app/project/projectBillingSummary/ProjectBillingSummary';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { isDateInSameMonth } from 'utils/dateUtils';
import { WorkStatement } from 'api/types/BillingSummary.types';

import { ClientBillingSummaryAccordionItemProps } from './ClientBillingSummaryAccordionItem.types';
import * as styles from './ClientBillingSummaryAccordionItem.styles';

export const ClientBillingSummaryAccordionItem = (props: ClientBillingSummaryAccordionItemProps) => {
  const {
    bINumber,
    bINumberToShowNav,
    firstBIMonthStartDate,
    setBINumberToShowNav,
    setFirstBIMonthStartDate,
    groupByAllBI,
    setLoading,
    setGroupByAllBI,
    setTotalAmountOfBI,
    totalAmountOfBI,
  } = props;

  const { billingCycle, isLoadingBillingSummaryData, setSelectedBIDate, selectedBIDate, workStatements } =
    useProjectBillingSummaryData();

  const firstBINumer = 0;
  const isTheFirstBI = bINumber === firstBINumer;
  const isBillingInformationProjectsEmpty = bINumberToShowNav < 0 && bINumber !== firstBINumer;

  const updateTotalAmount = (workStatement: WorkStatement) => {
    const existingProject = totalAmountOfBI.find((project) => project.id === workStatement.id);

    if (existingProject) {
      existingProject.amount !== workStatement.total &&
        setTotalAmountOfBI((prevVal) =>
          prevVal.map((project) =>
            project.id === existingProject.id ? { ...project, amount: workStatement.total } : project,
          ),
        );
    } else {
      setTotalAmountOfBI((prevVal) => [...prevVal, { id: workStatement.id, amount: workStatement.total }]);
    }
  };

  // Below code is responsible for setting the correct month for all Billing cycles and show group by dropdown and nav bar for the first project in Billing Information

  useLayoutEffect(() => {
    const areDateNotEqual = !isDateInSameMonth(selectedBIDate, firstBIMonthStartDate);

    if (isTheFirstBI && areDateNotEqual) {
      setFirstBIMonthStartDate(selectedBIDate);
    }

    if (!isTheFirstBI && areDateNotEqual) {
      setSelectedBIDate(firstBIMonthStartDate);
    }
  }, [firstBIMonthStartDate, selectedBIDate]);

  useLayoutEffect(() => {
    const notSelectedFirstBI = -1;

    if (!billingCycle && bINumber === bINumberToShowNav) {
      setBINumberToShowNav(notSelectedFirstBI);
    }

    if (bINumberToShowNav < firstBINumer && billingCycle) {
      setBINumberToShowNav(bINumber);
    }

    if (billingCycle && bINumber === firstBINumer) {
      setBINumberToShowNav(firstBINumer);
    }
  }, [billingCycle, isLoadingBillingSummaryData]);

  // Below code is responsible for setting total amount from every project from certain Billing Cycle

  useEffect(() => {
    if (workStatements.length > 0) {
      const workStatement = workStatements[0];
      workStatement?.total && updateTotalAmount(workStatement);
    }
  }, [workStatements, selectedBIDate, firstBIMonthStartDate, totalAmountOfBI]);

  useEffect(() => {
    setLoading(bINumber, !!isLoadingBillingSummaryData);
  }, [isLoadingBillingSummaryData]);

  return (
    <Box sx={isBillingInformationProjectsEmpty ? styles.itemWrapper : {}}>
      <ProjectBillingSummary
        isClientView={true}
        bINumber={bINumber}
        groupByAllBI={groupByAllBI}
        setGroupByAllBI={setGroupByAllBI}
      />
    </Box>
  );
};
