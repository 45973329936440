import { useCallback, useState } from 'react';

import ReactCanvasConfetti from 'react-canvas-confetti';

import { ConfettiContext } from 'context/confetti/confettiContext/ConfettiContext';
import { getRandomNumberInRange } from 'utils/getRandomNumberInRange';
import type { ConfettiValue } from 'context/confetti/confettiContext/ConfettiContext.types';

import * as styles from './ConfettiContextController.styles';
import type { ConfettiControllerProps, FireConfettiOptions } from './ConfettiContextController.types';

export const ConfettiContextController = ({ children }: ConfettiControllerProps) => {
  const [fireOptions, setFireOptions] = useState({ origin: { x: 0.5, y: 0.5 }, velocity: 35 });

  const fire = () => {
    setFireOptions({
      origin: {
        x: getRandomNumberInRange(0.15, 0.85, 2),
        y: getRandomNumberInRange(0.2, 0.85, 2),
      },
      velocity: getRandomNumberInRange(20, 40),
    });
  };

  const fireConfetti = useCallback((options?: FireConfettiOptions) => {
    if (!options || options.times === 1) {
      fire();
    } else {
      for (let i = 0; i < options.times; i++) {
        setTimeout(fire, i * (options.delay ?? 150));
      }
    }
  }, []);

  const contextValues: ConfettiValue = {
    fireConfetti,
  };

  return (
    <ConfettiContext.Provider value={contextValues}>
      {children}
      <ReactCanvasConfetti
        style={styles.confettiCanvas}
        fire={fireOptions}
        origin={fireOptions.origin}
        particleCount={450}
        ticks={400}
        startVelocity={fireOptions.velocity}
        spread={360}
      />
    </ConfettiContext.Provider>
  );
};
