import { useContext } from 'react';

import { PeopleFiltersContext } from 'context/peopleFilters/peopleFiltersContext/PeopleFiltersContext';

export const usePeopleFilters = () => {
  const context = useContext(PeopleFiltersContext);

  if (context === undefined) {
    throw new Error('PeopleFiltersContext must be within PeopleFiltersContextProvider');
  }

  return context;
};
