import { Stack } from '@mui/material';

import { ProposalTabSkeleton } from './proposalTab/ProposalTab.skeleton';
import * as styles from './NeedCardFooter.styles';

export const NeedCardFooterSkeleton = () => (
  <Stack sx={styles.proposalTabsWrapper} className="proposalTabs">
    <ProposalTabSkeleton />
    <ProposalTabSkeleton />
    <ProposalTabSkeleton />
  </Stack>
);
