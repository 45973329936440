import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { editNeedMutation } from 'api/actions/editNeed/editNeedActions';
import { dictionariesKeys, needsKeys, projectsKeys } from 'utils/queryKeys';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';

export const useEditNeed = (projectId: string, needId: string) => {
  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutationDomainError(['editNeedMutation'], editNeedMutation(projectId, needId), {
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.editNeed.success'] }));
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(projectsKeys.singleProjectNeeds(projectId));
      queryClient.invalidateQueries(needsKeys.singleNeed(needId));
      queryClient.invalidateQueries(needsKeys.needsList);
      queryClient.invalidateQueries(dictionariesKeys.skillsList);
      queryClient.invalidateQueries(dictionariesKeys.specializationsList);
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.editNeed.error'] }),
  });
};
