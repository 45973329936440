import { Styles } from 'styles/theme';

export const employeeCardHeaderWrapper: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 1,

  '&.disabled': {
    opacity: 0.4,
  },
};

export const avatar: Styles = {
  width: 40,
  height: 40,
};

export const employeeCardHeaderProfileDetails: Styles = {
  display: 'flex',
  flexDirection: 'column',
};

export const employeeCardHeaderDate: Styles = {
  marginRight: 6,
};
