import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';
import { DepartmentFilter as SharedDepartmentFilter } from 'shared/filters/departmentFilter.tsx/DepartmentFilter';

import { RoleFilterProps } from './DepartmentFilter.types';

export const DepartmentFilter = ({ variant }: RoleFilterProps) => {
  const {
    filters: { departments },
    toggleFilter,
    clearFilter,
  } = usePeopleFilters();

  const handleFilterChange = (value: string) => {
    toggleFilter('departments', value);
  };

  const clearRoles = () => {
    clearFilter('departments');
  };

  return (
    <SharedDepartmentFilter
      variant={variant}
      departments={departments}
      dataCy="available-people_filter-department"
      onFilterClick={handleFilterChange}
      onClearClick={clearRoles}
    />
  );
};
