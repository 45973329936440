import { Tooltip } from 'lux/components';

import { PersonTooltipProps } from './PersonTooltip.types';
import * as styles from './PersonTooltip.styles';
import { PersonTooltipContent } from './personTooltipContent/PersonTooltipContent';

export const PersonTooltip = ({ children, employeeId }: PersonTooltipProps) => (
  <Tooltip
    placement="top"
    arrow
    title={<PersonTooltipContent employeeId={employeeId} />}
    PopperProps={{ sx: styles.popper }}
  >
    {children}
  </Tooltip>
);
