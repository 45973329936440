import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Button } from 'lux/components';

import { useReopenProject } from 'hooks/useReopenProject/useReopenProject';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';
import { AppMessages } from 'i18n/messages';

import * as styles from './ReopenProjectModal.styles';
import { ReopenProjectModalProps } from './ReopenProjectModal.types';

export const ReopenProjectModal = ({ projectId, projectName, open, onClose }: ReopenProjectModalProps) => {
  const { formatMessage } = useLocale();

  const { mutate, isLoading } = useReopenProject({ projectId, projectName });
  useDialogBlockScroll(open);

  const handleSubmitButtonClick = () => {
    mutate(
      {},
      {
        onSuccess: onClose,
      },
    );
  };

  return (
    <>
      <Dialog maxWidth="xs" open={open} onClose={onClose}>
        <DialogTitle>{formatMessage({ id: AppMessages['projectDetails.reopenProjectModal.title'] })}</DialogTitle>
        <DialogContent>{formatMessage({ id: AppMessages['projectDetails.reopenProjectModal.message'] })}</DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button variant="text" sx={styles.cancelButton} onClick={onClose}>
            {formatMessage({ id: AppMessages['button.cancel'] })}
          </Button>
          <Button loading={isLoading} variant="contained" onClick={handleSubmitButtonClick}>
            {formatMessage({ id: AppMessages['projectDetails.reopenProjectModal.button.submit'] })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
