import Joi, { ObjectSchema } from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';

import { AppMessages } from 'i18n/messages';
import { intl } from 'context/locale/intl/Intl';

import { AddInvoiceForm } from './IssueInvoiceAction.types';

const issueInvoiceFormSchema = (): ObjectSchema<AddInvoiceForm> => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object().keys({
    invoiceNumber: Joi.string()
      .required()
      .trim()
      .messages({
        'any.required': formatMessage(
          { id: AppMessages['validation.required'] },
          { label: formatMessage({ id: AppMessages['projectDetails.invoices.invoiceNumber'] }) },
        ),
        'string.empty': formatMessage(
          { id: AppMessages['validation.empty'] },
          { label: formatMessage({ id: AppMessages['projectDetails.invoices.invoiceNumber'] }) },
        ),
      }),
  });
};

export const useIssueInvoiceForm = (defaultValues: AddInvoiceForm = { invoiceNumber: '' }) => {
  const {
    control,
    clearErrors,
    formState,
    formState: { isValid: canIssueInvoice },
    handleSubmit,
    watch,
  } = useForm<AddInvoiceForm>({
    mode: 'all',
    defaultValues,
    resolver: joiResolver(issueInvoiceFormSchema()),
  });
  return { control, canIssueInvoice, handleSubmit, clearErrors, formState, watch };
};
