import { useState } from 'react';

import FocusTrap from '@mui/base/FocusTrap';
import { Box, ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import { Avatar, MenuItem } from 'lux/components';

import { useAvailabilityPeople } from '../useAvailabilityPeople/useAvailabilityPeople';

import * as styles from './PeopleListPopover.styles';

export const PeopleListPopover = () => {
  const { anchor, onPopoverClose, selectedValue } = useAvailabilityPeople();
  const [arrowRef, setArrowRef] = useState(null);

  return (
    <Popper
      open={Boolean(anchor)}
      anchorEl={anchor}
      placement="top"
      transition
      sx={styles.popper}
      onKeyDown={(e) => {
        if (e.code === 'Escape') {
          onPopoverClose();
        }
      }}
      id="availability-people-list"
      modifiers={[
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onPopoverClose}>
          <Grow {...TransitionProps}>
            <div>
              <FocusTrap open>
                <Paper sx={styles.contentContainer}>
                  {selectedValue?.map((person) => (
                    <MenuItem
                      tabIndex={0}
                      onClick={() => window.open(`/people/${person.employeeId}`, '_blank')}
                      key={person.employeeId}
                      avatar={
                        <Avatar size="md" alt={`${person.firstName} ${person.lastName}`} image={person.picture} />
                      }
                    >{`${person.firstName} ${person.lastName}`}</MenuItem>
                  ))}
                </Paper>
              </FocusTrap>
              <Box sx={styles.arrow} ref={setArrowRef} className="MuiPopper-arrow" />
            </div>
          </Grow>
        </ClickAwayListener>
      )}
    </Popper>
  );
};
