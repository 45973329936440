import { useContext } from 'react';

import { ClientsFiltersContext } from '../../context/clientsFilters/clientsFiltersContext/ClientsFiltersContext';

export const useClientsFilters = () => {
  const context = useContext(ClientsFiltersContext);

  if (context === undefined) {
    throw new Error('ClientsFiltersContext must be within ClientsFiltersContextProvider');
  }

  return context;
};
