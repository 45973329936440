import { useState } from 'react';

import { Box, Stack } from '@mui/material';
import { Badge, Button, Accordion } from 'lux/components';
import { useIntl } from 'react-intl';
import { CheckIcon, ErrorIcon } from 'lux/icons';

import { StepProgress } from 'ui/StepProgress/StepProgress';
import { AppMessages } from 'i18n/messages';
import { projectSetupSteps } from '../../projectSetupSteps';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { DefaultPeopleFilters } from 'context/peopleFilters/peopleFiltersContext/PeopleFiltersContext.types';
import { AssignWithoutNeedContainer } from 'shared/assignWithoutNeedContainer/AssignWithoutNeedContainer';

import { ProjectSetupProps } from './ProjectSetup.types';
import { initialStepFilters } from './ProjectSetup.utils';

export const ProjectSetup = ({ projectData }: ProjectSetupProps) => {
  const [expanded, setExpanded] = useState(true);
  const [initialFilters, setInitialFilters] = useState<DefaultPeopleFilters | undefined>();
  const { formatMessage } = useIntl();
  const { isOpen, setClose, setOpen } = useDialog();
  const {
    projectDetails: { id },
  } = useProjectDetails();

  const steps = projectSetupSteps(projectData);
  const unfinishedStepsCount = steps.reduce((acc, curr) => acc + Number(!curr.completed), 0);
  const nextUnfinishedStep = steps.find((step) => !step.completed);

  return (
    <>
      <Accordion
        summary={formatMessage({ id: AppMessages['projectDetails.accordions.details.projectSetup'] })}
        expanded={expanded}
        onChange={() => setExpanded((prev) => !prev)}
        subTitleElement={
          unfinishedStepsCount ? (
            <Badge isStatic variant="digit" color="red" badgeContent={unfinishedStepsCount.toString()} />
          ) : undefined
        }
      >
        <Accordion.Details>
          <Box marginTop={1} marginBottom={2}>
            <StepProgress
              totalSteps={steps.length}
              nextStepName={nextUnfinishedStep ? nextUnfinishedStep.label : undefined}
              completedSteps={steps.length - unfinishedStepsCount}
            />
          </Box>
          <Stack gap={1} alignItems="flex-start">
            {steps.map(({ label, completed, id }) => (
              <Button
                size="small"
                variant="text"
                key={id}
                disabled={completed}
                startIcon={completed ? <CheckIcon /> : <ErrorIcon />}
                onClick={() => {
                  setInitialFilters(initialStepFilters[id]);
                  setOpen();
                }}
              >
                {label}
              </Button>
            ))}
          </Stack>
        </Accordion.Details>
      </Accordion>

      {isOpen && initialFilters && (
        <AssignWithoutNeedContainer
          defaultFilters={initialFilters}
          projectId={id}
          isOpen={isOpen && !!initialFilters}
          onClose={() => {
            setInitialFilters(undefined);
            setClose();
          }}
        />
      )}
    </>
  );
};
