import type { Proposal } from 'shared/needs/Needs.types';
import type { Rejection } from 'api/actions/getRejections/getRejections.types';
import { ProposalStatus } from 'config/data/need/ProposalStatus.enum';

import { ProposalCard } from './proposalCard/ProposalCard';
import type { ProposalsListProps } from './ProposalsList.types';

const isProposal = (item: Proposal | Rejection): item is Proposal => (item as Proposal).status !== undefined;
export const ProposalsList = ({ items }: ProposalsListProps) => (
  <>
    {items.map((item) => {
      const status = isProposal(item) ? item.status : ProposalStatus.rejected;
      return <ProposalCard key={item.id} proposal={item} status={status} />;
    })}
  </>
);
