import { SxProps } from '@mui/system';

export const headerContainer: SxProps = {
  mt: 4,
  mb: 4,
};

export const breadcrumsContainer: SxProps = {
  mb: 2,
};
