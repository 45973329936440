import type { Styles } from 'styles/theme';
import { theme } from 'styles/theme';

export const card: Styles = {
  '&': {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.border.default}`,
    backgroundColor: 'common.white',
    borderRadius: '12px',
    p: 2,
    gap: 2,
  },
};

export const cardBody: Styles = {
  columnGap: 3,
  rowGap: 2,
  flexDirection: 'row',
  flexWrap: 'wrap',

  '> .MuiFormControl-root:not(:first-of-type:last-of-type)': {
    minWidth: 0,
    flex: 1 / 3,
  },
};

export const warningBox: Styles = {
  py: 0.75,
  px: 1,
  alignItems: 'center',

  '.MuiAlert-icon, .MuiAlert-icon > svg': {
    opacity: 1,
    width: 24,
    height: 24,
    mr: 2,
  },

  '.MuiAlert-message': {
    fontSize: 12,
    lineHeight: '20px',
    color: 'text.primary',
  },

  '::before': {
    content: 'none',
  },
};

export const topBar: Styles = {
  alignItems: 'center',
};
