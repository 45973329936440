import type { Styles } from 'styles/theme';

export const needCardModalHeaderWrapper: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const needCardModalHeaderMainInfo: Styles = {
  display: 'flex',
  flexDirection: 'column',
};

export const needCardModalHeaderOtherInfo: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: 2,
};

export const needCardModalHeaderDate: Styles = {
  marginRight: 9,
};

export const toggleDetailsButton: Styles = {
  lineHeight: 1.5,
  fontWeight: 600,

  '&&&': {
    px: 1,
    py: 1.5,
  },

  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export const needCardModalFooter: Styles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1,
};

export const skillsContainer: Styles = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 1,
};

export const proposalStats: Styles = {
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
};

export const proposalItem: Styles = {
  '&:not(:last-of-type)': {
    marginRight: 2,
  },
};
