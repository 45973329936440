import { MappedRateCardItem } from 'api/actions/getProject/getProject.types';
import { Seniority } from 'api/types/Seniority.enum';

export const getSeniorityRateAmounts = (roleRates: MappedRateCardItem[] | undefined) =>
  [Seniority.junior, Seniority.mid, Seniority.senior, Seniority.withoutLevel].map((seniority) => {
    if (!roleRates) {
      return undefined;
    }

    return roleRates.find((roleRate) => roleRate.seniority === seniority)?.amount;
  });
