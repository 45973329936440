import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { RemoveFuturePersonRateParams } from './removeFuturePersonRateAction.types';

export const removeFuturePersonRateMutation: (personId: number) => MutationFn<RemoveFuturePersonRateParams> =
  (personId: number) => () => ({
    endpoint: `/people/${personId}/future-rate`,
    method: 'DELETE',
    params: {},
  });
