import { Box } from '@mui/material';
import { Typography } from 'lux/components';
import { WarningFilledIcon } from 'lux/icons';

import * as styles from './SeniorityRate.styles';
import type { SeniorityRateProps } from './SeniorityRate.types';

export const SeniorityRate = ({ amount, isMissing }: SeniorityRateProps) => (
  <Box sx={styles.wrapper}>
    {(amount || (!amount && !isMissing)) && <Typography sx={styles.amount(amount)}>{amount ?? '-'}</Typography>}

    {!amount && isMissing && <WarningFilledIcon sx={styles.icon} />}
  </Box>
);
