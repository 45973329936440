import { Styles } from 'styles/theme';

export const textfield: Styles = {
  label: {
    left: -7,
  },
  fieldset: {
    padding: '0px 3px',
    legend: {
      paddingRight: 0.1,
      span: {
        padding: '0 8px',
      },
    },
  },
};
