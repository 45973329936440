import { Box } from '@mui/material';
import { Badge, Typography, Grid } from 'lux/components';

import { Translation } from 'ui/translation/Translation';

import { EmployeeCardFooterProps } from './EmployeeCardFooter.types';
import * as styles from './EmployeeCardFooter.styles';

const skillsLimit = 3;

export const EmployeeCardFooter = ({ skills, reason = '' }: EmployeeCardFooterProps) => {
  const limitedSkills = skills && skills.slice(0, skillsLimit);

  if (!skills?.length && !reason) {
    return null;
  }

  return (
    <Box sx={styles.employeeCardFooter}>
      {reason ? (
        <Typography variant="badgeLabel" color="text.secondary">
          <Translation id="card.need.body.rejected.reason" values={{ reason }} />
        </Typography>
      ) : (
        <>
          {
            <Grid item xs sx={styles.skillsWrapper}>
              {limitedSkills && limitedSkills.length > 0 && (
                <>
                  {limitedSkills.map(({ id, name }) => (
                    <Badge isStatic key={id} color="gray" badgeContent={name} />
                  ))}
                  {skills.length > limitedSkills.length && (
                    <Badge isStatic color="gray" badgeContent={`+${skills.length - limitedSkills.length}`} />
                  )}
                </>
              )}
            </Grid>
          }
          <Grid item>{/*Emojis TODO*/}</Grid>
        </>
      )}
    </Box>
  );
};
