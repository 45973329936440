import { editChargeMutation } from 'api/actions/editCharge/editChargeActions';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { AppMessages } from 'i18n/messages';

export const useEditCharge = (workStatementId: string, chargeId: string) => {
  const { invalidateBillingSummaryCache } = useProjectBillingSummaryData();
  const { formatMessage } = useLocale();

  return useMutationDomainError(['editCharge'], editChargeMutation(workStatementId, chargeId), {
    onSuccess: () => {
      invalidateBillingSummaryCache();
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.editCharge.error'] }),
  });
};
