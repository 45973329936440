import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { projectsKeys, assignmentsKeys } from 'utils/queryKeys';
import { extendProjectMutation } from 'api/actions/extendProject/extendProject';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';

export const useConfirmProjectExtension = (projectId: string) => {
  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutationDomainError(['extendProject'], extendProjectMutation(projectId), {
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.confirmProjectExtension.success'] }));
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(assignmentsKeys.assignmentsList);
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.confirmProjectExtension.error'] }),
  });
};
