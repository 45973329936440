import { Styles } from 'styles/theme';

export const table: Styles = {
  borderCollapse: 'separate',
  borderSpacing: 16,
  tableLayout: 'fixed',

  '&& .MuiTableCell-root': {
    border: 'none',
    height: 32,
    padding: '4px 8px',
    borderRadius: 2,
  },

  '& th.MuiTableCell-root': {
    textAlign: 'left',
  },

  '& td.MuiTableCell-root': {
    textAlign: 'center',
  },
};

export const tableToggleWrapper = (isAvailabilityExpanded: boolean): Styles => ({
  marginTop: -2,
  height: 'auto',
  maxHeight: isAvailabilityExpanded ? '670px' : 0,
  transition: 'max-height 0.5s ease-in-out',
  overflow: 'hidden',
});

export const row: Styles = {
  '&.MuiTableRow-root:hover': {
    background: 'transparent',
  },
};

export const departmentColumn: Styles = {
  '&&': { width: 150 },
};
