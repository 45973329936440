import { Stack } from '@mui/material';
import { Table } from 'lux/components';

import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

import * as styles from './ComparisonTable.styles';
import { HeadRowSkeleton } from './headRow/HeadRow.skeleton';
import { SectionHeaderRowSkeleton } from './sectionHeaderRow/SectionHeaderRow.skeleton';
import { ComparisonRowSkeleton } from './comparisonRow/ComparisonRow.skeleton';
import { RatesRowSkeleton } from './ratesRow/RatesRow.skeleton';
import { SkillRowSkeleton } from './skillRow/SkillRow.skeleton';

export const ComparisonTableSkeleton = () => (
  <Stack spacing={3} marginBottom={3}>
    <Table>
      <Table.Table sx={styles.table}>
        <Table.Head>
          <HeadRowSkeleton />
        </Table.Head>
        <Table.Body>
          <SectionHeaderRowSkeleton />
          <RatesRowSkeleton />

          <SectionHeaderRowSkeleton spacingTop />
          <ComparisonRowSkeleton
            needCell={<TextSkeleton width={60} variant="caption" />}
            personCell={<TextSkeleton width={60} variant="caption" />}
          />
          <ComparisonRowSkeleton
            needCell={<TextSkeleton width={60} variant="caption" />}
            personCell={<TextSkeleton width={60} variant="caption" />}
          />
          <ComparisonRowSkeleton
            needCell={<TextSkeleton width={60} variant="caption" />}
            personCell={<TextSkeleton width={60} variant="caption" />}
          />

          <SectionHeaderRowSkeleton spacingTop />
          <SkillRowSkeleton />
          <SkillRowSkeleton />
          <SkillRowSkeleton />
        </Table.Body>
      </Table.Table>
    </Table>
  </Stack>
);
