import Joi from 'joi';

import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';

export const confirmRejectModalSchema = () => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object().keys({
    reason: Joi.string()
      .required()
      .messages({
        'string.empty': formatMessage(
          { id: AppMessages['validation.empty'] },
          { label: formatMessage({ id: AppMessages['rejectPerson.error.emptyReason'] }) },
        ),
      }),
  });
};
