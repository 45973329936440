import { IssueInvoiceErrorEnum } from 'hooks/useIssueInvoice/useIssueInvoice.types';

export const extractInvoiceNumber = (sentence: string, errorType: string) => {
  let regex;

  switch (errorType) {
    case IssueInvoiceErrorEnum.invoiceNotFoundError:
      regex = /number\s+([\d\s/()]+)\s+was/i;
      break;
    case IssueInvoiceErrorEnum.invoiceTotalAmountMismatchError:
      regex = /invoice\s+(\S+)\s+total/i;
      break;
    case IssueInvoiceErrorEnum.invoiceCurrencyMismatchError:
      regex = /invoice\s+(\S+)\s+currency/i;
      break;
    case IssueInvoiceErrorEnum.invoiceExistError:
      regex = /number:\s+(\S+)\s+already/i;
      break;
    default:
      return null;
  }

  const match = sentence.match(regex);

  return match ? match[1] : null;
};
