import { ChangeEvent } from 'react';

import { TestFieldChangeParser } from 'ui/form/formTextEdit/FormTextField.types';

import { NumberValidationOptions } from './types/number';

export const createInputNumberParser = ({
  allowNegative = true,
  decimalPrecision = Infinity,
}: NumberValidationOptions): ((
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
) => ReturnType<TestFieldChangeParser>) => {
  if (decimalPrecision < 0) {
    throw new Error('decimalPrecision must be equal or higher than 0');
  }

  const sign = allowNegative ? /\-?/.source : '';
  const decimals = decimalPrecision === Infinity ? /(\d+)?/.source : new RegExp(`(\[0-9]{0,})?`).source;
  const decimalSeparator = decimalPrecision ? /[\.\,]?/.source : '';
  const validInputRegex = new RegExp('^' + sign + /(\d+)?/.source + decimalSeparator + decimals + '$');

  return (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;

    if (!validInputRegex.test(value)) {
      return {
        isValid: false,
      };
    }

    let parsedValue = value.replace(',', '.');

    if (decimalPrecision !== Infinity) {
      const [int, decimal] = parsedValue.split('.');

      parsedValue = `${int}${parsedValue.includes('.') ? '.' : ''}${decimal?.substring(0, decimalPrecision) || ''}`;
    }

    return {
      isValid: true,
      value: parsedValue,
    };
  };
};

export const moneyInputNumberParser = createInputNumberParser({ allowNegative: false, decimalPrecision: 2 });
export const hourInputNumberParser = createInputNumberParser({ allowNegative: false, decimalPrecision: 2 });
export const percentageInputNumberParser = createInputNumberParser({ allowNegative: false, decimalPrecision: 0 });
