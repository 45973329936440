import { KeyboardEvent } from 'react';

import { NumberValidationOptions } from './types/number';

export const createNumberKeyDownHandler = ({
  allowNegative = true,
  decimalPrecision = Infinity,
}: NumberValidationOptions = {}) => {
  if (decimalPrecision < 0) {
    throw new Error('decimalPrecision must be equal or higher than 0');
  }

  const sign = allowNegative ? /\-?/.source : '';
  const decimals =
    decimalPrecision === Infinity ? /(\d+)?/.source : new RegExp(`(\[0-9]{0,${decimalPrecision}})?`).source;
  const decimalSeparator = decimalPrecision ? /[\.\,]?/.source : '';
  const validInputRegex = new RegExp('^' + sign + /(\d+)?/.source + decimalSeparator + decimals + '$');

  return (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (
      !['Backspace', 'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Delete', 'Enter', 'Tab'].includes(event.key) &&
      !event.metaKey &&
      !event.ctrlKey &&
      !validInputRegex.test(event.currentTarget.value + event.key)
    ) {
      event.preventDefault();
    }
  };
};
