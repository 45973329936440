import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { EditWorkStatementRequest, EditWorkStatementResponse } from './editWorkStatementActions.types';

export const editWorkStatementMutation: (
  id: string,
) => MutationFn<EditWorkStatementRequest, EditWorkStatementResponse> = (workStatementId: string) => (body) => ({
  endpoint: `/work-statements/${workStatementId}`,
  method: 'PATCH',
  params: body,
});
