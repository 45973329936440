import { SxProps } from '@mui/material';
import { Styles } from 'lux/theme';

export const wrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 3,
  my: 10,
};

export const text: Styles = {
  color: 'text.secondary',
  fontWeight: 500,
  maxWidth: 590,
  textAlign: 'center',
};

export const imageWrapper: SxProps = {
  maxWidth: '15.625rem',
};
