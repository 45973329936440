import { Styles } from 'styles/theme';

export const wrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '& img': {
    marginBottom: 6,
    width: '100%',
    maxWidth: 430,
  },
};

export const message: Styles = {
  marginTop: 0.25,
  marginBottom: 3,
  color: 'text.secondary',
  textAlign: 'center',
};
