import { useRef } from 'react';

import { Box, ButtonBase } from '@mui/material';
import { Typography } from 'lux/components';

import { getTextColor } from 'config/data/dashboard/availability';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages, Translation } from 'i18n/messages';
import { useAvailabilityPeople } from '../../../useAvailabilityPeople/useAvailabilityPeople';
import { Seniority } from 'api/types/Seniority.enum';

import * as styles from './SplitValue.styles';
import { SeniorityLevel, SplitValueProps } from './SplitValue.types';

const levelsMetadata = {
  [SeniorityLevel.junior]: {
    position: 1,
  },
  [SeniorityLevel.regular]: {
    position: 2,
  },
  [SeniorityLevel.senior]: {
    position: 3,
  },
};

export const SplitValue = ({
  data,
  totalInDepartment,
  showSeniorityHeaders,
  people,
  period,
  department,
}: SplitValueProps) => {
  const anchors = useRef<(HTMLDivElement | null)[]>([]);
  const { setSelectedPeople, setAnchor, setActiveCell, activeCellId } = useAvailabilityPeople();
  const { formatMessage } = useLocale();

  const entries = Object.entries(data).sort(
    (a, b) => levelsMetadata[a[0] as SeniorityLevel].position - levelsMetadata[b[0] as SeniorityLevel].position,
  );

  return (
    <>
      {showSeniorityHeaders && (
        <Box sx={styles.labelContainer}>
          {entries.map(([level]) => (
            <Box flex={1} key={level}>
              <Typography color="text.secondary" variant="badgeLabel">
                {formatMessage({ id: AppMessages[`dashboard.availability.table.level.${level}` as Translation] })}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
      <Box sx={styles.valuesContainer}>
        {entries.map(([level, value], index) => {
          const cellId = `${level}-${period}-${department}`;
          return (
            <Box
              ref={(ref) => (anchors.current[index] = ref as HTMLDivElement)}
              key={level}
              sx={styles.tableCell(Number(value), totalInDepartment)}
            >
              <Typography
                component={ButtonBase}
                onClick={(event) => {
                  setSelectedPeople(
                    people.filter(
                      (person) =>
                        (person.seniority === Seniority.mid && level === SeniorityLevel.regular) ||
                        person.seniority.toLowerCase() === level,
                    ),
                  );
                  setAnchor(anchors.current[index]);
                  setActiveCell(cellId);
                  event.stopPropagation();
                }}
                sx={styles.button}
                aria-haspopup="true"
                aria-expanded={cellId === activeCellId}
                aria-controls="availability-people-list"
                variant="body1"
                disableRipple
                height="100%"
                width="100%"
                fontWeight={500}
                disabled={value === 0}
                color={getTextColor(Number(value), totalInDepartment)}
              >
                {value}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </>
  );
};
