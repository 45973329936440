import { Stack } from '@mui/material';

import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

export const NeedRateSkeleton = () => (
  <Stack spacing={2} direction="row" alignItems="center" marginY={0}>
    <TextSkeleton width={44} variant="caption" />
    <TextSkeleton width={66} variant="caption" />
  </Stack>
);
