import { getNeedQuery } from 'api/actions/getNeed/getNeed';
import { GetNeedParams, GetNeedResponse } from 'api/actions/getNeed/getNeed.types';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { needsKeys } from 'utils/queryKeys';
import { sortSkillsByWeight } from 'utils/sortSkillsByWeight';

export const useNeed = (needId: string) => {
  const { isAuthenticated } = useAuth();

  const { data, ...query } = useQuery<GetNeedParams, GetNeedResponse>(
    needsKeys.singleNeed(needId),
    getNeedQuery(needId),
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
    },
  );

  const formattedData = data
    ? {
        ...data,
        skillSet: sortSkillsByWeight(data.skillSet),
      }
    : undefined;

  return { ...query, data: formattedData };
};
