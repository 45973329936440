import { Styles } from 'lux/theme';

import { DEFAULT_BORDER_PROPERTIES } from '../../tableComponents/tableComponents';

export const hoursSum = (isDimmed?: boolean): Styles => ({
  backgroundColor: isDimmed ? 'accent.gray' : 'warning.light',
  display: 'flex',
  alignItems: 'center',
  borderLeft: DEFAULT_BORDER_PROPERTIES,
  textAlign: 'center',
  borderColor: 'border.light',
  justifyContent: 'center',
  minWidth: 56,
  padding: '0 4px',
  height: '100%',
});
