import type { Styles } from 'lux/theme';

export const textSkeleton = (fontSize?: number | string, lineHeight?: number | string, overrides?: Styles): Styles => {
  const lineHeightInPx: number | null = lineHeight
    ? typeof lineHeight === 'number'
      ? lineHeight * parseInt(String(fontSize))
      : parseInt(lineHeight)
    : null;
  const fontSizeInPx: number | null = fontSize ? (typeof fontSize === 'number' ? fontSize : parseInt(fontSize)) : null;
  const my: string | null = fontSizeInPx && lineHeightInPx ? (lineHeightInPx - fontSizeInPx) / 2 + 'px' : null;

  return {
    borderRadius: 1,
    ...(fontSizeInPx && { height: fontSizeInPx }),
    ...(my && { my }),
    ...overrides,
  };
};
