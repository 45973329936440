import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { DashboardCard } from 'ui/dashboardCard/DashboardCard';
import { AvailabilityFiltersContextController } from 'context/availabilityFilters/availabilityFiltersContextController/AvailabilityFiltersContextController';

import { AvailabilityContent } from './availabilityContent/AvailabilityContent';

export const Availability = () => {
  const { formatMessage } = useLocale();

  return (
    <DashboardCard title={formatMessage({ id: AppMessages['dashboard.availability.title'] })}>
      <AvailabilityFiltersContextController storageKey="dashboardAvailability">
        <AvailabilityContent />
      </AvailabilityFiltersContextController>
    </DashboardCard>
  );
};
