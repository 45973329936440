import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { ConfirmInvoiceParams } from './confirmInvoice.types';

export const confirmInvoice: (invoiceId: string) => MutationFn<ConfirmInvoiceParams> =
  (invoiceId: string) => (params) => ({
    endpoint: `/invoices/${invoiceId}/confirm`,
    method: 'PATCH',
    params,
  });
