import { UserIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Filter } from 'ui/filter/Filter';
import { AutocompleteStack } from 'ui/autocompleteStack/AutocompleteStack';
import { useSpecializations } from 'hooks/useSpecializations/useSpecializations';

import { RoleFilterProps as SharedRoleFilterProps } from './RoleFilter.types';

export const RoleFilter = ({ variant, roles, onFilterChange, onClearClick }: SharedRoleFilterProps) => {
  const { formatMessage } = useLocale();
  const { data: specializations } = useSpecializations();

  return (
    <Filter
      variant={variant}
      icon={<UserIcon />}
      label={formatMessage({ id: AppMessages['filters.role.label'] })}
      popoverTitle={formatMessage({ id: AppMessages['filters.role.popover.title'] })}
      popoverActionLabel={formatMessage({ id: AppMessages['filters.clear'] })}
      valueSelected={roles.length > 0}
      selectedCount={roles.length}
      onPopoverActionClick={onClearClick}
      shouldStretch
    >
      <AutocompleteStack
        label={formatMessage({ id: AppMessages['filters.role.label'] })}
        values={roles}
        dictionary={specializations || []}
        onFieldsChange={onFilterChange}
        buttonLabel={formatMessage({ id: AppMessages['filters.role.addRole'] })}
      />
    </Filter>
  );
};
