import type { MutationFn } from 'hooks/useMutation/useMutation.types';
import { AssignPersonError } from 'api/actions/assignPerson/assignPersonActions.types';

import type { ProposePersonArguments, ProposePersonResponse } from './proposePersonActions.types';

export const proposePersonMutation: (
  projectId: string,
  needId: string,
) => MutationFn<ProposePersonArguments, ProposePersonResponse, AssignPersonError> =
  (projectId: string, needId: string) => (body) => ({
    endpoint: `/projects/${projectId}/needs/${needId}/propose-person`,
    method: 'POST',
    params: body,
  });
