import { useState } from 'react';

import { Tooltip, Typography } from 'lux/components';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { TableCell, TableRow } from '../../tableComponents/tableComponents';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';
import { useProjectTimesheet } from 'hooks/useProjectTimesheet/useProjectTimesheet';
import { useGetIsFirstDayOfProject } from 'hooks/useGetIsFirstDayOfProject/useGetIsFirstDayOfProject';
import { useProject } from 'hooks/useProject/useProject';
import { CollapseButton } from 'ui/collapseButton/CollapseButton';
import { TeamMemberRow } from '../teamMemberRow/TeamMemberRow';

import * as styles from './WorkStatementRow.styles';
import { WorkStatementRowProps } from './WorkStatementRow.types';

export const WorkStatementRow = ({ workStatement, isClientView }: WorkStatementRowProps) => {
  const [isProjectRowExpanded, setIsProjectRowExpanded] = useState(true);
  const { timesheetMonthDatesParsed: days } = useProjectTimesheet();
  const { projectId } = useProjectTimesheetData();
  const { data: project } = useProject(projectId);

  const getIsFirstDayOfProject = useGetIsFirstDayOfProject();

  const timesheetProjectAndWsInfo = `${isClientView && project?.name + ' - '} ${workStatement.statementNumber}`;

  return (
    <Accordion disableGutters expanded={isProjectRowExpanded} sx={{ border: 'none' }}>
      <AccordionSummary expandIcon={false} sx={styles.accordionSummary}>
        <TableRow>
          <TableCell sx={styles.workStatementCell(false)}>
            <CollapseButton
              isExpanded={isProjectRowExpanded}
              onClick={() => setIsProjectRowExpanded(!isProjectRowExpanded)}
            />
            <Tooltip placement="top" arrow title={timesheetProjectAndWsInfo}>
              <Typography variant="buttonSmall" sx={styles.workStatementName}>
                {timesheetProjectAndWsInfo}
              </Typography>
            </Tooltip>
          </TableCell>
          {days.map((day) => {
            const { isProjectStartDayMarker } = getIsFirstDayOfProject(Number(day.number));

            return <TableCell key={day.number} sx={styles.workStatementCell(isProjectStartDayMarker)} />;
          })}
        </TableRow>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetails}>
        {workStatement.assignments.map((assignment) => (
          <TeamMemberRow key={assignment.employeeId} assignment={assignment} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
