import { useState, useEffect } from 'react';

import { UseTypingStateOptions } from './useTypingState.types';

export const useTypingState = (
  onStopTyping: () => Promise<void>,
  delay = 1000,
  options: UseTypingStateOptions = { enabled: true },
) => {
  const [isTyping, setIsTyping] = useState(false);

  const onKeyDownHandler = () => {
    if (!isTyping && options.enabled) {
      setIsTyping(true);
    }
  };

  useEffect(() => {
    if (isTyping && options.enabled) {
      const timer = setTimeout(() => {
        setIsTyping(false);
        onStopTyping();
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [delay, isTyping, options.enabled, onStopTyping]);

  return { onKeyDownHandler, isTyping };
};
