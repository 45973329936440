import { Styles } from 'styles/theme';

export const wrapper: Styles = {
  border: 'none',

  '&:before': {
    display: 'none',
  },
};

export const summary: Styles = {
  paddingX: 0,
  paddingY: 1.5,

  '&:hover': {
    background: 'transparent',
  },

  'svg path': {
    fill: (theme) => theme.palette.text.primary,
  },
};

export const details: Styles = {
  margin: 0,

  '&.MuiAccordionDetails-root': {
    borderTop: 'none',
    paddingTop: 0,
    paddingX: 0,
  },

  '.MuiFormControlLabel-label': {
    typography: 'body2',
    fontWeight: 500,
  },
};
