import { getBillingCycleDetails } from 'api/actions/getBillingCycleDetails/getBillingCycleDetails';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { projectsKeys } from 'utils/queryKeys';

export const useBillingCycle = (projectId: string, date?: string, enabled = true) => {
  const { isAuthenticated } = useAuth();
  return useQuery(projectsKeys.projectBillingCycle(projectId, date), getBillingCycleDetails(projectId), {
    enabled: isAuthenticated && enabled && !!date,
    refetchOnWindowFocus: false,
    args: { date },
  });
};
