import { Table } from 'lux/components';
import { Box, Divider } from '@mui/material';

import { AvailabilityFilters } from 'context/availabilityFilters/availabilityFiltersContext/AvailabilityFiltersContext.types';
import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

import { INITIAL_DATA_ROWS_COUNT } from './AvailabilityTable.utils';
import * as styles from './AvailabilityTable.styles';
import { AvailabilityTableRowSkeleton } from './availabilityTableRow/AvailabilityTableRow.skeleton';

const ROWS_COUNT = 7;

export const AvailabilityTableSkeleton = ({ filters }: { filters: AvailabilityFilters }) => (
  <>
    <Table>
      <Table.Table sx={styles.table}>
        <Table.Head>
          <Table.Row sx={styles.row}>
            <Table.Cell sx={styles.departmentColumn} />
            {Array(4)
              .fill(null)
              .map((_, index) => (
                <Table.Cell align="left" key={index}>
                  <TextSkeleton width={76} variant="body1" />
                </Table.Cell>
              ))}
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {Array(INITIAL_DATA_ROWS_COUNT)
            .fill(null)
            .map((_, index) => (
              <AvailabilityTableRowSkeleton
                key={index}
                showDepartmentIcon
                splitBySeniority={filters.splitBySeniority}
                showSeniorityHeaders={index === 0}
              />
            ))}
        </Table.Body>
      </Table.Table>
    </Table>

    <Box sx={styles.tableToggleWrapper(filters.isAvailabilityExpanded)}>
      <Table>
        <Table.Table sx={styles.table}>
          <Table.Body>
            {Array(ROWS_COUNT - INITIAL_DATA_ROWS_COUNT)
              .fill(null)
              .map((_, index) => (
                <AvailabilityTableRowSkeleton
                  key={index}
                  showDepartmentIcon
                  splitBySeniority={filters.splitBySeniority}
                />
              ))}
          </Table.Body>
        </Table.Table>
      </Table>
      <Divider />
      <Table.Table sx={styles.table}>
        <Table.Body>
          <AvailabilityTableRowSkeleton splitBySeniority={filters.splitBySeniority} showDepartmentIcon={false} />
        </Table.Body>
      </Table.Table>
    </Box>
  </>
);
