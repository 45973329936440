import { useCallback, useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';

import { getActiveAccount } from '../../utils/msalAccountUtils';

export const useAcquireTokenSilent = () => {
  const { instance, inProgress } = useMsal();
  const [loading, setLoading] = useState(true);
  const [fetchingToken, setFetchingToken] = useState(false);
  const [error, setError] = useState<unknown>();
  const [activeAccount] = useState(getActiveAccount(instance));

  const fetchToken = useCallback(async () => {
    if (!activeAccount) {
      return;
    }

    setFetchingToken(true);
    setError(undefined);

    try {
      return instance.acquireTokenSilent({
        scopes: [`${process.env.REACT_APP_ACTIVE_DIRECTORY_CLIENT_ID}/.default`],
        account: activeAccount,
      });
    } catch (error) {
      setError(error);

      throw error;
    } finally {
      setFetchingToken(false);
    }
  }, [instance, activeAccount]);

  useEffect(() => {
    if (error) {
      setLoading(false);
    } else {
      setLoading(fetchingToken || inProgress !== 'none');
    }
  }, [inProgress, error, activeAccount, fetchingToken]);

  return { loading, error, fetchToken };
};
