import { useState } from 'react';

import { joiResolver } from '@hookform/resolvers/joi';
import { Box, Stack } from '@mui/material';
import { Button, InlineEditInputWrapper } from 'lux/components';
import { AddIcon } from 'lux/icons';

import { Translation } from 'ui/translation/Translation';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { AddDepositParams } from 'api/actions/addDeposit/addDeposit.types';
import { useAddDeposit } from 'hooks/useAddDeposit/useAddDeposit';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { useLocale } from 'hooks/useLocale/useLocale';
import { DatePicker } from 'ui/form/datePicker/DatePicker';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { MAX_SUPPORTED_DATE, formatDate, parseDate } from 'utils/dateUtils';
import { DepositStatus } from 'api/types/BillingSummary.types';
import { convertRateToInteger } from 'utils/convertRate';
import { moneyInputNumberParser } from 'utils/createInputNumberChangeParser';

import * as styles from './AddDeposit.styles';
import { AddDepositForm, AddDepositProps } from './AddDeposit.types';
import { addDepositSchema } from './AddDeposit.schema';

const defaultValues: AddDepositForm = {
  amount: 0,
  paymentDue: null,
};

export const AddDeposit = ({ workStatement }: AddDepositProps) => {
  const [isAddDepositOpen, setIsAddDepositOpen] = useState(false);
  const { t } = useLocale();
  const { mutate, isLoading } = useAddDeposit(workStatement.id);
  const { projectDetails } = useProjectDetails();
  const { showWorkStatementSnapshot } = useProjectBillingSummaryData();
  const currency = projectDetails.currency;
  const { isInvoiceEditBlocked } = useProjectBillingSummaryData();

  const openAddDeposit = () => setIsAddDepositOpen(true);
  const closeAddDeposit = () => setIsAddDepositOpen(false);

  const onSubmit = (data: AddDepositForm) => {
    if (!data.paymentDue) {
      return;
    }
    const payload: AddDepositParams = {
      amount: convertRateToInteger(data.amount),
      paymentDue: formatDate(data.paymentDue),
      status: DepositStatus.WAITING_FOR_PAYMENT,
    };
    mutate(payload, {
      onSuccess: () => {
        closeAddDeposit();
        reset();
      },
    });
  };

  const { control, handleSubmit, reset } = useIntlForm<AddDepositForm>({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(addDepositSchema(workStatement.startDate, workStatement.endDate)),
  });

  return (
    <>
      {isAddDepositOpen ? (
        <Box sx={styles.inputWrapper}>
          <InlineEditInputWrapper onCancel={closeAddDeposit} onSubmit={handleSubmit(onSubmit)} loading={isLoading}>
            <Stack sx={styles.wrapper}>
              <FormTextField
                control={control}
                name="amount"
                size="small"
                fullWidth
                label={t('projectDetails.billingSummary.workStatementDetails.deposits.amount')}
                endAdornment={currency}
                onChangeParser={moneyInputNumberParser}
                isDisabled={isInvoiceEditBlocked}
              />
              <DatePicker
                minDate={parseDate(workStatement.startDate)}
                maxDate={workStatement.endDate ? parseDate(workStatement.endDate) : MAX_SUPPORTED_DATE}
                name="paymentDue"
                control={control}
                label={t('projectDetails.billingSummary.workStatementDetails.deposits.paymentDue')}
                disableUntilDate={parseDate(workStatement.startDate)}
                fullWidth
                size="small"
                isDisabled={isInvoiceEditBlocked}
              />
            </Stack>
          </InlineEditInputWrapper>
        </Box>
      ) : (
        <Button
          variant="outlinedGray"
          disabled={showWorkStatementSnapshot || isInvoiceEditBlocked}
          endIcon={<AddIcon />}
          size="small"
          sx={styles.button}
          onClick={openAddDeposit}
        >
          <Translation id="projectDetails.billingSummary.workStatementDetails.deposits.addDeposit" />
        </Button>
      )}
    </>
  );
};
