import { useContext } from 'react';

import { ProjectBillingSummaryContext } from '../../context/projectBillingSummary/projectBillingSummaryContext/ProjectBillingSummaryContext';

export const useProjectBillingSummary = () => {
  const context = useContext(ProjectBillingSummaryContext);

  if (context === undefined) {
    throw new Error('ProjectBillingSummaryContext must be within ProjectBillingSummaryContextController');
  }

  return context;
};
