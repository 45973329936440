import { AvailabilityFiltersContext } from '../availabilityFiltersContext/AvailabilityFiltersContext';
import { AvailabilityFilters, PeriodType } from '../availabilityFiltersContext/AvailabilityFiltersContext.types';
import { useFilters } from 'hooks/useFilters/useFilters';

import { AvailabilityFiltersContextControllerProps } from './AvailabilityFiltersContextController.types';

const defaultFilters = {
  splitBySeniority: false,
  period: PeriodType.weekly,
  includeProposed: true,
  includeExtension: false,
  isAvailabilityExpanded: false,
};

export const AvailabilityFiltersContextController = ({
  initialFilters,
  children,
  withParams,
  storageKey,
}: AvailabilityFiltersContextControllerProps) => {
  const contextValues = useFilters<AvailabilityFilters>(
    {
      ...defaultFilters,
      ...initialFilters,
    },
    { withParams, storageKey },
    { includeProposed: true },
  );

  return <AvailabilityFiltersContext.Provider value={contextValues}>{children}</AvailabilityFiltersContext.Provider>;
};
