import Joi from 'joi';

import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';

export const linkSchema = () => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object().keys({
    name: Joi.string()
      .required()
      .messages({
        'string.empty': formatMessage(
          { id: AppMessages['validation.empty'] },
          { label: formatMessage({ id: AppMessages['projectDetails.links.linkName'] }) },
        ),
      }),
    url: Joi.string()
      .uri({ allowRelative: true, scheme: ['https', 'http'] })
      .required()
      .messages({
        'string.empty': formatMessage(
          { id: AppMessages['validation.empty'] },
          { label: formatMessage({ id: AppMessages['projectDetails.links.url'] }) },
        ),
        'string.uri': formatMessage(
          { id: AppMessages['validation.notLink'] },
          { label: formatMessage({ id: AppMessages['projectDetails.links.url'] }) },
        ),
      }),
  });
};
