import { Box } from '@mui/material';
import { WarningFilledIcon } from 'lux/icons';
import { Tooltip } from 'lux/components';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { EmploymentType } from 'api/types/EmploymentType.enum';
import { ComparisonRow } from '../comparisonRow/ComparisonRow';

import * as styles from './RatesRow.styles';
import { PersonRate } from './personRate/PersonRate';
import { RatesRowProps } from './RatesRow.types';
import { NeedRate } from './needRate/NeedRate';

export const RatesRow = ({
  employmentType,
  needRate,
  personRates,
  currency,
  effectiveRate,
  onEffectiveRateChange,
}: RatesRowProps) => {
  const { formatMessage } = useLocale();

  const hasCustomRate = effectiveRate !== null;
  const hasNeedRate = needRate !== undefined;
  const hasPersonRateWarning = () => {
    if (employmentType !== EmploymentType.LTSH || !hasNeedRate || !personRates) {
      return false;
    }
    if (hasCustomRate) {
      return Number(effectiveRate) <= Number(personRates[currency]);
    }

    return needRate <= Number(personRates[currency]);
  };

  return (
    <ComparisonRow
      highlightCondition={hasPersonRateWarning()}
      checkmarkCondition={false}
      variant="warning"
      heading={formatMessage({ id: AppMessages['card.need.comparisonTable.hourlyRate'] })}
      needCell={
        <NeedRate
          currency={currency}
          effectiveRate={effectiveRate}
          needRate={needRate}
          onEffectiveRateChange={onEffectiveRateChange}
        />
      }
      personCell={<PersonRate employmentType={employmentType} personRates={personRates} currency={currency} />}
      iconCell={
        hasPersonRateWarning() && (
          <Tooltip
            title={formatMessage({ id: AppMessages['card.need.comparisonTable.warnings.personRate.tooltip'] })}
            arrow
          >
            <Box>
              <WarningFilledIcon data-testid="warning" sx={styles.warningIcon} />
            </Box>
          </Tooltip>
        )
      }
    />
  );
};
