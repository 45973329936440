import { DialogTitle, DialogContent, DialogActions, Stack } from '@mui/material';
import { Typography, Button } from 'lux/components';
import { SubmitHandler } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useSnackbar } from 'notistack';

import { formatDate, parseDate } from 'utils/dateUtils';
import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useUnsavedChangesDialogContext } from 'hooks/useUnsavedChangesDialogContext/useUnsavedChangesDialogContext';
import { DatePicker } from 'ui/form/datePicker/DatePicker';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { AppMessages } from 'i18n/messages';
import { useCloseProject } from 'hooks/useCloseProject/useCloseProject';

import { getDefaultClosingDate } from './CloseProject.utils';
import * as styles from './CloseProjectContent.styles';
import { closeProjectSchema } from './CloseProject.schema';
import { CloseProjectFormValues, CloseProjectContentProps } from './CloseProjectContent.types';

export const CloseProjectContent = ({
  onClose,
  projectId,
  projectStartDate,
  projectEndDate,
  projectName,
}: CloseProjectContentProps) => {
  const defaultValues = {
    endDate: getDefaultClosingDate(projectEndDate),
    closingReason: '',
  };

  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isLoading } = useCloseProject(projectId);
  const { DialogClickAwayListenerWrapper } = useUnsavedChangesDialogContext();

  const form = useIntlForm<CloseProjectFormValues>({
    defaultValues,
    mode: 'onSubmit',
    resolver: joiResolver(closeProjectSchema(projectStartDate)),
  });

  const onSubmit: SubmitHandler<CloseProjectFormValues> = (data) => {
    if (!data.endDate) {
      return;
    }

    mutate(
      { endDate: formatDate(data.endDate), closingReason: data.closingReason },
      {
        onSuccess: (data, variables) => {
          onClose();
          enqueueSnackbar(
            formatMessage(
              { id: AppMessages['snackbar.closeProject.success'] },
              { projectName, date: variables.endDate },
            ),
          );
        },
      },
    );
  };

  return (
    <DialogClickAwayListenerWrapper defaultValues={defaultValues} form={form} onClose={onClose}>
      <DialogTitle>
        {formatMessage({ id: AppMessages['projectDetails.closeProjectModal.title'] }, { projectName })}
      </DialogTitle>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogContent sx={styles.dialogContent}>
          <Stack spacing={3} alignItems="flex-start">
            <Typography>{formatMessage({ id: AppMessages['projectDetails.closeProjectModal.message'] })}</Typography>

            <DatePicker
              fullWidth
              label={formatMessage({ id: AppMessages['projectDetails.closeProjectModal.endDate.label'] })}
              name="endDate"
              disableUntilDate={parseDate(projectStartDate)}
              disableFuture
              control={form.control}
            />
            <FormTextField
              rows={4}
              maxRows={4}
              multiline
              fullWidth
              name="closingReason"
              label={formatMessage({ id: AppMessages['projectDetails.closeProjectModal.closingReason.label'] })}
              control={form.control}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button variant="text" sx={styles.cancelButton} type="button" onClick={onClose}>
            {formatMessage({ id: AppMessages['button.cancel'] })}
          </Button>
          <Button loading={isLoading} type="submit" variant="contained">
            {formatMessage({ id: AppMessages['projectDetails.closeProjectModal.button.submit'] })}
          </Button>
        </DialogActions>
      </form>
    </DialogClickAwayListenerWrapper>
  );
};
