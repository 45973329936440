import { SxProps } from '@mui/material';

export const container: SxProps = {
  ['@media print']: {
    width: '300px',
    border: '0.5px solid',
    borderColor: 'border.light',
    borderRadius: 5,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export const wrapper: SxProps = {
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',

  '.MuiSvgIcon-colorError > path': {
    color: 'error.main',
    fill: 'currentColor',
  },

  '& .MuiButtonBase-root': {
    padding: 0,
    ['@media print']: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
};

export const titleWrapper: SxProps = {
  ['@media print']: {
    width: '100%',
    gap: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '.MuiSvgIcon-colorError > path': {
    color: 'error.main',
    fill: 'currentColor',
  },
};

export const commentWrapper: SxProps = {
  '&.MuiGrid-root': {
    paddingLeft: 2,
  },
};
