import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useMutation } from '../useMutation/useMutation';
import { disconnectFromCRMMutation } from '../../api/actions/disconnectFromCRMMutation/disconnectFromCRMMutation';
import { projectsKeys } from '../../utils/queryKeys';
import { useLocale } from '../useLocale/useLocale';

export interface UseDisconnectFromCRMModalProps {
  projectId: string;
  onClose: VoidFunction;
}
export const useDisconnectFromCRMModal = ({ projectId, onClose }: UseDisconnectFromCRMModalProps) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocale();
  const { mutate, isLoading } = useMutation(['disconnectFromCRM'], disconnectFromCRMMutation(projectId), {
    onSuccess: async () => {
      await queryClient.refetchQueries(projectsKeys.singleProject(projectId));
      onClose();
    },
    onError: () => {
      enqueueSnackbar(t('projectDetails.disconnectFromCRMModal.req.failed'), { variant: 'error' });
    },
  });

  return {
    handleSubmitButtonClick: mutate,
    isLoading,
  };
};
