import * as React from 'react';

import { ChevronDownIcon, ChevronUpIcon } from 'lux/icons';
import { Button } from 'lux/components';

import * as styles from './CollapseButton.styles';
import { CollapseButtonProps } from './CollapseButton.types';

export const CollapseButton = ({ isExpanded, onClick, sx }: CollapseButtonProps) => (
  <Button iconOnly variant="text" size="small" onClick={onClick} sx={{ ...styles.collapseButton, ...sx }}>
    {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
  </Button>
);
