import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { ReopenProjectMutationParams } from './reopenProjectActions.types';

export const reopenProjectMutation: (projectId: string) => MutationFn<ReopenProjectMutationParams> =
  (projectId: string) => (body) => ({
    endpoint: `/projects/${projectId}/reopen`,
    method: 'PATCH',
    params: body,
  });
