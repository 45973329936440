import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { EditDiscountParams } from './editDiscountActions.types';

export const editDiscountMutation: (workStatementId: string, discountId: string) => MutationFn<EditDiscountParams> =
  (workStatementId: string, discountId: string) => (body) => ({
    endpoint: `/work-statements/${workStatementId}/discounts/${discountId}`,
    method: 'PATCH',
    params: body,
  });
