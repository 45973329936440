import { Box, Typography } from '@mui/material';
import { Breadcrumbs, Button, Grid } from 'lux/components';
import { Link, matchPath } from 'react-router-dom';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppRoute } from 'routing/AppRoute.enum';

import { ClientHeaderProps } from './ClientHeader.types';
import { ClientHeaderSkeleton } from './ClientHeaderSkeleton';
import * as styles from './ClientHeader.styles';

export const ClientHeader = ({ name, isLoading }: ClientHeaderProps) => {
  const { formatMessage } = useLocale();

  return (
    <Box sx={styles.headerContainer}>
      <Grid item xs={12} sx={styles.breadcrumsContainer}>
        <Breadcrumbs
          linkNameMapper={{
            [AppRoute.clients]: formatMessage({ id: AppMessages['breadcrumbs.label.clients'] }),
            [AppRoute.clientDetails]: name,
          }}
          currentPath={location.pathname}
          matchPath={(pattern, path) => Boolean(matchPath(pattern, path))}
          renderButtonComponent={(props) => <Button component={Link} {...props} />}
        />
      </Grid>
      {isLoading ? <ClientHeaderSkeleton /> : <Typography variant="h4">{name}</Typography>}
    </Box>
  );
};
