import { useSnackbar } from 'notistack';

import { editWorkStatementMutation } from 'api/actions/editWorkStatement/editWorkStatementActions';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';

export const useEditWorkStatement = (workStatementId: string, workStatementNumber: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useLocale();
  const { invalidateBillingSummaryCache } = useProjectBillingSummaryData();

  return useMutation(['editWorkStatementMutation'], editWorkStatementMutation(workStatementId), {
    onSuccess: () => {
      enqueueSnackbar(
        formatMessage(
          { id: AppMessages['projectDetails.workStatementForm.editWorkStatement.success'] },
          { name: `${workStatementNumber}` },
        ),
      );
      invalidateBillingSummaryCache();
    },
  });
};
