import { Stack } from '@mui/material';
import { DataDisplay, Accordion, Typography } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { usePersonAbsences } from 'hooks/usePersonAbsences/usePersonAbsences';
import { usePersonData } from 'hooks/usePersonData/usePersonData';
import { AppMessages } from 'i18n/messages';
import { Loader } from 'ui/loader/Loader';
import { addMonthsToDate, formatDate, getCurrentDayStartDate, parseDate } from 'utils/dateUtils';

export const PersonAbsences = () => {
  const { person } = usePersonData();
  const { data, isLoading } = usePersonAbsences({
    personId: String(person.employeeId),
    fromDate: formatDate(getCurrentDayStartDate()),
    toDate: formatDate(addMonthsToDate(getCurrentDayStartDate(), 12)),
  });
  const { formatMessage } = useLocale();
  if (isLoading) {
    return <Loader fullHeight={false} />;
  }

  if (!data) {
    return null;
  }

  return (
    <Accordion defaultExpanded summary={formatMessage({ id: AppMessages['personDetails.accordion.absences'] })}>
      <Accordion.Details>
        <Stack spacing={3}>
          {data.absences?.timePeriod.map(({ from, to, duration }) => (
            <Stack spacing={0.5} key={parseDate(from).getTime()}>
              <DataDisplay
                header={`holiday
                ${formatMessage(
                  { id: AppMessages['personDetails.accordion.absences.daysCount'] },
                  {
                    daysCount: duration,
                  },
                )}`}
              >
                <Typography variant="caption">
                  {formatDate(from)} - {formatDate(to)}
                </Typography>
              </DataDisplay>
            </Stack>
          ))}
        </Stack>
      </Accordion.Details>
    </Accordion>
  );
};
