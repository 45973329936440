import { ProjectDetails } from 'api/actions/getProject/getProject.types';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { checkIsLeadFromHubSpot } from 'utils/checkers/checkIsLeadFromHubSpot';
import { checkIsProjectClosedOrArchived } from 'utils/checkers/checkIsProjectClosedOrArchived';

export const permitProjectEdition = (projectDetails: ProjectDetails) => {
  const { stage } = projectDetails;

  const isClosedOrArchived = checkIsProjectClosedOrArchived(stage as ProjectStage);
  const isLeadFromHubSpot = checkIsLeadFromHubSpot(projectDetails);

  return !isClosedOrArchived && !isLeadFromHubSpot;
};
