import { useEffect, useState } from 'react';

import { Typography, Tooltip, Box } from '@mui/material';
import { ErrorFilledIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import type { PersonRatesDataProps } from './PersonRatesData.types';
import * as styles from './PersonRatesData.styles';

export const PersonRatesData = ({ assignmentRates, currentRate, futureRate, cost }: PersonRatesDataProps) => {
  const { formatMessage } = useLocale();

  const detectHighRateInProject = () =>
    assignmentRates.some(
      (assignmentRate) =>
        assignmentRate.rate < (currentRate?.rates[assignmentRate.currency] || 0) ||
        (futureRate && assignmentRate.rate < futureRate.rates[assignmentRate.currency]),
    );

  const [highRateWarning, setHighRateWarning] = useState(detectHighRateInProject());

  useEffect(() => {
    setHighRateWarning(detectHighRateInProject());
  }, [cost, assignmentRates, currentRate, futureRate]);

  return (
    <Box display="flex" sx={styles.rateBox}>
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Box display="flex" gap={0.5}>
          <Typography variant="caption">{currentRate?.rate || '-'}</Typography>
          <Typography variant="caption" fontWeight={600} color="text.secondary">
            {currentRate?.currency}
          </Typography>

          {futureRate && (
            <Typography variant="caption" fontWeight={600} color="text.secondary">
              {formatMessage({ id: AppMessages['personDetails.accordion.details.personCost.current'] })}
            </Typography>
          )}
        </Box>

        {futureRate && (
          <Box display="flex" gap={0.5}>
            <Typography variant="caption">{futureRate.rate}</Typography>
            <Typography variant="caption" fontWeight={600} color="text.secondary">
              {futureRate.currency}
            </Typography>

            <Typography variant="caption" fontWeight={600} color="text.secondary">
              {formatMessage(
                { id: AppMessages['personDetails.accordion.details.personCost.future'] },
                { appliesFrom: futureRate.appliesFrom },
              )}
            </Typography>
          </Box>
        )}
      </Box>

      {highRateWarning && (
        <Tooltip
          title={formatMessage({ id: AppMessages['personDetails.accordion.details.personCost.higher.message'] })}
          PopperProps={{ sx: styles.highPersonRateTooltip }}
          arrow
          placement="top"
        >
          <div style={{ width: 'fit-content', height: 'fit-content' }}>
            <ErrorFilledIcon fontSize="small" color="error" />
          </div>
        </Tooltip>
      )}
    </Box>
  );
};
