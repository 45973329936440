import { Stack, Box } from '@mui/material';
import { Typography, Button } from 'lux/components';

import { RatesFilter } from 'shared/people/peopleFilters/ratesFilter/RatesFilter';
import { AvailabilityFilter } from 'shared/people/peopleFilters/availabilityFilter/AvailabilityFilter';
import { DepartmentFilter } from 'shared/people/peopleFilters/departmentFilter/DepartmentFilter';
import { SeniorityFilter } from 'shared/people/peopleFilters/seniorityFilter/SeniorityFilter';
import { FteFilter } from 'shared/people/peopleFilters/fteFilter/FteFilter';
import { StatusFilter } from 'shared/people/peopleFilters/statusFilter/StatusFilter';
import { SkillsFilter } from 'shared/people/peopleFilters/skillsFilter/SkillsFilter';
import { EmploymentFilter } from 'shared/people/peopleFilters/employmentFilter/EmploymentFilter';
import { NonDevRolesFilter } from 'shared/people/peopleFilters/nonDevRolesFilter/NonDevRolesFilter';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { usePeopleFilters } from 'hooks/usePeopleFilters/usePeopleFilters';

import * as styles from './PeopleAccordionFilters.styles';

export const PeopleAccordionFilters = () => {
  const { formatMessage } = useLocale();
  const { clearAllFilters } = usePeopleFilters();

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h6">{formatMessage({ id: AppMessages['filters.title'] })}</Typography>
        <Button onClick={() => clearAllFilters(['search'])} size="small" variant="text" color="primary">
          {formatMessage({ id: AppMessages['filters.clearAll'] })}
        </Button>
      </Stack>
      <Box sx={styles.filtersWrapper}>
        <EmploymentFilter />
        <NonDevRolesFilter />
        <DepartmentFilter variant="accordion" />
        <SeniorityFilter variant="accordion" />
        <StatusFilter variant="accordion" />
        <FteFilter variant="accordion" />
        <RatesFilter />
        <AvailabilityFilter />
        <SkillsFilter />
      </Box>
    </>
  );
};
