import { getCurrentDayStartDate, isFutureDate, parseDate } from 'utils/dateUtils';

export const getDefaultRemovalDate = (assignmentEndDate: string | null) => {
  if (!assignmentEndDate) return null;

  const date = parseDate(assignmentEndDate);

  if (isFutureDate(date)) {
    return getCurrentDayStartDate();
  }

  return date;
};
