import { Box } from '@mui/material';
import { Typography } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { RateCardItem } from '../rateCardItem/RateCardItem';
import { sortCaseInsensitive } from 'utils/stringUtils';
import { isFutureDate } from 'utils/dateUtils';
import { AppMessages } from 'i18n/messages';
import { Translation } from 'ui/translation/Translation';

import * as styles from './RateCardItems.styles';
import type { RateCardItemsProps } from './RateCardItems.types';

export const RateCardItems = ({ rateCard, neededRoles, children }: RateCardItemsProps) => {
  const { formatMessage } = useLocale();

  const rateCardItems = Object.keys(rateCard.items);
  const sortedRateCardItems = sortCaseInsensitive(rateCardItems);
  let formattedDate: string;

  if (isFutureDate(new Date(rateCard.appliesFrom))) {
    formattedDate = formatMessage(
      { id: AppMessages['projectDetails.accordions.details.rateCard.date.future'] },
      { appliesFrom: rateCard.appliesFrom },
    );
  } else if (rateCard.appliesUntil) {
    formattedDate = formatMessage(
      { id: AppMessages['projectDetails.accordions.details.rateCard.date.appliesUntil'] },
      { appliesFrom: rateCard.appliesFrom, appliesUntil: rateCard.appliesUntil },
    );
  } else {
    formattedDate = formatMessage(
      { id: AppMessages['projectDetails.accordions.details.rateCard.date.noAppliesUntil'] },
      { appliesFrom: rateCard.appliesFrom },
    );
  }

  const roles = (
    <Typography sx={styles.roles}>
      <Translation id="projectDetails.accordions.details.rateCard.role.junior" />
      <span>/</span>
      <Translation id="projectDetails.accordions.details.rateCard.role.mid" />
      <span>/</span>
      <Translation id="projectDetails.accordions.details.rateCard.role.senior" />
    </Typography>
  );

  return (
    <>
      <Box sx={styles.rateCardItemsWrapper}>
        <Typography sx={styles.dateRange}>{formattedDate}</Typography>

        {roles}

        <Box sx={styles.items}>
          {sortedRateCardItems.map((role) => (
            <RateCardItem key={role} role={role} rateCard={rateCard} neededRoles={neededRoles || []} />
          ))}
        </Box>
        <Box sx={styles.children}>{children}</Box>
      </Box>
    </>
  );
};
