import { useQueryClient } from '@tanstack/react-query';

import { useMutation } from 'hooks/useMutation/useMutation';
import { editUncertainPersonMutation } from 'api/actions/editUncertainPerson/editUncertainPerson';
import { peopleKeys } from 'utils/queryKeys';

export const useEditUncertainPerson = (employeeId: number) => {
  const queryClient = useQueryClient();
  return useMutation(['editUncertainPersonMutation'], editUncertainPersonMutation(employeeId), {
    onSuccess: () => {
      queryClient.invalidateQueries(peopleKeys.peopleList);
      queryClient.invalidateQueries(peopleKeys.singlePerson(String(employeeId)));
    },
  });
};
