import { Divider, Stack } from '@mui/material';

import { FteFilter } from 'shared/needs/needsFilters/fteFilter/FteFilter';
import { RoleFilter } from 'shared/needs/needsFilters/roleFilter/RoleFilter';
import { SeniorityFilter } from 'shared/needs/needsFilters/seniorityFilter/SeniorityFilter';
import { NeedTimeRangeFilter } from 'shared/needs/needsFilters/needTimeRangeFilter/NeedTimeRangeFilter';
import { NeedsActiveFilters } from 'shared/needs/needsFilters/needsActiveFilters/NeedsActiveFilters';
import * as styles from '../../DashboardGrid.styles';
import { TypeFilter } from 'shared/needs/needsFilters/typeFilter/TypeFilter';

export const NeedsFilters = () => (
  <>
    <Stack mb={1} direction="row" flexWrap="wrap" gap={1}>
      <RoleFilter variant="popover" />
      <SeniorityFilter variant="popover" />
      <FteFilter variant="popover" />
      <TypeFilter variant="popover" />
      <NeedTimeRangeFilter variant="popover" />
    </Stack>
    <Divider sx={styles.divider} />
    <NeedsActiveFilters />
  </>
);
