import { forwardRef, MouseEvent, useCallback, useLayoutEffect, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { Avatar, Button, Checkbox, Table, useTable } from 'lux/components';
import { RemoveUserIcon } from 'lux/icons';
import { Link } from 'react-router-dom';

import { addDaysToDate, getCurrentDayStartDate, isDateAfter, parseDate } from 'utils/dateUtils';
import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';
import { AssignmentStatusBadge } from 'ui/assignmentStatusBadge/AssignmentStatusBadge';
import { fteToDecimalFraction } from 'utils/fteToDecimalFraction';
import { formatRoleName } from 'utils/stringUtils';
import { useAssignmentInfo } from 'hooks/useAssignmentInfo/useAssignmentInfo';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { EmojiBadges } from 'ui/emojiBadges/EmojiBadges';
import { PersonTooltip } from 'shared/people/personTooltip/PersonTooltip';
import { useDialog } from 'hooks/useDialog/useDialog';
import { Assignment } from 'api/actions/getPersonAssignments/getPersonAssignments.types';
import { RemoveImmediatelyModal } from 'app/project/teamDetails/tabAssigned/assignmentMenuButton/removeImmediatelyModal/RemoveImmediatelyModal';
import { AssignmentsBulkActionsBar } from 'app/project/teamDetails/tabAssigned/assignmentsTable/assignmentsTableHead/assignmentsBulkActionsBar/AssignmentsBulkActionsBar';
import { AssignmentId } from 'api/types/AssignmentType.enum';

import { AssignmentsTableHead } from './assignmentsTableHead/AssignmentsTableHead';
import { getRows } from './AssignmentsTable.utils';
import * as styles from './AssignmentsTable.styles';
import type { AssignmentsTableProps } from './AssignmentsTable.types';

export const AssignmentsTable = forwardRef<HTMLTableRowElement, AssignmentsTableProps>(({ assignments }, ref) => {
  const { setOpen: setAssignmentInfoOpen, selectedAssignmentId, setClose } = useAssignmentInfo();
  const [assignmentData, setAssignmentData] = useState<Assignment | null>(null);
  const [prevSelected, setPrevSelected] = useState<string[]>([]);
  const rows = getRows(assignments);
  const { selected, isSelected, handleClick, handleSelectAllClick } = useTable(rows);

  useLayoutEffect(() => {
    setPrevSelected([...selected]);
  }, [selected]);

  const handleClickAssignment = (assignmentId: AssignmentId) => {
    if (selectedAssignmentId !== assignmentId) {
      return setAssignmentInfoOpen(assignmentId);
    }

    return setClose();
  };

  const {
    projectDetails: { plannedExtension },
  } = useProjectDetails();

  const {
    isOpen: isRemoveImmediatelyModalOpen,
    setOpen: setRemoveImmediatelyModalOpen,
    setClose: setRemoveImmediatelyModalClose,
  } = useDialog();

  const setRemoval = useCallback((event: MouseEvent, assignment: Assignment) => {
    event.stopPropagation();
    setAssignmentData(assignment);
    setRemoveImmediatelyModalOpen();
  }, []);

  const isAssignmentOutdated = (endDate: string) => {
    const dayPastAssignment = addDaysToDate(parseDate(endDate), 1);
    return !isDateAfter(dayPastAssignment, getCurrentDayStartDate());
  };

  const handleSelect = (event: MouseEvent, name: string) => {
    event.stopPropagation();
    handleClick(event, name);
  };

  const clearSelected = () => {
    selected.clear();
  };

  const isPlannedMemberExtension = assignments.some((assignment) => assignment.extensionDate);

  return (
    <>
      <Table sx={{ position: 'relative', overflowX: 'unset' }}>
        <Table.Table>
          {!!selected.size ? (
            <AssignmentsBulkActionsBar
              rows={rows}
              selected={selected}
              assignments={assignments}
              onBeforeRemoveAssignments={clearSelected}
              onSelectAll={handleSelectAllClick}
            />
          ) : (
            <AssignmentsTableHead rows={rows} selected={selected} onSelectAll={handleSelectAllClick} />
          )}
          <Table.Body data-cy="assigment-table_body">
            {assignments.map((assignment, index) => {
              const row = rows.find((row) => row.assignmentId === assignment.id)?.name;
              const prevRow = prevSelected.find((name) => name === row);
              const isItemSelected = isSelected(row as string) || !!prevRow;

              return (
                <Table.Row
                  ref={index === assignments.length - 1 ? ref : undefined}
                  hover={selectedAssignmentId !== assignment.id}
                  key={assignment.id}
                  onClick={() => handleClickAssignment(assignment.id)}
                  sx={{
                    ...styles.tableRow,
                    backgroundColor: selectedAssignmentId === assignment.id ? 'primary.light' : 'transparent',
                  }}
                  selected={isItemSelected}
                >
                  <Table.Cell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      onClick={(e) => handleSelect(e, row as string)}
                    />
                  </Table.Cell>
                  <Table.Cell sx={styles.employeeCell}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Stack
                        component={Link}
                        to={`/people/${assignment.person.employeeId}`}
                        sx={styles.employeeLink}
                        target="_blank"
                        direction="row"
                        alignItems="center"
                        gap={1}
                      >
                        <PersonTooltip employeeId={assignment.person.employeeId}>
                          <Box>
                            <Avatar
                              size="sm"
                              alt={`${assignment.person.firstName} ${assignment.person.lastName}`}
                              image={assignment.person.picture}
                            />
                          </Box>
                        </PersonTooltip>
                        {assignment.person.firstName} {assignment.person.lastName}
                      </Stack>
                      <EmojiBadges
                        isLeader={assignment.isLeader}
                        employmentType={assignment.person.employmentType}
                        proposalCount={assignment.person.proposalCount}
                        employedTo={assignment.person.employedTo}
                        absences={assignment.person.absences}
                        assignment={assignment}
                      />
                    </Stack>
                  </Table.Cell>
                  <Table.Cell>{fteToDecimalFraction(assignment.fte)}</Table.Cell>
                  <Table.Cell>{formatRoleName(assignment.role, assignment.seniority)}</Table.Cell>
                  <Table.Cell>{assignment.startDate}</Table.Cell>
                  <Table.Cell>{assignment.endDate ? assignment.endDate : '-'}</Table.Cell>
                  {(plannedExtension || isPlannedMemberExtension) && (
                    <Table.Cell>{assignment.extensionDate ? assignment.extensionDate : '-'}</Table.Cell>
                  )}
                  <Table.Cell>
                    {[AssignmentStatus.leaving, AssignmentStatus.temporary, AssignmentStatus.preassigned].includes(
                      assignment.status,
                    ) && <AssignmentStatusBadge status={assignment.status} />}
                  </Table.Cell>
                  <Table.Cell align="right">
                    {assignment.endDate && isAssignmentOutdated(assignment.endDate) && (
                      <Button
                        iconOnly
                        variant="outlined"
                        color="error"
                        sx={{ width: 40, height: 40 }}
                        onClick={(event: MouseEvent) => setRemoval(event, assignment)}
                      >
                        <RemoveUserIcon sx={styles.errorIcon} color="error" />
                      </Button>
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Table>
      </Table>
      {assignmentData && (
        <RemoveImmediatelyModal
          assignmentData={assignmentData}
          open={isRemoveImmediatelyModalOpen}
          onClose={setRemoveImmediatelyModalClose}
        />
      )}
    </>
  );
});
