import { Stack } from '@mui/material';
import { DateTimePicker, Typography, TextField } from 'lux/components';
import { ClockIcon } from 'lux/icons';

import { DEFAULT_DATE_FORMAT, ZERO_UNIX_DATE, MAX_SUPPORTED_DATE, parseDate } from 'utils/dateUtils';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useNeedsFilters } from 'hooks/useNeedsFilters/useNeedsFilters';
import { Filter } from 'ui/filter/Filter';
import { useAvailabilityRange } from 'hooks/useAvailabilityRange/useAvailabilityRange';

import { NeedTimeRangeFilterProps } from './NeedTimeRangeFilter.types';

export const NeedTimeRangeFilter = ({ variant }: NeedTimeRangeFilterProps) => {
  const { formatMessage } = useLocale();

  const { filters, setFilterValue, clearFilter } = useNeedsFilters();
  const { values, clear, errors, onDateChange } = useAvailabilityRange({
    filters,
    setFilter: setFilterValue,
    clearFilter,
  });

  return (
    <Filter
      variant={variant}
      icon={<ClockIcon />}
      label={formatMessage({ id: AppMessages['needs.filters.availability.label'] })}
      popoverTitle={formatMessage({ id: AppMessages['needs.filters.availability.popover.title'] })}
      popoverActionLabel={formatMessage({ id: AppMessages['filters.clear'] })}
      valueSelected={Boolean(filters.availabilityFrom) || Boolean(filters.availabilityTo)}
      onPopoverActionClick={clear}
    >
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <DateTimePicker
          label={formatMessage({ id: AppMessages['filters.availability.from'] })}
          value={values.availabilityFrom}
          views={['day']}
          minDate={ZERO_UNIX_DATE}
          maxDate={MAX_SUPPORTED_DATE}
          InputProps={{ error: errors.availabilityFrom }}
          onChange={(newValue) => onDateChange(newValue, 'availabilityFrom')}
          renderInput={(params) => (
            <TextField fullWidth={true} {...params} size="small" error={errors.availabilityFrom} />
          )}
          inputFormat={DEFAULT_DATE_FORMAT}
          shouldDisableDate={(date) => {
            if (filters.availabilityTo) {
              return new Date(date).getTime() > parseDate(filters.availabilityTo).getTime();
            }
            return false;
          }}
        />
        <Typography color="text.secondary">-</Typography>
        <DateTimePicker
          label={formatMessage({ id: AppMessages['filters.availability.to'] })}
          value={values.availabilityTo}
          views={['day']}
          minDate={ZERO_UNIX_DATE}
          maxDate={MAX_SUPPORTED_DATE}
          InputProps={{
            error: errors.availabilityTo,
          }}
          onChange={(newValue) => onDateChange(newValue, 'availabilityTo')}
          renderInput={(params) => (
            <TextField fullWidth={true} {...params} size="small" error={errors.availabilityTo} />
          )}
          inputFormat={DEFAULT_DATE_FORMAT}
          shouldDisableDate={(date) => {
            if (filters.availabilityFrom) {
              return new Date(date).getTime() < parseDate(filters.availabilityFrom).getTime();
            }
            return false;
          }}
        />
      </Stack>
    </Filter>
  );
};
