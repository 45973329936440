export enum ProjectType {
  greenfield = 'Greenfield',
  existingCode = 'Existing code',
  projectOutsourcing = 'Project outsourcing',
  peopleOutsourcing = 'People outsourcing',
  internal = 'Internal',
  workshop = 'Workshop',
  fixedPrice = 'Fixed Price',
  timeAndMaterial = 'Time and Material',
}
