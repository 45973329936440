import { DepartmentCode } from 'api/types/DepartmentCode.enum';
import { Seniority } from 'api/types/Seniority.enum';
import { Translation } from 'i18n/messages';
import { Fte } from '../fteList';
import { NeedsFilters } from 'context/needsFilters/needsFiltersContext/NeedsFiltersContext.types';
import { needsSortingOptions } from 'context/needsFilters/needsFiltersContextController/NeedsFiltersContextController';
import { NeedType } from 'api/types/NeedType.enum';

type FilterOption = {
  labelTranslation: Translation;
  value: string;
};

type NeedsFiltersData = Record<
  keyof Pick<NeedsFilters, 'sort' | 'seniorities' | 'roles' | 'fte' | 'type'>,
  FilterOption[]
>;

export const filtersData: NeedsFiltersData = {
  sort: [
    {
      labelTranslation: 'sorting.startDateAsc',
      value: needsSortingOptions.startDateASC,
    },
    {
      labelTranslation: 'sorting.startDateDesc',
      value: needsSortingOptions.startDateDESC,
    },
    {
      labelTranslation: 'sorting.needStartDateAsc',
      value: needsSortingOptions.createdAtASC,
    },
    {
      labelTranslation: 'sorting.needStartDateDesc',
      value: needsSortingOptions.createdAtDESC,
    },
    {
      labelTranslation: 'sorting.projectNameAsc',
      value: needsSortingOptions.nameASC,
    },
    {
      labelTranslation: 'sorting.projectNameDesc',
      value: needsSortingOptions.nameDESC,
    },
  ],
  seniorities: [
    {
      labelTranslation: 'filters.seniority.option.junior',
      value: Seniority.junior,
    },
    {
      labelTranslation: 'filters.seniority.option.mid',
      value: Seniority.mid,
    },
    {
      labelTranslation: 'filters.seniority.option.senior',
      value: Seniority.senior,
    },
    {
      labelTranslation: 'filters.seniority.option.notSpecified',
      value: Seniority.withoutLevel,
    },
  ],
  roles: [
    {
      labelTranslation: 'filters.role.option.front',
      value: DepartmentCode.front,
    },
    {
      labelTranslation: 'filters.role.option.node',
      value: DepartmentCode.node,
    },
    {
      labelTranslation: 'filters.role.option.java',
      value: DepartmentCode.java,
    },
    {
      labelTranslation: 'filters.role.option.archit',
      value: DepartmentCode.archit,
    },
    {
      labelTranslation: 'filters.role.option.devops',
      value: DepartmentCode.devops,
    },
    {
      labelTranslation: 'filters.role.option.php',
      value: DepartmentCode.php,
    },
    {
      labelTranslation: 'filters.role.option.pm',
      value: DepartmentCode.pm,
    },
    {
      labelTranslation: 'filters.role.option.qa',
      value: DepartmentCode.qa,
    },
    {
      labelTranslation: 'filters.role.option.qaa',
      value: DepartmentCode.qaa,
    },
    {
      labelTranslation: 'filters.role.option.ba',
      value: DepartmentCode.ba,
    },
    {
      labelTranslation: 'filters.role.option.nmd',
      value: DepartmentCode.nmd,
    },
    {
      labelTranslation: 'filters.role.option.others',
      value: DepartmentCode.others,
    },
  ],
  fte: [
    {
      labelTranslation: 'filters.fte.option.fullTime',
      value: Fte.fullTime,
    },
    {
      labelTranslation: 'filters.fte.option.halfTime',
      value: Fte.halfTime,
    },
  ],
  type: [
    {
      labelTranslation: 'filters.type.option.assignemnt',
      value: NeedType.assignment,
    },
    {
      labelTranslation: 'filters.type.option.replacement',
      value: NeedType.replacement,
    },
  ],
};
