import { Styles } from 'styles/theme';

import { StepperSize } from './Stepper.types';

export const stepper = (size: StepperSize): Styles => ({
  ...(size === 'small' && {
    '.MuiStepIcon-root.MuiStepIcon-root': {
      width: 16,
      height: 16,
    },
    '.MuiStepConnector-root': {
      marginLeft: 2,
    },
    '.MuiStepIcon-text': {
      fontSize: '1.125rem',
    },

    '.MuiStepLabel-label': {
      typography: 'body1',
    },
  }),
});
