import { TimesheetTableHead } from './timesheetTableHead/TimesheetTableHead';
import { TimesheetTableBody } from './timesheetTableBody/TimesheetTableBody';
import { Table, TableWrapper } from './tableComponents/tableComponents';
import { TimesheetTableProps } from './TimesheetTable.types';

export const TimesheetTable = ({ showTsHeading, isClientView }: TimesheetTableProps) => (
  <TableWrapper>
    <Table>
      {showTsHeading && <TimesheetTableHead />}
      <TimesheetTableBody isClientView={isClientView} />
    </Table>
  </TableWrapper>
);
