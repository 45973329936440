import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { AssignmentsRemovalArguments, AssignmentsRemovalResponse } from './assignmentsRemoval.types';

export const assignmentsRemovalMutation: (
  projectId: string,
) => MutationFn<AssignmentsRemovalArguments, AssignmentsRemovalResponse> = (projectId: string) => (body) => ({
  endpoint: `/projects/${projectId}/assignments/leave`,
  method: 'PATCH',
  params: body,
});
