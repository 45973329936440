import { useMemo } from 'react';

import { Avatar } from 'lux/components';

import { WorkStatementAssignment } from 'api/types/BillingSummary.types';

import { PickRandomPictures, RandomAvatarsProps } from './RandomAvatars.types';
import * as styles from './RandomAvatars.styles';

const MAX_AMOUNT_OF_AVATARS = 4;

const getRandomAssignments: PickRandomPictures = (initAssignments) => {
  let assignments: WorkStatementAssignment[] = [...initAssignments];
  const randomAssignments: WorkStatementAssignment[] = [];
  const length = initAssignments.length > MAX_AMOUNT_OF_AVATARS ? MAX_AMOUNT_OF_AVATARS : initAssignments.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * assignments.length);
    const randomAssignment = assignments[randomIndex];
    randomAssignments.push(randomAssignment);
    assignments = assignments.filter((assignment) => assignment.employeeId !== randomAssignment.employeeId);
  }

  return randomAssignments.map((assignment) => ({
    id: `avatar-${assignment.employeeId}`,
    fullName: `${assignment.firstName} ${assignment.lastName}`,
    picture: assignment.picture,
  }));
};

export const RandomAvatars = ({ assignments }: RandomAvatarsProps) => {
  const randomMembers = useMemo(() => getRandomAssignments(assignments), [assignments]);

  return (
    <>
      {randomMembers.map(({ picture, id, fullName }, idx) => (
        <Avatar size="sm" key={id} image={picture} alt={fullName} sx={styles.avatar(idx)} />
      ))}
    </>
  );
};
