import { Stack } from '@mui/material';

import { AssignmentTimeRangeFilter } from 'shared/assignments/assignmentsFilers/assignmentsTimeRangeFilter/AssignmentsTimeRangeFilter';
import { AssignmentSorting } from 'shared/assignments/assignmentsSorting/AssignmentsSorting';

export const AssignmentsFilters = () => (
  <Stack direction="row" spacing={4}>
    <AssignmentSorting />
    <AssignmentTimeRangeFilter />
  </Stack>
);
