import { Typography } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { RateProps } from './Rate.types';

export const Rate = ({ currency, rate }: RateProps) => {
  const { formatMessage } = useLocale();

  if (!rate) {
    return (
      <Typography variant="caption" fontWeight={600} color="error.dark">
        {formatMessage({ id: AppMessages['projectDetails.rate.notDefined'] })}
      </Typography>
    );
  }

  return (
    <Typography variant="caption" fontWeight={600}>
      {rate} {currency}
    </Typography>
  );
};
