import Joi from 'joi';

import { AppMessages } from 'i18n/messages';
import { intl } from 'context/locale/intl/Intl';

export const multipleAutocompleteEditSchema = (name: string) => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object({
    [name]: Joi.array()
      .items(Joi.string())
      .min(1)
      .messages({ 'array.min': formatMessage({ id: AppMessages['validation.empty'] }, { label: name }) }),
  });
};
