import { IconButton, Stack } from '@mui/material';
import { Typography, Button, Grid } from 'lux/components';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { AddIcon, CheckIcon, CloseIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Autocomplete } from 'ui/form/autocomplete/Autocomplete';
import { Select } from 'ui/form/select/Select';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { weightList } from '../data/weight';
import { NeedFormDto } from '../Need.types';
import { useSkills } from 'hooks/useSkills/useSkills';
import { mapToAutocompleteOptions } from 'ui/form/autocomplete/Autocomplete.functions';
import { SquareIconButton } from 'ui/squareIconButton/SquareIconButton';
import { Translation } from 'ui/translation/Translation';

import { SkillSetsProps } from './SkillSets.types';
import * as styles from './SkillSets.styles';

export const SkillSets = ({ loading }: SkillSetsProps) => {
  const { watch, control, trigger, formState, setError } = useFormContext<NeedFormDto>();
  const { formatMessage } = useLocale();
  const { data: skills, isLoading: isSkillsLoading } = useSkills();

  const { fields, remove, append, update } = useFieldArray({
    control,
    name: 'skillSet',
  });

  const updateSkill = async (index: number) => {
    await trigger('skillSet');

    const skillSetErrors = formState.errors.skillSet;

    if (!skillSetErrors) {
      triggerUpdate(index, false);
      return;
    }

    if (skillSetErrors[index] && skillSetErrors[index]?.type === 'array.unique') {
      setError(`skillSet.${index}.name`, { message: skillSetErrors[index]?.message });
    }
  };

  const triggerUpdate = (index: number, isDraft: boolean) =>
    update(index, {
      name: watch(`skillSet.${index}.name`),
      weight: watch(`skillSet.${index}.weight`),
      note: watch(`skillSet.${index}.note`),
      isDraft,
    });

  const getFilteredSkills = () =>
    mapToAutocompleteOptions((skills || []).filter((skill) => !fields.map((field) => field.name).includes(skill)));

  return (
    <>
      {fields.length > 0 && (
        <Stack sx={styles.skillSetsWrapper}>
          <Grid container gutter={0} sx={styles.baseSkillsGrid}>
            <Grid item>
              <Typography color="text.secondary" fontWeight={600} variant="caption">
                <Translation id="requestNeed.table.header.name" />
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="text.secondary" fontWeight={600} variant="caption">
                <Translation id="requestNeed.table.header.weight" />
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="text.secondary" fontWeight={600} variant="caption">
                <Translation id="requestNeed.table.header.note" />
              </Typography>
            </Grid>
            <Grid item />
          </Grid>
          {fields.map((item, index) =>
            item.isDraft ? (
              <Grid key={`${item.id}-${index}`} container gutter={0} sx={styles.editableSkillsGrid}>
                <Grid item>
                  <Autocomplete
                    name={`skillSet.${index}.name`}
                    control={control}
                    label={formatMessage({ id: AppMessages['need.form.skillSet.name'] })}
                    size="small"
                    fullWidth
                    options={getFilteredSkills()}
                    loading={isSkillsLoading}
                    freeSolo={true}
                    required
                    disabled={loading}
                  />
                </Grid>
                <Grid item>
                  <Select
                    name={`skillSet.${index}.weight`}
                    control={control}
                    label={formatMessage({ id: AppMessages['need.form.skillSet.weight'] })}
                    size="small"
                    fullWidth
                    required
                    options={weightList}
                    isDisabled={loading}
                  />
                </Grid>
                <Grid item>
                  <FormTextField
                    size="small"
                    name={`skillSet.${index}.note`}
                    control={control}
                    fullWidth
                    label={formatMessage({ id: AppMessages['need.form.skillSet.note'] })}
                    isDisabled={loading}
                  />
                </Grid>
                <Grid item>
                  <SquareIconButton size="small" color="error" onClick={() => remove(index)}>
                    <CloseIcon />
                  </SquareIconButton>
                  <SquareIconButton size="small" color="primary" onClick={() => updateSkill(index)}>
                    <CheckIcon />
                  </SquareIconButton>
                </Grid>
              </Grid>
            ) : (
              <Grid key={`${item.id}-${index}`} container gutter={0} sx={styles.previewSkillsGrid}>
                <Grid item>
                  <Typography variant="caption">{item.name}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">{item.weight}</Typography>
                </Grid>
                <Grid item>{item?.note && <Typography variant="caption">{item.note}</Typography>}</Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    color="primary"
                    sx={styles.editSkillButton}
                    onClick={() => triggerUpdate(index, true)}
                  >
                    <Translation id="requestNeed.table.row.edit" />
                  </IconButton>
                </Grid>
              </Grid>
            ),
          )}
        </Stack>
      )}

      <Button
        sx={styles.addSkillsButton}
        size="large"
        variant="text"
        startIcon={<AddIcon />}
        onClick={() => append({ name: '', weight: '', note: '', isDraft: true })}
      >
        {formatMessage({ id: AppMessages['button.addSkills'] })}
      </Button>
    </>
  );
};
