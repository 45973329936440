import { Stack, Typography } from '@mui/material';
import { DateTimePicker, TextField } from 'lux/components';

import { DEFAULT_DATE_FORMAT, ZERO_UNIX_DATE, MAX_SUPPORTED_DATE, parseDate } from 'utils/dateUtils';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useAssignmentsFilters } from 'hooks/useAssignmentsFilters/useAssignmentsFilters';
import { useTimeRange } from 'hooks/useTimeRange/useTimeRange';

import * as styles from './AssignmentsTimeRangeFilter.styles';

export const AssignmentTimeRangeFilter = () => {
  const { formatMessage } = useLocale();

  const { filters, setFilterValue, clearFilter } = useAssignmentsFilters();
  const { values, errors, onDateChange } = useTimeRange({ filters, setFilter: setFilterValue, clearFilter });

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <DateTimePicker
        label={formatMessage({ id: AppMessages['filters.availability.from'] })}
        value={values.startDate}
        minDate={ZERO_UNIX_DATE}
        maxDate={MAX_SUPPORTED_DATE}
        InputProps={{ error: errors.startDate }}
        views={['day']}
        onChange={(newValue) => onDateChange(newValue, 'startDate')}
        renderInput={(params) => (
          <TextField fullWidth={false} {...params} size="small" sx={styles.datePicker} error={errors.startDate} />
        )}
        inputFormat={DEFAULT_DATE_FORMAT}
        shouldDisableDate={(date) => {
          if (filters.endDate) {
            return new Date(date).getTime() > parseDate(filters.endDate).getTime();
          }
          return false;
        }}
      />
      <Typography color="text.secondary">-</Typography>
      <DateTimePicker
        label={formatMessage({ id: AppMessages['filters.availability.to'] })}
        value={values.endDate}
        minDate={ZERO_UNIX_DATE}
        maxDate={MAX_SUPPORTED_DATE}
        InputProps={{ error: errors.endDate }}
        views={['day']}
        onChange={(newValue) => onDateChange(newValue, 'endDate')}
        renderInput={(params) => (
          <TextField fullWidth={false} {...params} size="small" sx={styles.datePicker} error={errors.endDate} />
        )}
        inputFormat={DEFAULT_DATE_FORMAT}
        shouldDisableDate={(date) => {
          if (filters.startDate) {
            return new Date(date).getTime() < parseDate(filters.startDate).getTime();
          }
          return false;
        }}
      />
    </Stack>
  );
};
