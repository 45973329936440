import { Styles } from 'styles/theme';

export const header: Styles = {
  padding: 1,
  borderRadius: 1,

  '&:hover': {
    backgroundColor: 'primary.light',
  },
};

export const addPeopleButton: Styles = {
  marginTop: 0.5,
};
