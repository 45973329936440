import { useSnackbar } from 'notistack';

import { refreshEmployees } from '../../api/actions/refreshEmployees/refreshEmployees';
import { useMutation } from '../useMutation/useMutation';

export type RefreshPeopleType = 'data' | 'skills' | 'status';

export const useRefreshEmployee = (type: RefreshPeopleType) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isLoading } = useMutation([`refreshEmployee-${type}`], refreshEmployees(type), {
    onSuccess: () => {
      enqueueSnackbar('Refresh request was sent', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Refresh request failed', { variant: 'error' });
    },
  });

  return {
    refreshEmployee: mutate,
    isRefreshing: isLoading,
  };
};
