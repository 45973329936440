import { useAuth } from 'hooks/useAuth/useAuth';
import { useInfiniteQuery } from 'hooks/useInfiniteQuery/useInfiniteQuery';
import { GetAssignmentsParams, GetAssignmentsResponse } from 'api/actions/getAssignments/getAssignments.types';
import { getInfiniteAssignmentsQuery } from 'api/actions/getAssignments/getAssignments';
import { assignmentsKeys } from 'utils/queryKeys';
import { getNextPageParam } from 'api/utils/getNextPageParam';
import { parseSortFilter } from 'utils/filters/filters';

import { UseAssignmentsParams } from './useAssignments.types';

export const useAssignments = (params: UseAssignmentsParams) => {
  const { isAuthenticated } = useAuth();
  const queryKey = [...assignmentsKeys.assignmentsList, params];

  let args: GetAssignmentsParams = { limit: params.size || 25 };

  if (params.person) {
    args = { ...args, person: params.person };
  }

  if (params.project) {
    args = { ...args, project: params.project };
  }

  if (params.status) {
    args = { ...args, status: params.status };
  }

  if (params?.owner) {
    args = { ...args, owner: params.owner };
  }

  if (params?.sort) {
    const { field, order } = parseSortFilter(params.sort);

    args = {
      ...args,
      [`sort[${field}]`]: order,
    };
  }

  if (params?.roles) {
    args = { ...args, role: params.roles };
  }

  if (params?.seniorities) {
    args = { ...args, seniority: params.seniorities };
  }

  if (params?.fte) {
    args = { ...args, fte: Number(params.fte) };
  }

  if (params?.startDate) {
    args = { ...args, start_date: params.startDate };
  }

  if (params?.endDate) {
    args = { ...args, end_date: params.endDate };
  }

  return useInfiniteQuery<GetAssignmentsParams, GetAssignmentsResponse>(queryKey, getInfiniteAssignmentsQuery, {
    cursorKey: 'page',
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    startPage: 1,
    args,
    getNextPageParam: getNextPageParam,
  });
};
