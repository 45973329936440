import { Box } from '@mui/material';
import { AddUserIcon } from 'lux/icons';
import { Tooltip } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { SquareIconButton } from 'ui/squareIconButton/SquareIconButton';

import { AssignButtonProps } from './AssignButton.types';
import * as styles from './AssignButton.styles';

export const AssignButton = ({ onClick, disabled }: AssignButtonProps) => {
  const { formatMessage } = useLocale();

  return (
    <Tooltip
      title={
        disabled
          ? formatMessage({
              id: AppMessages['projectDetails.accordions.peopleInProject.peopleActionsModal.personAlreadyAssigned'],
            })
          : ''
      }
      placement="top"
      arrow
      disableFocusListener
      componentsProps={{
        tooltip: {
          sx: styles.tooltip,
        },
      }}
    >
      <Box display="flex" justifyContent="center">
        <SquareIconButton onClick={onClick} size="small" variant="contained" disabled={disabled}>
          <AddUserIcon />
        </SquareIconButton>
      </Box>
    </Tooltip>
  );
};
