import { Box } from '@mui/material';
import { AddIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useTitle } from 'hooks/useTitle/useTitle';
import { AppMessages } from 'i18n/messages';
import { PageHeader } from 'ui/pageHeader/PageHeader';

import { NeedsGrid } from './needsGrid/NeedsGrid';
import * as styles from './Needs.styles';

export const Needs = () => {
  const { formatMessage } = useLocale();

  useTitle(formatMessage({ id: AppMessages['title.needs'] }));

  return (
    <Box sx={styles.container}>
      <PageHeader
        title={formatMessage({ id: AppMessages['needs.header.title'] })}
        actionButton={{
          actionTitle: formatMessage({ id: AppMessages['needs.button.addNeed'] }),
          icon: <AddIcon />,
          disabled: true,
          tooltip: formatMessage({ id: AppMessages['needs.button.addNeed.tooltip'] }),
          tooltipPlacement: 'left',
        }}
      />
      <NeedsGrid />
    </Box>
  );
};
