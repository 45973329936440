import { Styles } from 'styles/theme';

export const cardWrapper: Styles = {
  width: '100%',
  padding: 2,
  border: 'unset',
  borderRadius: 2,
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: 'primary.light',
  },
};
