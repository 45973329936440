import { Stack } from '@mui/material';
import { Table } from 'lux/components';

import { Skeleton } from 'ui/skeleton/Skeleton';
import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

import { AssignmentsTableHead } from './assignmentsTableHead/AssignmentsTableHead';
import * as styles from './AssignmentsTable.styles';

export const AssignmentsTableSkeleton = () => (
  <Table>
    <Table.Table>
      <AssignmentsTableHead rows={[]} selected={new Set<string>()} onSelectAll={() => {}} isHeadBoxDisabled />
      <Table.Body>
        {Array(6)
          .fill(null)
          .map((_, index) => (
            <Table.Row key={index} sx={styles.tableRow}>
              <Table.Cell width={44}>
                <Stack alignItems="center" justifyContent="center">
                  <Skeleton width={20} height={20} />
                </Stack>
              </Table.Cell>
              <Table.Cell sx={styles.employeeCell}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Stack sx={styles.employeeLink} direction="row" alignItems="center" gap={1}>
                    <Skeleton circular width={32} height={32} />
                    <TextSkeleton width={120} variant="body2" />
                  </Stack>
                </Stack>
              </Table.Cell>
              <Table.Cell width={85}>
                <TextSkeleton width={16} variant="subtitle2" />
              </Table.Cell>
              <Table.Cell width={200}>
                <TextSkeleton width={152} variant="subtitle2" />
              </Table.Cell>
              <Table.Cell width={130}>
                <TextSkeleton width={76} variant="subtitle2" />
              </Table.Cell>
              <Table.Cell width={130}>
                <TextSkeleton width={76} variant="subtitle2" />
              </Table.Cell>
              <Table.Cell width={111}></Table.Cell>
              <Table.Cell width={44}></Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table.Table>
  </Table>
);
