import Joi from 'joi';

import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';
import { MAX_SUPPORTED_DATE, ZERO_UNIX_DATE, formatDate } from 'utils/dateUtils';

export const datePickerEditSchema = (name: string, label: string, required?: boolean) => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object({
    [name]: required
      ? Joi.date()
          .required()
          .min(ZERO_UNIX_DATE)
          .max(MAX_SUPPORTED_DATE)
          .messages({
            'date.base': formatMessage({ id: AppMessages['validation.empty'] }, { label }),
            'date.min': formatMessage(
              {
                id: AppMessages['validation.greater'],
              },
              {
                label,
                limit: formatDate(ZERO_UNIX_DATE),
              },
            ),
            'date.max': formatMessage(
              {
                id: AppMessages['validation.max'],
              },
              {
                label,
                limit: formatDate(MAX_SUPPORTED_DATE),
              },
            ),
          })
      : Joi.date()
          .allow(null)
          .optional()
          .min(ZERO_UNIX_DATE)
          .max(MAX_SUPPORTED_DATE)
          .messages({
            'date.base': formatMessage({ id: AppMessages['validation.empty'] }, { label }),
            'date.min': formatMessage(
              {
                id: AppMessages['validation.greater'],
              },
              {
                label,
                limit: formatDate(ZERO_UNIX_DATE),
              },
            ),
            'date.max': formatMessage(
              {
                id: AppMessages['validation.max'],
              },
              {
                label,
                limit: formatDate(MAX_SUPPORTED_DATE),
              },
            ),
          }),
  });
};
