import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import { Card } from 'ui/card/Card';
import { EmployeeCardHeader } from 'app/employee/card/header/EmployeeCardHeader';
import { EmployeeCardFooter } from 'app/employee/card/footer/EmployeeCardFooter';
import * as employeeCardStyles from 'app/employee/card/EmployeeCard.styles';

import { ProposalCardButtons } from './proposalCardButtons/ProposalCardButtons';
import * as styles from './ProposalCard.styles';
import type { ProposalCardProps } from './ProposalCard.types';
import { isRejection } from './ProposalCard.utils';

export const ProposalCard = (props: ProposalCardProps) => {
  const { status, proposal } = props;

  return (
    <Card sx={styles.card}>
      <Box
        component={Link}
        to={`/people/${proposal.person.employeeId}`}
        target="_blank"
        sx={employeeCardStyles.employeeCardWrapper}
      >
        <Card.Header>
          <EmployeeCardHeader
            employee={proposal.person}
            status={status}
            availabilityStatus={proposal.person.availabilityStatus}
            rejectionDate={isRejection(proposal) ? new Date(proposal.rejectionDate) : null}
            {...(isRejection(proposal) && {
              rejectionSource: proposal.source,
              disabled: true,
            })}
          />
        </Card.Header>
      </Box>

      <EmployeeCardFooter
        skills={proposal.person.skills}
        {...(isRejection(proposal) && {
          reason: proposal.reason,
        })}
      />

      <Card.Footer>
        <ProposalCardButtons proposal={proposal} status={status} />
      </Card.Footer>
    </Card>
  );
};
