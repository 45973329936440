import { MappedRateCard } from 'api/actions/getProject/getProject.types';
import { Seniority } from 'api/types/Seniority.enum';
import { sortCaseInsensitive } from 'utils/stringUtils';

import { EditableRateCard } from './UpdateRateCardContent.types';

export const mapRateCardFormItems = (rates: MappedRateCard) => {
  const sortedRoles = sortCaseInsensitive(Object.keys(rates));

  const rolesItems: EditableRateCard[] = sortedRoles.map((role) => ({
    role,
    juniorRate: String(rates[role].find((rate) => rate.seniority === Seniority.junior)?.amount ?? ''),
    regularRate: String(rates[role].find((rate) => rate.seniority === Seniority.mid)?.amount ?? ''),
    seniorRate: String(rates[role].find((rate) => rate.seniority === Seniority.senior)?.amount ?? ''),
    withoutLevelRate: String(rates[role].find((rate) => rate.seniority === Seniority.withoutLevel)?.amount ?? ''),
    seniorityEnabled: !rates[role].find((rate) => rate.seniority === Seniority.withoutLevel),
    isDraft: false,
  }));

  return rolesItems;
};
