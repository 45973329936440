import { Box } from '@mui/material';
import { Avatar, Button, Typography } from 'lux/components';
import { DeleteIcon } from 'lux/icons';

import { useEditKeyRoles } from 'hooks/useEditKeyRoles/useEditKeyRoles';
import { mapKeyRoles } from '../../PeopleInProject.utils';

import * as styles from './RoleAssignedPerson.styles';
import { RoleAssignedPersonProps } from './RoleAssignedPerson.types';

export const RoleAssignedPerson = ({
  employeeId,
  firstName,
  lastName,
  picture,
  sectionId,
  projectId,
  keyRoles,
}: RoleAssignedPersonProps) => {
  const { mutateAsync } = useEditKeyRoles(projectId);

  const handleUserDelete = async () => {
    const filteredKeyRoles = keyRoles.map((keyRole) => ({
      ...keyRole,
      people:
        keyRole.id === sectionId ? keyRole.people.filter((person) => person.employeeId !== employeeId) : keyRole.people,
    }));

    await mutateAsync({ keyRoleItems: mapKeyRoles(filteredKeyRoles) });
  };

  return (
    <Box sx={styles.personContainer}>
      <Avatar size="sm" image={picture} alt={`${firstName} ${lastName}`} />
      <Typography variant="caption">{`${firstName} ${lastName}`}</Typography>
      <Button
        onClick={handleUserDelete}
        variant="text"
        size="small"
        disableRipple
        iconOnly
        color="primary"
        sx={styles.deleteButton}
      >
        <DeleteIcon color="primary" />
      </Button>
    </Box>
  );
};
