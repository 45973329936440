import { useEffect, useState } from 'react';

import { FormControlLabel } from '@mui/material';
import { Radio } from 'lux/components';
import { EndDateIcon } from 'lux/icons';

import { Filter } from 'ui/filter/Filter';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { DebouncedTextField } from 'ui/debouncedTextField/DebouncedTextField';
import { Fte } from 'config/data/fteList';
import { createNumberKeyDownHandler } from 'utils/createNumberKeyDownHandler';

import * as styles from './FteFilter.styles';
import { FteFilterProps as SharedFteFilterProps } from './FteFilter.types';

export const FteFilter = ({ variant, fte, dataCy, onFilterChange, onClear }: SharedFteFilterProps) => {
  const { formatMessage } = useLocale();
  const [otherSelected, setOtherSelected] = useState(false);

  const handleOtherChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onFilterChange(event.target.value);
  };

  const handleFilterClick = (value: string) => {
    setOtherSelected(false);
    if (otherSelected && fte === value) {
      return;
    }

    onFilterChange(value);
  };

  const handleOtherSelect = () => {
    setOtherSelected((prev) => {
      if (prev) {
        onClear();
      }
      return !prev;
    });
  };

  const handleClearFte = () => {
    setOtherSelected(false);
    onClear();
  };

  useEffect(() => {
    if (fte !== '' && fte !== Fte.halfTime && fte !== Fte.fullTime) {
      setOtherSelected(true);
    }

    if (fte === '') {
      setOtherSelected(false);
    }
  }, [fte]);

  return (
    <Filter
      variant={variant}
      icon={<EndDateIcon />}
      dataCy={dataCy}
      label={formatMessage({ id: AppMessages['filters.fte.label'] })}
      popoverTitle={formatMessage({ id: AppMessages['filters.fte.popover.title'] })}
      popoverActionLabel={formatMessage({ id: AppMessages['filters.clear'] })}
      valueSelected={fte !== ''}
      onPopoverActionClick={handleClearFte}
    >
      <FormControlLabel
        label={formatMessage({ id: AppMessages['filters.fte.option.fullTime'] })}
        control={
          <Radio
            value={Number(Fte.fullTime)}
            checked={!otherSelected && Number(fte) === Number(Fte.fullTime)}
            onClick={() => handleFilterClick(Fte.fullTime)}
          />
        }
      />
      <FormControlLabel
        label={formatMessage({ id: AppMessages['filters.fte.option.halfTime'] })}
        control={
          <Radio
            value={Number(Fte.halfTime)}
            checked={!otherSelected && Number(fte) === Number(Fte.halfTime)}
            onClick={() => handleFilterClick(Fte.halfTime)}
          />
        }
      />
      <FormControlLabel
        label={formatMessage({ id: AppMessages['filters.fte.option.other'] })}
        control={<Radio checked={otherSelected} onClick={handleOtherSelect} />}
      />
      {otherSelected && (
        <DebouncedTextField
          size="small"
          type="number"
          label={formatMessage({ id: AppMessages['filters.fte.popover.hoursPerWeek'] })}
          InputProps={{
            onKeyDown: createNumberKeyDownHandler({ allowNegative: false, decimalPrecision: 0 }),
            inputProps: {
              min: 0,
              max: 40,
            },
          }}
          defaultValue={fte}
          value={fte}
          onChange={handleOtherChange}
          sx={styles.hoursInput}
        />
      )}
    </Filter>
  );
};
