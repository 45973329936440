import { keyframes } from '@emotion/react';

import { Styles } from 'styles/theme';

export const modal: Styles = {
  '.MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
};

export const modalContent: Styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 'max-content',
};

const slideFromTop = keyframes`
0%{
    transform:translateY(-50vh);
}
100%{
    transform:translateY(0);
}
`;

const slideFromBottom = keyframes`
0%{
    transform:translateY(50vh);
}
100%{
    transform:translateY(0);
}
`;

export const bottomContent: Styles = {
  animation: `${slideFromBottom} 0.4s cubic-bezier(.32,1.58,.58,.89)`,
};

export const imageWrapper: Styles = {
  marginBottom: 8,
  position: 'relative',
  animation: `${slideFromTop} 0.4s cubic-bezier(.32,1.58,.58,.89)`,
};

export const imageMatt = {
  position: 'absolute',
  top: '-1%',
  left: '44%',
  transform: 'rotate(-4deg)',
} as const;

export const title: Styles = {
  marginBottom: 2,
};

export const message: Styles = {
  marginBottom: 3,
};

export const background: Styles = {
  position: 'absolute',
  inset: 0,
  backgroundColor: 'surface.backdropOverlay',
  zIndex: -3,
};

export const confettiCanvas = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: -2,
} as const;
