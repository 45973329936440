import { Styles } from 'styles/theme';

export const tableCell = (checked: boolean): Styles => ({ color: checked ? 'primary.main' : 'text.primary' });

export const tableRow = (checked: boolean): Styles => ({
  color: checked ? 'primary.light' : 'transparent',
  cursor: 'pointer',
});

export const noAssignmentsWrapper: Styles = {
  mt: 3,
};
