import { BottomBar as LuxBottomBar } from 'lux/components';
import { SaveIcon } from 'lux/icons';
import { Box } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useFormNavigation } from 'app/createProject/useFormNavigation/useFormNavigation';

import * as styles from './BottomBar.styles';

export const BottomBar = () => {
  const { handleNext, handleBack, isLoading, activeStep, currentStepHasVisibleErrors } = useFormNavigation();
  const { formatMessage } = useLocale();

  const nextText =
    activeStep === 2
      ? formatMessage({ id: AppMessages['createProject.button.addProject'] })
      : formatMessage({ id: AppMessages['button.next'] });

  const startIcon = activeStep === 2 ? <SaveIcon /> : null;

  const helperText = currentStepHasVisibleErrors
    ? formatMessage({ id: AppMessages['validation.notAllRequiredFieldsAreFilled'] })
    : '';

  return (
    <Box sx={styles.barWrapper} data-cy="create-project_buttons-bar">
      <LuxBottomBar
        backButtonAction={handleBack}
        backButtonLabel={formatMessage({ id: AppMessages['button.back'] })}
        mainButtonLabel={nextText}
        mainButtonAction={handleNext}
        mainButtonProps={{
          loading: isLoading,
          startIcon: startIcon,
        }}
        helperText={helperText}
      />
    </Box>
  );
};
