import { MutationFn } from 'hooks/useMutation/useMutation.types';

export const removePersonRejectionAction: () => MutationFn<{
  rejectionId: string;
  projectId: string;
}> =
  () =>
  ({ rejectionId, projectId }) => ({
    endpoint: `/projects/${projectId}/rejections/${rejectionId}/remove`,
    method: 'PATCH',
  });
