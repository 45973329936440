import type { ReactNode } from 'react';

import { Button, Table } from 'lux/components';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

import { fteToHumanValue } from 'app/need/utils';
import { formatSeniorityName } from 'utils/stringUtils';
import { convertRateToDecimal } from 'utils/convertRate';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Card } from 'ui/card/Card';
import { EmployeeCardHeader } from 'app/employee/card/header/EmployeeCardHeader';
import { EmployeeCardFooter } from 'app/employee/card/footer/EmployeeCardFooter';
import { Translation } from 'ui/translation/Translation';

import * as styles from './AssigningSummary.styles';
import type { AssigningSummaryProps } from './AssigningSummary.types';

export const AssigningSummary = (props: AssigningSummaryProps) => {
  const { project, proposal, need } = props;
  const { formatMessage } = useLocale();

  const rows: { id: string; value: ReactNode }[] = [
    {
      id: 'project',
      value: (
        <Button
          size="small"
          sx={styles.link}
          component={Link}
          disableRipple
          variant="text"
          to={`/projects/${project.id}`}
          target="_blank"
        >
          {project.name}
        </Button>
      ),
    },
    {
      id: 'role',
      value: need.role,
    },
    {
      id: 'seniority',
      value: formatSeniorityName(need.seniority),
    },
    {
      id: 'fte',
      value: <Translation id={fteToHumanValue(need.fte)} values={{ fte: need.fte }} />,
    },
    {
      id: 'startDate',
      value: need.startDate || '-',
    },
    {
      id: 'endDate',
      value: need.endDate || '-',
    },
    {
      id: 'rate',
      value: (
        <>
          {convertRateToDecimal(proposal.rate)} {proposal.currency}
        </>
      ),
    },
  ];

  return (
    <Card data-cy="assigning-summary" sx={styles.card}>
      <Card.Header>
        <Box sx={styles.cardHeader}>
          <EmployeeCardHeader employee={proposal.person} rejectionDate={null} />
        </Box>
      </Card.Header>

      <EmployeeCardFooter skills={proposal.person.skills} />

      <Box sx={styles.cardBody}>
        <Table.Table sx={styles.table}>
          <Table.Body>
            {rows.map(({ id, value }) => (
              <Table.Row key={id}>
                <Table.Cell sx={styles.rowHeader}>{formatMessage({ id: `card.need.summary.${id}` })}</Table.Cell>
                <Table.Cell>{value}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Table>
      </Box>
    </Card>
  );
};
