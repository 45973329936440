import { Styles } from 'styles/theme';

export const accordion: Styles = {
  backgroundColor: 'surface.paper.default',
  border: 'none',

  '&:not(.Mui-expanded):hover': {
    backgroundColor: 'primary.light',
  },
};

export const accordionSummary: Styles = {
  '& > .MuiAccordionSummary-expandIconWrapper': {
    marginLeft: 4,
    borderRadius: 1,

    '&:hover': {
      backgroundColor: 'primary.light',
    },
  },
};

export const accordionDetails: Styles = {
  backgroundColor: (theme) => theme.palette.common.white,
  padding: 3,
  borderRadius: 1,
  marginTop: 0,
  marginBottom: 2,

  '&:first-of-type': {
    border: 'none',
  },
};

export const projectLink: Styles = {
  textDecoration: 'none',
};

export const expandIcon: Styles = {
  '&.MuiSvgIcon-root path': {
    fill: (theme) => theme.palette.text.primary,
  },
};
