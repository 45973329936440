import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useMutation } from 'hooks/useMutation/useMutation';
import { assignmentsKeys, needsKeys, proposalsKeys, rejectionsKeys } from 'utils/queryKeys';
import { getApiError } from 'api/utils/getApiError';
import { removePersonRejectionAction } from '../../api/actions/cancelPersonRejection/removePersonRejectionAction';

export const useRemovePersonRejection = (needId?: string) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(['removePersonRejection'], removePersonRejectionAction(), {
    onSuccess: () => {
      queryClient.invalidateQueries(rejectionsKeys.rejectionsList);
      queryClient.invalidateQueries(assignmentsKeys.assignmentsList);
      queryClient.invalidateQueries(needsKeys.needsList);

      if (needId) {
        queryClient.refetchQueries(needsKeys.singleNeed(needId));
        queryClient.refetchQueries(proposalsKeys.proposalsList(needId));
      }
    },
    onError: (error) => {
      enqueueSnackbar(getApiError(error));
    },
  });
};
