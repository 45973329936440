export enum EmploymentType {
  DG = 'DG',
  UOP = 'UoP',
  UZ = 'UZ',
  NPR = 'NPR',
  UOD = 'UoD',
  LTSH = 'LTSH',
  STAZ = 'Staz',
  DGBU = 'DGBU',
}
