import { Accent } from '@mui/material';

import { BillingCycleStatus } from 'api/types/BillingSummary.types';
import { Translation } from 'i18n/messages';

export const getBillingCycleStatusColor = (status: BillingCycleStatus): keyof Accent => {
  switch (status) {
    case 'created':
      return 'gray';
    case 'ongoing':
      return 'yellow';
    case 'in_review':
      return 'red';
    case 'approved_by_pm':
      return 'yellow';
    case 'approved':
      return 'yellow';
    case 'internal_billing':
      return 'green';
    case 'done':
      return 'green';
    default:
      return 'gray';
  }
};

export const getBillingCycleStatusTranslation = (status: BillingCycleStatus): Translation => {
  switch (status) {
    case 'created':
      return 'projectDetails.billingCycle.status.created';
    case 'ongoing':
      return 'projectDetails.billingCycle.status.ongoing';
    case 'in_review':
      return 'projectDetails.billingCycle.status.inReview';
    case 'approved_by_pm':
      return 'projectDetails.billingCycle.status.approvedByPm';
    case 'approved':
      return 'projectDetails.billingCycle.status.approved';
    case 'done':
      return 'projectDetails.billingCycle.status.done';
    case 'internal_billing':
      return 'projectDetails.billingCycle.status.internalBilling';
    default:
      return 'projectDetails.billingCycle.status.unknown';
  }
};
