import { useContext } from 'react';

import { ProjectDiscrepancyContext } from 'context/projectDiscrepancy/projectDiscrepancyContext/ProjectDiscrepancyContext';

export const useProjectDiscrepancy = () => {
  const context = useContext(ProjectDiscrepancyContext);

  if (!context) {
    throw new Error('ProjectDiscrepancyContext must be within ProjectDiscrepancyProvider');
  }

  return context;
};
