import { Styles } from 'styles/theme';

export const button: Styles = {
  '&.MuiButton-root': {
    paddingX: 1,
    minWidth: 'unset',

    '&.Mui-disabled': {
      color: 'text.secondary',
    },
  },
};

export const popover: Styles = {
  '.MuiPopover-paper': {
    backgroundColor: 'surface.paper.default',
    boxShadow: (theme) => theme.shadows[0],
    borderRadius: 2,
    padding: 1,
  },
};
