import { SxProps } from '@mui/material';
import { Styles } from 'lux/theme';

export const wrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 1,
  marginY: 2,
};

export const text: Styles = {
  color: 'text.secondary',
  fontWeight: 500,
};
