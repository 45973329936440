import { useState, useEffect } from 'react';

import { Stack } from '@mui/material';
import { Autocomplete, Button } from 'lux/components';
import { AddIcon, CloseIcon } from 'lux/icons';

import { SquareIconButton } from 'ui/squareIconButton/SquareIconButton';

import { AutocompleteStackProps } from './AutocompleteStack.types';
import * as styles from './AutocompleteStack.styles';

export const AutocompleteStack = ({
  values,
  label,
  dictionary,
  onFieldsChange,
  buttonLabel,
}: AutocompleteStackProps) => {
  const [internalValues, setInternalValues] = useState<(string | null)[]>(values);

  const handleFieldChange = (fieldIndex: number, fieldValue: string | null) => {
    const newValues = internalValues.map((value, index) => {
      if (index === fieldIndex) {
        return fieldValue;
      }
      return value;
    });
    setInternalValues(newValues);
    onFieldsChange(newValues.filter((value) => value !== null) as string[]);
  };
  const addField = () => {
    setInternalValues([...internalValues, null]);
  };

  const deleteField = (fieldIndex: number) => {
    const newValues = internalValues.filter((value, index) => index !== fieldIndex);
    setInternalValues(newValues);
    onFieldsChange(newValues.filter((value) => value !== null) as string[]);
  };

  useEffect(() => {
    if (
      internalValues.every((internalValue) => internalValue === null || values.includes(internalValue)) &&
      values.every((value) => internalValues.includes(value))
    ) {
      return;
    }
    setInternalValues(values);
  }, [values]);

  const remainingOptions = dictionary.filter((value) => !values.includes(value));

  return (
    <>
      <Stack rowGap={2}>
        {internalValues.map((value, index) => (
          <Stack marginBottom={2} direction="row" alignItems="center" columnGap={1} key={`${value} - ${index}`}>
            <Autocomplete
              options={remainingOptions}
              value={value}
              label={label}
              size="small"
              sx={styles.autocomplete}
              onChange={(event, newValue) => handleFieldChange(index, newValue)}
            />
            <SquareIconButton size="small" variant="text" sx={styles.deleteButton} onClick={() => deleteField(index)}>
              <CloseIcon />
            </SquareIconButton>
          </Stack>
        ))}
      </Stack>
      <Button
        variant="text"
        color="primary"
        startIcon={<AddIcon />}
        disabled={internalValues.length === dictionary.length || internalValues.includes(null)}
        sx={styles.addButton}
        onClick={() => addField()}
      >
        {buttonLabel}
      </Button>
    </>
  );
};
