import { Stack } from '@mui/material';
import { Typography, Button } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useNeedsFilters } from 'hooks/useNeedsFilters/useNeedsFilters';
import { RoleFilter } from 'shared/needs/needsFilters/roleFilter/RoleFilter';
import { SeniorityFilter } from 'shared/needs/needsFilters/seniorityFilter/SeniorityFilter';
import { FteFilter } from 'shared/needs/needsFilters/fteFilter/FteFilter';
import { NeedTimeRangeFilter } from 'shared/needs/needsFilters/needTimeRangeFilter/NeedTimeRangeFilter';
import { TypeFilter } from 'shared/needs/needsFilters/typeFilter/TypeFilter';

import * as styles from './NeedsAccordionFilters.styles';

export const NeedsAccordionFilters = () => {
  const { formatMessage } = useLocale();
  const { clearAllFilters } = useNeedsFilters();

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h6">{formatMessage({ id: AppMessages['filters.title'] })}</Typography>
        <Button
          onClick={() => clearAllFilters(['owner', 'search', 'sort', 'groupBy'])}
          size="small"
          variant="text"
          color="primary"
        >
          {formatMessage({ id: AppMessages['filters.clearAll'] })}
        </Button>
      </Stack>
      <Stack sx={styles.container}>
        <RoleFilter variant="accordion" />
        <SeniorityFilter variant="accordion" />
        <FteFilter variant="accordion" />
        <TypeFilter variant="accordion" />
        <NeedTimeRangeFilter variant="accordion" />
      </Stack>
    </>
  );
};
