import { Box } from '@mui/material';
import { Typography } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import * as styles from './Header.styles';

export const Header = () => {
  const { formatMessage } = useLocale();
  const environment = process.env.REACT_APP_ENVIRONMENT ?? 'undefined';

  return (
    <Box sx={styles.header}>
      <Typography fontSize={14}>{formatMessage({ id: AppMessages['header.environment'] }, { environment })}</Typography>
    </Box>
  );
};
