import { Seniority } from 'api/types/Seniority.enum';
import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';

export const seniorityList = () => {
  const { formatMessage } = intl.currentIntl;

  return [
    { id: '1', children: formatMessage({ id: AppMessages['people.seniority.junior'] }), value: Seniority.junior },
    { id: '2', children: formatMessage({ id: AppMessages['people.seniority.mid'] }), value: Seniority.mid },
    { id: '3', children: formatMessage({ id: AppMessages['people.seniority.senior'] }), value: Seniority.senior },
  ];
};
