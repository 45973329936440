import { Styles } from 'lux/theme';

export const popper: Styles = {
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'surface.paper.light',
    boxShadow: (theme) => theme.luxShadows[500],
    color: 'text.primary',
    width: 'fit-content',
  },
  '& .MuiTooltip-arrow': {
    '&::before': {
      backgroundColor: 'surface.paper.light',
      boxShadow: (theme) => theme.luxShadows[500],
    },
  },
};

export const iconWrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  path: {
    fill: 'currentColor',
  },
};
