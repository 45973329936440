import React from 'react';

import { ComparisonRow } from '../comparisonRow/ComparisonRow';
import { SkillWeight } from 'app/need/Need.enums';

import { SkillRowProps } from './SkillRow.types';

export const SkillRow = ({ requiredSkill, personSkills }: SkillRowProps) => {
  const personHasSkill = personSkills.some(
    (personSkill) => personSkill.name.toLowerCase() === requiredSkill.name.toLowerCase(),
  );

  return (
    <ComparisonRow
      key={requiredSkill.name}
      highlightCondition={requiredSkill.weight === SkillWeight.mustHave && !personHasSkill}
      heading={requiredSkill.name}
      needCell={requiredSkill.weight}
      personCell={null}
      checkmarkCondition={personHasSkill}
    />
  );
};
