import { useEffect, useState } from 'react';

import { useLocation, useSearchParams } from 'react-router-dom';

import { parseQueryString } from 'api/utils/queryString';

import { getClearedFilters, getNewFilters, getNewParamsFilters, getStorageData } from './useFilters.utils';
import type { UseFiltersOptions, InitialState } from './useFilters.types';

export const useFilters = <T extends InitialState>(
  initialState: T,
  { withParams, storageKey, storageType = 'local' }: UseFiltersOptions,
  overrideStorageKey?: Partial<T>,
) => {
  const [paramsFilters, setParamsFilters] = useSearchParams();
  const storageData = storageKey ? getStorageData<T>(storageKey, storageType) : undefined;
  const [filters, setFilters] = useState<T>({ ...initialState, ...storageData, ...overrideStorageKey });
  const location = useLocation();
  const filtersState = withParams
    ? { ...filters, ...parseQueryString<InitialState>(paramsFilters.toString()) }
    : filters;
  const storageConfig = {
    storageKey,
    storageType,
  };

  useEffect(() => {
    if (withParams) {
      setFiltersState({ ...filters, ...parseQueryString<InitialState>(paramsFilters.toString()) });
    }
  }, []);

  const setFiltersState = (newFiltersState: Partial<T>) => {
    if (withParams) {
      setParamsFilters((prevFilters) => getNewParamsFilters({ prevFilters, newFiltersState, ...storageConfig }), {
        state: { previousKey: location.state?.previousKey || location.key },
        replace: true,
      });
    }
    setFilters((prev) => getNewFilters({ prev, newFiltersState, ...storageConfig }));
  };

  const setStorageFiltersState = (newFiltersState: Partial<T>) => {
    getNewFilters({ prev: filters, newFiltersState, ...storageConfig });
  };

  const getFilterValue = (name: keyof T) => filtersState[name];

  const setFilterValue = <Key extends keyof T>(name: Key, value: T[Key]) => {
    setFiltersState({ [name]: value } as unknown as Partial<T>);
  };

  const clearFilter = (name: keyof T) => {
    setFilterValue(name, (Array.isArray(initialState[name]) ? [] : '') as T[keyof T]);
  };

  const toggleFilter = <Key extends keyof T>(name: Key, value: string) => {
    const targetFilterValue = getFilterValue(name);

    if (Array.isArray(targetFilterValue)) {
      if (targetFilterValue.includes(value)) {
        setFilterValue(name, targetFilterValue.filter((filterValue) => filterValue !== value) as T[Key]);
        return;
      }

      setFilterValue(name, [...targetFilterValue, value] as T[Key]);
      return;
    }

    if (targetFilterValue === value) {
      clearFilter(name);
      return;
    }

    setFilterValue(name, value as T[Key]);
  };

  const clearAllFilters = (exceptions: (keyof T)[] = []) => {
    const clearedFilters = getClearedFilters(filtersState, initialState, exceptions);

    setFiltersState(clearedFilters);
  };

  const resetAllFiltersToDefault = (exceptions: (keyof T)[] = []) => {
    const resetFilters = Object.entries(filtersState).reduce<Partial<T>>((acc, [filterKey]) => {
      if (exceptions.includes(filterKey)) {
        return acc;
      }

      return { ...acc, [filterKey]: initialState[filterKey] };
    }, {});

    setFiltersState(resetFilters);
  };

  return {
    filters: filtersState,
    setFilterValue,
    toggleFilter,
    clearFilter,
    clearAllFilters,
    resetAllFiltersToDefault,
    setStorageFiltersState,
  };
};
