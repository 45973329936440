import { NavLink, useNavigate, Link } from 'react-router-dom';
import { TopBar as LuxTopBar } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { usePermission } from 'hooks/usePermission/usePermission';
import { actions } from 'hooks/usePermission/Permissions';

import type { TopBarProps } from './TopBar.types';

export const TopBar = ({ user }: TopBarProps) => {
  const { formatMessage } = useLocale();
  const { hasPermission } = usePermission();
  const navigate = useNavigate();

  return (
    <LuxTopBar
      sx={{
        position: 'relative',
        backgroundColor: 'surface.default',
        zIndex: 1300,
      }}
      hasSearch={false}
      avatarImage={user?.picture}
      avatarAlt={`${user?.firstName} ${user?.lastName}`}
      onLogoutClick={() => navigate(AppRoute.logout)}
      renderLogo={(logo) => <Link to={AppRoute.homepage}>{logo}</Link>}
      menuItems={
        hasPermission(actions.viewMenuTabs)
          ? [
              {
                label: formatMessage({ id: AppMessages['topBar.menu.dashboard'] }),
                disabled: false,
                component: <NavLink to={AppRoute.dashboard} />,
              },
              {
                label: formatMessage({ id: AppMessages['topBar.menu.clients'] }),
                disabled: false,
                component: <NavLink to={AppRoute.clients} />,
              },
              {
                label: formatMessage({ id: AppMessages['topBar.menu.projects'] }),
                disabled: false,
                component: <NavLink to={AppRoute.projects} />,
              },
              {
                label: formatMessage({ id: AppMessages['topBar.menu.needs'] }),
                disabled: false,
                component: <NavLink to={AppRoute.needs} />,
              },
              {
                label: formatMessage({ id: AppMessages['topBar.menu.people'] }),
                disabled: false,
                component: <NavLink to={AppRoute.people} />,
              },
            ]
          : undefined
      }
    />
  );
};
