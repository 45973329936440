import { FormControl, Stack } from '@mui/material';

import { ButtonWithPopover } from 'ui/buttonWithPopover/ButtonWithPopover';
import { PopoverHeading } from 'ui/popoverHeading/PopoverHeading';

import { PopoverFilterProps } from './PopoverFilter.types';

export const PopoverFilter = ({
  children,
  label,
  dataCy,
  icon,
  popoverActionLabel,
  popoverTitle,
  selectedCount,
  shouldStretch,
  valueSelected,
  onPopoverActionClick,
}: PopoverFilterProps) => (
  <ButtonWithPopover active={valueSelected} count={selectedCount} dataCy={dataCy} icon={icon} label={label}>
    <FormControl component="fieldset">
      <Stack alignItems={shouldStretch ? 'stretch' : 'flex-start'} data-cy="popover-filter_stack">
        {(popoverTitle || popoverActionLabel) && (
          <PopoverHeading title={popoverTitle} actionLabel={popoverActionLabel} onActionClick={onPopoverActionClick} />
        )}
        {children}
      </Stack>
    </FormControl>
  </ButtonWithPopover>
);
