import { useState } from 'react';

import { Box } from '@mui/system';
import { Divider, Stack } from '@mui/material';
import { Typography } from 'lux/components';
import { useFormContext } from 'react-hook-form';

import * as styles from '../CreateProject.styles';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { MultipleAutocomplete } from 'ui/form/autocomplete/MultipleAutocomplete';
import { Select } from 'ui/form/select/Select';
import { timeDifferences } from 'config/data/timeDifferences';
import { Currency } from 'api/types/Currency.enum';
import { attributes } from 'config/data/attributes';
import { mapToAutocompleteOptions } from 'ui/form/autocomplete/Autocomplete.functions';
import { DatePicker } from 'ui/form/datePicker/DatePicker';
import { mapToSelectOptions } from 'ui/form/select/Select.mappers';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { subtractDaysFromDate, ZERO_UNIX_DATE } from 'utils/dateUtils';
import { SelectChips } from 'ui/form/select/selectChip/SelectChips';
import { composeStyles } from 'utils/composeStyles';
import { useTags } from 'hooks/useTags/useTags';
import { moneyInputNumberParser } from 'utils/createInputNumberChangeParser';

import { ProjectNameField } from './projectNameField/ProjectNameField';
import { CodenameField } from './codenameField/CodenameField';

export const NewProjectDetails = () => {
  const form = useFormContext();
  const { data: tags, isLoading: isTagsLoading } = useTags();
  const { formatMessage } = useLocale();

  const [isBudgetFieldSelected, setIsBudgetFieldSelected] = useState<boolean>(false);

  return (
    <Box>
      <Typography variant="h6" sx={styles.formHeader}>
        {formatMessage({ id: AppMessages['createProject.project.header'] })}
      </Typography>
      <ProjectNameField dataCy="create-project_name" />
      <FormTextField
        name="project.description"
        control={form.control}
        label={formatMessage({ id: AppMessages['createProject.project.form.description'] })}
        sx={styles.formElement}
        fullWidth
        multiline
        data-cy="create-project_description"
      />
      <Stack
        sx={styles.formElement}
        columnGap={5}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <DatePicker
          minDate={ZERO_UNIX_DATE}
          maxDate={
            form.watch('project.endDate') ? subtractDaysFromDate(new Date(form.watch('project.endDate')), 1) : undefined
          }
          name="project.startDate"
          control={form.control}
          label={formatMessage({ id: AppMessages['createProject.project.form.start'] })}
          fullWidth
          data-cy="create-project_start-date"
        />
        <DatePicker
          minDate={new Date(form.watch('project.startDate'))}
          name="project.endDate"
          control={form.control}
          label={formatMessage({ id: AppMessages['createProject.project.form.end'] })}
          disableUntilDate={new Date(form.watch('project.startDate'))}
          isDisabled={!form.watch('project.startDate')}
          fullWidth
          data-cy="create-project_end-date"
        />
      </Stack>
      <Select
        name="project.attributes"
        control={form.control}
        options={mapToSelectOptions(attributes)}
        label={formatMessage({ id: AppMessages['createProject.project.form.attributes'] })}
        sx={composeStyles([styles.formElement, form.watch('project.attributes').length > 0 && styles.selectWithChips])}
        multiple
        fullWidth
        renderValue={(selected) => (Array.isArray(selected) ? <SelectChips selectedValues={selected} /> : null)}
        selectedOptionCheckmark
        data-cy="create-project_attributes"
      />
      <MultipleAutocomplete
        name="project.tags"
        control={form.control}
        loading={isTagsLoading}
        options={mapToAutocompleteOptions(tags || [])}
        label={formatMessage({ id: AppMessages['createProject.project.form.tags'] })}
        sx={styles.formElement}
        freeSolo
        inputMaxLength={20}
        dataCy="create-project_tags"
      />
      <Select
        name="project.currency"
        control={form.control}
        options={mapToSelectOptions(Object.values(Currency))}
        label={formatMessage({ id: AppMessages['createProject.project.form.currency'] })}
        sx={styles.formElement}
        fullWidth
        data-cy="create-project_currency"
      />
      <Divider sx={styles.formElement} />
      <Typography variant="h6" sx={styles.formElement}>
        {formatMessage({ id: AppMessages['createProject.project.secondaryHeader'] })}
      </Typography>
      <Select
        name="project.timeDiff"
        control={form.control}
        options={mapToSelectOptions(timeDifferences, {
          clearable: true,
          emptyOptionLabel: formatMessage({ id: AppMessages['form.none'] }),
        })}
        label={formatMessage({ id: AppMessages['createProject.project.form.timeDiff'] })}
        sx={styles.formElement}
        fullWidth
        selectedOptionCheckmark
        data-cy="create-project_timezone"
      />
      <FormTextField
        name="project.contactPerson"
        control={form.control}
        label={formatMessage({ id: AppMessages['createProject.project.form.contactPerson'] })}
        sx={styles.formElement}
        fullWidth
        data-cy="create-project_contact-person"
      />
      <CodenameField dataCy="create-project_codename" />
      <FormTextField
        name="project.slackChannelId"
        control={form.control}
        label={formatMessage({ id: AppMessages['createProject.project.form.slackChannelId'] })}
        sx={styles.formElement}
        fullWidth
        data-cy="create-project_slack-channel-id"
      />
      <FormTextField
        name="project.budget"
        control={form.control}
        label={formatMessage({ id: AppMessages['createProject.project.form.budget'] })}
        sx={styles.formElement}
        isDisabled={!form.watch('project.currency')}
        startAdornment={isBudgetFieldSelected ? (form.watch('project.currency') as Currency) : null}
        fullWidth
        onFocus={() => setIsBudgetFieldSelected(true)}
        onBlur={() => setIsBudgetFieldSelected(false)}
        onChangeParser={moneyInputNumberParser}
        data-cy="create-project_budget"
      />
    </Box>
  );
};
