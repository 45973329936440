import { Box, Stack } from '@mui/material';
import { Badge, Typography } from 'lux/components';
import { CalendarIcon } from 'lux/icons';

import { chooseMainSkillsByWeight } from 'app/need/utils';
import { Translation } from 'ui/translation/Translation';
import { needFormatDate } from 'utils/dateUtils';
import { formatRoleName } from 'utils/stringUtils';

import { NeedCardHeaderProps } from './NeedCardHeader.types';
import * as styles from './NeedCardHeader.styles';

export const NeedCardHeader = ({
  role,
  seniority,
  projectName,
  clientName,
  startDate,
  skillSet,
  createdAt,
  additionalInformation,
}: NeedCardHeaderProps) => {
  const skills = chooseMainSkillsByWeight(skillSet);

  return (
    <Box sx={styles.needCardHeaderWrapper}>
      <Stack direction="row" justifyContent="space-between">
        <Stack gap={0.5}>
          <Typography variant="body2" fontWeight={600}>
            {formatRoleName(role, seniority)}
          </Typography>
          <Stack direction="row" spacing={0.5}>
            <Typography variant="caption" color="text.secondary">
              {clientName}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              |
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {projectName}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              |
            </Typography>
            <Typography variant="caption" color="text.secondary">
              <Translation id="card.need.createdAt" /> {needFormatDate(new Date(createdAt))}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
            <CalendarIcon />
            <Typography variant="caption" color="text.secondary">
              <Translation id="card.startDate.bare" />
            </Typography>
            <Typography variant="caption">{needFormatDate(new Date(startDate))}</Typography>
          </Stack>
          {skillSet.length > 0 && (
            <Box sx={styles.skillsWrapper}>
              {skills.map((item, index) => (
                <Badge isStatic key={`${item.name}-${index}`} color="lightGray" badgeContent={item.name} />
              ))}
              {skillSet.length > skills.length && (
                <Badge isStatic color="lightGray" badgeContent={`+${skillSet.length - skills.length}`} />
              )}
            </Box>
          )}
        </Stack>
      </Stack>
      {additionalInformation && (
        <Stack>
          <Typography variant="caption" color="text.secondary">
            {additionalInformation.split('\n').map((item, index, items) => (
              <span key={index}>
                {item} {items.length - 1 > index && <br />}
              </span>
            ))}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};
