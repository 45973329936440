import { Dialog } from '@mui/material';

import { useDialog } from 'hooks/useDialog/useDialog';
import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';

import { EditUncertainContent } from './editUncertainContent/EditUncertainContent';
import { EditUncertainModalProps } from './EditUncertainModal.types';
import { ConfirmUncertainRemovalModal } from './confirmUncertainRemovalModal/ConfirmUncertainRemovalModal';

export const EditUncertainModal = ({ open, onClose }: EditUncertainModalProps) => {
  const { isOpen: isConfirmModalOpen, setOpen: setConfirmModalOpen, setClose: setConfirmModalClose } = useDialog();
  useDialogBlockScroll(isConfirmModalOpen);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <EditUncertainContent onClose={onClose} onRemove={setConfirmModalOpen} />
      </Dialog>
      <ConfirmUncertainRemovalModal open={isConfirmModalOpen} onClose={setConfirmModalClose} />
    </>
  );
};
