import { useCallback, useState } from 'react';

export function useSessionStorage<DataType>(
  key: string,
  initialValue: DataType,
): [DataType, (newValue: DataType) => void] {
  const [storedValue, setStoredValue] = useState<DataType>(() => {
    const storedValue = window.sessionStorage.getItem(key);

    if (storedValue) {
      return JSON.parse(storedValue);
    }

    return initialValue;
  });

  const setValue = useCallback(
    (newValue: DataType) => {
      window.sessionStorage.setItem(key, JSON.stringify(newValue));
      setStoredValue(newValue);
    },
    [key],
  );

  return [storedValue, setValue];
}
