import { ElementType } from 'react';

import { Button } from 'lux/components';

import { SquareIconButtonProps } from './SquareIconButton.types';
import * as styles from './SquareIconButton.styles';

export const SquareIconButton = <C extends ElementType>({
  sx = [],
  loading = false,
  disabled,
  ...rest
}: SquareIconButtonProps<C>) => (
  <Button
    loading={loading}
    disabled={disabled}
    sx={[...(Array.isArray(sx) ? sx : [sx]), styles.button]}
    {...rest}
    loadingPosition="center"
    variant={rest.variant || 'text'}
  >
    {rest.children}
  </Button>
);
