import { useState } from 'react';

import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Tooltip } from 'lux/components';
import { AddIcon } from 'lux/icons';
import { Box } from '@mui/system';

import { AssignOrProposeAnywayModal } from 'shared/assignOrProposeAnywayModal/AssignOrProposeAnywayModal';
import { Person } from 'api/actions/getPerson/getPerson.types';
import { Seniority } from 'api/types/Seniority.enum';
import { PeopleActionsModal } from 'shared/peopleActionsModal/PeopleActionsModal';
import { SkillWeight } from 'app/need/Need.enums';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useProposePerson } from 'hooks/useProposePerson/useProposePerson';
import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';
import { createDepartmentLabel } from 'config/data/departments';
import { useProject } from 'hooks/useProject/useProject';
import { needsKeys, projectsKeys, proposalsKeys } from 'utils/queryKeys';
import { Loader } from 'ui/loader/Loader';
import { ComparisonTable } from '../comparisonTable/ComparisonTable';
import { SquareIconButton } from 'ui/squareIconButton/SquareIconButton';
import { convertRateToInteger } from 'utils/convertRate';
import { AssignPersonError } from 'api/actions/assignPerson/assignPersonActions.types';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useAssignmentInfo } from 'hooks/useAssignmentInfo/useAssignmentInfo';

import { PeopleProposeModalProps, PeopleProposeModalStep } from './PeopleProposeModal.types';

export const PeopleProposeModal = ({ needData, projectId, isOpen, onClose }: PeopleProposeModalProps) => {
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);
  const [currentStep, setCurrentStep] = useState<PeopleProposeModalStep | null>(null);
  const [isVisible, setIsVisible] = useState(true);
  const [effectiveRate, setEffectiveRate] = useState<number | null>(null);
  const [warnings, setWarnings] = useState<AssignPersonError | null>(null);

  const { checkAvailableRoleForUser } = useAssignmentInfo();

  const preassigned = 'PREASSIGNED';

  const { formatMessage } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    isOpen: isForceAssignModalOpen,
    setOpen: setForceAssignModalOpen,
    setClose: setForceAssignModalClose,
  } = useDialog();
  useDialogBlockScroll(isForceAssignModalOpen);

  const { data: projectData, isLoading: isProjectDataLoading } = useProject(projectId);
  const { mutate: proposePerson, isLoading: isProposePersonLoading } = useProposePerson(projectId, needData.id);

  const handleSubmitButtonClick = ({ force } = { force: false }) => {
    if (!selectedPerson || !projectData) {
      return;
    }

    proposePerson(
      {
        personId: selectedPerson.employeeId,
        ...(effectiveRate !== null && {
          proposedRateInProject: convertRateToInteger(effectiveRate),
        }),
        force,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(
            formatMessage(
              { id: AppMessages['snackbar.proposePerson.success'] },
              {
                personName: `${selectedPerson.firstName} ${selectedPerson.lastName}`,
                projectName: projectData.name,
                role: createDepartmentLabel(needData.role),
              },
            ),
          );
          queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
          queryClient.invalidateQueries(projectsKeys.singleProjectNeeds(projectId));
          queryClient.invalidateQueries(needsKeys.singleNeed(needData.id));
          queryClient.invalidateQueries(needsKeys.needsList);
          queryClient.invalidateQueries(proposalsKeys.proposalsList(needData.id));
          onClose();
        },
        onError: (error) => {
          if (error.response?.status === 409) {
            setWarnings(error.response.data);
            setForceAssignModalOpen();
            setIsVisible(false);
          } else {
            enqueueSnackbar(formatMessage({ id: AppMessages['snackbar.proposePerson.error'] }), {
              variant: 'error',
            });
          }
        },
      },
    );
  };

  const handlePersonSelect = (person: Person) => {
    setSelectedPerson(person);
    setCurrentStep(PeopleProposeModalStep.comparisonTable);
  };

  const handleBackToList = () => {
    setSelectedPerson(null);
    setCurrentStep(null);
    setEffectiveRate(null);
  };

  if (isProjectDataLoading || !projectData) {
    return <Loader fullHeight={false} />;
  }

  return (
    <PeopleActionsModal
      isOpen={isOpen}
      onClose={onClose}
      currentStepName={currentStep}
      personAction={(person) => {
        const availableRole = checkAvailableRoleForUser(person.employeeId);
        const isNotTheSameRole = !!(availableRole && availableRole !== needData.role);

        return (
          <Tooltip
            title={formatMessage({ id: 'needs.proposeEmployeeTable.row.btn.propose.tooltip' })}
            placement="left-end"
            arrow
            disableHoverListener={person.availabilityStatus !== preassigned}
          >
            <Box>
              <SquareIconButton
                onClick={() => {
                  handlePersonSelect(person);
                }}
                size="small"
                variant="contained"
                disabled={isNotTheSameRole}
              >
                <AddIcon />
              </SquareIconButton>
            </Box>
          </Tooltip>
        );
      }}
      isVisible={isVisible}
      peopleListTitle={formatMessage({ id: AppMessages['card.need.peopleList.propose.title'] })}
      steps={[
        {
          name: PeopleProposeModalStep.comparisonTable,
          title: formatMessage({ id: AppMessages['card.need.proposePerson.summary.title'] }),
          content: selectedPerson && (
            <>
              <ComparisonTable
                projectId={projectId}
                needData={needData}
                personId={String(selectedPerson.employeeId)}
                effectiveRate={effectiveRate}
                onEffectiveRateChange={setEffectiveRate}
              />

              {warnings && (
                <AssignOrProposeAnywayModal
                  mode="propose"
                  open={isForceAssignModalOpen}
                  onClose={() => {
                    setForceAssignModalClose();
                    setWarnings(null);
                    setIsVisible(true);
                  }}
                  startDate={needData.startDate}
                  projectId={projectId}
                  loading={isProposePersonLoading}
                  warnings={warnings}
                  employeeId={selectedPerson.employeeId}
                  onSubmit={() => handleSubmitButtonClick({ force: true })}
                />
              )}
            </>
          ),
          actions: (
            <>
              <Button data-cy="people-propose-modal_btn-back" variant="outlinedGray" onClick={handleBackToList}>
                {formatMessage({ id: AppMessages['card.need.peopleActions.button.back'] })}
              </Button>
              <Button
                data-cy="people-propose-modal_btn-propose"
                loading={isProposePersonLoading}
                variant="contained"
                onClick={handleSubmitButtonClick}
              >
                {formatMessage({ id: AppMessages['button.proposeTeamMember'] })}
              </Button>
            </>
          ),
        },
      ]}
      defaultPeopleFilters={{
        skills: needData.skillSet.filter(({ weight }) => weight === SkillWeight.mustHave).map(({ name }) => name),
        availabilityFrom: needData.startDate,
        availabilityTo: needData.endDate,
        seniorities: needData.seniority !== Seniority.withoutLevel ? [needData.seniority] : [],
      }}
    />
  );
};
