import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { EditDepositStatusRequest } from './editDepositStatusActions.types';

export const editDepositStatusMutation: (
  workStatementId: string,
  depositId: string,
) => MutationFn<EditDepositStatusRequest> = (workStatementId: string, depositId: string) => (body) => ({
  endpoint: `/work-statements/${workStatementId}/deposits/${depositId}/status`,
  method: 'PATCH',
  params: body,
});
