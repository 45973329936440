import { DepartmentCode } from 'api/types/DepartmentCode.enum';
import { PersonAvailabilityStatus } from 'api/types/PersonAvailabilityStatus.enum';
import { Seniority } from 'api/types/Seniority.enum';
import { Translation } from 'i18n/messages';
import { Fte } from '../fteList';
import { peopleSortingOptions } from 'context/peopleFilters/peopleFiltersContextController/PeopleFiltersContextController';
import {
  EmploymentTypeFilter,
  PeopleFilters,
} from 'context/peopleFilters/peopleFiltersContext/PeopleFiltersContext.types';

type FilterOption = {
  labelTranslation: Translation;
  value: string;
};

type PeopleFiltersData = Record<
  keyof Pick<PeopleFilters, 'sort' | 'seniorities' | 'departments' | 'fte' | 'status' | 'employmentType'>,
  FilterOption[]
>;

export const filtersData: PeopleFiltersData = {
  sort: [
    {
      labelTranslation: 'sorting.availabilityAsc',
      value: peopleSortingOptions.availabilityASC,
    },
    {
      labelTranslation: 'sorting.availabilityDesc',
      value: peopleSortingOptions.availabilityDESC,
    },
    {
      labelTranslation: 'sorting.nameAsc',
      value: peopleSortingOptions.nameASC,
    },
    {
      labelTranslation: 'sorting.nameDesc',
      value: peopleSortingOptions.nameDESC,
    },
  ],
  seniorities: [
    {
      labelTranslation: 'filters.seniority.option.junior',
      value: Seniority.junior,
    },
    {
      labelTranslation: 'filters.seniority.option.mid',
      value: Seniority.mid,
    },
    {
      labelTranslation: 'filters.seniority.option.senior',
      value: Seniority.senior,
    },
  ],
  departments: [
    {
      labelTranslation: 'filters.role.option.front',
      value: DepartmentCode.front,
    },
    {
      labelTranslation: 'filters.role.option.node',
      value: DepartmentCode.node,
    },
    {
      labelTranslation: 'filters.role.option.java',
      value: DepartmentCode.java,
    },
    {
      labelTranslation: 'filters.role.option.archit',
      value: DepartmentCode.archit,
    },
    {
      labelTranslation: 'filters.role.option.devops',
      value: DepartmentCode.devops,
    },
    {
      labelTranslation: 'filters.role.option.php',
      value: DepartmentCode.php,
    },
    {
      labelTranslation: 'filters.role.option.pm',
      value: DepartmentCode.pm,
    },
    {
      labelTranslation: 'filters.role.option.qa',
      value: DepartmentCode.qa,
    },
    {
      labelTranslation: 'filters.role.option.qaa',
      value: DepartmentCode.qaa,
    },
    {
      labelTranslation: 'filters.role.option.ba',
      value: DepartmentCode.ba,
    },
    {
      labelTranslation: 'filters.role.option.nmd',
      value: DepartmentCode.nmd,
    },
    {
      labelTranslation: 'filters.role.option.others',
      value: DepartmentCode.others,
    },
  ],
  fte: [
    {
      labelTranslation: 'filters.fte.option.fullTime',
      value: Fte.fullTime,
    },
    {
      labelTranslation: 'filters.fte.option.halfTime',
      value: Fte.halfTime,
    },
  ],
  status: [
    {
      labelTranslation: 'filters.status.option.available',
      value: PersonAvailabilityStatus.available,
    },
    {
      labelTranslation: 'filters.status.option.availableSoon',
      value: PersonAvailabilityStatus.availableSoon,
    },
    {
      labelTranslation: 'filters.status.option.assigned',
      value: PersonAvailabilityStatus.assigned,
    },
    {
      labelTranslation: 'filters.status.option.preassigned',
      value: PersonAvailabilityStatus.preassigned,
    },
  ],
  employmentType: [
    {
      labelTranslation: 'people.filters.employmentType.tshEmployee',
      value: EmploymentTypeFilter.tshEmployee,
    },
    {
      labelTranslation: 'people.filters.employmentType.subcontractor',
      value: EmploymentTypeFilter.subcontractor,
    },
    {
      labelTranslation: 'people.filters.employmentType.uncertain',
      value: EmploymentTypeFilter.uncertain,
    },
  ],
};
