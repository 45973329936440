import Joi from 'joi';

import { getCurrentDayStartDate, formatDate, getEndOfMonthDate } from 'utils/dateUtils';
import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';

export const extendEndDateSchema = (minDate: Date) => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object({
    extensionDate: Joi.date()
      .min(minDate)
      .messages({
        'date.base': formatMessage(
          { id: AppMessages['validation.date'] },
          { label: formatMessage({ id: AppMessages['assignment.teamMember.extension.label'] }) },
        ),
        'date.min': formatMessage(
          {
            id: AppMessages['validation.greater'],
          },
          {
            label: formatMessage({ id: AppMessages['assignment.teamMember.extension.label'] }),
            limit: formatDate(minDate),
          },
        ),
        'date.max': formatMessage(
          { id: AppMessages['validation.max'] },
          {
            label: formatMessage({ id: AppMessages['assignment.teamMember.extension.label'] }),
            limit: formatDate(getEndOfMonthDate(getCurrentDayStartDate())),
          },
        ),
      }),
  });
};
