import { DialogTitle, DialogContent, DialogActions, Stack } from '@mui/material';
import { SubmitHandler } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useSnackbar } from 'notistack';
import { Button } from 'lux/components';
import { parseISO } from 'date-fns';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { useAssignmentRemoval } from 'hooks/useAssignmentRemoval/useAssignmentRemoval';
import { useAssignmentInfo } from 'hooks/useAssignmentInfo/useAssignmentInfo';
import { useUnsavedChangesDialogContext } from 'hooks/useUnsavedChangesDialogContext/useUnsavedChangesDialogContext';
import { useLocale } from 'hooks/useLocale/useLocale';
import { DatePicker } from 'ui/form/datePicker/DatePicker';
import { AppMessages } from 'i18n/messages';
import { Checkbox } from 'ui/form/checkbox/Checkbox';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { InfoBox } from 'ui/infoBox/InfoBox';
import { formatDate, getCurrentDayStartDate, isDateBefore } from 'utils/dateUtils';
import { removeImmediatelySchema } from '../RemoveImmediatelyModal.schema';

import { getDefaultRemovalDate } from './RemoveImmediatelyContent.utils';
import * as styles from './RemoveImmediatelyContent.styles';
import { RemoveImmediatelyContentProps, RemoveImmediatelyFormValues } from './RemoveImmediatelyContent.types';

export const RemoveImmediatelyContent = ({ onClose, assignmentData }: RemoveImmediatelyContentProps) => {
  const { formatMessage, t } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { setClose: setSidecardClose } = useAssignmentInfo();
  const { mutate, isLoading } = useAssignmentRemoval(assignmentData.project.id, assignmentData.id);
  const { DialogClickAwayListenerWrapper } = useUnsavedChangesDialogContext();

  const isAssignmentFinished = assignmentData.endDate
    ? isDateBefore(parseISO(assignmentData.endDate), getCurrentDayStartDate())
    : false;

  const defaultValues = {
    includeInRejected: false,
    removePermanently: false,
    removalReason: '',
  };

  const form = useIntlForm<RemoveImmediatelyFormValues>({
    defaultValues: isAssignmentFinished
      ? defaultValues
      : { ...defaultValues, removalDate: getDefaultRemovalDate(assignmentData.endDate) },
    mode: 'onSubmit',
    resolver: joiResolver(removeImmediatelySchema(parseISO(assignmentData.startDate))),
  });

  const { control, handleSubmit, watch } = form;

  const includeInRejected = watch('includeInRejected');
  const removePermanently = watch('removePermanently');

  const personFullName = `${assignmentData.person.firstName} ${assignmentData.person.lastName}`;

  const onSubmit: SubmitHandler<RemoveImmediatelyFormValues> = ({ removalDate, includeInRejected, removalReason }) => {
    const requestBody = {
      includeInRejected,
      removePermanently,
      ...(includeInRejected && { reason: removalReason }),
    };

    mutate(
      isAssignmentFinished
        ? requestBody
        : { ...requestBody, endDate: removePermanently ? formatDate(new Date()) : formatDate(removalDate as Date) },
      {
        onSuccess: () => {
          onClose();
          enqueueSnackbar(
            formatMessage(
              { id: AppMessages['assignment.removeImmediately.snackbar.success'] },
              {
                personName: personFullName,
                projectName: assignmentData.project.name,
              },
            ),
          );
          setSidecardClose();
        },
      },
    );
  };

  return (
    <DialogClickAwayListenerWrapper defaultValues={defaultValues} form={form} onClose={onClose}>
      <DialogTitle>
        {formatMessage(
          { id: AppMessages['assignment.removeImmediately.title'] },
          { personName: personFullName, projectName: assignmentData.project.name },
        )}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={3} sx={styles.container}>
            <Checkbox
              name="removePermanently"
              label={t('projectDetails.removeEmployeesModal.warning.title')}
              control={control}
            />

            {removePermanently ? (
              <InfoBox variant="warning" title={t('assignment.menu.removeImmediately.title')}>
                {t('projectDetails.removeEmployeesModal.warning.description')}
              </InfoBox>
            ) : (
              <>
                {!isAssignmentFinished && (
                  <DatePicker
                    fullWidth
                    minDate={parseISO(assignmentData.startDate)}
                    maxDate={getCurrentDayStartDate()}
                    disableFuture
                    label={t('assignment.removeImmediately.removalDate.label')}
                    name="removalDate"
                    control={control}
                    defaultCalendarMonth={getCurrentDayStartDate()}
                    isDisabled={isAssignmentFinished}
                  />
                )}
                <Checkbox
                  name="includeInRejected"
                  label={t('assignment.removeImmediately.includeInRejected.label')}
                  control={control}
                />
                {includeInRejected && (
                  <FormTextField
                    label={t('assignment.removeImmediately.removalReason.label')}
                    fullWidth
                    name="removalReason"
                    control={control}
                  />
                )}
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button sx={styles.cancelButton} variant="text" type="button" onClick={onClose}>
            {t('button.cancel')}
          </Button>
          <Button color="error" loading={isLoading} type="submit" variant="contained">
            {t('assignment.removeImmediately.button.submit')}
          </Button>
        </DialogActions>
      </form>
    </DialogClickAwayListenerWrapper>
  );
};
