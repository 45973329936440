import { Box, Stack } from '@mui/material';

import { ProposalStatus } from 'config/data/need/ProposalStatus.enum';
import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';
import { Skeleton } from 'ui/skeleton/Skeleton';

import * as styles from './ProposalTab.styles';

export const ProposalTabSkeleton = () => {
  const mockedStatus = '' as ProposalStatus;

  return (
    <Box sx={styles.proposalTabWrapper(mockedStatus)}>
      <Box sx={styles.proposalContentWrapper}>
        <Box>
          <TextSkeleton width={52} variant="caption" />
        </Box>
        <Box sx={styles.proposalBadgeWrapper(mockedStatus)}>
          <Skeleton width={20} height={20} />
        </Box>
      </Box>
      <Stack sx={styles.proposalAvatarsWrapper}>
        <Skeleton circular width={32} height={32} />
      </Stack>
    </Box>
  );
};
