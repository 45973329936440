import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useMutation } from 'hooks/useMutation/useMutation';
import { assignmentsKeys, projectsKeys, rejectionsKeys } from 'utils/queryKeys';
import { personEndDateExtensionMutation } from 'api/actions/personEndDateExtension/personEndDateExtension';

import { EndDateExtensionErrorEnum, UseEndDateExtensionError } from './useEndDateExtension.types';

export const useEndDateExtension = (projectId: string) => {
  const queryClient = useQueryClient();
  const { t } = useLocale();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(['endDateExtension'], personEndDateExtensionMutation(projectId), {
    onSuccess: () => {
      queryClient.invalidateQueries(assignmentsKeys.assignmentsList);
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(rejectionsKeys.rejectionsList);
    },
    onError: (err) => {
      const { error } = err.response?.data as UseEndDateExtensionError;

      if (error.code.includes(EndDateExtensionErrorEnum.ALREADY_PLANNED)) {
        enqueueSnackbar(error.message);
      }

      enqueueSnackbar(t('assignment.teamMember.extension.snackbar.error'));
    },
  });
};
