import { Styles } from 'styles/theme';

export const skillSetsWrapper: Styles = {
  my: 3,
};

export const addSkillsButton: Styles = {
  mb: 3,
  '&.MuiButton-root': {
    paddingX: 1,
  },
};

export const baseSkillsGrid: Styles = {
  py: '14px',
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: 'repeat(3, 1fr) 0.3fr',
  gap: 3,
  mx: '0px',
  borderBottom: 1,
  borderColor: 'border.default',

  '> .MuiGrid-item': {
    px: '0 !important',
  },
};

export const editableSkillsGrid: Styles = {
  ...baseSkillsGrid,
  py: 2,
};

export const editSkillButton: Styles = {
  textDecoration: 'underline',
  fontSize: '14px',
  fontWeight: 600,
};

export const previewSkillsGrid: Styles = {
  ...baseSkillsGrid,
  py: 1,
  alignItems: 'center',
};
