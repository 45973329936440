import { useState, useEffect } from 'react';

import { Modal, Box, Stack } from '@mui/material';
import { Typography, Button } from 'lux/components';
import ReactCanvasConfetti from 'react-canvas-confetti';

import MattImg from 'assets/images/matt.png';
import CongratulationsImg from 'assets/images/congratulations.gif';
import { getRandomNumberInRange } from 'utils/getRandomNumberInRange';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';
import { AppMessages } from 'i18n/messages';

import { SuccessModalProps } from './SuccessModal.types';
import * as styles from './SuccessModal.styles';

export const SuccessModal = ({ open, onClose }: SuccessModalProps) => {
  const { formatMessage } = useLocale();

  const [fireOptions, setFireOptions] = useState({ origin: { x: 0.5, y: 0.5 }, velocity: 35 });

  useDialogBlockScroll(open);

  useEffect(() => {
    const fireConfetti = () => {
      setFireOptions({
        origin: {
          x: getRandomNumberInRange(0.15, 0.85, 2),
          y: getRandomNumberInRange(0.2, 0.85, 2),
        },
        velocity: getRandomNumberInRange(20, 40),
      });
    };

    const delay = getRandomNumberInRange(1500, 600);
    const timeout = setTimeout(() => fireConfetti(), delay);

    return () => clearTimeout(timeout);
  }, [fireOptions]);

  return (
    <>
      <Modal sx={styles.modal} open={open} onClose={onClose}>
        <>
          <Box sx={styles.modalContent}>
            <Box sx={styles.imageWrapper}>
              <img
                src={CongratulationsImg}
                width={888}
                height={355.2}
                alt={formatMessage({
                  id: AppMessages['projectDetails.convertLeadModal.successModal.img.congratulations'],
                })}
              />
              <img
                style={styles.imageMatt}
                width={134}
                height={201}
                src={MattImg}
                alt={formatMessage({
                  id: AppMessages['projectDetails.convertLeadModal.successModal.img.matt'],
                })}
              />
            </Box>

            <Stack sx={styles.bottomContent} alignItems="center">
              <Typography sx={styles.title} variant="h3">
                {formatMessage({ id: AppMessages['projectDetails.convertLeadModal.successModal.title'] })}
              </Typography>
              <Typography variant="h6" sx={styles.message}>
                {formatMessage({ id: AppMessages['projectDetails.convertLeadModal.successModal.message'] })}
              </Typography>
              <Button variant="contained" onClick={onClose}>
                {formatMessage({ id: AppMessages['projectDetails.convertLeadModal.successModal.button'] })}
              </Button>
            </Stack>
          </Box>
          <Box sx={styles.background} />
          <ReactCanvasConfetti
            style={styles.confettiCanvas}
            fire={fireOptions}
            origin={fireOptions.origin}
            particleCount={450}
            ticks={400}
            startVelocity={fireOptions.velocity}
            spread={360}
          />
        </>
      </Modal>
    </>
  );
};
