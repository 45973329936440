import { WorkStatementAssignment } from 'api/types/BillingSummary.types';

const SPACE_IN_CHARS = 1;

export const getTheLongestFullNameFromAssignments = (assignments: WorkStatementAssignment[]) =>
  assignments.reduce(
    (maxInAssignments, name) =>
      Math.max(maxInAssignments, name.firstName.length + SPACE_IN_CHARS + name.lastName.length),
    0,
  );
