import { getTagsQuery } from 'api/actions/getTags/getTags';
import { GetTagsParams, GetTagsResponse } from 'api/actions/getTags/getTags.types';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { dictionariesKeys } from 'utils/queryKeys';

export const useTags = () => {
  const { isAuthenticated } = useAuth();

  return useQuery<GetTagsParams, GetTagsResponse>(dictionariesKeys.tagsList, getTagsQuery, {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });
};
