import { SxProps } from '@mui/system';

export const dialog: SxProps = {
  '.MuiDialog-paper': {
    paddingRight: 5,
    flexDirection: 'row',
    minWidth: 808,
    overflow: 'hidden',
  },
};

export const container: SxProps = {
  backgroundColor: 'surface.paper.default',
  width: '400px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
};

export const avatar: SxProps = {
  width: 80,
  height: 80,
  mt: '2rem',
};

export const personName: SxProps = {
  color: 'text.main',
  fontSize: '1.1rem',
  mt: 2,
  textAlign: 'center',
};

export const personRole: SxProps = {
  color: 'text.secondary',
  fontSize: '0.8rem',
  m: '0.2rem',
  textAlign: 'center',
};
