import type { Styles } from 'lux/theme';

export const sidebar: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: 208,
  py: 5,
  px: 4,
  rowGap: 1,
  textAlign: 'center',
  backgroundColor: 'surface.paper.default',
};

export const roleName: Styles = {
  color: 'text.secondary',
};
