import { Button, Table, Tooltip, Typography } from 'lux/components';
import { Box } from '@mui/material';
import { OpenInNewIcon } from 'lux/icons';

import { useBillingSummaryPrice } from 'hooks/useBillingSummaryPrice/useBillingSummaryPrice';
import { Translation } from 'ui/translation/Translation';
import { InvoiceStatusBadge } from 'ui/invoiceStatusBadge/InvoiceStatusBadge';
import { getCurrentDayStartDate, getNumberOfCalendarDaysBetweenDates, parseDate } from 'utils/dateUtils';

import * as styles from './InvoiceRow.styles';
import { InvoiceRowProps } from './InvoiceRow.types';

export const InvoiceRow = ({ invoice, isClientView }: InvoiceRowProps) => {
  const { formatPrice } = useBillingSummaryPrice();

  const currentDate = new Date();

  const lastDaysCount = getNumberOfCalendarDaysBetweenDates(
    getCurrentDayStartDate(),
    parseDate(invoice.statusChangeDate),
  );

  return (
    <Table.Row sx={styles.row}>
      <Table.Cell>{invoice.number}</Table.Cell>
      <Table.Cell>
        <InvoiceStatusBadge
          invoice={invoice}
          status={invoice.status}
          billingCycleEndDate={parseDate(invoice.billingCycle.end)}
          currentDate={currentDate}
        />
        <Box sx={styles.statusInfo}>
          <Typography variant="caption" fontWeight={500} color="text.secondary">
            <Translation
              id="projectDetails.invoices.status.days"
              values={{
                daysCount: lastDaysCount,
              }}
            />
          </Typography>
        </Box>
      </Table.Cell>
      <Table.Cell>{invoice.workStatement.number}</Table.Cell>
      <Table.Cell>
        <Box>
          <Translation id="projectDetails.invoices.billingCycle.from" values={{ date: invoice.billingCycle.start }} />
        </Box>
        {invoice.billingCycle.end && (
          <Box>
            <Translation id="projectDetails.invoices.billingCycle.to" values={{ date: invoice.billingCycle.end }} />
          </Box>
        )}
      </Table.Cell>
      <Table.Cell>{formatPrice(invoice.amount.value)}</Table.Cell>
      {isClientView && (
        <Tooltip title={invoice.projectName} placement="right-start" arrow>
          <Table.Cell width={200} sx={styles.projectLabel}>
            {invoice.projectName}
          </Table.Cell>
        </Tooltip>
      )}
      <Table.Cell width={80}>
        {invoice.previewUrl && process.env.REACT_APP_MVP2_FEATURE_ENABLED === 'true' && (
          <Button iconOnly variant="text" color="primary" href={invoice.previewUrl} target="_blank" rel="noreferrer">
            <OpenInNewIcon />
          </Button>
        )}
      </Table.Cell>
    </Table.Row>
  );
};
