import { Styles } from 'styles/theme';

export const projectsGridWrapper: Styles = {
  paddingX: 6,
};

export const projectsListWrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
};

export const cardWrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden',
};
