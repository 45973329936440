import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { RequestNeedParams } from './requestNeedActions.types';

export const requestNeedMutation: (projectId: string) => MutationFn<RequestNeedParams> =
  (projectId: string) => (body) => ({
    endpoint: `/projects/${projectId}/request-need`,
    method: 'POST',
    params: body,
  });
