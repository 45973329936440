import { Styles } from 'styles/theme';

export const statsItems: Styles = {
  display: 'flex',
  flexWrap: 'wrap',

  '.MuiTypography-root': {
    marginRight: 2,
  },
};
