import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { EditDepositRequest } from './editDepositActions.types';

export const editDepositMutation: (workStatementId: string, depositId: string) => MutationFn<EditDepositRequest> =
  (workStatementId: string, depositId: string) => (body) => ({
    endpoint: `/work-statements/${workStatementId}/deposits/${depositId}`,
    method: 'PATCH',
    params: body,
  });
