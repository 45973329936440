import { Table } from 'lux/components';

import { TextSkeleton } from 'ui/textSkeleton/TextSkeleton';

import * as styles from './SectionHeaderRow.styles';

export const SectionHeaderRowSkeleton = ({ spacingTop }: { spacingTop?: boolean }) => (
  <>
    {spacingTop && <Table.Row sx={{ height: 16 }} />}
    <Table.Row>
      <Table.Cell sx={styles.sectionHeaderRow} size="small" colSpan={4}>
        <TextSkeleton width={80} variant="caption" />
      </Table.Cell>
    </Table.Row>
  </>
);
