import { Typography } from 'lux/components';

import { useDeleteNeed } from 'hooks/useDeleteNeed/useDeleteNeed';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';

import { DeleteNeedContentProps } from './DeleteNeedContent.types';

export const DeleteNeedContent = ({
  projectId,
  needId,
  onClose,
  onSuccess: onDeleteSuccess,
}: DeleteNeedContentProps) => {
  const { formatMessage } = useLocale();
  const { mutateAsync: deleteNeed, isLoading: isNeedDeleting } = useDeleteNeed(projectId);

  const handleDeleteNeed = async (needId: string) => {
    await deleteNeed(
      { needId },
      {
        onSuccess: () => {
          onClose();
          onDeleteSuccess();
        },
      },
    );
  };

  return (
    <DialogInnerWrapper
      title={formatMessage({ id: AppMessages['card.need.remove.title'] })}
      loading={isNeedDeleting}
      onClose={onClose}
      onSubmit={() => handleDeleteNeed(needId)}
      submitText={formatMessage({ id: AppMessages['button.remove'] })}
      isFormValid={!!projectId && !!needId}
    >
      <Typography variant="body1">{formatMessage({ id: AppMessages['card.need.remove.description'] })}</Typography>
    </DialogInnerWrapper>
  );
};
