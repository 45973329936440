import { useContext } from 'react';

import { FormNavigationContext } from '../formNavigationContext/FormNavigationContext';

export const useFormNavigation = () => {
  const context = useContext(FormNavigationContext);

  if (!context) {
    throw new Error('FormNavigationContext must be within FormNavigationContextController');
  }

  return context;
};
