import { Box } from '@mui/material';

import { Skeleton } from 'ui/skeleton/Skeleton';
import { AvailabilityFilters } from 'context/availabilityFilters/availabilityFiltersContext/AvailabilityFiltersContext.types';

import { AvailabilityFiltersSkeleton } from './availabilityFilters/AvailabilityFilters.skeleton';
import { AvailabilityTableSkeleton } from './availabilityTable/AvailabilityTable.skeleton';

export const AvailabilityContentSkeleton = ({ filters }: { filters: AvailabilityFilters }) => (
  <>
    <AvailabilityFiltersSkeleton />
    <AvailabilityTableSkeleton filters={filters} />
    <Box sx={{ mt: 2 }}>
      <Skeleton width={200} height={40} />
    </Box>
  </>
);
