import { Box } from '@mui/material';
import { Typography, Button, Tooltip } from 'lux/components';

import * as styles from './PageHeader.styles';
import { PageHeaderProps } from './PageHeader.types';

export const PageHeader = ({ title, actionButton }: PageHeaderProps) => {
  const {
    actionTitle,
    tooltip,
    icon,
    tooltipPlacement = 'bottom',
    disabled = false,
    size = 'medium',
    variant = 'contained',
    color = 'primary',
    onClick,
  } = actionButton ?? {};

  return (
    <Box data-cy="page-header" sx={styles.pageHeader}>
      <Typography variant="h4">{title}</Typography>
      {actionButton && (
        <Tooltip title={tooltip} placement={tooltipPlacement} arrow>
          <Box>
            <Button
              data-cy="page-header_btn"
              onClick={onClick}
              size={size}
              variant={variant}
              color={color}
              startIcon={icon}
              disabled={disabled}
            >
              {actionTitle}
            </Button>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};
