import { FormControlLabel } from '@mui/material';
import { Checkbox } from 'lux/components';

import { Filter } from 'ui/filter/Filter';
import { filtersData } from 'config/data/projects/filters';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useProjectsFilters } from 'hooks/useProjectsFilters/useProjectsFilters';

import { ProjectTypeFilterProps } from './ProjectTypeFilter.types';

export const ProjectTypeFilter = ({ variant }: ProjectTypeFilterProps) => {
  const { formatMessage } = useLocale();
  const {
    filters: { type },
    toggleFilter,
  } = useProjectsFilters();

  const handleFilterChange = (value: string) => {
    toggleFilter('type', value);
  };

  return (
    <Filter variant={variant} label={formatMessage({ id: AppMessages['projects.filters.type.label'] })}>
      {filtersData['type'].map(({ labelTranslation, value }) => (
        <FormControlLabel
          key={value}
          label={formatMessage({ id: AppMessages[labelTranslation] })}
          control={<Checkbox value={value} checked={type.includes(value)} onChange={() => handleFilterChange(value)} />}
        />
      ))}
    </Filter>
  );
};
