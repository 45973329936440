import { useState } from 'react';

import { Button, InlineEdit, Tooltip } from 'lux/components';
import { DeleteIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { Currency } from 'api/types/Currency.enum';
import { PersonRatesData } from '../personRatesData/PersonRatesData';
import { PersonRatesInput } from '../personRatesInput/PersonRatesInput';
import { formatDate, parseDate } from 'utils/dateUtils';
import { convertRateToInteger } from 'utils/convertRate';
import { useModifyPersonRate } from 'hooks/useModifyPersonRate/useModifyPersonRate';
import { useRemoveFuturePersonRate } from 'hooks/useRemoveFuturePersonRate/useRemoveFuturePersonRate';
import type { PersonRateErrors } from '../personRatesInput/PersonRatesInput.types';

import * as styles from './EditPersonRateInput.styles';
import type { EditPersonRateInputProps } from './EditPersonRateInput.types';

export const EditPersonRateInput = (props: EditPersonRateInputProps) => {
  const { assignmentRates, currentRate, futureRate, personId, isPersonPlaceholder, isEditMode, setIsEditMode } = props;
  const { t } = useLocale();
  const [cost, setCost] = useState(futureRate ? futureRate.rate : currentRate.rate);
  const [formCost, setFormCost] = useState(cost.toString());
  const [applyFrom, setApplyFrom] = useState(futureRate ? parseDate(futureRate.appliesFrom) : new Date());
  const [formApplyFrom, setFormApplyFrom] = useState<Date | null>(applyFrom);
  const [errors, setErrors] = useState({
    formCost: false,
    formApplyFrom: false,
  });

  const { mutateAsync: modifyRate } = useModifyPersonRate(personId);
  const { mutateAsync: removeFutureRate } = useRemoveFuturePersonRate(personId);
  const handleSubmit = async () => {
    if (!formApplyFrom || !formCost || errors.formApplyFrom || errors.formCost) {
      return;
    }

    await modifyRate({
      appliesFrom: formatDate(formApplyFrom),
      rate: { rate: convertRateToInteger(formCost), currency: currentRate.currency as Currency },
    });

    setIsEditMode(false);
    setCost(parseFloat(formCost));
    setApplyFrom(formApplyFrom);
  };
  const resetEditData = () => {
    setFormCost(cost.toString());
    setFormApplyFrom(applyFrom);
    setErrors({ formCost: false, formApplyFrom: false });
  };

  const handleRemoveFutureRate = async () => {
    if (currentRate) {
      await removeFutureRate({});

      setIsEditMode(false);
      setCost(currentRate.rate);
      setApplyFrom(new Date(currentRate.appliesFrom));

      resetEditData();
    }
  };

  const wasDataChanged = cost !== parseFloat(formCost) || applyFrom !== formApplyFrom;

  return (
    <InlineEdit
      header={t('personDetails.accordion.details.personCost')}
      disabled={isPersonPlaceholder}
      submitDisabled={!wasDataChanged || errors.formApplyFrom || errors.formCost}
      input={
        <PersonRatesInput
          assignmentRates={assignmentRates}
          currentRate={currentRate}
          formCost={formCost}
          formApplyFrom={formApplyFrom}
          onFormCostChange={setFormCost}
          onFormApplyFromChange={setFormApplyFrom}
          errors={errors}
          onFormError={({ formApplyFromError, formCostError }: PersonRateErrors) => {
            setErrors({
              formCost: formCostError ?? errors.formCost,
              formApplyFrom: formApplyFromError ?? errors.formApplyFrom,
            });
          }}
        />
      }
      onSubmit={handleSubmit}
      isActive={isEditMode}
      onCancel={() => setIsEditMode(false)}
      onDataDisplayClick={() => {
        resetEditData();
        setIsEditMode(true);
      }}
      customActionsLeft={
        futureRate && (
          <Tooltip
            title={t('personDetails.accordion.details.personCost.delete')}
            PopperProps={{ sx: styles.removeFutureRateTooltip }}
            arrow
            placement="top"
          >
            <Button
              onClick={handleRemoveFutureRate}
              iconOnly
              variant="outlined"
              color="error"
              size="small"
              sx={{ mr: 1, backgroundColor: 'common.white' }}
            >
              <DeleteIcon color="error" />
            </Button>
          </Tooltip>
        )
      }
    >
      <PersonRatesData
        assignmentRates={assignmentRates}
        currentRate={currentRate}
        futureRate={futureRate}
        cost={cost}
      />
    </InlineEdit>
  );
};
