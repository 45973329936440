import { useSnackbar } from 'notistack';

import { useMutation } from '../useMutation/useMutation';
import { useProjectBillingSummaryData } from '../useProjectBillingSummaryData/useProjectBillingSummaryData';
import { updateInvoice } from 'api/actions/updateInvoice/updateInvoice';
import { getApiError } from 'api/utils/getApiError';

export const useUpdateInvoice = (invoiceId: string, projectId: string) => {
  const { invalidateBillingSummaryCache } = useProjectBillingSummaryData();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(['updateInvoiceMutation'], updateInvoice(invoiceId, projectId), {
    onSuccess: () => {
      invalidateBillingSummaryCache();
    },
    onError: (error) => {
      enqueueSnackbar(getApiError(error));
    },
  });
};
