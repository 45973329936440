import { Styles } from 'styles/theme';

export const contentContainer: Styles = {
  marginY: 1,
  overflow: 'hidden',
};

export const truncatedContent = (maxLines: number): Styles => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: maxLines.toString(),
});
