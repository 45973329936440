import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { apiVersionKey } from 'utils/queryKeys';
import { GetApiVersionParams, GetApiVersionResponse } from 'api/actions/getApiVersion/getApiVersion.types';
import { getApiVersionQuery } from 'api/actions/getApiVersion/getApiVersion';

export const useApiVersion = () => {
  const { isAuthenticated } = useAuth();

  return useQuery<GetApiVersionParams, GetApiVersionResponse>(apiVersionKey, getApiVersionQuery, {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });
};
