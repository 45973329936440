import { Box, Stack } from '@mui/material';
import { Typography } from 'lux/components';

import { ReactComponent as ProjectLogoPlaceholder } from 'assets/images/project-logo-placeholder.svg';

import * as styles from './SidebarHeader.styles';
import { SidebarHeaderProps } from './SidebarHeader.types';

export const SidebarHeader = ({ title, description, iconOnly = false }: SidebarHeaderProps) => (
  <Stack direction="row">
    <ProjectLogoPlaceholder />
    <Box sx={styles.content(iconOnly)}>
      <Typography variant="body2" sx={styles.title}>
        {title}
      </Typography>
      <Typography variant="caption" sx={styles.description}>
        {description}
      </Typography>
    </Box>
  </Stack>
);
