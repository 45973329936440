import { useRef, MouseEvent, useEffect } from 'react';

import { Box, ButtonBase } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { Button } from 'lux/components';
import { CloseIcon, SearchIcon } from 'lux/icons';

import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

import * as styles from './Searchbar.styles';
import { SearchbarProps } from './Searchbar.types';

export const Searchbar = <T extends FieldValues>({
  label,
  name,
  onSubmit,
  control,
  isClearable,
  onClear,
  focus,
}: SearchbarProps<T>) => {
  const { formatMessage } = useLocale();
  const searchbarRef = useRef<HTMLInputElement>(null);

  const handleClearMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    if (document.activeElement === searchbarRef.current) {
      event.preventDefault();
    }
  };

  const handleSubmitMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    if (document.activeElement === searchbarRef.current) {
      event.preventDefault();
    }
  };

  const handleSubmitClick = () => {
    onSubmit();
    searchbarRef.current?.blur();
  };

  useEffect(() => {
    if (focus) {
      searchbarRef.current?.focus();
    }
  }, []);

  return (
    <Box sx={styles.searchbarWrapper} component="form" onSubmit={onSubmit}>
      <FormTextField
        control={control}
        name={name}
        inputRef={searchbarRef}
        endAdornment={
          isClearable ? (
            <ButtonBase onMouseDown={handleClearMouseDown} onClick={onClear}>
              <CloseIcon />
            </ButtonBase>
          ) : (
            <SearchIcon />
          )
        }
        fullWidth
        label={label}
      />
      <Button
        onMouseDown={handleSubmitMouseDown}
        onClick={handleSubmitClick}
        variant="contained"
        type="submit"
        size="large"
      >
        {formatMessage({ id: AppMessages['button.search'] })}
      </Button>
    </Box>
  );
};
