import { Stack } from '@mui/material';

import { GroupByProject } from '../../../../shared/needs/needsFilters/groupByProject/GroupByProject';
import { NeedsSorting } from '../../../../shared/needs/needsFilters/needsSorting/NeedsSorting';

export const NeedsSortingBar = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="center">
    <GroupByProject />
    <NeedsSorting />
  </Stack>
);
