import { useForm } from 'react-hook-form';
import Joi, { ObjectSchema } from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

import { HoursPopoverForm } from './HoursPopover.types';

const hoursPopoverSchema: ObjectSchema<HoursPopoverForm> = Joi.object()
  .keys({
    regularWorkAmount: Joi.number().allow('').min(0).max(24).required(),
    regularWorkHourRate: Joi.number().min(0).required(),
    overhoursAmount: Joi.number().allow('').min(0).max(24).required(),
    overhourRate: Joi.number().min(0).required(),
  })
  .custom((value, helpers) => {
    const sum = value.regularWorkAmount + value.overhoursAmount;
    const maxValue = 24;

    if (sum > maxValue) {
      return helpers.error('regularWorkAmount');
    }

    return value;
  }, 'customValidationStr');

export const useHoursPopoverForm = (defaultValues: HoursPopoverForm) => {
  const {
    control,
    reset,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    trigger,
    formState: { isValid },
  } = useForm<HoursPopoverForm>({
    defaultValues,
    criteriaMode: 'all',
    resolver: joiResolver(hoursPopoverSchema),
  });

  return { control, reset, handleSubmit, isValid, watch, setError, clearErrors, trigger };
};
