import { Table } from 'lux/components';

import * as styles from './SectionHeaderRow.styles';
import { SectionHeaderProps } from './SectionHeader.types';

export const SectionHeaderRow = ({ title, spacingTop }: SectionHeaderProps) => (
  <>
    {spacingTop && <Table.Row sx={{ height: 16 }} />}
    <Table.Row>
      <Table.Cell sx={styles.sectionHeaderRow} size="small" colSpan={4}>
        {title}
      </Table.Cell>
    </Table.Row>
  </>
);
