import { useRef } from 'react';

import { enqueueSnackbar, closeSnackbar, SnackbarKey } from 'notistack';

import { intl } from 'context/locale/intl/Intl';
import { AppMessages } from 'i18n/messages';

const AUTO_HIDE_DURATION = 5000;

export const useUndoSnackbar = (title: string, onSuccess: VoidFunction) => {
  const { formatMessage } = intl.currentIntl;

  const snackbarRef = useRef<SnackbarKey>();
  const isUndoActionTriggeredRef = useRef<boolean>(false);

  const onClose = () => {
    if (!isUndoActionTriggeredRef.current) {
      onSuccess();
      snackbarRef.current = undefined;
    } else {
      isUndoActionTriggeredRef.current = false;
    }
  };

  const handleAction = () => {
    if (!snackbarRef.current) {
      snackbarRef.current = enqueueSnackbar(title, {
        autoHideDuration: AUTO_HIDE_DURATION,
        luxSnackbarAction: () => {
          isUndoActionTriggeredRef.current = true;
          closeSnackbar(snackbarRef.current);
        },
        luxSnackbarActionLabel: formatMessage({ id: AppMessages['snackbar.button.undo'] }),
        onClose,
      });
    }
  };

  return handleAction;
};
