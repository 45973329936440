import { Styles } from 'styles/theme';

export const dialogActions: Styles = {
  justifyContent: 'space-between',
};

export const cancelButton: Styles = {
  '&.MuiButton-root': {
    paddingX: 1,
  },
};

export const container: Styles = {
  mb: 3,
  alignItems: 'flex-start',

  '& .MuiPaper-root': {
    alignItems: 'flex-start',

    '& .MuiAlert-icon': {
      mt: 0.3,
    },
  },
};
